import React from "react";

const UndoButton = ({ onHandleClick, closeToast, title, buttonTitle }) => {
    const handleClick = () => {
        onHandleClick();
        closeToast();
    };

    return (
        <div>
            <p>{title}</p>
            <button onClick={handleClick}>{buttonTitle}</button>
        </div>
    );
};
export default UndoButton;