import React from "react";
import { Draggable } from "react-beautiful-dnd";
import _ from "lodash";

import { LOCATION_INPUT_BOX_NAME_SOURCE } from "../../../utils/constants/constant";
import LocationSearchDeleteButton from "../LocationSearchDeleteButton/LocationSearchDeleteButton";

const classNames = require("classnames");

export default function LocationSearchBox(props) {
	return (
		<Draggable index={props.id} draggableId={props.id.toString()}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="location-search-box-container draggable"
				>
					{!props.savedPlaces ? (
						<div className="pin-icon-wrapper">
							{props.icon}
							{props.number && !_.isNull(props.number) && <span className="number-destination">{props.number}</span>}
						</div>
					) : null}
					<div className={classNames({ "location-search-box-inner": !props.savedPlaces })}>
						<input
							type="text"
							autoComplete={props.autoComplete}
							name={LOCATION_INPUT_BOX_NAME_SOURCE}
							onFocus={props.onFocus ? (e) => props.onFocus(e, props.refrence, props.index) : null}
							ref={props.refrence}
							defaultValue={props.value}
							onKeyUp={props.onSearchPlace}
							autoFocus={props.autoFocus}
							className="location-search-box-input"
							placeholder={props.placeholder ? props.placeholder : "Add first stop"}
							disabled={props.disabled}
							index={props.index}
						/>
						{props.clear ? (
							<div className="location-search-box-delete">
								<LocationSearchDeleteButton draggable={props.number && !_.isNull(props.number)} onClick={props.onClear} />
							</div>
						) : null}
					</div>
				</div>
			)}
		</Draggable>
	);
}
