import React, {Fragment} from 'react';

import spacerImg from '../../../assests/images/jobs/spacer.svg';

export default function LocationSearchSpacerBooking(props) {

    return (
		<Fragment>
			<div className="Location-box jobs-box-spacer" ref={props.refrence}>
				<div className="spacer-img">
					<img src={spacerImg} className="pull-left" alt="" />
				</div>
				<div className="Location-search-booking-divider">
					<hr></hr>
				</div>
			</div>
		</Fragment>
	);
}