import React, { Fragment } from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";

import { MdAdd, MdRemove } from "react-icons/md";

const classNames = require("classnames");

export default function JobChatHistoryExtend(props) {
	return (
		<Fragment>
			<div className="Job_Chat_History_Extend Job_Chat_History_Extend_Action no-padding">
				<div className="J_C_H_E_Time_Box">
					<h5 className="Job_Chat_History_Extend_Time">{priceJobTime(props.prices, props.cubeNumber, props.currentPrice)}</h5>
				</div>
				<div className="J_C_H_E_Time_Fee">
					<p className="">
						{props.currency} {" "}
						{priceValue(props.prices, props.cubeNumber, props.currentPrice)}
					</p>
				</div>
				<div className="J_C_H_E_Time_Extend_Cost">
					<Button
						disabled={props.prices.items.length === props.currentPrice + 1}
						onClick={props.onBookingPlus}
						className={classNames({
							"Job_Chat_History_Extend_Plus Job_Chat_History_Extend": true,
							Job_Chat_History_Extend_Disabled: props.prices.items.length === props.currentPrice + 1,
						})}
					>
						<MdAdd />
					</Button>

					<Button
						disabled={props.currentPrice === props.cubeNumber}
						onClick={props.onBookingMinus}
						className={classNames({
							"Job_Chat_History_Extend_Minus Job_Chat_History_Extend": true,
							Job_Chat_History_Extend_Disabled: props.currentPrice === props.cubeNumber,
						})}
					>
						<MdRemove />
					</Button>
				</div>
			</div>
			<div className="Job_Chat_History_Comment">
				<p>
					<span role="img" aria-label="emoji">💡</span> Your time extension request will be sent to the partner and you will only be charged {props.currency} {" "}
					{priceValue(props.prices, props.cubeNumber, props.currentPrice)} time extension fee once the partner accepts the request
				</p>
			</div>
		</Fragment>
	);
}

/**
 * Calculate price value for extending
 * @param {*} prices
 * @param {*} cubeNumber
 * @param {*} currentPrice
 */
const priceJobTime = (prices, cubeNumber, currentPrice) => {
	let priceCurrent = prices.items[currentPrice];
	let initialPrice = prices.items[cubeNumber];
	if(!_.isUndefined(priceCurrent)) {
		let value = priceCurrent.jobTime.total.value - initialPrice.jobTime.total.value;
		if (value === 0) {
			return 0;
		} else {
			return time_convert(value);
		}
	}
};
/**
 * Calculating price for extending job
 * @param {*} prices
 * @param {*} cubeNumber
 * @param {*} currentPrice
 */
const priceValue = (prices, cubeNumber, currentPrice) => {
	let priceCurrent = prices.items[currentPrice];
	let initialPrice = prices.items[cubeNumber];
	let value = priceCurrent.price.value - initialPrice.price.value;
	value = value;
	return value; //price.price.displayValue.split(' ').join('');
};
/**
 * convert mins to readable time format
 * @param {*} num
 */
const time_convert = (num) => {
	const hours = Math.floor(num / 60);
	const minutes = num % 60;
	return `${padNumber(hours, 2) + " hr"} ${padNumber(minutes, 2) + " min"}`;
};
/**
 * padding number with zero
 * @param {*} number
 * @param {*} size
 */
const padNumber = function(number, size) {
	number = String(number);
	while (number.length < (size || 2)) {
		number = "0" + number;
	}
	return number;
};