import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = (props) =>
	props.devicewidth >= 412 ? (
		<ContentLoader
			speed={3}
			width={412}
			height={312}
			viewBox="0 0 412 312"
			backgroundColor="rgba(255,255,255,0.1)"
			foregroundColor="rgba(255,255,255,0.2)"
			{...props}
		>
			<rect x="28" y="120" rx="3" ry="3" width="88" height="12" />
			<circle cx="69" cy="91" r="20" />
			<rect x="25" y="18" rx="3" ry="3" width="120" height="18" />
			<circle cx="206" cy="89" r="20" />
			<rect x="163" y="117" rx="3" ry="3" width="88" height="12" />
			<circle cx="341" cy="88" r="20" />
			<rect x="298" y="116" rx="3" ry="3" width="88" height="12" />
			<circle cx="72" cy="173" r="20" />
			<rect x="28" y="205" rx="3" ry="3" width="88" height="12" />
			<circle cx="206" cy="172" r="20" />
			<rect x="164" y="203" rx="3" ry="3" width="88" height="12" />
			<circle cx="348" cy="168" r="20" />
			<rect x="302" y="203" rx="3" ry="3" width="88" height="12" />
			<circle cx="70" cy="255" r="20" />
			<rect x="28" y="287" rx="3" ry="3" width="88" height="12" />
			<circle cx="212" cy="256" r="20" />
			<rect x="167" y="285" rx="3" ry="3" width="88" height="12" />
			<circle cx="347" cy="255" r="20" />
			<rect x="305" y="284" rx="3" ry="3" width="88" height="12" />
		</ContentLoader>
	) : (
		<ContentLoader
			speed={3}
			width={412}
			height={312}
			viewBox="0 0 412 312"
			backgroundColor="rgba(255,255,255,0.1)"
			foregroundColor="rgba(255,255,255,0.2)"
			{...props}
		>
			<rect x="28" y="120" rx="3" ry="3" width="88" height="12" />
			<circle cx="69" cy="91" r="20" />
			<rect x="25" y="18" rx="3" ry="3" width="120" height="18" />
			<circle cx="180" cy="90" r="20" />
			<rect x="137" y="118" rx="3" ry="3" width="88" height="12" />
			<circle cx="299" cy="88" r="20" />
			<rect x="257" y="118" rx="3" ry="3" width="88" height="12" />
			<circle cx="72" cy="173" r="20" />
			<rect x="28" y="205" rx="3" ry="3" width="88" height="12" />
			<circle cx="185" cy="175" r="20" />
			<rect x="138" y="205" rx="3" ry="3" width="88" height="12" />
			<circle cx="305" cy="176" r="20" />
			<rect x="261" y="206" rx="3" ry="3" width="88" height="12" />
			<circle cx="70" cy="255" r="20" />
			<rect x="28" y="287" rx="3" ry="3" width="88" height="12" />
			<circle cx="181" cy="259" r="20" />
			<rect x="141" y="287" rx="3" ry="3" width="88" height="12" />
			<circle cx="304" cy="255" r="20" />
			<rect x="265" y="285" rx="3" ry="3" width="88" height="12" />
		</ContentLoader>
	);

export default Skeleton;