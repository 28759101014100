import React from 'react';

import { ReactComponent as DeleteSvg } from '../../../v5-icons/clear.svg';
import { ReactComponent as DragSvg } from '../../../v5-icons/drag.svg';

export default function LocationSearchDeleteButton(props) {
    return (
        <button onClick={props.onClick} className="location-search-delete-button">
            <DeleteSvg />
            {props.draggable ? <DragSvg /> : null}
        </button>
    );
}