import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import forward from "../../assests/icons/rightArrow.svg"

export const CustomizedSnackbar = (props) => {
	const history = useHistory();
	return <div onClick={() => window.location.href = "https://kuya.to/9w2u"} className={classNames(
		"snackbar-container",
		history.location.pathname === "/home" ? "snackbar-sticky" : "snackbar-absolute",
	)} style={{...props?.styles}}>
		<div className="snackbar-body">
			{/*<img src={noAudio} alt="icon" />*/}
			<span>Download mykuya app to receive chat notifications</span>
		</div>
		<div className="snackbar-corner">
			<img src={forward} alt="forward icon" />
		</div>
	</div>;
}