import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import onClickOutside from "react-onclickoutside";

import Avatar from "../../../avatar/avatar";

import { appGoBackJobInformation, addAnimateToText } from "../../../../utils/redux/actions/commonActions";
import { checkVoipIsSupported, onCallPartner } from "../../../../utils/redux/actions/chatActions";
import { onChatMenu, navigateToPartnerLivaLocation } from "../../../../utils/redux/actions/jobsActions";

import genderPatner from "../../../../utils/functions/gender-partner";
import JobCoPartner from "../../job-co-partner/job-co-partner";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import TitleCard from "../../../ui-elements/TitleCard";
import backButton from "../../../../v5-icons/close-thin.svg";
import KebabMenu from "../../../../v5-icons/kebab-menu.svg";

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
class JobChatHistoryHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isVoiceCallDropDownOpen: false,
			hasFadePropPartner: false,
			hasFadePropTitle: false
		};
	}

	componentDidMount() {
		this.props.dispatch(addAnimateToText(document.querySelector(".long-heading"), document.querySelector(".long-heading .heading")))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropTitle: true,
					}));
				}
			}).catch((err) => {});
		this.props
			.dispatch(addAnimateToText(document.querySelector(".partner-name-container"), document.querySelector(".partner-name-container .heading")))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropPartner: true,
					}));
				}
			}).catch((err) => {});
	}

	handleClickVoiceCallOrPhoneCall () {
		this.setState((state) => ({
			isVoiceCallDropDownOpen: !state.isVoiceCallDropDownOpen,
		}));
	}

	componentWillUnmount() {
		this.setState(() => ({
			isVoiceCallDropDownOpen: false
		}))
	}

	handleClickOutside = evt => {
		this.setState(() => ({
			isVoiceCallDropDownOpen: false
		}))
	};

	render() {
		return (
			<div className="chat-header-container">
				<div className="chat-menu-title-bar">
					<TitleCard
						leftIcon={backButton}
						rightIcon={KebabMenu}
						handleClickRightIcon={(e) => this.props.dispatch(onChatMenu())}
						handleClickLeftIcon={(e) => this.props.dispatch(appGoBackJobInformation())}
						lozengeObject={{
							title:
								this.props.jobState === "Started"
									? "In Progress"
									: this.props.jobState === "Queued" && this.props.job.product.mode.includes("MY_MALL")
									? "MATCHED"
									: this.props.jobState,
							color: this.props.jobState === "Started" ? "green" : "orange",
							longHeading: this.props.job.product.commercialName,
						}}
						classList="has-long-heading"
						hasFadeProp={this.state.hasFadePropTitle}
					/>
				</div>
				{(this.props.job.product.mode.includes("MY_MALL") && !_.isNull(this.props.job.partner)) ||
				!this.props.job.product.mode.includes("MY_MALL") ? (
					<div className="job-chat-history-header-down">
						{!_.isEmpty(this.props.job) && !_.isNull(this.props.job.partner) && (
							<div className="J_C_H_H_D_Profile">
								<div className="J_C_H_H_D_Profile_Img_Box">
									<Avatar img={this.props.job.partner.photo} />
								</div>
								<div className="J_C_H_H_D_Profile_Text_Box">
									<div className="long-heading text-container partner-name-container">
										<span className="heading Job_Chat_History_Header_Name">
											{this.props.job.partner.title ? this.props.job.partner.title : capitalizeFirstLetter(genderPatner(this.props.job.partner))}{" "}
											{capitalizeFirstLetter(this.props.job.partner.givenName)}{" "}
											{capitalizeFirstLetter(this.props.job.partner.lastName)}
										</span>
										{this.state.hasFadePropPartner && (
											<>
												<div className="fader fader-left"></div>
												<div className="fader fader-right"></div>
											</>
										)}
									</div>

									{_.has(this.props.job.partner, "coPartner") && !_.isNull(this.props.job.partner.coPartner) ? (
										!_.isNull(this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name) ? (
											<JobCoPartner
												name={this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name}
											/>
										) : null
									) : null}
								</div>
							</div>
						)}
						<div className="job-chat-history-top-icons">
							<div className="live-location-icon-wrapper">
								<div className="live-location-icon-container">
									<DynamicIcon
										icon="WhitePin"
										classes="white-pin-icon"
										clicked={(e) => this.props.dispatch(navigateToPartnerLivaLocation())}
									/>
								</div>
							</div>

							{!this.props.booking.voipIsSupported ? (
								<div className="J_C_H_H_D_Call">
									<div className="J_C_H_H_D_C_Circle">
										<DynamicIcon
											icon="WhiteCall"
											classes="Call_Partner_Icon"
											clicked={(e) => this.props.dispatch(onCallPartner())}
										/>
									</div>
								</div>
							) : null}

							{this.props.booking.voipIsSupported && this.props.booking.voipMode.length > 0 ? (
								<div className="call-container">
									<div className="phone-voice-video-container">
										<div className="call-container">
											<div className="J_C_H_H_D_Call">
												<div className="J_C_H_H_D_C_Circle" onClick={() => this.handleClickVoiceCallOrPhoneCall()}>
													<DynamicIcon icon="WhiteCall" classes="Call_Partner_Icon" />
												</div>
											</div>
										</div>
										{this.state.isVoiceCallDropDownOpen ? (
											<div className="call-drop-down">
												{this.props.booking.voipMode.includes("VIDEO_CHAT") ? (
													<div
														className="call-drop-down-container"
														onClick={(e) => this.props.dispatch(checkVoipIsSupported("VIDEO_CHAT_TOKEN"))}
													>
														<div className="call-drop-down-icon-container">
															<DynamicIcon icon="VideoCall" classes="voice-call" />
														</div>
														<p>Video Call</p>
													</div>
												) : null}

												{this.props.booking.voipMode.includes("VOICE_CHAT") ? (
													<div
														className="call-drop-down-container"
														onClick={(e) => this.props.dispatch(checkVoipIsSupported("VOICE_CHAT_TOKEN"))}
													>
														<div className="call-drop-down-icon-container">
															<DynamicIcon icon="VoiceCall" classes="voice-call" />
														</div>
														<p>Voice Call</p>
													</div>
												) : null}

												{/*<div className="call-drop-down-container" onClick={() => this.props.dispatch(onCallPartner())}>*/}
												{/*	<div className="call-drop-down-icon-container">*/}
												{/*		<DynamicIcon icon="PhoneCall" classes="voice-call" />*/}
												{/*	</div>*/}
												{/*	<p>Phone Call</p>*/}
												{/*</div>*/}
											</div>
										) : null}
									</div>
								</div>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

const mapStateTothis = state => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
	booking: state.booking
});

export default connect(mapStateTothis)(onClickOutside(JobChatHistoryHeader));
