import React, { Fragment } from "react";

import "emoji-mart/css/emoji-mart.css";

import { Input } from "../../../chat-elements";
import JobChatHistoryAttach from "../JobChatHistoryAttach/JobChatHistoryAttach";
import JobChatHistoryCamera from "../JobChatHistoryCamera/JobChatHistoryCamera";
import JobChatHistoryRecorder from "../JobChatHistoryRecorder/JobChatHistoryRecorder";
import JobChatHistorySend from "../JobChatHistorySend/JobChatHistorySend";
import JobChatLeftAction from "../JobChatLeftAction/JobChatLeftAction";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import JobChatHistoryThumbsUp from "../JobChatHistoryThumbsUp/JobChatHistoryThumbsUp";

const classNames = require("classnames");

export default function JobChatHistoryAction(props) {
	return (
		<Fragment>
			<div className="no-padding Job-chat-history-input-container">
				{props.record ? <div id="job-chat-history-timer"
									 className="no-padding Job-chat-history-user-actions Job-chat-history-user-actions-textarea-record">

					<JobChatHistoryRecorder isDisabled={props.isDisabled}
											onStart={props.recording}
											onStop={props.recording}
											record={props.record}
											onCancel={props.onCancelRecording}/>
				</div> : <div className="Job-chat-history-action-parent">
					<div className="J_C_H_A_P_Right">
						<div
							className="no-padding Left_Icons_Text_Area">
							<div className={classNames("L_I_T_A_Icons", {
								"L_I_T_A_Icons_Focus": props.inputValue.length === 0,
								"L_I_T_A_Icons_Blur": props.inputValue.length > 0,
							})}>
								<div className="Left_Icons_Parent">
									{(props.inputValue.length === 0) ?
										<Fragment>
											<div className="Left_Icons_Child_Box">
												<JobChatHistoryCamera
													inputCameraRef={props.inputCameraRef}
													onCamera={props.onCamera}
													inputFileCameraChange={props.inputFileCameraChange}
													icon="Camera"
													iconClasses="Camera_Chat_History"
												/>
											</div>
											<div className="Left_Icons_Child_Box">
												<JobChatHistoryAttach
													onAttach={props.onAttach}
													inputFileRef={props.inputFileRef}
													inputFileChange={props.inputFileChange}/>
											</div>
											<div className="Left_Icons_Child_Box">
												<div onClick={props.recording}
													 className="Job-chat-history-actions  no-padding text-center pull-right">
													<button className="Job-chat-history-emoji-button"
															type="button">
														<DynamicIcon icon="VoiceRecorder" classes="Recorder_Chat_History"/>
													</button>
												</div>
											</div>

										</Fragment>
										: <div className="Left_Icons_Child_Box">
											<JobChatLeftAction
												onClearTyping={props.onClearInput}
											/>
										</div>}
								</div>

							</div>
							<div
								className={classNames("no-padding Job-chat-history-input-and-actions Job_Chat_History_Input_And_Actions", {
									"Job-chat-history-actions no-padding pull-right": true,
									"Job-chat-history-input-width": props.inputValue.length === 0,
									"Job-chat-history-input-width-longer": props.inputValue.length > 0,
								})}>

								<div
								>
									<Input
										// onKeyPress={(e) => {
										// 	if (e.shiftKey && e.charCode === 13) {
										// 		return true;
										// 	}
										// 	if (e.charCode === 13) {
										// 		if (props.inputValue.length > 0) {

										// 			props.onSend();
										// 			e.preventDefault();
										// 			return false;
										// 		} else {
										// 			e.preventDefault();
										// 			return false;
										// 		}
										// 	}
										// }}
										multiline={true}
										inputRef={props.inputRef}
										onChange={props.onChangeInput}
										placeholder="Aa"
										value={props.inputValue}/>
								</div>
							</div>

						</div>
					</div>

					<div className="J_C_H_A_P_Left">
						{(props.inputValue.length > 0) ?
							<JobChatHistorySend onClick={props.onSend}
												disabled={!(props.inputValue.length > 0)}/>
							:
							<div>
								<JobChatHistoryThumbsUp thumbsUpSend={props.thumbsUpSend}/>
							</div>
						}
					</div>

				</div>}

			</div>
		</Fragment>
	);
}