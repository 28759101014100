import { push } from 'connected-react-router';
import _ from 'lodash';

import * as DISPATCH_STATE from '../commonChangeState';
import { appLogout } from "../commonActions";

import { localStorageLogout } from "../../../../functions/local-storage";

/**
 * Handle fetch error in app
 * @param {*} error 
 */
export const appFetchFailure = error => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.appLoadingEnd());
        dispatch(DISPATCH_STATE.appLoadingKuyaEnd());
        // errors for graphql implementation
        if (error.result === "Invalid verification code.") {
            dispatch(DISPATCH_STATE.wrongVerificationCode(true));
        } else if (error.result === "Token is invalid") {
            dispatch(DISPATCH_STATE.wrongVerificationCode(true));
        } else if (error.result === "Token is invalid. Token was used recently") {
            dispatch(DISPATCH_STATE.wrongVerificationCode(true));
        } else if (error === "Invalid Party id.") {
            dispatch(DISPATCH_STATE.saveLastPathName(window.location.pathname));
            dispatch(DISPATCH_STATE.appAuthCheckFailed(localStorage.getItem("token"), false));
            dispatch(DISPATCH_STATE.toggleRegisterBottomSheet(true));
            localStorage.setItem("userWantsToRegister", 1);
            dispatch(DISPATCH_STATE.appAuthCheckEnd());

            // dispatch(push('/register')); 
            // dispatch(DISPATCH_STATE.appAuthCheckInit());
            //  || error === "Unauthorized."
        } 
        // else if(error === "Unauthorized.") {
        //     localStorageLogout();
        // }
        else if (error === "jwt expired" || error === "Client is not found") {
			dispatch(DISPATCH_STATE.tokenExpired());
			dispatch(appLogout());
		}
        if (_.has(error, 'response')) {
            if (_.has(error.response, 'status')) {
                if (error.response.status === 500) {
                    if (error.response.data.message === "Invalid partyId.") {
                        dispatch(push('/register'));
                    } else if (error.result === "Invalid verification code.") {
                        dispatch(DISPATCH_STATE.wrongVerificationCode(true));
                    } else if (error.response.data.message === "Token is invalid") {
                        dispatch(DISPATCH_STATE.wrongVerificationCode(true));
                    } else if (error.response.data.message === "Token is invalid. Token was used recently") {
                        dispatch(DISPATCH_STATE.wrongVerificationCode(true));
                    }
                } 
                // else if (error.response.status === 402) {
                //     if (error.response.data.message === "Provide Payment method") {
                //         dispatch(DISPATCH_STATE.providePaymentMethod(true));
                //     }
                // }
            }
        }
        dispatch(checkInternetConnection());
        dispatch(DISPATCH_STATE.appFetchErrorFailure(error))
    }
};

// check internet connection
export function checkInternetConnection() {
    return (dispatch) => {
        if ('navigator' in window && !window.navigator.onLine) {
            dispatch(DISPATCH_STATE.appInternetIsDisconnected());
        }
    }
}