import React, { Fragment } from 'react';

import { ReactComponent as RecentSearch } from "../../../v5-icons/recent.svg";
import { ReactComponent as ResultLocationIcon } from "../../../v5-icons/result-location.svg";
const classNames = require('classnames');

export default function LocationSearchSuggestionsItem(props) {
    return (
		<div
			className={classNames("location-search-suggestions-item", {
				"is-searching": props.isSearching,
			})}
		>
			{!props.isSearching ? (
				<div className="recent-search-result">
					<RecentSearch />
				</div>
			) : (
				<div className="recent-search-result">
					<ResultLocationIcon />
				</div>
			)}

			<p className="location-search-suggestions-title" onClick={(e) => props.onSearchClick(e, props.index)}>
				{props.title}
			</p>
		</div>
	);
}
