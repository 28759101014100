import React from "react";
import ContentLoader from "react-content-loader";

const SingleListSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={props.devicewidth}
		height={70}
		viewBox={`0 0 ${props.devicewidth} 70`}
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<circle cx="28" cy="39" r="18" />
		<rect x="58" y="20" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="39" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="58" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
	</ContentLoader>
);

export default SingleListSkeleton;
