import _ from 'lodash';

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import { savedPlacesHomeWork } from '../../../../functions/saved-places-home-work';
import { appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import { getLastUsedRequestBody } from "./saved-places-gql";
import { setDeliveringToPlaceInfo } from "./savedPlaces-select";
import { fetchPrice, setEventBookingLocationsDidSet } from "../../bookingActions";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

/**
 * Fetch all saved places
 */
export const fetchSavedPlaces = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const allSavedPlaces = {
                query: `query SavedPlaces {
                    savedPlaces {
                        specific{
                            id,
                            title,
                            phoneCall,
                            note,
                            location {
                                name,
                                address,
                                lat,
                                lng,
                                data
                            },
                            type,
                            isBookmarked
                        },
                        extra {
                            id,
                            title,
                            phoneCall,
                            note,
                            location {
                                name,
                                address,
                                lat,
                                lng,
                                data
                            },
                            type,
                            isBookmarked
                        }
                    }
                }`,
            };
            dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(true));
            GraphRequest.all(allSavedPlaces)
                .then((json) => {
                    if (!_.isNull(json.data.data) && json.data.data.savedPlaces) {
                        dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(false));
                        dispatch(DISPATCH_STATE.savedPlacesFetchAll(json.data.data.savedPlaces));
                        dispatch(DISPATCH_STATE.savedPlacesFindHomeWork(savedPlacesHomeWork(json.data.data.savedPlaces['specific'])));
                        resolve(true);
                    }
                    reject(false);
                })
                .catch((e) => dispatch(appFetchFailure(e)))
        })
    }
}