import React from "react";
import ContentLoader from "react-content-loader";

const LinearSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={412}
		height={60}
		viewBox="0 0 412 60"
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<rect x="0" y="0" rx="3" ry="3" width={props.devicewidth.toString()} height="12" />
		<rect x="0" y="20" rx="3" ry="3" width={props.devicewidth.toString()} height="12" />
	</ContentLoader>
);

export default LinearSkeleton;
