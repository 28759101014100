import React from 'react';
import _ from 'lodash';

import CustomButton from '../../ui-elements/CustomButton/CustomButton';

export default function ReviewBookingButton(props) {
	return !_.isEmpty(props.prices) && !_.isNull(props.prices.minCubeNumbers) ? (
		<div className="review-booking-button-container">
			<div className="price-container">
				<p>Starts at</p>
				<p className="price">
					{props.prices.items[props.prices.minCubeNumbers].price.currency} {props.prices.items[props.prices.minCubeNumbers].price.value}{" "}
				</p>
			</div>
			<div className="review-booking-button">
				<CustomButton title="Review Booking" onClick={props.onReviewBooking} />
			</div>
		</div>
	) : null;
}