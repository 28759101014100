import React from "react";
import Lozenge from "../lozenge/lozenge";
const classNames = require("classnames");

export default function TitleCard({ title, leftIcon, rightIcon, handleClickLeftIcon, handleClickRightIcon, lozengeObject, classList, hasFadeProp }) {
	return (
		<div className={classNames("title-bar-container", classList, { "has-not-fade": !hasFadeProp })}>
			{leftIcon && (
				<div className="title-bar-icon" onClick={handleClickLeftIcon}>
					<div className="title-bar-icon-inner">
						<div className="back-button">
							<img className="" src={leftIcon} alt="LeftIcon" />
						</div>
						{lozengeObject && (
							<div className="title-card-body">
								{lozengeObject.longHeading && (
									<div className="long-heading text-container">
										<span className="heading">{lozengeObject.longHeading}</span>
										{hasFadeProp && (
											<>
												<div className="fader fader-left"></div>
												<div className="fader fader-right"></div>
											</>
										)}
									</div>
								)}
								<Lozenge title={lozengeObject.title} color={lozengeObject.color} />
							</div>
						)}
					</div>
				</div>
			)}
			{title && <p>{title}</p>}
			{rightIcon && (
				<div className="right-icon-container">
					<img className="right-icon" onClick={handleClickRightIcon} src={rightIcon} alt="" />
				</div>
			)}
		</div>
	);
}
