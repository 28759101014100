import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {push} from "connected-react-router";
import _ from 'lodash';
import Lottie from 'react-lottie';

import JobMatchingModal from './JobMatchingModal/JobMatchingModal';
import JobMatchedModal from './JobMatchedModal/JobMatchedModal';
import JobUnfulfilledModal from './JobUnfulfilledModal/JobUnfulfilledModal';
import JobOutOfWorkingHourModal from './JobOutOfWorkingHourModal/JobOutOfWorkingHourModal';
import JobMatchingRippleEffect from './JobMatchingRippleEffect/JobMatchingRippleEffect';
import TitleBar from "../../../organisms/title-bar";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import QueuedBookingBottomSheet from "../QueuedBookingBottomSheet/QueuedBookingBottomSheet";
import ScheduledBookingBottomSheet from "../../job-history/ScheduledBookingBottomSheet/ScheduledBookingBottomSheet";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import DarkLoading from "../../ui-elements/Loading/DarkLoading";

import {
	checkJobMatchingState,
	cancelSetIntervalAndGoHomeInUnfulfilled,
	cancelOpenJob,
	unMountJobMatching, SUCCESS_STATE_OF_INTREVAL_REQUEST_QUEUED,
} from "../../../utils/redux/actions/jobsActions";
import { SUCCESS_STATE_OF_INTREVAL_REQUEST_UNFULFILLED, SUCCESS_STATE_OF_INTREVAL_REQUEST_MATCHED } from "../../../utils/redux/actions/jobsActions/jobsConstant";
import { jobMatchingWillUnMount, setIsOutOfWorkingHours } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
import { cancelSetIntervalAndGoHome } from "../../../utils/redux/actions/jobsActions/actions/jobs-matching";
import backButton from "../../../v5-icons/close-thin.svg";
import * as animationData from './JobMatchedModal/pinjump.json';
import {
	closeQueuedJobBottomSheet,
	toggleScheduleBooking,
	toggleConfirmationBottomSheet, willUnMountReviewBooking,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import * as LOG_EVENT from "../../../utils/analytics/index";

class JobMatching extends Component {
	static prompt;
	componentDidMount() {
		if (this.props.jobMatchingState) {
			this.prompt = this.props.history.block("Are you sure you want to cancel the request?");
		}
		this.props.dispatch(checkJobMatchingState());
	}

	onCancel = (e) => {
		if (this.props.jobMatchingState) {
			this.prompt();
		}
		this.props.dispatch(cancelSetIntervalAndGoHome());
	};

	cancelOpenJob = () => {
		
	}

	componentWillUnmount() {
		this.props.dispatch(unMountJobMatching());
		if (this.props.jobMatchingState) {
			this.prompt();
		}
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		const defaultOptions = {
			background: "transparent",
			speed: "1",
			loop: true,
			control: true,
			autoplay: true,
			animationData: animationData.default,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		return (
			<Fragment>
				{!_.isEmpty(this.props.job) ? (
					<div className="job-matching">
						<TitleBar
							leftIcon={backButton}
							handleClickLeftIcon={() => this.props.dispatch(toggleConfirmationBottomSheet())}
							lozengeObject={{ title: "OPEN", color: "yellow", heading: this.props.booking.product.commercialName }}
						/>
						<JobMatchingRippleEffect />
						<JobMatchingModal prompt={this.prompt} />
						{(!_.isNull(this.props.matchingRequest) &&
						this.props.matchingRequest.state === SUCCESS_STATE_OF_INTREVAL_REQUEST_MATCHED &&
						!_.isNull(this.props.matchedPartner)) || (!_.isNull(this.props.matchingRequest) &&
							this.props.matchingRequest.state === SUCCESS_STATE_OF_INTREVAL_REQUEST_QUEUED &&
							this.props.booking.product.mode.includes("MY_MALL")) ? (
							<>
								<div className="job-matched-lottie">
									<Lottie options={defaultOptions} />
								</div>
								<BottomSheet
									isOpen={
										(!_.isNull(this.props.matchingRequest) &&
											this.props.matchingRequest.state === SUCCESS_STATE_OF_INTREVAL_REQUEST_MATCHED &&
											!_.isNull(this.props.matchedPartner)) || (!_.isNull(this.props.matchingRequest) &&
											this.props.matchingRequest.state === SUCCESS_STATE_OF_INTREVAL_REQUEST_QUEUED &&
											this.props.booking.product.mode.includes("MY_MALL"))
									}
									config={{ topShadow: false }}
									classes="job-matched-bottom-sheet"
									indicator={true}
									styles={bottomSheetStyle}
								>
									<JobMatchedModal prompt={this.prompt} />
								</BottomSheet>
							</>
						) : null}
						<BottomSheet
							isOpen={
								!_.isNull(this.props.matchingRequest) &&
								this.props.matchingRequest.state === SUCCESS_STATE_OF_INTREVAL_REQUEST_UNFULFILLED &&
								_.isNull(this.props.matchedPartner)
							}
							config={{ topShadow: false }}
							classes="job-matched-bottom-sheet"
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(cancelSetIntervalAndGoHomeInUnfulfilled());
							}}
							styles={bottomSheetStyle}
						>
							<JobUnfulfilledModal
								prompt={this.prompt}
								mode={this.props.booking.product.mode}
								onScheduleBooking={() => {
									this.props.dispatch(jobMatchingWillUnMount());
									this.props.dispatch(toggleScheduleBooking());
								}}
							/>
						</BottomSheet>

						<BottomSheet
							isOpen={this.props.isOutOfWorkingHours}
							config={{ topShadow: false }}
							classes="job-matched-bottom-sheet"
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) {
									this.props.dispatch(cancelSetIntervalAndGoHomeInUnfulfilled());
									this.props.dispatch(setIsOutOfWorkingHours(false));
								}
							}}
							styles={bottomSheetStyle}
						>
							<JobOutOfWorkingHourModal
								prompt={this.prompt}
								mode={this.props.booking.product.mode}
								onScheduleBooking={() => {
									this.props.dispatch(jobMatchingWillUnMount());
									this.props.dispatch(toggleScheduleBooking());
								}}
							/>
						</BottomSheet>

						<BottomSheet
							classes="login-bottom-sheet"
							isOpen={this.props.booking.queuedJobBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(closeQueuedJobBottomSheet());
							}}
						>
							<QueuedBookingBottomSheet />
						</BottomSheet>

						<ScheduledBookingBottomSheet isOpen={this.props.booking.scheduleBookingBottomSheet} />

						<BottomSheet
							classes="login-bottom-sheet"
							isOpen={_.isNull(this.props.matchedPartner) && this.props.booking.confirmationBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleConfirmationBottomSheet());
							}}
						>
							<div className="matching-cta-container">
								<h2>Are you sure to cancel?</h2>
								<CustomButton onClick={() => {
									this.props.dispatch(push("/jobs"));
									this.props.dispatch(willUnMountReviewBooking());
								}} type="button" title="Keep Looking In Background" />
								<CustomButton
									onClick={() => {
										LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DID_CANCEL_CWA, { from: "matching" });
										this.props.dispatch(cancelOpenJob(this.props.job.id));
									}}
									type="button"
									title={this.props.cancelJobLoading ?
										<div className="next-button-loading">
											<DarkLoading />
										</div> : "Cancel Request"
									}
									classList="cancel-booking"
								/>
							</div>
						</BottomSheet>
					</div>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    ...state.jobs,
    chat: state.chat,
    common: state.common,
	booking: state.booking,
	billing: state.billing
});

export default connect(mapStateToProps)(JobMatching);