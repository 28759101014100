import _ from 'lodash';
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import * as LOG_EVENT from "../../../../analytics/index";
import { appFetchFailure } from '../../commonActions';
import { GraphRequest } from '../../../../../axios';
import { fetchSavedPlaces } from './savedPlaces-list';
import { deleteSavedPlaceRequestBody } from './saved-places-gql';
import {
    getDeliveringToPlaceLocalStorage, getHomePickedLocationLocalStorage,
} from "../../../../functions/local-storage";
import { setDeliveringToPlaceInfo, setHomePickedLocation } from "./savedPlaces-select";
import { changeCurrentLocation } from "../../mapActions/mapChangeState";
import { mapSetCurrentLocationLocalstorage } from "../../mapActions/actions/map-current-location";
import { homeProductCheckExist } from "../../homeActions/homeChangeState";
import { mapSetPlaceGoogleSource } from "../../mapActions";
import { closeLocationSearchBottomSheet } from "../../bookingActions/bookingChangeState";
import { fetchHome, initDeliveringToPlace } from "../../homeActions";
import {localStorageIsTwa} from "../../../../functions/local-storage";
/**
 * delete request place from saved places
 * @param {*} place 
 */
export const deleteRequestPlaceOfSavedPlaces = (place) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(true));
        if (place.location.id || _.isNull(place.location.id)) delete place.location.id;
        const deleteRequestBody = {
            query: deleteSavedPlaceRequestBody,
            variables: { "id": place.id }
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(deleteRequestBody) 
                .then(res => {
                    dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(false));
                    if (!_.isNull(res.data.data) && res.data.data.deleteSavedPlace) {
                        LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACE_DELETED, { os: localStorageIsTwa() ? "twa" : "web" });
                        const deliveringToPlace = getState().savedPlaces?.deliveringToPlace ||
                            getDeliveringToPlaceLocalStorage();

                        const homePickedLocation = getHomePickedLocationLocalStorage();
                        if(!_.isNull(deliveringToPlace)) {
                            if (place.id === deliveringToPlace.id) {
                                dispatch(initDeliveringToPlace())
                            }
                        }
                        if(!_.isNull(homePickedLocation)) {
                            if (place.id === homePickedLocation.id) {
                                dispatch(setHomePickedLocation({...getState().map.location, title: getState().map.location.address}));
                                dispatch(mapSetPlaceGoogleSource({...getState().map.location, title: getState().map.location.address}));
                                localStorage.setItem('locationTitle', getState().map.location.address);
                            }
                        }
                        dispatch(fetchSavedPlaces());
                    } else {

                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
                    // dispatch(appLoadingEnd());
                    resolve();
                })
                // .catch((e) => {
                //     console.log("errr", e)
                //     dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(false));
                //     dispatch(appFetchFailure(e));
                // })
        })
    }
}
/**
 * Nevermind delete request place from saved places
 */
export const nevermindDeleteRequest = () => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.savedPlacesSetNullWantedToDeletePlace());
        dispatch(fetchSavedPlaces());
    }
}