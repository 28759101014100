import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import JobLocations from '../JobLocations/JobLocations';
import {googlePlacesMatching} from '../../../../utils/functions/google-places-matching';
import {onletsGetStarted, TIME_FOR_SETTIMEOUT_IN_MATCHED_STATE} from '../../../../utils/redux/actions/jobsActions';
import genderPatner from '../../../../utils/functions/gender-partner';
import buttonTimeout from '../../../../utils/functions/button-timeout';
import addBlurToGoogleMap from '../../../../utils/functions/add-blur-to-google-map';
import { ReactComponent as SuiteCase } from "../../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../../v5-icons/clock-small.svg";
import { ReactComponent as Note } from "../../../../v5-icons/note-small.svg";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import { getDeliveringToPlaceLocalStorage } from "../../../../utils/functions/local-storage";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require('classnames');

function JobMatchedModal(props) {
	
    addBlurToGoogleMap();
    let setTimeoutMatched = setTimeout(() => {
        props.dispatch(onletsGetStarted())
        clearTimeout(setTimeoutMatched);
    }, TIME_FOR_SETTIMEOUT_IN_MATCHED_STATE); 
    if (_.isFunction(props.prompt)) {
			props.prompt();
	}
	const jobCost = {
		currency: props.booking.prices.items[props.booking.currentPrice]["price"]["currency"],
		value: props.booking.prices.items[props.booking.currentPrice]["price"]["value"],
	};
	const deliveringToPlace = props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage()

    return (
		<Fragment>
			<div className="matching-process">
				{/* <div className="top-icon">
					<MatchedIcon />
				</div> */}
				{!_.isEmpty(props.matchedPartner?.partner) ? (
					<div className="matching-process-title">
						<div className="partner-icon">
							<img src={props.matchedPartner.partner.photo} alt="" />
						</div>
						<div className="title">
							<p>
								{props.matchedPartner.partner.title ? props.matchedPartner.partner.title : genderPatner(props.matchedPartner.partner)} {props.matchedPartner.partner.givenName}{" "}
								{props.matchedPartner.partner.lastName}
							</p>
							{_.has(props.matchedPartner.partner, "coPartner") ? (
								<span className="text-secondary">
									{props.matchedPartner.partner.coPartner.displayValue || props.matchedPartner.partner.coPartner.name}
								</span>
							) : null}
						</div>
					</div>
				) : (
					<div className="matching-process-title">
						<div className="title">
							<p>Start Shopping</p>
							<span className="text-secondary">Shop in seconds. Delivered in minutes</span>
						</div>
					</div>
				)}
				{!_.isEmpty(props.job) ? (
					<div className="matching-process-container">
						<div className="matching-process-row">
							<SuiteCase className="icon" />
							<p className="commercial-name">{props.booking.product.commercialName}</p>
						</div>
						<div className="matching-process-row">
							<JobLocations locations={props.booking.jobLocations.jobLocations} />
						</div>
						<div className="matching-process-row">
							<Clock className="icon" />
							<p>
								{jobCost && props.common.client.currency.signText} {!_.isNull(props.newPrice) ? props.newPrice : jobCost.value} for{" "}
								{props.booking.formattedTime}, extendable
							</p>
						</div>
						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance <= 0 ? (
							<div className="matching-process-row payment">
								<img
									src={props.primaryPayment[0].icon}
									alt={props.primaryPayment[0].type}
									alt={this.props.primaryPayment[0].type}
									className={classNames("billing-card-image", this.props.primaryPayment[0].type)}
									// className={classNames("billing-card-image", cardType[props.primaryPayment[0].value])}
								/>
								<p>
									Pay via {!_.isNull(props.primaryPayment[0].name) ? props.primaryPayment[0].name : this.props.primaryPayment[0].type}
									{this.props.primaryPayment[0].type === "mc" || this.props.primaryPayment[0].type === "visa"
										? `**** ${this.props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}
						{_.isEmpty(props.primaryPayment) ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
							</div>
						) : null}

						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance > 0 ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>
									Pay via mykoins then {props.primaryPayment[0].type}{" "}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}

						<div className="matching-process-row">
							<Note className="icon" />
							{!_.isNull(deliveringToPlace) ? (
								deliveringToPlace.note?.length ? (
									<p>{deliveringToPlace.note}</p>
								) : (
									<p>No note to partner</p>
								)
							) : !_.isEmpty(props.booking.note) ? (
								<p>{props.booking.note}</p>
							) : (
								<p>No note to partner</p>
							)}
							{/* {!_.isEmpty(props.booking.note) ? <p>{props.booking.note}</p> : <p>No note to partner</p>} */}
						</div>
					</div>
				) : null}
				<div className="get-started-button">
					<CustomButton
						onClick={() => {
							buttonTimeout(() => {
								clearTimeout(setTimeoutMatched);
								props.dispatch(onletsGetStarted());
							});
						}}
						type="button"
						title="Get Started"
					/>
				</div>
			</div>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
	locations: googlePlacesMatching(state.map),
	formattedTime: state.booking.formattedTime,
	primaryPayment: state.common.primaryPayment,
	billing: state.billing,
	booking: state.booking,
	newPrice: state.booking.priceWithAppliedCode,
	savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(JobMatchedModal);