import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import _ from "lodash";
import { push } from "connected-react-router";
import { parse } from "query-string";
import { toast } from "react-toastify";	
import Tour from "reactour";

import * as LOG_EVENT from "../../../utils/analytics/index";
import {
	reviewBookingPriceAndLocationExist,
	backToBookingPage,
	navigateToRequestPage,
	requestNowBooking,
	checkRejectedPayment,
	topUpMyKoin,
	applyPromoCode,
	checkRetryWithCard,
	setReviewBookingConfigTour,
	setPaymentBottomSheetTour,
	fetchLastUsed,
	fetchPrice,
	mymallReviewBookingPriceAndLocationExist,
	fetchReviewMymallAction,
} from "../../../utils/redux/actions/bookingActions";
import {
	changeBookingTimeFormat,
	setIsInstantMatch,
	togglePaymentMethodsBottomSheet,
	toggleRejectedPayment,
	toggleTopUpBottomSheet,
	togglePromoCodeBottomSheet,
	toggleScheduleBooking,
	toggleCancelBookingBottomSheet,
	willUnMountReviewBooking,
	retryWithTopUp,
	setDefaultRetryWithTopUp,
	retryWithCard,
	setPercentOff,
	setAmountOff,
	setNewPrice,
	setPromoCode,
	changePromoCode,
	removePromoCode,
	setPriceWithAplliedPromoCode, toggleRetryFailed, openLocationSearchBottomSheet, closeLocationSearchBottomSheet,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import BookingInfoModal from "../BookingInfoModal/BookingInfoModal";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import { jobTime } from "../../../utils/functions/job-time-convert";
import {
	appGoHome,
	fetchClient,
	checkIsTopUpSuccess,
	tourPageisDone,
	checkCurrentStep,
	skipShowingTour,
	removeQueryParam, addAnimateToText, appFetchFailure,
} from "../../../utils/redux/actions/commonActions";
import {
	toggleChatWithSupportBottomSheet,
	storeBookingUrl,
	getPrimaryPayment,
	toggleLogInBottomSheet,
	toggleTour,
	unMountTourConfig,
	toggleRegisterBottomSheet,
	setNavigationBlackBox, appLoadingEnd, appLoadingBegin, closeGcashFailedBottomSheet, openGcashFailedBottomSheet,
} from "../../../utils/redux/actions/commonActions/commonChangeState";
import { fetchMethodsOfPayment, availableMethods, initGCash } from "../../../utils/redux/actions/billingActions";
import {
	supportGcash,
	toggleGcashBottomSheet,
	toggleVerifyingGcashBottomSheet,
	paymentMethodsLoading,
} from "../../../utils/redux/actions/billingActions/billingChangeState";
import {
	setReviewBookingLocalStorage,
	localStorageSetCurrentBalance,
	localStorageGetCurrentBalance,
	getReviewBookingLocalStorage,
	setStoredUrlLocalStorage,
	getStoredUrlLocalStorage,
	localStorageGetTourPageViwed,
	getAddingGcashLocalStorage,
	removeAddingGcashLocalStorage,
	getDeliveringToPlaceLocalStorage,
	getHomePickedLocationLocalStorage,
	setVoucherObjectLocalStorage, getVoucherObjectLocalStorage,
} from "../../../utils/functions/local-storage";

import TitleBar from "../../../organisms/title-bar";
import BookingService from "../BookingService/BookingService";
import JobLocations from "../JobLocations/JobLocations";
import BookingPriceTime from "../BookingPriceTime/BookingPriceTime";
import ReviewBookingNote from "../BookingNote/ReviewBookingNote";
import BookingPayment from "../BookingPayment/BookingPayment";
import BookingPaymentBottomSheet from "../BookingPayment/BookingPaymentBottomSheet";
import BookingButton from "../BookingButton/BookingButton";
import RejectedPaymentMethod from "../RejectedPaymentMethod/RejectedPaymentMethod";
import HowItWorks from "../HowItWorks/HowItWorks";
import PromoCodeBottomSheet from "../PromoCodeBottomSheet/PromoCodeBottomSheet";
import UndoButton from '../../LocationSearch/LocationBookmarked/ToastLocationBookmarked';
import LogInBottomSheet from "../../LogInBottomSheet/LogInBottomSheet";
import ScheduledBookingBottomSheet from "../../job-history/ScheduledBookingBottomSheet/ScheduledBookingBottomSheet";
import TopUpBottomSheet from "../../TopUp/TopUpBottomSheet";
import CancelBookingBottomSheet from "./CancelBookingBottomSheet";
import ChatWithSupportBottomSheet from "../../ChatWithSupportBottomSheet/ChatWithSupportBottomSheet";
import BillingGCash from "../../Account/Billing/BillingInformation/BillingGCash/BillingGCash";
import BillingVerifyingGCash from "../../Account/Billing/BillingInformation/BillingGCash/BillingVerifyingGCash";
import RegisterForm from "../../register/form/register-form";

import backButton from "../../../v5-icons/back-button.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";
import MyKoinSvg from "../../../assests/images/billing/my-koin.svg";
import BookingOverlaidButton from "../BookingOverlaidButton/BookingOverlaidButton";
import ServiceFee from "../ServiceFee/ServiceFee";
import HowItWorksCarousel from "../HowItWorksCarousel/HowItWorksCarousel";
import DeliveringTo from "../DeliveringTo/DeliveringTo";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import MymallProductInfo from "../MymallProductInfo/MymallProductInfo";
import {
	editLocationSavedPlace,
	fetchSavedPlaces,
	navigateSavedPlaces, SAVED_PLACE_CUSTOM_TYPE, SAVED_PLACE_HOME_TYPE,
	savedPlaceSelectedAddress, savedPlaceSelectMap, savedPlacesSetType,
	savedPlaceUnSelectMap, setDeliveringToPlace, setSavedPlaceLocationPointer, toggleOutOfGeo,
} from "../../../utils/redux/actions/savedPlacesActions";
import { createUpdatedSavedPlaceObject } from "../../../utils/functions/create-place-object-from-google-suggestions";
import RetryFailedBottomSheet from "../RetryFailedBottomSheet/RetryFailedBottomSheet";
import SavedPlacesBottomSheet from "../../SavedPlaces/SavedPlacesBottomSheet/SavedPlacesBottomSheet";
import * as DISPATCH_STATE from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import OutOfGeoBottomSheet from "../../SavedPlaces/OutOfGeoBottomSheet/OutOfGeoBottomSheet";
import {
	searchNavigateToMapFromHome,
	searchNavigateToMapFromLocationSearch, searchNavigateToMapFromReviewMymall,
} from "../../../utils/redux/actions/searchActions";
import { setGooglePlaceObject } from "../../../utils/redux/actions/mapActions/mapChangeState";
import { createMarker } from "../../../utils/redux/actions/mapActions";
import { createPlainLocationObject } from "../../../utils/functions/create-place-object-saved-places";
import {
	fetchMymallPrice,
	reApplyPromoCode,
} from "../../../utils/redux/actions/bookingActions/actions/booking-service";
import GcashFailedBottomSheet from "../../GcashFailedBottomSheet/GcashFailedBottomSheet";
import { getQuerySearchValue } from "../../../utils/functions/get-query-search-value";
import ScheduledMymallBookingBottomSheet
	from "../../job-history/ScheduledBookingBottomSheet/ScheduledMymallBookingBottomSheet";
import LinearSkeleton from "../../ui-elements/Skeleton/LinearSkeleton";
import { localStorageIsTwa } from "../../../utils/functions/local-storage";
class MymallReviewBooking extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isShowProductInfoPopup: false,
			isOpenBottomSheet: false,
			pageName: this.props.match.url.split("/").pop(),
			isFadeIn: false,
			deliveringToPlace: null,
			actionAfterLogin: null,
			hasFadePropProduct: false,
			hasFadePropSubtitle: false,
			hasFadePropProductInfo: false,
			hasFadePropSubtitleInfo: false,
			innerWidth: null,
		};
	}

	determinePaymentActionBasedOnSearchParam(params) {
		if (_.has(params, "topUp")) {
			this.props.dispatch(checkIsTopUpSuccess(params["topUp"]));
			this.props.dispatch(removeQueryParam("topUp", this.props.history));
		} else if (_.has(params, "retryWithTopUp")) {
			this.props.dispatch(setDefaultRetryWithTopUp());
			this.props.dispatch(removeQueryParam("retryWithTopUp", this.props.history));
		} else if (_.has(params, "retryWithCard") || getStoredUrlLocalStorage()) {
			if (!_.isNull(this.props.billing.gcashItems) && this.props.billing.gcashItems.length) {
				if (_.has(params, "retryWithCard") && getAddingGcashLocalStorage()) {
					if (this.props.billing.gcashItems[0].isPending) {
						this.props.dispatch(toggleVerifyingGcashBottomSheet());
					}
					removeAddingGcashLocalStorage();
				}
			}
			this.props.dispatch(removeQueryParam("retryWithCard", this.props.history));
			this.props.dispatch(checkRetryWithCard());
		} else {
			if (localStorageGetCurrentBalance()) localStorage.removeItem("currentBalance");
			localStorage.removeItem("reviewBooking");
		}
	}

	checkGcashSuccess(params) {
		if (_.has(params, "gCashSucceed")) {
			if (getQuerySearchValue("gCashSucceed") === "false") {
				this.props.dispatch(openGcashFailedBottomSheet());
			}
		}
	}

	checkLocationHash(hash) {
		if (hash === "#adyen") {
			const voucherObject = getVoucherObjectLocalStorage();
			if (!_.isNull(voucherObject.promoCode)) this.props.dispatch(setPromoCode(voucherObject.promoCode));
			if (!_.isNull(voucherObject.newPrice)) this.props.dispatch(setNewPrice(voucherObject.newPrice));
			if (!_.isNull(voucherObject.amountOff)) this.props.dispatch(setAmountOff(voucherObject.amountOff));
			if (!_.isNull(voucherObject.percentOff)) this.props.dispatch(setPercentOff(voucherObject.percentOff));
			window.history.back();
		}
	}

	getReviewMymallAction() {
		this.props
			.dispatch(
				fetchReviewMymallAction(
					Number(this.props.history.location.pathname.split("/")[2]),
					createPlainLocationObject(this.state.deliveringToPlace?.location || this.state.deliveringToPlace),
				),
			)
			.then(() => this.addAnimateToProductName());
	}

	logEvent() {
		LOG_EVENT.logEvent(LOG_EVENT.REVIEW_PAGE_CWA, {
			hasNote: this.props.note.length > 0 ? true : false,
			locations: this.props.locationLength,
			paymentMethod: !_.isNull(this.props.common.primaryPayment) ? this.props.common.primaryPayment[0].type : "noPayment",
			myKoinBalance: this.props.billing.balance ? this.props.billing.balance[0].balance : null,
		});
	}

	setupScrollThings() {
		if (typeof window !== "undefined") {
			document.addEventListener("scroll", this.onScroll, true);
			$(".fixed-wrapper").css({ position: "relative" });
			$("body").css({ overflow: "auto" });
		}
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		if (!_.isNull(this.props.common.pathName)) {
			this.addAnimateToProductName();
		} 
		this.props.dispatch(mymallReviewBookingPriceAndLocationExist(parse(window.location.search)));
		this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() }, () => {
			if (_.isEmpty(parse(window.location.search))) {
				this.getReviewMymallAction();
			}
		});

		if (this.props.common.authCheck && this.props.common.auth && !_.isNull(localStorage.getItem("token"))) {
			this.props.dispatch(fetchSavedPlaces());

			this.props.dispatch(paymentMethodsLoading(true));
			this.props.dispatch(availableMethods()).then((res) => {
				if (res.data && !_.isNull(res.data.data.adyenPaymentMethods.paymentMethods)) {
					res.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
						if (x.type === "gcash") this.props.dispatch(supportGcash(x));
					});
				}
				this.props.dispatch(fetchMethodsOfPayment()).then((res) => {
					this.props.dispatch(paymentMethodsLoading(false));
					if (res.data.data.paymentMethods.totalItems > 0) {
						const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
						this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
					}
					this.props.dispatch(checkRejectedPayment());

					this.determinePaymentActionBasedOnSearchParam(parse(window.location.search))

					this.logEvent()

					this.checkGcashSuccess(parse(window.location.search));

					this.checkLocationHash(window.location.hash)

				});
			});
			if (!_.isNull(this.props.savedPlaces.searchPointer)) this.props.dispatch(closeLocationSearchBottomSheet());
		}

		if (this.props.promoCode) {
			this.props.dispatch(setPromoCode(this.props.promoCode));
		}

		this.setupScrollThings()
	}

	getMymallPrice() {
		this.props.dispatch(DISPATCH_STATE.fetchPriceLoading(true));
		this.props
			.dispatch(
				fetchMymallPrice(
					this.props.product.id,
					createPlainLocationObject(this.state.deliveringToPlace?.location || this.state.deliveringToPlace),
				),
			)
			.then((res) => {
				this.props.dispatch(DISPATCH_STATE.fetchPriceLoading(false));
				if (res) {
					if (!_.isEmpty(this.props.prices)) {
						this.props.dispatch(changeBookingTimeFormat(jobTime(this.props.prices, this.props.prices.minCubeNumbers)));
					}
					if (this.props.promoCode) {
						this.reApplyCode();
					}
				}
			})
			.catch((err) => {
				this.props.dispatch(DISPATCH_STATE.fetchPriceLoading(false));
				this.props.dispatch(appFetchFailure(err.response.data.errors[0].message));
				toast(`${err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
	}

	determineRightActionBasedOnButtonClick() {
		const deliveringToPlace = this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage();
		if (
			!this.props.common.primaryPayment &&
			(this.props.billing?.balance?.[0].balance === undefined || this.props.billing?.balance?.[0].balance === 0) &&
			this.state.actionAfterLogin === "onChangePrimary"
		) {
			this.onChangePrimary();
		} else if (this.state.actionAfterLogin === "onSetAddress" && !deliveringToPlace?.location) {
			this.onSetAddress();
		} else if (this.state.actionAfterLogin === "onBookingRequest") {
			this.onBookingRequest();
		} else if (this.state.actionAfterLogin === "onScheduleBooking") {
			this.onScheduleBooking();
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.locationSearchBottomSheet !== this.props.locationSearchBottomSheet && this.props.common.authCheck) {
			this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() }, () => {
				this.getMymallPrice()
			});
		}

		if (this.props.common.auth !== nextProps.common.auth) {
			this.props.dispatch(paymentMethodsLoading(true));
			// this.props.dispatch(fetchClient());
			this.props.dispatch(fetchSavedPlaces()).then((res) => {
				if (res) {
					if (
						(!_.isNull(this.props.savedPlaces.savedHome) ||
							!_.isNull(this.props.savedPlaces.savedWork) ||
							this.props.savedPlaces?.all?.extra?.length) &&
						this.state.actionAfterLogin === "onUpdateAddress"
					) {
						this.props.dispatch(setSavedPlaceLocationPointer());
						this.props.dispatch(openLocationSearchBottomSheet());
					} else if (this.state.actionAfterLogin === "onUpdateAddress") {
						this.onUpdateAddress();
					}
				}
			});
			this.props.dispatch(availableMethods()).then((res) => {
				if (res.data && !_.isNull(res.data.data.adyenPaymentMethods.paymentMethods)) {
					res.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
						if (x.type === "gcash") this.props.dispatch(supportGcash(x));
					});
				}
				this.props.dispatch(fetchMethodsOfPayment()).then((res) => {
					this.props.dispatch(paymentMethodsLoading(false));
					if (res.data.data.paymentMethods.totalItems > 0) {
						const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
						this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
					}
					this.props.dispatch(checkRejectedPayment());

					this.determinePaymentActionBasedOnSearchParam(parse(window.location.search));

					this.logEvent()

					this.determineRightActionBasedOnButtonClick()

				});
			});
		}
		return true;
	}

	setAnimateOnText(firstQuery, secondQuery, state) {
		this.props
			.dispatch(addAnimateToText(document.querySelector(firstQuery), document.querySelector(secondQuery)))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						...state
					}));
				}
			})
			.catch((err) => {});
	}

	addAnimateToProductName () {
		this.setAnimateOnText(".long-heading", ".long-heading .heading", { hasFadePropProduct: true });
		this.setAnimateOnText(".long-subtitle", ".long-subtitle .subtitle", { hasFadePropSubtitle: true });
		this.setAnimateOnText(".long-heading-info", ".long-heading-info .heading-info", { hasFadePropProductInfo: true });
		this.setAnimateOnText(".long-subtitle-info", ".long-subtitle-info .subtitle-info", { hasFadePropSubtitleInfo: true });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.scheduleBookingBottomSheet) {
			$(window).scrollTop();
			$(".fixed-wrapper").css({ position: "fixed" });
			$("body").css({ overflow: "hidden" });
		} else {
			$(".fixed-wrapper").css({ position: "relative" });
			$("body").css({ overflow: "auto" });
		}
	}

	componentWillUnmount() {
		this.props.dispatch(toggleTour(false));
		this.props.dispatch(unMountTourConfig());
		document.removeEventListener("scroll", this.onScroll, true);
		$(".fixed-wrapper").css({ position: "" });
		$("body").css({ overflow: "" });
		this.props.dispatch(setDeliveringToPlace(null));
	}

	onScroll = () => {
		const elementTop = $("#service-fee").offset().top;
		const elementBottom = elementTop + $("#service-fee").outerHeight();
		const viewportTop = $(window).scrollTop();
		const viewportBottom = viewportTop + $(window).height() - 215;
		if (elementBottom > viewportTop && elementTop < viewportBottom) {
			this.setState({ isFadeIn: true });
		} else {
			this.setState({ isFadeIn: false });
		}
	};

	onCloseProductInfo = () => {
		this.setState(() => ({
			isShowProductInfoPopup: false,
		}));
	};

	isToggleSheet = () => {
		this.setState(() => ({ isShowProductInfoPopup: false }));
	};

	onOpenProductInfoModal = () => {
		this.setState(() => ({
			isShowProductInfoPopup: true,
		}));
	};

	goHome = () => {
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(appGoHome());
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	onJobLocationClick = () => {
		LOG_EVENT.logEvent(LOG_EVENT.REVIEW_EDIT_LOCATION_CWA);
		this.props.dispatch(backToBookingPage());
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
	};

	handleBlinkingWithNoLocation(isDiscounted, isNegativeBalance, isSufficientBalance, serviceFee) {
		$("html, body").animate({ scrollTop: 98 }, "slow");
		$(".delivering-body").addClass("blinking");
		if (isDiscounted && serviceFee === 0 && !isNegativeBalance ? false : !isSufficientBalance) {
			$(".payment-method-body").addClass("blinking");
			LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
				reason: "noPayment",
				productId: this.props.product.id,
				productName: this.props.product.commercialName,
			});
		}
		const blinkingInterval = setTimeout(function () {
			$(".delivering-body").removeClass("blinking");
			$(".payment-method-body").removeClass("blinking");
			clearInterval(blinkingInterval);
		}, 2000);
	}

	handleBlinkingWithLocation(isDiscounted, isNegativeBalance, isSufficientBalance) {
		const elementTop = $(".delivering-body").offset().top;
		const elementBottom = elementTop + $(".delivering-body").outerHeight();
		$("html, body").animate({ scrollTop: elementBottom + 100 }, "slow");
		$(".payment-method-body").addClass("blinking");
		const blinkingInterval = setTimeout(function () {
			$(".payment-method-body").removeClass("blinking");
			clearInterval(blinkingInterval);
		}, 2000);
		LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
			reason: "noPayment",
			productId: this.props.product.id,
			productName: this.props.product.commercialName,
		});
	}

	provideBalanceFlags() {
		const serviceFee =
			this.props.newPrice || this.props.newPrice === 0
				? this.props.newPrice
				: this.props.prices?.items?.[this.props.prices?.minCubeNumbers]?.price.value;
		const isDiscounted = !!this.props.newPrice;
		const hasGCash = this.props.common.primaryPayment?.[0].type === "GCash" || this.props.common.primaryPayment?.[0].name === "GCash";
		const hasNoCard = _.isNull(this.props.common.primaryPayment) || _.isUndefined(this.props.common.primaryPayment);
		const isNegativeBalance = this.props.billing.balance?.[0].balance < 0;
		const isSufficientBalance = this.props.billing.balance?.[0].balance >= serviceFee || !hasNoCard || hasGCash;

		return {
			serviceFee,
			isDiscounted,
			isNegativeBalance,
			isSufficientBalance
		}
	}

	onBookingRequest = () => {
		if (this.props.common.authCheck && this.props.common.auth) {

			const {
				serviceFee,
				isDiscounted,
				isNegativeBalance,
				isSufficientBalance
			} = this.provideBalanceFlags()

			this.props.dispatch(setPriceWithAplliedPromoCode(null));
			this.props.dispatch(setSavedPlaceLocationPointer());
			if (!this.state.deliveringToPlace?.location || !this.state.deliveringToPlace?.location?.data) {
				this.handleBlinkingWithNoLocation(isDiscounted, isNegativeBalance, isSufficientBalance, serviceFee)
			} else if (isDiscounted && serviceFee === 0 && !isNegativeBalance ? false : !isSufficientBalance) {
				this.handleBlinkingWithLocation(isDiscounted, isNegativeBalance, isSufficientBalance)
			} else if (this.props.hasRejectedPayment) {
				this.props.dispatch(toggleRejectedPayment());
				LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
					reason: "rejected",
					productId: this.props.product.id,
					productName: this.props.product.commercialName,
				});
			} else {
				// provide appropriate address title for booking modals like "looking around" modal
				this.props.dispatch(
					DISPATCH_STATE.fetchPriceBegin({
						jobLocations: [
							{
								...this.state.deliveringToPlace.location,
								title: this.state.deliveringToPlace.title,
							},
						],
					}),
				);
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(
						requestNowBooking(
							{
								jobLocations: [
									this.state.deliveringToPlace.byMap ? this.state.deliveringToPlace : this.state.deliveringToPlace.location,
								],
							},
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				} else {
					this.props.dispatch(
						requestNowBooking(
							{
								jobLocations: [
									this.state.deliveringToPlace.byMap ? this.state.deliveringToPlace : this.state.deliveringToPlace.location,
								],
							},
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				}
				LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "instant" });
				this.props.dispatch(setIsInstantMatch(this.props.prices.isInstantMatch));
			}
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.setState({ actionAfterLogin: "onBookingRequest" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onScheduleBooking = () => {
		if (this.props.common.authCheck && this.props.common.auth) {

			const {
				serviceFee,
				isDiscounted,
				isNegativeBalance,
				isSufficientBalance
			} = this.provideBalanceFlags()

			this.props.dispatch(setPriceWithAplliedPromoCode(null));
			this.props.dispatch(setSavedPlaceLocationPointer());
			if (!this.state.deliveringToPlace?.location || !this.state.deliveringToPlace?.location?.data) {
				this.handleBlinkingWithNoLocation(isDiscounted, isNegativeBalance, isSufficientBalance, serviceFee)
			} else if (isDiscounted && serviceFee === 0 && !isNegativeBalance ? false : !isSufficientBalance) {
				this.handleBlinkingWithLocation(isDiscounted, isNegativeBalance, isSufficientBalance)
			} else if (this.props.hasRejectedPayment) {
				this.props.dispatch(toggleRejectedPayment());
			} else {
				this.props.dispatch(toggleScheduleBooking());
			}
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.setState({ actionAfterLogin: "onScheduleBooking" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	completePaymentMethod = () => {
		const storedUrl = `${this.props.match.url}?retryWithCard`;
		this.props.dispatch(storeBookingUrl(storedUrl));
		setStoredUrlLocalStorage(storedUrl);
		const reviewBookingObject = {
			prices: this.props.prices,
			product: this.props.product,
			formattedTime: this.props.formattedTime,
			jobLocations: this.props.jobLocations,
			primaryPayment: this.props.primaryPayment,
			note: this.props.note,
			promoCode: this.props.promoCode,
			newPrice: this.props.newPrice,
			amountOff: this.props.amountOff,
			percentOff: this.props.percentOff,
		};
		setReviewBookingLocalStorage(JSON.stringify(reviewBookingObject));
		this.props.dispatch(togglePaymentMethodsBottomSheet());
		this.props.dispatch(push("/account/billing/add"));
	};

	handleChangeBookingInfoBottomSheet = (isOpen) => {
		if (!isOpen) this.setState(() => ({ isShowProductInfoPopup: false }));
	};

	setTopUpValue = () => {
		window.history.pushState("", "", `${window.location.pathname}#adyen`);
		const voucherObject = {
			promoCode: this.props.promoCode,
			newPrice: this.props.newPrice,
			amountOff: this.props.amountOff,
			percentOff: this.props.percentOff,
		};
		setVoucherObjectLocalStorage(JSON.stringify(voucherObject));
		this.props.dispatch(
			topUpMyKoin(
				parseInt(this.props.topUpMyKoinAmount),
				process.env.REACT_APP_PUBLIC_URL,
				this.props.product.id,
				this.props.common.client.currency.unit,
			),
		);
		const reviewBookingObject = {
			prices: this.props.prices,
			product: this.props.product,
			formattedTime: this.props.formattedTime,
			jobLocations: this.props.jobLocations,
			primaryPayment: this.props.primaryPayment,
			note: this.props.note,
			promoCode: this.props.promoCode,
			newPrice: this.props.newPrice,
			amountOff: this.props.amountOff,
			percentOff: this.props.percentOff,
		};
		setReviewBookingLocalStorage(JSON.stringify(reviewBookingObject));
		localStorageSetCurrentBalance(this.props.billing.balance[0].balance);
	};

	applyCode = () => {
		const splittedPromoCode = this.props.promoCode.split(" ").join("");
		this.props.dispatch(applyPromoCode(splittedPromoCode)).then((res) => {
			if (res) {
				toast(
					<UndoButton
						title={`${res}`}
						buttonTitle={"Change"}
						onHandleClick={() => {
							this.props.dispatch(togglePromoCodeBottomSheet());
							this.props.dispatch(changePromoCode(true));
						}}
					/>,
					{
						position: "bottom-center",
						autoClose: 5000,
						limit: 1,
						className: "black-background",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					},
				);
			}
		});
	};

	reApplyCode = () => {
		const splittedPromoCode = this.props.promoCode.split(" ").join("");
		this.props.dispatch(reApplyPromoCode(splittedPromoCode));
	};

	handleLinkRemovePromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());
		this.props.dispatch(removePromoCode(true));
	};

	handleRemovePromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());

		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(removePromoCode(false));
		toast(
			<UndoButton
				title={`${this.props.promoCode} removed`}
				buttonTitle={"Undo"}
				onHandleClick={() => {
					this.props.dispatch(setNewPrice(this.props.promoCodeResult.discountedOrderAmount));
					this.props.dispatch(setAmountOff(this.props.promoCodeResult.amountOff));
					this.props.dispatch(setPercentOff(this.props.promoCodeResult.percentOff));
					this.props.dispatch(setPromoCode(this.props.tempPromoCode));
				}}
			/>,
			{
				position: "bottom-center",
				autoClose: 5000,
				limit: 1,
				className: "black-background",
				bodyClassName: "toastify-inner",
				hideProgressBar: true,
				closeOnClick: false,
			},
		);
	};

	handleCancelPromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(removePromoCode(false));
	};

	setCurrentStep(current) {
		const { pageName } = this.state;
		if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(pageName)) {
			this.props.dispatch(checkCurrentStep(current, "reviewBottomSheet"));
		} else {
			this.props.dispatch(checkCurrentStep(current, pageName));
		}
	}

	checkOpenWichBottomSheet = () => {
		if (this.props.common.auth && this.props.common.authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.TOPUP_SHEET_OPENED_CWA, {
				paymentMethod: !_.isNull(this.props.common.primaryPayment) ? this.props.common.primaryPayment[0].type : "noPayment",
				myKoinBalance: this.props.billing.balance ? this.props.billing.balance[0].balance : null,
			});
			this.props.dispatch(toggleTopUpBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onChangePrimary = () => {
		if (this.props.common.authCheck && this.props.common.auth) {
			LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_METHODS_PAGE_CWA, { from: "review" });
			this.props.dispatch(togglePaymentMethodsBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
			this.setState({ actionAfterLogin: "onChangePrimary" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onChangeAddress = () => {
		if (this.props.common.auth && this.props.common.authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_PAGE_CWA);
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_OPENED, { from: "review", os: localStorageIsTwa() ? "twa" : "web" });
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(openLocationSearchBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onAddEditDetails = (type) => {
		if (this.props.common.auth && this.props.common.authCheck) {
			this.props.dispatch(setSavedPlaceLocationPointer());
			const place = {
				address: this.state.deliveringToPlace?.location?.address || this.props.jobLocations?.jobLocations[0].address,
				lat: this.state.deliveringToPlace?.location?.lat || this.props.jobLocations?.jobLocations[0].lat,
				lng: this.state.deliveringToPlace?.location?.lng || this.props.jobLocations?.jobLocations[0].lng,
				title: this.state.deliveringToPlace?.location?.title || this.props.jobLocations?.jobLocations[0].title,
			};
			this.props.dispatch(savedPlaceSelectedAddress(createUpdatedSavedPlaceObject(place, this.state.deliveringToPlace)));
			this.props.dispatch(editLocationSavedPlace(this.state.deliveringToPlace));
			this.props.dispatch(setGooglePlaceObject(this.state.deliveringToPlace?.location));
			this.props.dispatch(savedPlacesSetType(this.state.deliveringToPlace?.type));
			this.props.dispatch(setNavigationBlackBox({ from: "review-mymall", goBack: "/review-mymall", action: "editAddress" }));
			this.props.dispatch(push("/saved-places/search/edit"));
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onUpdateAddress = () => {
		if (this.props.common.auth && this.props.common.authCheck) {
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(setNavigationBlackBox({ from: "review-mymall", goBack: "/review-mymall", action: "updateAddress" }));
			this.props.dispatch(savedPlacesSetType(SAVED_PLACE_HOME_TYPE));
			this.props.dispatch(push("/saved-places/search"));
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.setState({ actionAfterLogin: "onUpdateAddress" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onRetry = () => {
		if (this.props.common.auth && this.props.common.authCheck) {
			this.props.dispatch(checkRetryWithCard());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	goToMap = () => {
		this.props.dispatch(searchNavigateToMapFromReviewMymall());
	};

	handleChangeLocationBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.props.dispatch(closeLocationSearchBottomSheet());
		}
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "101",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<Fragment>
				<div className="review-container" id="review-container">
					{/* {this.state.isShowProductInfoPopup ? ( */}
					<BottomSheet
						indicator={true}
						isOpen={this.state.isShowProductInfoPopup}
						styles={bottomSheetStyle}
						closeSheet={this.isToggleSheet}
						classes="product-info-bottom-sheet"
						onChangeOverlay={this.handleChangeBookingInfoBottomSheet}
					>
						<BookingInfoModal
							hasFadePropProductInfo={this.state.hasFadePropProductInfo}
							hasFadePropSubtitleInfo={this.state.hasFadePropSubtitleInfo}
							closeModal={this.onCloseProductInfo}
							productDetail={this.props.product}
						/>
					</BottomSheet>
					{/* // ) : null} */}
					<div className="review-product">
						<TitleBar
							leftIcon={backButton}
							rightIcon={chatSupport}
							handleClickLeftIcon={this.goHome}
							handleClickRightIcon={this.onSupport}
							title="Review"
						/>
						{!_.isEmpty(this.props.product) ? (
							<MymallProductInfo
								icon={this.props.product.icon}
								commercialName={this.props.product.commercialName}
								descriptionTitle={this.props.product.descriptionTitle}
								hasIconInfo={true}
								showInfoProductModal={this.onOpenProductInfoModal}
								hasFadePropProduct={this.state.hasFadePropProduct}
								hasFadePropSubtitle={this.state.hasFadePropSubtitle}
							/>
						) : (
							<div className="booking-skeleton">
								<LinearSkeleton
									devicewidth={
										_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
									}
								/>
							</div>
						)}
					</div>

					<DeliveringTo
						title={this.state.deliveringToPlace?.title}
						note={this.state.deliveringToPlace?.note}
						isSavedPlace={this.state.deliveringToPlace?.type}
						dontCallMe={this.state.deliveringToPlace?.phoneCall === "DO_NOT_CALL_ME"}
						detail={this.state.deliveringToPlace?.location?.data}
						location={this.state.deliveringToPlace?.location ? this.state.deliveringToPlace?.location : this.state.deliveringToPlace}
						onChangeAddress={this.onChangeAddress}
						onAddEditDetails={this.onAddEditDetails}
						onUpdateAddress={this.onUpdateAddress}
						hasSavedPlace={
							!_.isNull(this.props.savedPlaces.savedHome) ||
							!_.isNull(this.props.savedPlaces.savedWork) ||
							this.props.savedPlaces?.all?.extra?.length
						}
					/>

					<div id="how-it-works">
						<HowItWorksCarousel />
					</div>

					<PaymentMethod
						billing={this.props.billing}
						primaryPayment={this.props.common.primaryPayment}
						loading={this.props.billing.paymentLoading}
						newPrice={this.props.newPrice}
						price={this.props.prices}
						onTopUp={this.checkOpenWichBottomSheet}
						onChangePrimary={this.onChangePrimary}
						onRetry={this.onRetry}
						isAuthenticated={this.props.common.auth && this.props.common.authCheck}
					/>

					<div id="service-fee" style={{ margin: "24px 20px" }}>
						<ServiceFee
							newPrice={this.props.newPrice}
							amountOff={!_.isNull(this.props.amountOff) && this.props.amountOff}
							percentOff={!_.isNull(this.props.percentOff) && this.props.percentOff}
							promoCode={this.props.promoCode}
							handleLinkRemovePromoCode={this.handleLinkRemovePromoCode}
							handleClickEnterPromoCode={() => {
								if (this.props.common.authCheck && this.props.common.auth) {
									LOG_EVENT.logEvent(LOG_EVENT.DISCOUNT_SHEET_OPENED_CWA);
									this.props.dispatch(togglePromoCodeBottomSheet());
								} else {
									LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
									this.props.dispatch(toggleLogInBottomSheet());
								}
							}}
							time={this.props.formattedTime}
							price={this.props.prices}
							currency={!_.isEmpty(this.props.common.client) ? this.props.common.client.currency.signText : "₱"}
						/>
					</div>
					<BookingButton
						onCancelBooking={() => this.props.dispatch(toggleCancelBookingBottomSheet())}
						onClick={this.onBookingRequest}
						isInstantMatch={this.props.prices.isInstantMatch}
						onScheduleBooking={this.onScheduleBooking}
						mode={this.props.product.mode}
						loading={this.props.getJobLoading}
						mymall
					/>
				</div>
				<BookingOverlaidButton
					onCancelBooking={() => this.props.dispatch(toggleCancelBookingBottomSheet())}
					onClick={this.onBookingRequest}
					isInstantMatch={this.props.prices.isInstantMatch}
					onScheduleBooking={this.onScheduleBooking}
					mode={this.props.product.mode}
					loading={this.props.getJobLoading}
					isFadeIn={this.state.isFadeIn}
					newPrice={this.props.newPrice}
					amountOff={!_.isNull(this.props.amountOff) && this.props.amountOff}
					percentOff={!_.isNull(this.props.percentOff) && this.props.percentOff}
					promoCode={this.props.promoCode}
					handleLinkRemovePromoCode={this.handleLinkRemovePromoCode}
					handleClickEnterPromoCode={() => {
						if (this.props.common.authCheck && this.props.common.auth) {
							LOG_EVENT.logEvent(LOG_EVENT.DISCOUNT_SHEET_OPENED_CWA);
							this.props.dispatch(togglePromoCodeBottomSheet());
						} else {
							LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
							this.props.dispatch(toggleLogInBottomSheet());
						}
					}}
					time={this.props.formattedTime}
					price={this.props.prices}
					currency={!_.isEmpty(this.props.common.client) ? this.props.common.client.currency.signText : null}
					currencyIcon={!_.isEmpty(this.props.common.client) ? this.props.common.client.currency.signSvg : null}
				/>
				{this.props.isOpenPromoCodeBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenPromoCodeBottomSheet}
						styles={bottomSheetStyle}
						classes="promo-code-bottom-sheet"
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(togglePromoCodeBottomSheet());
								this.props.dispatch(setPromoCode(null));
								this.props.dispatch(setNewPrice(null));
								this.props.dispatch(setAmountOff(null));
								this.props.dispatch(setPercentOff(null));
								this.props.dispatch(removePromoCode(false));
							}
						}}
					>
						<PromoCodeBottomSheet
							applyCode={this.applyCode}
							loading={this.props.promoCodeLoading}
							handleRemovePromoCode={this.handleRemovePromoCode}
							handleCancelPromoCode={this.handleCancelPromoCode}
							value={!_.isNull(this.props.promoCode) ? this.props.promoCode : ""}
							mymall
						/>
					</BottomSheet>
				)}

				{this.props.isOpenPaymentMethodsBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenPaymentMethodsBottomSheet}
						styles={bottomSheetStyle}
						classes="payment-method-bottom-sheet"
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(togglePaymentMethodsBottomSheet());
						}}
					>
						{!_.isEmpty(this.props.prices) && !_.isNull(this.props.billing.balance) ? (
							<BookingPaymentBottomSheet
								onAddMethod={this.completePaymentMethod}
								price={this.props.prices.items[this.props.prices.minCubeNumbers].price.displayValue}
								paymentMethods={this.props.paymentMethods}
								insufficientBalance={this.props.billing.balance[0].balance <= 0}
							/>
						) : null}
					</BottomSheet>
				)}

				{this.props.isOpenRejectedPaymentBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenRejectedPaymentBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleRejectedPayment());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<RejectedPaymentMethod
							showTopUpBottomSheet={() => {
								this.props.dispatch(toggleTopUpBottomSheet());
								this.props.dispatch(toggleRejectedPayment());
								this.props.dispatch(retryWithTopUp());
							}}
							showPaymentMethodsBottomSheet={() => {
								this.props.dispatch(togglePaymentMethodsBottomSheet());
								this.props.dispatch(toggleRejectedPayment());
								this.props.dispatch(retryWithTopUp());
								this.props.dispatch(retryWithCard());
							}}
							navigateToSupport={this.onSupport}
						/>
					</BottomSheet>
				)}

				{this.props.isOpenRetryFailedBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenRetryFailedBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleRetryFailed());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<RetryFailedBottomSheet
							showPaymentMethodsBottomSheet={() => {
								this.props.dispatch(togglePaymentMethodsBottomSheet());
								this.props.dispatch(toggleRetryFailed());
								this.props.dispatch(retryWithTopUp());
								this.props.dispatch(retryWithCard());
							}}
							navigateToSupport={this.onSupport}
						/>
					</BottomSheet>
				)}

				{this.props.isShowTopUpBottomSheet && (
					<BottomSheet
						classes="topup-mykoin-bottom-sheet"
						isOpen={this.props.isShowTopUpBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleTopUpBottomSheet());
						}}
					>
						<TopUpBottomSheet setTopUpValue={this.setTopUpValue} />
					</BottomSheet>
				)}

				{this.props.isOpenCancelBookingBottomSheet && (
					<BottomSheet
						classes="cancel-booking-container"
						isOpen={this.props.isOpenCancelBookingBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleCancelBookingBottomSheet());
						}}
					>
						<CancelBookingBottomSheet
							navigateToSupport={this.onSupport}
							confirmCancelBooking={() => {
								LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DID_CANCEL_CWA, { from: "review" });
								this.props.dispatch(appGoHome());
								this.props.dispatch(willUnMountReviewBooking());
							}}
						/>
					</BottomSheet>
				)}

				{this.props.common.toggleChatWithSupport && (
					<BottomSheet
						isOpen={this.props.common.toggleChatWithSupport}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						classes="chat-with-support-bottom-sheet"
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleChatWithSupportBottomSheet());
						}}
					>
						<ChatWithSupportBottomSheet navigateToSupport={this.onSupport} />
					</BottomSheet>
				)}

				{this.props.common.logInBottomSheet ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.common.logInBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							//false => phone | true => showing verification
							if (this.props.common.enteringVerificationCode) {
								LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "code" });
							} else {
								LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "phone" });
							}
							if (!isOpen) this.props.dispatch(toggleLogInBottomSheet());
						}}
					>
						<LogInBottomSheet />
					</BottomSheet>
				) : null}

				{this.props.common.registerBottomSheet ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.common.registerBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={false}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(toggleRegisterBottomSheet(false));
								localStorage.removeItem("token");
								localStorage.removeItem("username");
								localStorage.removeItem("countryFlag");
							}
						}}
					>
						<RegisterForm />
					</BottomSheet>
				) : null}

				{this.props.scheduleBookingBottomSheet ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.scheduleBookingBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={false}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(toggleScheduleBooking());
							}
						}}
					>
						<ScheduledMymallBookingBottomSheet location={this.props.location} />
					</BottomSheet>
				) : null}

				{this.props.billing.isGcashSupported ? (
					<>
						<BottomSheet
							isOpen={this.props.billing.gcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleGcashBottomSheet());
							}}
						>
							<BillingGCash />
						</BottomSheet>
						<BottomSheet
							isOpen={this.props.billing.verifyingGcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleVerifyingGcashBottomSheet());
							}}
						>
							<BillingVerifyingGCash />
						</BottomSheet>
					</>
				) : null}

				{this.props.locationSearchBottomSheet ? (
					<BottomSheet
						isOpen={this.props.locationSearchBottomSheet}
						classes="choose-location-bottom-sheet"
						styles={{
							zIndex: "100",
							borderRadius: "20px",
							bottom: "0",
							position: "fixed",
							marginLeft: "auto",
							marginRight: "auto",
							maxWidth: "414px",
						}}
						config={{ marginTop: 50, fullScreen: true }}
						indicator={true}
						onChangeOverlay={this.handleChangeLocationBottomSheet}
					>
						<SavedPlacesBottomSheet noMap pageName={this.props.match.url.split("/")[1]} />
					</BottomSheet>
				) : null}

				{this.props.savedPlaces.isOutOfGeoBottomSheet && (
					<BottomSheet
						isOpen={this.props.savedPlaces.isOutOfGeoBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleOutOfGeo());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<OutOfGeoBottomSheet
							onChangeAddress={() => {
								this.props.dispatch(toggleOutOfGeo());
								this.onChangeAddress();
							}}
						/>
					</BottomSheet>
				)}

				{this.props.common.gcashFailedBottomSheet ? (
					<BottomSheet
						isOpen={this.props.common.gcashFailedBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(closeGcashFailedBottomSheet());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<GcashFailedBottomSheet />
					</BottomSheet>
				) : null}

				{/* {!_.isNull(this.props.common.tourSteps) && !this.props.common.loading && (
					<Tour
						onRequestClose={() => this.props.dispatch(toggleTour(false))}
						steps={this.props.common.tourSteps}
						startAt={this.props.common.currentStep}
						isOpen={this.props.common.isOpenTour}
						maskClassName="mask"
						className="onboard-message-box"
						rounded={5}
						maskSpace={5}
						showCloseButton={false}
						showNavigation={false}
						showNumber={false}
						closeWithMask={false}
						accentColor={"#5cb7b7"}
						lastStepNextButton={
							<p
								className="next-button"
								onClick={() => {
									if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(this.state.pageName)) {
										this.props.dispatch(tourPageisDone("reviewBottomSheet"));
									} else {
										this.props.dispatch(tourPageisDone(this.state.pageName));
										document.getElementById("review-container").style.overflowY = "auto";
									}
								}}
							>
								DONE!
							</p>
						}
						disableInteraction
						getCurrentStep={(curr) => this.setCurrentStep(curr)}
						nextButton={<p className="next-button">NEXT</p>}
						prevButton={
							<p
								className="skip-button"
								onClick={() => {
									if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(this.state.pageName)) {
										this.props.dispatch(skipShowingTour("reviewBottomSheet"));
									} else {
										this.props.dispatch(skipShowingTour(this.state.pageName));
										document.getElementById("review-container").style.overflowY = "auto";
									}
								}}
							>
								SKIP TUTORIAL
							</p>
						}
					/>
				)} */}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
	home: state.home,
	savedPlaces: state.savedPlaces,
	common: state.common,
	map: state.map,
	location: {
		jobLocations: [
			{
				lat: state.map.location.lat,
				lng: state.map.location.lng,
				address: state.map.location.address,
				title: state.map.location.title,
			},
		],
	},
	locationTitle: state.map.location.title || state.map.location.address,
	billing: state.billing,
	router: state.router
});

export default connect(mapStateToProps)(MymallReviewBooking);