import { initializeApp } from "firebase/app";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";

// const firebaseConfigMykuya = {
// 	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
// 	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
// 	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
// 	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// 	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
// 	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
// 	appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
// 	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// }

const firebaseConfigMykuya = {
	apiKey: "AIzaSyDpwddyUJzGYyH_E3etjox34WfRS-wUv5U",
	authDomain: "mykuya-app.firebaseapp.com",
	databaseURL: "https://mykuya-app.firebaseio.com",
	projectId: "mykuya-app",
	storageBucket: "mykuya-app.appspot.com",
	messagingSenderId: "514358143092",
	appId: "1:514358143092:web:aadfba9b14651c2f180921",
	// measurementId: "G-PPQNGC9HGV",
}

const firebase = initializeApp({...firebaseConfigMykuya});

const remoteConfig = getRemoteConfig(firebase);

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "stage") {
	remoteConfig.settings.minimumFetchIntervalMillis = 0;
}

remoteConfig.defaultConfig = {
	"mymall_priceModifier": 0,
	"feedbackViewType": "segment"
};

export default firebase;
export { remoteConfig };

export const getMymallPriceFromFirebase = (remoteConfig) => {
	return fetchAndActivate(remoteConfig)
		.then(() => {
			const val = getValue(remoteConfig, "mymall_priceModifier");
			return +val?._value;
		})
		.catch((err) => {
		});
}

export const getMymallScheduleTimeFirebase = (remoteConfig) => {
	return fetchAndActivate(remoteConfig)
		.then(() => {
			const scheduleTimeFrom = getValue(remoteConfig, "mymall_scheduleTimeFrom");
			const scheduleTimeTo = getValue(remoteConfig, "mymall_scheduleTimeTo");
			const scheduleTimeLimit = getValue(remoteConfig, "mymall_scheduleTimeLimit");
			const scheduleTimeStepper = getValue(remoteConfig, "mymall_scheduleTimeStepper");
			return {
				scheduleTimeFrom: + scheduleTimeFrom?._value,
				scheduleTimeTo: + scheduleTimeTo?._value,
				scheduleTimeLimit: + scheduleTimeLimit?._value,
				scheduleTimeStepper: + scheduleTimeStepper?._value,
			}
		})
		.catch((err) => {
		});
}

export const getComplimentsViewFromFirebase = (remoteConfig) => {
	return fetchAndActivate(remoteConfig)
		.then(() => {
			const val = getValue(remoteConfig, "feedbackViewType");
			return val?._value;
		})
		.catch((err) => {});
};
