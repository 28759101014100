import React from "react";

const classNames = require("classnames");
export default function JobsBoxHeadingStatus(props) {
	return props.state === "Started" || props.state === "Matched" || props.state === "Upcoming" ||
	(props.state === "Queued" && props.mode.includes("MY_MALL")) ? (
		<div className={classNames("Jobs-box-heading-status", props.state === "Started" ? "green" : "orange")}>
			<p className="Status_State">
				{props.state === "Started" ? "IN PROGRESS" : props.state === "Upcoming" ? "MATCHED" :
					(props.state === "Queued" && props.mode.includes("MY_MALL")) ? "MATCHED" : props.state.toUpperCase()}
			</p>
		</div>
	) : props.state === "Scheduled" || props.state === "Queued" || props.state === "Matching" ? (
		<div className="Jobs-box-heading-status yellow">
			<p className="Status_State">OPEN</p>
		</div>
	) : null;
}