import React, { Fragment } from 'react';

import LocationSearchSuggestionsItem from './LocationSearchSuggestionsItem';

export default function LocationSearchSuggestions(props) { 
    let search = [];
    search = props.places.map((place, index) => {
        return place && <LocationSearchSuggestionsItem
            isSearching={props.isSearching}
            isSavedPlaces={props.isSavedPlaces}
            onSearchClick={props.onSearchClick}
            title={place.description} key={index} index={index}
            address={''} />
    });
    return (
		<Fragment>
			<div className="location-search-suggestions">
				{search}
			</div>
		</Fragment>
	);
}