import * as DISPATCH_STATE from "../mapChangeState";
import * as CONSTANT_ACTIONS from "../mapConstant";
// import * as LOG_EVENT from '../../../../analytics';

import { locationStorageSetData, locationStorageGetData, localStorageIsTwa } from "../../../../functions/local-storage";
import { appLoadingBegin, appLoadingEnd } from "../../commonActions/commonChangeState";
import { navigatorGetCurrentLocation, getLocationFromPostionNavigator, geolocationError } from "../../../../functions/navigator-getcurrent-location";
import { createMarkerObject } from "../../../../functions/create-marker";
import { getExactAddress, getDetailPlaces } from "../../../../functions/nearby-search";
import {
	createPlaceObjectFromGoogleNearsearchWithCurrentLocation,
	createPlaceObjectForCurrentLocation,
} from "../../../../functions/create-place-object-from-google-suggestions";
import { createMarkerSetLocationPlacesWithMap } from "./map-marker";
import { updatedLocationStillHere } from "../../homeActions";
import { homeProductListShouldUpdated } from "../../homeActions/homeChangeState";
import { toggleSelectCityModal } from "../../registerActions/registerChangeState";
import CurrentHolderImg from "../../../../../assests/icons/current-holder.png";
import * as LOG_EVENT from "../../../../analytics/index";

/**
 * Set source location to localstorage for useing later
 * @param {*} data
 */
export const mapSetCurrentLocationLocalstorage = (data) => {
	return (dispatch) => {
		locationStorageSetData(data);
		dispatch(DISPATCH_STATE.mapSetCurrentLocationLocalstorageDispatch());
		dispatch(homeProductListShouldUpdated(true));
	};
};

/**
 * Put source location of user from localstorage and put it in map state
 * @param {*} data
 */
export const mapGetcurrentLocationLocalstorage = (data) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapGetCurrentLocationLocalstorageDispatch(locationStorageGetData(getState)));
	};
};

/**
 * Try for get current location from browser api for move map to current for setting marker
 */
export function mapGetCurrentLocation() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			// Get user's location
			if ("geolocation" in navigator) {
				dispatch(DISPATCH_STATE.gettingLocationLoading(true));
				navigatorGetCurrentLocation(
					(position) => {
						dispatch(createCurrentLocationMarker(getLocationFromPostionNavigator(position)));
						dispatch(DISPATCH_STATE.gettingLocationLoading(false));
						dispatch(DISPATCH_STATE.mapGetCurrentLocationDispatch());
						resolve(true);
					},
					dispatch,
					DISPATCH_STATE.gettingLocationLoading,
				);
			} else {
				dispatch(DISPATCH_STATE.gettingLocationLoading(false));
				dispatch(DISPATCH_STATE.mapGetCurrentLocationDispatch());
				reject(geolocationError());
			}
		});
	};
}
/**
 * Try for get current location from browser api without move map to current for setting marker
 */
export function mapGetCurrentLocationWithoutMap() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			// Get user's location
			dispatch(DISPATCH_STATE.gettingLocationWithoutMapLoading(true));
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						console.log(position, "pos")
						LOG_EVENT.logEvent(LOG_EVENT.INITIAL_LOCATION_PICKED_CWA, {
							location: "locationPermission",
							os: localStorageIsTwa() ? "twa" : "web",
							autoGenerated: false
						});
						try {
							dispatch(createMarkerSetLocationPlacesWithMap(getLocationFromPostionNavigator(position), {}));
						} catch (err) {
							dispatch(DISPATCH_STATE.gettingLocationWithoutMapLoading(false));
							dispatch(toggleSelectCityModal());
							throw(err);
						}
						dispatch(DISPATCH_STATE.gettingLocationWithoutMapLoading(false));
						resolve(true);
					},
					(err) => {
						if (err.code === 1 && err.message === "User denied Geolocation") {
							dispatch(DISPATCH_STATE.userDeniedGeolocationAccess());
						}
						dispatch(toggleSelectCityModal());
						dispatch(DISPATCH_STATE.gettingLocationWithoutMapLoading(false));
					},
				);
			} else {
				dispatch(DISPATCH_STATE.gettingLocationWithoutMapLoading(false));
				dispatch(DISPATCH_STATE.mapGetCurrentLocationDispatch());
				reject(geolocationError());
			}
		});
	};
}

/**
 * When user allows to get current location from browser api we show marker for showing current location exactly
 * @param {*} position
 */
export function createCurrentLocationMarker(position) {
	return (dispatch, getState) => {
		getState().map.googleMap.panTo(position);
		dispatch(DISPATCH_STATE.mapCreateCurrentMarker(createMarkerObject(getState, position, CurrentHolderImg)));
	};
}

/**
 * Try to Get Current location of user via browser api
 */
export function mapGetJustCurrentLocation() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			// Get user's location
			if ("geolocation" in navigator) {
				dispatch(appLoadingBegin());
				navigatorGetCurrentLocation(
					(position) => {
						dispatch(fetchSearchPlacesGoogleByGetCurrent(getLocationFromPostionNavigator(position)));
						dispatch(appLoadingEnd());
						dispatch(updatedLocationStillHere());
						dispatch(DISPATCH_STATE.mapGetCurrentLocationDispatch());
						resolve(true);
					},
					dispatch,
					appLoadingEnd,
				);
			} else {
				dispatch(appLoadingEnd());
				dispatch(DISPATCH_STATE.mapGetCurrentLocationDispatch());
				reject(geolocationError());
			}
		});
	};
}

/**
 * Fetch google places search result for put title of first search result in current location
 * @param {*} location
 */
export function fetchSearchPlacesGoogleByGetCurrent(location) {
	return (dispatch, getState) => {
		getExactAddress(location, (results, status) => {
			const index = CONSTANT_ACTIONS.GOOGLE_PLACES_NEARBY_SEARCH_ACCEPT_INDEX_OF_ARRAY;
			if (status === "OK") {
				//get detail address of client location with place_id
				getDetailPlaces(getState, results[index].place_id, (result, status) => {
					dispatch(DISPATCH_STATE.mapGetCurrentLocationSet(createPlaceObjectFromGoogleNearsearchWithCurrentLocation(getState, result)));
					dispatch(mapSetCurrentLocationLocalstorage(createPlaceObjectForCurrentLocation(result, index)));
					// LOG_EVENT.logEvent(LOG_EVENT.GOOGLE_PLACE_SERVICE_BY_GET_CURRENT_CWA);
				});
			} else {
				console.error("GOOGLE_PLACE_SERVICE_BY_GET_CURRENT_CWA", results, status);
				// TODO[emma]: set log for bad status
				// LOG_EVENT.logEvent(LOG_EVENT.GOOGLE_PLACE_SERVICE_BY_GET_CURRENT_CWA);
			}
		});
	};
}

export const setDefaultLocation = () => {
	return (dispatch, getState) => {
		locationStorageSetData(getState().map.defaultPickupLocation);
		dispatch(DISPATCH_STATE.setDefaultPickupLocation(getState().map.defaultPickupLocation));
	};
};
