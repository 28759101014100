import { push } from 'connected-react-router';
import _ from 'lodash';
import {toast} from "react-toastify";

import * as DISPATCH_STATE from './jobsChangeState';
import { appGoHome, appFetchFailure } from '../commonActions';
import { saveLastPathName } from "../commonActions/commonChangeState";
import { onCloseLivePartnerLocation } from '../chatActions';
import { postRequestNowBooking } from '../bookingActions/actions/booking-service';
import { bookingRemovePrice } from "../bookingActions/bookingChangeState";
import { unMountJobMatching, cancelSetInterval } from "./actions/jobs-matching";
import { fetchStateOfJob } from './actions/jobs-matching';
import {cancelJobRequestBody} from "../jobsActions/jobs-gql";
import { GraphRequest } from "../../../../axios";
/**
 * When user come out from list of jobs
 */
export const jobChatHistoryWillUnMount = () => {
  return (dispatch, getState) => {
    clearInterval(getState().jobs.chatJobStateInterval);
    dispatch(onCloseLivePartnerLocation());
    dispatch(DISPATCH_STATE.jobChatHistoryWillUnMountDispatch());
    //empty prices on booking
    dispatch(bookingRemovePrice());
  }
}
/**
 * check job matching state
 */
export const checkJobMatchingState = () => {
  return (dispatch, getState) => {
    if (!getState().jobs.jobMatchingState) {
      dispatch(appGoHome());
    }
  }
}
/**
 * 
 * @param {*} job 
 */
export const initJobByMatchingStatus = (job) => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.initJobByMatchStatus(job));
    // dispatch(push('/jobs/' + getState().booking.product.id + '/matching'));
  }
}
export const updateJobByMatchingStatus = (job) => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.updateJobByMatchStatus(job));
  }

}
export const setIsOutOfWorkingHours = (isOutOfWorkingHours) => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.setIsOutOfWorkingHours(isOutOfWorkingHours));
  }
}
/**
 * Navigate user from job info either chat or receipt
 * @param {*} link 
 */
export const jobInformationNavigate = (link) => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.jobInformationNavigateToEitherReceiptOrChat());
    dispatch(push(getState().router.location.pathname + link.link));
  }
};
/**
 *  navigate user to job chat and get started
 */
export const onletsGetStarted = (jobId) => {
  return (dispatch, getState) => {
    dispatch(push(`/jobs/${jobId ? jobId : getState().jobs.job.id}/chat`))
    dispatch(onCloseLivePartnerLocation());
    dispatch(checkForActiveJobCount());
  }
}

export const onChatMenu = () => {
	return (dispatch, getState) => {
		dispatch(push(`/jobs/${getState().jobs.job.id}/chat/menu`));
	}
}

export const navigateToPartnerLivaLocation = () => {
  return (dispatch, getState) => {
    dispatch(saveLastPathName(getState().router.location.pathname));
    dispatch(push(`/jobs/${getState().jobs.job.id}/partner-location`));
  }
}
/**
 * When user accept partner in matched state in matching page, this function check active jobs count number
 */
export const checkForActiveJobCount = () => {
  return (dispatch, getState) => {
    if (getState().jobs.activeJobsCount === 0) {
      dispatch(DISPATCH_STATE.jobsActiveCountIncrementOne());
    }
  }
}

export const cancelRequestStartToCount = () => {
  return (dispatch, getState) => {
    if (!_.isNull(getState().jobs.cancelRequestInterval)) {
      dispatch(onClearIntervalCancelRequest());
    }
    let countNumber = getState().jobs.cancelRequest;
    let interval = setInterval(() => {
      if (countNumber !== 0) {
        countNumber -= 1;
        dispatch(DISPATCH_STATE.cancelRequestReduceCount(countNumber));
      } else {
        dispatch(onClearIntervalCancelRequest());
        dispatch(postRequestNowBooking(getState().jobs.job))
          .then((res) => {
            dispatch(fetchStateOfJob());
          })
      }
    }, 1000);
    dispatch(DISPATCH_STATE.cancelRequestInterval(interval));
  }
}
/**
 * clear interval of timing to canncel request
 */
export const onClearIntervalCancelRequest = () => {
  return (dispatch, getState) => {
    clearInterval(getState().jobs.cancelRequestInterval);
    dispatch(DISPATCH_STATE.clearIntervalCancelRequestInterval());
  }
}
/**
 * Set myjobs location pointer
 */
export const setMyJobsLocationPointer = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.setMyJobsLocationPointer(getState().router.location.pathname));
    }
}

export const goBack = () => {
	return (dispatch, getState) => {
		if (_.isNull(getState().jobs.lastLocationPointer)) {
      dispatch(appGoHome());
    } else {
      dispatch(push(getState().jobs.lastLocationPointer));
      dispatch(DISPATCH_STATE.setMyJobsLocationPointer(null));
    }
	};
};

export const cancelOpenJob = (jobId) => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.cancelOpenJobBegin());
    dispatch(DISPATCH_STATE.cancelJobLoading(true));
    const cancelJob = {
      query: cancelJobRequestBody,
      variables: {
        jobId: jobId ? jobId : getState().jobs.job.id,
        cancelReason: {
          reason: "",
          explanation: "",
        },
      },
    };
    // cancelSetInterval;
    GraphRequest.all(cancelJob)
      .then((res) => {
        dispatch(DISPATCH_STATE.cancelJobLoading(false));
        if (!_.isNull(res.data.data) && res.data.data.cancelJob) {
          dispatch(cancelSetInterval()); // cancel interval
          dispatch(unMountJobMatching());
          dispatch(appGoHome())
        } else {
          dispatch(appFetchFailure(res.data.errors[0].message));
          toast(`${res.data.errors[0].message}`, {
            position: "bottom-center",
            autoClose: 2000,
            limit: 1,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
            hideProgressBar: true,
            closeOnClick: false,
          });
        }

      }).catch(error => {
        dispatch(DISPATCH_STATE.cancelJobLoading(false));
		    dispatch(appFetchFailure(error));
      })
  }
}