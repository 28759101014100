import React, {Fragment} from 'react';

import downImg from '../../../assests/images/jobs/thumb-down-gold.svg';
import upImg from '../../../assests/images/jobs/thumb-up-gold.svg';

import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

const classNames = require('classnames');

export default function JobRatingThumb(props) {
	let img = props.type === "up" ? upImg : downImg;
	return (
		<Fragment>
			<div
				onClick={props.onClick}
				className={classNames({
					Job_Rating_Thumb: true,
					Job_Rating_Thumb_Selected: props.thumb === props.rate,
					Job_Rating_Thumb_Change: props.thumb !== 0,
					[props.type]: true,
				})}
			>
				{props.loading && props.thumb === props.rate ? (
					<div className="next-button-loading">
						<WhiteLoading />
					</div>
				) : (
					<img src={img} alt="" className={classNames("img-responsive", props.type)} />
				)}
			</div>
		</Fragment>
	);
}
