import React from 'react';
import _ from 'lodash';

import MyKoinSvg from '../../../../assests/images/billing/my-koin.svg';
import { cardType } from "../../../../utils/constants/constant";

export default function JobReceiptPaymentMethod(props) {
    return (
		<div className="job-receipt-payment-method-container">
			{props.paymentDetails.mykoinUsage || props.paymentDetails.cardUsage ? (
				<div className="job-receipt-payment-method">
					{props.paymentDetails.mykoinUsage > 0 && (
						<div className="card-container">
							<div className="icon-container">
								<img src={MyKoinSvg} alt="MyKoinSvg" className="mykoin" />
							</div>
							<p>
								Paid {props.jobCost.currency}{" "}
								{props.paymentDetails.mykoinUsage % 1 !== 0
									? Number(props.paymentDetails.mykoinUsage).toFixed(2)
									: props.paymentDetails.mykoinUsage}{" "}
								via mykoins
							</p>
						</div>
					)}
					{props.paymentDetails.cardUsage > 0 && !_.isNull(props.cardDetail) && (
						<div className="card-container">
							<div className="icon-container">
								<img src={props.cardDetail.icon} alt="cardImg"
									 className={props.cardDetail.type}
									 // className={cardType[props.cardDetail.type?.toUpperCase()]}
								/>
							</div>
							<p>
								Paid {props.jobCost.currency}{" "}
								{props.paymentDetails.cardUsage % 1 !== 0
									? Number(props.paymentDetails.cardUsage).toFixed(2)
									: props.paymentDetails.cardUsage}{" "}
								via {props.cardDetail.variant}
								{props.cardDetail.type === "mc" || props.cardDetail.type === "visa"
									? `**** ${props.cardDetail.cardNumber}`
									: props.cardDetail.name}
								{/*{cardType[props.cardDetail.type?.toUpperCase()] === "mc" || cardType[props.cardDetail.type?.toUpperCase()] === "visa"*/}
								{/*	? `**** ${props.cardDetail.cardNumber}`*/}
								{/*	: props.cardDetail.name}*/}
							</p>
						</div>
					)}
				</div>
			) : null}
		</div>
	);
}