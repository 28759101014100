import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "connected-react-router";
import { parse } from "query-string";
import { toast } from "react-toastify";	
import Tour from "reactour";

import * as LOG_EVENT from "../../../utils/analytics/index";
import {
	reviewBookingPriceAndLocationExist,
	backToBookingPage,
	navigateToRequestPage,
	requestNowBooking,
	checkRejectedPayment,
	topUpMyKoin,
	applyPromoCode,
	checkRetryWithCard,
	setReviewBookingConfigTour,
	setPaymentBottomSheetTour, bookingXenditTopUpMyKoin,
} from "../../../utils/redux/actions/bookingActions";
import {
	changeBookingTimeFormat,
	setIsInstantMatch,
	togglePaymentMethodsBottomSheet,
	toggleRejectedPayment,
	toggleTopUpBottomSheet,
	togglePromoCodeBottomSheet,
	toggleScheduleBooking,
	toggleCancelBookingBottomSheet,
	willUnMountReviewBooking,
	retryWithTopUp,
	setDefaultRetryWithTopUp,
	retryWithCard,
	setPercentOff,
	setAmountOff,
	setNewPrice,
	setPromoCode,
	changePromoCode,
	removePromoCode,
	setPriceWithAplliedPromoCode,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import BookingInfoModal from "../BookingInfoModal/BookingInfoModal";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import { jobTime } from "../../../utils/functions/job-time-convert";
import {
	appGoHome,
	fetchClient,
	checkIsTopUpSuccess,
	tourPageisDone,
	checkCurrentStep,
	skipShowingTour,
	removeQueryParam,
	addAnimateToText, checkXenditMinLimit,
} from "../../../utils/redux/actions/commonActions";
import {
	toggleChatWithSupportBottomSheet,
	storeBookingUrl,
	getPrimaryPayment,
	toggleLogInBottomSheet,
	toggleTour,
	unMountTourConfig,
	openGcashFailedBottomSheet,
	closeGcashFailedBottomSheet,
	xenditMinLimitBottomSheet,
	toggleQrCodeBottomSheet,
} from "../../../utils/redux/actions/commonActions/commonChangeState";
import {
	fetchMethodsOfPayment,
	availableMethods,
	fetchXenditAndBalance, billingXenditTopUpMyKoin,
} from "../../../utils/redux/actions/billingActions";
import {
	supportGcash,
	toggleGcashBottomSheet,
	toggleVerifyingGcashBottomSheet,
	paymentMethodsLoading,
} from "../../../utils/redux/actions/billingActions/billingChangeState";
import {
	setReviewBookingLocalStorage,
	localStorageSetCurrentBalance,
	localStorageGetCurrentBalance,
	getReviewBookingLocalStorage,
	setStoredUrlLocalStorage,
	getStoredUrlLocalStorage,
	localStorageGetTourPageViwed,
	getAddingGcashLocalStorage,
	removeAddingGcashLocalStorage,
} from "../../../utils/functions/local-storage";

import TitleBar from "../../../organisms/title-bar";
import BookingService from "../BookingService/BookingService";
import JobLocations from "../JobLocations/JobLocations";
import BookingPriceTime from "../BookingPriceTime/BookingPriceTime";
import ReviewBookingNote from "../BookingNote/ReviewBookingNote";
import BookingPayment from "../BookingPayment/BookingPayment";
import BookingPaymentBottomSheet from "../BookingPayment/BookingPaymentBottomSheet";
import BookingButton from "../BookingButton/BookingButton";
import RejectedPaymentMethod from "../RejectedPaymentMethod/RejectedPaymentMethod";
import HowItWorks from "../HowItWorks/HowItWorks";
import PromoCodeBottomSheet from "../PromoCodeBottomSheet/PromoCodeBottomSheet";
import UndoButton from '../../LocationSearch/LocationBookmarked/ToastLocationBookmarked';
import LogInBottomSheet from "../../LogInBottomSheet/LogInBottomSheet";
import ScheduledBookingBottomSheet from "../../job-history/ScheduledBookingBottomSheet/ScheduledBookingBottomSheet";
import TopUpBottomSheet from "../../TopUp/TopUpBottomSheet";
import CancelBookingBottomSheet from "./CancelBookingBottomSheet";
import ChatWithSupportBottomSheet from "../../ChatWithSupportBottomSheet/ChatWithSupportBottomSheet";
import BillingGcashBottomSheet from "../../Account/Billing/BillingInformation/BillingGcashBottomSheet/BillingGcashBottomSheet";
import BillingVerifyingGCash from "../../Account/Billing/BillingInformation/BillingGCash/BillingVerifyingGCash";
import SingleLineSkeleton from "../../ui-elements/Skeleton/SingleLineSkeleton";

import backButton from "../../../v5-icons/back-button.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";
import MyKoinSvg from "../../../assests/images/billing/my-koin.svg";
import { getQuerySearchValue } from "../../../utils/functions/get-query-search-value";
import GcashFailedBottomSheet from "../../GcashFailedBottomSheet/GcashFailedBottomSheet";
import {localStorageIsTwa} from "../../../utils/functions/local-storage";
import ScheduledMymallBookingBottomSheet from "../../job-history/ScheduledBookingBottomSheet/ScheduledMymallBookingBottomSheet";
import { XenditMinLimitBottomSheet } from "../../xenditMinLimitBottomSheet/XenditMinLimitBottomSheet";
import { QRCode } from "../../QRCode/QRCode";
class ReviewBooking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowProductInfoPopup: false,
			isOpenBottomSheet: false,
			pageName: this.props.match.url.split("/").pop(),
		};
	}
	componentDidMount() {
		this.props.dispatch(reviewBookingPriceAndLocationExist());
		this.props
			.dispatch(addAnimateToText(document.querySelector(".long-heading"), document.querySelector(".long-heading .heading")))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropProduct: true,
					}));
				}
			})
			.catch((err) => {});
		if (this.props.common.authCheck && this.props.common.auth) {
			this.props.dispatch(paymentMethodsLoading(true));
			this.props.dispatch(fetchClient());
			this.props.dispatch(availableMethods()).then((res) => {
				if (res.data && !_.isNull(res.data.data.adyenPaymentMethods.paymentMethods)) {
					res.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
						if (x.type === "gcash") this.props.dispatch(supportGcash(x));
					});
				}
				this.props.dispatch(fetchMethodsOfPayment()).then((res) => {
					this.props.dispatch(paymentMethodsLoading(false));
					if (res.data.data.paymentMethods.totalItems > 0) {
						const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
						this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
					}
					this.props.dispatch(checkRejectedPayment());
					const params = parse(window.location.search);

					this.determinePaymentActionBasedOnSearchParam(params)

					this.logEvent()

					this.checkGcashSuccess()
				});
			});
		}
		// if (this.props.common.authCheck && this.props.common.auth) {
		// 	this.props.dispatch(paymentMethodsLoading(true));
		// 	this.props.dispatch(fetchClient());
			// 	this.props.dispatch(fetchXenditAndBalance()).then((res) => {
			// 		this.props.dispatch(paymentMethodsLoading(false));
			// 		if (res.data.data.getXenditPaymentMethods.totalItems > 0) {
			// 			const primaryPayment = res.data.data.getXenditPaymentMethods.items.filter((payment) => payment.isPrimary === true);
			// 			this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
			// 		}
			// 		this.props.dispatch(checkRejectedPayment());
			// 		const params = parse(window.location.search);
			//
			// 		this.determinePaymentActionBasedOnSearchParam(params)
			//
			// 		this.logEvent()
			//
			// 		this.checkGcashSuccess()
			// 	});
		//}

		// this.props.dispatch(setReviewBookingConfigTour(this.state.pageName));
		if (!_.isEmpty(this.props.prices)) {
			this.props.dispatch(changeBookingTimeFormat(jobTime(this.props.prices, this.props.prices.minCubeNumbers)));
		}
	}

	determinePaymentActionBasedOnSearchParam(params) {
		if (_.has(params, "topUp")) {
			this.props.dispatch(checkIsTopUpSuccess(params["topUp"]));
			this.props.dispatch(removeQueryParam("topUp", this.props.history));
		} else if (_.has(params, "retryWithTopUp")) {
			this.props.dispatch(setDefaultRetryWithTopUp());
			this.props.dispatch(removeQueryParam("retryWithTopUp", this.props.history));
		} else if (_.has(params, "retryWithCard") || getStoredUrlLocalStorage()) {
			if (!_.isNull(this.props.billing.gcashItems) && this.props.billing.gcashItems.length) {
				if (_.has(params, "retryWithCard") && getAddingGcashLocalStorage()) {
					if (this.props.billing.gcashItems[0].isPending) {
						this.props.dispatch(toggleVerifyingGcashBottomSheet());
					}
					removeAddingGcashLocalStorage();
				}
			}
			this.props.dispatch(removeQueryParam("retryWithCard", this.props.history));
			this.props.dispatch(checkRetryWithCard());
		} else {
			if (localStorageGetCurrentBalance()) localStorage.removeItem("currentBalance");
			localStorage.removeItem("reviewBooking");
		}
	}

	logEvent() {
		if (!_.isNull(this.props.product.mode) && !_.isUndefined(this.props.product.mode)) {
			LOG_EVENT.logEvent(LOG_EVENT.REVIEW_PAGE_CWA, {
				os: localStorageIsTwa() ? "twa" : "web",
				service: this.props.product.mode.includes("MY_MALL") ? "mymall" : "mykuya",
			});
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.REVIEW_PAGE_CWA, {
				os: localStorageIsTwa() ? "twa" : "web",
				service: "mykuya",
			});
		}
	}

	checkGcashSuccess(params) {
		if (_.has(params, "gCashSucceed")) {
			if (getQuerySearchValue("gCashSucceed") === "false") {
				this.props.dispatch(openGcashFailedBottomSheet());
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (this.props.common.authCheck !== nextProps.common.authCheck) {
			this.props.dispatch(paymentMethodsLoading(true));
			this.props.dispatch(fetchClient());
			this.props.dispatch(availableMethods()).then((res) => {
				if (res.data && !_.isNull(res.data.data.adyenPaymentMethods.paymentMethods)) {
					res.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
						if (x.type === "gcash") this.props.dispatch(supportGcash(x));
					});
				}
				this.props.dispatch(fetchMethodsOfPayment()).then((res) => {
					this.props.dispatch(paymentMethodsLoading(false));
					if (res.data.data.paymentMethods.totalItems > 0) {
						const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
						this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
					}
					this.props.dispatch(checkRejectedPayment());
					const params = parse(window.location.search);
					this.determinePaymentActionBasedOnSearchParam(params)
					this.logEvent()
				});
			});
		}

		// if (this.props.common.authCheck !== nextProps.common.authCheck) {
		// 	this.props.dispatch(paymentMethodsLoading(true));
		// 	this.props.dispatch(fetchClient());
			// this.props.dispatch(fetchXenditAndBalance()).then((res) => {
			// 	this.props.dispatch(paymentMethodsLoading(false));
			// 	if (res.data?.data?.getXenditpaymentMethods?.totalItems > 0) {
			// 		const primaryPayment = res.data.data.getXenditpaymentMethods.items.filter((payment) => payment.isPrimary === true);
			// 		this.props.dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
			// 	}
			// 	this.props.dispatch(checkRejectedPayment());
			// 	const params = parse(window.location.search);
			// 	this.determinePaymentActionBasedOnSearchParam(params)
			// 	this.logEvent()
			// });
		//}
		return true;
	}

	componentWillUnmount() {
		this.props.dispatch(toggleTour(false));
		this.props.dispatch(unMountTourConfig());
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(toggleQrCodeBottomSheet(false));
	}

	onCloseProductInfo = () => {
		this.setState(() => ({
			isShowProductInfoPopup: false,
		}));
	};

	isToggleSheet = () => {
		this.setState(() => ({ isShowProductInfoPopup: false }));
	};

	onOpenProductInfoModal = () => {
		this.setState(() => ({
			isShowProductInfoPopup: true,
		}));
	};

	goBack = () => {
		LOG_EVENT.logEvent(LOG_EVENT.REVIEW_EDIT_NOTE_CWA, {
			hasNote: this.props.note.length > 0 ? true : false,
		});
		this.props.dispatch(push(`/booking/${this.props.product.id}/note`));
		// if (getReviewBookingLocalStorage()) {
		// 	this.props.dispatch(navigateToRequestPage(this.props.product.id, this.props.jobLocations, `/booking/${this.props.product.id}/note`));
		// } else {
		// 	this.props.dispatch(navigateToRequestPage(this.props.product.id, this.props.location, `/booking/${this.props.product.id}/note`));
		// }
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	onJobLocationClick = () => {
		LOG_EVENT.logEvent(LOG_EVENT.REVIEW_EDIT_LOCATION_CWA);
		this.props.dispatch(backToBookingPage());
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
	};

	onBookingRequest = () => {
		if (this.props.common.authCheck && this.props.common.auth) {
			if (this.props.prices?.items[this.props.prices?.minCubeNumbers]?.price?.value === 0) {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(requestNowBooking(this.props.jobLocations));
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				} else {
					this.props.dispatch(requestNowBooking(this.props.location));
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				}
				LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "instant" });
				this.props.dispatch(setIsInstantMatch(this.props.prices.isInstantMatch));
			} else if (this.props.common.clientHasNoPaymentMethod) {
				this.props.dispatch(togglePaymentMethodsBottomSheet());
				LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
					reason: "noPayment",
					productId: this.props.product.id,
					productName: this.props.product.commercialName,
				});
			} else if (this.props.hasRejectedPayment) {
				this.props.dispatch(toggleRejectedPayment());
				LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
					reason: "rejected",
					productId: this.props.product.id,
					productName: this.props.product.commercialName,
				});
			} else {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(requestNowBooking(this.props.jobLocations));
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				} else {
					this.props.dispatch(requestNowBooking(this.props.location));
					if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
				}
				LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "instant" });
				this.props.dispatch(setIsInstantMatch(this.props.prices.isInstantMatch));
			}
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	// onBookingRequest = () => {
	// 	if (this.props.common.authCheck && this.props.common.auth) {
	// 		if (this.props.prices?.items[this.props.prices?.minCubeNumbers]?.price?.value === 0) {
	// 			this.bookThisJob()
	// 		} else if (this.props.common.clientHasNoPaymentMethod) {
	// 			this.props.dispatch(togglePaymentMethodsBottomSheet());
	// 			LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
	// 				reason: "noPayment",
	// 				productId: this.props.product.id,
	// 				productName: this.props.product.commercialName,
	// 			});
	// 		} else if (this.props.hasRejectedPayment) {
	// 			this.props.dispatch(toggleRejectedPayment());
	// 			LOG_EVENT.logEvent(LOG_EVENT.INSTANT_BOOKING_FAILED, {
	// 				reason: "rejected",
	// 				productId: this.props.product.id,
	// 				productName: this.props.product.commercialName,
	// 			});
	// 		} else {
	// 			if (this.props.common?.primaryPayment?.[0].type) {
	// 				this.xenditTopupWithMinLimit()
	// 			} else {
	// 				this.bookThisJob()
	// 			}
	//
	// 			LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "instant" });
	// 			this.props.dispatch(setIsInstantMatch(this.props.prices.isInstantMatch));
	// 		}
	// 	} else {
	// 		LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
	// 		this.props.dispatch(toggleLogInBottomSheet());
	// 	}
	// };

	onScheduleBooking = () => {
		if (this.props.common.authCheck && this.props.common.auth) {
			if (this.props.prices?.items[this.props.prices?.minCubeNumbers]?.price?.value === 0) {
				this.props.dispatch(toggleScheduleBooking());
			} else if (this.props.common.clientHasNoPaymentMethod) {
				this.props.dispatch(togglePaymentMethodsBottomSheet());
			} else if (this.props.hasRejectedPayment) {
				this.props.dispatch(toggleRejectedPayment());
			} else {
				this.props.dispatch(toggleScheduleBooking());
			}
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "book" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	completePaymentMethod = () => {
		const storedUrl = `${this.props.match.url}?retryWithCard`;
		this.props.dispatch(storeBookingUrl(storedUrl));
		setStoredUrlLocalStorage(storedUrl);
		const reviewBookingObject = {
			prices: this.props.prices,
			product: this.props.product,
			formattedTime: this.props.formattedTime,
			jobLocations: this.props.jobLocations,
			primaryPayment: this.props.primaryPayment,
			note: this.props.note,
			promoCode: this.props.promoCode,
			newPrice: this.props.newPrice,
			amountOff: this.props.amountOff,
			percentOff: this.props.percentOff,
		};
		setReviewBookingLocalStorage(JSON.stringify(reviewBookingObject));
		this.props.dispatch(togglePaymentMethodsBottomSheet());
		this.props.dispatch(push("/account/billing/add"));
	};

	handleChangeBookingInfoBottomSheet = (isOpen) => {
		if (!isOpen) this.setState(() => ({ isShowProductInfoPopup: false }));
	};

	setTopUpValue = () => {
		this.props.dispatch(
			topUpMyKoin(
				parseInt(this.props.topUpMyKoinAmount),
				process.env.REACT_APP_PUBLIC_URL,
				this.props.product.id,
				this.props.common.client.currency.unit,
			),
		);
		// this.props.dispatch(bookingXenditTopUpMyKoin(parseInt(this.props.topUpMyKoinAmount),
		// 	`booking/${this.props.product.id}`, this.props.common.client.currency.unit, "retryWithTopUp"));
		const reviewBookingObject = {
			prices: this.props.prices,
			product: this.props.product,
			formattedTime: this.props.formattedTime,
			jobLocations: this.props.jobLocations,
			primaryPayment: this.props.primaryPayment,
			note: this.props.note,
			promoCode: this.props.promoCode,
			newPrice: this.props.newPrice,
			amountOff: this.props.amountOff,
			percentOff: this.props.percentOff,
		};
		setReviewBookingLocalStorage(JSON.stringify(reviewBookingObject));
		localStorageSetCurrentBalance(this.props.billing.balance[0].balance);
	};

	applyCode = () => {
		const splittedPromoCode = this.props.promoCode.split(" ").join("");
		this.props.dispatch(applyPromoCode(splittedPromoCode)).then((res) => {
			if (res) {
				toast(
					<UndoButton
						title={`${res}`}
						buttonTitle={"Change"}
						onHandleClick={() => {
							this.props.dispatch(togglePromoCodeBottomSheet());
							this.props.dispatch(changePromoCode(true));
						}}
					/>,
					{
						position: "bottom-center",
						autoClose: 5000,
						limit: 1,
						className: "black-background",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					},
				);
			}
		});
	};

	handleLinkRemovePromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());
		this.props.dispatch(removePromoCode(true));
	};

	handleRemovePromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());

		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(removePromoCode(false));
		toast(
			<UndoButton
				title={`${this.props.promoCode} removed`}
				buttonTitle={"Undo"}
				onHandleClick={() => {
					this.props.dispatch(setNewPrice(this.props.promoCodeResult.discountedOrderAmount));
					this.props.dispatch(setAmountOff(this.props.promoCodeResult.amountOff));
					this.props.dispatch(setPercentOff(this.props.promoCodeResult.percentOff));
					this.props.dispatch(setPromoCode(this.props.tempPromoCode));
				}}
			/>,
			{
				position: "bottom-center",
				autoClose: 5000,
				limit: 1,
				className: "black-background",
				bodyClassName: "toastify-inner",
				hideProgressBar: true,
				closeOnClick: false,
			},
		);
	};

	handleCancelPromoCode = () => {
		this.props.dispatch(togglePromoCodeBottomSheet());
		this.props.dispatch(setPromoCode(null));
		this.props.dispatch(setNewPrice(null));
		this.props.dispatch(setAmountOff(null));
		this.props.dispatch(setPercentOff(null));
		this.props.dispatch(removePromoCode(false));
	};

	setCurrentStep(current) {
		const { pageName } = this.state;
		if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(pageName)) {
			this.props.dispatch(checkCurrentStep(current, "reviewBottomSheet"));
		} else {
			this.props.dispatch(checkCurrentStep(current, pageName));
		}
	}

	checkOpenWichBottomSheet = () => {
		if (this.props.common.auth && this.props.common.authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.TOPUP_SHEET_OPENED_CWA, {
				paymentMethod: !_.isNull(this.props.common.primaryPayment) ? this.props.common.primaryPayment[0].type : "noPayment",
				myKoinBalance: this.props.billing.balance ? this.props.billing.balance[0].balance : null,
			});
			this.props.dispatch(toggleTopUpBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
			this.props.dispatch(toggleLogInBottomSheet());
		}
	};

	onAddGcashCard = () => {
		this.props.dispatch(toggleGcashBottomSheet());
		this.props.billing.gcashInfo.submit();
	}

	xenditTopupWithMinLimit = () => {
		this.props.dispatch(checkXenditMinLimit("PHP",
			this.props.prices.items[this.props.prices.minCubeNumbers].price.value)).then((isLowerThanMinLimit) => {
			if (isLowerThanMinLimit) {
				this.props.dispatch(xenditMinLimitBottomSheet(true));
			} else {
				this.bookThisJob()
			}
		})
	}

	bookThisJob = () => {
		if (getReviewBookingLocalStorage()) {
			this.props.dispatch(requestNowBooking(this.props.jobLocations));
			if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
		} else {
			this.props.dispatch(requestNowBooking(this.props.location));
			if (!_.isNull(this.props.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.newPrice));
		}
		this.props.dispatch(xenditMinLimitBottomSheet(false));
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<Fragment>
				<div className="review-container" id="review-container">
					{this.state.isShowProductInfoPopup ? (
						<BottomSheet
							indicator={true}
							isOpen={this.state.isShowProductInfoPopup}
							styles={bottomSheetStyle}
							closeSheet={this.isToggleSheet}
							classes="product-info-bottom-sheet"
							onChangeOverlay={this.handleChangeBookingInfoBottomSheet}
						>
							<BookingInfoModal closeModal={this.onCloseProductInfo} productDetail={this.props.product} />
						</BottomSheet>
					) : null}
					<div className="review-product">
						<TitleBar
							leftIcon={backButton}
							rightIcon={chatSupport}
							handleClickLeftIcon={this.goBack}
							handleClickRightIcon={this.onSupport}
							lozengeObject={{ title: "Reviewing", color: "violet" }}
						/>
						<BookingService
							icon={this.props.product.icon}
							commercialName={this.props.product.commercialName}
							descriptionTitle={this.props.product.descriptionTitle}
							hasIconInfo={true}
							showInfoProductModal={this.onOpenProductInfoModal}
							hasFadePropProduct={this.state.hasFadePropProduct}
						/>
						<p className="tip">
							<span role="img" aria-label="emoji">
								💡
							</span>{" "}
							Please double check if the following are correct. Tap the information you wish to change
						</p>
					</div>
					{!_.isEmpty(this.props.jobLocations) ? (
						<JobLocations locations={this.props.jobLocations.jobLocations} handleClickBookingItem={this.onJobLocationClick} />
					) : null}
					{!_.isNull(this.props.formattedTime) && !_.isEmpty(this.props.prices) ? (
						<BookingPriceTime
							newPrice={this.props.newPrice}
							amountOff={!_.isNull(this.props.amountOff) && this.props.amountOff}
							percentOff={!_.isNull(this.props.percentOff) && this.props.percentOff}
							promoCode={this.props.promoCode}
							handleLinkRemovePromoCode={this.handleLinkRemovePromoCode}
							handleClickEnterPromoCode={() => {
								if (this.props.common.authCheck && this.props.common.auth) {
									LOG_EVENT.logEvent(LOG_EVENT.DISCOUNT_SHEET_OPENED_CWA);
									this.props.dispatch(togglePromoCodeBottomSheet());
								} else {
									LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
									this.props.dispatch(toggleLogInBottomSheet());
								}
							}}
							time={this.props.formattedTime}
							price={this.props.prices}
							currency={!_.isEmpty(this.props.common.client) ? this.props.common.client.currency.signText : null}
							currencyIcon={!_.isEmpty(this.props.common.client) ? this.props.common.client.currency.signSvg : null}
						/>
					) : null}
					<div className="tour-payment-info" data-tut="tour-mk-balance">
						<div className="review-booking-item-container mykoin">
							<p className="title">mykoins Balance</p>
							<div className="review-booking-item-inner" onClick={this.checkOpenWichBottomSheet}>
								{this.props.billing.paymentLoading ? (
									<SingleLineSkeleton />
								) : _.isNull(this.props.billing.balance) ? (
									<p>None</p>
								) : (
									<p>{this.props.billing.balance[0].displayValue}</p>
								)}
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
							</div>
							<span className="hint">Top-up using GCash, Paymaya, or bank cards</span>
						</div>
					</div>

					<div className="tour-payment-info" data-tut="tour-payment-info">
						<BookingPayment
							primaryPayment={this.props.common.primaryPayment}
							loading={this.props.billing.paymentLoading}
							handleClickBookingItem={() => {
								if (this.props.common.authCheck && this.props.common.auth) {
									LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_METHODS_PAGE_CWA, { from: "review" });
									this.props.dispatch(setPaymentBottomSheetTour("reviewBottomSheet"));
									this.props.dispatch(togglePaymentMethodsBottomSheet());
								} else {
									LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "review" });
									this.props.dispatch(toggleLogInBottomSheet());
								}
							}}
						/>
					</div>

					<ReviewBookingNote note={this.props.note} handleClickBookingItem={this.goBack} />

					{this.props.product.howItWorks && <HowItWorks content={this.props.product.howItWorks} />}
					<BookingButton
						onCancelBooking={() => this.props.dispatch(toggleCancelBookingBottomSheet())}
						onClick={this.onBookingRequest}
						isInstantMatch={this.props.prices.isInstantMatch}
						onScheduleBooking={this.onScheduleBooking}
						mode={this.props.product.mode}
						loading={this.props.getJobLoading}
					/>
				</div>
				{this.props.isOpenPromoCodeBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenPromoCodeBottomSheet}
						styles={bottomSheetStyle}
						classes="promo-code-bottom-sheet"
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(togglePromoCodeBottomSheet());
								this.props.dispatch(setPromoCode(null));
								this.props.dispatch(setNewPrice(null));
								this.props.dispatch(setAmountOff(null));
								this.props.dispatch(setPercentOff(null));
								this.props.dispatch(removePromoCode(false));
							}
						}}
					>
						<PromoCodeBottomSheet
							applyCode={this.applyCode}
							loading={this.props.promoCodeLoading}
							handleRemovePromoCode={this.handleRemovePromoCode}
							handleCancelPromoCode={this.handleCancelPromoCode}
							value={!_.isNull(this.props.promoCode) ? this.props.promoCode : ""}
						/>
					</BottomSheet>
				)}

				{this.props.isOpenPaymentMethodsBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenPaymentMethodsBottomSheet}
						styles={bottomSheetStyle}
						classes="payment-method-bottom-sheet"
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(togglePaymentMethodsBottomSheet());
						}}
					>
						{!_.isEmpty(this.props.prices) && !_.isNull(this.props.billing.balance) ? (
							<BookingPaymentBottomSheet
								onAddMethod={this.completePaymentMethod}
								price={this.props.prices.items[this.props.prices.minCubeNumbers].price.displayValue}
								paymentMethods={this.props.paymentMethods}
								insufficientBalance={this.props.billing.balance[0].balance <= 0}
							/>
						) : null}
					</BottomSheet>
				)}

				{this.props.isOpenRejectedPaymentBottomSheet && (
					<BottomSheet
						isOpen={this.props.isOpenRejectedPaymentBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleRejectedPayment());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<RejectedPaymentMethod
							showTopUpBottomSheet={() => {
								this.props.dispatch(toggleTopUpBottomSheet());
								this.props.dispatch(toggleRejectedPayment());
								this.props.dispatch(retryWithTopUp());
							}}
							showPaymentMethodsBottomSheet={() => {
								this.props.dispatch(togglePaymentMethodsBottomSheet());
								this.props.dispatch(toggleRejectedPayment());
								this.props.dispatch(retryWithTopUp());
								this.props.dispatch(retryWithCard());
							}}
							navigateToSupport={this.onSupport}
						/>
					</BottomSheet>
				)}

				<BottomSheet
					classes="topup-mykoin-bottom-sheet"
					isOpen={this.props.isShowTopUpBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleTopUpBottomSheet());
					}}
				>
					<TopUpBottomSheet setTopUpValue={this.setTopUpValue} />
				</BottomSheet>

				{this.props.isOpenCancelBookingBottomSheet && (
					<BottomSheet
						classes="cancel-booking-container"
						isOpen={this.props.isOpenCancelBookingBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleCancelBookingBottomSheet());
						}}
					>
						<CancelBookingBottomSheet
							navigateToSupport={this.onSupport}
							confirmCancelBooking={() => {
								LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DID_CANCEL_CWA, { from: "review" });
								this.props.dispatch(appGoHome());
								this.props.dispatch(willUnMountReviewBooking());
							}}
						/>
					</BottomSheet>
				)}

				{this.props.common.toggleChatWithSupport && (
					<BottomSheet
						isOpen={this.props.common.toggleChatWithSupport}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						classes="chat-with-support-bottom-sheet"
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleChatWithSupportBottomSheet());
						}}
					>
						<ChatWithSupportBottomSheet navigateToSupport={this.onSupport} />
					</BottomSheet>
				)}

				{!(this.props.common.auth && this.props.common.authCheck) ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.common.logInBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							//false => phone | true => showing verification
							if (this.props.common.enteringVerificationCode) {
								LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "code" });
							} else {
								LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "phone" });
							}
							if (!isOpen) this.props.dispatch(toggleLogInBottomSheet());
						}}
					>
						<LogInBottomSheet />
					</BottomSheet>
				) : null}

				{/* <ScheduledBookingBottomSheet isOpen={this.props.scheduleBookingBottomSheet} location={this.props.location} /> */}
				{this.props.scheduleBookingBottomSheet ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.scheduleBookingBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={false}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(toggleScheduleBooking());
							}
						}}
					>
						<ScheduledMymallBookingBottomSheet location={this.props.location} />
					</BottomSheet>
				) : null}

				{/* {!_.isNull(this.props.common.tourSteps) && !this.props.common.loading && (
					<Tour
						onRequestClose={() => this.props.dispatch(toggleTour(false))}
						steps={this.props.common.tourSteps}
						startAt={this.props.common.currentStep}
						isOpen={this.props.common.isOpenTour}
						maskClassName="mask"
						className="onboard-message-box"
						rounded={5}
						maskSpace={5}
						showCloseButton={false}
						showNavigation={false}
						showNumber={false}
						closeWithMask={false}
						accentColor={"#5cb7b7"}
						lastStepNextButton={
							<p
								className="next-button"
								onClick={() => {
									if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(this.state.pageName)) {
										this.props.dispatch(tourPageisDone("reviewBottomSheet"));
									} else {
										this.props.dispatch(tourPageisDone(this.state.pageName));
										document.getElementById("review-container").style.overflowY = "auto";
									}
								}}
							>
								DONE!
							</p>
						}
						disableInteraction
						getCurrentStep={(curr) => this.setCurrentStep(curr)}
						nextButton={<p className="next-button">NEXT</p>}
						prevButton={
							<p
								className="skip-button"
								onClick={() => {
									if (!_.isNull(localStorageGetTourPageViwed()) && localStorageGetTourPageViwed().includes(this.state.pageName)) {
										this.props.dispatch(skipShowingTour("reviewBottomSheet"));
									} else {
										this.props.dispatch(skipShowingTour(this.state.pageName));
										document.getElementById("review-container").style.overflowY = "auto";
									}
								}}
							>
								SKIP TUTORIAL
							</p>
						}
					/>
				)} */}

				{this.props.billing.isGcashSupported ? (
					<>
						<BottomSheet
							isOpen={this.props.billing.gcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleGcashBottomSheet());
							}}
						>
							<BillingGcashBottomSheet onAddGcashCard={this.onAddGcashCard} />
						</BottomSheet>
						<BottomSheet
							isOpen={this.props.billing.verifyingGcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleVerifyingGcashBottomSheet());
							}}
						>
							<BillingVerifyingGCash />
						</BottomSheet>
					</>
				) : null}

				{this.props.common.gcashFailedBottomSheet ? (
					<BottomSheet
						isOpen={this.props.common.gcashFailedBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(closeGcashFailedBottomSheet());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<GcashFailedBottomSheet />
					</BottomSheet>
				) : null}

				{this.props.common.xenditMinLimitBottomSheet ? (
					<BottomSheet
						isOpen={this.props.common.xenditMinLimitBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(xenditMinLimitBottomSheet(false));
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<XenditMinLimitBottomSheet xenditTopup200Peso={this.bookThisJob} />
					</BottomSheet>
				) : null}

				<BottomSheet
					classes="topup-mykoin-bottom-sheet"
					isOpen={this.props.common.qrCodeBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleQrCodeBottomSheet(false));
					}}
				>
					<QRCode client={this.props.common?.client} />
				</BottomSheet>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
	common: state.common,
	map: state.map,
	location: {
		jobLocations: [
			{
				lat: state.map.location.lat,
				lng: state.map.location.lng,
				address: state.map.location.address,
				title: state.map.location.title,
			},
		],
	},
	locationTitle: state.map.location.title || state.map.location.address,
	billing: state.billing
});

export default connect(mapStateToProps)(ReviewBooking);