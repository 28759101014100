import React, {Fragment} from 'react';
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";


export default function JobChatLeftAction(props) {

	return (
		<Fragment>
			<div onClick={props.onClearTyping} className="Job-chat-history-actions  no-padding text-center pull-right">
				<button className="Job-chat-history-emoji-button" type="button">
					<DynamicIcon icon="BlueLeft" classes="Blue_Left_History"/>
				</button>


			</div>
		</Fragment>
	);
}