//Job state's Enum
export const All = "All";
export const Active = "Active";
export const Upcoming = "Upcoming";
export const Open = "Open";
export const Completed = "Completed";

export const SET_SELECTED_TAB_INDEX = "SET_SELECTED_TAB_INDEX";
export const FETCH_OPEN_JOBS_BEGIN = "FETCH_OPEN_JOBS_BEGIN";
export const FETCH_OPEN_JOBS_SUCCESSFULLY = "FETCH_OPEN_JOBS_SUCCESSFULLY";
export const FETCH_UPCOMING_JOBS = "FETCH_UPCOMING_JOBS";
export const FETCH_ALL_JOBS = "FETCH_ALL_JOBS";
export const NO_OPEN_JOBS = "NO_OPEN_JOBS";
export const FETCH_COMPLETED_JOBS = "FETCH_COMPLETED_JOBS";
export const FETCH_ACTIVE_JOBS = "FETCH_ACTIVE_JOBS";
export const FETCH_JOBS_BY_OFFSET_BEGIN = "FETCH_JOBS_BY_OFFSET_BEGIN";
export const FETCH_JOBS_BY_OFFSET_END = "FETCH_JOBS_BY_OFFSET_END";
export const FETCH_JOBS_BY_REQUEST_TYPE_BEGIN = "FETCH_JOBS_BY_REQUEST_TYPE_BEGIN";
export const UNMOUNT_JOBS_LIST = "UNMOUNT_JOBS_LIST";
export const FETCH_JOBS_LOADING = "FETCH_JOBS_LOADING";