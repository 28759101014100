import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { toast } from "react-toastify";
import Tour from "reactour";
import { parse } from "query-string";

import HomeHeader from "../HomeHeader/HomeHeader";
import Featured from "../Featured/Featured";
import WhatsNew from "../WhatsNew/WhatsNew";
import JobRating from "../../JobRating/JobRating";
import HomeSearch from "../HomeSearch/HomeSearch";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import GoogleMap from "../../LocationSearch/GoogleMap/GoogleMap";
import AppRating from "../../app-rating/app-rating";
import SavedPlacesBottomSheet from "../../SavedPlaces/SavedPlacesBottomSheet/SavedPlacesBottomSheet";

import {
	fetchHome,
	clickShowMoreServices,
	clickService,
	clickSubCategories,
	 initDeliveringToPlace,
} from "../../../utils/redux/actions/homeActions";
import { homeWillUnMount } from "../../../utils/redux/actions/homeActions/homeChangeState";
import { searchNavigateToMapFromLocationSearch } from "../../../utils/redux/actions/searchActions";
import { mapClearGoogleMapArrayOfDestination } from "../../../utils/redux/actions/mapActions/mapChangeState";
import {
	bookingEmptyNote,
	bookingSetNullSelectedOptions,
	openLocationSearchBottomSheet,
	closeLocationSearchBottomSheet,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { toggleRatePopup, appRated } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
import {
	appAuthCheck,
	tourPageisDone,
	checkCurrentStep,
	skipShowingTour,
	removeQueryParam,
	setPathname,
} from "../../../utils/redux/actions/commonActions";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import {
	locationIsChanged,
	toggleLogInBottomSheet,
	toggleTour,
	unMountTourConfig,
} from "../../../utils/redux/actions/commonActions/commonChangeState";
import { setSavedPlaceLocationPointer, setSavedPlacePathname } from "../../../utils/redux/actions/savedPlacesActions";
import {
	localStorageSetReminderRateApp,
	localStorageSetAppIsRated,
	getDeliveringToPlaceLocalStorage,
} from "../../../utils/functions/local-storage";

import momentNowTimestamp from "../../../utils/functions/moment-now-timestamp";
import * as LOG_EVENT from "../../../utils/analytics";

import HomeActiveJob from "../HomeActiveJob/HomeActiveJob";
import HomeEmpty from "../HomeEmpty/HomeEmpty";
import Skeleton from "../../ui-elements/Skeleton/Skeleton";

import RectangeRight from "../../../v5-icons/rec-right.png";
import HomeBottomImage from "../../../v5-icons/home-bottom.png";
import BottomEllipse from "../../../v5-icons/bottom-ellipse.png";
import { separateCityName } from "../../../utils/functions/create-place-object-from-google-suggestions";
import {localStorageIsTwa} from "../../../utils/functions/local-storage";
import { isChrome, isIOS, isSamsungBrowser } from "../../../utils/functions/check-browser";
class Home extends Component {
	constructor(props) {
		super(props);
		this.isToggleSheet = this.isToggleSheet.bind(this);
		this.onShowMoreServices = this.onShowMoreServices.bind(this);
		this.onNavigateToService = this.onNavigateToService.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.onNavigateToPureProduct = this.onNavigateToPureProduct.bind(this);
		this.onGoToStore = this.onGoToStore.bind(this);
		this.onLocationPage = this.onLocationPage.bind(this);
		this.onLocationPageBack = this.onLocationPageBack.bind(this);
		this.navigateToSearchLocation = this.navigateToSearchLocation.bind(this);
		this.state = {
			showLocationPage: false,
			isOpenBottomSheet: false,
			pageName: this.props.match.url.split("/")[1],
			innerWidth: null,
			deliveringToPlace: null
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		if (this.props.common.lctionPointer && this.props.common.lctionPointer === "/jobs") {
			this.setState(() => ({
				showLocationPage: !this.state.showLocationPage,
			}));
		}

		// this.props.dispatch(fetchLastUsed());
		if ((!this.props.savedPlaces?.deliveringToPlace && !getDeliveringToPlaceLocalStorage()) || !this.props.common.auth) {
			this.props.dispatch(initDeliveringToPlace())
		}
		if (_.isNull(this.props.allProducts) || _.isUndefined(this.props.jobs.activeJobs) || !this.props.productExist) this.props.dispatch(fetchHome(this.state.pageName));
		this.props.dispatch(mapClearGoogleMapArrayOfDestination());
		this.props.dispatch(bookingSetNullSelectedOptions());
		this.props.dispatch(bookingEmptyNote());
		if (!_.isEmpty(this.props.common.client)) {
			this.props.dispatch(appAuthCheck(this.props.common.client));
		}
		if (this.props.common.locationIsChanged) {
			toast("Location changed", {
				position: "bottom-center",
				autoClose: 4000,
				limit: 1,
				className: "toast-rejected-payment",
				bodyClassName: "toastify-inner",
				hideProgressBar: true,
				closeOnClick: false,
			});
		}
		const params = parse(window.location.search);
		if (_.has(params, "fromNotif")) {
			LOG_EVENT.logEvent(LOG_EVENT.LAUNCH_FROM_NOTIFICATION_CWA);
			this.props.dispatch(removeQueryParam("fromNotif", this.props.history));
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.locationSearchBottomSheet !== this.props.locationSearchBottomSheet) {
			if (!this.props.savedPlaces.deliveringToPlace && !getDeliveringToPlaceLocalStorage()) {
				this.props.dispatch(initDeliveringToPlace())
			}
			this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() });
		}
		return true;
	}

	componentWillUnmount() {
		// this.props.dispatch(homeWillUnMount());
		this.props.dispatch(toggleTour(false));
		this.props.dispatch(locationIsChanged(false));
		this.props.dispatch(unMountTourConfig());
	}

	isToggleSheet() {
		const toggleSheet = this.state.isOpenBottomSheet;
		this.setState({ isOpenBottomSheet: !toggleSheet });
	}

	onShowMoreServices(e) {
		this.props.dispatch(clickShowMoreServices());
	}

	navigateToSearchLocation(param) {
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_SHEET_OPENED_CWA, { from: param });
		if(param === "search") {
			this.props.dispatch(setSavedPlacePathname("search"));
		} else {
			this.props.dispatch(setSavedPlacePathname(null));
		}
		// this.props.dispatch(push("/search?fromHome"));
		const { auth, authCheck } = this.props.common;
		if (auth && authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_OPENED, { from: param, os: localStorageIsTwa() ? "twa" : "web" });
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(openLocationSearchBottomSheet());
		} else {
			this.goToMap();
		}
		// this.props.dispatch(locationIsChanged(false));
		// this.props.dispatch(homeWillUnMount());
	}

	/**
	 * When user select one service and click on it
	 * @param {*}
	 */
	onNavigateToService(e, service) {
		if (service.products.length === 1) {
			if (!_.isNull(service.products[0].mode)) {
				LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
					os: localStorageIsTwa() ? "twa" : "web",
					service: service.products[0].mode.includes("MY_MALL") ? "mymall" : "mykuya",
				});
			} else {
				LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
					os: localStorageIsTwa() ? "twa" : "web",
					service: "mykuya"
				});
			}
			this.props.dispatch(clickService(service.products[0]));
		} else {
			this.props.dispatch(clickSubCategories(service));
		}
	}

	onNavigateToPureProduct(e, service, param) {
		if (!_.isNull(service.mode)) {
			LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
				os: localStorageIsTwa() ? "twa" : "web",
				service: service.mode.includes("MY_MALL") ? "mymall" : "mykuya",
			});
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
				os: localStorageIsTwa() ? "twa" : "web",
				service: "mykuya",
			});
		}
		this.props.dispatch(clickService(service));
	}

	onCloseModal(e) {
		e.preventDefault();
		this.props.dispatch(toggleRatePopup(false));
		localStorageSetReminderRateApp(momentNowTimestamp());
	}

	onGoToStore(e) {
		e.preventDefault();
		window.location.replace("https://onelink.to/MyKuyaApp");
		//app is rated successfully
		this.props.dispatch(appRated());
		localStorageSetAppIsRated();
	}

	onLocationPage() {
		document.querySelector(".tabbar-wrapper").style.display = "none";
		this.setState({ showLocationPage: !this.state.showLocationPage });
		LOG_EVENT.logEvent(LOG_EVENT.SEARCH_PAGE_CWA);
		if (document.getElementById("searchInput") !== null) {
			document.getElementById("searchInput").focus();
		}
	}

	onLocationPageBack() {
		document.querySelector(".tabbar-wrapper").style.display = "block";
		this.setState({ showLocationPage: !this.state.showLocationPage });
		// if (document.getElementById("searchInput") !== null) {
		// 	document.getElementById("searchInput").focus();
		// }
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	navigateToAccount = () => {
		if (!this.props.common.auth && !this.props.common.authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "bar" });
			this.props.dispatch(toggleLogInBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.ACCOUNT_PAGE_CWA, { from: "tab" });
			this.props.dispatch(push("/account"));
		}
	};

	onNavigateJobs = () => {
		this.props.dispatch(push("/jobs"));
	};

	onNavigateNews = () => {
		LOG_EVENT.logEvent(LOG_EVENT.NEWS_LIST_PAGE, { from: ["account", "feedButton"] });
		this.props.dispatch(push("/news"));
	};

	handleClickOnChatSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
		LOG_EVENT.logEvent(LOG_EVENT.APP_HERE_2_HELP_TAPPED_CWA, { value: 1.0, currency: "PHP" });
	};

	setCurrentStep(current) {
		const { pageName } = this.state;
		this.props.dispatch(checkCurrentStep(current, pageName));
	}

	goToMap = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_OPENED, {
			os: localStorageIsTwa() ? "twa" : "web",
			from: _.isNull(this.props.savedPlaces.pathname) ? "home" : "search",
			isGuest: this.props.common.auth && this.props.common.authCheck ? false : true,
		});
		this.props.dispatch(searchNavigateToMapFromLocationSearch(this.props.match.params));
	};

	handleChangeLocationBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.props.dispatch(closeLocationSearchBottomSheet());
		}
	};

	render() {
		return (
			<Fragment>
				<div className="Home_Parent_Box" id="home">
					<div className={`Search_Home_Box ${this.state.showLocationPage ? "Animated_Location" : ""}`}>
						<HomeSearch
							onBackHomePage={this.onLocationPageBack}
							locationTitle={separateCityName(this.props.savedPlaces.deliveringToPlace?.title ||
								this.props.savedPlaces.deliveringToPlace?.location?.title ||
								getDeliveringToPlaceLocalStorage()?.title || getDeliveringToPlaceLocalStorage()?.location?.title)}
							navigateToSearchLocation={() => this.navigateToSearchLocation("search")}
							onNavigateToService={this.onNavigateToPureProduct}
							allProduct={this.props.allProducts}
						/>
					</div>
					<div className={`Home_Page ${this.state.showLocationPage ? "Animated_Home_Page" : ""}`}>
						<HomeHeader
							handleClickOnChatSupport={this.handleClickOnChatSupport}
							navigateToSearchLocation={() => this.navigateToSearchLocation("home")}
							onSearchPage={this.onLocationPage}
							locationTitle={separateCityName(this.props.savedPlaces.deliveringToPlace?.title ||
								this.props.savedPlaces.deliveringToPlace?.location?.title ||
								getDeliveringToPlaceLocalStorage()?.title || getDeliveringToPlaceLocalStorage()?.location?.title)}
							navigateToAccount={this.navigateToAccount}
						/>
						<div className="products-container"
							 style={{height: this.props.shopInfo ? "344px" : "290px"}} data-tut="tour-services">
							<img alt="rec-right" src={RectangeRight} className="rec-right" />
							<img alt="home-bottom" src={HomeBottomImage} className="home-bottom" />
							<img alt="bottom-ellipse" src={BottomEllipse} className="bottom-ellipse" />
							{!_.isNull(this.props.shopInfo) ? (
								<div className="shop-name">
									<h1>{this.props.shopInfo.shop.title}</h1>
								</div>
							) : null}
							{this.props.productExist ? (
								!_.isNull(this.props.allProducts) && this.props.allProducts.length !== 0 ? (
									<Featured
										data={this.props.services}
										onNavigateToService={this.onNavigateToService}
										categories={this.props.showInHomeCategories}
									/>
								) : (
									<HomeEmpty navigateToSearchLocation={() => this.navigateToSearchLocation("home")} onSupport={this.onSupport} />
								)
							) : (
								<Skeleton devicewidth={!_.isNull(this.state.innerWidth) ? this.state.innerWidth : 412} />
							)}
						</div>
						{this.props.jobs.activeJobs !== undefined ? (
							<HomeActiveJob
								activeJobs={this.props.jobs.activeJobs}
								history={this.props.history}
								onNavigateJobs={this.onNavigateJobs}
							/>
						) : null}

						{this.props.news.length ? (
							<WhatsNew data={this.props.news} onNavigateNews={this.onNavigateNews} hasBottomSpace={this.props.showBottomSheet} />
						) : null}

						{React.createElement(GoogleMap, { display: "none", listener: false })}
					</div>
					{/* {!_.isNull(this.props.common.tourSteps) &&
						_.isNull(this.props.unrated) &&
						!this.props.jobs.appRateRemindMeLater &&
						!this.props.common.loading && (
							<Tour
								onRequestClose={() => this.props.dispatch(toggleTour(false))}
								steps={this.props.common.tourSteps}
								startAt={this.props.common.currentStep}
								isOpen={this.props.common.isOpenTour}
								maskClassName="mask"
								className="onboard-message-box"
								rounded={5}
								maskSpace={5}
								showCloseButton={false}
								showNavigation={false}
								showNumber={false}
								closeWithMask={false}
								accentColor={"#5cb7b7"}
								lastStepNextButton={
									<p className="next-button" onClick={() => this.props.dispatch(tourPageisDone(this.state.pageName))}>
										DONE!
									</p>
								}
								disableInteraction
								getCurrentStep={(curr) => this.setCurrentStep(curr)}
								nextButton={<p className="next-button">NEXT</p>}
								prevButton={
									<p className="skip-button" onClick={() => this.props.dispatch(skipShowingTour(this.state.pageName))}>
										SKIP TUTORIAL
									</p>
								}
							/>
						)} */}
				</div>
				{!_.isNull(this.props.unrated) ? <JobRating /> : null}
				{this.props.jobs.appRateRemindMeLater ? <AppRating closeModal={this.onCloseModal} goToStore={this.onGoToStore} /> : ""}
				{this.props.locationSearchBottomSheet ? (
					<BottomSheet
						isOpen={this.props.locationSearchBottomSheet}
						classes="choose-location-bottom-sheet"
						styles={{
							zIndex: "100",
							borderRadius: "20px",
							bottom: "0",
							position: "fixed",
							marginLeft: "auto",
							marginRight: "auto",
							maxWidth: "414px",
						}}
						config={{ marginTop: 50, fullScreen: true }}
						indicator={true}
						onChangeOverlay={this.handleChangeLocationBottomSheet}
					>
						<SavedPlacesBottomSheet handleGoToMap={this.goToMap} pageName={this.props.match.url.split("/")[1]} />
					</BottomSheet>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.home,
	savedPlaces: state.savedPlaces,
	common: state.common,
	map: state.map,
	unrated: state.jobs.unrated,
	jobs: state.jobs,
	register: state.register,
	router: state.router,
	locationSearchBottomSheet: state.booking.locationSearchBottomSheet,
});

export default connect(mapStateToProps)(Home);
