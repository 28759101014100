import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as moment from "moment-timezone";

import JobLocations from "../../JobLocation/JobLocations";
import Moment from "../../../moment/moment";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import { ReactComponent as SuiteCase } from "../../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../../v5-icons/clock-small.svg";
import { ReactComponent as StartTime } from "../../../../v5-icons/start-time-small.svg";
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require("classnames");

class JobChatHistoryDetailSheetContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			weekDay: null,
		};
	}

	componentDidMount() {
		if (this.props.job.state === "Upcoming" && !_.isNull(this.props.job.dueDate)) {
			const opendJobDate = moment(this.props.job.dueDate, "YYYY-MM HH:mm:ss");
			const opendJobWeekDay = opendJobDate.format("dddd");
			this.setState(() => ({
				weekDay: opendJobWeekDay,
			}));
		}
	}

	render() {
		return !_.isEmpty(this.props.job) ? (
			<Fragment>
				<div className="Job_Chat_History_Detail_Sheet_Content">
					<div className="no-padding Title_Box">
						<p className="text-center Title">Job Details</p>
					</div>
					<div className="J_C_H_D_S_C_Job_Type_Box">
						<SuiteCase classes="Job_Type" />
						<div className="text-title Job_Description_Inner">
							<p>{this.props.job.product.commercialName}</p>
							<p>{this.props.job.bookingId}</p>
						</div>
						{/* <div className="text-title Job_Description_Inner"></div> */}
					</div>

					<div className="Job_Chat_History_Detail_Locations">
						<JobLocations locations={this.props.locations} show="name" />
					</div>

					<div className="J_C_H_D_S_C_Job_Time_Content">
						{!_.isEmpty(this.props.job) ? (
							<Fragment>
								<div className="J_C_H_D_S_C_Job_Type_Box">
									<div className="job-time-inner">
										<Clock classes="Home_SearchLocation_Pin" />
										<div className="text-title Job_Description_Inner">
											{this.props.job.jobCost && this.props.job.jobCost.currency}{" "}
											{this.props.job.jobCost && this.props.job.jobCost.value} for {this.props.common.formattedTime}, extendable
										</div>
									</div>
									{!_.isNull(this.props.job.startDateTime) ? (
										<div className="job-time-inner">
											<StartTime classes="Home_SearchLocation_Pin" />
											<div className="text-title Job_Description_Inner">
												<Moment format="hh:mm A">{this.props.job.startDateTime}</Moment> start time
											</div>
										</div>
									) : this.props.job.state === "Upcoming" && !_.isNull(this.state.weekDay) ? (
										<div className="job-time-inner">
											<StartTime classes="Home_SearchLocation_Pin" />
											<div className="text-title Job_Description_Inner">
												<p>
													Scheduled for{" "}
													{this.state.weekDay.indexOf("Invalid") === -1 ? (
														<span>{this.state.weekDay.slice(0, 3)} </span>
													) : (
														""
													)}
													<Moment format="DD MMM , h:mmA">{this.props.job.dueDate}</Moment>
												</p>
											</div>
										</div>
									) : (
										<div className="job-time-inner">
											<StartTime classes="Home_SearchLocation_Pin" />
											<div className="text-title Job_Description_Inner">Not yet started</div>
										</div>
									)}
								</div>
							</Fragment>
						) : null}
					</div>

					<div className="card">
						{_.isEmpty(this.props.primaryPayment) ? (
							<div className="job-description-container">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
							</div>
						) : null}

						{!_.isNull(this.props.billing.balance) && !_.isEmpty(this.props.primaryPayment) ? (
							this.props.billing.balance[0].balance > 0 ? (
								<div className="job-description-container">
									<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
									<p>
										{/*Pay via mykoins then {cardType[this.props.primaryPayment[0].value]}{" "}*/}
										{/*{this.props.primaryPayment[0].value === "MASTERCARD" || this.props.primaryPayment[0].value === "VISA"*/}
										{/*	? `**** ${this.props.primaryPayment[0].cardNumber}`*/}
										{/*	: null}*/}
										Pay via mykoins then {this.props.primaryPayment[0].type}{" "}
										{this.props.primaryPayment[0].type === "mc" || this.props.primaryPayment[0].type === "visa"
											? `**** ${this.props.primaryPayment[0].cardNumber}`
											: null}
									</p>
								</div>
							) : (
								<div className="job-description-container">
									<img
										src={this.props.primaryPayment[0].icon}
										alt={this.props.primaryPayment[0].type}
										className={classNames("billing-card-image", this.props.primaryPayment[0].type)}
										// className={classNames("billing-card-image", cardType[this.props.primaryPayment[0].value])}
									/>
									<p className="job-description-inner text-display">
										Pay via{" "}
										{!_.isNull(this.props.primaryPayment[0].name)
											? this.props.primaryPayment[0].name
											: this.props.primaryPayment[0].type}
										{this.props.primaryPayment[0].type === "mc" || this.props.primaryPayment[0].type === "visa"
											? `**** ${this.props.primaryPayment[0].cardNumber}`
											: null}
									</p>
								</div>
							)
						) : null}
						
					</div>
					<CustomButton title="Got It" onClick={this.props.onCancelDetail} />
				</div>
			</Fragment>
		) : null;
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
	locations: state.jobs.job.jobLocations,
	primaryPayment: state.common.primaryPayment,
	billing: state.billing,
});

export default connect(mapStateToProps)(JobChatHistoryDetailSheetContent);