import React, { Component, Fragment } from "react";
import { push } from "connected-react-router";

import HomeSearchLocation from "./HomeSearchLocation/HomeSearchLocation";
import HomeSearchInputBox from "./HomeSearchInputBox/HomeSearchInputBox";
import HomeSearchProducts from "./HomeSearchProducts/HomeSearchProducts";
import HomeSearchEmpty from "./HomeSearchEmpty/HomeSearchEmpty";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import { connect } from "react-redux";
import HomeSearchEmptyState from "./HomeSearchEmptyState/HomeSearchEmptyState";
import {isMobile} from 'react-device-detect';
class HomeSearch extends Component {
	state = { allProduct: [] ,  firstSearch: false , highLightText: [] ,  isFocus: true};

	constructor(props) {
		super(props);
		this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
	}

	componentDidMount() {
		this.setState({ allProduct: this.props.showInHomeProducts });
	}



	onChangeSearchInput(event) {
		let highLightObj = []
		highLightObj.push(event)
		this.setState({highLightText: highLightObj})
		this.setState({firstSearch: true});
		let filteredProducts = [];
		
		if (event.length === 0) {
			this.setState({firstSearch: false});
		} else {
			let val = event.toLowerCase();
			filteredProducts = this.props.showInHomeProducts.filter(v => v.commercialName.toLowerCase().includes(val));
			filteredProducts.map(item => {
				item.searchedText = val;
			});
			this.setState({ allProduct: filteredProducts });
		}

	}
	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	render() {
		return (
			<Fragment>
				<div className={`${isMobile ? "Home_Search_Box_Mobile" : "Home_Search_Box_Desktop"}`}>
					<HomeSearchLocation onBackHomePage={this.props.onBackHomePage}
										navigateToSearchLocation={this.props.navigateToSearchLocation}
										locationTitle={this.props.locationTitle}/>
					<HomeSearchInputBox onChangeSearchInput={this.onChangeSearchInput} isFocus={this.state.isFocus}/>
				</div>
				{this.state.firstSearch === true ?
					<div className="Home_Product_Box">
						{(this.state.allProduct.length !== 0) ?
							<Fragment>
								{this.state.allProduct.map((item, index) => {
									return <HomeSearchProducts key={index} product={item}
															   classes="H_S_P_Text"
															   highLightText={this.state.highLightText}
															   onNavigateToService={this.props.onNavigateToService}/>;
								})}


							</Fragment>
							: <Fragment>
								<HomeSearchEmpty
									onSupport={this.onSupport}
									isFocus={this.state.isFocus}/>

							</Fragment>}

					</div> :
					<div>
						<HomeSearchEmptyState onSupport={this.onSupport}
											  isFocus={this.state.isFocus}/>
					</div>
				}
			</Fragment>
		);
	}
}
const mapStateToProps = state => ({
	...state.home,
});
export default connect(mapStateToProps)(HomeSearch);