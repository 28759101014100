import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';

import { onNeverMind, onCancelJobRequest } from '../../../../utils/redux/actions/chatActions';
import buttonTimeout from '../../../../utils/functions/button-timeout';
import {ReactComponent as ChatSupport} from "../../../../v5-icons/chat-support.svg";
import { onGetCancelJobReasons } from "../../../../utils/redux/actions/chatActions/actions/chat-cancel-job";

const CancelSchema = Yup.object().shape({
    reason: Yup.string()
        .required('Required'),
    explanation: Yup.string()
        .when('reason', {
            is: 'Other',
            then: Yup.string().required(<p>*</p>),
        }),
});

/**
 * Create array of radio box inputs
 * @param {*} handleChange 
 * @param {*} handleBlur 
 */
function inputsArray(cancelReasons, handleChange, handleBlur) {

    return cancelReasons.map((title, index) => {
        return (
			<li key={index}>
				<input
					onChange={handleChange}
					onBlur={handleBlur}
					type="radio"
					id={index + "-option"}
					value={title}
					defaultChecked={index === 0}
					name="reason"
				/>
				<div className="check">
					<label htmlFor={index + "-option"}>{title}</label>
				</div>
			</li> 
		);
    });
}

function JobChatHistoryCancelForm(props) {

	useEffect(() => {
		props.dispatch(onGetCancelJobReasons());
	}, []);

    return (
		<Fragment>
			{props.chat.cancelReasons?.[0] && (<Formik
				initialValues={{
					reason: props.chat.cancelReasons[0],
					explanation: "",
				}}
				validationSchema={CancelSchema}
				onSubmit={(values, { setSubmitting }) => {
					props.dispatch(onCancelJobRequest(values));
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						<div className="form-cancel-job">
							<ul>{inputsArray(props.chat.cancelReasons, handleChange, handleBlur)}</ul>
							<textarea
								rows={4}
								onChange={handleChange}
								onBlur={handleBlur}
								name="explanation"
								placeholder="Other Reason"
								className="Job-chat-history-cancel-textarea"
							></textarea>
							{errors.explanation && touched.explanation && errors.explanation}
						</div>
						<div className="Job-chat-modal-button-container">
							<Button type="submit" className="retry-button">
								Confirm Cancel
							</Button>
							<Button onClick={(e) => buttonTimeout(() => props.dispatch(onNeverMind()))} type="button"
									className="cancel-button">
								Back
							</Button>
						</div>
						<div className="chat-with-support" onClick={props.navigateToSupport}>
							<ChatSupport />
							<span>Get Help</span>
						</div>
					</form>
				)}
			</Formik>)}
		</Fragment>
	);
}

const mapStateToProps = state => ({
    jobs: state.jobs,
    chat: state.chat,
    common: state.common,
});

export default connect(mapStateToProps)(JobChatHistoryCancelForm);