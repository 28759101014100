import React from 'react';

import './loading-ring.css';

export default function LoadingRing() {
  return (
    <React.Fragment>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </React.Fragment>
  );
}
