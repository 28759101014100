import _ from 'lodash';
import { push } from "connected-react-router";

import * as DISPATCH_STATE from '../chatChangeState';
import * as CONSTANT_ACTIONS from '../chatConstant';
import * as CONSTANT from "../../jobsActions/jobsConstant";
import { GraphRequest } from '../../../../../axios';
import { createMarkerPartnerLocation, removeMarkerForSelectingLocation } from '../../mapActions';
import { mapCreateMarkerUnSetPosition } from "../../mapActions/mapChangeState";
import { appFetchFailure } from '../../commonActions';
import { setJobState } from "../../jobsActions/jobsChangeState";
import { createMarkerWithoutPartnerLocation, fitBoundGoogleMapMarkers } from "../../mapActions/actions/map-marker";
import { JOB_STATE_QUEUED } from "../../jobsActions/jobsConstant";

/**
 * When user click live partner location on option menu in chat page
 */
export const onLivePartnerLocation = (jobId) => {
    return (dispatch, getState) => {
        dispatch(getPartnerLifeCycle(jobId))
            .then((res) => {
                if (!_.isNull(res.data) && res.data.request) {
                    dispatch(setJobState(res.data.request.state));
                    if(res.data.request.state === CONSTANT.JOB_STATE_QUEUED && getState().jobs.job.product.mode.includes("MY_MALL")) {
                        dispatch(createMarkerWithoutPartnerLocation())
                    } else {
                        dispatch(createMarkerPartnerLocation(res.data.request.partnerLifeCycle.location));
                        dispatch(setIntervalPartnerLiveLocation(jobId));
                    }
                }
            });
    }
}
export const setIntervalPartnerLiveLocation = (jobId) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.chatOnLiveLocationPartner(setInterval(() => {
            dispatch(getPartnerLifeCycle(jobId))
            .then((res) => {
                if (!_.isNull(res?.data) && res?.data.request) {
                    if (res.data.request.state !== CONSTANT.JOB_STATE_COMPLETED) {
                        if(getState().jobs.job.product.mode.includes("MY_MALL")) {
                            dispatch(createMarkerWithoutPartnerLocation())
                        } else {
                            dispatch(createMarkerPartnerLocation(res.data.request.partnerLifeCycle.location));
                        }
						dispatch(setJobState(res.data.request.state));
					} else {
                        window.location.replace("/home");
                    }
                }});
        }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_LOCATION_PARTNER)));
    }
}

export const getPartnerLifeCycle = (jobId) => {
    return async (dispatch, getState) => {
        const getLocationrequestBody = {
			query: `query GetRequest($requestId: Int!) {
                request(requestId: $requestId) {
                    id
                    state
                    partnerLifeCycle {
                        location {
                            lat
                            lng
                        }
                    }
                }}`,
			variables: {
				requestId: Number(jobId),
			},
		};
        try {
            const { data } = await GraphRequest.all(getLocationrequestBody);
            return data;
        
        } catch(err) {
            return dispatch(appFetchFailure(err));
        }
    }
}
/**
 * When user click close live partner location on option menu in chat page
 */
export const onCloseLivePartnerLocation = () => {
    return (dispatch, getState) => {
        dispatch(removeMarkerForSelectingLocation());
        clearInterval(getState().chat.liveInternal);
        dispatch(DISPATCH_STATE.chatOnLiveLocationPartnerClose());
        dispatch(mapCreateMarkerUnSetPosition());
    }
}