import React, { Fragment, Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import ProfileFormSelectCountry from './select-country/profile-form-select-country';
import FormInput from '../../../register/form/input/form-input';
import CustomButton from '../../../../components/ui-elements/CustomButton/CustomButton';
const SignupSchema = Yup.object().shape({
    givenName: Yup.string()
        .required('Fist Name is required'),
    lastName: Yup.string()
        .required('Last Name is required'),
    emailAddress: Yup.string()
        .trim()
        .email('Invalid email address')
        .required('Email Address is required'),
});
export default class ProfileForm extends Component {

    profileFormSelectRef = React.createRef();
    emailAddressRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isChanged: false,
            emailAddressError: null,
            staticCoveredCities: [
                {
                    "_id": 11,
                    "id": 11,
                    "parentId": 2,
                    "type": "City",
                    "name": "Caloocan",
                    "displayValue": "Caloocan",
                    "lat": "14.7566",
                    "lng": "121.0450"
                },
                {
                    "_id": 14,
                    "id": 14,
                    "parentId": 2,
                    "type": "City",
                    "name": "Las Piñas",
                    "displayValue": "Las Piñas",
                    "lat": "14.4445",
                    "lng": "120.9939",
                },
                {
                    "_id": 5,
                    "id": 5,
                    "parentId": 2,
                    "type": "City",
                    "name": "Makati",
                    "displayValue": "Makati",
                    "lat": "14.5547",
                    "lng": "121.0244",
                },
                {
                    "_id": 18,
                    "id": 18,
                    "parentId": 2,
                    "type": "City",
                    "name": "Malabon",
                    "displayValue": "Malabon",
                    "lat": "14.6681",
                    "lng": "120.9658",
                },
                {
                    "_id": 12,
                    "id": 12,
                    "parentId": 2,
                    "type": "City",
                    "name": "Mandaluyong",
                    "displayValue": "Mandaluyong",
                    "lat": "14.5794",
                    "lng": "121.0359",
                },
                {
                    "_id": 3,
                    "id": 3,
                    "parentId": 2,
                    "type": "City",
                    "name": "Manila",
                    "displayValue": "Manila",
                    "lat": "14.5995",
                    "lng": "120.9842",
                },
                {
                    "id": 13,
                    "parentId": 2,
                    "type": "City",
                    "name": "Marikina",
                    "displayValue": "Marikina",
                    "lat": "14.6507",
                    "lng": "121.1029",
                },
                {
                    "_id": 15,
                    "id": 15,
                    "parentId": 2,
                    "type": "City",
                    "name": "Muntinlupa",
                    "displayValue": "Muntinlupa",
                    "lat": "14.4081",
                    "lng": "121.0415",
                },
                {
                    "_id": 17,
                    "id": 17,
                    "parentId": 2,
                    "type": "City",
                    "name": "Navotas",
                    "displayValue": "Navotas",
                    "lat": "14.6732",
                    "lng": "120.9350",
                },
                {
                    "_id": 19,
                    "id": 19,
                    "parentId": 2,
                    "type": "City",
                    "name": "Others",
                    "displayValue": "Others",
                    "lat": "14.5995",
                    "lng": "120.9842",
                },
                {
                    "_id": 568,
                    "id": 568,
                    "parentId": 2,
                    "type": "City",
                    "name": "Parañaque",
                    "displayValue": "Parañaque",
                    "lat": "14.4793",
                    "lng": "121.0198",
                },
                {
                    "_id": 6,
                    "id": 6,
                    "parentId": 2,
                    "type": "City",
                    "name": "Pasay",
                    "displayValue": "Pasay",
                    "lat": "14.5378",
                    "lng": "121.0014",
                },
                {
                    "_id": 9,
                    "id": 9,
                    "parentId": 2,
                    "type": "City",
                    "name": "Pasig",
                    "displayValue": "Pasig",
                    "lat": "14.5764",
                    "lng": "121.0851",
                },
                {
                    "_id": 20,
                    "id": 20,
                    "parentId": 2,
                    "type": "City",
                    "name": "Pateros",
                    "displayValue": "Pateros",
                    "lat": "14.5454",
                    "lng": "121.0687",
                },
                {
                    "_id": 4,
                    "id": 4,
                    "parentId": 2,
                    "type": "City",
                    "name": "Quezon City",
                    "displayValue": "Quezon City",
                    "lat": "14.6760",
                    "lng": "121.0437",
                },
                {
                    "_id": 8,
                    "id": 8,
                    "parentId": 2,
                    "type": "City",
                    "name": "San Juan",
                    "displayValue": "San Juan",
                    "lat": "14.6019",
                    "lng": "121.0355",
                },
                {
                    "_id": 7,
                    "id": 7,
                    "parentId": 2,
                    "type": "City",
                    "name": "Taguig",
                    "displayValue": "Taguig",
                    "lat": "14.5176",
                    "lng": "121.0509",
                },
                {
                    "_id": 16,
                    "id": 16,
                    "parentId": 2,
                    "type": "City",
                    "name": "Valenzuela",
                    "displayValue": "Valenzuela",
                    "lat": "14.7011",
                    "lng": "120.9830",
                }
            ]
        }
    }

    onSubmit(values) {
        this.props.onSubmit(values);
    }

    formikChanges(e) {
        if (!this.state.isChanged) {
            this.setState({ isChanged: true });
        }
        if (e.target.name === "emailAddress") {
            this.validateEmail(e.target.value);
        }
    }

    toggleFocusedClass(e) {
        if (e.target.parentNode.classList.contains('focused')) {
            e.target.parentNode.classList.remove('focused');
        } else {
            e.target.parentNode.classList.add('focused');
        }
        if (_.isUndefined(this.validateEmail(this.emailAddressRef.current.value))) {
			this.setState(() => ({
				emailAddressError: null,
            }));
		} else {
			this.validateEmail(this.emailAddressRef.current.value);
		}
    }

    onDiscardChanges = () => {
        this.props.history.goBack();
    }

	validateEmail(value) {
        let error;
		if (
			value &&
			!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(value)
		) {
			error = "Email Address is not valid";
		} else if (!value.length) {
            error = "Email Address is Required";
        }
        this.setState(() => ({
            emailAddressError: error
        }))
		return error;
	}

    render() {
        const countryCode = "+" + this.props.client.mobileNumber.countryCode.code;
        return (
			<Fragment>
				<div className="profile-form">
					<Formik
						initialValues={{
							givenName: this.props.client.givenName,
							lastName: this.props.client.lastName,
							mobileNumber: this.props.client.mobileNumber.number,
							emailAddress: this.props.client.emailAddress,
                            countryCode: "+" + this.props.client.mobileNumber.countryCode.code,
						}}
						validationSchema={SignupSchema}
						onSubmit={(values, { setSubmitting }) => {
							this.onSubmit(values, setSubmitting);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							isSubmitting,
							isValid,
							dirty,
							/* and other goodies */
						}) => (
                            <form onSubmit={handleSubmit}>
								<div className="client-avatar-name">
									<div className="client-name-input">
										<div className="form-group">
											<FormInput
												placeholder="First Name"
												label="First Name"
												name="givenName"
												onChange={(e) => {
													this.formikChanges(e);
													handleChange(e);
												}}
												onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
												onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
												error={errors.givenName && touched.givenName && errors.givenName}
												value={values.givenName}
											/>
										</div>
										<div className="form-group">
											<FormInput
												placeholder="Last Name"
												label="Last Name"
												name="lastName"
												onChange={(e) => {
													this.formikChanges(e);
													handleChange(e);
												}}
												onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
												onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
												value={values.lastName}
												error={errors.lastName && touched.lastName && errors.lastName}
											/>
										</div>
									</div>
								</div>
								<div className="client-mobile-number">
									<div className="form-group select-country">
										<ProfileFormSelectCountry
											options={this.props.countries}
											profileref={this.profileFormSelectRef}
											paddingleft={this.state.selectCountryPaddingLeft}
											placeholder="Country Code"
											label="Country Code"
											name="countryCode"
											onChange={(e) => {
												this.formikChanges(e);
												handleChange(e);
											}}
											value={countryCode}
											disabled
										/>
									</div>
									<div className="form-group mobile-number">
										<FormInput
											placeholder="Phone Number"
											label="Mobile Number"
											name="mobileNumber"
											onChange={(e) => {
												this.formikChanges(e);
												handleChange(e);
											}}
											onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											value={values.mobileNumber}
											disabled
										/>
									</div>
								</div>
								<div className="form-group">
									<FormInput
										placeholder="Email Address"
										forminputref={this.emailAddressRef}
										label="Email Address"
										name="emailAddress"
										onChange={(e) => {
											this.formikChanges(e);
											handleChange(e);
										}}
										onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
										onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
										value={values.emailAddress}
										error={this.state.emailAddressError}
										autoComplete="off"
									/>
								</div>
								<div className="edit-profile-action">
									<CustomButton
										disabled={!dirty || !_.isNull(this.state.emailAddressError)}
										title="Update Profile"
										type="submit"
										className="primary-button"
									/>
									<CustomButton onClick={this.onDiscardChanges} title="Cancel" type="button" classList="error-button" />
								</div>
							</form>
						)}
					</Formik>
				</div>
			</Fragment>
		);
    }
}