import React, {Fragment} from 'react';
import './job-chat-history-send.css';
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

export default function JobChatHistorySend(props) {

    return (
        <Fragment>
            <button disabled={props.disabled} 
                    className="Job_Chat_History_Send"
                    onClick={props.onClick} type="button">
                <DynamicIcon icon="WhiteUpArrow" classes="White_Up_Arrow"/>

            </button>
        </Fragment>
    );
}