import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { initGCash, availableMethods } from "../../../../../utils/redux/actions/billingActions";
import { toggleGcashBottomSheet } from "../../../../../utils/redux/actions/billingActions/billingChangeState";

import CustomButton from "../../../../ui-elements/CustomButton/CustomButton";
import WhiteLoading from "../../../../ui-elements/Loading/WhiteLoading";
class BillingGCash extends Component {
	state = {
		card: null,
	};

	componentDidMount() {
		if (!_.isNull(this.props.availableMethods)) {
			let card = this.props.dispatch(initGCash(this.props.availableMethods));
			this.setState({ card });
		} else {
			this.props.dispatch(availableMethods()).then((res) => {
				let card = this.props.dispatch(initGCash(res));
				this.setState({ card });
			});
		}
	}

	onAddCard = (e) => {
		this.state.card.submit();
	};
	render() {
		return (
			<Fragment>
				<div className="add-gcash-container">
					<h3 className="title">Add Your GCash</h3>
					<p className="body">Easy + Secure Payments, brought to you by GCash x mymall.</p>
					<p className="body">We'll charge ₱1 to validate your GCash. You can use it on your next job!</p>
					<div className="add-new-card-container" id="gcash-container"></div>
					<div className="cta-gcash">
						<CustomButton
							title="Nevermind"
							onClick={() => this.props.dispatch(toggleGcashBottomSheet())}
							outline
							classList="nevermind-add-gcash"
							variant="outlined"
						/>
						<button onClick={this.onAddCard} className="adyen-checkout__button adyen-checkout__button--pay" type="button">
							<span className="adyen-checkout__button__content">
								{this.props.gcashLoading ? (
									<div className="gcash-button-loading">
										<WhiteLoading />
									</div>
								) : (
									<span className="adyen-checkout__button__text">Connect</span>
								)}
							</span>
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.billing,
});

export default connect(mapStateToProps)(BillingGCash);
