import _ from 'lodash';
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import * as DISPATCH_STATE from '../chatChangeState';
import { GraphRequest, networkCall } from "../../../../../axios";
import { fetchAllMessages } from "./chat-connecting";
import { appFetchFailure } from "../../commonActions";
import {
	appLoadingBegin,
	appLoadingEnd,
	storeBookingUrl,
	xenditMinLimitBottomSheet,
} from "../../commonActions/commonChangeState";
import { handleAction } from "../../billingActions";
import $ from "jquery";
import { toggleTransfer3DsBottomSheet } from "../chatChangeState";
import { togglePaymentMethodsBottomSheet } from "../../bookingActions/bookingChangeState";

export const submitRequestMkForm = (requestFormData) => {
    return (dispatch, getState) => {
        const requestMyKoinBody = {
			query: `mutation requestMyKoin($jobId: Int!, $data: ClientRequestMykoinInput!) {
                requestMykoin(jobId: $jobId, requestMykoinData: $data) {
                    id
                    state
                }
            }`,
			variables: {
				data: requestFormData.data, 
                jobId: Number(requestFormData.jobId)
			},
		};
        dispatch(DISPATCH_STATE.sendMoneyLoading(true));
        GraphRequest.all(requestMyKoinBody)
			.then((res) => {
				dispatch(DISPATCH_STATE.sendMoneyLoading(false));
				if (res && res.data && !_.isNull(res.data.data) && res.data.data.requestMykoin) {
					dispatch(backToTransactionsHistory(res.data.data.requestMykoin.id));
				} else {
                    dispatch(DISPATCH_STATE.sendMoneyLoading(false));
					dispatch(appFetchFailure(res.data.errors[0].message));
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			}).catch((err) => {
				dispatch(DISPATCH_STATE.sendMoneyLoading(false));
				dispatch(appFetchFailure(err.response.data.errors[0].message));
				toast(`${err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
    }
}

export const submitSendMkForm = (sendFormData) => {
    return (dispatch, getState) => {
        const sendMyKoinBody = {
			query: `mutation transferMykoin($jobId: Int!, $data: ClientRequestMykoinInput!) {
                transferMykoin(jobId: $jobId, requestMykoinData: $data) {
                    id
                    state
                }
            }`,
			variables: {
				data: sendFormData.data, 
                jobId: Number(sendFormData.jobId)
			},
		};
        dispatch(DISPATCH_STATE.sendMoneyLoading(true));
        GraphRequest.all(sendMyKoinBody)
			.then((res) => {
				dispatch(DISPATCH_STATE.sendMoneyLoading(false));
				if (res && res.data && !_.isNull(res.data.data) && res.data.data.transferMykoin) {
					dispatch(DISPATCH_STATE.toggleTransferMKConfirmation());
					dispatch(backToTransactionsHistory(res.data.data.transferMykoin.id));
				} else {
					dispatch(DISPATCH_STATE.toggleTransferMKConfirmation());
					if (res.data.errors[0].extensions?.code === 402) {
						const details = res.data.errors[0].extensions?.details;
						if (_.has(details, 'action')) {
							dispatch(handleAction(details));
						}
					} else {
						dispatch(appFetchFailure(res.data.errors[0].message));
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
				}
			}).catch((err) => {
                dispatch(DISPATCH_STATE.sendMoneyLoading(false));
                dispatch(DISPATCH_STATE.toggleTransferMKConfirmation());
				dispatch(appFetchFailure(err.response.data.errors[0].message));
				toast(`${err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
    }
}

export const backToTransactionsHistory = (jobId) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.backToTransactionsHistoryDispatch());
        dispatch(push(`/jobs/${Number(jobId)}/chat/history-transactions`));
    }
}

export const acceptSendMk = (jobId, sendFormData = {}) => {
    return (dispatch, getState) => {
        const acceptTransferMykoinRequestBody = {
			query: `mutation acceptTransferMykoinRequest($jobId: Int!, $transferMykoinId: Int!, $requestMykoinData: ClientAcceptRequestMykoinInput!) {
                acceptTransferMykoinRequest(jobId: $jobId, transferMykoinId: $transferMykoinId, requestMykoinData: $requestMykoinData) {
                    id
                    state
                }
            }`,
			variables: {
				transferMykoinId: getState().chat.transferMkId, 
                jobId: _.isUndefined(jobId) ? getState().jobs.job.id : Number(jobId),
				requestMykoinData: sendFormData ? { ...sendFormData } : undefined,
			},
		};
        dispatch(appLoadingBegin());
        networkCall(acceptTransferMykoinRequestBody)
            .then((res) => {
                if(res.data && res.data.acceptTransferMykoinRequest) {
                    dispatch(DISPATCH_STATE.confirmSendMk(null, null));
                    if(!_.isUndefined(jobId)) {
                        dispatch(fetchHistoryTransactions(jobId));
                        dispatch(appLoadingEnd());
                    } else {
                        dispatch(fetchAllMessages(getState().jobs.job.id));
                        dispatch(appLoadingEnd());
                    }
                } else {
					dispatch(appLoadingEnd());
					if (res.errors[0].extensions?.code === 402) {
						const details = res.errors[0].extensions?.details;
						if (_.has(details, 'action')) {
							dispatch(handleAction(details));
						}
					} else {
						dispatch(appFetchFailure(res.errors[0].message));
						toast(`${res.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
					dispatch(DISPATCH_STATE.confirmSendMk(null, null));
                }
            })
    }
}

export const acceptXenditSendMk = (jobId, authenticationId = null) => {
    return (dispatch, getState) => {
        const acceptTransferMykoinRequestBody = {
			query: `mutation acceptXenditTransferMykoinRequest($jobId: Int!, $transferMykoinId: Int!, $authenticationId: String) {
                acceptXenditTransferMykoinRequest(jobId: $jobId, transferMykoinId: $transferMykoinId, authenticationId: $authenticationId) {
                    id
                    state
                }
            }`,
			variables: {
				transferMykoinId: getState().chat.transferMkId,
			jobId: _.isUndefined(jobId) ? getState().jobs.job.id : Number(jobId),
				authenticationId: authenticationId || null
			},
		};
        dispatch(appLoadingBegin());
        networkCall(acceptTransferMykoinRequestBody)
            .then((res) => {
                if(res.data && res.data.acceptXenditTransferMykoinRequest) {
					if (getState().chat.toggleConfirmationSendMkBottomSheet) {
						dispatch(DISPATCH_STATE.confirmSendMk(null, null));
					}
                    if(!_.isUndefined(jobId)) {
                        dispatch(fetchHistoryTransactions(jobId));
                        dispatch(appLoadingEnd());
                    } else {
                        dispatch(fetchAllMessages(getState().jobs.job.id));
                        dispatch(appLoadingEnd());
                    }
                } else {
					if (res.errors[0].extensions?.code === 402) {
						dispatch(appLoadingEnd());
						dispatch(DISPATCH_STATE.confirmSendMk(null, null));
						dispatch(togglePaymentMethodsBottomSheet());
					} else if (res.errors[0].extensions?.code === 406) {
						const totalTransferAmount = Math.ceil(getState().chat.transferRequested + getState().chat.transferFee)
						window.Xendit.card.createAuthentication({
							"amount": totalTransferAmount < 200 ? 200 : totalTransferAmount,
							"token_id": res.errors[0].extensions?.paymentMethod?.creditCardTokenId
						}, (err, data) => {
							if (err) {
								dispatch(appLoadingEnd());
								dispatch(toggleTransfer3DsBottomSheet(false))
							}
							if (data.status === 'VERIFIED') {
								dispatch(acceptXenditSendMk(undefined, data.id))
								dispatch(xenditMinLimitBottomSheet(false))
								dispatch(toggleTransfer3DsBottomSheet(false))
							} else if (data.status === 'IN_REVIEW') {
								dispatch(toggleTransfer3DsBottomSheet(true))
								dispatch(appLoadingEnd());
								window.open(data.payer_authentication_url, 'transfer-inline-frame');
							} else if (data.status === 'FAILED') {
								dispatch(appLoadingEnd());
								dispatch(toggleTransfer3DsBottomSheet(false))
							}
						});
					} else {
						dispatch(appLoadingEnd());
						dispatch(appFetchFailure(res.errors[0].message));
						toast(`${res.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
					// dispatch(DISPATCH_STATE.confirmSendMk(null, null));
                }
            })
    }
}

export const rejecttSendMk = (jobId) => {
    return (dispatch, getState) => {
        const rejectTransferMykoinRequestBody = {
			query: `mutation rejectTransferMykoinRequest($jobId: Int!, $transferMykoinId: Int!) {
                rejectTransferMykoinRequest(jobId: $jobId, transferMykoinId: $transferMykoinId) {
                    id
                    state
                }
            }`,
			variables: {
				transferMykoinId: getState().chat.transferMkId, 
                jobId: _.isUndefined(jobId) ? getState().jobs.job.id : Number(jobId)
			},
		};
        dispatch(appLoadingBegin());
        networkCall(rejectTransferMykoinRequestBody)
            .then((res) => {
                if(res.data && res.data.rejectTransferMykoinRequest) {
                    dispatch(DISPATCH_STATE.refuseSendMk(null, null));
                    if(!_.isUndefined(jobId)) {
                        dispatch(fetchHistoryTransactions(jobId));
                        dispatch(appLoadingEnd());
                    } else {
                        dispatch(fetchAllMessages(getState().jobs.job.id));
                        dispatch(appLoadingEnd());
                    }
                } else {
                    dispatch(DISPATCH_STATE.refuseSendMk(null, null));
                    dispatch(appFetchFailure(res.errors[0].message));
					toast(`${res.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
                }
            })
    }
}

export const fetchHistoryTransactions = (jobId) => {
    return dispatch => {
        const koinTransferHistoryBody = {
            query: `query koinTransferHistory($id: Int!) {
                request(requestId: $id) {
                    transferMykoins {
                        id
                        amount
                        from
                        to
                        note
                        createdAt
                        createdBy
                        state
                    }
                }}`,
            variables: {
                id: Number(jobId)
            },
        };

        dispatch(DISPATCH_STATE.mkTransactionsFetching(true));
        networkCall(koinTransferHistoryBody)
            .then((res) => {
                dispatch(DISPATCH_STATE.mkTransactionsFetching(false));
                if(res.data && res.data.request) {
                    if(!_.isNull(res.data.request.transferMykoins)) {
                        dispatch(sortTransactionHistory(res.data.request.transferMykoins));
                    }
                } else {
                    dispatch(appFetchFailure(res.errors[0].message));
					toast(`${res.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
                }
            })
    }
}

export const sortTransactionHistory = (array) => {
    return dispatch => {
        const sortedArray = array.sort(function(a,b){
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
        dispatch(seprationSentAndRecievedMk(sortedArray));
    }
}

export const seprationSentAndRecievedMk = (items) => {
    return dispatch => {
        let sentMk = [];
        let recievedMk = [];
        items.map((item) => {
            if(item.from === "TRANSFER_ACTOR_CLIENT") {
                sentMk.push(item)
                dispatch(DISPATCH_STATE.sentMkTransactionHistory(sentMk))

            } else if(item.to === "TRANSFER_ACTOR_CLIENT") {
                recievedMk.push(item)
                dispatch(DISPATCH_STATE.recievedMkTransactionHistory(recievedMk))
            }
        })
    }
}