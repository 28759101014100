import {
	FETCH_BOOKING_BEGIN,
	FETCH_BOOKING_SUCCESS,
	FETCH_JOB_EXTEND_PRICE_SUCCESS,
	BOOKING_TOGGLE_SHOW_DESCRIPTION,
	BOOKING_SET_PRODUCT_FROM_HOME,
	BOOKING_TOGGLE_SHOW_OPTION,
	BOOKING_ON_CHANGE_SPECIAL_TEXT,
	BOOKING_WILL_UNMOUNT,
	BOOKING_ON_REQUEST_NOW_BUTTON_CLICK,
	BOOKING_EMPTY_NOTE,
	BOOKING_ERROR_LOCATION_PRICE,
	BOOKING_UN_SET_ERROR_LOCATION_PRICE,
	BOOKING_REMOVE_PRICE,
	BOOKING_SET_CURRENT_PRICE,
	BOOKING_GET_SELECTED_OPTIONS,
	BOOKING_SET_NULL_SELECTED_OPTIONS,
	FETCH_BOOKING_V5_SUCCESS,
	CLOSE_LOCATION_SEARCH_BOTTOM_SHEET,
	OPEN_LOCATION_SEARCH_BOTTOM_SHEET,
	ENABLE_ADD_NEXT_STOP,
	FETCH_PRICE_SUCCESS,
	FETCH_PRICE_BEGIN,
	CHANGE_BOOKING_TIME_FORMAT,
	CLIENT_HAS_PAYMENT_METHOD,
	SET_PAYMENT_METHODS,
	CLOSE_BOOKING_PAYMENT_BOTTOM_SHEET,
	OPEN_BOOKING_PAYMENT_BOTTOM_SHEET,
	SET_IS_INSTANT_MATCH,
	FETCH_PAYMENT_METHOD_SUCCESS,
	CHANGE_PRIMARY_PAYMENT,
	CLIENT_HAS_REJECTED_PAYMENT,
	TOGGLE_TOPUP_BOTTOM_SHEET,
	SET_TOP_UP_AMOUNT,
	SET_PRODUCT_ON_REVIEW_BOOKING,
	SET_PRICE_ON_REVIEW_BOOKING,
	TOGGLE_PAYMENT_METHODS_BOTTOM_SHEET,
	TOGGLE_REJECTED_PAYMENT,
	TOGGLE_RETRY_FAILED,
	TOGGLE_CANCEL_BOOKING_BOTTOM_SHEET,
	WILL_UN_MOUNT_REVIEW_BOOKING,
	RETRY_WITH_TOPUP,
	RETRY_WITH_CARD,
	SET_DEFAULT_RETRY_WITH_TOPUP,
	SET_DEFAULT_RETRY_WITH_CARD,
	SET_DEFAULT_VALUE_REJECTED_PAYMENT,
	TOGGLE_PROMO_CODE_BOTTOM_SHEET,
	SET_PROMO_CODE,
	SET_NEW_PRICE,
	SET_AMOUNT_OFF,
	SET_PERCENT_OFF,
	REMOVE_PROMO_CODE,
	RESULT_APPPLIED_PROMO_CODE,
	ADD_TEMPORARY_PROMO_CODE,
	CHANGE_PROMO_CODE,
	VOIP_IS_SUPPORTED,
	OPEN_QUEUED_JOB_BOTTOM_SHEET,
	CLOSE_QUEUED_JOB_BOTTOM_SHEET,
	SET_QUEUED_JOB_TIME,
	TOGGLE_SCHEDULE_BOOKING,
	DISPATCH_SET_DEFAULT_SCHEDULE_DATE,
	SET_SCHEDULE_BOOKING_DATE,
	SET_ACTIVE_TAB,
	TOGGLE_REQUEST_PLACED_MODAL,
	SET_MAX_DATE,
	SET_MIN_DATE,
	TOGGLE_CONFIRMATION_BOTTOM_SHEET,
	SET_DEFAULT_CONFIRMATION_BOTTOM_SHEET,
	PRODUCT_MODE_UNMOUNT,
	SET_PRICE_WITH_APLLIED_PROMO_CODE,
	SET_LOCATIONS_LENGTH,
	SET_SELECTED_QUEUED,
	EMPTY_PRODUCT_OBJECT,
	FETCH_PRICE_LOADING,
	FETCH_PRICE_LOCATION_LOADING,
	GET_JOB_LOADING,
	SCHEDULE_LOADING,
	QUEUED_LOADING,
	PROMO_CODE_LOADING,
	EXTENSION_REQUEST_LOADING,
} from "../actions/bookingActions";
  
    const initialState = {
		product: {},
		prices: {},
		isDescription: false,
		isOption: false, // for show and hidden options,
		note: "",
		currentPrice: 0,
		createReqeustObj: null,
		errorPrice: false, // boolean when error price occured
		errorPriceMessage: null, // error message text for price error
		selectedOptions: null,
		formattedTime: null,
		clientHasPaymentMethod: null,
		primaryPayment: null,
		paymentMethods: null,
		bookingPaymentBottomSheet: null,
		isInstantMatch: null,
		hasRejectedPayment: null,
		isShowTopUpBottomSheet: false,
		topUpMyKoinAmount: null,
		isOpenPaymentMethodsBottomSheet: false,
		isOpenRejectedPaymentBottomSheet: false,
		isOpenCancelBookingBottomSheet: false,
		retryWithTopUp: null,
		retryWithCard: null,
		isEnableNextStop: false,
		isOpenPromoCodeBottomSheet: false,
		promoCode: null,
		tempPromoCode: null,
		newPrice: null,
		amountOff: null,
		percentOff: null,
		removePromoCode: false,
		promoCodeResult: null,
		changePromoCode: false,
		voipIsSupported: false,
		voipMode: [],
		queuedJobBottomSheet: false,
		queuedJobTime: null,
		scheduleBookingBottomSheet: false,
		scheduleDate: null,
		scheduleBookingDate: null,
		activeTab: null,
		requestPlacedModal: null,
		maxScheduleDate: null,
		minScheduleDate: null,
		confirmationBottomSheet: null,
		priceWithAppliedCode: null,
		locationLength: null,
		queueType: null,
		locationSearchBottomSheet: false,
		fetchPriceLoading: false,
		fetchPriceLocationLoading: false,
		getJobLoading: false,
		scheduleLoading: false,
		queuedLoading: false,
		promoCodeLoading: false,
		extensionRequestLoading: false,
	};
    
    export default function bookingReducer(state = initialState, action) {
      switch (action.type) {
			case FETCH_BOOKING_BEGIN:
				return {
					...state,
				};
			case FETCH_JOB_EXTEND_PRICE_SUCCESS:
				return {
					...state,
					prices: action.payload,
				};
			case BOOKING_SET_CURRENT_PRICE:
				return {
					...state,
					currentPrice: action.payload,
				};
			case BOOKING_SET_PRODUCT_FROM_HOME:
				return {
					...state,
					product: action.payload,
				};
			case BOOKING_TOGGLE_SHOW_DESCRIPTION:
				return {
					...state,
					isDescription: !state.isDescription,
				};
			case BOOKING_TOGGLE_SHOW_OPTION:
				return {
					...state,
					isOption: !state.isOption,
				};
			case BOOKING_ON_CHANGE_SPECIAL_TEXT:
				return {
					...state,
					note: action.payload,
				};
			case BOOKING_ON_REQUEST_NOW_BUTTON_CLICK:
				return {
					...state,
					createReqeustObj: action.payload,
				};
			case BOOKING_WILL_UNMOUNT:
				return {
					prices: {},
					isDescription: false,
					isOption: false,
					note: "",
					currentPrice: 0,
				};
			case BOOKING_EMPTY_NOTE:
				return {
					...state,
					note: "",
				};
			case BOOKING_ERROR_LOCATION_PRICE:
				return {
					...state,
					errorPrice: true,
					errorPriceMessage: action.payload,
				};
			case BOOKING_UN_SET_ERROR_LOCATION_PRICE:
				return {
					...state,
					errorPrice: false,
					errorPriceMessage: null,
				};
			case BOOKING_REMOVE_PRICE:
				return {
					...state,
					prices: {},
				};
			case BOOKING_GET_SELECTED_OPTIONS:
				return {
					...state,
					selectedOptions: action.payload,
				};
			case BOOKING_SET_NULL_SELECTED_OPTIONS:
				return {
					...state,
					selectedOptions: null,
				};
			case FETCH_BOOKING_V5_SUCCESS:
				return {
					...state,
					product: action.payload,
				};
			case OPEN_LOCATION_SEARCH_BOTTOM_SHEET:
				return {
					...state,
					locationSearchBottomSheet: true,
				};
			case CLOSE_LOCATION_SEARCH_BOTTOM_SHEET:
				return {
					...state,
					locationSearchBottomSheet: false,
				};
			case ENABLE_ADD_NEXT_STOP:
				return {
					...state,
					isEnableNextStop: true,
				};
			case FETCH_PRICE_SUCCESS:
				return {
					...state,
					prices: action.payload !== undefined ? action.payload : {},
					currentPrice:
						action.payload !== undefined
							? state.currentPrice === 0
								? parseInt(action.payload.minCubeNumbers)
								: state.currentPrice >= parseInt(action.payload.minCubeNumbers)
								? state.currentPrice
								: parseInt(action.payload.minCubeNumbers)
							: state.currentPrice,
				};
			case FETCH_PRICE_BEGIN:
				return {
					...state,
					jobLocations: action.payload,
				};
			case CHANGE_BOOKING_TIME_FORMAT:
				return {
					...state,
					formattedTime: action.payload,
				};
			case CLIENT_HAS_PAYMENT_METHOD:
				return {
					...state,
					clientHasPaymentMethod: true,
					primaryPayment: action.payload,
				};
			case SET_PAYMENT_METHODS:
				return {
					...state,
					paymentMethods: action.payload,
				};
			case CLOSE_BOOKING_PAYMENT_BOTTOM_SHEET:
				return {
					...state,
					bookingPaymentBottomSheet: false,
				};
			case OPEN_BOOKING_PAYMENT_BOTTOM_SHEET:
				return {
					...state,
					bookingPaymentBottomSheet: true,
				};
			case SET_IS_INSTANT_MATCH:
				return {
					...state,
					isInstantMatch: action.payload,
				};
			case FETCH_PAYMENT_METHOD_SUCCESS:
				return {
					...state,
					paymentMethods: action.payload,
				};
			case CHANGE_PRIMARY_PAYMENT:
				return {
					...state,
					primaryPayment: action.payload,
				};
			case CLIENT_HAS_REJECTED_PAYMENT:
				return {
					...state,
					hasRejectedPayment: true,
				};
			case SET_DEFAULT_VALUE_REJECTED_PAYMENT:
				return {
					...state,
					hasRejectedPayment: null,
				};
			case TOGGLE_TOPUP_BOTTOM_SHEET:
				return {
					...state,
					isShowTopUpBottomSheet: !state.isShowTopUpBottomSheet,
				};
			case SET_TOP_UP_AMOUNT:
				return {
					...state,
					topUpMyKoinAmount: action.payload,
				};
			case SET_PRODUCT_ON_REVIEW_BOOKING:
				return {
					...state,
					product: action.payload,
				};
			case SET_PRICE_ON_REVIEW_BOOKING:
				return {
					...state,
					prices: action.payload !== undefined ? action.payload : {},
					currentPrice:
						action.payload !== undefined
							? state.currentPrice === 0
								? parseInt(action.payload.minCubeNumbers)
								: state.currentPrice >= parseInt(action.payload.minCubeNumbers)
								? state.currentPrice
								: parseInt(action.payload.minCubeNumbers)
							: state.currentPrice,
				};
			case TOGGLE_PAYMENT_METHODS_BOTTOM_SHEET:
				return {
					...state,
					isOpenPaymentMethodsBottomSheet: !state.isOpenPaymentMethodsBottomSheet,
				};
			case TOGGLE_REJECTED_PAYMENT:
				return {
					...state,
					isOpenRejectedPaymentBottomSheet: !state.isOpenRejectedPaymentBottomSheet,
				};
			case TOGGLE_RETRY_FAILED:
				return {
					...state,
					isOpenRetryFailedBottomSheet: !state.isOpenRetryFailedBottomSheet,
				};
			case TOGGLE_CANCEL_BOOKING_BOTTOM_SHEET:
				return {
					...state,
					isOpenCancelBookingBottomSheet: !state.isOpenCancelBookingBottomSheet,
				};
			case WILL_UN_MOUNT_REVIEW_BOOKING:
				return {
					...state,
					isOpenCancelBookingBottomSheet: false,
					isOpenRejectedPaymentBottomSheet: false,
					isOpenRetryFailedBottomSheet: false,
					isOpenPaymentMethodsBottomSheet: false,
					hasRejectedPayment: null,
					primaryPayment: null,
					product: {},
					prices: {},
					currentPrice: 0,
				};
			case RETRY_WITH_TOPUP:
				return {
					...state,
					retryWithTopUp: true,
				};
			case SET_DEFAULT_RETRY_WITH_TOPUP:
				return {
					...state,
					retryWithTopUp: null,
				};
			case RETRY_WITH_CARD:
				return {
					...state,
					retryWithCard: true,
				};
			case SET_DEFAULT_RETRY_WITH_CARD:
				return {
					...state,
					retryWithCard: null,
				};
		  	case TOGGLE_PROMO_CODE_BOTTOM_SHEET:
				return {
					...state,
					isOpenPromoCodeBottomSheet: !state.isOpenPromoCodeBottomSheet
				}
		  	case SET_PROMO_CODE:
				return {
					...state,
					promoCode: action.payload
				}
		  	case SET_NEW_PRICE:
				return {
					...state,
					newPrice: action.payload
				}
		  	case SET_AMOUNT_OFF:
			  	return {
				  	...state,
				  	amountOff: action.payload
				}
		  	case SET_PERCENT_OFF:
			  	return {
				  	...state,
				  	percentOff: action.payload
				  }
		  	case REMOVE_PROMO_CODE:
				return {
					...state,
					removePromoCode: action.payload
				}
		  	case RESULT_APPPLIED_PROMO_CODE:
			  	return {
				   ...state,
				   promoCodeResult: action.payload
				}
			case ADD_TEMPORARY_PROMO_CODE:
				return {
					...state,
					tempPromoCode: action.payload
				}
		  	case CHANGE_PROMO_CODE:
			  	return {
				  ...state,
				  changePromoCode: action.payload
			}
			case VOIP_IS_SUPPORTED:
				return {
					...state,
					voipIsSupported: true,
					voipMode: action.payload
				}
		  	case OPEN_QUEUED_JOB_BOTTOM_SHEET:
				return {
					...state,
					queuedJobBottomSheet: true
				}
		  	case CLOSE_QUEUED_JOB_BOTTOM_SHEET:
				return {
					...state,
					queuedJobBottomSheet: false
				}
			case SET_QUEUED_JOB_TIME:
				return {
					...state,
					queuedJobTime: action.payload
				}
			case TOGGLE_SCHEDULE_BOOKING:
				return {
					...state,
					scheduleBookingBottomSheet: !state.scheduleBookingBottomSheet
				}
			case DISPATCH_SET_DEFAULT_SCHEDULE_DATE:
				return {
					...state,
					scheduleDate: action.payload
				}
			case SET_SCHEDULE_BOOKING_DATE:
				return {
					...state,
					scheduleBookingDate: action.payload
				}
			case SET_ACTIVE_TAB:
				return {
					...state,
					activeTab: action.payload
				}
			case TOGGLE_REQUEST_PLACED_MODAL:
				return {
					...state,
					requestPlacedModal: !state.requestPlacedModal
				}
			case SET_MAX_DATE:
				return {
					...state,
					maxScheduleDate:action.payload
				}
			case SET_MIN_DATE:
				return {
					...state,
					minScheduleDate: action.payload
				}
			case TOGGLE_CONFIRMATION_BOTTOM_SHEET:
				return {
					...state,
					confirmationBottomSheet: !state.confirmationBottomSheet
				}
			case SET_DEFAULT_CONFIRMATION_BOTTOM_SHEET:
				return {
					...state,
					confirmationBottomSheet: null
				}
		  	case PRODUCT_MODE_UNMOUNT:
				return {
					...state,
					voipIsSupported: false,
					voipMode: []
				}
			case SET_PRICE_WITH_APLLIED_PROMO_CODE:
				return {
					...state,
					priceWithAppliedCode: action.payload
				}
			case SET_LOCATIONS_LENGTH:
				return {
					...state,
					locationLength: action.payload
				}
			case SET_SELECTED_QUEUED:
				return {
					...state,
					queueType: action.payload
				}
			case EMPTY_PRODUCT_OBJECT:
				return {
					...state,
					product: {}
				}
			case FETCH_PRICE_LOADING:
				return {
					...state,
					fetchPriceLoading: action.payload
				}
			case FETCH_PRICE_LOCATION_LOADING:
				return {
					...state,
					fetchPriceLocationLoading: action.payload
				}
			case GET_JOB_LOADING:
				return {
					...state,
					getJobLoading: action.payload
				}
			case SCHEDULE_LOADING:
				return {
					...state,
					scheduleLoading: action.payload
				}
			case QUEUED_LOADING:
				return {
					...state,
					queuedLoading: action.payload
				}
			case PROMO_CODE_LOADING:
				return {
					...state,
					promoCodeLoading: action.payload
				}
			case EXTENSION_REQUEST_LOADING:
				return {
					...state,
					extensionRequestLoading: action.payload
				}
			default:
				// ALWAYS have a default case in a reducer
				return state;
		}
}