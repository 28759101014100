import _ from 'lodash';
import * as DISPATCH_STATE from '../registerChangeState';

/**
 * start check code counter
 */
export const setIntervalToEnterOtp = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.intervalCheckCodeBegin());
        let countNumber = getState().register.requestNumberCheckCode;
        let interval = setInterval(() => {
            dispatch(registerCheckCodeStartToCount(countNumber));
            countNumber -= 1;
        }, 1000);
        dispatch(DISPATCH_STATE.registerCheckCodeInterval(interval));
    }
}
export const registerCheckCodeStartToCount = (countNumber) => {
    return (dispatch, getState) => {
        if (countNumber >= 0) {
            dispatch(DISPATCH_STATE.registerCheckCodeReduceCount(countNumber));
            // if (countNumber < 10) {
            //     dispatch(DISPATCH_STATE.registerCheckCodeReduceCount('0' + countNumber));
            // } else {
            //     dispatch(DISPATCH_STATE.registerCheckCodeReduceCount(countNumber));
            // }
        } else {
            dispatch(onClearIntervalCheckCodeRequestNumber());
        }
    }
}
/**
 * clear interval of timing send request
 */
export const onClearIntervalCheckCodeRequestNumber = () => {
    return (dispatch, getState) => {
        clearInterval(getState().register.requestNumberCheckCodeInterval);
        dispatch(DISPATCH_STATE.clearIntervalNumberCheckcodeInterval());
    }
}