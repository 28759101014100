import {
	FETCH_PAYMENT_METHOD_SUCCESS,
	FETCH_ACCOUNTING_BALANCE_SUCCESS,
	BILLING_DELETE_PAYMENT_METHOD,
	BILLING_SET_ADYEN,
	BILLING_ERROR_PAYMENT,
	BILLING_UN_SET_ERROR_PAYMENT,
	BILLING_PAYMENT_METHOD_NULL,
	TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET,
	SET_NEW_ACCOUNT_BALANCE,
	DELETE_PAYMENT_ERROR_TEXT,
	SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR,
	SET_CLIENT_BALANCES,
	SUPPORT_G_CASH,
	FETCH_G_CASH_SUCCESS,
	TOGGLE_G_CASH_BOTTOM_SHEET,
	STORE_AVAILABLE_PAYMENT_METHODS,
	TOGGLE_VERIFYING_GCASH_BOTTOM_SHEET,
	IS_SUPPORT_GCASH,
	FETCH_AVAILABLE_METHODS_LOADING,
	FETCH_PAYMENT_METHODS_LOADING,
	BILLING_TOP_UP_MYKOIN,
	GCASH_LOADING,
	PAYMENT_METHODS_LOADING,
	SAVE_GCASH_INFO
} from "../actions/billingActions";
    
const initialState = {
	methods: null,
	balance: null,
	adyen: null,
	errorPayment: false,
	errorPaymentMessage: null,
	isShowTopUpBottomSheet: false,
	errorDeletePaymentText: null,
	isGcashSupported: false,
	gcashItems: null,
	gcashIcon: null,
	gcashBottomSheet: false,
  availableMethods: null,
  verifyingGcashBottomSheet: false,
  availableMethodsLoading: false,
  paymentMethodsLoading: false,
  topUpLoading: false,
  gcashLoading: false,
  paymentLoading: false,
  gcashInfo: null
};
      
export default function billingReducer(state = initialState, action) {
  switch(action.type) {
    case BILLING_DELETE_PAYMENT_METHOD:
      return {
        ...state,
        methods: action.payload[0]['data']
      }
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        methods: action.payload
      }
    case BILLING_PAYMENT_METHOD_NULL:
      return {
        ...state,
        methods: null,
        gcashItems: null,
        isGcashSupported: false,
		};
    case FETCH_ACCOUNTING_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload
      }
    case BILLING_SET_ADYEN:
      return {
        ...state,
        adyen: action.payload
      }
    case BILLING_ERROR_PAYMENT: 
      return {
        ...state,
        errorPayment: true,
        errorPaymentMessage: action.payload
      }
    case BILLING_UN_SET_ERROR_PAYMENT: 
      return {
        ...state,
        errorPayment: false,
        errorPaymentMessage: null
      }
    case TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET:
      return {
        ...state,
        isShowTopUpBottomSheet:!state.isShowTopUpBottomSheet,
      }
    case SET_NEW_ACCOUNT_BALANCE:
      return {
        ...state,
        balance: action.payload
      }
    case DELETE_PAYMENT_ERROR_TEXT:
      return {
        ...state,
        errorDeletePaymentText: action.payload
      }
    case SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR:
      return {
        ...state,
        errorDeletePaymentText: null
      }
    case SET_CLIENT_BALANCES:
      return {
        ...state,
        balance: action.payload
      }
    case SUPPORT_G_CASH:
      return {
        ...state,
        isGcashSupported: true,
        gcashIcon: action.payload
      }
    case IS_SUPPORT_GCASH:
      return {
        ...state,
        isGcashSupported: true
      }
    case FETCH_G_CASH_SUCCESS:
      return {
        ...state,
        gcashItems: action.payload
      }
    case TOGGLE_G_CASH_BOTTOM_SHEET:
      return {
        ...state,
        gcashBottomSheet: !state.gcashBottomSheet
      }
    case STORE_AVAILABLE_PAYMENT_METHODS:
      return {
        ...state,
        availableMethods: action.payload
      }
    case TOGGLE_VERIFYING_GCASH_BOTTOM_SHEET:
      return {
        ...state,
        verifyingGcashBottomSheet: !state.verifyingGcashBottomSheet
      }
    case FETCH_AVAILABLE_METHODS_LOADING:
      return {
        ...state,
        availableMethodsLoading: action.payload
      }
    case FETCH_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        paymentMethodsLoading: action.payload
      }
    case BILLING_TOP_UP_MYKOIN:
      return {
        ...state,
        topUpLoading: action.payload
      }
    case GCASH_LOADING:
      return {
        ...state,
        gcashLoading: action.payload
      }
    case PAYMENT_METHODS_LOADING:
      return {
        ...state,
        paymentLoading: action.payload
      }
	case SAVE_GCASH_INFO:
      return {
        ...state,
        gcashInfo: action.payload
      }
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}