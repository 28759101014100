import React from "react";
import { ReactComponent as Note } from "../../../v5-icons/note-booking.svg";

export default function ReviewBookingNote({ note, handleClickBookingItem }) {
	return (
		<div className="review-booking-item-container booking-note">
			<p className="title">Note To Partner</p>
			<div className="review-booking-item-inner" onClick={handleClickBookingItem}>
				<p>{note.length ? note : "None"}</p>
				<Note />
			</div>
			<span className="hint">You can chat with the partner once matched</span>
		</div>
	);
}
