import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Lozenge from "../ui-elements/lozenge/lozenge";
import Avatar from "../avatar/avatar";
import JobCoPartner from "../job-history/job-co-partner/job-co-partner";
import JobChatHistoryLiveLocation from "../job-history/JobChatHistory/JobChatHistoryLiveLocation/JobChatHistoryLiveLocation";
import FetchingLoading from "../chat-elements/loading/loading";

import backButton from "../../v5-icons/back-button.svg";

import { onCloseLivePartnerLocation, onLivePartnerLocation, backToChatHistory } from "../../utils/redux/actions/chatActions";
import { appGoBack, addAnimateToText } from "../../utils/redux/actions/commonActions";
import genderPatner from "../../utils/functions/gender-partner";
const classNames = require("classnames");

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
class PartnerLiveLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
			innerHeight: null,
			hasFadePropPartner: false,
			hasFadePropTitle: false,
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		
		if (_.isEmpty(this.props.job)) {
			this.handleBackToChatHistory();
		} else {
			this.props.dispatch(onLivePartnerLocation(!_.isEmpty(this.props.job) ? this.props.job.id : this.props.match.params.id));
			this.props
				.dispatch(addAnimateToText(document.querySelector(".long-heading"), document.querySelector(".long-heading .heading")))
				.then((res) => {
					if (res) {
						this.setState(() => ({
							hasFadePropTitle: true,
						}));
					}
				})
				.catch((err) => {});
			this.props
				.dispatch(
					addAnimateToText(document.querySelector(".partner-name-container"), document.querySelector(".partner-name-container .heading")),
				)
				.then((res) => {
					if (res) {
						this.setState(() => ({
							hasFadePropPartner: true,
						}));
					}
				})
				.catch((err) => {});
		}
	}

	componentWillUnmount() {
		this.props.dispatch(onCloseLivePartnerLocation());
	}

	handleBackToChatHistory() {
		const jobId = !_.isEmpty(this.props.job) ? this.props.job.id : this.props.match.params.id;
		if (!_.isNull(this.props.common.lastPathName)) {
			this.props.dispatch(appGoBack(this.props.common.lastPathName));
		} else {
			this.props.dispatch(backToChatHistory(jobId));
		}
		this.props.dispatch(onCloseLivePartnerLocation());
	}
	render() {
		return (
			<>
				{_.isNull(this.props.map.googlePartnerMarkers) ? (
					<div className="live-location-loading-full-width">
						<FetchingLoading />
					</div>
				) : null}
				{!_.isEmpty(this.props.job) ? (
					<div className="partner-live-location-wrapper">
						<div className="partner-live-location-header-container">
							<div className="title-bar-container">
								<div className="title-bar-icon" onClick={(e) => this.handleBackToChatHistory()}>
									<img className="back-button" src={backButton} alt="backButton" />
								</div>
								{!_.isNull(this.props.job.partner) && (
									<div className="profile">
										<div className="profile-img-box">
											<Avatar img={this.props.job.partner.photo} />
										</div>
										<div className="profile-text-box">
											<div className="partner-name-container">
												<span className="heading Job_Chat_History_Header_Name">
													{this.props.job.partner.title ? this.props.job.partner.title : capitalizeFirstLetter(genderPatner(this.props.job.partner))}{" "}
													{capitalizeFirstLetter(this.props.job.partner.givenName)}{" "}
													{capitalizeFirstLetter(this.props.job.partner.lastName)}
												</span>
												{this.state.hasFadePropPartner && (
													<>
														<div className="fader fader-left"></div>
														<div className="fader fader-right"></div>
													</>
												)}
											</div>
											{_.has(this.props.job.partner, "coPartner") && !_.isNull(this.props.job.partner.coPartner) ? (
												!_.isNull(this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name) ? (
													<JobCoPartner
														name={this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name}
													/>
												) : null
											) : null}
										</div>
									</div>
								)}
							</div>
							<div
								className={classNames({
									"partner-live-location-header": true,
									"partner-live-location-padding":
										this.props.jobState === "Queued" && this.props.job.product.mode.includes("MY_MALL"),
								})}
							>
								<div className={classNames("long-heading text-container", {"has-not-fade": !this.state.hasFadePropTitle})}>
									<span className="heading">{this.props.job.product.commercialName}</span>
									{this.state.hasFadePropTitle && (
										<>
											<div className="fader fader-left"></div>
											<div className="fader fader-right"></div>
										</>
									)}
								</div>
								<Lozenge
									title={
										this.props.jobState === "Started"
											? "In Progress"
											: this.props.jobState === "Queued" && this.props.job.product.mode.includes("MY_MALL")
											? "Matched"
											: this.props.jobState
									}
									color={this.props.jobState === "Started" ? "green" : "orange"}
								/>
							</div>
						</div>
						<JobChatHistoryLiveLocation />
					</div>
				) : null}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
    ...state.jobs,
	chat: state.chat,
	map: state.map,
	router: state.router,
	common: state.common,
});

export default connect(mapStateToProps)(PartnerLiveLocation);
