import React, {Fragment} from 'react';
import { VERSION_CWA_NUMBER } from "../../../utils/constants";
import logoutIcon from "../../../assests/icons/account-page/ic_cl_logout@3x.png";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import * as LOG_EVENT from "../../../utils/analytics";
import { toggleLogOutBottomSheet } from "../../../utils/redux/actions/commonActions/commonChangeState";
import LogOutBottomSheet from "../../LogOutBottomSheet/LogOutBottomSheet";

const bottomSheetStyle = {
	zIndex: "100",
	borderRadius: "20px",
	bottom: "0",
	position: "fixed",
	marginLeft: "auto",
	marginRight: "auto",
	maxWidth: "414px",
};

export default function AccountVersion (props) {

	const openLogoutBottomSheet = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOGOUT_CWA);
		props.dispatch(toggleLogOutBottomSheet());
	}

    return (
		<Fragment>
			<div className="account-version-container">
				{props.isAuthenticated && (
					<>
						<div className="logout-btn" onClick={() => openLogoutBottomSheet()}>
							<img alt="logoutIcon" src={logoutIcon} />
							<span className="account-links-box-title">Log Out</span>
						</div>
					</>
				)}
				<div className="account-necessary-links">
					<p className="account-version-number">Version {VERSION_CWA_NUMBER}</p>
				</div>
			</div>
			<div className="logout-bottom-sheet-container">
				<BottomSheet
					classes="login-bottom-sheet"
					isOpen={props.isLogoutBottomSheetOpen}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={false}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) {
							props.dispatch(toggleLogOutBottomSheet());
						}
					}}
				>
					<LogOutBottomSheet dispatch={props.dispatch} />
				</BottomSheet>
			</div>
		</Fragment>
	);
}