import React, {Fragment} from 'react';

import { LOCATION_INPUT_BOX_NAME_SOURCE } from '../../../utils/constants/constant';
import LocationSearchDeleteButton from '../LocationSearchDeleteButton/LocationSearchDeleteButton';
import SearchIcon from "../../../v5-icons/search.svg";
const classNames = require('classnames');

export default function LocationSearchBox(props) {
    return (
		<Fragment>
			<div className={classNames("location-search-box", props.classList)}>
				<div
					className={classNames("form-group-container form-group-contain-icon", {
						"location-search-box-inner focused": !props.savedPlaces,
					})}
				>
					<img src={SearchIcon} alt="searchIcon" className="icon" />
					<input
						type="text"
						autoComplete={props.autoComplete}
						name={props.name ? props.name : LOCATION_INPUT_BOX_NAME_SOURCE}
						onFocus={props.onFocus ? (e) => props.onFocus(e, props.refrence, props.index) : null}
						onBlur={props.onFocus ? (e) => props.onFocus(e, props.refrence, props.index) : null}
						ref={props.refrence}
						defaultValue={props.value}
						onKeyUp={props.onSearchPlace}
						autoFocus={props.autoFocus}
						className="location-search-box-input"
						placeholder={props.placeholder ? props.placeholder : "Add a stop"}
					/>
					{props.clear ? (
						<div className="location-search-box-delete">
							<LocationSearchDeleteButton draggable={false} onClick={props.onClear} />
						</div>
					) : null}
				</div>
			</div>
		</Fragment>
	);
}