import React, {Fragment} from 'react';
import AccountLink from './AccountLink';

export default function AccountLinks(props) {
	let	account_links = props.links.map((link, index) => {
			return <AccountLink key={index} onNavigate={props.onNavigate} data={link} />;
		});

    return (
		<Fragment>
			<div className="account-links">{account_links}</div>
		</Fragment>
	);
}