import React, {Fragment} from 'react';
import moment from "moment";

import SwipeToDelete from '../BillingSwipeToDelete/BillingSwipeToDelete';
import BillingInformationPrimaryLozenge from '../BillingInformationPrimaryLozenge/BillingInformationPrimaryLozenge';
import _ from 'lodash';
const classNames = require('classnames');

export default function BillingInformationGcashItem(props) {
	return (
		<Fragment>
			{props.isBottomSheet ? (<div
				className={classNames("billing-information-card-item", props.method.type)}
				onClick={(e) => props.onChangePrimary(e, props.method.id)}
			>
				<div className="billing-information-card-item-first-row">
					<div className="billing-information-card-item-info-inner">
						<div className="billing-information-card-item-img">
							<img src={props.icon} alt={props.method.type}
								 className={classNames("billing-card-image", props.method.type)} />
						</div>
						<div className="billing-information-card-item-inner">
							{props.method.isPrimary ? (
								<div className="billing-information-card-item-radio">
									<BillingInformationPrimaryLozenge title="PRIMARY" color="gray" />
								</div>
							) : null}
						</div>
					</div>
					<div className="billing-information-card-expiration">
						<p>Connected</p>
					</div>
				</div>
			</div>) :
				(<SwipeToDelete onDelete={(e) => props.onDelete(e, props.method.id)} height={85} borderRadius={4}>
					<div
						className={classNames("billing-information-card-item", props.method.type)}
						onClick={(e) => props.onChangePrimary(e, props.method.id)}
					>
						<div className="billing-information-card-item-first-row">
							<div className="billing-information-card-item-info-inner">
								<div className="billing-information-card-item-img">
									<img src={props.icon} alt={props.method.type}
										 className={classNames("billing-card-image", props.method.type)} />
								</div>
								<div className="billing-information-card-item-inner">
									{props.method.isPrimary ? (
										<div className="billing-information-card-item-radio">
											<BillingInformationPrimaryLozenge title="PRIMARY" color="gray" />
										</div>
									) : null}
								</div>
							</div>
							<div className="billing-information-card-expiration">
								<p>Connected</p>
							</div>
						</div>
					</div>
				</SwipeToDelete>)}
		</Fragment>
	);
}