import React, { Component } from 'react';
import _ from "lodash";

import {ReactComponent as ProgrammableMessageIcon} from "../../../v5-icons/programmable.svg";

export class ManagementMessage extends Component {
    render() {
        return (
            <div className="programmable-message">
                <span className="timezone">{this.props.date}</span>
                <div className="rce-mbox rce-mbox-pm">
                    <div className="rce-mbox-body">
                        <ProgrammableMessageIcon />
                        {!_.isNull(this.props.text) ? <div className="rce-mbox-text"><p>{this.props.text}</p></div> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default ManagementMessage;
