import React from 'react';
import { useDispatch } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { ReactComponent as ActionIcon } from "../../../v5-icons/kebab-menu.svg";

import {
	deletePlaceOfSavedPlaces,
	savedPlacesSetType,
	editLocationSavedPlace,
	savedPlaceSelectedAddress,
	deleteRequestPlaceOfSavedPlaces,
	savedPlacesSetNullWantedToDeletePlace,
} from "../../../utils/redux/actions/savedPlacesActions";
import { setGooglePlaceObject } from "../../../utils/redux/actions/mapActions/mapChangeState";
import { createUpdatedSavedPlaceObject } from "../../../utils/functions/create-place-object-from-google-suggestions";
import { setNavigationBlackBox } from "../../../utils/redux/actions/commonActions/commonChangeState";

const AccordionMenu = ({place, index}) => {
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleClickAway = () => {
		setExpanded(false);
	};

    const editLocation = (e, place) => {
		dispatch(savedPlacesSetType(place.type));
		dispatch(savedPlaceSelectedAddress(createUpdatedSavedPlaceObject(place, place)));
		dispatch(editLocationSavedPlace(place));
		dispatch(setGooglePlaceObject(place.location));
		dispatch(setNavigationBlackBox({from: "saved-places", goBack: "/review-mymall", action: "editAddress"}))
		dispatch(push('/saved-places/search/edit'));
	}

	const deleteSavedPlace = (e, place) => {
		dispatch(deletePlaceOfSavedPlaces(place.location));

		dispatch(deleteRequestPlaceOfSavedPlaces(place)).then(() => {
			toast(
				// <UndoButton
				// 	title={`${place.title} address removed`}
				// 	buttonTitle={"Undo"}
				// 	onHandleClick={() => dispatch(undoBookmarkedPlace(place))}
				// />,
				`${place.title} address removed`,
				{
					position: "bottom-center",
					autoClose: 5000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
					onClose: (e) => dispatch(savedPlacesSetNullWantedToDeletePlace()),
				},
			);
		});
	}

    return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div className="accordion-wrapper">
				<Accordion className="accordion" expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
					<AccordionSummary
						className="accordion-summary"
						expandIcon={<ActionIcon />}
						aria-controls={`panel${index}bh-content`}
						id={`panel${index}bh-header`}
					></AccordionSummary>
					<AccordionDetails className="accordion-items">
						<Typography onClick={(e) => editLocation(e, place)}>Edit Location</Typography>
						<Typography onClick={(e) => deleteSavedPlace(e, place)} className="delete">Delete</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</ClickAwayListener>
	);
}

export default AccordionMenu