import React, { Fragment } from "react";
import _ from "lodash";
import CustomButton from "../../ui-elements/CustomButton/CustomButton"

export default function SeeJobReceipt(props) {
	return (
		<Fragment>
			<div className="Blue_Box">
				<div className="See_Job_Left">
					<h5 className="Total_Amount_Paid">Total amount paid</h5>
					<h3 className="Total_Amount_Cost">
						{/* {props.currency} {props.jobCost.value} */}
						{props.currency}
						{!_.isNull(props.jobCost.clientTransferMykoin) && props.jobCost.clientTransferMykoin > 0
							? (props.jobCost.value + props.jobCost.clientTransferMykoin + (props.jobCost.transferFee || 0))%1 !== 0
								? Number(props.jobCost.value + props.jobCost.clientTransferMykoin + (props.jobCost.transferFee || 0)).toFixed(2)
								: props.jobCost.value + props.jobCost.clientTransferMykoin + (props.jobCost.transferFee || 0)
							: props.jobCost.value + (props.jobCost.transferFee || 0)}
					</h3>
				</div>
				<div className="See_Job_Right ">
					<CustomButton title="See job receipt" onClick={props.clicked} classList="See_Job_Receipt_Btn" />
				</div>
			</div>
		</Fragment>
	);
}