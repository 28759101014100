import React from "react"
import MinLimitImage from "../../v5-icons/xendit-min-limit.png";
import CustomButton from "../ui-elements/CustomButton/CustomButton";
import {useDispatch} from "react-redux";
import { xenditMinLimitBottomSheet } from "../../utils/redux/actions/commonActions/commonChangeState";

export const XenditMinLimitBottomSheet = (props) => {

	const dispatch = useDispatch();

	return (
		<div className="xendit-min-limit-container">
			<div className="xendit-min-limit-img">
				<img src={MinLimitImage} alt="Min limit image" />
			</div>
			<div className="xendit-min-limit-header">
				<h2>₱200 Minimum Top-up</h2>
				<h6>To minimize bank fees, a minimum ₱200 top-up is needed. You will have the full amount in your mykoins balance shortly.

					Is it okay to proceed? </h6>
			</div>
			<div className="xendit-min-limit-bottom">
				<CustomButton
					classList="top-up-button"
					title="Top-up ₱200"
					onClick={props.xenditTopup200Peso}
				/>
				<CustomButton
					outline={true}
					variant="outlined"
					classList="change-payment-button"
					title="Cancel"
					onClick={() => dispatch(xenditMinLimitBottomSheet(false))}
				/>
			</div>
		</div>
	)
}