import React, { Component, createRef, Fragment } from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import { initScript } from '../../../utils/redux/actions/mapActions';
import { initializeMapRef, setGooglePlaceObject } from "../../../utils/redux/actions/mapActions/mapChangeState";
import DarkLoading from "../../ui-elements/Loading/DarkLoading";
class GoogleMap extends Component {

    googleMapRef = createRef();

    componentDidMount() {
        if (!this.props.googleMapPlace?.lat || !this.props.googleMapPlace?.lng) {
            this.props.dispatch(setGooglePlaceObject(this.props.location));
        }
        if(this.props.url === "/map") {
            this.props.dispatch(initScript(initializeMapRef(this.googleMapRef), this.props.listener, this.props.searchAddressRef, this.props.url));
        } else {
            this.props.dispatch(initScript(initializeMapRef(this.googleMapRef), this.props.listener, this.props.searchAddressRef));
        }
    }

    render() {
        return (
			<Fragment>
				{!_.isEmpty(this.props.googleMapRef) ? (
					<>
						<div
							style={{ display: this.props.display }}
							id="google-map"
							ref={this.props.googleMapRef}
							className={this.props.classList && this.props.classList}
						/>
						{this.props.gettingLocationLoading ? (
							<div className="map-loading">
								<DarkLoading />
							</div>
						) : null}
					</>
				) : (
					""
				)}
			</Fragment>
		);
    }
}


const mapStateToProps = state => ({
    ...state.map,
    common: state.common
});

export default connect(mapStateToProps)(GoogleMap);