import React, { Fragment } from "react";

import downImg from "../../../../../assests/images/jobs/thumbs-down.svg";
import upImg from "../../../../../assests/images/jobs/thumbsUpWithBorder.svg";

const classNames = require("classnames");

const JobInformationThumb = (props) => {
	let rate = props.rate === 5 ? upImg : downImg;
	let type = props.rate === 5 ? "up" : "down";
	return (
		<Fragment>
			<div className="Img_Box">
				<img src={rate} alt="" className={classNames("img-responsive", "Job_Info_Thumb", type)}/>
			</div>
			{type === "up" ?
				<h5 className="thumbs-title">Thumbs up</h5>
				: <h5 className="thumbs-title">Thumbs down</h5>
			}
		</Fragment>
	);
};
export default JobInformationThumb;