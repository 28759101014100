import React, {Fragment} from 'react';
import TextareaAutosize from "react-textarea-autosize";

export default function BookingSpecial(props) {

    return (
		<Fragment>
			<div className="booking-special">
				<div className="booking-special-title">
					<p>Any specifics you would like the partner to know before you match with them?</p>
				</div>
				<div className="booking-special-textarea form-group-container">
					<label>Note to Partner</label>
					<TextareaAutosize
						onChange={props.onChange}
						onKeyDown={props.onKeyDown}
						onFocus={props.onFocus}
						onBlur={props.onBlur}
						rows={4}
						autoFocus
						value={props.value}
						placeholder={props.value ? props.value : 'e.g "Please bring ID and message me once you are near"'}
					/>
				</div>
			</div>
		</Fragment>
	);
}