import React from 'react';
import { connect } from 'react-redux';
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import { toggleOutOfGeo } from "../../../utils/redux/actions/savedPlacesActions";

class OutOfGeoBottomSheet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isShow: false,
			timeOut: null,
		};
	}

	render() {
		return (
			<div className="rejected-payment-container">
				<div className="rejected-payment-header">
					<h2>Not Available</h2>
					<h6>Our services do not cover your location yet.</h6>
				</div>
				<div className="rejected-payment-bottom">
					{/*<CustomButton classList="top-up-button" title="Retry Payment" onClick={this.props.showTopUpBottomSheet} />*/}
					<CustomButton
						classList="change-payment-button"
						title="Change Location"
						onClick={this.props.onChangeAddress}
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(OutOfGeoBottomSheet);
