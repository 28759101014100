import React, {Fragment} from 'react';
import _ from "lodash";
import {ReactComponent as Peso} from "../../../v5-icons/peso.svg";

export default function BookingPriceTime({
	time,
	price,
	handleClickEnterPromoCode,
	newPrice,
	amountOff,
	percentOff,
	promoCode,
	handleLinkRemovePromoCode,
	currency,
	currencyIcon,
}) {
	return (
		<div className="review-booking-item-container">
			<p className="title">Total Service Fee</p>
			{!_.isNull(newPrice) && newPrice >= 0 ? (
				<Fragment>
					<div className="review-booking-item-inner">
						<p>
							{newPrice >= 0 ? <span className="real-price">{price.items[price.minCubeNumbers].price.value}</span> : null}{" "}
							<span className="new-price">{newPrice}</span> for {time}
						</p>
						{currencyIcon ? <img src={currencyIcon} alt="currencyIcon" /> : <Peso />}
					</div>
					<div className="applide-promo-code">
						{amountOff ? (
							<p>
								{currency} {amountOff}{" "}
							</p>
						) : (
							<p>{percentOff}% </p>
						)}
						<p className="static-text">
							{" "}
							off using {promoCode}.<span onClick={handleLinkRemovePromoCode}> Remove code?</span>
						</p>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="review-booking-item-inner">
						<p>
							{price.items[price.minCubeNumbers].price.currency} {price.items[price.minCubeNumbers].price.value} for {time}
						</p>
						{currencyIcon ? <img src={currencyIcon} alt="currencyIcon" /> : <Peso />}
					</div>

					<p className="promo-code" onClick={handleClickEnterPromoCode}>
						Enter Discount Code
					</p>
				</Fragment>
			)}
		</div>
	);
}