import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Tour from "reactour";
import { push } from "connected-react-router";

import * as LOG_EVENT from "../../utils/analytics/index";

import BookingService from "./BookingService/BookingService";
import BookingInfoModal from "./BookingInfoModal/BookingInfoModal";
import { fetchBookingV5, navigateToRequestPage, setBookingConfigTour } from "../../utils/redux/actions/bookingActions";
import {
	openLocationSearchBottomSheet,
	closeLocationSearchBottomSheet,
	voipIsSupported,
	bookingUnSetErrorLocationPrice,
	emptyProductObject,
} from "../../utils/redux/actions/bookingActions/bookingChangeState";
import { appGoHome, tourPageisDone, checkCurrentStep, skipShowingTour, addAnimateToText } from "../../utils/redux/actions/commonActions";
import { toggleTour, unMountTourConfig } from "../../utils/redux/actions/commonActions/commonChangeState";
import { localStorageGetIsFirstRequest, localStorageGetLocation } from "../../utils/functions/local-storage";
import { addFirstStop, mapClearGoogleMapArrayOfDestination } from "../../utils/redux/actions/mapActions/mapChangeState";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import { setSavedPlaceLocationPointer } from "../../utils/redux/actions/savedPlacesActions";

import TitleBar from "../../organisms/title-bar";
import backButton from "../../v5-icons/back-button.svg";
import chatSupport from "../../v5-icons/chat-support.svg";
import CustomButton from "../ui-elements/CustomButton/CustomButton";
import BottomSheet from "../ui-elements/BottomSheet/BottomSheet";
import LinearSkeleton from "../ui-elements/Skeleton/LinearSkeleton";
import SavedPlacesBottomSheet from "../SavedPlaces/SavedPlacesBottomSheet/SavedPlacesBottomSheet";
import LocationSearchBooking from "../LocationSearchBooking/LocationSearchBooking";
import { searchNavigateToMapFromLocationSearch } from "../../utils/redux/actions/searchActions";
import BookingPriceErrorModal from "./BookingPriceErrorModal/BookingPriceErrorModal";
import WhiteLoading from "../ui-elements/Loading/WhiteLoading";
import {localStorageIsTwa} from "../../utils/functions/local-storage"
class Booking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowProductInfoPopup: false,
			isOpenBottomSheet: false,
			pageName: this.props.match.url.split("/")[1],
			innerWidth: null,
			hasFadePropProduct: false,
		};

		this.goBack = this.goBack.bind(this);
		this.onCloseProductInfo = this.onCloseProductInfo.bind(this);
		this.onOpenProductInfoModal = this.onOpenProductInfoModal.bind(this);
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		this.props.dispatch(fetchBookingV5(this.props.match.params.id)).then((res) => {
			//Add animate to text when it's too long
			this.props
				.dispatch(addAnimateToText(document.querySelector(".long-heading"), document.querySelector(".long-heading .heading")))
				.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropProduct: true,
					}));
				}
			}).catch((err) => {});

			this.props.dispatch(setBookingConfigTour(res.minPoints, res.maxPoints, this.state.pageName));
			if (
				!_.isEmpty(this.props.product.mode) &&
				(this.props.product.mode.includes("VIDEO_CHAT") || this.props.product.mode.includes("VOICE_CHAT"))
			) {
				const mode = [];
				if (this.props.product.mode.includes("VIDEO_CHAT") && this.props.product.mode.includes("VOICE_CHAT")) {
					mode.push("VIDEO_CHAT", "VOICE_CHAT");
				} else if (this.props.product.mode.includes("VIDEO_CHAT")) {
					mode.push("VIDEO_CHAT");
				} else {
					mode.push("VOICE_CHAT");
				}
				this.props.dispatch(voipIsSupported(!_.isEmpty(mode) ? mode : this.props.product.mode));
			}
		});

		if (!localStorageGetIsFirstRequest()) {
			this.props.dispatch(addFirstStop());
		}
		LOG_EVENT.logEvent(LOG_EVENT.BOOKING_LOCATIONS_PAGE_CWA);
	}

	componentWillUnmount() {
		this.props.dispatch(toggleTour(false));
		this.props.dispatch(unMountTourConfig());
	}

	onCloseProductInfo = () => {
		this.setState(() => ({
			isShowProductInfoPopup: false,
		}));
	};

	onOpenProductInfoModal = () => {
		this.setState(() => ({
			isShowProductInfoPopup: true,
		}));
	};

	goBack() {
		this.props.dispatch(emptyProductObject());
		this.props.dispatch(appGoHome());
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	isToggleSheet() {
		this.setState(() => ({ isShowProductInfoPopup: false }));
	}

	onAddNextStop = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_SHEET_OPENED_CWA, { from: "booking" });
		const { auth, authCheck } = this.props.common;
		if (auth && authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_OPENED, { from: "addStop", os: localStorageIsTwa() ? "twa" : "web" });
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(openLocationSearchBottomSheet());
		} else {
			this.goToMap();
		}
	};

	goToMap = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_OPENED, {
			os: localStorageIsTwa() ? "twa" : "web",
			from: "addStop",
			isGuest: this.props.common.auth && this.props.common.authCheck ? false : true,
		});
		this.props.dispatch(searchNavigateToMapFromLocationSearch(this.props.match.params));
	};

	changeLocation = () => {
		this.props.dispatch(bookingUnSetErrorLocationPrice());
		this.props.dispatch(mapClearGoogleMapArrayOfDestination());
	};
	handleChangeLocationBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.props.dispatch(closeLocationSearchBottomSheet());
		}
	};
	handleChangeBookingInfoBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.setState(() => ({ isShowProductInfoPopup: false }));
		}
	};
	handleChangeErrorPriceBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.props.dispatch(bookingUnSetErrorLocationPrice());
			this.changeLocation();
		}
	};
	setCurrentStep(current) {
		const { pageName } = this.state;
		this.props.dispatch(checkCurrentStep(current, pageName));
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<Fragment>
				<div className="booking">
					{this.state.isShowProductInfoPopup ? (
						<BottomSheet
							isOpen={this.state.isShowProductInfoPopup}
							indicator={true}
							styles={bottomSheetStyle}
							closeSheet={() => this.isToggleSheet()}
							classes="product-info-bottom-sheet"
							onChangeOverlay={this.handleChangeBookingInfoBottomSheet}
							config={{ marginTop: 50 }}
						>
							<BookingInfoModal closeModal={this.onCloseProductInfo} productDetail={this.props.product} />
						</BottomSheet>
					) : null}
					<TitleBar leftIcon={backButton} rightIcon={chatSupport} handleClickLeftIcon={this.goBack} handleClickRightIcon={this.onSupport} />
					<div className="booking-inner">
						{!_.isEmpty(this.props.product) ? (
							<BookingService
								progressBar="booking"
								icon={this.props.product.icon}
								commercialName={this.props.product.commercialName}
								descriptionTitle={this.props.product.descriptionTitle}
								hasIconInfo={true}
								showInfoProductModal={this.onOpenProductInfoModal}
								hasFadePropProduct={this.state.hasFadePropProduct}
							/>
						) : (
							<div className="booking-skeleton">
								<LinearSkeleton
									devicewidth={
										_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
									}
								/>
							</div>
						)}

						<LocationSearchBooking onAddNextStop={this.onAddNextStop} match={this.props.match} />
					</div>
					<div className="call-to-action-container">
						{
							<CustomButton
								disabled={
									_.isEmpty(this.props.product)
										? true
										: this.props.product.minPoints > this.props.map.googlePlaces.length + 1 ||
										  this.props.errorPrice ||
										  JSON.parse(localStorageGetIsFirstRequest()) ||
										  _.isNull(localStorageGetLocation().lat) ||
										  _.isNull(localStorageGetLocation().lng) ||
										  this.props.map.location.title === "Choose your location"
								}
								onClick={() =>
									this.props.dispatch(
										navigateToRequestPage(
											this.props.match.params.id,
											this.props.location,
											`/booking/${this.props.product.id}/note`,
										),
									)
								}
								type="button"
								title={
									this.props.fetchPriceLoading ? (
										<div className="next-button-loading">
											<WhiteLoading />
										</div>
									) : (
										"Next"
									)
								}
							/>
						}
					</div>
					{this.props.locationSearchBottomSheet ? (
						<BottomSheet
							isOpen={this.props.locationSearchBottomSheet}
							classes="choose-location-bottom-sheet"
							styles={bottomSheetStyle}
							config={{ marginTop: 50, fullScreen: true }}
							indicator={true}
							onChangeOverlay={this.handleChangeLocationBottomSheet}
						>
							<SavedPlacesBottomSheet handleGoToMap={this.goToMap} />
						</BottomSheet>
					) : null}
					{this.props.errorPrice ? (
						<BottomSheet
							isOpen={this.props.errorPrice}
							indicator={true}
							onChangeOverlay={this.handleChangeErrorPriceBottomSheet}
							styles={bottomSheetStyle}
						>
							<BookingPriceErrorModal yes={this.changeLocation} />
						</BottomSheet>
					) : null}
				</div>
				{/* {!_.isEmpty(this.props.product)
					? !_.isNull(this.props.common.tourSteps) &&
					  !this.props.common.loading && (
							<Tour
								onRequestClose={() => this.props.dispatch(toggleTour(false))}
								steps={this.props.common.tourSteps}
								startAt={this.props.common.currentStep}
								isOpen={this.props.common.isOpenTour}
								maskClassName="mask"
								className="onboard-message-box"
								rounded={5}
								maskSpace={5}
								showCloseButton={false}
								showNavigation={false}
								showNumber={false}
								closeWithMask={false}
								accentColor={"#5cb7b7"}
								lastStepNextButton={
									<p className="next-button" onClick={() => this.props.dispatch(tourPageisDone(this.state.pageName))}>
										DONE!
									</p>
								}
								disableInteraction
								getCurrentStep={(curr) => this.setCurrentStep(curr)}
								nextButton={<p className="next-button">NEXT</p>}
								prevButton={
									<p className="skip-button" onClick={() => this.props.dispatch(skipShowingTour(this.state.pageName))}>
										SKIP TUTORIAL
									</p>
								}
							/>
					  )
					: null} */}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
	common: state.common,
	map: state.map,
	location: {
		jobLocations: [
			{
				lat: state.map.location.lat,
				lng: state.map.location.lng,
				address: state.map.location.address,
				title: state.map.location.title,
			},
		],
	},
	locationTitle: state.map.location.title || state.map.location.address,
});

export default connect(mapStateToProps)(Booking);