import { goBack, push } from 'connected-react-router';

import * as DISPATCH_STATE from '../commonChangeState';
import * as LOG_EVENT from "../../../../analytics/index";
import { myJobsSetSearchLocationPointer, setDefaultJobObject } from "../../jobsActions/jobsChangeState";
import { homeWillUnMount } from "../../homeActions/homeChangeState";
import buttonTimeout from '../../../../functions/button-timeout';
import { willUnMountReviewBooking } from "../../bookingActions/bookingChangeState";

export function appAuthRedirectCheck(history, auth) {
    return dispatch => {
        dispatch(DISPATCH_STATE.appAuthRedirect());
        history.push("/home");
    };
}
/**
 * When go back in application
 */
export function appGoBack(backUrl = null) {
    return (dispatch) => {
        if (!backUrl) {
            dispatch(goBack());
        } else {
            dispatch(push(backUrl));
        }
    }
}
/**
 * When go back job information
 */
export function appGoBackJobInformation() { 
    return (dispatch, getState) => {
        // if (getState().jobs.job.state === JOB_STATE_COMPLETED) {
        //     dispatch(push('/jobs/' + getState().jobs.job.id));
        // } else 
        if (getState().jobs.searchPointer) {
            dispatch(push(getState().jobs.searchPointer));
            dispatch(myJobsSetSearchLocationPointer(null));
            dispatch(homeWillUnMount());
        } else {
            let setTimeoutMatched = setTimeout(() => {
                dispatch(setDefaultJobObject());
                dispatch(push("/jobs"));
				clearTimeout(setTimeoutMatched);
			}, 500); 
        }
    }
}
/**
 * When go back in application
 */
export function appGoHome() {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.appGoHomeCheck());
		dispatch(push('/home'));
		dispatch(willUnMountReviewBooking());
	}
}
/**
 * When go welcome
 */
export function appGoToWelcome() {
    return (dispatch) => {
        dispatch(push('/welcome'));
    }
}
/**
 * Navigate by click on bottom bar link
 * @param {*} url 
 */
export function appGoPushBottomBar(url) {
    return (dispatch, getState) => {
        if (getState().common.auth && getState().common.authCheck) {
            dispatch(DISPATCH_STATE.appUserClickOnBottomBarLink());
            dispatch(setEventTrackerForBottomSheetItems(url));
			buttonTimeout(() => {
				dispatch(push(url));
			});
        } else {
            if(url === "/jobs" || url === "/account") {
                LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "drawer" });
				dispatch(DISPATCH_STATE.toggleLogInBottomSheet());
            }
        }
    }
}

export const setEventTrackerForBottomSheetItems = (url) => {
	return (dispatch, getState) => {
		switch (url) {
			case "/about-mykuya":
				LOG_EVENT.logEvent(LOG_EVENT.ABOUT_PAGE_CWA);
				break;
			case "/jobs":
				LOG_EVENT.logEvent(LOG_EVENT.MY_JOBS_PAGE_CWA, { from: ["tab", "home"] });
				break;
			case "/account":
				LOG_EVENT.logEvent(LOG_EVENT.ACCOUNT_PAGE_CWA, { from: "tab" });
				break;
			case "/news":
				LOG_EVENT.logEvent(LOG_EVENT.NEWS_LIST_PAGE, { from: ["account", "feedButton"] });
				break;
			default:
				break;
		}
	};
};
