import React, { Fragment } from 'react';

import ForwardIcon from '../../../../v5-icons/forward.svg';
import AccordionMenu from "../../AccordionMenu/AccordionMenu";

const classNames = require('classnames');

export default function SavedPlacesFavoritesItem(props) {
    let box = (
		<div className={classNames("saved-places-favorite-item", props.id)}>
			<div className="saved-places-info" onClick={props.onClick}>
				<div className="icon">
					<img src={props.icon} alt="icon" />
				</div>
				<div className="title-container">
					<p className="name">{props.title}</p>
					<div className={props.add ? "blue-sub-title" : "sub-title"}>
						{props.subTitle}
						{props.add ? (
							<div className="forward-icon">
								<img src={ForwardIcon} className="img-responsive" alt="ForwardIcon" />
							</div>
						) : null}
					</div>
				</div>
			</div>
			{!props.add ? <AccordionMenu actionIcon={props.actionIcon} place={props.place} index={props.id} /> : null}
		</div>
	);
    return (
		<Fragment>
			{box}
		</Fragment>
	);
}