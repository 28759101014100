import React, { Fragment, Component } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import _ from "lodash";

import JobRatingPartner from "./JobRatingPartner/JobRatingPartner";
import JobRatingThumb from "./JobRatingThumb/JobRatingThumb";
import JobRatingCompleted from "./JobRatingCompleted/JobRatingCompleted";
import JobRatingCompliments from "./JobRatingCompliments/JobRatingCompliments";
import JobRatingSegmentCompliments from "./JobRatingSegmentCompliments/JobRatingSegmentCompliments";
import { createJobRateObject } from "../../utils/functions/create-rate-partner-object";
import BottomSheet from "../ui-elements/BottomSheet/BottomSheet";
import SeeJobReceipt from "./SeeJobReceipt/SeeJobReceipt";
import TitleBar from "../../organisms/title-bar";

import { fetchJobUnratedCompliment, postRequestJobUnratedCompliment } from "../../utils/redux/actions/jobsActions";
import { JOB_UNRATED_THUMBS_UP_RATE, JOB_UNRATED_THUMBS_DOWN_RATE } from "../../utils/redux/actions/jobsActions/jobsConstant";
import {
	jobsRatingWillUnMount,
	addedNewKeyForCompliments,
	addCommentOfAppRating,
	isToggleSheet,
} from "../../utils/redux/actions/jobsActions/jobsChangeState";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import { getComplimentsViewFromFirebase, remoteConfig } from "../../utils/functions/firebase-initialize";

import chatSupport from "../../v5-icons/chat-support.svg";
const classNames = require("classnames");

class JobRating extends Component {
	constructor(props) {
		super(props);

		// this.isToggleSheet = this.isToggleSheet.bind(this);
		this.state = {
			complimentsView: "segment",
			thumb: 0,
			isOpenBottomSheet: false,
			isShowTopImage: true,
		};
	}

	componentDidMount() {
		if (window.innerHeight <= 700) {
			this.setState(() => ({
				isShowTopImage: false,
			}));
		}
		getComplimentsViewFromFirebase(remoteConfig).then((res) => {
			this.setState(() => ({ complimentsView: res }));
		});
	}

	componentWillUnmount() {
		this.props.dispatch(jobsRatingWillUnMount());
	}

	onThumb = (e, rate) => {
		this.setState({ thumb: rate });
		this.props.dispatch(fetchJobUnratedCompliment());
		this.props.dispatch(isToggleSheet());
	};
	onJocReceipt = () => {
		this.props.dispatch(push("/jobs/" + this.props.unrated.id + "/receipt"));
	};

	obSubmit = () => {
		this.props.dispatch(postRequestJobUnratedCompliment(createJobRateObject(this.props.jobRateComment, this.state.thumb, this.props.newCompliments)))
			.then((res) => {
				if(res) this.props.dispatch(isToggleSheet());
			}).catch(() => this.props.dispatch(isToggleSheet()))

	};

	isToggleSheet() {
		// const toggleSheet = this.state.isOpenBottomSheet;
		// this.setState({ isOpenBottomSheet: !toggleSheet });
		this.props.dispatch(isToggleSheet());
	};

	render() {
		const bottomSheetStyle = { zIndex: "100", borderRadius: "20px", bottom: "0", position: "fixed", marginLeft: "auto", marginRight: "auto", maxWidth: "414px" };
		return (
			<Fragment>
				<div className="Job_Rating">
					{!_.isNull(this.props.unrated) && (
						<BottomSheet
							isOpen={this.props.isOpenRatingBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							closeSheet={this.isToggleSheet}
							indicator={true}
							onChangeOverlay={(isOpen) => {
								if (!isOpen) {
									this.isToggleSheet();
									this.props.dispatch(addedNewKeyForCompliments(null));
									this.props.dispatch(addCommentOfAppRating(""));
								}
							}}
							classes="Job_Rating_Bottom_Sheet"
						>
							{_.isNull(this.props.newCompliments) ? null : this.state.complimentsView === "segment" ? (
								<JobRatingSegmentCompliments
									toggleSheet={this.isToggleSheet}
									obSubmit={this.obSubmit}
									compliments={this.props.newCompliments}
									thumb={this.state.thumb}
									isOpen={this.props.isOpenRatingBottomSheet}
									sendFeedBackLoading={this.props.sendFeedBackLoading}
									selectedIndex={this.state.thumb === 1 ? 1 : 0}
									firstCompliment={
										this.state.thumb === 1
											? this.props.newCompliments[0]["compliments"]
											: this.props.newCompliments[1]["compliments"]
									}
								/>
							) : (
								<JobRatingCompliments
									toggleSheet={this.isToggleSheet}
									obSubmit={this.obSubmit}
									compliments={this.props.newCompliments}
									thumb={this.state.thumb}
									isOpen={this.props.isOpenRatingBottomSheet}
									sendFeedBackLoading={this.props.sendFeedBackLoading}
								/>
							)}
						</BottomSheet>
					)}

					<div
						className={classNames("White_Box", {
							"not-show-top-image": !this.state.isShowTopImage,
						})}
					>
						<div className="Job_Rating_Box no-padding">
							<TitleBar
								rightIcon={chatSupport}
								handleClickRightIcon={(e) => {
									this.props.dispatch(push("/support"));
									this.props.dispatch(navigateToSupport());
								}}
								lozengeObject={{ title: "COMPLETED", color: "charcoal", heading: this.props.unrated.product?.commercialName }}
							/>

							<div className="Job_Rating_Hand_Shake">
								<Fragment>
									<JobRatingCompleted isShowTopImage={this.state.isShowTopImage} partner={this.props.unrated.partner} />
								</Fragment>
							</div>
							{!_.isNull(this.props.unrated.partner) ? (
								<div className="Job_Rating_Partner">
									<JobRatingPartner partner={this.props.unrated.partner} />
								</div>
							) : null}
							<div className="Job_Rating_Thumb">
								<div className="Thumb_Box">
									<JobRatingThumb
										onClick={(e) => this.onThumb(e, JOB_UNRATED_THUMBS_UP_RATE)}
										rate={JOB_UNRATED_THUMBS_UP_RATE}
										thumb={this.state.thumb}
										type="up"
										loading={this.props.complimentLoading}
									/>
								</div>
								<div className="Thumb_Box">
									<JobRatingThumb
										onClick={(e) => this.onThumb(e, JOB_UNRATED_THUMBS_DOWN_RATE)}
										rate={JOB_UNRATED_THUMBS_DOWN_RATE}
										thumb={this.state.thumb}
										type="down"
										loading={this.props.complimentLoading}
									/>
								</div>
							</div>
						</div>
					</div>
					<SeeJobReceipt
						jobCost={this.props.unrated.jobCost}
						currency={this.props.common.client.currency.signText}
						clicked={this.onJocReceipt}
					/>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	common: state.common,
	unrated: state.jobs.unrated,
	// compliments: state.jobs.unratedCompliment,
	complimentLoading: state.jobs.complimentLoading,
	sendFeedBackLoading: state.jobs.sendFeedBackLoading,
	newCompliments: state.jobs.newCompliments,
	jobRateComment: state.jobs.jobRateComment,
	isOpenRatingBottomSheet: state.jobs.isOpenRatingBottomSheet,
});

export default connect(mapStateToProps)(JobRating);
