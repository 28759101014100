import React, { Component } from "react";
import { connect } from "react-redux";

import { ReactComponent as ChatSupport } from "../../v5-icons/chat-support-white.svg";
import CancelBookingImage from "../../v5-icons/cancel-booking.png";

class ChatWithSupportBottomSheet extends Component {
	render() {
		return (
			<div className="chat-with-support-container">
				<div className="img-container">
					<img src={CancelBookingImage} alt="Cancel" />
				</div>
				<p className="header">Top-up mykoins interrupted</p>
				<p className="content">
					Visa & Master offer instant top-ups, but sometimes other methods take more time to give us the confirmation. We are waiting for
					them and will update your mykoins balance as soon as we get it. Sorry to keep you waiting, if you need help, we are here!
				</p>
				<div className="chat-with-support" onClick={this.props.navigateToSupport}>
					<ChatSupport />
					<span>Get help</span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
	common: state.common,
});

export default connect(mapStateToProps)(ChatWithSupportBottomSheet);
