import React, {Fragment} from 'react';
import * as moment from 'moment-timezone';
import _ from 'lodash';

export default function Moment (props) {
    let format = "DD MMMM YYYY, h:mm A";
    if (_.has(props, 'format')) {
        format = props.format;
    }
    return (
        <Fragment>
            {moment(props.children).format(format)}
        </Fragment>
    );
}