import * as CONSTANT_ACTIONS from "./bookingConstant";

export const fetchBookingBegin = () => ({
	type: CONSTANT_ACTIONS.FETCH_BOOKING_BEGIN,
});
/**
 * empty note in homepage
 */
export const bookingEmptyNote = () => ({
	type: CONSTANT_ACTIONS.BOOKING_EMPTY_NOTE,
});
/**
 * empty note in homepage
 */
export const bookingSetNullSelectedOptions = () => ({
	type: CONSTANT_ACTIONS.BOOKING_SET_NULL_SELECTED_OPTIONS,
});
/**
 *
 * @param {*} price
 */
export const fetchJobExtendPriceSuccess = price => ({
	type: CONSTANT_ACTIONS.FETCH_JOB_EXTEND_PRICE_SUCCESS,
	payload: price,
});

export const bookingErrorLocationPrice = (message) => ({
	type: CONSTANT_ACTIONS.BOOKING_ERROR_LOCATION_PRICE,
	payload: message,
});
// remove price of booking
export const bookingRemovePrice = () => ({
	type: CONSTANT_ACTIONS.BOOKING_REMOVE_PRICE,
});

export const bookingSetCurrentPrice = (current) => ({
	type: CONSTANT_ACTIONS.BOOKING_SET_CURRENT_PRICE,
	payload: current,
});

export const bookingUnSetErrorLocationPrice = () => ({
	type: CONSTANT_ACTIONS.BOOKING_UN_SET_ERROR_LOCATION_PRICE,
});

/**
 * on change text of note for request
 */
export const onChnageSpecialTextarea = (value) => ({
	type: CONSTANT_ACTIONS.BOOKING_ON_CHANGE_SPECIAL_TEXT,
	payload: value,
});

export const bookingSetProductFromHome = (product) => ({
	type: CONSTANT_ACTIONS.BOOKING_SET_PRODUCT_FROM_HOME,
	payload: product,
});

export const bookingToggleShowDescription = () => ({
	type: CONSTANT_ACTIONS.BOOKING_TOGGLE_SHOW_DESCRIPTION,
});

export const bookingWillUnMountDispatch = () => ({
	type: CONSTANT_ACTIONS.BOOKING_WILL_UNMOUNT,
});

export const bookingToggleShowOption = () => ({
	type: CONSTANT_ACTIONS.BOOKING_TOGGLE_SHOW_OPTION,
});

export const bookingOnFocusSpecialText = () => ({
	type: CONSTANT_ACTIONS.BOOKING_ON_FOCUS_SPECIAL_TEXT,
});

export const bookingOnRequestNowButtonClick = (createReqeust) => ({
	type: CONSTANT_ACTIONS.BOOKING_ON_REQUEST_NOW_BUTTON_CLICK,
	payload: createReqeust,
});

export const bookingNavigateToLocationFromBooking = () => ({
	type: CONSTANT_ACTIONS.BOOKING_NAVIGATE_TO_LOCATION_FROM_BOOKING,
});

export const bookingGetSelectedOptions = (selected) => ({
	type: CONSTANT_ACTIONS.BOOKING_GET_SELECTED_OPTIONS,
	payload: selected,
});

export const fetchBookingV5Success = (booking) => ({
	type: CONSTANT_ACTIONS.FETCH_BOOKING_V5_SUCCESS,
	payload: booking,
});

export const openLocationSearchBottomSheet = () => ({
	type: CONSTANT_ACTIONS.OPEN_LOCATION_SEARCH_BOTTOM_SHEET,
});

export const closeLocationSearchBottomSheet = () => ({
	type: CONSTANT_ACTIONS.CLOSE_LOCATION_SEARCH_BOTTOM_SHEET,
});

export const enableAddNextStop = () => ({
	type: CONSTANT_ACTIONS.ENABLE_ADD_NEXT_STOP,
});

export const navigateToRequestPage = () => ({
	type: CONSTANT_ACTIONS.NAVIGATE_TO_REQUEST_PAGE,
});

export const backToBookingPage = () => ({
	type: CONSTANT_ACTIONS.BACK_TO_BOOKING_PAGE,
});

export const requestCheckBookingProductExist = () => ({
	type: CONSTANT_ACTIONS.REQUEST_CHECK_BOOKING_PRODUCT_EXIST,
});

export const fetchPriceBegin = (location) => ({
	type: CONSTANT_ACTIONS.FETCH_PRICE_BEGIN,
	payload: location,
});

export const fetchPriceSuccess = (price) => ({
	type: CONSTANT_ACTIONS.FETCH_PRICE_SUCCESS,
	payload: price,
});

export const enableBookingBottomSheet = () => ({
	type: CONSTANT_ACTIONS.ENABLE_BOOKING_BOTTOM_SHEET,
});

export const navigateToReviewBooking = () => ({
	type: CONSTANT_ACTIONS.NAVIGATE_TO_REVIEW_BOOKING,
})

export const reviewBookingPriceAndLocationExist = () => ({
	type: CONSTANT_ACTIONS.REVIEW_BOOKING_PRICE_AND_LOCATION_EXIST,
});

export const changeBookingTimeFormat = (time) => ({
	type: CONSTANT_ACTIONS.CHANGE_BOOKING_TIME_FORMAT,
	payload: time,
});

export const clientHasPaymentMethod = (payment) => ({
	type: CONSTANT_ACTIONS.CLIENT_HAS_PAYMENT_METHOD,
	payload: payment,
});

export const setPaymentMethods = (paymentMethods) => ({
	type: CONSTANT_ACTIONS.SET_PAYMENT_METHODS,
	payload: paymentMethods
});

export const setIsInstantMatch = (isInstantMatch) => ({
	type: CONSTANT_ACTIONS.SET_IS_INSTANT_MATCH,
	payload: isInstantMatch
});

export const fetchPaymentMethodSuccess = (paymentMethod) => ({
	type: CONSTANT_ACTIONS.FETCH_PAYMENT_METHOD_SUCCESS,
	payload: paymentMethod
});

export const changePrimaryPayment = (primaryPayment) => ({
	type: CONSTANT_ACTIONS.CHANGE_PRIMARY_PAYMENT,
	payload: primaryPayment
});

export const clientRejectedPayment = () => ({
	type: CONSTANT_ACTIONS.CLIENT_HAS_REJECTED_PAYMENT
});

export const toggleTopUpBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_TOPUP_BOTTOM_SHEET,
});

export const setTopUpAmount = (amount) => ({
	type: CONSTANT_ACTIONS.SET_TOP_UP_AMOUNT,
	payload: amount,
});

export const topUpMyKoinBegin = () => ({
	type: CONSTANT_ACTIONS.TOP_UP_MYKOIN_BEGIN,
});

export const redirectClientToAdyenForTopUp = () => ({
	type: CONSTANT_ACTIONS.REDIRECT_CLIENT_TO_ADYEN_FOR_TOP_UP,
});

export const setProductOnReviewBooking = (product) => ({
	type: CONSTANT_ACTIONS.SET_PRODUCT_ON_REVIEW_BOOKING,
	payload: product
});

export const setPriceOnReviewBooking = (price) => ({
	type: CONSTANT_ACTIONS.SET_PRICE_ON_REVIEW_BOOKING,
	payload: price,
});

export const togglePaymentMethodsBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_PAYMENT_METHODS_BOTTOM_SHEET,
});

export const toggleRejectedPayment = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_REJECTED_PAYMENT,
});

export const toggleRetryFailed = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_RETRY_FAILED,
});

export const toggleCancelBookingBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_CANCEL_BOOKING_BOTTOM_SHEET
});

export const willUnMountReviewBooking = () => ({
	type: CONSTANT_ACTIONS.WILL_UN_MOUNT_REVIEW_BOOKING
});

export const clientHasPositiveBalance = () => ({
	type: CONSTANT_ACTIONS.CLIENT_HAS_POSITIVE_BALANCE,
});

export const retryWithTopUp = () => ({
	type: CONSTANT_ACTIONS.RETRY_WITH_TOPUP,
});

export const setDefaultRetryWithTopUp = () => ({
	type: CONSTANT_ACTIONS.SET_DEFAULT_RETRY_WITH_TOPUP,
});

export const retryWithCard = () => ({
	type: CONSTANT_ACTIONS.RETRY_WITH_CARD,
});

export const setDefaultRetryWithCard = () => ({
	type: CONSTANT_ACTIONS.SET_DEFAULT_RETRY_WITH_CARD,
});

export const setDefaultValueRejectedPayment = () => ({
	type: CONSTANT_ACTIONS.SET_DEFAULT_VALUE_REJECTED_PAYMENT
});

export const togglePromoCodeBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_PROMO_CODE_BOTTOM_SHEET
});

export const setPromoCode = (code) => ({
	type: CONSTANT_ACTIONS.SET_PROMO_CODE,
	payload: code
});

export const setNewPrice = (price) => ({
	type: CONSTANT_ACTIONS.SET_NEW_PRICE,
	payload: price
});

export const setAmountOff = (amountOff) => ({
	type: CONSTANT_ACTIONS.SET_AMOUNT_OFF,
	payload: amountOff
});

export const setPercentOff = (percentOff) => ({
	type: CONSTANT_ACTIONS.SET_PERCENT_OFF,
	payload: percentOff
});

export const removePromoCode = (data) => ({
	type: CONSTANT_ACTIONS.REMOVE_PROMO_CODE,
	payload: data
});

export const resultAppliedPromoCode = (data) => ({
	type: CONSTANT_ACTIONS.RESULT_APPPLIED_PROMO_CODE,
	payload: data
});

export const addTemporaryPromoCode = (data) => ({
	type: CONSTANT_ACTIONS.ADD_TEMPORARY_PROMO_CODE,
	payload: data
});

export const changePromoCode = (data) => ({
	type: CONSTANT_ACTIONS.CHANGE_PROMO_CODE,
	payload: data
});

export const voipIsSupported = (mode) => ({
	type: CONSTANT_ACTIONS.VOIP_IS_SUPPORTED,
	payload: mode
});

export const productModeUnmount = () => ({
	type: CONSTANT_ACTIONS.PRODUCT_MODE_UNMOUNT
})

export const openQueuedJobBottomSheet = () => ({
	type: CONSTANT_ACTIONS.OPEN_QUEUED_JOB_BOTTOM_SHEET
});

export const closeQueuedJobBottomSheet = () => ({
	type: CONSTANT_ACTIONS.CLOSE_QUEUED_JOB_BOTTOM_SHEET
});

export const setQueuedJobTime = (time) => ({
	type: CONSTANT_ACTIONS.SET_QUEUED_JOB_TIME,
	payload: time
});

export const toggleScheduleBooking = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_SCHEDULE_BOOKING
});

export const dispatchSetDefaultScheduleDate = (date) => ({
	type: CONSTANT_ACTIONS.DISPATCH_SET_DEFAULT_SCHEDULE_DATE,
	payload: date
});

export const setScheduleBookingDate = (date) => ({
	type: CONSTANT_ACTIONS.SET_SCHEDULE_BOOKING_DATE,
	payload: date
});

export const setActiveTab = (activeTab) => ({
	type: CONSTANT_ACTIONS.SET_ACTIVE_TAB,
	payload: activeTab
});

export const toggleRequestPlacedModal = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_REQUEST_PLACED_MODAL
});

export const setMaxDate = (date) => ({
	type: CONSTANT_ACTIONS.SET_MAX_DATE,
	payload: date
});

export const setMinDate = (date) => ({
	type: CONSTANT_ACTIONS.SET_MIN_DATE,
	payload: date,
});

export const toggleConfirmationBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_CONFIRMATION_BOTTOM_SHEET
});

export const setDefaultConfirmationBottomSheet = () => ({
	type: CONSTANT_ACTIONS.SET_DEFAULT_CONFIRMATION_BOTTOM_SHEET
});

export const setPriceWithAplliedPromoCode = (price) => ({
	type: CONSTANT_ACTIONS.SET_PRICE_WITH_APLLIED_PROMO_CODE,
	payload: price
});

export const setLocationsLength = (length) => ({
	type: CONSTANT_ACTIONS.SET_LOCATIONS_LENGTH,
	payload: length
});

export const setSelectedQueued = (selectedQueued) => ({
	type: CONSTANT_ACTIONS.SET_SELECTED_QUEUED,
	payload: selectedQueued
});

export const emptyProductObject = () => ({
	type: CONSTANT_ACTIONS.EMPTY_PRODUCT_OBJECT
});

export const fetchPriceLoading = (condition) => ({
	type: CONSTANT_ACTIONS.FETCH_PRICE_LOADING,
	payload: condition
});

export const fetchPriceLocationLoading = (condition) => ({
	type: CONSTANT_ACTIONS.FETCH_PRICE_LOCATION_LOADING,
	payload: condition
});

export const getJobLoading = (condition) => ({
	type: CONSTANT_ACTIONS.GET_JOB_LOADING,
	payload: condition
});

export const scheduleLoading = (condition) => ({
	type: CONSTANT_ACTIONS.SCHEDULE_LOADING,
	payload: condition
});

export const queuedLoading = (condition) => ({
	type: CONSTANT_ACTIONS.QUEUED_LOADING,
	payload: condition
});

export const promoCodeLoading = (condition) => ({
	type: CONSTANT_ACTIONS.PROMO_CODE_LOADING,
	payload: condition
});

export const extentionRequestLoading = (condition) => ({
	type: CONSTANT_ACTIONS.EXTENSION_REQUEST_LOADING,
	payload: condition
})