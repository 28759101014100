import React from "react";
import _ from "lodash";
import SingleLineSkeleton from "../../ui-elements/Skeleton/SingleLineSkeleton";

import { ReactComponent as BankCard } from "../../../v5-icons/bank-card.svg";
import { cardType } from "../../../utils/constants/constant";
const classNames = require("classnames");

export default function BookingPayment({ primaryPayment, handleClickBookingItem, loading }) {
	
	return (
		<div className="review-booking-item-container">
			<p className="title">Primary Bank Card</p>
			<div className="review-booking-item-inner" onClick={handleClickBookingItem}>
				{loading ? (
					<>
						<SingleLineSkeleton />
						<BankCard />
					</>
				) : _.isNull(primaryPayment) || _.isUndefined(primaryPayment) ? (
					<>
						<p>None</p>
						<BankCard />
					</>
				) : (
					<>
						{primaryPayment[0].type === "GCash" || primaryPayment[0].name === "GCash" ? (
							<p>{!_.isNull(primaryPayment[0].name) ? primaryPayment[0].name : primaryPayment[0].type}</p>
						) : (
							<p>
								{!_.isNull(primaryPayment[0].name) ? primaryPayment[0].name : primaryPayment[0].type} ****{" "}
								{primaryPayment[0].cardNumber}
							</p>
						)}

						<img
							src={primaryPayment[0].icon}
							alt={primaryPayment[0].type}
							className={classNames("billing-card-image", primaryPayment[0].type)}
						/>
					</>
				)}
			</div>
			<span className="hint">In case of insufficient mykoins balance, this card will be charged once the job starts</span>
		</div>
	);
}