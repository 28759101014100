import React, {Fragment} from 'react';

import CurrentIcon from '../../icons/current-icon';

export default function LocationSearchCurrent(props) {

    return (
        <Fragment>
            <button type="button" className="location-search-current" onClick={props.onGetCurrent}>
                <CurrentIcon />
            </button>
        </Fragment>
    );
}