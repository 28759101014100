import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import MyJobs from "./MyJobs";
import TitleBar from "../../organisms/title-bar";
import RequestPlaced from "../job-history/Jobs/RequestPlaced";

import { unMountJobsList } from "../../utils/redux/actions/myJobsActions/myJobsChangeState";
import JobsEmpty from "../job-history/Jobs/jobs-empty/jobs-empty";
import * as LOG_EVENT from "../../utils/analytics";
import { toggleLogInBottomSheet, toggleRegisterBottomSheet } from "../../utils/redux/actions/commonActions/commonChangeState";
import LogInBottomSheet from "../LogInBottomSheet/LogInBottomSheet";
import BottomSheet from "../ui-elements/BottomSheet/BottomSheet";
import RegisterForm from "../register/form/register-form";
class MyJobsList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillUnmount() {
		this.props.dispatch(unMountJobsList());
	}

	openLogInBottomSheet = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "bar" });
		this.props.dispatch(toggleLogInBottomSheet());
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		return (
			<Fragment>
				{this.props.booking.requestPlacedModal ? (
					<RequestPlaced />
				) : (
					<div className="jobs-list-container">
						<TitleBar title="My Jobs" />
						{this.props.common.auth && this.props.common.authCheck ? (
							<MyJobs />
						) : (
							<div style={{ marginTop: "144px" }}>
								<JobsEmpty
									activeTabTitle="You're not logged in"
									icon="jobsUnauthenticated"
									tabType="all"
									buttonText="Login"
									description="To access your jobs history you would need to login first."
									onNavigateToSearch={this.openLogInBottomSheet}
								/>
							</div>
						)}
					</div>
				)}
				<BottomSheet
					classes="login-bottom-sheet"
					isOpen={this.props.common.logInBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={false}
					onChangeOverlay={(isOpen) => {
						//false => phone | true => showing verification
						if (this.props.enteringVerificationCode) {
							LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "code" });
						} else {
							LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "phone" });
						}
						if (!isOpen) {
							this.props.dispatch(toggleLogInBottomSheet());
						}
					}}
				>
					<LogInBottomSheet />
				</BottomSheet>

				{this.props.common.registerBottomSheet ? (
					<BottomSheet
						classes="login-bottom-sheet"
						isOpen={this.props.common.registerBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={false}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(toggleRegisterBottomSheet(false));
								localStorage.removeItem("token");
								localStorage.removeItem("username");
								localStorage.removeItem("countryFlag");
							}
						}}
					>
						<RegisterForm />
					</BottomSheet>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.myJobs,
	booking: state.booking,
	common: state.common,
	jobs: state.jobs
});

export default connect(mapStateToProps)(MyJobsList);
