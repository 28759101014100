import React, { Component, } from 'react';
import { connect } from 'react-redux';
import './MessageList.css';
import { IoIosArrowDropdown } from "react-icons/io";

import { fetchMessagesByOffset } from '../../../utils/redux/actions/chatActions';
import MessageBox from '../MessageBox/MessageBox';
import FetchingLoading from '../loading/loading';
import {JOB_STATE_COMPLETED} from '../../../utils/redux/actions/jobsActions/jobsConstant';
import log from "eslint-plugin-react/lib/util/log";

const classNames = require('classnames');

export class MessageList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollBottom: 0,
            downButton: false,
            isDateShow: false
        };

        this.loadRef = this.loadRef.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.checkScroll = this.checkScroll.bind(this);
        this.updateScrollBar = this.updateScrollBar.bind(this);
    }

    componentDidMount() {
        this.checkScroll();
        window.addEventListener('resize', this.updateScrollBar);
    }

    checkScroll() {
        var e = this.mlistRef;
        if (!e)
            return;

        if (this.props.toBottomHeight === '100%' || this.state.scrollBottom < this.props.toBottomHeight) {
            e.scrollTop = e.scrollHeight;
        } 
    }

    componentWillReceiveProps(nextProps) {
        if (!this.mlistRef)
            return;
        if (nextProps.dataSource.length !== this.props.dataSource.length) {
            this.setState({
                scrollBottom: this.getBottom(this.mlistRef),
            }, this.checkScroll.bind(this));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScrollBar);
    }

    getBottom(e) {
        return e.scrollHeight - e.scrollTop - e.offsetHeight;
    }

    onOpen(item, i, e) {
        if (this.props.onOpen instanceof Function)
            this.props.onOpen(item, i, e);
    }

    onDownload(item, i, e) {
        if (this.props.onDownload instanceof Function)
            this.props.onDownload(item, i, e);
    }

    onPhotoError(item, i, e) {
        if (this.props.onPhotoError instanceof Function)
            this.props.onPhotoError(item, i, e);
    }

    onClick(item, i, e) {
        if (this.props.onClick instanceof Function)
            this.props.onClick(item, i, e);
    }

    onContextMenu(item, i, e) {
        if (this.props.onContextMenu instanceof Function)
            this.props.onContextMenu(item, i, e);
    }

    onMessageFocused(item, i, e) {
        if (this.props.onMessageFocused instanceof Function)
            this.props.onMessageFocused(item, i, e);
    }

    loadRef(ref) {
        this.mlistRef = ref;
        if (this.props.cmpRef instanceof Function)
            this.props.cmpRef(ref);
    }

    onScroll(e) {
        let scroll = document.getElementById('v-scrollable-chat');

        if (e.target.scrollTop === 0 && this.props.jobs.job.state !== JOB_STATE_COMPLETED) {

            this.props.dispatch(fetchMessagesByOffset(scroll.scrollHeight));
        }
        var bottom = e ? this.getBottom(e.target) : 0;
        this.setState(() => ({ scrollBottom: bottom }))
        if (this.props.toBottomHeight === '100%' || bottom > this.props.toBottomHeight) {
            if (this.state.downButton !== true) {
                this.setState({
                    downButton: true,
                    scrollBottom: bottom,
                });
            }
        } else {
            if (this.state.downButton !== false) {
                this.setState({
                    downButton: false,
                    scrollBottom: bottom,
                });
            }
        }

        if (this.props.onScroll instanceof Function) {
            this.props.onScroll(e);
        }
    }

    toBottom(e) {
        if (!this.mlistRef)
            return;
        this.mlistRef.scrollTop = this.mlistRef.scrollHeight;
        if (this.props.onDownButtonClick instanceof Function) {
            this.props.onDownButtonClick(e);
        }
    }

    updateScrollBar() {
        this.checkScroll();
    }

    render() {
        return (
			<div className={classNames(["rce-container-mlist", this.props.className])}>
				{this.props.isFetching && <FetchingLoading />}
				<div ref={this.loadRef} onScroll={this.onScroll} id="v-scrollable-chat" className="rce-mlist">
					{this.props.dataSource[0].items.map((x, i) => (
                        <div key={i} onClick={() => this.setState({isDateShow: !this.state.isDateShow})}>
                            <div className="group-date">{x?.groupDateString}</div>
                            <MessageBox
                                {...x}
                                index={x.index}
                                isDateShow={this.state.isDateShow}
                                onOpen={this.props.onOpen && ((e) => this.onOpen(x, i, e))}
                                onPhotoError={this.props.onPhotoError && ((e) => this.onPhotoError(x, i, e))}
                                onDownload={this.props.onDownload && ((e) => this.onDownload(x, i, e))}
                                onClick={this.props.onClick && ((e) => this.onClick(x, i, e))}
                                onContextMenu={this.props.onContextMenu && ((e) => this.onContextMenu(x, i, e))}
                                onMessageFocused={this.props.onMessageFocused && ((e) => this.onMessageFocused(x, i, e))}
                                partner={this.props.partner}
                                jobId={this.props.jobs.job.id}
                                isChatHistory={this.props.isChatHistory}
                            />
                        </div>

					))}
				</div>

				{this.props.downButton === true && this.state.downButton && this.props.toBottomHeight !== "100%" && (
					<div className="rce-mlist-down-button" onClick={this.toBottom.bind(this)}>
						<IoIosArrowDropdown />
						{this.props.downButtonBadge && <span className="rce-mlist-down-button--badge">{this.props.downButtonBadge}</span>}
					</div>
				)}
			</div>
		);
    }
}

MessageList.defaultProps = {
    onClick: null,
    onTitleClick: null,
    onForwardClick: null,
    onDownButtonClick: null,
    onOpen: null,
    onPhotoError: null,
    onDownload: null,
    dataSource: [],
    lockable: false,
    toBottomHeight: 300,
    downButton: true,
    downButtonBadge: null,
};

const mapStateToProps = state => ({
    ...state.chat,
    common: state.common,
    jobs: state.jobs,
});

export default connect(mapStateToProps)(MessageList);
