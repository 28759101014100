import React, { Fragment } from 'react';
import AccordionMenu from "../../AccordionMenu/AccordionMenu";
const classNames = require('classnames');

export default function SavedPlacesOthersItem(props) {
	return (
		<Fragment>
			<div className={classNames("saved-places-others-item", props.id)}>
				<div className="saved-places-info" onClick={(e) => props.onClick(e, props.place, props.index)}>
					<div className="icon">{props.icon}</div>
					<div className="title-container">
						<p className="name">{props.name}</p>
						<p className="sub-title">{props.place.location.address || props.place.location.name}</p>
					</div>
				</div>
				<AccordionMenu actionIcon={props.actionIcon} place={props.place} index={props.index} />
			</div>
		</Fragment>
	);
}