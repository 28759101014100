import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from "react-toastify";	

import * as DISPATCH_STATE from '../jobsChangeState';
import * as CONSTANT_ACTIONS from '../jobsConstant';
import { GraphRequest } from "../../../../../axios";

import { convertTime } from "../../../../functions/job-time-convert";
import { appFetchFailure } from "../../commonActions";
import { changeTimeFormat } from "../../commonActions/commonChangeState";
import { initMessagesFromJobChatHistory } from '../../chatActions';
import { onCloseLivePartnerLocation } from '../../chatActions';
import { fetchJobById, fetchActiveJobRequestBody } from "../jobs-gql";
// import {createMarkerPartnerLocation} from "../../mapActions";
/**
 * Fetch job information
 * @param {*} id
 * @param {*} getToken 
 */
// export function fetchJob(id, getToken = null) {
export function fetchJob(id) {
    return (dispatch, getState) => {
        const fetchJob = {
            query: fetchJobById,
            variables: { "requestId": Number(id) }
        }
        return new Promise((resolve, reject) => {
            dispatch(getJob(fetchJob))
                .then(() => resolve())
        })
    };
}
/**
 * check to remove listener from chat
 */
export function channelRemoveListener() {
    return (dispatch, getState) => {
        if (getState().chat.channelRoom && !getState().chat.channel) {
            getState().chat.channelRoom.removeAllListeners();
        }
    }
}

export const fetchJobInformationSuccess = (job) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.fetchJobInformationSuccessDispatch(job));
    }
};

export function fetchUpdatedJob(requestId) {
    return (dispatch, getState) => {
        const getJobRequestBody =  {
            query: fetchJobById,
            variables: { "requestId": requestId ? Number(requestId) : getState().jobs.job.id}
        }
        return new Promise((resolve, reject) => {
            dispatch(getJob(getJobRequestBody)).then((res) => {
                if (!_.isNull(res) && !_.isUndefined(res)) {
                        dispatch(fetchJobInformationSuccess(res));
                        dispatch(DISPATCH_STATE.setCubeNumber(res.jobTime.cubeNumbers));
                        dispatch(changeTimeFormat(convertTime(res.jobTime.total.value)));
                        resolve(true)
                    }
            });
        });
    }
}
/**
 * When user click on one job
 */
export const navigateOnClickJobInJobsList = (job) => {
    return (dispatch) => {
        dispatch(onCloseLivePartnerLocation());
        if (job.state === CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(push('/jobs/' + job.id));
        } else if ((job.state === "Queued" && !job.product.mode.includes("MY_MALL"))
            || job.state === "Scheduled" || job.state === "Matching") {
            dispatch(push(`/jobs/${job.id}/open-job`));
            dispatch(DISPATCH_STATE.setJobData(job));
        } else {
            dispatch(push('/jobs/' + job.id + '/chat/'));
        }
    }
}

// navigate from home
export const setJobsLocationPointer = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.myJobsSetSearchLocationPointer(getState().router.location.pathname));
    }
};

//job is completed and extended so we wanna to check each used payment methood
export const checkPaymentMethodUsed = () => {
    return (dispatch, getState) => {
        
        let clonnedPaymentMethod;
        if (
			!_.isNull(getState().jobs.unrated) &&
			!_.isNull(getState().jobs.unrated.payments) &&
			getState().jobs.unrated.payments.length
		) {
			clonnedPaymentMethod = [...getState().jobs.unrated.payments];
		} else if (
			!_.isNull(getState().jobs.job) &&
			!_.isNull(getState().jobs.job.payments) &&
			getState().jobs.job.payments.length
		) {
			clonnedPaymentMethod = [...getState().jobs.job.payments];
		}

        if (clonnedPaymentMethod && clonnedPaymentMethod.length) {
            let cardUsage = 0;
            let mykoinUsage = 0;
            if (clonnedPaymentMethod.length === 1 && clonnedPaymentMethod[0].cardUsage > 0) {
                if (!_.isNull(clonnedPaymentMethod[0].card) && clonnedPaymentMethod[0].card) {
                    dispatch(DISPATCH_STATE.addCardDetail(clonnedPaymentMethod[0].card));
                }
            }
    
            const paymentDetail = clonnedPaymentMethod.reduce((acc, current) => {
                if (acc.cardUsage || current.cardUsage) {
                    let accumulatorCardUsage = acc.cardUsage ? acc.cardUsage : 0;
                    let currentCardUsage = current.cardUsage ? current.cardUsage : 0;
                    cardUsage = accumulatorCardUsage + currentCardUsage;
                    if (!_.isNull(current.card) && current.card) {
                        dispatch(DISPATCH_STATE.addCardDetail(current.card));
                    } else if (!_.isNull(acc.card) && acc.card) {
                        dispatch(DISPATCH_STATE.addCardDetail(acc.card));
                    }
                }
                if (acc.mykoinUsage || acc.cardUsage) {
                    let accumulatorMykoinUsage = acc.mykoinUsage ? acc.mykoinUsage : 0;
                    let currentMykoinUsage = current.mykoinUsage ? current.mykoinUsage : 0;
                    mykoinUsage = accumulatorMykoinUsage + currentMykoinUsage;
                }
                return {"mykoinUsage": mykoinUsage, "cardUsage": cardUsage}
            });
            
            const paymentDetailObject = {
                "cardUsage": paymentDetail["cardUsage"],
                "mykoinUsage": paymentDetail["mykoinUsage"]
            } 
            dispatch(DISPATCH_STATE.detailPaymentMethod(paymentDetailObject));
        }
    }
};

export function fetchActiveJob(id) {
    return (dispatch, getState) => {
        const fetchJobRequestBody = {
            query: fetchActiveJobRequestBody,
            variables: { "requestId": Number(id) }
        }
        return new Promise((resolve, reject) => {
            dispatch(fetchJobApiCall(fetchJobRequestBody))
                .then((res) => {
                    if (res && !_.isUndefined(res) && res.data && res.data.request) {
                        dispatch(fetchJobInformationSuccess(res.data.request));
                        resolve(res.data.request)
                    } else {
                        dispatch(appFetchFailure(res.data.errors[0].message));
                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    };
};

export const getJob = (requestBody) => {
    return (dispatch, getState) => {
        return new Promise((resolveFetch) => {
            dispatch(DISPATCH_STATE.fetchJobInformationBegin());
            GraphRequest.all(requestBody)
                .then(json => {
                    if (json.data.data.request.chatMessagesHistory) {
                        dispatch(fetchJobInformationSuccess(json));
                        dispatch(initMessagesFromJobChatHistory(json));
                    } else {
                        dispatch(fetchJobInformationSuccess(json.data.data.request));
                        if (json.data.data.request.clientRate) {
                            const sortedArr = json.data.data.request.clientRate.compliments.reduce((acc, element) => {
                                if (element.feedbackType === "POSITIVE") {
                                    return [element, ...acc];
                                }
                                return [...acc, element];
                            }, []);
                            dispatch(DISPATCH_STATE.setSortedClientRate(sortedArr))
                        }
                        resolveFetch(json.data.data.request);
                    }
                })
                .catch(error => dispatch(appFetchFailure(error)));
        });
    }
}

export const fetchJobApiCall = (requestBody) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await GraphRequest.all(requestBody);
            return data;
        
        } catch(err) {}
    }
}

/**
 * Increase cube box to request for increase time
 */
export function increaseCubeNumber() {
	return (dispatch, getState) => {
		let index = getState().jobs.cubeNumber + 1;
		if (getState().booking.prices.items.length - 1 >= index) {
			dispatch(DISPATCH_STATE.plusCubeNumber());
		}
	};
}

/**
 * Decrease cube box from request for decrease time
 */
export function decreaseCubeNumber() {
	return (dispatch, getState) => {
        let index = getState().jobs.cubeNumber - 1;
		if (index >= 0) {
			dispatch(DISPATCH_STATE.minusCubeNumber());
		}
	};
}