import React, {Fragment} from 'react';

export default function BookingServiceDescription(props) {

    return (
        <Fragment>
            <div className="booking-service-description-component">
                <p className="">{props.description}</p>
            </div>
        </Fragment>
    );
}