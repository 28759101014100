export const FETCH_HOME_BEGIN   = 'FETCH_HOME_BEGIN';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const CLICK_SHOW_MORE_SERVICES = 'CLICK_SHOW_MORE_SERVICES';
export const CLICK_ON_SERVICE_FOR_SHOW_DETAIL = 'CLICK_ON_SERVICE_FOR_SHOW_DETAIL'; // when user click on each service in home page
export const HOME_WILL_UN_MOUNT = 'HOME_WILL_UN_MOUNT';
export const HOME_REJECTED_PAYMENT = 'HOME_REJECTED_PAYMENT'; // check home rejected payment
export const HOME_REJECTED_PAYMENT_PSP = 'HOME_REJECTED_PAYMENT_PSP'; // close home rejected payment
export const HOME_PRODUCT_CHECK_EXIST = 'HOME_PRODUCT_CHECK_EXIST'; // Check product exist or not
export const HOME_PRODUCT_LIST_SHOULD_UPDATED = 'HOME_PRODUCT_LIST_SHOULD_UPDATED'; // product list should updated
export const FETCH_PRODUCT_CATALOG_BEGIN = "FETCH_PRODUCT_CATALOG_BEGIN";
export const FETCH_PRODUCT_CATALOG_SUCCESS = "FETCH_PRODUCT_CATALOG_SUCCESS";
export const GO_TO_MAP_FROM_BOTTOM_SHEET = "GO_TO_MAP_FROM_BOTTOM_SHEET";
export const SET_SHOP_INFO = "SET_SHOP_INFO";
export const SET_CATEGORIZED_PRODUCTS = "SET_CATEGORIZED_PRODUCTS";
export const TOGGLE_NAVIGATE_BOTTOM_SHEET = "TOGGLE_NAVIGATE_BOTTOM_SHEET";
export const SET_SHOW_IN_HOME_PRODUCTS = "SET_SHOW_IN_HOME_PRODUCTS";
export const SET_SHOW_IN_HOME_CATEGORIES = "SET_SHOW_IN_HOME_CATEGORIES";