import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import JobChatHistoryCancelForm from './JobChatHistoryCancelForm';

function JobChatHistoryCancelModal({ navigateToSupport }) {
	return (
		<Fragment>
			<div className="Job-chat-history-cancel">
				<h2 className="title">Reason For Cancelling Job</h2>

				<JobChatHistoryCancelForm navigateToSupport={navigateToSupport} />
			</div>
		</Fragment>
	);
}

const mapStateToProps = state => ({
    jobs: state.jobs,
    chat: state.chat,
    common: state.common,
});

export default connect(mapStateToProps)(JobChatHistoryCancelModal);