import React, { Component } from 'react';
import Slider from "react-slick";
import $ from 'jquery';
import {connect} from 'react-redux';

import BGImg from '../../assests/images/tutorial/BG.svg';
import {appGoToWelcome} from '../../utils/redux/actions/commonActions';
import {localStorageSetTutorialBrowser} from '../../utils/functions/local-storage';

import StepOneImg  from '../../assests/images/tutorial/tu1.png';
import StepTwoImg from '../../assests/images/tutorial/tu2.png';
import StepThreeImg from '../../assests/images/tutorial/tu3.png';
import StepFourImg from '../../assests/images/tutorial/tu4.png';
import StepFiveImg from '../../assests/images/tutorial/tu5.png';
// import * as LOG_EVENT from '../../utils/analytics';

const SLIDER_COUNT_LENGTH = 3;

class TutorialFirefox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 0
        }
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#0b90f9';
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        document.getElementsByTagName('body')[0].style.backgroundColor = '#fff';
    }

    next = () => {
        localStorageSetTutorialBrowser();
        this.slider.slickNext();
    }

    skip = () => {
        localStorageSetTutorialBrowser();
        // LOG_EVENT.logEvent(LOG_EVENT.CUSTOMER_ONBOARDING_FINISHED, { currentStep: this.state.active, currency: 'PHP', value: 2.00 })
        this.props.dispatch(appGoToWelcome());
    }

    render() {
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            touchMove: true,
            beforeChange: (current, next) => {
                this.setState({ active: next },  ()=>{
                    if(next === SLIDER_COUNT_LENGTH) {
                        // LOG_EVENT.logEvent(LOG_EVENT.SIGNUP_COMPLETED_SUCCESSFULLY)
                    }
                    // LOG_EVENT.logEvent(LOG_EVENT.CUSTOMER_ONBOARDING_FINISHED, { currentStep: next, currency: 'PHP', value: 2.00 })
                })
            }
        };
    
        let height = $(window).height() + 'px';
        let imgHeight = $(window).height() * 2 / 3 + 'px';
    
        return (
    
            <div className="Tutorial">
                {this.state.active === SLIDER_COUNT_LENGTH ? 
                    <button onClick={this.skip} className="next-tutorial">DONE</button>
                : 
                    <button onClick={this.next} className="next-tutorial">NEXT</button>
                }
                
                <button onClick={this.skip} className="skip-tutorial">SKIP</button>
				<Slider {...settings} ref={c => (this.slider = c)} >
					<div>
						<div className="slide" style={{'background': 'url(' + BGImg + ') no-repeat', 'height': height}} >
							<div className="Slide_Box_Img">
								<img src={StepOneImg} style={{'height': imgHeight}} alt="" className="step-svg img-responsive"/>
							</div>
							<div className="Text_Box text  no-padding">
								<h5 className="title">Choose a service</h5>
								<p className="description">Save time. Let us do jobs for you</p>
							</div>
						</div>
					</div>
					<div>
						<div className="slide" style={{'background': 'url(' + BGImg + ') no-repeat', 'height': height}} >
							<div className="Slide_Box_Img">
								<img src={StepTwoImg} style={{'height': imgHeight}} alt="" className="step-svg img-responsive"/>
							</div>
							<div className="Text_Box text  no-padding">
								<h5 className="title">Pin the job locaion</h5>
								<p className="description">Multiple-locations? Just add a stop!</p>
							</div>
						</div>
					</div>
					<div>
						<div className="slide" style={{'background': 'url(' + BGImg + ') no-repeat', 'height': height}} >
							<div className="Slide_Box_Img">
								<img src={StepThreeImg} style={{'height': imgHeight}} alt="" className="step-svg img-responsive"/>
							</div>
							<div className="Text_Box text  no-padding">
								<h5 className="title">  Choose payment</h5>
								<p className="description">No cash, no problem. Safe and secure</p>
							</div>
						</div>
					</div>
					<div>
						<div className="slide" style={{'background': 'url(' + BGImg + ') no-repeat', 'height': height}} >
							<div className="Slide_Box_Img">
								<img src={StepFourImg} style={{'height': imgHeight}} alt="" className="step-svg img-responsive"/>
							</div>
							<div className="Text_Box text  no-padding">
								<h5 className="title">Give honest feedback</h5>
								<p className="description">You matter. We want to serve you better</p>
							</div>
						</div>
					</div>
					<div>
						<div className="slide" style={{'background': 'url(' + BGImg + ') no-repeat', 'height': height}} >
							<div className="Slide_Box_Img">
								<img src={StepFiveImg} style={{'height': imgHeight}} alt="" className="step-svg img-responsive"/>
							</div>
							<div className="Text_Box text  no-padding">
								<h5 className="title">Get help anytime</h5>
								<p className="description">MyKuya Support is here for you</p>
							</div>
						</div>
					</div>
				</Slider>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(TutorialFirefox);