import _ from "lodash";
import { push } from "connected-react-router";
import $ from "jquery";
import { toast } from "react-toastify";	

import * as LOG_EVENT from "../../../analytics";
import * as DISPATCH_STATE from "./homeChangeState";
import * as TourText from "../../../constants/constant";
import { GraphRequest } from "../../../../axios";
import { appFetchFailure, fetchClient, setConfigForTour, checkIsShop, validURL } from "../commonActions";
import { appLoadingBegin, appLoadingEnd, saveLastPathName } from "../commonActions/commonChangeState";
import { registerFetchCountryCode } from "../registerActions/registerChangeState";
import { fetchJobsUnratedSuccess, jobsActive } from "../jobsActions/jobsChangeState";
import { mapGetcurrentLocationLocalstorage } from "../mapActions/actions/map-current-location";
import { getHomeObject, getHomeObjectWithoutAuth } from "./home-gql";
import {
	setShopIdStorage,
	localStorageGetSkipTour,
	locationStorageGetData,
	getHomePickedLocationLocalStorage,
	getDeliveringToPlaceLocalStorage,
} from "../../../functions/local-storage";
import { fetchBookingV5, navigateToRequestPage } from "../bookingActions";
import { setDeliveringToPlaceInfo } from "../savedPlacesActions";
import { getLastUsedRequestBody } from "../savedPlacesActions/actions/saved-places-gql";
import { setHomePickedLocation } from "../savedPlacesActions/actions/savedPlaces-select";
import { getExactAddress } from "../../../functions/nearby-search";
import {
	createPlaceObjectFromGoogleGetExactAddressFromRoute
} from "../../../functions/create-place-object-from-google-suggestions";
import {localStorageIsTwa} from "../../../functions/local-storage"
/**
 * Show more button
 */
export const clickShowMoreServices = () => {
	return (dispatch, getState) => {
		if (getState().home.isShowMore) {
			$(".Home-services-hidden").slideUp(600);
		} else {
			$(".Home-services-hidden").slideDown(600);
		}
		dispatch(DISPATCH_STATE.clickSHowMoreServices());
	};
};

/**
 * navigate user to specific service by clicking on services in home
 * @param {*} service
 */
export function clickService(service) {
	return (dispatch, getState) => {
		if (service.mode.includes("MY_MALL")) {
			dispatch(push("/booking/" + service.id + "/review-mymall", {productId: service.id}));
		} else {
			dispatch(fetchBookingV5(service.id)).then(() => {
				dispatch(DISPATCH_STATE.clickOnServiceForShowDetail());
				LOG_EVENT.logEvent(LOG_EVENT.APP_PRODUCT_VIEWED_CWA, {
					products: [
						{
							product_id: service.id,
							name: service.commercialName
						}
					],
					value: 1.00,
					currency: service.currency
				});
				dispatch(push("/booking/" + service.id));
			});
		}
	};
}

export function clickSubCategories(service) {
	return (dispatch, getState) => {
		dispatch(push("/subcategories/" + service.id));
	};
}
export function backToHme() {
	return (dispatch, getState) => {
		dispatch(push("/home" ));
	};
}

/**
 * Fetch element that is needed by home page
 */
export function fetchHome(pageName) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.fetchHomeBegin());

		const { location } = getState().map;
		const localStorageLocation = locationStorageGetData(getState);
		let homeRequestBody;
		dispatch(checkIsShop())
			.then((res) => {
				if(res) {
					switch(res) {
						case "isStore":
							homeRequestBody = {
								query: getState().common.authCheck && getState().common.auth ? getHomeObject : getHomeObjectWithoutAuth,
								variables: {
									lat: _.isNull(location.lat) ? getState().map.defaultPickupLocation.lat : location.lat,
									lng: _.isNull(location.lng) ? getState().map.defaultPickupLocation.lng : location.lng,
									isWhatsNew: true,
								},
							};
							break;
						case "isNotStore":
							homeRequestBody = {
								query: getState().common.authCheck && getState().common.auth ? getHomeObject : getHomeObjectWithoutAuth,
								variables: {
									lat: _.isNull(localStorageLocation.lat) ? location.lat : localStorageLocation.lat,
									lng: _.isNull(localStorageLocation.lng) ? location.lng : localStorageLocation.lng,
									isWhatsNew: true,
								},
							};
							break;
						
						default:
							break;
					}
					if (localStorage.getItem("pageIsLoaded")) {
						if(getState().common.lastPathName !== "/welcome") {
							let homeTimeout = setTimeout(() => {
								LOG_EVENT.logEvent(LOG_EVENT.WELCOME_PAGE_LOADED, {
									os: localStorageIsTwa() ? "twa" : "web",
									autoGenerated: true
								});
								clearTimeout(homeTimeout);
							}, 1000);
							let checkTimeout = setTimeout(() => {
								LOG_EVENT.logEvent(LOG_EVENT.INITIAL_LOCATION_PICKED_CWA, { 
									location: "cityChosen", 
									os: localStorageIsTwa() ? "twa" : "web",
									autoGenerated: true
								 });
								clearTimeout(checkTimeout);
							}, 2000);
						} 
						// else {
						// 	dispatch(saveLastPathName(null));
						// }
					} 
					GraphRequest.all(homeRequestBody)
						.then((res) => {
							if (res.data && res.data.data) {
								const { productCatalog, announcements, activeJobs, unratedJobs, countryCodes } = res.data.data;
								let showInHomeProducts = [];
								if (productCatalog) {
									let allSubCategories = [];
									let showInHomeCategories = [];
									productCatalog.map((categories) => {
										allSubCategories.push(categories);
										categories.subCategories.map((subCategories) => {
											if (subCategories.showInHome) {
												showInHomeCategories.push(categories);
												subCategories.products.map((products) => showInHomeProducts.push(products));
											}
										});
									});
									const showInHomeCategoriesUnique = [...new Set(showInHomeCategories)];
									dispatch(DISPATCH_STATE.setShowInHomeCategories(showInHomeCategoriesUnique));
									dispatch(DISPATCH_STATE.setShowInHomeProducts(showInHomeProducts));
								}
								if (announcements) {
									announcements.forEach((announcement) => {
										if (validURL(announcement.body)) {
											announcement.hasUrl = true;
										} else {
											announcement.hasUrl = false;
										}
									});
								}
								if (!_.isUndefined(countryCodes)) dispatch(registerFetchCountryCode(countryCodes));
								dispatch(DISPATCH_STATE.fetchHomeSuccess(productCatalog, announcements));
								dispatch(getRandomProduct(productCatalog)).then((res) => {
									if (res) {
										dispatch(DISPATCH_STATE.setCategorizedProducts(res));
										dispatch(checkEmptyProductsList(res));
									}
								});
								if (unratedJobs && unratedJobs.totalItems > 0) {
									dispatch(fetchJobsUnratedSuccess(unratedJobs["items"][0]));
								}

								// resolve(res.data.data);
								let checkTimeout = setTimeout(() => {
									LOG_EVENT.logEvent(LOG_EVENT.HOME_PAGE_CWA, { hasService: productCatalog.length ? true : false, os: localStorageIsTwa() ? "twa" : "web" });
									clearTimeout(checkTimeout);
								}, 3000);
								dispatch(setHomeConfigTour(productCatalog, pageName));
								dispatch(jobsActive(activeJobs));
							} else {
								toast(`${res.data.errors[0].message}`, {
									position: "bottom-center",
									autoClose: 5000,
									limit: 1,
									className: "toast-rejected-payment",
									bodyClassName: "toastify-inner",
									hideProgressBar: true,
									closeOnClick: false,
								});
								dispatch(appFetchFailure(res.data.errors[0].message));
							}
						})
						.catch((error) => {
							console.log(error);
						});
				}
			}).catch((error) => {
				console.log("error", error);
			})
		dispatch(mapGetcurrentLocationLocalstorage());
	};
}

export const getRandomProduct = (arr) => {
	return (getState, dispatch) =>  {
		return new Promise((resolve) => {
			let allProducts = [];
			arr.map((category) => {
				category.subCategories.map((products) => {
					if(products.showInHome) {
						products.products.map(product => {
							allProducts.push(product);
						});
					}
				});

			});
			if (!_.isEmpty(allProducts)) {
				const shuffled = allProducts.sort(function () { return .5 - Math.random() });
				const randomedProducts = shuffled.slice(0, 4);

				if (allProducts.length < 6) {
					resolve({ randomedProducts: null, allProducts: allProducts, showBottomsheet: true })
				} else {
					resolve({ randomedProducts, allProducts, showBottomsheet: false});
				}
			} else {
				resolve({ randomedProducts: null, allProducts: null, showBottomsheet: false})
			}
		});
	}
}

/**
 * Check products exist in array or not.
 * @param {*} products
 */
export const checkEmptyProductsList = allSubCategories => {
	return dispatch => {
		if (_.isEmpty(allSubCategories)) {
			dispatch(DISPATCH_STATE.homeProductCheckExist(false));
		} else {
			dispatch(DISPATCH_STATE.homeProductCheckExist(true));
		}
	};
};

/**
 * Show balance modal when user's balance is negative
 */
export function checkRejectedPayment() {
	return (dispatch, getState) => {
		if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance < 0) {
			dispatch(DISPATCH_STATE.homeRejectedPayment());
		}
	};
}

/**
 * Check for get client for balance
 */
export function checkForGetClientWhenForBalance() {
	return (dispatch, getState) => {
		dispatch(fetchClient(false)).then(r => {
			dispatch(checkRejectedPayment());
		});
	};
}

/**
 * If product is not exist in specific location user can change its location
 */
export function navigateToSearch() {
	return dispatch => {
		dispatch(push("/search"));
	};
}

export const fetchProductCatalog = (data) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.fetchProductCatalogBegin());
		dispatch(appLoadingBegin());
		const productCatalogRequestBody = {
			query: `query ProductCatalog($lat: Float!, $lng: Float!) {
				productCatalog(lat: $lat, lng: $lng) {
					title
					subCategories {
					id
					title
					showInHome
					icon
					products {
						id
						commercialName
						icon
					}
					}
				} 
			}`,
			variables: {
				"lat": Number(data.lat),
				"lng": Number(data.lng),
			}
		};
		return new Promise(resolve => {
			GraphRequest.all(productCatalogRequestBody)
				.then((res) => {
					const { productCatalog } = res.data.data;
					if (res.data.data.productCatalog) {
						let allSubCategories = [];
						productCatalog.map(subCategories => {
							allSubCategories.push(subCategories);
						});
						dispatch(checkEmptyProductsList(allSubCategories));
						resolve();
					}
	
					dispatch(DISPATCH_STATE.fetchProductCatalogSuccess(productCatalog));
					dispatch(appLoadingEnd());
				})
		})
	}
}

export const fetchShopInfo = () => {
	return (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch(appLoadingBegin());
			const shopInfoRequestBody = {
				query: `query ShopInfo( $url: String!){
					shopUrl: enterprisePartnerByShopURL(url: $url){
						id
						shop {
							title
						}
					}
				}`,
				variables: {
					url: window.location.host.split(".")[0].includes("localhost") ? "sara" : window.location.host.split(".")[0],
				},
			};
			GraphRequest.all(shopInfoRequestBody).then((res) => {
				if (!_.isNull(res.data.data) && res.data.data.shopUrl && res.data.data.shopUrl.shop) {
					dispatch(DISPATCH_STATE.setShopInfo(res.data.data.shopUrl));
					setShopIdStorage(res.data.data.shopUrl.id);
					resolve(true);
					dispatch(appLoadingEnd())
				} else {
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 5000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
					dispatch(appFetchFailure(res.data.errors[0].message));
				}
			});
		})
	}
}

export const setHomeConfigTour = (productCatalog, pageName) => {
	return (dispatch, getState) => {
		if(!_.isNull(localStorage.getItem("pageIsLoaded"))) {
			const isSkipped = localStorageGetSkipTour();
			if (!isSkipped) {
				if (!_.isEmpty(productCatalog)) {
					const steps = [
						{
							selector: '[data-tut="tour-services"]',
							content: TourText.Services,
							position: "top",
						},
					];
					dispatch(setConfigForTour(pageName, steps));
				}
			}
		} else {
			let checkTimeout = setTimeout(() => {
				dispatch(setHomeConfigTour(productCatalog, pageName));
				clearTimeout(checkTimeout)
			}, 500)
		}
		
		
	}
}

/**
 * Fetch last used saved place and calculate the price
 */
export const fetchLastUsed = () => {
	return (dispatch, getState) => {
		const lastUsedRequest = {
			query: getLastUsedRequestBody
		}
		GraphRequest.all(lastUsedRequest)
			.then((json) => {
				if (!_.isNull(json.data.data) && json.data.data.lastUsedSavedPlace) {
					dispatch(setDeliveringToPlaceInfo(json.data.data.lastUsedSavedPlace));
				} else {
					dispatch(setDeliveringToPlaceInfo({location: getState().map.location }));
				}
			})
			.catch((e) => dispatch(appFetchFailure(e)))
	}
}

export const initDeliveringToPlace = () => {
	return (dispatch, getState) => {
		getExactAddress(getState().map.location, getState, (results, status) => {
			const place = createPlaceObjectFromGoogleGetExactAddressFromRoute(
				results,
				getState().map.location,
				0,
			);
			if(place) {
				dispatch(setDeliveringToPlaceInfo(place));
			}
		});
	}
}