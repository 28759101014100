import _ from 'lodash';
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import * as DISPATCH_STATE from '../bookingChangeState';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from '../../commonActions';
import { getPrimaryPayment } from "../../commonActions/commonChangeState";
import { initJobByMatchingStatus, setIsOutOfWorkingHours, updateJobByMatchingStatus } from "../../jobsActions";
import { unsetMatchingRequest, setJobMatchingState } from "../../jobsActions/jobsChangeState";
import { initCancelRequestCountDown } from '../../jobsActions/jobsChangeState';
import { createReqeustObj } from '../../../../functions/create-booking-request-object';
import { fetchStateOfJob } from "../../jobsActions/actions/jobs-matching";
import { fetchPaymentMethods, fetchXenditPaymentMethods } from "../../billingActions";
import { fetchProduct, getPrice, createRequest, fetchMymallProductAndPrice } from "../booking-gql";
import { createPlaceObjectForFetchingPrice } from "../../../../functions/create-booking-request-object";
import { getShopIdStorage } from "../../../../functions/local-storage";
import { fetchSavedPlacesLoading } from "../../savedPlacesActions/savedPlacesChangeState";
import { bookingSetCurrentPrice, togglePaymentMethodsBottomSheet } from "../bookingChangeState";
/**
 * post request to server for save request job
 * @param {*} locations 
 */
export function postRequestNowBooking(requestObj) {
  return (dispatch, getState) => {
    return new Promise(resolve => { 
      const createJobRequestBody = {
        query: createRequest,
        variables: {
          "requestData": requestObj
        }
      }
      GraphRequest.all(createJobRequestBody)
        .then(json => {
          dispatch(DISPATCH_STATE.getJobLoading(false));
          if (!_.isNull(json.data.data) && json.data.data.createRequest) {
            dispatch(updateJobByMatchingStatus(json.data.data.createRequest));
            resolve(json.data.data.createRequest);
          } 
          else {
			  if (json.data.errors[0].extensions?.exception?.status === 417) {
				  dispatch(setIsOutOfWorkingHours(true));
				  dispatch(push('/jobs/' + requestObj.productId + '/matching'));
			  } else if (json.data.errors[0].extensions?.exception.status === 402) {
				  dispatch(togglePaymentMethodsBottomSheet());
			  } else {
				  dispatch(appFetchFailure(json.data.errors[0].message));
				  toast(`${json.data.errors[0].message}`, {
					  position: "bottom-center",
					  autoClose: 3000,
					  limit: 1,
					  className: "toast-rejected-payment",
					  bodyClassName: "toastify-inner",
					  hideProgressBar: true,
					  closeOnClick: false,
				  });
			  }
          }
        }).catch(error => {
          dispatch(DISPATCH_STATE.getJobLoading(false));
          dispatch(appFetchFailure(error));
          toast(`${error.response?.data?.errors[0]?.message}`, {
            position: "bottom-center",
            autoClose: 3000,
            limit: 1,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
            hideProgressBar: true,
            closeOnClick: false,
          });
        });
    })
  }
}
export function requestNowBooking(locations, deliveringPlace) {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.getJobLoading(true));
    dispatch(setJobMatchingState());
    let createReqeust = createReqeustObj(getState, locations, deliveringPlace);
    dispatch(DISPATCH_STATE.bookingOnRequestNowButtonClick(createReqeust));
    dispatch(initJobByMatchingStatus(createReqeust))
    dispatch(postRequestNowBooking(createReqeust))
    .then((res) => {
        if(res) {
          dispatch(fetchStateOfJob());
          dispatch(DISPATCH_STATE.getJobLoading(false));
          
          dispatch(push('/jobs/' + res.id + '/matching'));
        }
      }).catch((err) => {
        dispatch(DISPATCH_STATE.getJobLoading(false));
      })
  }
}
/**
 * Retry matching state by saving new request(job) by previous product
 */
export function retryRequestBooking() {
  return (dispatch, getState) => {
    dispatch(unsetMatchingRequest());
    // dispatch(cancelSetInterval());
    if (getState().booking.createReqeustObj) {
      let createReqeust = getState().booking.createReqeustObj;
      dispatch(initCancelRequestCountDown());
      dispatch(DISPATCH_STATE.bookingOnRequestNowButtonClick(createReqeust));
      dispatch(initJobByMatchingStatus(createReqeust));
      // dispatch(cancelRequestStartToCount());
      dispatch(postRequestNowBooking(createReqeust))
        .then((res) => {
          dispatch(fetchStateOfJob());
        })
    }
  }
}

/**
 * Just Fetch product without location
 * @param {*} id 
 */
export function fetchBookingV5(id) {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.fetchBookingBegin());
    return new Promise(resolve => {
      const productRequestBody = {
        query: fetchProduct,
        variables: {
          "id": Number(id)
        }
      }
      GraphRequest.all(productRequestBody)
			.then((json) => {
				if (!_.isNull(json.data.data) && json.data.data.product) {
					dispatch(DISPATCH_STATE.fetchBookingV5Success(json.data.data.product));
					resolve(json.data.data.product);
				} else {
					dispatch(appFetchFailure(json.data.errors[0].message));
					toast(`${json.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			})
			.catch((error) => {
				dispatch(appFetchFailure(error));
			});
    });
  };
}
/**
 * Fetch price
 * @param {*} id 
 * @param {*} locations 
 */
export function fetchPrice(id, locations) {
  return (dispatch, getState) => {
	  if (!_.isEmpty(getState().map.googlePlaces)) {
		locations.jobLocations = [
        ...locations.jobLocations,
        ...getState().map.googlePlaces
      ]
	}
	  const dispatchedLocation = locations.jobLocations.map((location) => createPlaceObjectForFetchingPrice(location));
    if(dispatchedLocation) {
      dispatch(DISPATCH_STATE.fetchPriceBegin(locations));
      let variables = {};
        if (Number(getShopIdStorage())) {
            variables = {
                "productId": +id,
                "priceModel": {
                  "jobLocations": dispatchedLocation,
                  "exclusiveServiceProviderId": !_.isNull(getState().home.shopInfo) ? Number(getState().home.shopInfo.id) : Number(getShopIdStorage())
                },
            }
        } else {
            variables = {
                "productId": +id,
                "priceModel": {
                  "jobLocations": dispatchedLocation,
                },
            }
        }
        const priceRequestBody = {
            query: getPrice,
            variables: variables
        }
      return new Promise ((resolve, reject) => {
        GraphRequest.all(priceRequestBody)
          .then((res) => {
            if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
              resolve(true)
              dispatch(DISPATCH_STATE.fetchPriceSuccess(res.data.data.productPriceList));
            } else {
              resolve(false)
              dispatch(DISPATCH_STATE.bookingErrorLocationPrice(res.data.errors[0].message));
            }
          })
      })
    }
  };
}

/**
 * Fetch mymall price
 * @param {*} id
 * @param {*} locations
 */
export function fetchMymallPrice(id, locations) {
  return (dispatch, getState) => {
	  if (!_.isEmpty(getState().map.googlePlaces)) {
		locations.jobLocations = [
        ...locations.jobLocations,
        ...getState().map.googlePlaces
      ]
	}
	  const dispatchedLocation = locations.jobLocations.map((location) => createPlaceObjectForFetchingPrice(location));
    if(dispatchedLocation) {
      dispatch(DISPATCH_STATE.fetchPriceBegin(locations));
      let variables = {};
        if (Number(getShopIdStorage())) {
            variables = {
                "productId": +id,
                "priceModel": {
                  "jobLocations": dispatchedLocation,
                  "exclusiveServiceProviderId": !_.isNull(getState().home.shopInfo) ? Number(getState().home.shopInfo.id) : Number(getShopIdStorage())
                },
            }
        } else {
            variables = {
                "productId": +id,
                "priceModel": {
                  "jobLocations": dispatchedLocation,
                },
            }
        }
        const priceRequestBody = {
            query: getPrice,
            variables: variables
        }
      return new Promise ((resolve, reject) => {
        GraphRequest.all(priceRequestBody)
          .then((res) => {
            if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
              	dispatch(DISPATCH_STATE.fetchPriceSuccess(res.data.data.productPriceList));
				dispatch(DISPATCH_STATE.bookingSetCurrentPrice(parseInt(res.data.data.productPriceList.minCubeNumbers)));
				resolve(true)
			} else {
              resolve(false)
              dispatch(DISPATCH_STATE.bookingErrorLocationPrice(res.data.errors[0].message));
            }
          })
      })
    }
  };
}

/**
 * Check out of geoboundary
 * @param {*} id
 * @param {*} savedPlace
 */
export function checkOutOfGeoboundary(id, savedPlace) {
  return (dispatch) => {
	  // dispatch(appLoadingBegin());
    dispatch(fetchSavedPlacesLoading(true));
	  const location = [{
		  address: savedPlace.location.address,
		  lat: savedPlace.location.lat,
		  lng: savedPlace.location.lng
	  }]
	  let variables = {
				"productId": +id,
				"priceModel": {
				  "jobLocations": location,
				},
			}
		const priceRequestBody = {
			query: getPrice,
			variables: variables
		}
      return new Promise ((resolve, reject) => {
        GraphRequest.all(priceRequestBody)
          .then((res) => {
            dispatch(fetchSavedPlacesLoading(false));
            if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
              resolve(res.data)
            } else {
              resolve(res.data)
              dispatch(DISPATCH_STATE.bookingErrorLocationPrice(res.data.errors[0].message));
            }
          })
      })
    };
}

//fetch primary payment
export const fetchPrimaryPayment = () => {
  return (dispatch, getState) => {
    dispatch(fetchPaymentMethods())
      .then((res) => {
        if (res.totalItems > 0) {
          const primaryPayment = res.items.filter(payment => payment.isPrimary === true);
          dispatch(getPrimaryPayment(primaryPayment));
        }
      })

  }
};

//fetch xendit primary payment
export const fetchXenditPrimaryPayment = () => {
  return (dispatch, getState) => {
    dispatch(fetchXenditPaymentMethods())
      .then((res) => {
        if (res.totalItems > 0) {
          const primaryPayment = res.items.filter(payment => payment.isPrimary === true);
          dispatch(getPrimaryPayment(primaryPayment));
        }
      })

  }
};

export const applyPromoCode = (promoCode) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(DISPATCH_STATE.promoCodeLoading(true));
      dispatch(DISPATCH_STATE.setPromoCode(promoCode));
      const promoCodeRequestBody = {
			query: `mutation ClientApplyVoucher($voucherData: ClientApplyVoucherInput!) {
          applyVoucher(voucherData: $voucherData) {
            succeed
            result {
              id
              type
              reason
              amountOff
              percentOff
              discountedOrderAmount
            }
          }
        }`,
			variables: {
				voucherData: {
					code: promoCode,
					productId: Number(getState().booking.product.id),
					cachedPriceId: getState().booking.prices.cachedPriceId.toString(),
				},
			},
		};

      GraphRequest.all(promoCodeRequestBody)
			.then((res) => {
				dispatch(DISPATCH_STATE.togglePromoCodeBottomSheet());
        dispatch(DISPATCH_STATE.promoCodeLoading(false));
				if (!_.isNull(res.data?.data?.applyVoucher) && res.data?.data?.applyVoucher?.succeed) {
					const { type, reason } = res.data.data.applyVoucher.result;
					dispatch(DISPATCH_STATE.resultAppliedPromoCode(res.data.data.applyVoucher.result));
					dispatch(DISPATCH_STATE.addTemporaryPromoCode(getState().booking.promoCode));
					if (!_.isNull(type)) {
						switch (type) {
							case "FIXED":
							case "AMOUNT": {
								dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
								dispatch(DISPATCH_STATE.setAmountOff(res.data.data.applyVoucher.result.amountOff));
								break;
							}
							case "PERCENT": {
								dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
								dispatch(DISPATCH_STATE.setPercentOff(res.data.data.applyVoucher.result.percentOff));
								break;
							}
							default: {
								break;
							}
						}
						toast(`${getState().booking.promoCode} applied`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					} else if (reason) {
						resolve(reason);
						dispatch(DISPATCH_STATE.setNewPrice(null));
						dispatch(DISPATCH_STATE.setAmountOff(null));
						dispatch(DISPATCH_STATE.setPercentOff(null));
						dispatch(DISPATCH_STATE.setPromoCode(null));
					}
				} else {
					dispatch(appFetchFailure(res.data?.errors[0]?.message));
					toast(`${res.data?.errors[0]?.message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
			})
			.catch((err) => {
				dispatch(DISPATCH_STATE.togglePromoCodeBottomSheet());
        		dispatch(DISPATCH_STATE.promoCodeLoading(false));
				dispatch(appFetchFailure(err?.response?.data?.errors[0]?.message));
				// toast(`${err.response.data.errors[0].message}`, {
				// 	position: "bottom-center",
				// 	autoClose: 2000,
				// 	limit: 1,
				// 	className: "toast-rejected-payment",
				// 	bodyClassName: "toastify-inner",
				// 	hideProgressBar: true,
				// 	closeOnClick: false,
				// });
			});
    })
  }
}

export const reApplyPromoCode = (promoCode) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(DISPATCH_STATE.promoCodeLoading(true));
      dispatch(DISPATCH_STATE.setPromoCode(promoCode));
      const promoCodeRequestBody = {
        query: `mutation ClientApplyVoucher($voucherData: ClientApplyVoucherInput!) {
          applyVoucher(voucherData: $voucherData) {
            succeed
            result {
              id
              type
              reason
              amountOff
              percentOff
              discountedOrderAmount
            }
          }
        }`,
        variables: {
          "voucherData": {
            "code": promoCode,
            "productId": Number(getState().booking.product.id),
            "cachedPriceId": getState().booking.prices.cachedPriceId.toString()
          }
        }
      }

      GraphRequest.all(promoCodeRequestBody)
			.then((res) => {
        dispatch(DISPATCH_STATE.promoCodeLoading(false));
				if (!_.isNull(res.data.data.applyVoucher) && res.data.data.applyVoucher.succeed) {
					const { type, reason } = res.data.data.applyVoucher.result;
					dispatch(DISPATCH_STATE.resultAppliedPromoCode(res.data.data.applyVoucher.result));
					dispatch(DISPATCH_STATE.addTemporaryPromoCode(getState().booking.promoCode));
					if (!_.isNull(type)) {
						switch (type) {
							case "AMOUNT": {
								dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
								dispatch(DISPATCH_STATE.setAmountOff(res.data.data.applyVoucher.result.amountOff));
                break;
              }
							case "PERCENT": {
								dispatch(DISPATCH_STATE.setNewPrice(res.data.data.applyVoucher.result.discountedOrderAmount));
								dispatch(DISPATCH_STATE.setPercentOff(res.data.data.applyVoucher.result.percentOff));
                break;
              }
              default: 
                break;
						}
					} else if (!reason) {
						dispatch(appFetchFailure(res.data.errors[0].message));
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
				}
			})
			.catch((err) => {
				dispatch(DISPATCH_STATE.togglePromoCodeBottomSheet());
        dispatch(DISPATCH_STATE.promoCodeLoading(false));
				dispatch(appFetchFailure(err.response.data.errors[0].message));
				toast(`${err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
    })
  }
}

export function fetchReviewMymallService(id, locations) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.fetchBookingBegin());
			if (!_.isEmpty(getState().map.googlePlaces)) {
				locations.jobLocations = [
					...locations.jobLocations,
					...getState().map.googlePlaces
				]
			}
			const dispatchedLocation = locations.jobLocations.map((location) => createPlaceObjectForFetchingPrice(location));
			if(dispatchedLocation) {
				dispatch(DISPATCH_STATE.fetchPriceBegin(locations));
				let variables = {};
				if (Number(getShopIdStorage())) {
					variables = {
						"productId": +id,
						"priceModel": {
							"jobLocations": dispatchedLocation,
							"exclusiveServiceProviderId": !_.isNull(getState().home.shopInfo) ? Number(getState().home.shopInfo.id) : Number(getShopIdStorage())
						},
					}
				} else {
					variables = {
						"productId": +id,
						"priceModel": {
							"jobLocations": dispatchedLocation,
						},
					}
				}
				const fetchMymallProductAndPriceBody = {
					query: fetchMymallProductAndPrice,
					variables: variables
				}
				return new Promise(resolve => {
					GraphRequest.all(fetchMymallProductAndPriceBody)
						.then((json) => {
							if (!_.isNull(json.data.data) && json.data.data.product) {
								dispatch(DISPATCH_STATE.fetchBookingV5Success(json.data.data.product));
								dispatch(DISPATCH_STATE.fetchPriceSuccess(json.data.data.productPriceList));
								resolve(true);
							} else {
								dispatch(appFetchFailure(json.data.errors[0].message));
								toast(`${json.data.errors[0].message}`, {
									position: "bottom-center",
									autoClose: 2000,
									limit: 1,
									className: "toast-rejected-payment",
									bodyClassName: "toastify-inner",
									hideProgressBar: true,
									closeOnClick: false,
								});
							}
						})
						.catch((error) => {
							dispatch(appFetchFailure(error));
						});
				});
			}
	};
}