import React, {Fragment} from 'react';
import ThumbsUp from "../../../../v5-icons/thumbs-up.png"
export default function JobChatHistoryThumbsUp(props) {
	const thumbsUpString = '  👍🏻';

	return (
		<Fragment>
			<div  onClick={props.thumbsUpSend} className="no-padding text-center Job-chat-history-user-actions-recorder">
				<button disabled={props.disabled}
						className="Job_Chat_History_Thumbs_Up Job-chat-history-emoji-button"
						 type="button">
					<img src={ThumbsUp} alt="ThumbsUp" />
				</button>
			</div>
		</Fragment>
	);
}