import {Howl} from 'howler';

import startTrack from '../../assests/images/chat/record_start.mp3';
import stopTrack from '../../assests/images/chat/record_stop.mp3';

// import {checkBrowser, getBrowser} from './check-browser';
// import {NAME_SAFARI_BROWSER} from '../../components/tutorial/add-to-home-screen';

export const mediaRecorder = (audioContext) => {
    return new Promise(resolve => {
        let soundStart = new Howl({
            src: [startTrack],
            preload: true, 
            format: ['mp3']
        });
        let soundStop = new Howl({
            src: [stopTrack],
            preload: true, 
            format: ['mp3']
        });
        let gumStream;
        let rec;
        let input;
        // let AudioContext = window.AudioContext || window.webkitAudioContext || false;
        // let audioContext = new AudioContext();

        let constraints = {
            audio: true,
            video: false
        }

        
        // if (!navigator.mediaDevices) {
        //     alert("This browser is not suitable for media support. For having best experience please use Safari on your phone.");
        // }
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
            gumStream = stream;
            input = audioContext.createMediaStreamSource(stream);
            rec = new window.Recorder(input, {
                numChannels: 1
            }) 

            const start = () => {
                soundStart.play();
                rec.record()
            }

            const stop = (callback) => {
                soundStop.play();
                rec.stop();
                gumStream.getAudioTracks()[0].stop();
                rec.exportWAV(callback);
            }

            const cancel = () => {
                soundStop.play();
                rec.stop();
                gumStream.getAudioTracks()[0].stop();
            }

            resolve({rec, start, stop, cancel});
        })
        .catch((err) => {
            // alert(err.message);
            alert('Your browser does not support audio recording');
            
        })        
    })
}