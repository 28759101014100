import React from "react";

import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";
import chatSupport from "../../../v5-icons/chat-support.svg";
import FreeSvg from "../../../v5-icons/free.png";

function LocationBox(props) {
	return (
		<div className="Home_Location">
			<div className="Home_Location_White_Box">
				<div className="H_L_W_B_Top_Box">
					<div onClick={props.navigateToSearchLocation} className="Location">
						<DynamicIcon icon="LocationPin" classes="Location_Pin" />
						<div className="H_L_W_B_Top_Text">
							<h5>{props.locationTitle}</h5>
							<DynamicIcon icon="BlueForward" classes="Right_Arrow" />
						</div>
						{/* <div className="H_L_W_B_Top_Right_Arrow_Location">
						</div> */}
					</div>
				</div>
				<div className="H_L_W_B_Down_Box" onClick={props.onSearchPage}>
					<DynamicIcon icon="BlueSearch" classes="Location_Search" />
					<div className="H_L_W_B_Top_Text">
						<h5>What do you need today?</h5>
					</div>
				</div>
			</div>
			<div className="free-concierge-container" onClick={props.handleClickOnChatSupport}>
				{/*<img src={FreeSvg} alt="free" className="free-icon" />*/}
				<img src={chatSupport} className="support-icon" alt="chatSupport" />
				<p>Support</p>
			</div>
		</div>
	);
}

export default LocationBox;
