import React from "react";
import JobReceiptRow from "../JobReceiptRow/JobReceiptRow";
import _ from "lodash";
import Moment from "../../../moment/moment";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

const JobDetailSection = (props) => {

	return (
		<div className="job-detail-section">
			{props.job && !_.isNull(props.job) && (
				<div className="job-receipt-keys-values">
					<JobReceiptRow title="Booking ID" value={props.job.bookingId} />
					<JobReceiptRow title="Customer" value={props.client.givenName + " " + props.client.lastName} />
					{!_.isNull(props.job.partner) ? (
						<JobReceiptRow title="Partner" value={props.job.partner.givenName + " " + props.job.partner.lastName} />
					) : null}
					{_.has(props.job, "startDateTime") ? (
						<JobReceiptRow title="Start time" value={<Moment format="DD MMM YYYY h:mm A">{props.job.startDateTime}</Moment>} />
					) : null}
					{_.has(props.job, "completeDateTime") ? (
						<JobReceiptRow title="End time" value={<Moment format="DD MMM YYYY h:mm A">{props.job.completeDateTime}</Moment>} />
					) : null}
					<div className="Job-receipt-row location-job-receipt">
						<div className="Job-receipt-title">{props.job.jobLocations.length > 1 ? "Job locations" : "Job location"}</div>

						{props.job.jobLocations.map((jobLoc, index) => {
							return (
								<div className="location-job-receipt-inner" key={index}>
									<DynamicIcon icon="LocationPin" classes="Location_Pin_Receipt" />
									<span className="Job_Receipt_Location_Address">{jobLoc.address} </span>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};
export default JobDetailSection;