import React from "react"
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import { useHistory } from "react-router-dom";

import homeTab from "../../assests/icons/home-tab.svg";
import jobsTab from "../../assests/icons/jobs-tab.svg";
import accountTab from "../../assests/icons/account-tab.svg";

const classNames = require("classnames");

const tabs = [{
	route: "/home",
	icon: homeTab,
	label: "Home"
},{
	route: "/jobs",
	icon: jobsTab,
	label: "My Jobs"
},{
	route: "/account",
	icon: accountTab,
	label: "Account"
}]

export const Tabbar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const onToggleTab = (navigationPath) => {
		dispatch(push(navigationPath));
	}

	return (
		<div className={classNames(
			"tabbar-wrapper",
			history.location.pathname === "/home" ? "tabbar-fixed" : "tabbar-absolute"
		)}>
			<div className="tabbar-container" role="tablist">
				{
					tabs.map((tab, index) => (
						<button key={`tab-${index}`} className="tabbar-btn" onClick={() => onToggleTab(tab.route)}>
							<img src={tab.icon} alt="" className={classNames({
								"tabbar-img-selected": history.location.pathname === tab.route
							})} />
							<span className="tabbar-text" role="tab" aria-selected={history.location.pathname === tab.route}>
								{tab.label}
							</span>
						</button>
					))
				}
			</div>
		</div>
	)
}