import React, { Component } from "react";
import _ from "lodash";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import * as LOG_EVENT from "../../../utils/analytics/index";

import SelectCitySearchInput from "./SelectCitySearchInput";
import SearchCityResult from "./SearchCityResult";
import DarkLoading from "../../ui-elements/Loading/DarkLoading";

import { setLivingCity, toggleSelectCityModal } from "../../../utils/redux/actions/registerActions/registerChangeState";
import { setCitiesData } from "../../../utils/redux/actions/registerActions";
import { initUserLocationDetail } from "../../../utils/redux/actions/mapActions/mapChangeState";
import { locationStorageSetData, localStorageSetIsFirstRequest,localStorageIsTwa } from "../../../utils/functions/local-storage";
class SelectCity extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			searchResult: {}, 
			highLightText: []
		}
	}

	componentDidMount() {
		this.props.dispatch(setCitiesData())
			.then((res) => {
				this.setState(() => ({ searchResult: this.sortCities(res) }));
			})
	}

	sortCities (array) {
		return array.sort(function (a, b) {
			var nameA = a.name.toLowerCase(),
				nameB = b.name.toLowerCase();
			if (nameA < nameB)
				//sort string ascending
				return -1;
			if (nameA > nameB) return 1;
			return 0; //default return value (no sorting)
		});
	}

	onChangeSearchInput = (event) => {
		let highLightObj = [];
		if(event.length) highLightObj.push(event);
		
		this.setState({ highLightText: highLightObj });
		this.setState({ firstSearch: true });
		let filteredCities = [];

		if (event.length === 0) {
			this.setState({ firstSearch: false });
			this.setState(() => ({ searchResult: this.props.register.cities }));
		} else {
			let val = event.toLowerCase();
			filteredCities = this.props.register.cities.filter((city) => city.name.toLowerCase().includes(val));
			filteredCities.map((filteredCity) => (filteredCity.searchedText = val));
			this.setState(() => ({ searchResult: filteredCities }));
		}
	};

	onClickSearchResult = (e, city) => {
		delete city["searchedText"];
		this.props.dispatch(setLivingCity(city));
		this.props.dispatch(toggleSelectCityModal());
		const location = {
			lat: Number(city.lat),
			lng: Number(city.lng),
			address: city.name,
			title: city.name,
			defaultLat: 14.5995,
			defaultLng: 120.9842,
		};
		this.props.dispatch(initUserLocationDetail(location));
		localStorageSetIsFirstRequest();
		locationStorageSetData(location);
		LOG_EVENT.logEvent(LOG_EVENT.INITIAL_LOCATION_PICKED_CWA, { 
			location: "cityChosen", 
			os: localStorageIsTwa() ? "twa" : "web",
			autoGenerated: false
		 });
		this.props.dispatch(push("/home"));
	};

	render() {
		return (
			<div className="select-city-container">
				{
					this.props.register.fetchCitiesLoading ? (
						<div className="select-city-loading">
							<DarkLoading />
						</div>
					) : (
						<>
							<SelectCitySearchInput
								onChangeSearchInput={this.onChangeSearchInput}
								isFocus={this.state.isFocus}
								closeSelectCity={() => this.props.dispatch(toggleSelectCityModal())}
							/>

							<div className="cities-container">
								{this.state.searchResult.length > 0 ? (
									this.state.searchResult.map((city) => (
										<SearchCityResult
											key={city.id}
											city={city}
											classes="city-item"
											highLightText={this.state.highLightText}
											onClickSearchResult={this.onClickSearchResult}
										/>
									))
								) : (
									<p>No city found</p>
								)}
							</div>
						</>
					)}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	...state.home,
	register: state.register,
});
export default connect(mapStateToProps)(SelectCity);