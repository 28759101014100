export const getHomeObject = `query HomeRequestBody($lat: Float!, $lng: Float!,$isWhatsNew: Boolean) {
  
  productCatalog(lat: $lat, lng: $lng) {
    title
    subCategories {
      id
      title
      showInHome
      icon
      products {
        id
        commercialName
        icon
        mode
      }
    }
  }   

  activeJobs: requests(requestType: Active) {
    totalItems
    items {
        id
        product {
            commercialName
            icon
            mode
        }
        partner {
            givenName
            lastName
            gender
            title
            coPartner {
              displayValue
            }
            photo
        }
        startDateTime
        jobTime {
            plate {
                value
                unit
                displayUnit
                displayValue
            }
            cube {
                value
                unit
                displayUnit
                displayValue
            }
            total {
                value
                unit
                displayUnit
                displayValue
            }
            cubeNumbers
        }
        state
    }
  }

  unratedJobs: requests(requestType: Unrated) {
    totalItems
    items {
      id
      bookingId
      dateTime
      startDateTime
      completeDateTime
      payments {
        unit
        isPaid
        card {
          type
          icon
          name
          cardNumber
        }
        cardUsage
        mykoinUsage
      }
      jobCost {
          currency
          serviceFee
          discount
          discountedServiceFee
          extendFee
          clientTransferMykoin
          value
          transferFee
      }
      product {
        commercialName
        id
      }
      jobLocations {
        address
        name
      }
      partner {
        givenName
        lastName
        photo
        gender
        title
        coPartner {
          displayValue
        }
      }
    }
  }

  announcements(isWhatsNew: $isWhatsNew) {
    id
    title
    thumbnailImage
    body
  }
}`;

export const getHomeObjectWithoutAuth = `query HomeRequestBodyWithoutAuth($lat: Float!, $lng: Float!,$isWhatsNew: Boolean) {
  productCatalog(lat: $lat, lng: $lng) {
    title
    subCategories {
      id
      title
      showInHome
      icon
      products {
        id
        commercialName
        icon
        mode
      }
    }
  }   
        
  announcements(isWhatsNew: $isWhatsNew) {
    id
    title
    thumbnailImage
    body
  }
  
  countryCodes {
    code
    icon
  }
}`;