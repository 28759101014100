import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import * as moment from 'moment-timezone';
import _ from "lodash";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";

import staticMap from "../../../assests/images/home/Map.png";
import { ReactComponent as LocationPin } from "../../../v5-icons/pin-map.svg";

import Lozenge from "../../ui-elements/lozenge/lozenge";
import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";
import genderPatner from "../../../utils/functions/gender-partner";

import { navigateOnClickJobInJobsList, setJobsLocationPointer } from "../../../utils/redux/actions/jobsActions";
import { selectJobsFromJobs } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
class HomeActiveJob extends Component {

	onActiveJob = (e, activeJobs) => {
		this.props.dispatch(setJobsLocationPointer());
		this.props.dispatch(selectJobsFromJobs(activeJobs));
		this.props.dispatch(navigateOnClickJobInJobsList(activeJobs));
	};

	render() {
		let activeJobs = "";
		if (this.props.activeJobs.totalItems > 0) {
			if ("items" in this.props.activeJobs && this.props.activeJobs.items.length !== 0) {
				activeJobs = this.props.activeJobs.items.map((activeJobs, index) => {
					let color = "";
					let restTimeOfJob = 0;
					let restTimeOfJobH = 0;
					let restTimeOfJobM = 0;
					let format = "DD MMMM YYYY, h:mmA";
					/*calculate the rest time of job after Started partner with partner*/
					if (activeJobs.state === "Started" && activeJobs.startDateTime) {
						let format = "DD MMMM YYYY, h:mmA";
						let startedTime = moment(activeJobs.startDateTime);
						let nowTime = moment(new Date());
						let durationStarted = Math.floor(moment.duration(nowTime.diff(startedTime)).asMinutes());
						restTimeOfJob = activeJobs.jobTime.total.value - durationStarted;
						restTimeOfJobH = Math.floor(restTimeOfJob / 60);
						restTimeOfJobM = restTimeOfJob % 60;
					}
					switch (activeJobs.state) {
						case "Matched":
							color = "orange";
							break;
						case "In Progress":
							color = "green";
							break;
						case "Not Much Time":
							color = "#E34B34";
							break;
						case "Started":
							color = "green";
							break;
						case "Pending":
							color = "yellow";
							break;
						case "Matching":
							color = "yellow";
							break;
						case "Reviewing":
							color = "purple";
							break;
					}
					return (
						<div key={index} onClick={(e) => this.onActiveJob(e, activeJobs)} className="Active_Jobs_Slider_Box">
							<div className="Home_Active_Job_Container">
								<div className="H_A_J_C_Map">
									<img className="Map_Img" src={staticMap} alt="" />
									<LocationPin className="location-icon" />
								</div>
								<div className="H_A_J_C_Content">
									<div className="H_A_J_C_C_State">
										<Lozenge
											title={activeJobs.state === "Started" ? "IN PROGRESS" : activeJobs.state === "Queued" &&
												activeJobs.product.mode.includes("MY_MALL") ? "MATCHED" : activeJobs.state.toUpperCase()}
											color={activeJobs.state === "Started" ? "green" : "orange"}
										/>
										<h5 className="H_A_J_C_C_S_Child_Service">{activeJobs.product.commercialName}</h5>
									</div>
									{!_.isNull(activeJobs.partner) ? (
										<div className="H_A_J_C_C_Row">
											<DynamicIcon icon="BluePerson" classes="Blue_Person" />
											<div className="H_A_J_C_C_R_Text">
												<h5>
													{activeJobs.partner.title ? activeJobs.partner.title : genderPatner(activeJobs.partner)} {activeJobs.partner.givenName} {activeJobs.partner.lastName}
												</h5>
											</div>
										</div>
									) : null}
									{activeJobs.state === "Started" ? (
										<div className="H_A_J_C_C_Row">
											<DynamicIcon icon="SandWatch" classes="Blue_Person" />
											<div className="H_A_J_C_C_R_Text">
												<h5
													className={`${
														restTimeOfJobM > 10 || restTimeOfJobH >= 0 ? " Time_Left_Black" : " Time_Left_Red"
													}`}
												>
													{restTimeOfJobH > 0 ? <span>{restTimeOfJobH} hr </span> : null}
													<span>{restTimeOfJobM > 0 ? restTimeOfJobM : 0} mins left</span>
												</h5>
											</div>
										</div>
									) : (
										<div className="H_A_J_C_C_Row">
											<DynamicIcon icon="SandWatch" classes="Blue_Person" />
											<div className="H_A_J_C_C_R_Text">
												<h5>Not started yet</h5>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				});
			}
		}
		return (
			<Fragment>
				<div className="Active_Jobs">
					<div className="Active_Jobs_Title">{activeJobs.length !== 0 ? <h1>Active Jobs</h1> : null}</div>
					<div
						// className="Active_Jobs_Scroll"
						className={`${isMobile ? "Active_Jobs_Scroll_Mobile" : "Active_Jobs_Scroll_Desktop"}`}
					>
						{isMobile ? activeJobs : <PerfectScrollbar>{activeJobs}</PerfectScrollbar>}
					</div>
					{activeJobs.length !== 0 ? (
						<h5 className="Active_Job_Link" onClick={this.props.onNavigateJobs}>
							See all your jobs
						</h5>
					) : null}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.home,
	common: state.common,
});

export default connect(mapStateToProps)(HomeActiveJob);
