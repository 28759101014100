import React, {Fragment} from 'react';

import './header-back.css';
// import backImg from '../../assests/icons/back-button.png';
import BackBlackIcon from '../icons/back-black-icon';

export default function HeaderBack(props) {

    return (
        <Fragment>
            <div className="col-xs-12 Header-back no-padding">
                <div className="Header-back-img">
                    <BackBlackIcon onClick={props.onBack} />
                    <p>{props.title}</p>
                </div>
            </div>
        </Fragment>
    );
}