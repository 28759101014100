import React, { Fragment } from 'react';

import './news-empty.css';
import { ReactComponent as EmptySvg } from '../../../assests/images/news/empty-news.svg';

export default function NewsEmpty(props) {

    return (
        <Fragment>
            <div className="col-xs-12 News-empty text-center m-t-50 no-padding">
                <div className="col-xs-12 no-padding">
                    <EmptySvg className="Jobs-empty-svg"/>
                </div>
                <div className="col-xs-12 no-padding">
                    <p className="jobs-empty-memories">Stay in touch with MyKuya!</p>
                </div>
                <div className="col-xs-12 no-padding m-t-10">
                    <p className="Jobs-empty-pro">News, features and updates will be shared</p>
                    <p className="Jobs-empty-pro">with you here!</p>
                </div>
            </div>
        </Fragment>
    );
}