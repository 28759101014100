import React from 'react';
import { connect } from 'react-redux';
import CustomButton from "../ui-elements/CustomButton/CustomButton";
import { closeGcashFailedBottomSheet } from "../../utils/redux/actions/commonActions/commonChangeState";
import WhiteLoading from "../ui-elements/Loading/WhiteLoading";

class OutOfGeoBottomSheet extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isShow: false,
			timeOut: null,
		};
	}

	onGoToGcash = () => {
		if (this.props.billing.gcashInfo) {
			this.props.billing.gcashInfo.submit();
		}
	}

	onCancel = () => {
		this.props.dispatch(closeGcashFailedBottomSheet());
	}

	render() {
		return (
			<div className="rejected-payment-container">
				<div className="rejected-payment-header">
					<h2 style={{color: "#FC6A54"}}>Adding GCash failed</h2>
					<h6>Please check the details of your account and try again. You may contact GCash support if the error persists </h6>
				</div>
				<div className="rejected-payment-bottom">
					{/*<CustomButton*/}
					{/*	classList="change-payment-button"*/}
					{/*	title="Try Again"*/}
					{/*	onClick={this.onGoToGcash}*/}
					{/*/>*/}
					<button onClick={this.onGoToGcash} className="adyen-checkout__button adyen-checkout__button--pay" type="button">
							<span className="adyen-checkout__button__content">
								{this.props.billing.gcashLoading ? (
									<div className="gcash-button-loading">
										<WhiteLoading />
									</div>
								) : (
									<span className="adyen-checkout__button__text">Try Again</span>
								)}
							</span>
					</button>
					<CustomButton
						classList="gcash-cancel-button"
						outline={true}
						variant="outlined"
						title="Cancel"
						onClick={this.onCancel}
					/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	billing: state.billing,
	common: state.common,
});

export default connect(mapStateToProps)(OutOfGeoBottomSheet);
