import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";

import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import {
	returnErrorFromAdyen,
	toggleQrCodeBottomSheet,
} from "../../utils/redux/actions/commonActions/commonChangeState";
import { fetchClient, setLimitationTopup } from "../../utils/redux/actions/commonActions";
import {
	toggleTopUpBottomSheet,
	togglePaymentMethodsBottomSheet,
	clientRejectedPayment,
	setTopUpAmount,
} from "../../utils/redux/actions/bookingActions/bookingChangeState";
import { getClientUnitCurrency } from "../../utils/functions/local-storage";

import TitleBar from "../../organisms/title-bar";
import WhiteLoading from "../ui-elements/Loading/WhiteLoading";

import backButton from "../../v5-icons/back-button.svg";
import chatSupport from "../../v5-icons/chat-support.svg";
import { ReactComponent as DisableLock } from "../../v5-icons/disable-lock.svg";
import { ReactComponent as Lock } from "../../v5-icons/lock.svg";
import QRCodeIcon from "../../v5-icons/qr-code-sharp.svg"

class TopUpBottomSheet extends Component {
	inputAmountRef = React.createRef();
    state = {
        isDisableButton: true,
        amount: null
    }
    componentDidMount() {
		this.setState(() => ({ amount: this.props.topUpMyKoinAmount }));
		if (this.props.common.authCheck && this.props.common.auth) {
			if (getClientUnitCurrency()) {
				this.props.dispatch(setLimitationTopup(getClientUnitCurrency()));
			} else {
				this.props.dispatch(fetchClient()).then((res) => this.props.dispatch(setLimitationTopup(res.currency.unit)));
			}
		}
    }
	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};
	handleChange = (e) => {
		if (!this.inputAmountRef.current.value.length) {
			this.setState(() => ({
				isDisableButton: true,
                amount: null
            }));
            this.props.dispatch(setTopUpAmount(null));
        } else if (this.inputAmountRef.current.value === "0" || this.inputAmountRef.current.value.charAt(0) === "0") {
			this.setState(() => ({ isDisableButton: true }));
		} else {
			if (Number(this.inputAmountRef.current.value) >= this.props.common.topupLimitation) {
				this.setState(() => ({
					amount: this.inputAmountRef.current.value,
					isDisableButton: false,
				}));
            	this.props.dispatch(setTopUpAmount(this.inputAmountRef.current.value));
			} else {
				this.setState(() => ({
					amount: this.inputAmountRef.current.value,
					isDisableButton: true,
				}));
			}
		}
		if (!_.isNull(this.props.common.errorMessageAdyen)) {
			this.props.dispatch(returnErrorFromAdyen(null));
		}
    };
	getAmountValue = (e, amount) => {
        this.inputAmountRef.current.value = amount;

        this.setState(() => ({ 
            isDisableButton: false,
            amount: amount
		}));
		if (!_.isNull(this.props.common.errorMessageAdyen)) {
			this.props.dispatch(returnErrorFromAdyen(null));
		}
        this.props.dispatch(setTopUpAmount(amount));
	};
	render() {
		return (
			<Fragment>
				<TitleBar
					leftIcon={backButton}
					rightIcon={chatSupport}
					handleClickLeftIcon={() => {
						this.props.dispatch(toggleTopUpBottomSheet());
						this.props.dispatch(clientRejectedPayment());
						this.props.dispatch(togglePaymentMethodsBottomSheet());
					}}
					title="Top-Up mykoins"
					handleClickRightIcon={this.onSupport}
				/>
				<div className="top-up-mykoin-container">
					<div className="topup-form">
						<label>Top-up Amount ({!_.isEmpty(this.props.common.client) && this.props.common.client.currency.unit})</label>
						<div className="input-container">
							{!_.isEmpty(this.props.common.client) ? (
								<>
									<input
										className=""
										name="amount"
										onChange={this.handleChange}
										placeholder={`Minimum amount is ${this.props.common.client.currency.signText}${this.props.common.topupLimitation}`}
										ref={this.inputAmountRef}
									/>
								</>
							) : null}
						</div>
					</div>
					<span className="topup-info">
						Visa & Mastercard top-ups are processed instantly. For GCash & Maya, it may take up to 3 minutes to receive your payment.
						We will send you a notification when it's done!
					</span>
					{!_.isNull(this.props.common.errorMessageAdyen) && <span className="error">{this.props.common.errorMessageAdyen}</span>}
					<div className="default-amount-container">
						{!_.isEmpty(this.props.common.client) && !_.isNull(this.props.common.predefinedTopupValues)
							? this.props.common.predefinedTopupValues.map((value, idx) => (
									<div key={idx} className="default-amount" onClick={(e) => this.getAmountValue(e, value)}>
										<img src={this.props.common.client.currency.signSvg} />
										<p>{value}</p>
									</div>
							  ))
							: null}
					</div>
					<div className="button-container">
						{!_.isNull(this.props.topUpMyKoinAmount) ? (
							<div className="add-mykoins-btn">
								<Button variant="contained" disabled={this.state.isDisableButton} onClick={this.props.setTopUpValue}>
									{this.props.billing.topUpLoading ? (
										<div className="topup-button-loading">
											<WhiteLoading />
										</div>
									) : (
										<>
											<Lock className="white-lock" />
											<span>
										Add {this.props.common.client.currency.signText} {this.props.topUpMyKoinAmount} To mykoins
									</span>
										</>
									)}
								</Button>
								<img onClick={() => this.props.dispatch(toggleQrCodeBottomSheet(true))}
									 className="qr-code-btn" src={QRCodeIcon} />
							</div>
						) : (
							<div className="add-mykoins-btn">
								<Button disabled={this.state.isDisableButton}>
									<DisableLock />
									Add mykoins
								</Button>
								<img onClick={() => this.props.dispatch(toggleQrCodeBottomSheet(true))}
									 className="qr-code-btn" src={QRCodeIcon} />
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
	common: state.common,
	billing: state.billing,
});

export default connect(mapStateToProps)(TopUpBottomSheet);
