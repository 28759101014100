import _ from 'lodash';
import $ from 'jquery';
import {push} from "connected-react-router";

import * as DISPATCH_STATE from '../mapChangeState';
import * as CONSTANT_ACTIONS from '../mapConstant';
import { appGoBack } from '../../commonActions';
import { getExactAddress, getGeoCodePlaces } from "../../../../functions/nearby-search";
import {
	addUserLocationToPlace, createPlaceGoogleSuggestionsFromGeoCode,
	createPlaceObjectFromGoogleGetExactAddressFromRoute, separateCityName,
} from "../../../../functions/create-place-object-from-google-suggestions";
import { savedPlaceSelectedAddress } from '../../savedPlacesActions';
import { savedPlaceUnSelectMap } from "../../savedPlacesActions/savedPlacesChangeState";
import { mapSetCurrentLocationLocalstorage } from './map-current-location';
import { createMarkerPartnerObject } from '../../../../functions/create-marker';
import { calculateGoogleMapOffsetRippleEffect } from '../../../../functions/calculate-google-map-offset';
import { JOB_MATCHING_CIRCLE_RIPPLE } from '../../jobsActions/jobsConstant';
import { closeLocationSearchBottomSheet, enableAddNextStop } from "../../bookingActions/bookingChangeState";
import { createUpdatedSavedPlaceObject } from "../../../../functions/create-place-object-from-google-suggestions";

import kuyaPartner from '../../../../../v5-icons/partner-avatar.png';
import PinSvg from '../../../../../assests/images/jobs/end-resized.png';
import MymallPinSvg from '../../../../../assests/images/jobs/mymallPin@2x.png';
import * as LOG_EVENT from '../../../../analytics';
import * as constant from "../../../../constants/constant";
import {
	setDeliveringToPlaceInfo,
	setHomePickedLocation,
	setLastUsed,
} from "../../savedPlacesActions/actions/savedPlaces-select";
import { setEventForLocationConfirmedSavedPlaces, setEventForLocationFromMapConfirmed } from "../../savedPlacesActions";
import { changeCurrentLocation, setGooglePlaceId, setGooglePlaceObject } from "../mapChangeState";
import { fetchHome } from "../../homeActions";
import { homeProductCheckExist } from "../../homeActions/homeChangeState";
/**
 * When user confirm marker on map we create marker on specific location on map
 */
export function createMarker(params = {}, isSearching, searchPointer) {
	return (dispatch, getState) => {
		let location = {
			lat: getState().map.googleMap.getCenter().lat(),
			lng: getState().map.googleMap.getCenter().lng(),
		};
		dispatch(DISPATCH_STATE.mapCreateMarker());
		dispatch(DISPATCH_STATE.initializeMapHide());
		dispatch(createMarkerSetLocationPlaces(location, params, isSearching, searchPointer));
	};
}

export function setLocation(params = {}, isSearching, searchPointer) {
	return (dispatch, getState) => {
		let location = {
			lat: getState().map.googleMap.getCenter().lat(),
			lng: getState().map.googleMap.getCenter().lng(),
		};
		dispatch(DISPATCH_STATE.mapCreateMarker());
		dispatch(DISPATCH_STATE.initializeMapHide());
		dispatch(createMarkerSetLocationPlacesWithMap(location, params, isSearching, searchPointer));
	};
}

/**
 * When user show location on map by confirm marker we try to find nearest location to selected location by user
 * @param {*} location 
 */
export function createMarkerSetLocationPlaces(location, params, isSearching, searchPointer) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapCreateMarkerSetLocationPlaces());
		dispatch(fetchSearchPlacesGoogleByMarkerLocation(location, params, isSearching, searchPointer));
	};
}

export function createMarkerSetLocationPlacesWithMap(location, params, isSearching, searchPointer) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapCreateMarkerSetLocationPlaces());
		dispatch(fetchSearchPlacesGoogleByMarkerLocationWithMap(location, params, isSearching, searchPointer));
	};
}
export function fetchSearchPlacesGoogleByMarkerLocationWithMap(location, params, isSearching, searchPointer) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapSearchPlacesFetchByMarkerLocation());

		getExactAddress(location, getState, (results, status) => {
			let place = {}
			if (getState().map.googlePlaceId) {
				place = createPlaceGoogleSuggestionsFromGeoCode(results);
			} else {
				place = createPlaceObjectFromGoogleGetExactAddressFromRoute(
					results,
					location,
					CONSTANT_ACTIONS.GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY,
				);
			}

			dispatch(setGooglePlaceId(null));

			if (!place.title && !place.address && getState().map.googlePlaceId) {
				getGeoCodePlaces(getState().map.googlePlaceId, (result) => {
					place.title = result.address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name;
					place.address = result.formatted_address;
				});
			}

			dispatch(setDeliveringToPlaceInfo({ location: place }));
			// dispatch(setLastUsed({location: place }));

			dispatch(
				setEventForLocationFromMapConfirmed({
					pathname: searchPointer,
					fromSearch: isSearching,
					productId:
						!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product) ? getState().booking.product.id : null,
				}),
			);

			if (_.isEmpty(params)) {
				dispatch(homeProductCheckExist(false));
				dispatch(mapSetPlaceGoogleSource(place));
				dispatch(setGooglePlaceObject(place))
				dispatch(fetchHome("home"))
				dispatch(push("/home"));
			}
		});
	};
}
/**
 * Fetch google places for when set merker with map and get first place for put in location source or destination
 * @param {*} location 
 */
export function fetchSearchPlacesGoogleByMarkerLocation(location, params, isSearching, searchPointer) {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapSearchPlacesFetchByMarkerLocation());
		getExactAddress(location, getState, (results, status) => {
			let place = {}
			if (getState().map.googlePlaceId) {
				place = createPlaceGoogleSuggestionsFromGeoCode(results);
			} else {
				place = createPlaceObjectFromGoogleGetExactAddressFromRoute(
					results,
					location,
					CONSTANT_ACTIONS.GOOGLE_PLACES_GET_ADDRESS_ACCEPT_INDEX_OF_ARRAY,
				);
			}

			dispatch(setGooglePlaceId(null));

			if (getState().savedPlaces.savedMap) {
				dispatch(savedPlaceUnSelectMap());
				if (!_.isNull(getState().savedPlaces.editLocationSavedPlace)) {
					dispatch(
						setEventForLocationConfirmedSavedPlaces({
							isSearching: isSearching,
							isEdited: true,
							productId:
								!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product)
									? getState().booking.product.id
									: null,
						}),
					);
					dispatch(savedPlaceSelectedAddress(createUpdatedSavedPlaceObject(place, getState().savedPlaces.editLocationSavedPlace)));
					dispatch(setGooglePlaceObject(createUpdatedSavedPlaceObject(place, getState().savedPlaces.editLocationSavedPlace).location));
					dispatch(push("/saved-places/search/edit"));
				} else {
					dispatch(
						setEventForLocationConfirmedSavedPlaces({
							isSearching: isSearching,
							isEdited: false,
							productId:
								!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product)
									? getState().booking.product.id
									: null,
						}),
					);

					dispatch(savedPlaceSelectedAddress(place));
					dispatch(setGooglePlaceObject(place));
					dispatch(push("/saved-places/search/add"));
				}
			} else if (_.isEmpty(params)) {
				dispatch(mapSetPlaceGoogleSource(place));
				dispatch(appGoBack());
			} else if (_.has(params, "id")) {
				//add destination from map during the booking
				const { currentRefName } = getState().search;

				dispatch(
					setEventForLocationFromMapConfirmed({
						pathname: searchPointer,
						fromSearch: isSearching,
						productId:
							!_.isNull(getState().booking.product) || !_.isEmpty(getState().booking.product) ? getState().booking.product.id : null,
					}),
				);
				switch (currentRefName) {
					case constant.LOCATION_INPUT_BOX_NAME_SOURCE: {
						dispatch(mapSetPlaceGoogleSourceFromBookingPage(place));
						dispatch(enableAddNextStop());
						localStorage.removeItem("isFirstRequest");
						break;
					}
					case constant.LOCATION_INPUT_BOX_NAME_DESTINATION: {
						if (_.isEmpty(getState().map.googlePlaces)) {
							dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination([place]));
							dispatch(enableAddNextStop());
							localStorage.removeItem("isFirstRequest");
						} else {
							const attachedPlaces = [...getState().map.googlePlaces, place];
							dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(attachedPlaces));
						}
						break;
					}
					case constant.LOCATION_INPUT_BOX_NAME_SECOND_SOURCE: {
						if (_.isEmpty(getState().map.googlePlaces)) {
							dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination([place]));
							dispatch(enableAddNextStop());
							localStorage.removeItem("isFirstRequest");
						} else {
							const attachedPlaces = [...getState().map.googlePlaces, place];
							dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(attachedPlaces));
						}
						break;
					}
					default: {
						break;
					}
				}

				dispatch(appGoBack());
			} else if (_.has(params, "index")) {
				let index = parseInt(params["index"]);
				if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
					dispatch(mapSetPlaceGoogleSource(place));
					dispatch(appGoBack());
				} else if (index >= CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
					let places = getState().map.googlePlaces;
					places[index - 1] = place;
					dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
					dispatch(appGoBack());
				} else if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
					let places = getState().map.googlePlaces;
					places.push(place);
					dispatch(appGoBack(getState().router.location.pathname.replace("/-1/map", "")));
					dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
				}
			}
		});
	};
}

export const mapSetPlaceGoogleSource = (place) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapGetCurrentLocationSet(addUserLocationToPlace(getState, place)));
        dispatch(mapSetCurrentLocationLocalstorage(place))
        dispatch({
            type: CONSTANT_ACTIONS.MAP_SET_PLACE_GOOGLE_SOURCE
        });
    }
}

export const mapSetPlaceGoogleSourceFromBookingPage = (place) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.mapGetCurrentLocationSet(addUserLocationToPlace(getState, place)));
		dispatch({
			type: CONSTANT_ACTIONS.MAP_SET_PLACE_GOOGLE_SOURCE,
		});
	};
};

export function removeMarkerForSelectingLocation() {
    return (dispatch, getState) => {
        if (!_.isEmpty(getState().map.googleMarker)) {
            getState().map.googleMarker.setMap(null);
        }
        if (!_.isNull(getState().map.googlePartnerMarkers)) {
            getState().map.googlePartnerMarkers.map((marker) => {
                marker.setMap(null);
                return marker;
            })
        }
        dispatch(DISPATCH_STATE.mapRemoveMarkerForSelectingLocation());
    }
}

export function returnCreatedMarkers(location) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if(!_.isEmpty(getState().jobs.job)) {
                const markers = createMarkerPartnerObject(getState, location, kuyaPartner, PinSvg);
                resolve(markers);
            }
        })
    }
}

/**
 * Put marker for location of partner in live mode
 */
export function createMarkerPartnerLocation(location) {
    return (dispatch, getState) => {
        if ((_.isNull(getState().map.googlePartnerMarkers) || !isPartnerLocationAdded(location, getState().map.googlePartnerMarkers))
			&& !_.isEmpty(getState().map.googleMap)) {
            dispatch(returnCreatedMarkers(location))
                .then((res) => {
                    dispatch(DISPATCH_STATE.mapCreateMarkerPartner(res));
                    dispatch(fitBoundGoogleMapMarkers(res));
                })
        } else {
            if(!_.isNull(getState().map.googlePartnerMarkers)) {
                if(location.lat !== getState().map.googlePartnerMarkers[0].getPosition().lat()) {
                    getState().map.googlePartnerMarkers[0].setPosition(location);
                    dispatch(fitBoundGoogleMapMarkers(getState().map.googlePartnerMarkers));
                }
            }
        }
    }
}

function isPartnerLocationAdded(location, markerArray) {
	return markerArray.some((element) => element.getPosition().lat() === location.lat &&
		element.getPosition().lng() === location.lng);
}

export function createMarkerWithoutPartnerLocation() {
    return (dispatch, getState) => {
		let markersArray = [];
		let map = getState().map.googleMap;
		
		getState().jobs.job.jobLocations.map((location, index) => {
			let marker = new window.google.maps.Marker({
				position: location,
				map,
				icon: {
					url: index === 0 ? MymallPinSvg : PinSvg
				},
				label: {text: String(index + 1), color: 'white', fontWeight: 'bold'}
			})
			markersArray.push(marker);
			return marker;
		});
		dispatch(DISPATCH_STATE.mapCreateMarkerPartner(markersArray));
		dispatch(fitBoundGoogleMapMarkers(markersArray));
	}
}

export const fitBoundGoogleMapMarkers = (markers, zoomLevel = null) => {
    return (dispatch, getState) => {
        let bounds = new window.google.maps.LatLngBounds();
		if (markers.length > 0) {
			for (let i = 0; i < markers.length; i++) {
				let latlng = getState().map.googlePartnerMarkers[i].getPosition();
				bounds.extend(latlng);
			}
            getState().map.googleMap.fitBounds(bounds);
			if (zoomLevel) {
				getState().map.googleMap.setZoom(zoomLevel);
			}
			setTimeout(function () {
                getState().map.googleMap.setCenter(bounds.getCenter());
			}, 1500);

		}
    }
}

/**
 * When user allows to get current location from browser api we show marker for showing current location exactly
 * @param {*} position 
 */
export function createCurrentLocationMarkerForMatching() {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.mapIntervalMarkerMatching(setInterval(() => {
            if (!_.isEmpty(getState().map.googleMap)) {
                let rippleCss = calculateGoogleMapOffsetRippleEffect();
                rippleCss['display'] = 'block';
                let location = getState().map.location;
                // location.lat -= 0.00200;
                getState().map.googleMap.panTo({lat: location.lat - 0.00200, lng: location.lng});
                $(JOB_MATCHING_CIRCLE_RIPPLE).css(rippleCss);       
                // dispatch(DISPATCH_STATE.mapCreateCurrentMarker( createMarkerObject(getState, getState().map.location, CurrentHolderImg) ));
                getState().map.googleMap.setOptions({
                    draggable: false,
                    zoomControl: false,
                    scrollwheel: false,
                    disableDoubleClickZoom: true
                });
                dispatch(DISPATCH_STATE.mapClearIntervalMarkerMatching());
            }
        }, 300)));
    }
}