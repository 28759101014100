import React, { Fragment, Component } from "react";
import { connect } from "react-redux";

import JobsBox from "../JobsBox/JobsBox";

class OpenJobs extends Component {

	render() {
		const {matching, scheduled, queued} = this.props.data;
		return (
			<Fragment>
				{matching.totalItems > 0 ? (
					<div className="open-jobs-container">
						<h2>MATCHING</h2>
						{matching.items.map((job, idx) => (
							<JobsBox onClick={() => this.props.onClickOpenJobs(job)} key={idx} data={job} />
						))}
					</div>
				) : null}
				{scheduled.totalItems > 0 ? (
					<div className="open-jobs-container">
						<h2>SCHEDULED</h2>
						{scheduled.items.map((job, idx) => (
							<JobsBox onClick={() => this.props.onClickOpenJobs(job)} key={idx} data={job} />
						))}
					</div>
				) : null}
				{queued.totalItems > 0 ? (
					<div className="open-jobs-container">
						<h2>QUEUED</h2>
						{queued.items.map((job, idx) => (
							<JobsBox onClick={() => this.props.onClickOpenJobs(job)} key={idx} data={job} />
						))}
					</div>
				) : null}
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => ({
	jobs: state.jobs,
});

export default connect(mapStateToProps)(OpenJobs);