import React, { Fragment } from 'react';

import './jobs-empty.css';
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import Button from "@material-ui/core/Button";

export default function JobsEmpty(props) {

	return (
		<Fragment>
			<div className="Jobs-empty text-center  no-padding">
				<div className="Home_Empty_Box Empty">
					<div className="Jobs_Empty_Box">
						<DynamicIcon icon={props.icon} classes="Search_Empty_Jobs"/>
					</div>
					<h5 className="Not_Found_Title_Job ">
						{props.activeTabTitle}
					</h5>
					<p className={`Empty_Search_Text ${props.tabType === 'active' ? "Active_Margin" : ""}`}>
						{props.description}
					</p>
				</div>

				{props.tabType !== 'active'
					?
					<Button type="button" className="Empty_Job_Btn" onClick={props.onNavigateToSearch}>
						<span className="Btn_Text">{props.buttonText}</span>
					</Button>
					: null}

				{props.helpDescription && (<h5 className="Empty_Down_Description_Jobs">
					<span>{props.helpDescription}</span>
					<span onClick={props.onSupport}>Get Help Now</span>
				</h5>)}
			</div>

		</Fragment>
	);
}