import React, { Fragment} from "react";

const classNames = require('classnames');

const JobInformationRate = (props) => {
	return (
		<Fragment>
			<div className="Img_Box_Rate">
				<img src={props.icon} alt="" className={classNames("img-responsive", "rate-icon", props.type)} />
			</div>
			<h5 className="thumbs-title">{props.title}</h5>
		</Fragment>
	);
};
export default JobInformationRate;