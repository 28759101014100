import _ from 'lodash';
import { toast } from "react-toastify";
import * as DISPATCH_STATE from '../jobsChangeState';
import { appFetchFailure, appGoHome } from "../../commonActions";

import { GraphRequest } from '../../../../../axios';
import * as LOG_EVENT from '../../../../analytics';
import { localStorageGetReminderRateApp, localStorageGetAppIsRated } from '../../../../functions/local-storage';
import { momentDiffFromNowAsDays } from '../../../../functions/moment-now-timestamp';
import { fetchComplimentsRequestBody, jobRateRequestBody } from "../jobs-gql";
/**
 * Fetch compliment from server for rate job after is completed
 */
export const fetchJobUnratedCompliment = () => {
    return (dispatch, getState) => {
        if (_.isNull(getState().jobs.unratedCompliment)) {
            dispatch(DISPATCH_STATE.complimentLoading(true));
            const complimentsRequestBody = {
                query: fetchComplimentsRequestBody,
                variables: {
                    "requestId": getState().jobs.unrated.id
                }
            }
            GraphRequest.all(complimentsRequestBody)
                .then((res) => {
                    dispatch(DISPATCH_STATE.complimentLoading(false));
                    if (!_.isNull(res.data.data) && res.data.data.request) { 
                        dispatch(DISPATCH_STATE.fetchJobUnratedComplimentSuccess(res.data.data.request.product.compliments));
                        dispatch(createComplimentsWithSelectedKey());
                    } else {
                        dispatch(appFetchFailure(res.data.errors[0].message));
                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
            }).catch((error) => {
                dispatch(DISPATCH_STATE.complimentLoading(false));
				dispatch(appFetchFailure(error));
                toast(`${error.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			})
        } else {
            dispatch(createComplimentsWithSelectedKey());
        }
    }
}
/**
 * Post request for rating partner in completed job
 */
export const postRequestJobUnratedCompliment = (obj) => {
    return (dispatch, getState) => {
        const logObj = {
            jobDetails: {
                serviceId: getState().jobs.unrated.product.id,
                commercialName: getState().jobs.unrated.product.commercialName,
            },
            requestId: getState().jobs.unrated.id,
            currency: 'PHP',
            value: 10.00,
        }
        if (obj.rate === 5) {
            logObj.isPositive = true;
            logObj.status = "positive";
            // LOG_EVENT.logEvent(LOG_EVENT.JOB_RATE_CWA, logObj);
        } else {
            logObj.isPositive = false;
            logObj.status = "negative";
            // LOG_EVENT.logEvent(LOG_EVENT.JOB_RATE_CWA, logObj);
        }
        if (!_.isEmpty(obj.comment)) {
            // LOG_EVENT.logEvent(LOG_EVENT.FEEDBACK_GIVEN_CWA, logObj);
        }
        if (obj.compliments.length > 0) {
            // LOG_EVENT.logEvent(LOG_EVENT.COMPLIMENT_GIVEN_CWA, logObj);
        }
        dispatch(DISPATCH_STATE.sendFeedBackLoading(true));
        const postComplimentRequestBody = {
            query: jobRateRequestBody,
            variables: {
                "rating": obj,
                "jobId": getState().jobs.unrated.id 
            }
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(postComplimentRequestBody)
				.then((res) => {
					dispatch(setEventForABTesting(obj));
					dispatch(DISPATCH_STATE.sendFeedBackLoading(false));
					if (!_.isNull(res.data.data) && res.data.data.jobRatePartner) {
						dispatch(DISPATCH_STATE.jobRatedPartnerComplited());
						//app is not rated
						if (obj.rate === 5 && !localStorageGetAppIsRated()) {
							if (localStorageGetReminderRateApp()) {
								// show popup for rate app after 14 days
								const firstTimeIgnoreAppRate = localStorageGetReminderRateApp();
								if (momentDiffFromNowAsDays(firstTimeIgnoreAppRate) >= 14) {
									dispatch(DISPATCH_STATE.toggleRatePopup(true));
								}
							} else {
								dispatch(DISPATCH_STATE.toggleRatePopup(true));
							}
						}
						toast(`Feedback sent`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appGoHome());
					} else {
						dispatch(appFetchFailure(res.data.errors[0].message));
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 2000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(DISPATCH_STATE.jobRatedPartnerComplited());
					}
                    resolve(res);
				})
				.catch((err) => {
					dispatch(DISPATCH_STATE.sendFeedBackLoading(false));
					dispatch(appFetchFailure(err.response.data.errors[0].message));
					toast(`${err.response.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
                    reject(err)
				});
        });
    }
}

export const createComplimentsWithSelectedKey = () => {
    return (dispatch, getState) => {
		let clonned = [...getState().jobs.unratedCompliment]
		clonned.map((compliment) => {
			compliment.compliments.map((c) => {
				if(compliment.rate === 1) {
					Object.assign(c, {
						selected: false,
						feedbackType: "NEGATIVE",
					});
				} else {
					Object.assign(c, {
						selected: false,
						feedbackType: "POSITIVE",
					});
				}
			});
		});
		dispatch(DISPATCH_STATE.addedNewKeyForCompliments(clonned));
	}
}

export const storeSelectedCompliments = (selectedCompliments) => {
    return (dispatch, getState) => {
        dispatch(toggleSelectedCompliment(selectedCompliments));     
    }
}

export const toggleSelectedCompliment = (selectedCompliments) => {
	return (dispatch, getState) => {
		const clonned = [...getState().jobs.newCompliments];
		clonned.map((compliment) => {
			compliment.compliments.map((c) => {
				if (c.id === selectedCompliments.id) {
					c.selected = !c.selected;
				}
			});
		});
		dispatch(DISPATCH_STATE.addedNewKeyForCompliments(clonned));
        dispatch(handleJobRateButton());
	};
};

export const handleJobRateButton = () => {
    return (dispatch, getState) => {
        const rawCompliments = [...getState().jobs.newCompliments[0].compliments, ...getState().jobs.newCompliments[1].compliments];
		const falsy = rawCompliments.filter((c) => !c.selected);
		if (falsy.length === rawCompliments.length) {
			dispatch(DISPATCH_STATE.toggleJobRatingButton(true));
		} else {
			dispatch(DISPATCH_STATE.toggleJobRatingButton(false));
		}
    }
}

export const setEventForABTesting = (selectedCompliments) => {
	return (dispatch, getState) => {
		let feedbacks = [];
		selectedCompliments.compliments.map((com) => feedbacks.push(com.feedbackType));

		if(_.uniq(feedbacks).length > 1) {
			LOG_EVENT.logEvent(LOG_EVENT.AB_HAD_BOTH_FEEDBACKS, { hasComment: selectedCompliments.comment.length ? true : false });
		}
	}
}