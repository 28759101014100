import _ from 'lodash';
import {push} from "connected-react-router";
import { toast } from "react-toastify";

import * as DISPATCH_STATE from '../bookingChangeState';
import * as Constant from "../../myJobsActions/myJobsConstant";
import * as TourText from "../../../../constants/constant";
import * as LOG_EVENT from "../../../../analytics/index";
import { localStorageGetSkipTour } from "../../../../functions/local-storage";
import { jobMatchingWillUnMount, unsetMatchingRequest, initCancelRequestCountDown, updateJobByOpenStatus } from "../../jobsActions/jobsChangeState";
import { createQueuedRequest, createScheduledRequest } from "../booking-gql";
import {createScheduledObj} from "../../../../functions/create-booking-request-object";
import { GraphRequest } from "../../../../../axios";
import { appFetchFailure, setConfigForTour } from "../../commonActions";
import { appLoadingBegin, appLoadingEnd } from "../../commonActions/commonChangeState";
import { checkCurrentStep } from "../../commonActions/actions/common-tour";
import { setSelectedTabIndex } from "../../myJobsActions/myJobsChangeState";
import { togglePaymentMethodsBottomSheet } from "../bookingChangeState";
 
export const checkKeepLooking = () => {
    return (dispatch, getState) => {
        const queued = true;
        if(queued) {
            dispatch(jobMatchingWillUnMount());
            dispatch(DISPATCH_STATE.openQueuedJobBottomSheet());

        }
    }
}

export const setQueuedJobTime = (time) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.setQueuedJobTime(time));
    }
}

export const postQueuedRequest = () => {
    return (dispatch, getState) => {
        const {queuedJobTime} = getState().booking;
        dispatch(unsetMatchingRequest());
        dispatch(initCancelRequestCountDown());
        dispatch(DISPATCH_STATE.queuedLoading(true));
        const createQueuedJobRequestBody = {
			query: createQueuedRequest,
			variables: {
				requestData: {
                    dueDate: queuedJobTime,
                    requestId: getState().jobs.job.id
				},
			},
        };
        GraphRequest.all(createQueuedJobRequestBody)
            .then((res) => {
                LOG_EVENT.logEvent(LOG_EVENT.SERVICE_QUEUED_CWA, {
					success: res.data.data.queueRequest ? true : false,
					queueType: getState().booking.queueType,
					productId: getState().booking.product.productId,
					productName: getState().booking.product.commercialName,
				});
                dispatch(DISPATCH_STATE.queuedLoading(false));
                dispatch(DISPATCH_STATE.closeQueuedJobBottomSheet());
                if (res.data.data && res.data.data.queueRequest) {
                    dispatch(updateJobByOpenStatus(res.data.data.queueRequest));
                    dispatch(DISPATCH_STATE.toggleRequestPlacedModal());
					dispatch(push("/jobs"));
					dispatch(setSelectedTabIndex(2, Constant.Open));
					dispatch(appLoadingEnd());
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 3000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                }
            }).catch((err) => {
                dispatch(DISPATCH_STATE.queuedLoading(false));
                dispatch(appFetchFailure(err.response.data.errors[0].message));
				toast(`${err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
            })
    }
}

export const setDefaultScheduleDate = (today) => {
    return (dispatch, getState) => {
        const calculatedTime = calculateHalfTime(today);
        let changedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), calculatedTime.h, calculatedTime.m, 0);
        dispatch(DISPATCH_STATE.dispatchSetDefaultScheduleDate(changedDate));
    };
};

export const setLimitationScheduleDate = (today) => {
    return (dispatch, getState) => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0);

        dispatch(setDefaultScheduleDate(new Date(tomorrow)));
        dispatch(DISPATCH_STATE.setMinDate(new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0, 0)));
        dispatch(DISPATCH_STATE.setMaxDate(new Date(tomorrow.getTime() + 31 * 24 * 60 * 60 * 1000)));
    }
}

export const calculateHalfTime = (today) => {
    let minutes = today.getMinutes();
    let hours = today.getHours();
    return {
        
        m : (parseInt((minutes + 20) / 30) * 30) % 60,
        h : minutes > 30 ? (hours === 23 ? 0 : hours + 1) : hours,
    }
}

export const requestScheduledBooking = (dueDate, location, deliveringPlace) => {
    return (dispatch, getState) => {
        dispatch(unsetMatchingRequest());
        dispatch(initCancelRequestCountDown());
        const createScheduledRequestRequestBody = {
			query: createScheduledRequest,
			variables: {
				requestData: createScheduledObj(getState, dueDate, location, deliveringPlace),
			},
		};
        const scheduledObj = createScheduledObj(getState, dueDate, location);
        dispatch(DISPATCH_STATE.scheduleLoading(true));
		GraphRequest.all(createScheduledRequestRequestBody)
			.then((res) => {
				LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SCHEDULED_CWA, {
					success: !_.isNull(res.data.data) && res.data.data.createScheduleRequest ? true : false,
					scheduledDate: scheduledObj.dueDate.toString(),
					productId: scheduledObj.productId,
					productName: getState().booking.product.commercialName,
				});
				dispatch(DISPATCH_STATE.scheduleLoading(false));
				dispatch(DISPATCH_STATE.toggleScheduleBooking());
				if (res.data.data && res.data.data.createScheduleRequest) {
					dispatch(updateJobByOpenStatus(res.data.data.createScheduleRequest));
					dispatch(DISPATCH_STATE.toggleRequestPlacedModal());
					dispatch(push("/jobs"));
					dispatch(setSelectedTabIndex(2, Constant.Open));
				} else {
					if (res.data.errors[0].extensions?.exception.status === 402) {
						dispatch(togglePaymentMethodsBottomSheet());
					} else {
						dispatch(appFetchFailure(res.data.errors[0].message));
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 3000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
				}
			})
			.catch((err) => {
				dispatch(DISPATCH_STATE.scheduleLoading(false));
				dispatch(DISPATCH_STATE.toggleScheduleBooking());
				dispatch(appFetchFailure(err.message ? err.message : err.response.data.errors[0].message));
				toast(`${err.message ? err.message : err.response.data.errors[0].message}`, {
					position: "bottom-center",
					autoClose: 2000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
    }
}

export const setBookingConfigTour = (minPoint, maxPoint, pageName) => {
    return (dispatch, getState) => {
        // const reactRoot = document.getElementById("tour-product-info");
        // console.log("document", reactRoot);
        // const restUrl = reactRoot.getAttribute("data-tut");
        if(!_.isNull(localStorage.getItem("pageIsLoaded"))) {
            const isSkipped = localStorageGetSkipTour();
            if (!isSkipped) {
                let steps;
                if (minPoint > 1) {
					steps = [
						{
							selector: '[data-tut="tour-product-info"]',
							content: TourText.ProductInfo,
							position: "bottom",
						},
						{
							selector: '[data-tut="tour-select-location"]',
							content: TourText.SelectMultiLocation,
							position: "bottom",
						},
					];
				} else if (minPoint === 1) {
					steps = [
						{
							selector: '[data-tut="tour-product-info"]',
							content: TourText.ProductInfo,
							position: "bottom",
						},
						{
							selector: '[data-tut="tour-select-location"]',
							content: TourText.SelectLocation,
							position: "bottom",
						},
					];
				}
                
                dispatch(setConfigForTour(pageName, steps));
            }
        } else {
            let checkTimeout = setTimeout(() => {
				dispatch(setBookingConfigTour(minPoint, maxPoint, pageName));
				clearTimeout(checkTimeout);
			}, 350);
        }
    }
}

export const setReviewBookingConfigTour = (pageName) => {
    return (dispatch, getState) => {
        if (!_.isNull(localStorage.getItem("pageIsLoaded"))) {
            const isSkipped = localStorageGetSkipTour();
            const { mode } = getState().booking.product;
			if (!isSkipped) {
                let steps = [
					{
						selector: '[data-tut="tour-mk-balance"]',
						content: TourText.MkBalanceInfo,
						position: "bottom",
					},
					{
						selector: '[data-tut="tour-payment-info"]',
						content: TourText.PaymentInfo,
						position: "bottom",
					},
				];
                if(!_.isUndefined(mode)) {
                    if(mode.includes("INSTANT") && mode.includes("SCHEDULED")) {
                        steps.push({
                            selector: '[data-tut="tour-booking-button"]',
                            content: TourText.BookingButton,
                            position: "bottom",
                        });
                        dispatch(setConfigForTour(pageName, steps));
                    } else {
                        if (mode.includes("SCHEDULED")) {
                            steps.push({
                                selector: '[data-tut="tour-scheduled-booking"]',
                                content: TourText.ScheduledButton,
                                position: "bottom",
                            });
                            dispatch(setConfigForTour(pageName, steps));
                        } else if (mode.includes("INSTANT")) {
                            steps.push({
                                selector: '[data-tut="tour-instant-booking"]',
                                content: TourText.InstantButton,
                                position: "bottom",
                            });
                            dispatch(setConfigForTour(pageName, steps));
                        }
                    }
                }
			}
		} else {
			let checkTimeout = setTimeout(() => {
				dispatch(setReviewBookingConfigTour(pageName));
				clearTimeout(checkTimeout);
			}, 500);
		}
    }
}

export const setPaymentBottomSheetTour = (pageName) => {
    return (dispatch, getState) => {
        const isSkipped = localStorageGetSkipTour();
        if(!isSkipped) {
            const steps = [
				{
					selector: '[data-tut="tour-topup"]',
					content: TourText.TopupInfo,
                    position: "bottom",
				},
				{
					selector: '[data-tut="tour-add-card"]',
					content: TourText.AddCardInfo,
					position: "bottom",
				},
			];
            let checkTimeout = setTimeout(() => {
                if (getState().booking.isOpenPaymentMethodsBottomSheet) {
                    dispatch(checkCurrentStep(0, pageName));
					dispatch(setConfigForTour(pageName, steps));

                }
					clearTimeout(checkTimeout);
			}, 350);
        }
    }
}