import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import SadMascot from "../../../v5-icons/sad-mascot.svg";
function BookingPriceErrorModal(props) {
    return (
		<div className="booking-price-error">
			<div className="booking-price-error-content">
				<img src={SadMascot} alt="sad-kuya-icon" />
				<div className="booking-price-description">
					<p className="title">Not Available</p>
					{!_.isNull(props.errorPriceMessage) ? (
						<p className="text-secondary">{props.errorPriceMessage}</p>
					) : null}
				</div>
			</div>
			<div className="booking-price-error-button">
				<CustomButton onClick={props.yes} type="button" className="retry-button" title="Change Location" />
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
    ...state.booking,
    common: state.common,
});

export default connect(mapStateToProps)(BookingPriceErrorModal);