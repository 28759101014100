import {
	REGISTER_FETCH_COUNTRY_CODE,
	SET_DEFAULT_COUNTRY_CODE,
	REGISTER_CHANGE_COUNTRY_SELECTED,
	REGISTER_FETCH_CITIES,
	REGISTER_CODE_VALUE_SET,
	REGISTER_PHONE_NUMBER_VALUE_SET,
	REGISTER_PHONE_NUMBER_VALUE_UNSET,
	INTERVAL_CHECK_CODE_BEGIN,
	REGISTER_BACK_TO_WELCOME,
	REGISTER_CHECK_CODE_REDUCE_COUNT,
	REGISTER_CHECK_CODE_INTERVAL,
	CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL,
	INTERVAL_NUMBER_CHECK_CODE,
	POST_PHONE_NUMBER_HAS_ERROR,
	SET_LIVING_CITY,
	TOGGLE_SELECT_CITY_MODAL,
	VERIFICATION_LOADING,
	ENTER_VERIFICATION_CODE_LOADING,
	REGISTER_CLIENT_LOADING,
	FETCH_CITIES_LOADING,
} from "../actions/registerActions";

const initialState = {
	code: null,
	countries: {},
	cities: {},
	countrySelected: {},
	number: null,
	requestNumberCheckCode: INTERVAL_NUMBER_CHECK_CODE,
	requestNumberCheckCodeInterval: null,
	numberCountValid: false,
	postPhoneNumberHasError: null,
	livingCity: null,
	toggleSelectCityModal: false,
	verificationLoading: false,
	enterVerificationCodeLoading: false,
    registerClientLoading: false,
    fetchCitiesLoading: false,
};

export default function registerReducer(state = initialState, action) {
    switch (action.type) {
		case REGISTER_FETCH_COUNTRY_CODE:
			return {
				...state,
				countries: action.payload,
				countrySelected: action.payload[0],
			};
		case SET_DEFAULT_COUNTRY_CODE:
			return {
				...state,
				countries: action.payload,
				countrySelected: action.payload,
			};
		case REGISTER_CHANGE_COUNTRY_SELECTED:
			return {
				...state,
				countrySelected: action.payload,
			};
		case REGISTER_FETCH_CITIES:
			return {
				...state,
				cities: action.payload,
			};
		case REGISTER_CODE_VALUE_SET:
			return {
				...state,
				code: action.payload,
			};
		case REGISTER_PHONE_NUMBER_VALUE_SET:
			return {
				...state,
				number: action.payload,
				numberCountValid: /^([0-9]{10})$/.test(action.payload),
			};
        case REGISTER_PHONE_NUMBER_VALUE_UNSET:
            return {
                ...state,
                number: null,
                numberCountValid: false
            }
        case REGISTER_BACK_TO_WELCOME:
            return {
                ...state,
                number: null,
                numberCountValid: false,
                code: null,
                countrySelected: {},
            }
        case INTERVAL_CHECK_CODE_BEGIN:
            return {
                ...state,
                requestNumberCheckCodeInterval: null,
                requestNumberCheckCode: INTERVAL_NUMBER_CHECK_CODE
            }
        case CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL:
            return {
                ...state,
                requestNumberCheckCodeInterval: null,
            }
        case REGISTER_CHECK_CODE_INTERVAL:
            return {
                ...state,
                requestNumberCheckCodeInterval: action.payload
            }
        case REGISTER_CHECK_CODE_REDUCE_COUNT:
            return {
                ...state,
                requestNumberCheckCode: action.payload
            }
        case POST_PHONE_NUMBER_HAS_ERROR:
            return {
                ...state,
                postPhoneNumberHasError: true
            }
        case SET_LIVING_CITY:
            return {
                ...state,
                livingCity: action.payload
            }
        case TOGGLE_SELECT_CITY_MODAL:
            return {
                ...state,
                toggleSelectCityModal: !state.toggleSelectCityModal,
            }
        case VERIFICATION_LOADING:
            return {
                ...state,
                verificationLoading: action.payload
            }
        case ENTER_VERIFICATION_CODE_LOADING:
            return {
                ...state,
                enterVerificationCodeLoading: action.payload
            }
        case REGISTER_CLIENT_LOADING:
            return {
                ...state,
                registerClientLoading: action.payload
            }
        case FETCH_CITIES_LOADING:
            return {
                ...state,
                fetchCitiesLoading: action.payload
            }
        default:
            // ALWAYS have a default case in a reducer
            return state;
    }
}