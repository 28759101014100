import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";


export default function ServiceBox(props) {
	return (
		<Fragment>
			<div className="Service-box" onMouseUp={(e) => props.onNavigateToService(e, props.data, props.subCategoryId)}>
				<div className="Service_Box_Container">
					<LazyLoadImage
						alt={props.title}
						src={props.img}
					/>
				</div>
				<h5 className="Featured_Title">{props.title}</h5>
			</div>
		</Fragment>
	);
}