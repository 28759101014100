import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import loadImage from '../../../libs/load-image';
import MessageListLoading from '../message-list-loading/message-list-loading';

import { onShowPhoto } from '../../../utils/redux/actions/chatActions';

class PhotoMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: null,
        }
    }
    componentDidMount() {
        this.props.data.uri.then((url) => {
            loadImage.parseMetaData(url, (data) => {
                loadImage(
                    url,
                    (canvas) => {
                        let base64data;
                        try {
                            base64data = canvas.toDataURL('image/' + this.props.data.format);
                        } catch (e) {
                            base64data = url;
                        }
                        if(document.documentElement.clientWidth < 770) {
                            const sampleWidth = document.documentElement.clientWidth - 40;
                            if(canvas.height < canvas.width) {
                                this.setState(() => ({ 
                                    url: base64data, 
                                    width: sampleWidth,
                                    height: "auto"
                                }));
                            } else {
                                this.setState(() => ({ 
                                    url: base64data, 
                                    width: sampleWidth,
                                    height: sampleWidth * .75
                                }));
                            }
                            
                        } else {
                            const sampleWidth = 
                                document.querySelector(".pm-container") && !_.isNull(document.querySelector(".pm-container")) ? 
                                    document.querySelector(".pm-container").offsetWidth : 372;
                            if(canvas.height < canvas.width) {
                                this.setState(() => ({ 
                                    url: base64data, 
                                    width: sampleWidth,
                                    height: "auto"
                                }));
                            } else {
                                this.setState(() => ({ 
                                    url: base64data, 
                                    width: sampleWidth,
                                    height: sampleWidth * .75
                                }));
                            }
                        }                       
                    }, {
                    canvas: true,
                    orientation: true
                });
            });
        })
    }

    onOpen = (e) => {
        this.props.dispatch(onShowPhoto(this.state.url));
    }

    render() {
        return (
            <div className="rce-mbox-photo">
                {this.props.authorType === "programmable_message" ?
                <div className="pm-container">

                    {this.state.url && (this.state.width || this.state.height) 
                    ? 
                        <div className="rec-mbox-photo-pm" style={{ width: `${this.state.width}px`, height: `${this.state.height}px` }}>
                            <img
                                
                                className="img-responsive"
                                src={this.state.url}
                                alt={this.props.data.alt}
                                onClick={this.onOpen}
                                onLoad={this.props.onLoad}
                                onError={this.props.onPhotoError} />
                        </div>
                    : 
                        <div className="rce-mbox-photo--img-loading">
                            <MessageListLoading />
                        </div>}
                </div>
                        
                    :
                        <div className="rce-mbox-photo--img">

                            {this.state.url ?
                                <img
                                    className="img-responsive"
                                    src={this.state.url}
                                    alt={this.props.data.alt}
                                    onClick={this.onOpen}
                                    onLoad={this.props.onLoad}
                                    onError={this.props.onPhotoError} />
                                :
                                <div className="rce-mbox-photo--img-loading">
                                    <MessageListLoading />
                                </div>}
                        </div>
                    }
            </div>
        );
    }
}

PhotoMessage.defaultProps = {
    text: '',
    data: {},
    onDownload: null,
    onOpen: null,
    onLoad: null,
    onPhotoError: null,
};

const mapStateToProps = state => ({
    chat: state.chat
});

export default connect(mapStateToProps)(PhotoMessage);
