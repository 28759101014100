import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

const classNames = require("classnames");

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: 0,
            seconds: 0
        }
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;
            
            if (seconds < 59) {
                this.setState(({ seconds }) => ({
                    seconds: seconds + 1
                }))
            }
            if (seconds === 59) {
                this.setState(({ minutes }) => ({
                    minutes: minutes + 1,
                    seconds: 0
                }))
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { seconds, minutes } = this.state;
        return (
            <div>
                <p>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    chat: state.chat,
});

export default connect(mapStateToProps)(Timer);
