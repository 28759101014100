import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import onClickOutside from "react-onclickoutside";
import { isMobile } from "react-device-detect";

import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import { setAmountOfSendMkForm, toggleTransferMKConfirmation } from "../../../utils/redux/actions/chatActions/chatChangeState";
import{ submitSendMkForm } from "../../../utils/redux/actions/chatActions";
import { getBrowserInfo } from "../../../utils/functions/getBrowserInfo";

class SendMK extends Component {
	inputAmountRef = React.createRef();
	inputNoteRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			isDisabledButton: true,
			data: null,
			inputAmountValue: "",
		};
	}

	componentDidMount() {
		this.inputSelect();
	}

	inputSelect = () => {
		if (this.inputAmountRef.current) {
			this.inputAmountRef.current.focus();
			this.inputAmountRef.current.parentNode.classList.add("focused");
		}
	};

	toggleFocusedClass(e) {
		const element = document.querySelector(".call-to-action");
		const bottomPosition = element.style.bottom;
		
		if (!_.isNull(this.inputAmountRef.current) && this.inputAmountRef.current.parentNode.classList.contains("focused")) {
			this.inputAmountRef.current.parentNode.classList.remove("focused");
		}
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			if (bottomPosition === "0px" && isMobile) {
				element.style.bottom = "-18px";
			}
			e.target.parentNode.classList.add("focused");
		}
	}

	handleChange() {
		if (this.inputAmountRef.current.value.length > 0 && this.inputNoteRef.current.value.length >= 4) {
			this.setState(() => ({
				isDisabledButton: false,
			}));
			this.props.dispatch(
				setAmountOfSendMkForm({
					amount: Number(this.inputAmountRef.current.value),
					note: this.inputNoteRef.current.value,
				}),
			);
		} else {
			this.setState(() => ({
				isDisabledButton: true,
			}));
		}
	}

	submitForm() {
		const sendFormData = {
			jobId: this.props.jobId,
			data: {
				...this.props.mkSendFormData,
				browserInfo: getBrowserInfo(),
				channel: "Web",
				shopperRedirectUrl: `${window.location.origin}/jobs/${this.props.jobId}/chat/history-transactions`
			},
		};
		this.props.dispatch(submitSendMkForm(sendFormData));
	}

	handleClickOutside = (evt) => {
		const element = document.querySelector(".call-to-action");
		element.style.bottom = "0px";
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		return (
			<>
				<div className="mk-form">
					<div className="form-group">
						<div className="form-group-container">
							<label>Amount to send</label>
							<input
								className="form-control"
								type="text"
								placeholder="Enter the amount"
								onChange={(e) => {
									if (e.target.validity.valid) {
										this.handleChange(e);
										this.setState(() => ({ inputAmountValue: e.target.value }));
									}
								}}
								pattern="\d+$|(?=^.+$)^\d+\.\d{0,2}"
								autoComplete="off"
								ref={this.inputAmountRef}
								onBlur={(e) => this.toggleFocusedClass(e)}
								onFocus={(e) => this.toggleFocusedClass(e)}
								value={this.state.inputAmountValue}
							/>
						</div>
					</div>
					<div className="form-group">
						<div className="form-group-container">
							<label>What is it for?</label>
							<textarea
								className="form-control"
								onChange={(e) => this.handleChange(e)}
								onFocus={(e) => this.toggleFocusedClass(e)}
								onBlur={(e) => this.toggleFocusedClass(e)}
								ref={this.inputNoteRef}
								maxLength="255"
								placeholder="For grocery purchases & parking fees"
							></textarea>
						</div>
					</div>
				</div>
				<div className="call-to-action" style={{ bottom: 0 }}>
					<CustomButton
						title="Send Money"
						type="button"
						disabled={this.state.isDisabledButton}
						onClick={() => this.props.dispatch(toggleTransferMKConfirmation())}
					/>
				</div>
				<div className="add-new-card-container" id="component-container"></div>
				{!_.isEmpty(this.props.client) && !_.isNull(this.inputAmountRef.current) && (
					<BottomSheet
						classes="confirmation-bottom-sheet"
						isOpen={this.props.toggleTransferMKConfirmation}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleTransferMKConfirmation());
						}}
					>
						<div className="bottom-sheet-info">
							<p className="title-send-mk">
								Are you sure to send {this.props.client.currency.signText}
								{this.inputAmountRef.current.value}?
							</p>
							<div className="call-to-action">
								<CustomButton
									title="No, Go Back!"
									outline
									variant="outlined"
									onClick={() => this.props.dispatch(toggleTransferMKConfirmation())}
								/>
								<CustomButton
									title={
										this.props.sendMoneyLoading ? (
											<div className="next-button-loading">
												<WhiteLoading />
											</div>
										) : (
											"Send Money"
										)
									}
									disabled={this.props.sendMoneyLoading}
									onClick={() => this.submitForm()}
								/>
							</div>
						</div>
					</BottomSheet>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.chat,
    client: state.common.client,
});

export default connect(mapStateToProps)(onClickOutside(SendMK));
