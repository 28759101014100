import React from 'react';
import './LoadingScreen.css';

import NewSplash from '../../assests/images/logo/mykuya-splash.png';

export default function LoadingScreenKuya(props) {
    return (
		<div className="loading-screen-index">
			<div className="splash-image-container">
				<img src={NewSplash} className="splash-image" alt="" />
			</div>
		</div>
	);
}