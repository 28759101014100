import React from "react";
import Highlighter from "react-highlight-words";

const SearchCityResult = (props) => {
	
	return (
		<div className="" onClick={(e) => props.onClickSearchResult(e, props.city)}>
			<div className={props.classes}>
				<Highlighter
					className={`${props.highLightText.length ? "container-highlight" : ""}`}
					highlightClassName="High_Lighted"
					searchWords={props.highLightText}
					autoEscape={true}
					textToHighlight={props.city.name}
				/>
			</div>
		</div>
	);
};
export default SearchCityResult;