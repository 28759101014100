import * as CONSTANT_ACTIONS from "./myJobsConstant";

export const setSelectedTabIndex = (index, tabName) => ({
    type: CONSTANT_ACTIONS.SET_SELECTED_TAB_INDEX,
    payload: {index, tabName}
});

export const fetchOpenJobsBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_OPEN_JOBS_BEGIN
});

export const fetchOpenJobsSuccessfully = (data) => ({
	type: CONSTANT_ACTIONS.FETCH_OPEN_JOBS_SUCCESSFULLY,
	payload: data,
});

export const noOpenJobs = () => ({
    type: CONSTANT_ACTIONS.NO_OPEN_JOBS
})

export const fetchUpcomingJobs = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_UPCOMING_JOBS,
    payload: data
});

export const fetchAllJobs = (data) => ({
	type: CONSTANT_ACTIONS.FETCH_ALL_JOBS,
	payload: data,
});

export const fetchCompletedJobs = (data) => ({
	type: CONSTANT_ACTIONS.FETCH_COMPLETED_JOBS,
	payload: data,
});

export const fetchActiveJobs = (data) => ({
	type: CONSTANT_ACTIONS.FETCH_ACTIVE_JOBS,
	payload: data,
});

export const fetchJobsByOffsetBegin = () => ({
	type: CONSTANT_ACTIONS.FETCH_JOBS_BY_OFFSET_BEGIN,
});

export const fetchJobsByOffsetEnd = () => ({
	type: CONSTANT_ACTIONS.FETCH_JOBS_BY_OFFSET_END
})

export const fetchJobsByRequestTypeBegin = () => ({
	type: CONSTANT_ACTIONS.FETCH_JOBS_BY_REQUEST_TYPE_BEGIN
});

export const unMountJobsList = () => ({
	type: CONSTANT_ACTIONS.UNMOUNT_JOBS_LIST
});

export const fetchJobsLoading = (condition) => ({
	type: CONSTANT_ACTIONS.FETCH_JOBS_LOADING,
	payload: condition
});