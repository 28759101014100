import React, { Fragment, Component } from "react";

import JobsBoxHeadingStatus from "./JobsBoxHeadingStatus/JobsBoxHeadingStatus";
import { JOB_STATE_COMPLETED } from "../../../../../utils/redux/actions/jobsActions";
import JobsBoxHeadingDatePrice from "./JobsBoxHeadingDatePrice/JobsBoxHeadingDatePrice";

const classNames = require("classnames");

export default class JobsBoxHeading extends Component {
	render() {
		
		return (
			<Fragment>
				<div className="Jobs-box-heading-startdate">
					<JobsBoxHeadingDatePrice dueDate={this.props.dueDate} state={this.props.state} date={this.props.date} jobPrice={this.props.price}/>
					<JobsBoxHeadingStatus state={this.props.state} mode={this.props.mode}/>
				</div>

				<div className={classNames({
					"Jobs-box-heading-title": true,
					"Jobs-box-heading-title-completed": this.props.state === JOB_STATE_COMPLETED,
				})}>
					<span className="title">{this.props.title}</span>
					<span className="address"> to </span>
					<span className="address"> {this.props.address}</span>
				</div>
			</Fragment>
		);
	}
}