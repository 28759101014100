import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import GoogleMap from "../GoogleMap/GoogleMap";
import LocationSearchConfirm from "../LocationSearchConfirm/LocationSearchConfirm";
import LocationSearchCurrent from "../LocationSearchCurrent/LocationSearchCurrent";
import TitleBar from "../../../organisms/title-bar";
import EllipseIcon from "../../icons/ellipse-icon";

import { ReactComponent as PinImg } from "../../../v5-icons/pin-map.svg";
import backButton from "../../../v5-icons/back-button.svg";

import { createMarker, mapGetCurrentLocation, checkShouldMapShow } from "../../../utils/redux/actions/mapActions";
import { mapinitializeFinishIcon, mapRemoveGoogleMarker } from "../../../utils/redux/actions/mapActions/mapChangeState";
import { updatedLocationUpdateUpdatedLocation } from "../../../utils/redux/actions/homeActions";
import { closeLocationSearchBottomSheet } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
const classNames = require("classnames");

class LocationSearchMap extends Component {
	finish = React.createRef();

	constructor(props) {
		super(props);

		this.onBack = this.onBack.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.onGetCurrent = this.onGetCurrent.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(checkShouldMapShow());
		this.props.dispatch(mapinitializeFinishIcon(this.finish));
		// this.props.dispatch(searchCheckBookingProductExist());
	}

	componentWillUnmount() {
		this.props.dispatch(mapRemoveGoogleMarker());
	}

	onBack() {
		this.props.history.goBack();
		this.props.dispatch(closeLocationSearchBottomSheet());
	}

	onConfirm(e) {
		this.props.dispatch(createMarker(this.props.match.params));
		this.props.dispatch(closeLocationSearchBottomSheet());
	}

	onGetCurrent(e) {
		this.props.dispatch(mapGetCurrentLocation()).then((res) => {
			this.props.dispatch(updatedLocationUpdateUpdatedLocation());
		});
		localStorage.removeItem("isFirstRequest");
	}

	render() {
		return (
			<div className="location-search-map">
				<TitleBar title="Pick your location" leftIcon={backButton} handleClickLeftIcon={this.onBack} />
				<GoogleMap listener={true} />
				<div
					className={classNames({
						"location-search-map-icon": true,
						dragged: this.props.map.dragged,
					})}
				>
					{!_.isEmpty(this.props.map.finish) && !_.isNull(this.props.map.finishLeft) && _.isEmpty(this.props.map.googleMarker) ? (
						<Fragment>
							<PinImg
								className="Pin-icon"
								style={{ left: this.props.map.finishLeft + 4, top: this.props.map.finishTop + 17 }}
								refs={this.props.map.finish}
							/>
							<EllipseIcon style={{ left: this.props.map.finishLeft + 20, top: this.props.map.finishTop + 52 }} />
						</Fragment>
					) : null}
				</div>

				<div className="location-search-map-confirm">
					<LocationSearchConfirm style={{ backgroundColor: "#0488FA" }} title="Confirm address" onClick={this.onConfirm} />
				</div>

				<div className="location-search-map-current">
					<LocationSearchCurrent onGetCurrent={this.onGetCurrent} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	map: state.map,
	common: state.common,
});

export default connect(mapStateToProps)(LocationSearchMap);
