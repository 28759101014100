import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import { localStorageGetAndroidApp, localStorageGetIosApp } from '../../utils/functions/local-storage';
import AppRatingIcon from '../../assests/images/jobs/app-rating-icon.svg';
import CloseIcon from '../../assests/icons/close.svg';
import './app-rating.css';
import ModalBody from '../Modal/ModalBody';

function AppRating(props) {
    return (
		<Fragment>
			<div className="app-rating col-xs-12  no-padding" onClick={props.closeModal}>
				<ModalBody>
					<div className="modal-close" onClick={props.closeModal}>
						<img src={CloseIcon} alt="" className="img-responsive" />
					</div>
					<div className="modal-body-title">
						<div className="modal-kuya-container">
							<img src={AppRatingIcon} alt="" className="img-responsive" />
						</div>
						<div className="title">
							<p className="app-rating-title">
								Awesome! Would you mind sharing your review on
								{localStorageGetAndroidApp() ? " Google Play" : ""}
								{localStorageGetIosApp() ? " App Store" : ""}
								?
							</p>
						</div>
					</div>

                    <div className="m-t-5 app-rating-button-container">
                        <Button type="button" className="primary-button" onClick={props.goToStore}>Sure</Button>
                    </div>
                    <div className="m-t-5 app-rating-button-container">
                        <Button type="button" className="secondary-button" onClick={props.closeModal}>Not Now</Button>
                    </div>
                </ModalBody>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    common: state.common,
    jobs: state.jobs
});

export default connect(mapStateToProps)(AppRating);