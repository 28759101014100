import React, { Fragment } from "react";

const classNames = require("classnames");

export default function JobRatingComplimentsItem(props) {

	return (
		<Fragment>
			<div className={classNames("Job-Rating-compliments-item", props.children.replace(" ", "-").toLowerCase())}>
				<input name={`compliments[${props.id}]`}
					   onChange={props.onChange}
					   onBlur={props.onBlur}
					   value={props.id}
					   type="checkbox"
					   id={"option-" + props.id}/>
				<label className="check" htmlFor={"option-" + props.id}>
					<div className="J_R_Complements_Label_Title">
						<h5>{props.children}</h5>
					</div>
				</label>
			</div>
		</Fragment>
	);
}