import React from "react";
import MyKoinSvg from "../../../assests/images/billing/my-koin.svg";
import noCardSvg from "../../../assests/images/billing/card-coin.svg";
import WarningIcon from "../../../assests/icons/no-address-warning.svg";
import SingleLineSkeleton from "../../ui-elements/Skeleton/SingleLineSkeleton";
import _ from "lodash";
import { cardType } from "../../../utils/constants/constant";

const classNames = require('classnames');

export default function PaymentMethod({
      loading,
      billing,
      primaryPayment,
      newPrice,
      price,
      onTopUp,
      onChangePrimary,
      onRetry,
      isAuthenticated
    }) {

    const serviceFee = (newPrice || newPrice === 0) ? newPrice : price?.items?.[price?.minCubeNumbers]?.price.value;
    const hasNoCard = _.isNull(primaryPayment) || _.isUndefined(primaryPayment);
    const hasGCash = primaryPayment?.[0].type === "GCash" || primaryPayment?.[0].name === "GCash";
    const isNegativeBalance = !loading && billing.balance?.[0].balance < 0;
    const isZeroBalance = !loading && billing.balance?.[0].balance === 0;
    const isSufficientBalance = !loading && billing.balance?.[0].balance >= serviceFee || !hasNoCard || hasGCash;

	return (
        <div className="payment-method-wrapper">
           <div className="payment-method-title">Paying with</div>
            <div className="payment-method-body">
                {(!isZeroBalance || !hasNoCard) && isAuthenticated ? (<>
                    <div>
                        {loading ? (
                            <SingleLineSkeleton />
                        ) : !_.isNull(billing.balance) && !isZeroBalance &&
                            (<>
                                <div className="payment-method-type">
                                    <img src={MyKoinSvg} alt="MyKoin" style={{height: "16px", width: "16px"}} />
                                    <span>
                                        mykoins <span className={classNames({
                                        "negative-color": isNegativeBalance
                                    })}>{billing.balance[0].displayValue}</span>
                                    </span>
                                </div>
                                {(!isSufficientBalance || isNegativeBalance) && (<div className="balance-warning">
                                    <img src={WarningIcon} alt="no balance" />
                                    <div className="balance-warning-text">
                                        { (!isSufficientBalance && !isNegativeBalance) ? "mykoins insufficient. Add a payment method for easy payments or make a one time top-up."  :
                                            "We weren't able to receive your last payment. Please settle this to proceed."}
                                    </div>
                                </div>)}
                                {(isSufficientBalance && !isNegativeBalance) && (<div className="payment-method-description">
                                    You will only be charged after the job starts
                                </div>)}
                            </>)}
                    </div>
                    {!hasNoCard && !isZeroBalance && <div className="dashed-spliter"></div>}
                    {loading ? (
                        <SingleLineSkeleton />
                    ) : (!hasNoCard) &&
                        (<>
                            <div>
                                <div className="payment-method-type">
                                    <img src={primaryPayment[0].icon}
                                         alt={primaryPayment[0].type}
                                         style={{ height: hasGCash ? "15px" : "11px" }} />
                                    {hasGCash ? (<>
                                    <span>
                                        {!_.isNull(primaryPayment[0].name) ? primaryPayment[0].name : primaryPayment[0].type}
                                    </span>
                                    </>) : (
                                        <>
                                    <span>
                                       {/*{!_.isNull(primaryPayment[0].name) ? primaryPayment[0].name : cardType[primaryPayment[0].value]}, ****{" "}*/}
                                       {/* {primaryPayment[0].cardNumber}*/}
                                        {!_.isNull(primaryPayment[0].name) ? primaryPayment[0].name : primaryPayment[0].type}, ****{" "}
                                        {primaryPayment[0].cardNumber}
                                    </span>
                                        </>)}
                                </div>
                                <div className="payment-method-description">
                                    {isSufficientBalance && !isZeroBalance ?
                                        `${hasGCash ? 'GCash' : 'This card'} will be charged if your mykoins are insufficient` :
                                        !hasNoCard && isZeroBalance && ("You will only be charged after the job starts")
                                    }
                                </div>
                            </div>
                        </>)}
                </>) : (
                    <div className="no-payment-method">
                        <img src={noCardSvg} alt="no payment method" />
                        <p>
                            Pay with Visa, Mastercard or GCash
                        </p>
                    </div>
                )}
                <div className="delivering-to-actions">
                    {!isAuthenticated ?
                        <button onClick={onChangePrimary}>Add Yours</button> :
                        isZeroBalance && hasNoCard ?
                            <button onClick={onChangePrimary}>Add Yours</button> :
                            isSufficientBalance && !isZeroBalance ?
                                (<>
                                    <button onClick={onChangePrimary}>Change</button>
                                    <button onClick={onTopUp}>Top-up</button>
                                </>) : isZeroBalance && !hasNoCard ?
                                    <button onClick={onChangePrimary}>Change</button> :
                                hasNoCard || (hasNoCard && isNegativeBalance) ?
                                    (<>
                                <button onClick={onChangePrimary}>Add Yours</button>
                                        <button onClick={onTopUp}>Top-up</button>
                                    </>) : !hasNoCard && isNegativeBalance ?
                                        (<>
                                            <button onClick={onRetry}>Retry</button>
                                            <button onClick={onTopUp}>Top-up</button>
                                        </>) : isZeroBalance && hasNoCard ?
                                            <button onClick={onChangePrimary}>Add Yours</button> : null
                    }
                </div>
            </div>
        </div>
	);
}
