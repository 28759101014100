export const FETCH_JOBS_BEGIN = 'FETCH_JOBS_BEGIN';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_SUCCESS_SCROLL = 'FETCH_JOBS_SUCCESS_SCROLL';
export const WILL_UNMOUNT_JOBS = 'WILL_UNMOUNT_JOBS';
export const SELECT_JOB_FROM_JOBS = 'SELECT_JOB_FROM_JOBS'; // when user click on job from list of jobs
export const FETCH_JOB_INFORMATION_BEGIN = 'FETCH_JOB_INFORMATION_BEGIN'; // Before start to get job information from server
export const FETCH_JOB_INFORMATION_SUCCESS = 'FETCH_JOB_INFORMATION_SUCCESS'; // after information of job is completed
export const FETCH_JOB_UNRATED_SUCCESS = 'FETCH_JOB_UNRATED_SUCCESS'; // fetch unrated job
export const JOB_INFORMATION_NAVIGATE_TO_EITHER_RECEIPT_OR_CHAT = 'JOB_INFORMATION_NAVIGATE_TO_EITHER_RECEIPT_OR_CHAT'; // when user click for see chat or receipt of job when he or she in job information component
export const INIT_JOB_BY_MATCH_STATUS = 'INIT_JOB_BY_MATCH_STATUS'; // Put job info based on current data
export const UPDATE_JOB_BY_MATCH_STATUS = 'UPDATE_JOB_BY_MATCH_STATUS'; // Update job info when send new request to server and being accepted
export const FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING = 'FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING'; // fetch state of job every minutes
export const INIT_INTERVAL_MATCHING_JOB_REQUEST = 'INIT_INTERVAL_MATCHING_JOB_REQUEST'; // Initialize setInterval for job matching 
export const FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING_SUCCESS = 'FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING_SUCCESS'; // Fetch state of matching job for checking success
export const FETCH_PARTNER_OF_MATCHED_JOB_BEGIN = 'FETCH_PARTNER_OF_MATCHED_JOB_BEGIN';
export const FETCH_PARTNER_OF_MATCHED_JOB_SUCCESS = 'FETCH_PARTNER_OF_MATCHED_JOB_SUCCESS';
export const CANCEL_SETINTERVAL_JOB = 'CANCEL_SETINTERVAL_JOB'; // cancel setinterval to stop request
export const PLAY_TRACK_MATCHED = 'PLAY_TRACK_MATCHED'; // play track matched
export const STOP_TRACK_MATCHED = 'STOP_TRACK_MATCHED'; // stop track matched
export const FETCH_JOB_UNRATED_RATE_COMPLIMENT = 'FETCH_JOB_UNRATED_RATE_COMPLIMENT'; // fetch unrated rate compliment
export const JOB_RATE_PARTNER_COMPLITED = 'JOB_RATE_PARTNER_COMPLITED';
export const JOB_MATCHING_WILL_UN_MOUNT = 'JOB_MATCHING_WILL_UN_MOUNT';
export const JOB_ACTIVE_COUNT = 'JOB_ACTIVE_COUNT'; // when get jobs active from server
export const JOB_ACTIVE = 'JOB_ACTIVE'; // when get jobs active from server
export const JOB_MATCHING_STATE_SET = 'JOB_MATCHING_STATE_SET';
export const JOB_CHAT_HISTOR_WILL_UNMOUNT = 'JOB_CHAT_HISTOR_WILL_UNMOUNT';
export const JOB_UNSET_MATCHING_REQUEST = 'JOB_UNSET_MATCHING_REQUEST'; // unset matching request
export const INIT_INTERVAL_JOB_STATE_CHECK = 'INIT_INTERVAL_JOB_STATE_CHECK';
export const JOB_CHANGE_STATE_INTERVAL_CHAT = 'JOB_CHANGE_STATE_INTERVAL_CHAT';
export const JOB_RATING_UNRATED_WILL_UNMOUNT = 'JOB_RATING_UNRATED_WILL_UNMOUNT';
export const OUT_OF_WORKING_HOURS = 'OUT_OF_WORKING_HOURS';

export const TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_STATE = 5000; // time for interval request 
export const TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_COMPLITION = 10000; // time for interval request
export const TIME_FOR_SETTIMEOUT_IN_MATCHED_STATE = 5000; // time for set time out in matched modal
export const SUCCESS_STATE_OF_INTREVAL_REQUEST_MATCHED = 'Matched';
export const SUCCESS_STATE_OF_INTREVAL_REQUEST_UNFULFILLED = 'Unfulfilled';
export const SUCCESS_STATE_OF_INTREVAL_REQUEST_CANCELLED = "Cancelled";
export const SUCCESS_STATE_OF_INTREVAL_REQUEST_STARTED = "Started";
export const SUCCESS_STATE_OF_INTREVAL_REQUEST_QUEUED = "Queued";
export const PARTNER_KEY_JOB = 'partner';
export const JOB_UNRATED_THUMBS_DOWN_RATE = 1; // rate for thumbs down number
export const JOB_UNRATED_THUMBS_UP_RATE = 5; // rate for thumbs up number
export const JOB_STATE_COMPLETED = "Completed"; // one of jobs state
export const JOB_STATE_STARTED = "Started"; // one of jobs state
export const JOB_STATE_MATCHED = "Matched"; // one of jobs state
export const JOB_STATE_ACTIVE = "Active"; // one of jobs state
export const JOB_STATE_CANCELLED = "Cancelled"; // one of jobs state
export const JOB_STATE_MATCHING = "Matching"; // one of jobs state
export const JOB_STATE_QUEUED = "Queued"; // one of jobs state
export const JOB_GOOGLE_MAP_ID = '#google-map';
export const JOB_GOOGLE_MAP_BLUR_CLASS = 'google-map-blur';
export const JOB_MATCHING_CIRCLE_RIPPLE = '#Job-matching-circle-ripple';

export const CLEAR_INTERVAL_CANCEL_REQUEST_INTERVAL = 'CLEAR_INTERVAL_CANCEL_REQUEST_INTERVAL';
export const CANCEL_REQUEST_REDUCE_COUNT = 'CANCEL_REQUEST_REDUCE_COUNT';
export const CANCEL_REQUEST_INTERVAL = 'CANCEL_REQUEST_INTERVAL';
export const INIT_CANCEL_REQUEST_COUNT_DOWN = 'INIT_CANCEL_REQUEST_COUNT_DOWN';

export const TOGGLE_RATE_POPUP = "TOGGLE_RATE_POPUP";
export const APP_RATED = "APP_RATED";

export const SET_MY_JOB_LOCATION_POINTER = "SET_MY_JOB_LOCATION_POINTER";
export const SET_MY_JOBS_SET_SEARCH_LOCATION_POINTER = "SET_MY_JOBS_SET_SEARCH_LOCATION_POINTER";
export const DETAIL_PAYMENT_METHOD = "DETAIL_PAYMENT_METHOD";
export const SET_DEFAULT_PAYMENT_DETAILS = "SET_DEFAULT_PAYMENT_DETAILS";
export const ADD_CARD_DETAIL = "ADD_CARD_DETAIL";
export const SET_DEFAULT_CARD_DETAIL = "SET_DEFAULT_CARD_DETAIL";
export const SET_DEFAULT_JOB_OBJECT = "SET_DEFAULT_JOB_OBJECT";
export const FETCH_OPEN_JOBS_BEGIN = "FETCH_OPEN_JOBS_BEGIN";
export const FETCH_OPEN_JOBS_SUCCESSFULLY = "FETCH_OPEN_JOBS_SUCCESSFULLY";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const CANCEL_OPEN_JOB_BEGIN = "CANCEL_OPEN_JOB_BEGIN";
export const UPDATE_JOB_BY_OPEN_STATUS = "UPDATE_JOB_BY_OPEN_STATUS";
export const SET_CUBE_NUMBER = "SET_CUBE_NUMBER";
export const PLUS_CUBE_NUMBER = "PLUS_CUBE_NUMBER";
export const MINUS_CUBE_NUMBER = "MINUS_CUBE_NUMBER";
export const SET_JOB_STATE = "SET_JOB_STATE";
export const CANCEL_JOB_LOADING = "CANCEL_JOB_LOADING";
export const FETCH_JOB_AND_PAYMENT_LOADING = "FETCH_JOB_AND_PAYMENT_LOADING";
export const COMPLIMENT_LOADING = "COMPLIMENT_LOADING";
export const SEND_FEEDBACK_BACK_LOADING = "SEND_FEEDBACK_BACK_LOADING";
export const ADDED_NEW_KEY_FOR_COMPLIMENTS = "ADDED_NEW_KEY_FOR_COMPLIMENTS";
export const TOGGLE_JOB_RATING_BUTTON = "TOGGLE_JOB_RATING_BUTTON";
export const ADD_COMMENT_OF_APP_RATING = "ADD_COMMENT_OF_APP_RATING";
export const SET_SORTED_CLIENT_RATE = "SET_SORTED_CLIENT_RATE";
export const IS_TOGGLE_SHEET = "IS_TOGGLE_SHEET";