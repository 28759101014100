import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { push } from "connected-react-router";
import $ from "jquery";
import {
	navigateToAccountBilling,
	addXenditCard,
} from "../../../../utils/redux/actions/billingActions";
import { billingUnSetErrorPayment } from "../../../../utils/redux/actions/billingActions/billingChangeState";
import BillingErrorModal from '../BillingErrorModal/BillingErrorModal';
import TitleBar from "../../../../organisms/title-bar";
import backButton from "../../../../v5-icons/back-button.svg";
import cardNo from "../../../../v5-icons/nocard.svg";
import cardCvn from "../../../../v5-icons/card-cvn.svg";
import fieldError from "../../../../v5-icons/field_error.svg";
import mastercard from "../../../../assests/icons/Mastercard.svg"
import visacard from "../../../../assests/icons/Visacard.png"
import { toggleTransfer3DsBottomSheet } from "../../../../utils/redux/actions/chatActions";
import BottomSheet from "../../../ui-elements/BottomSheet/BottomSheet";

const cardNumberRegex = {
	mc: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
	visa: /^4[0-9]{12}(?:[0-9]{3})?$/
}

const cardType = {
	mastercard: mastercard,
	visacard: visacard
}

class BillingAddCard extends Component {
	state = {
		cardNumber: "", // "5200000000001096", "4000000000001091"
		cardExpMonth: "",
		cardCvn: "",
		cardName: ""
	};

	componentDidMount() {
		this.handleDateSeparator();
	}

	handleDateSeparator = () => {
		let separator = "/";
		$('input[name="cardExpMonth"]').keyup(function(e) {
			let textSoFar = $(this).val();
			if (e.keyCode != 191) {
				if (e.keyCode != 8) {
					if (textSoFar.length == 2) {
						$(this).val(textSoFar + separator);
					} else if (e.keyCode == 86 && textSoFar.length == 4) {
						//to handle copy & paste of 8 digit
						$(this).val(
							textSoFar.substr(0, 2) +
							separator +
							textSoFar.substr(2, 2)
						);
					}
				} else {
					//backspace would skip the slashes and just remove the numbers
					if (textSoFar.length == 5) {
						$(this).val(textSoFar.substring(0, 4));
					} else if (textSoFar.length == 2) {
						$(this).val(textSoFar.substring(0, 1));
					}
				}
			} else {
				//remove slashes to avoid 12//01/2014
				$(this).val(textSoFar.substring(0, textSoFar.length - 1));
			}
		});
	}

	onCardInfoChange = (e) => {
		if (!e.target.value.includes(" ")) {
			this.setState({ [e.target.name]: e.target.value }, () => {
				this.checkFormValidation()
			})
		}
	}

	onAddCard = () => {
		window.Xendit.card.createToken({
			amount: "0",
			card_number: this.state.cardNumber,
			card_exp_month: this.state.cardExpMonth.split("/")[0],
			card_exp_year: "20"+this.state.cardExpMonth.split("/")[1],
			card_cvn: this.state.cardCvn,
			is_multiple_use: true,
			should_authenticate: true
		}, this.xenditResponseHandler);
	};

	xenditResponseHandler = (err, creditCardToken) => {
		let $form = $('#payment-form');
		if (err) {
			// Show the errors on the form
			$('#error pre').text(err.message);
			$('#error').show();
			$form.find('.submit').prop('disabled', false); // Re-enable submission
			return;
		}
		if (creditCardToken.status === 'VERIFIED') {
			window.Xendit.card.createAuthentication({
				"amount": "0",
				"token_id": creditCardToken.id
			}, (err, data) => {
				if (err) {
					//Define error handling
				}
				if (data.status === 'VERIFIED') {
					this.props.dispatch(addXenditCard({
						id: creditCardToken.card_info?.fingerprint,
						cardInfo: {
							...creditCardToken.card_info,
							card_expiration_month: creditCardToken.card_expiration_month,
							card_expiration_year: creditCardToken.card_expiration_year,
						},
						status: creditCardToken.status,
						maskedCardNumber: creditCardToken.masked_card_number,
						creditCardTokenId: creditCardToken.id,
						name: this.state.cardName
					}))
					// $('#three-ds-container').hide();
					this.props.dispatch(toggleTransfer3DsBottomSheet(false))
				} else if (data.status === 'IN_REVIEW') {
					window.open(data.payer_authentication_url, 'sample-inline-frame');
					$form.hide();
					$('#save-bank-card').hide();
					//$('#three-ds-container').show();
					this.props.dispatch(toggleTransfer3DsBottomSheet(true))
				} else if (data.status === 'FAILED') {
					// Handle failure
				}
			});
		} else if (creditCardToken.status === 'FAILED') {
			$('#error pre').text(creditCardToken.failure_reason);
			$('#error').show();
			$form.find('.submit').prop('disabled', false); // Re-enable submission
		}
	}

	checkFormValidation = () => {
		if (this.state.cardNumber && !window.Xendit.card.validateCardNumber(this.state.cardNumber)) {
			$("#card-number-icon").hide()
			$(".card-number-error").show()
		} else {
			if (this.state.cardNumber.match(cardNumberRegex.mc) || this.state.cardNumber.match(cardNumberRegex.visa)) {
				$("#card-number-icon").hide()
				$(".card-number-error").hide()
				$(".card-number-valid").show()
			} else {
				$("#card-number-icon").show()
				$(".card-number-error").hide()
			}
		}
		if (this.state.cardCvn && (!window.Xendit.card.validateCvn(this.state.cardCvn) || this.state.cardCvn.length < 3)) {
			$("#card-cvn-icon").hide()
			$(".card-cvn-error").show()
		} else {
			$("#card-cvn-icon").show()
			$(".card-cvn-error").hide()
		}
		if (this.state.cardExpMonth && !window.Xendit.card.validateExpiry(this.state.cardExpMonth.split("/")[0], "20"+this.state.cardExpMonth.split("/")[1])) {
			$(".card-expiry-error").show()
		} else {
			$(".card-expiry-error").hide()
		}
	}

	getCardType = () => {
		if (this.state.cardNumber.match(cardNumberRegex.mc) && window.Xendit.card.validateCardNumber(this.state.cardNumber)) {
			return "mastercard"
		}
		if (this.state.cardNumber.match(cardNumberRegex.visa) && window.Xendit.card.validateCardNumber(this.state.cardNumber)) {
			return "visacard"
		}
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<Fragment>
				<div>
					<TitleBar
						title="New Bank Card"
						leftIcon={backButton}
						handleClickLeftIcon={(e) => {
							if (!_.isNull(this.props.common.pathName)) {
								this.props.dispatch(push(this.props.common.pathName));
							} else {
								this.props.dispatch(navigateToAccountBilling());
							}
						}}
					/>
					<div className="add-xendit-card">
						{/*<p className="tip">💡 Only Visa or Mastercard prepaid, debit, and credit bank cards can be accepted at the moment</p>*/}
						{/*<h6 className="title">Information On Card</h6>*/}
						<div className="form-wrapper">
							<form role="form" id="payment-form" method="POST" >
								<div className="field-wrapper">
									<label htmlFor="card-number">Card number</label>
									<div className="input-container">
										<input id="card-number" data-fieldtype="encryptedCardNumber" type="tel" inputMode="numeric" pattern="[0-9\s]{13,19}" maxLength="24" autoComplete="cc-number" placeholder="1234 5678 9012 3456" aria-label="Card number field" aria-invalid="true" aria-required="true" aria-describedby="ariaErrorField"
											   placeholder="1234 5678 9012 3456" name="cardNumber" value={this.state.cardNumber} onChange={this.onCardInfoChange} />
										<img id="card-number-icon" src={cardNo} />
										<img className="card-number-valid" style={{ display: "none" }} src={cardType[this.getCardType()]} />
										<img className="card-number-error" style={{ display: "none" }} src={fieldError} />
									</div>
									<span className="card-number-error label-text-error" style={{ display: "none" }}>Invalid card number</span>
								</div>
								<div className="field-batch">
									<div className="field-wrapper">
										<label htmlFor="card-exp-month"><span>Expiry </span>date</label>
										<div className="input-container">
											<input type="text" id="card-exp-month"
												   placeholder="MM/YY" name="cardExpMonth" maxLength="5" value={this.state.cardExpMonth} onChange={this.onCardInfoChange} />
											<img className="card-expiry-error" style={{ display: "none" }} src={fieldError} />
										</div>
										<span className="card-expiry-error label-text-error" style={{ display: "none" }}>Invalid expiry date</span>
									</div>
									<div className="field-wrapper">
										<label htmlFor="card-cvn">CVC / CVV</label>
										<div className="input-container">
											<input type="text" id="card-cvn"
												   placeholder="3 digits" name="cardCvn" maxLength="4" value={this.state.cardCvn} onChange={this.onCardInfoChange} />
											<img id="card-cvn-icon" src={cardCvn} />
											<img className="card-cvn-error" style={{ display: "none" }} src={fieldError} />
										</div>
										<span className="card-cvn-error label-text-error" style={{ display: "none" }}>Invalid cvv number</span>
									</div>
								</div>
								<div className="field-wrapper name-on-card">
									<label htmlFor="name-on-card">Name on card</label>
									<div className="input-container">
										<input type="text" id="name-on-card"
											   placeholder="Janina San Miguel" name="cardName"
											   value={this.state.cardName} onChange={
											(e) => this.setState({"cardName": e.target.value})} />
										<img style={{ display: "none" }} src={fieldError} />
									</div>
								</div>
							</form>
						</div>
						<button id="save-bank-card" onClick={this.onAddCard} className="submit adyen-checkout__button adyen-checkout__button--pay" type="button">
							<span className="adyen-checkout__button__content">
								<span className="adyen-checkout__button__text">Save bank card</span>
							</span>
						</button>
					</div>

					<BottomSheet
						//classes="confirmation-bottom-sheet"
						isOpen={this.props.chat.transfer3DsBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(toggleTransfer3DsBottomSheet(false))
								$('#payment-form').show();
								$('#save-bank-card').show();
							};
						}}
					>
						<iframe height="1000" width="100%" id="sample-inline-frame" name="sample-inline-frame"> </iframe>
					</BottomSheet>
				</div>
				{this.props.errorPayment ? (
					<BillingErrorModal
						edit={(e) => {
							this.props.dispatch(billingUnSetErrorPayment());
						}}
						another={(e) => {
							this.props.dispatch(billingUnSetErrorPayment());
							this.props.dispatch(navigateToAccountBilling());
						}}
					/>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    ...state.billing,
	chat: state.chat,
	common: state.common
});

export default connect(mapStateToProps)(BillingAddCard);