import UAParser from 'ua-parser-js';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Fingerprint2 from "fingerprintjs2"


import { localStorageSetDeviceId } from '../functions/local-storage';

/**
 * Generate fingerprint hash code
 * @param {*} callback 
 */
export function fingerprint(callback) {
    // const uuid = uuidv4()
    // localStorageSetDeviceId(uuid);
    // callback(uuid);
    setTimeout(function () {
        const uuid = uuidv4()
        localStorageSetDeviceId(uuid);
        callback(uuid);
        // Fingerprint2.get(function (components) {
        //     var values = components.map(function (component) { return component.value })
        //     let hash = Fingerprint2.x64hash128(values.join(''), 31);
        //     localStorageSetDeviceId("73632622e7c65e0efecbe573abf2auiy");
        //     callback("73632622e7c65e0efecbe573abf2auiy");
        // })
        // callback(Fingerprint2.x64hash128(process.env.REACT_APP_ONE_SIGNAL_ID))
    }, 500);
}

export function deviceParser(getState, player_id, fcmToken, callback) {
    let parser = new UAParser();
    let result = parser.getResult();

    fingerprint((id) => {
        let obj = {
            id,
            userType: 'cwa',
            oneSignalId: player_id !== 'Unknown' ? player_id : 'Unknown',
            fcmToken: fcmToken !== 'Unknown' ? fcmToken : 'Unknown',
            userId: (!_.isEmpty(getState().common.client) && `${getState().common.client.id}`) || 'Unknown',
            os: result.os.name + '/' + result.browser.name,
            osVersion: result.os.version + '/' + result.browser.version,
            brand: result.device.vendor || "Unknown",
            model: result.device.model || "Unknown",
            buildNum: 40209
        }
        callback(obj);
    })
}

export function fcmDeviceParser(getState, fcmToken, callback) {
    let parser = new UAParser();
    let result = parser.getResult();

    fingerprint((id) => {
        let obj = {
            id,
            userType: 'cwa',
            oneSignalId: 'Unknown',
            fcmToken: fcmToken !== 'Unknown' ? fcmToken : 'Unknown',
            // userId: (!_.isEmpty(getState().common.client) && `${getState().common.client.id}`) || 'Unknown',
            os: result.os.name + '/' + result.browser.name,
            osVersion: result.os.version + '/' + result.browser.version,
            brand: result.device.vendor || "Unknown",
            model: result.device.model || "Unknown",
            buildNum: 40212
        }
        callback(obj);
    })
}