import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "connected-react-router";

import TitleBar from "../../../organisms/title-bar";
import ArticleSkeleton from "../../ui-elements/Skeleton/ArticleSkeleton";
import closeButton from "../../../v5-icons/close-thin.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";
import Moment from "../../moment/moment";
import { fetchArticle, willUnmountArticle, appGoBackNews } from "../../../utils/redux/actions/newsActions";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";

class NewsDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			innerWidth: null,
		};

		this.onBack = this.onBack.bind(this);
	}

	componentDidMount() {
		if (performance.navigation.type === 2) {
			if (this.props.history.action === "POP") {
				this.props.dispatch(push("/news"));
			}
		}

		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		this.props.dispatch(fetchArticle(this.props.match.params.id));
	}

	componentWillUnmount() {
		this.props.dispatch(willUnmountArticle());
	}

	/**
	 * Go back to news list
	 * @param {*} e
	 */
	onBack(e) {
		this.props.history.goBack();
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	render() {
		let news = this.props.news;
		const titleBar = (
			<TitleBar
				leftIcon={closeButton}
				handleClickLeftIcon={() => this.props.dispatch(appGoBackNews())}
				handleClickRightIcon={this.onSupport}
				rightIcon={chatSupport}
			/>
		);
		return (
			<Fragment>
				<div>{titleBar}</div>

				{this.props.detailNewsLoading ? (
					<div className="detail-news-loading">
						<ArticleSkeleton
							devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
						/>
					</div>
				) : !_.isEmpty(news) ? (
					<div className="News_Detail">
						<div className="Title_Date_Box">
							<h3>{news.title}</h3>
							<p>
								<Moment>{news.dateTime}</Moment>
							</p>
						</div>

						<div className="col-xs-12 m-t-20 m-b-20">
							<img className="img-responsive News_Detail_Thumbnail" src={news.thumbnailImage} alt="" />
						</div>
						<div className="col-xs-12 m-b-70">
							<p className="News_Detail_Body">{news.body}</p>
						</div>
					</div>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	news: state.news.article,
	detailNewsLoading: state.news.detailNewsLoading,
});

export default connect(mapStateToProps)(NewsDetail);