import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import './location-search-saved-place-slider.css';
import LocationSearchSavedPlaceBox from './location-search-saved-place-box';
import { ReactComponent as HomeIcon } from '../../../assests/images/places/home.svg';
import { ReactComponent as WorkIcon } from '../../../assests/images/places/work.svg';
import {SAVED_PLACE_HOME_TYPE, SAVED_PLACE_WORK_TYPE} from '../../../utils/redux/actions/savedPlacesActions';

function LocationSearchSavedPlaceSlider (props) {

    return (
        <Fragment>
            <div className="col-xs-12 m-t-15 m-b-10">
                <div className="Location-search-saved-place-slider">
                    {props.loading ? '' : 
                        <Fragment>
                            <LocationSearchSavedPlaceBox
                                title={_.isNull(props.savedHome) ? "Add Home" : "Home"}
                                onClick={(e) => props.onClick(e, props.savedHome, SAVED_PLACE_HOME_TYPE)}
                                icon={<HomeIcon className="Home-icon"/>}/>
                            <LocationSearchSavedPlaceBox
                                title={_.isNull(props.savedWork) ? "Add Work" : "Work"}
                                onClick={(e) => props.onClick(e, props.savedWork, SAVED_PLACE_WORK_TYPE)}
                                icon={<WorkIcon className="Work-icon"/>}/>
                        </Fragment>
                    }
                    
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    ...state.savedPlaces,
    loading: state.common.loading
});

export default connect(mapStateToProps)(LocationSearchSavedPlaceSlider);