import $ from 'jquery';
import _ from 'lodash';

import {BOOKING_OPTIONS_FORM_ID} from '../redux/actions/bookingActions';
import { getReviewBookingLocalStorage, getShopIdStorage } from "./local-storage";
/**
 * Create object for sending in body of post request in booking Request Now
 * @param {*} getState 
 * @param {*} locations 
 */
export function createReqeustObj(getState, locations, deliveringPlace) {
	let objRequest = {};
	const product = getState().booking.product;
	objRequest.cachedPriceId = getState().booking.prices.cachedPriceId.toString();
	objRequest.productId = product.id;
	objRequest.cubeNumber = getState().booking.prices.minCubeNumbers;
    if(!_.isUndefined(deliveringPlace)) {
        if (deliveringPlace?.byMap) {
            if (getReviewBookingLocalStorage()) {
                objRequest.jobLocations = addNameToLocations([...locations.jobLocations]);
            } else {
                objRequest.jobLocations = addNameToLocations([...locations.jobLocations, ...getState().map.googlePlaces]);
            }
        } else {
            if (getReviewBookingLocalStorage()) {
                objRequest.jobLocations = addDetailsToLocation([...locations.jobLocations], deliveringPlace);
            } else {
                objRequest.jobLocations = addDetailsToLocation([...locations.jobLocations, ...getState().map.googlePlaces], deliveringPlace);
            }
        }
    } else {
        if (getReviewBookingLocalStorage()) {
			objRequest.jobLocations = addNameToLocations([...locations.jobLocations]);
		} else {
			objRequest.jobLocations = addNameToLocations([...locations.jobLocations, ...getState().map.googlePlaces]);
		}
    }
    if(_.isUndefined(deliveringPlace)) objRequest.note = getState().booking.note;
	objRequest.voucherCode = getState().booking.promoCode;
	if (Number(getShopIdStorage())) {
		objRequest.exclusiveServiceProviderId = !_.isNull(getState().home.shopInfo)
			? Number(getState().home.shopInfo.id)
			: Number(getShopIdStorage());
	}

	return objRequest;
}

function addNameToLocations(jobLocations) {
    return jobLocations.map((location) => {
        
		return {
			address: location.address,
			lat: location.lat,
			lng: location.lng,
			name: location.title,
			title: location.title
		}
    });
}

function addDetailsToLocation(jobLocations, deliveringPlace) {
    if (deliveringPlace.note?.length) {
		return jobLocations.map((location) => {
			return {
				address: location.address,
				lat: location.lat,
				lng: location.lng,
				name: location.name,
				title: location.title,
				details: location.data,
				note: deliveringPlace.note,
				phoneCall: deliveringPlace.phoneCall,
			};
		});
    } else {
        //Avoid to send note if is empty
        return jobLocations.map((location) => {
			return {
				address: location.address,
				lat: location.lat,
				lng: location.lng,
				name: location.name,
				title: location.title,
				details: location.data,
				phoneCall: deliveringPlace.phoneCall,
			};
		});
    }
}
/**
 * Handle options of product request
 */
function makeOptionsObject() {
    let options = [];
    let dom = $(BOOKING_OPTIONS_FORM_ID);
    if (dom) {
        options = $(BOOKING_OPTIONS_FORM_ID).serializeArray().map((option, index) => {
            return {
                title: option.value
            }
        });
    }
    
    return options;
}

export const createPlaceObjectForFetchingPrice = (obj) => {
    return {
        lat: obj.lat,
        lng: obj.lng,
        address: obj.address ? obj.address : obj.name
    }
}

export function createScheduledObj(getState, dueDate, locations, deliveringPlace) {
	let objRequest = {};
	const product = getState().booking.product;
	objRequest.cachedPriceId = getState().booking.prices.cachedPriceId.toString();
	objRequest.productId = product.id;
	objRequest.cubeNumber = getState().booking.prices.minCubeNumbers;
    if (!_.isUndefined(deliveringPlace)) {
		if (getReviewBookingLocalStorage()) {
			objRequest.jobLocations = addDetailsToLocation([...locations.jobLocations], deliveringPlace);
		} else {
			objRequest.jobLocations = addDetailsToLocation([...locations.jobLocations, ...getState().map.googlePlaces], deliveringPlace);
		}
	} else {
		if (getReviewBookingLocalStorage()) {
			objRequest.jobLocations = addNameToLocations([...locations.jobLocations]);
		} else {
			objRequest.jobLocations = addNameToLocations([...locations.jobLocations, ...getState().map.googlePlaces]);
		}
	}
	objRequest.note = getState().booking.note;
	objRequest.voucherCode = !_.isNull(getState().booking.promoCode) ? getState().booking.promoCode : getState().booking.tempPromoCode;
	objRequest.dueDate = dueDate;
	if (Number(getShopIdStorage())) {
		objRequest.exclusiveServiceProviderId = !_.isNull(getState().home.shopInfo)
			? Number(getState().home.shopInfo.id)
			: Number(getShopIdStorage());
	}

	return objRequest;
}
