import React, {Component} from "react";
import ReactDOM from "react-dom";

export default class ContentEditable extends Component {
	getDOMNode() {
		return ReactDOM.findDOMNode(this);
	}

	emitChange() {
		var html = this.getDOMNode().innerHTML;
		this.lastHtml = html;
	}
	render() {
		return (
			<div
				id="contenteditable"
				onInput={this.emitChange}
				onBlur={this.emitChange}
				className={this.props.className}
				contentEditable={!this.props.readOnly}
				dangerouslySetInnerHTML={{ __html: this.props.html }}
                onClick={this.props.handleClick}
			></div>
		);
	}
};
