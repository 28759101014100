import React, { Fragment } from "react";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";

const classNames = require("classnames");
const BottomSheet = (props) => {
	return (
		<Fragment>
			<SwipeableBottomSheet
				open={props.isOpen}
				style={props.styles}
				topShadow={props.config && props.config.topShadow}
				overlay={props.config && props.config.overlay}
				overflowHeight={props.config && props.config.overflowHeight}
				fullScreen={props.config && props.config.fullScreen}
				marginTop={props.config && props.config.marginTop}
				shadowTip={false}
				onChange={props.onChangeOverlay}
			>
				<div className={classNames("Bottom_Sheet", props.classes)}>
					<div className="Sheet_Content">
						{props.indicator && (
							<div className="indicator-container">
								<div className="indicator"></div>
							</div>
						)}
						{props.children}
					</div>
				</div>
			</SwipeableBottomSheet>
		</Fragment>
	);
};

export default BottomSheet;