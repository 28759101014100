import React, {Fragment} from 'react';
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

import './job-chat-history-attach.css';

export default function JobChatHistoryAttach(props) {

    return (
		<Fragment>
			<div className="Job-chat-history-actions no-padding text-center pull-right">
				<button onClick={props.onAttach} className="Job-chat-history-emoji-button" type="button">
					<DynamicIcon icon="Gallery" classes="Group_Chat_History" />
				</button>
				<input type="file" ref={props.inputFileRef} accept="image/*" onChange={props.inputFileChange} className="hidden" />
			</div>
		</Fragment>
	);
}