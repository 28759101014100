import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as LOG_EVENT from "../../../utils/analytics";

import { backToHme, clickService } from "../../../utils/redux/actions/homeActions";
import { push } from "connected-react-router";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";

import backButton from "../../../v5-icons/back-button.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";

import TitleBar from "../../../organisms/title-bar";
import HomeSearchProducts from "../HomeSearch/HomeSearchProducts/HomeSearchProducts";
import {localStorageIsTwa} from "../../../utils/functions/local-storage"
class HomeSubCategories extends Component{
	state = {
		highLightText: [], 
		category: null
	};
	constructor(props){
		super(props);
		this.onNavigateToService = this.onNavigateToService.bind(this);

	}
	componentDidMount() {
		if(!_.isNull(this.props.showInHomeCategories)) {
			let mappedProduct = [];
			this.props.showInHomeCategories.map((service) => {
				service.subCategories.map((subCategory) => {
					if (subCategory.id === +this.props.match.params.productsId) {
						mappedProduct.push(subCategory);
					}
					return mappedProduct;
				});
			});
			this.setState(() => ({ category: mappedProduct[0] }));
		} else {
			this.props.dispatch(push("/home"))
		}
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};
	
	goBack = () => {
		this.props.dispatch(backToHme());
	};

	onNavigateToService( e, service ) {
		let setTime = setTimeout(() => {
			if (!_.isNull(service.mode)) {
				LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
					os: localStorageIsTwa() ? "twa" : "web",
					service: service.mode.includes("MY_MALL") ? "mymall" : "mykuya",
				});
			} else {
				LOG_EVENT.logEvent(LOG_EVENT.SERVICE_SELECTED_CWA, {
					os: localStorageIsTwa() ? "twa" : "web",
					service: "mykuya",
				});
			}
			this.props.dispatch(clickService(service));
			clearTimeout(setTime);
		}, 300);
	}

	render() {
		return(
			<Fragment>
				{!_.isNull(this.state.category) ? (
					<Fragment>
						<div className="Home_Sub_Categories">
							<TitleBar
								leftIcon={backButton}
								rightIcon={chatSupport}
								handleClickLeftIcon={this.goBack}
								handleClickRightIcon={this.onSupport}
							/>
						</div>
						<div className="Sub_Category_Parent">
							{!_.isUndefined(this.state.category.icon) && <div className="H_S_P_Icon">
								<img src={this.state.category.icon} alt="ProductIcon" />
							</div>}
							<div className="H_S_P_Text">
								<h3>{this.state.category.title}</h3>
							</div>
						</div>
						<div className="Home_Product_Box Sub_Parent_Product">
							{this.state.category.products.length ?
								<Fragment>
									{this.state.category.products.map((item, index) => {
										return <HomeSearchProducts key={index} product={item}
																   classes="S_P_P_Product"
																   highLightText={this.state.highLightText}
																   onNavigateToService={this.onNavigateToService}/>;
									})}


								</Fragment>: ''}

						</div>
					</Fragment>
				) : null}

			</Fragment>
		)
	}

}


const mapStateToProps = state => ({
	...state.home,
	common: state.common,
	map: state.map
});
export default connect(mapStateToProps)(HomeSubCategories);