import UAParser from 'ua-parser-js';

const ADD_TO_HOME_SCREEN_BROWSER = ['Chrome', 'Safari', 'Firefox'];

export function checkBrowser() {

    let parser = new UAParser();
    return ADD_TO_HOME_SCREEN_BROWSER.includes(parser.getResult().browser.name.replace('Mobile', '').trim());
}

export function getBrowser() {
    
    let parser = new UAParser();
    return parser.getResult().browser.name.replace('Mobile', '').trim();
}

export function getOS() {
	let parser = new UAParser();
	return parser.getOS().name.trim();
}

export function isFirefox() {
    let parser = new UAParser();
    return ['firefox'].includes(parser.getResult().browser.name.replace('Mobile', '').toLowerCase().trim());
}

export function isSafari() {
    let parser = new UAParser();
    return ['Safari'].includes(parser.getResult().browser.name.replace('Mobile', '').trim());
}

export function isChrome() {
    let parser = new UAParser();
    return ['chrome'].includes(parser.getResult().browser.name.replace('Mobile', '').toLowerCase().trim());
}

export function isSamsungBrowser() {
	let parser = new UAParser();
	return ["samsung"].includes(parser.getResult().browser.name.replace("Browser", "").toLowerCase().trim());
}

export function isIOS() {
    let parser = new UAParser();
    return ['ios'].includes(parser.getOS().name?.toLowerCase().trim())
}

export function isAndroidBrowser() {
    let parser = new UAParser();
    return ['android'].includes(parser.getResult().browser.name.replace('Mobile', '').toLowerCase().trim())
}

export function isBot() {
    let parser = new UAParser();
    const browserName = parser.getResult().browser.name.replace("Mobile", "").toLowerCase().trim();
    return browserName.includes('Bot');
}