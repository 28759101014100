import React, { Fragment } from "react";
import Highlighter from "react-highlight-words";
const HomeSearchProducts = (props) => {
	return (
		<Fragment>
			<div className="Home_Search_Product" onClick={(e) => props.onNavigateToService(e, props.product, "search")}>
				<div className="H_S_P_Icon">
					<img src={props.product.icon} alt="productIcon" />
				</div>

				<div className={props.classes}>
					<Highlighter
						highlightClassName="High_Lighted"
						searchWords={props.highLightText}
						autoEscape={true}
						textToHighlight={props.product.commercialName}
					/>
				</div>

			</div>
		</Fragment>
	);
};
export default HomeSearchProducts;