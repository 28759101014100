import _ from 'lodash';
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import * as DISPATCH_STATE from './newsChangeState';
import { GraphRequest } from '../../../../axios';
import { appFetchFailure, validURL } from "../commonActions";

/**
 * Get news from server for news page for first time of loading
 */
export function fetchNews() {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.fetchNewsBegin());
    dispatch(DISPATCH_STATE.newsLoading(true));
    const getNewsRequestBody = {
		query: `query Announcements($offset: Int, $limit: Int, $isWhatsNew: Boolean) {
          announcements(offset: $offset, limit: $limit, isWhatsNew: $isWhatsNew) {
            id
            title
            body
            thumbnailImage
            dateTime
          }
        }`,
		variables: {
			offset: 0,
			// "isWhatsNew": true,
		},
	};
  GraphRequest.all(getNewsRequestBody)
      .then(json => {
        if (!_.isNull(json.data.data) && json.data.data.announcements) {
           const { announcements } = json.data.data;
          dispatch(DISPATCH_STATE.newsLoading(false));
          if (announcements) {
            announcements.forEach((announcement) => {
              if (validURL(announcement.body)) {
                announcement.hasUrl = true;
              } else {
                announcement.hasUrl = false;
              }
            });
          }
          dispatch(DISPATCH_STATE.fetchNewsSuccess(announcements));
        } else {
          dispatch(appFetchFailure(json.data.errors[0].message));
          toast(`${json.data.errors[0].message}`, {
            position: "bottom-center",
            autoClose: 2000,
            limit: 1,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
            hideProgressBar: true,
            closeOnClick: false,
          });
          dispatch(DISPATCH_STATE.newsLoading(false));
        }
      })
      .catch(error => dispatch(appFetchFailure(error)));
  };
}
/**
 * Fetch Article
 * @param {*} id 
 */
export function fetchArticle(id) {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.fetchNewsArticleBegin());
    if (!_.isEmpty(getState().news.article) && getState().news.article.id === Number(id)) {
      dispatch(DISPATCH_STATE.fetchNewsArticleSuccess(getState().news.article));
    } else {
      const getNewsDetailRequestBody = {
        query: `query AnnouncementDetail($id: Int!) {
          announcement(announcementId: $id) {
            id
            title
            body
            thumbnailImage
            dateTime
          }
        }`,
        variables: {
          "id": Number(id)
        }
      }
      dispatch(DISPATCH_STATE.detailNewsLoading(true));
      GraphRequest.all(getNewsDetailRequestBody)
        .then(json => {
          dispatch(DISPATCH_STATE.detailNewsLoading(false));
          if (!_.isNull(json.data.data) && json.data.data.announcement) {
            dispatch(DISPATCH_STATE.fetchNewsArticleSuccess(json.data.data.announcement));
          } else {
            dispatch(appFetchFailure(json.data.errors[0].message));
            toast(`${json.data.errors[0].message}`, {
              position: "bottom-center",
              autoClose: 2000,
              limit: 1,
              className: "toast-rejected-payment",
              bodyClassName: "toastify-inner",
              hideProgressBar: true,
              closeOnClick: false,
            });
          }
        })
        .catch(err => {
          dispatch(DISPATCH_STATE.detailNewsLoading(false));
			    dispatch(appFetchFailure(err.response.data.errors[0].message));
          toast(`${err.response.data.errors[0].message}`, {
            position: "bottom-center",
            autoClose: 2000,
            limit: 1,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
            hideProgressBar: true,
            closeOnClick: false,
          });
        });
    }
  };
};

// navigate from home
export const setNewsLocationPointer = () => {
  return (dispatch, getState) => {
    dispatch(DISPATCH_STATE.newsSetSearchLocationPointer(getState().router.location.pathname));
  }
};

/**
 * When go back news
 */
export function appGoBackNews() {
  return (dispatch, getState) => {
    if (getState().news.searchLocationPointer) {
      dispatch(push(getState().news.searchLocationPointer));
      dispatch(DISPATCH_STATE.newsSetSearchLocationPointer(null));
    } else {
      dispatch(push("/news"));
    }
  }
}