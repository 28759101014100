import React, { Component } from "react";
import { connect } from "react-redux";

import CustomButton from "../../../../ui-elements/CustomButton/CustomButton";
class DeletePaymentErrorBottomSheet extends Component {
    render() {
        return (
            <div className="error-text-container">
                <p>{this.props.errorText}</p>
                <CustomButton title="Got It" onClick={this.props.handleClickGotItButton} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state,
    common: state.common,
});

export default connect(mapStateToProps)(DeletePaymentErrorBottomSheet);
