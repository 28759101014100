export const INIT_MESSAGES_FROM_JOB_CHAT_HISTORY = 'INIT_MESSAGES_FROM_JOB_CHAT_HISTORY'; // GET MESSAGES NOT ACTIVATED FROM JOB OBJECT
export const CHAT_SCROLL_TO_BOTTOM = 'CHAT_SCROLL_TO_BOTTOM';
export const CHAT_ON_JOB_DETAIL_NAVIGATION = 'CHAT_ON_JOB_DETAIL_NAVIGATION'; // when user click on job detail in option menu
export const CHAT_ON_LIVE_LOCATION_PARTNER = 'CHAT_ON_LIVE_LOCATION_PARTNER'; // when user click on live location in option menu
export const CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE = 'CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE'; // when user click on live location back icon
export const CHAT_ON_CALL_PARTNER = 'CHAT_ON_CALL_PARTNER'; // when user click on call partner in option menu
export const CHAT_ON_EXTEND_JOB_CHANGE_STATE = 'CHAT_ON_EXTEND_JOB_CHANGE_STATE'; // when user click on extend job in option menu chat page
export const CHAT_ON_NEVERMIND_CANCEL = 'CHAT_ON_NEVERMIND_CANCEL'; // when user click on cancel job in option menu chat page
export const CHAT_NOTE_TO_PARTNER = 'CHAT_NOTE_TO_PARTNER'; // when user click on cancel job in option menu chat page
export const REQUEST_JOB_CANCEL_BEGIN = 'REQUEST_JOB_CANCEL_BEGIN'; // Before start to send request cancel job to server in chat area
export const REQUEST_JOB_CANCEL_SUCCESS = 'REQUEST_JOB_CANCEL_SUCCESS'; // after request send to server and cancel job in chat area
export const REQUEST_JOB_CANCEL_REASONS_BEGIN = 'REQUEST_JOB_CANCEL_REASONS_BEGIN'; // Before start to send request cancel job reasons to server
export const REQUEST_JOB_CANCEL_REASONS_SUCCESS = 'REQUEST_JOB_CANCEL_REASONS_SUCCESS'; // after request send to server for getting cancel job reasons
export const CHAT_ON_PHOTO_MESSAGE = 'CHAT_ON_PHOTO_MESSAGE';
export const CHAT_ON_CLOSE_PHOTO_MESSAGE = 'CHAT_ON_CLOSE_PHOTO_MESSAGE';
export const CHAT_WILL_UNMOUNT = 'CHAT_WILL_UNMOUNT'; // chat will unmount
export const CHAT_HISTORY_SCROLL_BUTTON = '#Job-chat-history-scroll'; // chat history scroll button id

export const TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_LOCATION_PARTNER = 5000; // time for interval request 
export const CHAT_ON_ATTACH_FILE_MAX_SIZE = 10000000; // time for interval request 
export const CHAT_ON_ATTACH_FILE_MAX_SIZE_ERROR = "The image is too large. Please upload a file less than 10MB."; // time for interval request 


export const INIT_MESSAGES_DISPATCH = "INIT_MESSAGES_DISPATCH";
export const INIT_INTERVAL_GET_MESSAGE = "INIT_INTERVAL_GET_MESSAGE";
export const GET_MESSAGE_WILL_UNMOUNT_DISPATCH = "GET_MESSAGE_WILL_UNMOUNT_DISPATCH";
export const SET_LAST_INDEX = "SET_LAST_INDEX";
export const SET_OFFSET = "SET_OFFSET";
export const SET_LIMIT = "SET_LIMIT";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const UPDATE_MESSAGE_LIST = "UPDATE_MESSAGE_LIST";
export const SEND_MESSAGE_BEGIN = "SEND_MESSAGE_BEGIN";
export const PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY = "PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY";
export const CHAT_ON_ATTACH_FILE_NEW_SERVICE_DISPATCH = "CHAT_ON_ATTACH_FILE_NEW_SERVICE_DISPATCH";
export const FETCH_MESSAGES_BEGIN = "FETCH_MESSAGES_BEGIN";
export const FETCH_MESSAGES_END = "FETCH_MESSAGES_END";
export const EMPTY_NEW_MESSAGES = "EMPTY_NEW_MESSAGES";
export const SET_LAST_CONSUME_INDEX = "SET_LAST_CONSUME_INDEX";
export const SET_JOB_CANCEL_REASONS = "SET_JOB_CANCEL_REASONS";


export const TIME_FOR_INTERVAL_REQUEST_FOR_NEW_MESSAGE = 5000; // set time for interval request to check new message

export const CHECK_VOIP_IS_SUPPORTED_BEGIN = "CHECK_VOIP_IS_SUPPORTED_BEGIN";
export const CHECK_VOIP_IS_SUPPORTED_END = "CHECK_VOIP_IS_SUPPORTED_END";
export const GENERATE_PEERS_TOKEN_BEGIN = "GENERATE_PEERS_TOKEN_BEGIN";
export const GENERATE_PEERS_TOKEN_SUCCESSFUL = "GENERATE_PEERS_TOKEN_SUCCESSFUL";
export const INIT_PEERS_TOKEN = "INIT_PEERS_TOKEN";
export const SET_ROOM_INFO = "SET_ROOM_INFO";
export const PARTICIPANT_JOINED_TO_ROOM = "PARTICIPANT_JOINED_TO_ROOM";
export const BACK_TO_CHAT_HISTORY = "BACK_TO_CHAT_HISTORY";
export const TOGGLE_VOICE_ON_VIDEO_CHAT = "TOGGLE_VOICE_ON_VIDEO_CHAT";
export const TOGGLE_VIDEO_ON_VIDEO_CHAT = "TOGGLE_VIDEO_ON_VIDEO_CHAT";
export  const SET_TOKEN_TYPE_FOR_VOIP = "SET_TOKEN_TYPE_FOR_VOIP";
export const ADD_PARTNER_INFO = "ADD_PARTNER_INFO";
export const PARTNER_JOINED = "PARTNER_JOINED";
export const PARTNER_NOT_JOINED = "PARTNER_NOT_JOINED"
export const END_CALL = "END_CALL";
export const BACK_TO_CHAT_HISTORY_MENU_PAGE = "BACK_TO_CHAT_HISTORY_MENU_PAGE";
export const BACK_TO_HISTORY_TRANSACTION_PAGE = "BACK_TO_HISTORY_TRANSACTION_PAGE";
export const SET_AMOUNT_OF_REQUEST_MK_FORM = "SET_AMOUNT_OF_REQUEST_MK_FORM";
export const SET_AMOUNT_OF_SEND_MK_FORM = "SET_AMOUNT_OF_SEND_MK_FORM";
export const CANNOT_REQUEST_MK = "CANNOT_REQUEST_MK";
export const CONFIRM_SEND_MK = "CONFIRM_SEND_MK";
export const REFUSE_SEND_MK = "REFUSE_SEND_MK";
export const SENT_MK_TRANSACTION_HISTORY = "SENT_MK_TRANSACTION_HISTORY";
export const RECIEVED_MK_TRANSACTION_HISTORY = "RECIEVED_MK_TRANSACTION_HISTORY";
export const TOGGLE_TRANSFER_MK_NOTE = "TOGGLE_TRANSFER_MK_NOTE";
export const UN_MOUNT_SENT_RECIEVE_MK_TRANSACTIONS = "UN_MOUNT_SENT_RECIEVE_MK_TRANSACTIONS";
export const BACK_TO_TRANSACTIONS_HISTORY = "BACK_TO_TRANSACTIONS_HISTORY";
export const TOGGLE_TRANSFER_MK_CONFIRMATION = "TOGGLE_TRANSFER_MK_CONFIRMATION";
export const TOGGLE_COPY_CALL_BOTTOM_SHEET = "TOGGLE_COPY_CALL_BOTTOM_SHEET";
export const CALL_TO_NUMBER_DISPATCH = "CALL_TO_NUMBER_DISPATCH";
export const MK_TRANSACTIONS_FETCHING = "MK_TRANSACTIONS_FETCHING";
export const SEND_MONEY_LOADING = "SEND_MONEY_LOADING";
export const CONFIRM_TRANSFER_IN_APP = "CONFIRM_TRANSFER_IN_APP";
export const TOGGLE_TRANSFER_3DS_BOTTOM_SHEET = "TOGGLE_TRANSFER_3DS_BOTTOM_SHEET";