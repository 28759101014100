import React from 'react';

import {
    isFirefox
} from '../../../utils/functions/check-browser';

const classNames = require('classnames');

export default function WelcomeSelectCountry(props) {

    const defaultCountry = {
        _id: "5c5c4ad426f970accd48d378",
        code: "+63",
        countryCode: "PH",
        icon: "🇵🇭",
        name: "Philippines",
        unicode: "U+1F1F5U+1F1ED"
    }

    return (
        <div className="form-group-container">
            <label>Country</label>
            <select onChange={props.onChange}
                ref={props.welcomeSelectCountryRef}
                className={classNames({
                    "welcome-select-country": true,
                    "welcome-select-country-firefox": isFirefox()
                })}>
                {
                    props.options instanceof Array
                        ?
                        props.options.map((country, index) => {
                            return <option value={index} key={index}>{country.icon} {country.code}</option>
                        })
                        :
                        <option value="0">{defaultCountry.icon} {defaultCountry.code}</option>
                }
            </select>
        </div>
    );
}