import React from "react"
import nnsImg from "../../assests/images/chat/notif-not-support.png"
import Button from "@material-ui/core/Button";
import { setNotifNotSupportLocalStorage } from "../../utils/functions/local-storage";

export const NotifNotSupport = (props) => {

	const dismissBottomSheet = () => {
		props.dismissBottomSheet();
		setNotifNotSupportLocalStorage();
	}

	const getApp = () => {
		setNotifNotSupportLocalStorage();
		window.location.href = "https://kuya.to/9w2u";
	}

	return <div className="nns-container">
		<div className="nns-title">Don't miss chat notifications!</div>
		<img src={nnsImg} alt="notification not support" />
		<div>This browser may have some limitations. Download mykuya app to receive chat notifications.</div>
		<div className="get-app">
			<Button type="button" onClick={getApp}>
				Get mykuya app
			</Button>
		</div>
		<div className="get-help"
			onClick={dismissBottomSheet}
		>
			{/*<ChatSupport />*/}
			<span>Accept risks & proceed</span>
		</div>
	</div>;
}