import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


import { jobInformationNavigate } from "../../../utils/redux/actions/jobsActions";
import { fetchJob } from "../../../utils/redux/actions/jobsActions/actions/jobs-detail";
import { setDefaultPaymentDetails, setDefaultCardDetail, setDefaultJobObject } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
import { appGoBack } from "../../../utils/redux/actions/commonActions";
import JobReceipt from "../JobReceipt/JobReceipt";
import JobChatHistory from "../JobChatHistory/JobChatHistory";
import JobPartnerRating from "./JobPartnerRating/JobPartnerRating";
import Avatar from "../../avatar/avatar";
import genderPatner from "../../../utils/functions/gender-partner";
import TitleBar from "../../../organisms/title-bar";

import backButton from "../../../v5-icons/back-button.svg";
import ArticleSkeleton from "../../ui-elements/Skeleton/ArticleSkeleton";
class JobInformation extends Component {
	constructor(props) {
		super(props);
		this.state = { innerWidth: null, innerHeight: null };
		this.onBack = this.onBack.bind(this);
		this.onLinkClick = this.onLinkClick.bind(this);
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		this.props.dispatch(fetchJob(this.props.match.params.id));
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return nextProps.job?.partner !== this.props.job?.partner
	}


	onLinkClick(e, link) {
		this.props.dispatch(jobInformationNavigate(link));
	}

	onBack() {
		this.props.dispatch(appGoBack("/jobs"));
		this.props.dispatch(setDefaultPaymentDetails());
		this.props.dispatch(setDefaultCardDetail());
		this.props.dispatch(setDefaultJobObject());
	}

	render() {
		const tabList = ["Job Receipt", "Partner Rating", "Chat History"];
		return _.isEmpty(this.props.job?.partner) ? (
			<div className="detail-news-loading">
				<ArticleSkeleton
					devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
				/>
			</div>
		) : (
			<div className="job-completed-container">
				<TitleBar leftIcon={backButton} title={this.props.job.product.commercialName} handleClickLeftIcon={this.onBack} />
				<div className="job-information-container">
					<Tabs selectedIndex={this.props.selectedIndex} selectedTabClassName={"segment-active"}>
						<TabList className="tabs-menu-segment">
							{tabList.map((item, idx) => (
								<Tab className="item-segment" key={idx}>
									{item}
								</Tab>
							))}
						</TabList>
						<div className="tabs-panel-container">
							<TabPanel panelclass="job-history">
								<JobReceipt job={this.props.job} jobId={this.props.match.params.id} />
							</TabPanel>
							<TabPanel panelclass="job-history">
								<JobPartnerRating job={this.props.job} />
							</TabPanel>
							<TabPanel panelclass="job-history">
								{!_.isNull(this.props.job.partner) ? (
									<div className="Job_Information_Partner">
										<div className="Section_1">
											<Avatar img={this.props.job.partner?.photo} />
											<div className="Job_Information_Section1_Right_Box">
												<h5 className="Partner_Name">
													{this.props.job.partner.title ? this.props.job.partner.title : genderPatner(this.props.job.partner)} {this.props.job.partner.givenName}{" "}
													{this.props.job.partner.lastName}
												</h5>
												<p className="Co_Partner_Name">
													{" "}
													{this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name}
												</p>
											</div>
										</div>
									</div>
								) : null}
								<JobChatHistory job={this.props.job} jobId={this.props.match.params.id} isChatHistory />
							</TabPanel>
						</div>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.jobs,
});

export default connect(mapStateToProps)(JobInformation);