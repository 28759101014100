import React from 'react';
import _ from 'lodash';

export default function ProfileFormSelectCountry(props) {
    let countries = '';
    let flag = '🇵🇭';
    if (props.options instanceof Array) {
        countries = props.options.map((country, index) => {
            return <option
                value={country.code}
                key={index}
                data-flag={country.icon} >{country.code}</option>;
        });
        flag = _.find(props.options, function(country) { return props.value === country.code })?.icon;
    }
    return (
        <React.Fragment>
            <div className="form-group-container">
                <label>Country</label>
                <div className="flag">
                    <span className="flag-box">{flag}</span>
                    <select
                        style={{ 'paddingLeft': props.paddingleft }}
                        ref={props.profileref}
                        {...props}
                        className="form-control Profile-form-select-country" >
                        {countries}
                    </select>
                </div>
            </div>
        </React.Fragment>
    );
}