import * as CONSTANT_ACTIONS from './billingConstant';

export const fetchPaymentMethodSuccess = (methods) => ({
    type: CONSTANT_ACTIONS.FETCH_PAYMENT_METHOD_SUCCESS,
    payload: methods
})
export const billingPaymentMethodEmpty = () => ({
    type: CONSTANT_ACTIONS.BILLING_PAYMENT_METHOD_NULL,
})

export const fetchAccountingBalanceSuccess = (balance) => ({
    type: CONSTANT_ACTIONS.FETCH_ACCOUNTING_BALANCE_SUCCESS,
    payload: balance
})

export const billingDeletePaymentMethod = (methods) => ({
    type: CONSTANT_ACTIONS.BILLING_DELETE_PAYMENT_METHOD,
    payload: methods
})

export const billingErrorPayment = (message = null) => ({
    type: CONSTANT_ACTIONS.BILLING_ERROR_PAYMENT,
    payload: message
})
  
export const billingUnSetErrorPayment = () => ({
    type: CONSTANT_ACTIONS.BILLING_UN_SET_ERROR_PAYMENT
})

export const billingOnAddPaymentMethodsInChoosePage = () => ({
    type: CONSTANT_ACTIONS.BILLING_ON_ADD_PAYMENT_METHODS_IN_CHOOSE_PAGE
})

export const billingSetAdyen = (ady) => ({
    type: CONSTANT_ACTIONS.BILLING_SET_ADYEN,
    payload: ady
});

export const toggleBillingTopUpBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_BILLING_TOP_UP_BOTTOM_SHEET,
});

export const billingTopUpMyKoin = (condition) => ({
    type: CONSTANT_ACTIONS.BILLING_TOP_UP_MYKOIN,
    payload: condition
});

export const setNewAccountBalance = (balanceAmount) => ({
    type: CONSTANT_ACTIONS.SET_NEW_ACCOUNT_BALANCE,
    payload: balanceAmount
});

export const deletePaymentErrorText = (error) => ({
    type: CONSTANT_ACTIONS.DELETE_PAYMENT_ERROR_TEXT,
    payload: error
});

export const setDefaultValueOfDeletePaymentError = () => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_VALUE_OF_DELETE_PAYMENT_ERROR
});

export const setClientBalances = (data) => ({
    type: CONSTANT_ACTIONS.SET_CLIENT_BALANCES,
    payload: data
});

export const supportGcash = (data) => ({
	type: CONSTANT_ACTIONS.SUPPORT_G_CASH,
	payload: data.details[0].type,
});

export const isSupportGcash = (data) => ({
    type: CONSTANT_ACTIONS.IS_SUPPORT_GCASH,
    payload: data
})

export const fetchGcashSuccess = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_G_CASH_SUCCESS,
    payload: data
});

export const toggleGcashBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_G_CASH_BOTTOM_SHEET
});

export const storeAvailablePaymentMethods = (data) => ({
    type: CONSTANT_ACTIONS.STORE_AVAILABLE_PAYMENT_METHODS,
    payload: data
});

export const toggleVerifyingGcashBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_VERIFYING_GCASH_BOTTOM_SHEET
});

export const fetchAvailableMethodsLoading = (condition) => ({
    type: CONSTANT_ACTIONS.FETCH_AVAILABLE_METHODS_LOADING,
    payload: condition
});

export  const fetchPaymentMethodsLoading = (condition) => ({
    type: CONSTANT_ACTIONS.FETCH_PAYMENT_METHODS_LOADING,
    payload: condition
});

export const gcashLoading = (condition) => ({
    type: CONSTANT_ACTIONS.GCASH_LOADING,
    payload: condition
});

export const paymentMethodsLoading = (condition) => ({
    type: CONSTANT_ACTIONS.PAYMENT_METHODS_LOADING,
    payload: condition
})

export const saveGcashInfo = (gcash) => ({
    type: CONSTANT_ACTIONS.SAVE_GCASH_INFO,
    payload: gcash
})