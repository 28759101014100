import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { isChrome, isSamsungBrowser, isIOS, isSafari } from "../../utils/functions/check-browser";

const LOCATION_BASE_COMPONENT = ['/register'];
export class AuthRequired extends React.Component {
	render() {
		if ((!isChrome() && !isSamsungBrowser() && !isIOS() && !isSafari())) {
			// if (isIOS()) {
			return <Redirect to="/not-support" />;
		} else if (!this.props.authCheck && (_.isNull(this.props.map.location.lat) || _.isNull(this.props.map.location.lng))) {
			return <Redirect to="/welcome" />;
		} else if (!_.isEmpty(this.props.common.client) && LOCATION_BASE_COMPONENT.includes(this.props.orRender.props.location.pathname)) {
			return <Redirect to="/home" />;
		} else {
			return this.props.orRender;
		}
	}
}

const mapStateToProps = state => ({
    common: state.common,
    map: state.map,
    router: state.router
});

export default connect(mapStateToProps)(AuthRequired);

// if(!this.props.auth) {
//     return(<Redirect to='/welcome' /> );
// } else if (LOCATION_BASE_COMPONENT.includes(this.props.orRender.props.location.pathname) ) {
//     return (this.props.orRender);
// } else if (isUserinActive(this.props.common.client)) {
//     if (this.props.router.location.pathname === '/home') {
//         return (this.props.orRender);
//     } else {
//         return(<Redirect to='/home' /> );
//     }
// } else if ((_.isNull(this.props.map.location.lat) || _.isNull(this.props.map.location.lng))) {
//     return(<Redirect to='/search' /> );
// } else {
//     return (this.props.orRender);
// }