import React from "react";
import ContentLoader from "react-content-loader";

const ArticleSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={props.devicewidth}
		height={500}
		viewBox={`0 0 ${props.devicewidth} 500`}
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<rect x="6" y="60" rx="7" ry="7" width={`${props.devicewidth - 20}`} height="180" />
		<rect x="6" y="259" rx="7" ry="7" width={`${props.devicewidth - 20}`} height="20" />
		<rect x="6" y="289" rx="7" ry="7" width={`${props.devicewidth - 80}`} height="20" />
		<rect x="6" y="316" rx="7" ry="7" width={`${props.devicewidth - 20}`} height="20" />
		<rect x="6" y="18" rx="7" ry="7" width={`${props.devicewidth - 200}`} height="20" />
	</ContentLoader>
);

export default ArticleSkeleton;
