import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { isMobile } from "react-device-detect";
import { setNewsLocationPointer } from "../../../utils/redux/actions/newsActions";
class WhatsNew extends Component {
	onNews = (e, news) => {
		this.props.dispatch(setNewsLocationPointer());
		if (news.hasUrl) {
			window.location.replace(news.body);
		} else {
			this.props.dispatch(push("/news/" + news.id));
		}
	};

	render() {
		let news = "";
		if (this.props.data) {
			news = this.props.data.map((news, index) => {
				return (
					<div
						key={index}
						onClick={(e) => this.onNews(e, news)}
						className={`${isMobile ? "Whats_New_Slider_Box_Mobile" : "Whats_New_Slider_Box_Desktop"}`}
					>
						<div className="Whats_New_Slider_Container">
							<LazyLoadImage alt={news.title} src={news.thumbnailImage} />
							<div className="Whats_New_More">
								<p className="Whats_New_More_Title">
									<strong>{news.title}</strong>
								</p>
							</div>
						</div>
					</div>
				);
			});
		}
		return (
			<div className={`Whats_New ${this.props.hasBottomSpace ? "News_MarginBottom" : ""}`}>
				<div className="no-padding Whats_New_Title">
					<h1>What's New?</h1>
				</div>
				<div className={`no-padding  ${isMobile ? "Whats_New_Scroll_Mobile" : "Whats_New_Scroll_Desktop"}`}>
					{isMobile ? news : <PerfectScrollbar>{news}</PerfectScrollbar>}
				</div>
				<h5 className="News_Link" onClick={this.props.onNavigateNews}>
					Browse newsfeed
				</h5>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	news: state.news,
});

export default connect(mapStateToProps)(WhatsNew);