export function createMarkerObject(getState, position, pinIcon) {
    return new window.google.maps.Marker({
        position,
        map: getState().map.googleMap,
        icon: {
            url: pinIcon
        }
    })
}

export function createMarkerPartnerObject(getState, position, currentIcon, pinIcon) {
    let markersArray = [];
    let map = getState().map.googleMap;
    markersArray.push(createMarkerForPartner(map, position, currentIcon));
    getState().jobs.job.jobLocations.map((location, index) => {
        let infowindow = new window.google.maps.InfoWindow({
            content: location.address
        });
        let marker = new window.google.maps.Marker({
            position: location,
            map,
            icon: {
                url: pinIcon
            },
            label: {text: String(index + 1), color: 'white', fontWeight: 'bold'}
        })
        markersArray.push(marker);
        infowindow.open(map, marker);
        return marker;
    });
    return markersArray;
}
/**
 * 
 * @param {*} location 
 */
function createMarkerForPartner(map, location, currentIcon) {
    let marker = new window.google.maps.Marker({
		position: location,
		map,
		icon: {
			url: currentIcon,
			size: new window.google.maps.Size(30, 30),
		},
	});

    return marker;
}