import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { IoIosPlay, IoIosPause } from 'react-icons/io';
import { Howl } from 'howler';

import * as animationData from './voice_darker.json';
import LoadingRing from '../../loading-ring/loading-ring';

const classNames = require('classnames');

export class VoiceMessage extends Component {



    constructor(props) {
        super(props);

        this.state = {
            isStopped: false,
            isPaused: true,
            isPlay: false,
            audio: null,
            paused: false,
            play: false,
            url: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.props.data.uri.then((res) => {

            let format = '';            
            if (this.props.data.format === 'audio/webm') {
                format = 'webm';
            } else if (this.props.data.format === "audio/wav") {
                format = "audio/wav";
            } else {
                format = this.props.data.format;
            }

            this.audio = new Howl({
                src: [ res ],
                preload: false,
                html5: true,
                onload: () => {
                    this.setState({ loading: false });
                },
                onplay: () => {
                    this.setState((preState) => (
                        { isPlay: !preState.isPlay, isPaused: !preState.isPaused, play: true, loading: false }
                    ));
                },
                onpause: () => {
                    this.setState((preState) => (
                        { isPlay: !preState.isPlay, isPaused: !preState.isPaused, play: false, paused: true }
                    ));
                },
                onend: () => {
                    this.setState((preState) => (
                        { isPlay: false, isPaused: true, play: false, paused: false }
                    ));
                },
                format: [ format ]
            });
        });
    }

    onClick = () => {
        if (this.audio) {  
            if (!this.state.paused && this.state.play) {
                this.audio.pause();
            } else if (!this.state.play && this.state.paused) {
                this.audio.play();
            } else if (!this.state.play && !this.state.paused) {
                this.setState(() => ({ loading: true }));
                this.audio.play();
            } else {
                this.audio.pause();
            }
        }
    }
 
    render() {

        const defaultOptions = {
            background: "transparent",
            speed: "1",
            loop: true,
            control: true,
            autoplay: false,
            height: 25,
            animationData: animationData.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
			<div className="rce-mbox-file voice">
				<button
					onClick={this.onClick}
					type="button"
					className={classNames({
						"voice-play": !this.state.isPlay,
						"voice-pause": this.state.isPlay,
					})}
				>
					{this.state.loading ? <LoadingRing /> : ""}
					{!this.state.isPlay ? <IoIosPlay /> : <IoIosPause />}
				</button>
				<div className="lottie-animation">
					<Lottie options={defaultOptions} isStopped={this.state.isStopped} isPaused={this.state.isPaused} />
				</div>
			</div>
		);
    }
}

VoiceMessage.defaultProps = {
    text: '',
    data: {},
    onClick: null,
    onDownload: null,
    onOpen: null,
};


export default VoiceMessage;
