/**
 * create object from saved places
 * @param {*} obj 
 */
export const createPlaceObjectFromSavedPlaceObject = (obj) => {
    let place = obj.location;
    place['title'] = obj.title;
	place['id'] = obj.id;
    return place;
}

export const createPlainLocationObject = (location) => {
    const locationAdapter = {
		jobLocations: [
            {
                lat: location.lat,
                lng: location.lng,
                address: location.address,
                title: location.title,
            }
        ]
	};
	return locationAdapter;
};