import React from 'react';
import {ReactComponent as LocationPin} from "../../../../v5-icons/pin-location-small.svg";

export default function JobLocations({ locations }) {
	return (
		<div className="job-locations-container review-booking-item-container">
			{locations
			? locations.length > 2 
				? 
				<>
					<div className="review-booking-item-inner">
						<LocationPin className="icon-location" />
						<p>{locations[0].title || locations[0].address}</p>
					</div>
					<div className="review-booking-item-inner">
						<LocationPin className="icon-location" />
						<p>{locations.length - 1} more stops</p>
					</div>
				</>

				: 
				<>
					{locations.map((location, idx) => (
						<div key={idx} className="review-booking-item-inner">
							<LocationPin className="icon-location" />
							<p>{location.title || location.address}</p>
						</div>
				  	))}
				</>
			: null}
		</div>
	);
}