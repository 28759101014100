import {
	FETCH_JOBS_BEGIN,
	FETCH_JOBS_SUCCESS,
	FETCH_JOBS_SUCCESS_SCROLL,
	WILL_UNMOUNT_JOBS,
	SELECT_JOB_FROM_JOBS,
	FETCH_JOB_INFORMATION_BEGIN,
	FETCH_JOB_INFORMATION_SUCCESS,
	INIT_JOB_BY_MATCH_STATUS,
	UPDATE_JOB_BY_MATCH_STATUS,
	INIT_INTERVAL_MATCHING_JOB_REQUEST,
	FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING_SUCCESS,
	FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING,
	JOB_UNSET_MATCHING_REQUEST,
	FETCH_PARTNER_OF_MATCHED_JOB_SUCCESS,
	CANCEL_SETINTERVAL_JOB,
	PLAY_TRACK_MATCHED,
	STOP_TRACK_MATCHED,
	FETCH_JOB_UNRATED_SUCCESS,
	FETCH_JOB_UNRATED_RATE_COMPLIMENT,
	JOB_RATE_PARTNER_COMPLITED,
	JOB_MATCHING_WILL_UN_MOUNT,
	JOB_MATCHING_STATE_SET,
	JOB_ACTIVE_COUNT,
	JOB_ACTIVE,
	INIT_INTERVAL_JOB_STATE_CHECK,
	JOB_CHANGE_STATE_INTERVAL_CHAT,
	JOB_RATING_UNRATED_WILL_UNMOUNT,
	JOB_CHAT_HISTOR_WILL_UNMOUNT,
	CLEAR_INTERVAL_CANCEL_REQUEST_INTERVAL,
	CANCEL_REQUEST_REDUCE_COUNT,
	CANCEL_REQUEST_INTERVAL,
	INIT_CANCEL_REQUEST_COUNT_DOWN,
	TOGGLE_RATE_POPUP,
	APP_RATED,
	SET_MY_JOB_LOCATION_POINTER,
	SET_MY_JOBS_SET_SEARCH_LOCATION_POINTER,
	DETAIL_PAYMENT_METHOD,
	SET_DEFAULT_PAYMENT_DETAILS,
	ADD_CARD_DETAIL,
	SET_DEFAULT_CARD_DETAIL,
	SET_DEFAULT_JOB_OBJECT,
	FETCH_OPEN_JOBS_SUCCESSFULLY,
	SET_JOB_DATA,
	UPDATE_JOB_BY_OPEN_STATUS,
	SET_CUBE_NUMBER,
	PLUS_CUBE_NUMBER,
	MINUS_CUBE_NUMBER,
	SET_JOB_STATE,
	CANCEL_JOB_LOADING,
	FETCH_JOB_AND_PAYMENT_LOADING,
	COMPLIMENT_LOADING,
	SEND_FEEDBACK_BACK_LOADING,
	OUT_OF_WORKING_HOURS,
	ADDED_NEW_KEY_FOR_COMPLIMENTS,
	TOGGLE_JOB_RATING_BUTTON,
	ADD_COMMENT_OF_APP_RATING,
	SET_SORTED_CLIENT_RATE,
	IS_TOGGLE_SHEET,
} from "../actions/jobsActions";

const INTERVAL_NUMBER_CANCEL_REQUEST = 10;

const initialState = {
	jobs: {},
	job: {},
	jobMatchingState: false, // check job should be in matching state
	unrated: null,
	unratedCompliment: null,
	matchingTimeout: null,
	matchingRequest: null,
	shouldBlockNavigation: false,
	matchedPartner: null,
	activeJobsCount: 0, // number of active jobs for use in bottomBar component
	chatJobStateInterval: null,
	cancelRequest: INTERVAL_NUMBER_CANCEL_REQUEST,
	cancelRequestInterval: null,
	appRateRemindMeLater: false,
	appIsRated: false,
	lastLocationPointer: null,
	searchPointer: null,
	paymentDetails: null,
	cardDetail: null,
	jobState: null,
	openJobs: null,
	sound: null,
	cubeNumber: null,
	cancelJobLoading: false,
	fetchJobAndPaymentLoading: false,
	complimentLoading: false,
	sendFeedBackLoading: false,
	isOutOfWorkingHours: false,
	newCompliments: null,
	jobRatingButton: true,
	jobRateComment: "",
	sortedClientRate: null,
	isOpenRatingBottomSheet: false,
};

export default function jobsReducer(state = initialState, action) {
  switch (action.type) {
		case FETCH_JOBS_BEGIN:
			return {
				...state,
			};
		case FETCH_JOBS_SUCCESS:
			return {
				...state,
				jobs: action.payload,
			};
		case FETCH_JOBS_SUCCESS_SCROLL:
			return {
				...state,
				jobs: action.payload,
			};
		case WILL_UNMOUNT_JOBS:
			return {
				...state,
				jobs: {},
			};
		case SELECT_JOB_FROM_JOBS:
			return {
				...state,
				job: action.payload,
				jobState: action.payload.state,
			};
		case FETCH_JOB_INFORMATION_BEGIN:
			return {
				...state,
			};
		case JOB_CHANGE_STATE_INTERVAL_CHAT:
			return {
				...state,
				// job: action.payload,
				jobState: action.payload.state,
			};
		case FETCH_JOB_INFORMATION_SUCCESS:
			return {
				...state,
				job: action.payload,
				jobState: action.payload.state,
			};
		case INIT_JOB_BY_MATCH_STATUS:
			return {
				...state,
				job: action.payload,
				jobState: action.payload.state,
				// job: action.payload.data,
			};
		case UPDATE_JOB_BY_MATCH_STATUS:
			return {
				...state,
				job: action.payload,
				jobState: action.payload.state,
			};
		case INIT_INTERVAL_MATCHING_JOB_REQUEST:
			return {
				...state,
				matchingTimeout: action.payload,
			};
		case FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING_SUCCESS:
			return {
				...state,
				matchingRequest: action.payload,
			};
		case JOB_UNSET_MATCHING_REQUEST:
		case FETCH_STATE_OF_MATCHING_JOB_FOR_CHECKING:
			return {
				...state,
				matchingRequest: null,
			};
		case FETCH_PARTNER_OF_MATCHED_JOB_SUCCESS:
			return {
				...state,
				matchedPartner: action.payload,
				job: action.payload,
			};
		case CANCEL_SETINTERVAL_JOB:
			return {
				...state,
				matchingTimeout: null,
			};
		case PLAY_TRACK_MATCHED:
		  return {
		    ...state,
		    sound: action.payload,
		  };
		case STOP_TRACK_MATCHED:
		  return {
		    ...state,
		    sound: null,
		  };
		case FETCH_JOB_UNRATED_SUCCESS:
			return {
				...state,
				unrated: action.payload,
				job: {}
			};
		case FETCH_JOB_UNRATED_RATE_COMPLIMENT:
			return {
				...state,
				unratedCompliment: action.payload,
			};
		case JOB_RATE_PARTNER_COMPLITED:
			return {
				...state,
				unrated: null,
			};

		case JOB_MATCHING_WILL_UN_MOUNT:
			return {
				...state,
				matchingTimeout: null,
				matchingRequest: null,
				matchedPartner: null,
				confirmationBottomSheet: null,
				jobMatchingState: false,
			};
		case JOB_ACTIVE_COUNT:
			return {
				...state,
				activeJobsCount: action.payload,
			};
		case JOB_ACTIVE:
			return {
				...state,
				activeJobs: action.payload,
			};
		case JOB_MATCHING_STATE_SET:
			return {
				...state,
				jobMatchingState: true,
			};
		case INIT_INTERVAL_JOB_STATE_CHECK:
			return {
				...state,
				chatJobStateInterval: action.payload,
			};
		case JOB_CHAT_HISTOR_WILL_UNMOUNT:
			return {
				...state,
				chatJobStateInterval: null,
				unratedCompliment: null,
			};
		case JOB_RATING_UNRATED_WILL_UNMOUNT:
			return {
				...state,
				unratedCompliment: null,
				newCompliments: null,
			};
		case CLEAR_INTERVAL_CANCEL_REQUEST_INTERVAL:
			return {
				...state,
				cancelRequestInterval: null,
			};
		case CANCEL_REQUEST_REDUCE_COUNT:
			return {
				...state,
				cancelRequest: action.payload,
			};
		case CANCEL_REQUEST_INTERVAL:
			return {
				...state,
				cancelRequestInterval: action.payload,
			};
		case INIT_CANCEL_REQUEST_COUNT_DOWN:
			return {
				...state,
				cancelRequest: INTERVAL_NUMBER_CANCEL_REQUEST,
			};
		case TOGGLE_RATE_POPUP:
			return {
				...state,
				appRateRemindMeLater: action.payload,
			};
		case APP_RATED:
			return {
				...state,
				appIsRated: true,
			};
		case SET_MY_JOB_LOCATION_POINTER:
			return {
				...state,
				lastLocationPointer: action.payload,
			};
		case SET_MY_JOBS_SET_SEARCH_LOCATION_POINTER:
			return {
				...state,
				searchPointer: action.payload,
			};
		case DETAIL_PAYMENT_METHOD:
			return {
				...state,
				paymentDetails: action.payload,
			};
		case SET_DEFAULT_PAYMENT_DETAILS:
			return {
				...state,
				paymentDetails: null,
			};
		case ADD_CARD_DETAIL:
			return {
				...state,
				cardDetail: action.payload,
			};
		case SET_DEFAULT_CARD_DETAIL:
			return {
				...state,
				cardDetail: null,
			};
		case SET_DEFAULT_JOB_OBJECT:
			return {
				...state,
				job: {},
			};
		case FETCH_OPEN_JOBS_SUCCESSFULLY:
			return {
				...state,
				openJobs: action.payload,
			};
		case SET_JOB_DATA:
			return {
				...state,
				job: action.payload,
			};
		case UPDATE_JOB_BY_OPEN_STATUS:
			return {
				...state,
				job: action.payload,
			};
		case SET_CUBE_NUMBER:
			return {
				...state,
				cubeNumber: action.payload,
			}
		case PLUS_CUBE_NUMBER:
			return {
				...state,
				cubeNumber: state.cubeNumber + 1,
			};
		case MINUS_CUBE_NUMBER:
			return {
				...state,
				cubeNumber: state.cubeNumber - 1,
			};
		case SET_JOB_STATE:
			return {
				...state,
				jobState: action.payload
			}
		case CANCEL_JOB_LOADING:
			return {
				...state,
				cancelJobLoading: action.payload
			}
		case FETCH_JOB_AND_PAYMENT_LOADING:
			return {
				...state,
				fetchJobAndPaymentLoading: action.payload
			}
		case COMPLIMENT_LOADING:
			return {
				...state,
				complimentLoading: action.payload
			}
		case SEND_FEEDBACK_BACK_LOADING:
			return {
				...state,
				sendFeedBackLoading: action.payload
			}
		case OUT_OF_WORKING_HOURS:
			return {
				...state,
				isOutOfWorkingHours: action.payload
			}
		case ADDED_NEW_KEY_FOR_COMPLIMENTS:
			return {
				...state,
				newCompliments: action.payload
			}
		case TOGGLE_JOB_RATING_BUTTON:
			return {
				...state,
				jobRatingButton: action.payload
			}
		case ADD_COMMENT_OF_APP_RATING:
			return {
				...state,
				jobRateComment: action.payload
			}
		case SET_SORTED_CLIENT_RATE:
			return {
				...state,
				sortedClientRate: action.payload
			}
		case IS_TOGGLE_SHEET:
			return {
				...state,
				isOpenRatingBottomSheet: !state.isOpenRatingBottomSheet
			}
		default:
			// ALWAYS have a default case in a reducer
			return state;
  }
}