import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from "../../commonActions";
import { DEFAULT_COUNTRY_CODE } from "../registerConstant";
/**
 * Fetch countries
 */
export const fetchCountries = () => {
    return async (dispatch) => {
        const defaultCountry = DEFAULT_COUNTRY_CODE;
		const geoBoundaryCountryCodeRequestBody = {
			query: `query CountryCodes {
                    countryCodes {
                        code
                        icon
                    }
                }`,
        };
        try {
            const { data } = await GraphRequest.all(geoBoundaryCountryCodeRequestBody);
            return data;
        
        } catch(err) {
            return dispatch(DISPATCH_STATE.setDefaultCountryCode(defaultCountry));
        }

	};
}
 
export function setCountryCodeData() {
    return (dispatch) => {
        const defaultCountry = DEFAULT_COUNTRY_CODE;
        dispatch(fetchCountries()).then((countries) => {
			if (!_.isNull(countries.data) && countries.data.countryCodes && !_.isEmpty(countries.data.countryCodes)) {
                dispatch(DISPATCH_STATE.registerFetchCountryCode(countries.data.countryCodes));
			} else {
				dispatch(DISPATCH_STATE.setDefaultCountryCode(defaultCountry));
			}
		}).catch(err => console.log(err))
    }
}
/**
 * Fetch cities
 */
export const fetchCities = () => {
    return async dispatch => {
        const geoBoundaryCityRequestBody = {
			query: `query ClientCoveredCities {
                coveredCities {
                    id
                    name
                    lat
                    lng
                }
            }`,
		};
        try {
			const { data } = await GraphRequest.all(geoBoundaryCityRequestBody);
			return data;
		} catch (err) {
            dispatch(appFetchFailure(err));
		}
    };
}

export const setCitiesData = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(DISPATCH_STATE.fetchCitiesLoading(true));
			dispatch(fetchCities()).then((cities) => {
				dispatch(DISPATCH_STATE.fetchCitiesLoading(false));
				if (!_.isNull(cities) && cities.data.coveredCities) {
					resolve(cities.data.coveredCities);
					dispatch(DISPATCH_STATE.registerFetchCities(cities.data.coveredCities));
				}
			});
		});
	};
};
