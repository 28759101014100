import React from 'react';
import {ReactComponent as LocationPin} from "../../../v5-icons/location.svg";

export default function JobLocations({ locations, handleClickBookingItem }) {
	return (
		<div className="job-locations-container review-booking-item-container">
			<p className="title">Job Location</p>
			{locations
				? locations.map((location, idx) => (
						<div key={idx} className="review-booking-item-inner" onClick={handleClickBookingItem}>
							<p>{location.address}</p>
							<div className="icon-container">
								<LocationPin />
								<span className="number">{idx + 1}</span>
							</div>
						</div>
				  ))
				: null}
		</div>
	);
}