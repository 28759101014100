import React, { Component } from "react";
import { connect } from "react-redux";

import { toggleVerifyingGcashBottomSheet } from "../../../../../utils/redux/actions/billingActions/billingChangeState";

import CustomButton from "../../../../ui-elements/CustomButton/CustomButton";
class BillingVerifyingGCash extends Component {
	render() {
		return (
			<div className="verifying-gcash-container">
				<h3 className="title">Connecting with GCash</h3>
				<p className="body">It may take up to 3 minutes. We will send you a notification when it's done!</p>
				<CustomButton classList="verify-gcash-btn" title="Got It" onClick={() => this.props.dispatch(toggleVerifyingGcashBottomSheet())} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.billing,
});

export default connect(mapStateToProps)(BillingVerifyingGCash);
