import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import JobRatingComplimentsItem from "./JobRatingComplimentsItem";
import JobRatingButton from "../JobRatingButtons/JobRatingButtons";

import { storeSelectedCompliments } from "../../../utils/redux/actions/jobsActions/actions/jobs-rating";
import { addCommentOfAppRating } from "../../../utils/redux/actions/jobsActions/jobsChangeState";

class JobRatingCompliments extends Component {

	onChangeComplimentItem = (compliment) => {
		this.props.dispatch(storeSelectedCompliments(compliment));
	};

	handleCommentChange = (e) => {
		const { value } = e.target;
		if (value.length) {
			this.props.dispatch(addCommentOfAppRating(value));
		}
	};

	handleFocus = (e) => {
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			e.target.parentNode.classList.add("focused");
		}
	};

	render() {
		const { compliments } = this.props;
		return this.props.isOpen ? (
			<Fragment>
				<div className="Job-rating-compliments compliments-list">
					<h5 className="feedback-text">Feedback</h5>
					<h6 className="feedback-text-subtitle">Tell us about your experience</h6>
					<div className="job-information-container">
						<div className="compliments-container">
							<h2>Strengths</h2>
							<div className="no-padding Job_Rating_Items">
								{!_.isNull(compliments)
									? compliments[1].compliments.map((compliment) => (
											<JobRatingComplimentsItem
												key={compliment.id}
												onChange={() => this.onChangeComplimentItem(compliment)}
												id={compliment.id}
												selected={compliment.selected}
											>
												{compliment.name}
											</JobRatingComplimentsItem>
									  ))
									: null}
							</div>
						</div>
						<div className="compliments-container">
							<h2>Weaknesses</h2>
							<div className="no-padding Job_Rating_Items">
								{!_.isNull(compliments)
									? compliments[0].compliments.map((compliment) => (
											<JobRatingComplimentsItem
												key={compliment.id}
												onChange={() => this.onChangeComplimentItem(compliment)}
												id={compliment.id}
												selected={compliment.selected}
											>
												{compliment.name}
											</JobRatingComplimentsItem>
									  ))
									: null}
							</div>
						</div>
					</div>

					<div className="Job_Rating_Comment no-padding">
						<h2>Anything else?</h2>
						<div className="form-group-container" id="Job_Rating_Text">
							<label>Share feedback or a personal message to us...</label>
							<textarea
								rows={4}
								onChange={this.handleCommentChange}
								onFocus={this.handleFocus}
								onBlur={this.handleFocus}
								name="comment"
								className="Job_Rating_Comment_Text_Area"
							></textarea>
						</div>
					</div>
					<JobRatingButton
						loading={this.props.sendFeedBackLoading}
						isDisabbled={this.props.jobs.jobRatingButton || this.props.sendFeedBackLoading}
						closeSheet={this.props.toggleSheet}
						handleClick={this.props.obSubmit}
					/>
				</div>
			</Fragment>
		) : null;
	}
}

const mapStateToProps = (state) => ({
	jobs: state.jobs,
});

export default connect(mapStateToProps)(JobRatingCompliments);
