import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import JobLocations from '../JobMatching/JobLocations/JobLocations';
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import { setQueuedJobTime, postQueuedRequest } from "../../../utils/redux/actions/bookingActions/actions/booking-new-service";
import { setSelectedQueued } from "../../../utils/redux/actions/bookingActions/bookingChangeState";

import { googlePlacesMatching } from '../../../utils/functions/google-places-matching';
import { ReactComponent as SuiteCase } from "../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../v5-icons/clock-small.svg";
import { ReactComponent as Note } from "../../../v5-icons/note-small.svg";
import { ReactComponent as High } from "../../../v5-icons/high.svg";
import { ReactComponent as Medium } from "../../../v5-icons/medium.svg";
import { ReactComponent as Low } from "../../../v5-icons/low.svg";
import MyKoinSvg from "../../../assests/images/billing/my-koin.svg";
import { cardType } from "../../../utils/constants/constant";
const classNames = require("classnames");
class QueuedBookingBottomSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
			isDisabled: true,
			cannotRequest: false
		};
    }

    componentDidMount() {
        this.props.dispatch(setQueuedJobTime(null));

        const format = "hh:mm:ss";
        const now = moment();
        const beforeTimeRequest = moment("18:00:00", format);
        const afterTimeRequest = moment("23:59:59", format);
        if (now.isBetween(beforeTimeRequest, afterTimeRequest)) {
			this.setState(() => ({
				cannotRequest: true,
			}));
		}
    }

	handleChange(e) {
        const addedDate = moment().add(Number(e.target.value), "hours");
        this.props.dispatch(setQueuedJobTime(addedDate.format()));
		this.props.dispatch(setSelectedQueued(e.target.value !== 6 ? `${e.target.value}h` : 'eod'));
        if (e.target.value) {
            this.setState(() => ({
                isDisabled: false
            }))
        }
    }

	render() { 
		const jobCost = {
			currency: this.props.booking.prices.items[this.props.booking.currentPrice]["price"]["currency"],
			value: this.props.booking.prices.items[this.props.booking.currentPrice]["price"]["value"],
		};
		return (
			<div className="queued-jobs-container">
				<h2 className="title">Keep Looking</h2>

				{!_.isEmpty(this.props.job) ? (
					<div className="matching-process-container">
						<div className="matching-process-row">
							<SuiteCase className="icon" />
							<p className="commercial-name">{this.props.booking.product.commercialName}</p>
						</div>
						<div className="matching-process-row">
							<JobLocations locations={this.props.booking.jobLocations.jobLocations} />
						</div>
						<div className="matching-process-row">
							<Clock className="icon" />
							<p>
								{jobCost && this.props.common.client.currency.signText}{" "}
								{!_.isNull(this.props.newPrice) ? this.props.newPrice : jobCost.value} for {this.props.booking.formattedTime},
								extendable
							</p>
						</div>
						{!_.isEmpty(this.props.primaryPayment) && this.props.billing.balance[0].balance <= 0 ? (
							<div className="matching-process-row payment">
								<img
									src={this.props.primaryPayment[0].icon}
									alt={this.props.primaryPayment[0].type}
									className={classNames("billing-card-image", this.props.primaryPayment[0].type)}
								/>
								<p>
									Pay via{" "}
									{!_.isNull(this.props.primaryPayment[0].name)
										? this.props.primaryPayment[0].name
										: this.props.primaryPayment[0].type}
									{this.props.primaryPayment[0].type === "mc" || this.props.primaryPayment[0].type === "visa"
										? `**** ${this.props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}
						{_.isEmpty(this.props.primaryPayment) ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
							</div>
						) : null}

						{!_.isEmpty(this.props.primaryPayment) && this.props.billing.balance[0].balance > 0 ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>
									Pay via mykoins then {this.props.primaryPayment[0].type}{" "}
									{this.props.primaryPayment[0].type === "mc" || this.props.primaryPayment[0].type === "visa"
										? `**** ${this.props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}

						<div className="matching-process-row">
							<Note className="icon" />
							{!_.isEmpty(this.props.booking.note) ? <p>{this.props.booking.note}</p> : <p>No note to partner</p>}
						</div>
					</div>
				) : null}
				<ul className="queued-options-list" onChange={(e) => this.handleChange(e)}>
					<li className="queued-option">
						<input value="1" type="radio" id="need-asap-option" name="need-asap" />
						<div className="check">
							<label htmlFor="need-asap-option">
								<High className="icon" />
								<div className="content">
									<p>Need ASAP</p>
									<span>Keep looking for 1 hour</span>
								</div>
							</label>
						</div>
					</li>
					<li className="queued-option">
						<input type="radio" id="need-soon-option" value="3" name="need-asap" />
						<div className="check">
							<label htmlFor="need-soon-option">
								<Medium className="icon" />
								<div className="content">
									<p>Need Soon</p>
									<span>Keep looking for 3 hours</span>
								</div>
							</label>
						</div>
					</li>
					<li
						className=""
						className={classNames("queued-option", {
							"disabled-option": this.state.cannotRequest,
						})}
					>
						<input type="radio" id="need-before-today-option" value="6" name="need-asap" disabled={this.state.cannotRequest} />
						<div className="check">
							<label htmlFor="need-before-today-option" disabled={this.state.cannotRequest}>
								<Low className="icon" />
								<div className="content">
									<p>Need Before Today End</p>
									<span>Keep looking until later 6:00 PM</span>
								</div>
							</label>
						</div>
					</li>
				</ul>
				<p className="tip">💡 Drop your worries and let the next partner ready, take this job for you. You can cancel anytime too!</p>
				<div className="queued-job-button">
					<CustomButton
						title={
							this.props.booking.queuedLoading ? (
								<div className="next-button-loading">
									<WhiteLoading />
								</div>
							) : (
								"Confirm"
							)
						}
						type="submit"
						className="retry-button"
						disabled={this.state.isDisabled}
						onClick={() => this.props.dispatch(postQueuedRequest())}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	common: state.common,
	locations: googlePlacesMatching(state.map),
	primaryPayment: state.common.primaryPayment,
	billing: state.billing,
	newPrice: state.booking.priceWithAppliedCode,
});

export default connect(mapStateToProps)(QueuedBookingBottomSheet);