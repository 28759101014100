import React from "react";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import { useSelector, shallowEqual } from "react-redux";
import bankCard from '../../../icons/bankCard.svg'

export const  TransferConfirmation = (props) => {
	const chat = useSelector(state => state.chat, shallowEqual);
	const balance = useSelector(state => state.billing?.balance, shallowEqual);
	const common = useSelector(state => state.common, shallowEqual);

	return (
		<>
			{balance && common && chat && (<div className="transfer-confirmation-container">
				<div className="transfer-confirmation-header">
					<div className="title">In-App Payment</div>
					<div className="subtitle">{`You’re about to send ${chat.currencySign}${chat.transferRequested} to`}
						<p>{chat.partnerName}</p></div>
				</div>

				<div className="transfer-confirmation-body">
					<div>
						<div>Requested</div>
						<div className="text-bold">{chat.currencySign}{chat.transferRequested}</div>
					</div>
					<div>
						<div>Bank Fees</div>
						<div className="text-bold">{chat.currencySign}{chat.transferFee || 0}</div>
					</div>
					<div className="transfer-confirmation-splitter"></div>
					<div>
						<div>Total</div>
						<div className="text-bold">{chat.currencySign}{Number(chat.transferRequested + chat.transferFee).toFixed((chat.transferRequested + chat.transferFee) % 1 !== 0 ? 2 : 0)}</div>
					</div>
				</div>

				<div className="description-about-fee" style={{ marginBottom: 0 }}>Honestly, this is what banks charge
					us. We don’t earn any of it!
				</div>
				<div className="description-about-fee" style={{ marginTop: 0 }}>💡 If you’d like to save on fees, send
					directly to <br /> GCash.
				</div>
				<div className="transfer-confirmation-splitter"></div>

				<div className="current-payment-methods-wrapper">
					<div className="current-payment">
						<div className="icon">
							<DynamicIcon icon="MyKoin" classes="icon-mk" />
						</div>
						<p>mykoins</p>
						<span>{balance?.[0]?.availableDisplayValue}</span>
					</div>
					<div className="current-payment">
						<div className="icon">
							<img
								className="icon"
								src={common?.primaryPayment?.[0]?.icon || bankCard}
								alt={common?.primaryPayment?.[0]?.type || "none"}
							/>
						</div>
						{common?.primaryPayment?.[0].type ? (<>
							{common?.primaryPayment?.[0].type !== "GCash" ? (
								<>
									<p>Payment method</p>
									<span>**** {common.primaryPayment?.[0].cardNumber.substring(common.primaryPayment?.[0].cardNumber.length, common.primaryPayment?.[0].cardNumber.length-4)}</span>
								</>
							) : (
								<>
									<p>Payment method</p>
									<span>{common.primaryPayment?.[0].name}</span>
								</>
							)}
						</>) : (
							<>
								<p>Payment method</p>
								<span>None</span>
							</>
						)}
					</div>
				</div>

				<div className="transfer-confirmation-footer">Not enough mykoins? Your payment method will be used.
				</div>
				{props.primaryButton}
			</div>)}
		</>
	)
}