export const LOCATION_INPUT_BOX_NAME_DESTINATION = "destination";
export const LOCATION_INPUT_BOX_NAME_SOURCE = "source";
export const LOCATION_INPUT_BOX_NAME_SECOND_SOURCE = "second-source";
export const HomeNavigateRows = [
	{
		id: 0,
		title: "Account",
		description: "Your mymall profile & preferences",
		icon: "Account",
		navigateLink: "/account",
	},
	{
		id: 1,
		title: "My Jobs",
		description: "All of the jobs you have booked",
		icon: "Bag",
		navigateLink: "/jobs",
	},
	{
		id: 2,
		title: "What’s New?",
		description: "Latest announcements, promos, etc.",
		icon: "Announce",
		navigateLink: "/news",
	},
	{ id: 3, title: "About mymall by MyKuya", description: "Shop in Seconds. Delivered in Minutes!", icon: "MyMall", navigateLink: "/about-mykuya" },
];

//Text for tour
//Home
export const Services = "Welcome to mymall®! Take a stroll & see what we have in store for you!";

//Product or Booking page
export const ProductInfo = "Specifics? How it works? Check this out!";
export const SelectLocation = "Tell us where and we'll be there!";
export const SelectMultiLocation = "Where does it start? Where does it go? Let us know & it's done!";

//Review booking
export const PaymentInfo = "You can also add your payment method once & use it every time after. ";
export const MkBalanceInfo = "All payments are handled inside mymall®. You can make one-time payments with MyKoins using your preferred payment method.";
export const BookingButton = "Book this right away or schedule it for later. You decide!";
export const InstantButton = "You can get this right away!";
export const ScheduledButton = "When do you need it? Get it then! ";

//Review payment bottom sheet 
export const TopupInfo = "MyKoins are good for one-time payments. You can top-up with your preferred method and use it in mymall®";
export const AddCardInfo = "Want a hassle free experience? Add your payment method once and not worry about it again. ";
export const cardType = {
	MASTERCARD: 'mc',
	VISA: 'visa'
}