import React, { Component } from "react";
import { connect } from "react-redux";
import onClickOutside from "react-onclickoutside";
import { isMobile } from "react-device-detect";

import FormInput from "../../register/form/input/form-input";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import { setAmountOfRequestMkForm } from "../../../utils/redux/actions/chatActions/chatChangeState";
import { submitRequestMkForm } from "../../../utils/redux/actions/chatActions";
class RequestMK extends Component {
	inputAmountRef = React.createRef();
	inputNoteRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			isDisabledButton: true,
			inputAmountValue: "",
		};
	}

	handleChange() {
		if (this.inputAmountRef.current.value.length > 0 && this.inputNoteRef.current.value.length >= 4) {
			this.setState(() => ({
				isDisabledButton: false,
			}));
			this.props.dispatch(
				setAmountOfRequestMkForm({
					amount: Number(this.inputAmountRef.current.value),
					note: this.inputNoteRef.current.value,
				}),
			);
		} else {
			this.setState(() => ({
				isDisabledButton: true,
			}));
		}
	}

	submitForm() {
		const requestFormData = {
			jobId: this.props.jobId,
			data: this.props.mkRequestFormData,
		};
		this.props.dispatch(submitRequestMkForm(requestFormData));
	}

	toggleFocusedClass(e) {
		const element = document.querySelector(".call-to-action");
		const bottomPosition = element.style.bottom;

		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			if (bottomPosition === "0px" && isMobile) {
				element.style.bottom = "-18px";
			}
			e.target.parentNode.classList.add("focused");
		}
	}

	handleClickOutside = (evt) => {
		const element = document.querySelector(".call-to-action");
		element.style.bottom = "0px";
	};

	render() {
		return (
			<>
				<div className="mk-form">
					<div className="form-group">
						<FormInput
							label="Amount to receive"
							placeholder="Type the amount"
							autoFocus
							name="amount"
							onBlur={(e) => this.toggleFocusedClass(e)}
							onFocus={(e) => this.toggleFocusedClass(e)}
							onChange={(e) => {
								if (e.target.validity.valid) {
									this.handleChange(e);
									this.setState(() => ({ inputAmountValue: e.target.value }));
								}
							}}
							forminputref={this.inputAmountRef}
							pattern="\d+$|(?=^.+$)^\d+\.\d{0,2}"
							value={this.state.inputAmountValue}
						/>
					</div>
					<div className="form-group">
						<div className="form-group-container">
							<label>What is it for?</label>
							<textarea
								className="form-control"
								onFocus={(e) => this.toggleFocusedClass(e)}
								onChange={(e) => this.handleChange(e)}
								onBlur={(e) => this.toggleFocusedClass(e)}
								ref={this.inputNoteRef}
								maxLength="255"
								amount="note"
								placeholder="Unused cash that I gave you for shopping."
							></textarea>
						</div>
					</div>
				</div>
				<div className="call-to-action" style={{ bottom: 0 }}>
					<CustomButton
						title={
							this.props.sendMoneyLoading ? (
								<div className="next-button-loading">
									<WhiteLoading />
								</div>
							) : (
								"Request Money"
							)
						}
						type="button"
						disabled={this.state.isDisabledButton || this.props.sendMoneyLoading}
						onClick={() => this.submitForm()}
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.chat,
});

export default connect(mapStateToProps)(onClickOutside(RequestMK));
