import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import $ from 'jquery';
import SavedPlacesFavorite from './SavedPlacesFavorites/SavedPlacesFavorites';
import SavedPlacesOthers from './SavedPlacesOthers/SavedPlacesOthers';
import SingleListSkeleton from "../ui-elements/Skeleton/SingleListSkeleton";

import {
	fetchSavedPlaces,
	savedPlacesSetType,
	SAVED_PLACE_CUSTOM_TYPE,
	checkSelectedPlaceExist,
	goBackSavedPlaces,
	checkLocationPointerExist,
	toggleOutOfGeo,
	editLocationSavedPlace,
	SAVED_PLACE_HOME_TYPE,
} from "../../utils/redux/actions/savedPlacesActions";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import TitleBar from '../../organisms/title-bar';
import backButton from '../../v5-icons/back-button.svg';
import chatSupport from '../../v5-icons/chat-support.svg';
import CustomButton from "../ui-elements/CustomButton/CustomButton";
import { setNavigationBlackBox } from "../../utils/redux/actions/commonActions/commonChangeState";

const classNames = require('classnames');

class SavedPlaces extends Component {
	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
			isMenuOpen: false,
			hasShadow: false
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		this.props.dispatch(checkLocationPointerExist());
		this.props.dispatch(fetchSavedPlaces());
		this.props.dispatch(editLocationSavedPlace(null));

		if (typeof window !== "undefined") {
			const contentTop = $(".saved-places-container").offset().top;
			const contentBottom = contentTop + $(".saved-places-container").outerHeight() - 100;
			const addAddressBtnTop = $(".add-new-address").offset().top;
			if (contentBottom >= addAddressBtnTop) {
				this.setState({hasShadow: true});
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((this.props.savedPlaces.all?.extra?.length !== prevProps.savedPlaces.all?.extra?.length)
			|| (this.props.savedPlaces.all?.specific?.length !== prevProps.savedPlaces.all?.specific?.length)
		) {
			const contentTop = $(".saved-places-container").offset().top;
			const contentBottom = contentTop + $(".saved-places-container").outerHeight() - 100;
			const addAddressBtnTop = $(".add-new-address").offset().top;
			if (contentBottom >= addAddressBtnTop) {
				this.setState({hasShadow: true});
			} else {
				this.setState({hasShadow: false});
			}
		}

		if (_.isNull(this.props.savedPlaces.savedHome) && _.isNull(this.props.savedPlaces.savedWork)) {
			$(".saved-places-others").css("margin-top", 0);
		} else {
			$(".saved-places-others").css("margin-top", "");
		}
		return true;
	}

	addAddress = (e) => {
		this.props.dispatch(setNavigationBlackBox({from: "", goBack: "", action: "addAddress"}));
		this.props.dispatch(savedPlacesSetType(_.isNull(this.props.savedPlaces.savedHome) ? "Home" :
			_.isNull(this.props.savedPlaces.savedWork) ? "Work" : "Custom"));
		this.props.dispatch(push("/saved-places/search"));
	};

	onClickSpecific = (e, place, type) => {
		if (!place) {
			this.props.dispatch(savedPlacesSetType(type));
			this.props.dispatch(push("/saved-places/search"));
		} else {
			this.props.dispatch(checkSelectedPlaceExist(place));
		}
	};

	onSelectPlace = (e, place, index) => {
		this.props.dispatch(checkSelectedPlaceExist(place));
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "101",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		return (
			<Fragment>
				<div className="saved-places">
					<TitleBar
						title="Saved Places"
						leftIcon={backButton}
						handleClickLeftIcon={(e) => this.props.dispatch(goBackSavedPlaces())}
						rightIcon={chatSupport}
						handleClickRightIcon={this.onSupport}
					/>
					<div className={classNames("saved-places-container", {"saved-places-no-content":
							!this.props.savedPlaces.all?.extra?.length && _.isNull(this.props.savedPlaces.savedHome) &&
							_.isNull(this.props.savedPlaces.savedWork && this.props.savedPlaces.fetchSavedPlacesLoading)
					})}>
						{this.props.savedPlaces.fetchSavedPlacesLoading ? (
							<div className="skeleton-saved-places">
								<SingleListSkeleton
									devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
								/>
							</div>
						) : !_.isNull(this.props.savedPlaces.all) && !this.props.common.loading ? (
							<SavedPlacesFavorite onClick={this.onClickSpecific} />
						) : null}

						{this.props.savedPlaces.fetchSavedPlacesLoading ? (
							<div className="skeleton-saved-places">
								<SingleListSkeleton
									devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
								/>
							</div>
						) : !_.isNull(this.props.savedPlaces.all) && !this.props.common.loading ? (
							<SavedPlacesOthers data={this.props.savedPlaces.all.extra} onClick={this.onSelectPlace} />
						) : null}

						{this.props.savedPlaces.fetchSavedPlacesLoading ? (
							<div className="skeleton-saved-places">
								<SingleListSkeleton
									devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
								/>
							</div>
						) : !this.props.savedPlaces.all?.extra?.length && _.isNull(this.props.savedPlaces.savedHome) &&
						_.isNull(this.props.savedPlaces.savedWork) ? (
							<div className="no-address-text">
								<h6>Tell us where!</h6>
								<p>Save time by saving your address; or just pick your current location.</p>
							</div>
						) : null }

						<div className={classNames("add-new-address", { "add-shadow": this.state.hasShadow })}>
							<CustomButton onClick={this.addAddress} title="Add New Address" type="button"
										  classList="" />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    ...state.search,
    common: state.common,
    map: state.map,
    savedPlaces: state.savedPlaces,
	booking: state.booking
});

export default connect(mapStateToProps)(SavedPlaces);