import { push } from 'connected-react-router';
import _ from 'lodash';

import * as DISPATCH_STATE from '../savedPlacesChangeState';
import * as CONSTANT_ACTIONS from '../savedPlacesConstant';
import { createPlaceObjectFromSavedPlaceObject, createPlainLocationObject } from "../../../../functions/create-place-object-saved-places";
import { mapSetPlaceGoogleSource } from '../../mapActions/actions/map-marker';
import {
	MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT,
	MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION,
	MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW,
} from "../../mapActions/mapConstant";
import {
	LOCATION_INPUT_BOX_NAME_DESTINATION,
	LOCATION_INPUT_BOX_NAME_SOURCE,
	LOCATION_INPUT_BOX_NAME_SECOND_SOURCE,
} from "../../../../constants/constant";
import { searchSetCurrentReferenceName } from '../../searchActions/searchChangeState';
import { fetchPrice } from "../../bookingActions";
import {
	closeLocationSearchBottomSheet,
	enableAddNextStop, fetchPriceBegin,
	fetchPriceLocationLoading,
	toggleRetryFailed,
} from "../../bookingActions/bookingChangeState";
import { mapSelectAddressFromSuggestions } from "../../mapActions";
import {
	mapInputSelectAddressFirstDestination,
	addFirstStop,
	changeCurrentLocation, setGooglePlaceObject,
} from "../../mapActions/mapChangeState";
import { fetchHome } from "../../homeActions";
import { homeProductCheckExist } from "../../homeActions/homeChangeState";
import { setDeliveringToPlace, toggleOutOfGeo } from "../savedPlacesChangeState";
import {
	setDeliveringToPlaceLocalStorage,
	setHomePickedLocationLocalStorage,
} from "../../../../functions/local-storage";
import { checkOutOfGeoboundary } from "../../bookingActions/actions/booking-service";
import { createLastUsedRequestBody, getLastUsedRequestBody, updateSavedPlaceRequestBody } from "./saved-places-gql";
import { GraphRequest } from "../../../../../axios";
import { appFetchFailure } from "../../commonActions";
import { createLastUsedObject } from "../../../../functions/create-place-object-from-google-suggestions";
import { toast } from "react-toastify";

/**
 * check for selected place exist so can put in address box
 */
export const checkSelectedPlaceExist = (savedPlace, pageName) => {
    return (dispatch, getState) => {
        let place = createPlaceObjectFromSavedPlaceObject(savedPlace);
        //when user want to change pickup location from home and then update home
        if (getState().savedPlaces.searchPointer === CONSTANT_ACTIONS.LOCATION_SEARCH_NAME) {
            dispatch(homeProductCheckExist(false));//to show the skeleton
			dispatch(setDeliveringToPlaceInfo(savedPlace));
			// dispatch(setLastUsed(savedPlace));
			dispatch(setGooglePlaceObject(savedPlace.location))
			dispatch(changeCurrentLocation(savedPlace.location))
			dispatch(mapSetPlaceGoogleSource(place));
			dispatch(closeLocationSearchBottomSheet());
			dispatch(fetchHome(pageName))
		} else if (!_.isEmpty(getState().search.currentRef)) {
			//want to add destination for booking
			dispatch(setSelectedAddressForDestination(getState().search.currentRef, place));
		}
		// else if (getState().search.currentRefName !== '') {
		//     if (getState().search.currentRefName !== '' && getState().search.currentRefName === 'destination') {
		//         dispatch(mapInputSelectAddressFirstDestination([place]));
		//     } else {
		//         dispatch(mapSetPlaceGoogleSource(place));
		//     }
		//     dispatch(push(getState().savedPlaces.searchPointer));
		// }
		else if (getState().savedPlaces.searchPointer?.includes("add")) {
			let index = getState().savedPlaces.searchPointer.split("/").pop();
			index = parseInt(index);
			if (index === MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
				dispatch(mapSetPlaceGoogleSource(place));
			} else if (index >= MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
				let places = getState().map.googlePlaces;
				places[index - 1] = place;
				dispatch(mapInputSelectAddressFirstDestination(places));
			} else if (index === MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
				let places = getState().map.googlePlaces;
				places.push(place);
				dispatch(mapInputSelectAddressFirstDestination(places));
			}
			let link = getState().savedPlaces.searchPointer;
			link = link.split("/");
			link = link.slice(0, link.length - 1);
			link = link.join("/");
			dispatch(push(link));
		}
		if(getState().savedPlaces.searchPointer?.includes("review-mymall")) {
			dispatch(checkOutOfGeoboundary(getState().booking.product.id, savedPlace)).then((result) => {
				if (result?.errors?.[0]?.extensions?.code === "403") {
					dispatch(closeLocationSearchBottomSheet());
					dispatch(toggleOutOfGeo());
				} else {
					dispatch(setDeliveringToPlaceInfo(savedPlace));
					dispatch(changeCurrentLocation(savedPlace.location))
					dispatch(setGooglePlaceObject(savedPlace.location))
					dispatch(mapSetPlaceGoogleSource(savedPlace.location));
					dispatch(homeProductCheckExist(false));
					// dispatch(setLastUsed(savedPlace));
					dispatch(closeLocationSearchBottomSheet());
				}
			});
		}
        dispatch(searchSetCurrentReferenceName(""));
    }
}

export const setLastUsed = (savedPlace) => {
	return (dispatch, getState) => {
		const createLastUsedRequest = {
			query: createLastUsedRequestBody,
			variables: {
				data: createLastUsedObject(savedPlace),
			},
		};
		dispatch(DISPATCH_STATE.addSavedPlaceLoading(true));
		GraphRequest.all(createLastUsedRequest)
			.then((res) => {
				if (!_.isNull(res.data.data) || res.data.data.createSavedPlace) {
					// dispatch(setDeliveringToPlaceInfo(res.data.data.createSavedPlace));
					// dispatch(setHomePickedLocation(res.data.data.createSavedPlace));
				} else {
					toast(`${res.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				}
				dispatch(DISPATCH_STATE.addSavedPlaceLoading(false));
			})
			.catch((e) => {
				console.error("error", e);
				dispatch(appFetchFailure(e));
			});
	}
}


export const setDeliveringToPlaceInfo = (place) => {
	return (dispatch, getState) => {
		dispatch(setDeliveringToPlace(place));
		setDeliveringToPlaceLocalStorage(place)
		dispatch(DISPATCH_STATE.setDeliveringToPlaceDone());
	}
}

export const setHomePickedLocation = (place) => {
	return () => {
		setHomePickedLocationLocalStorage(place);
	}
}

export const setSelectedAddressForDestination = (inputRef, selectedPlace) => {
    return (dispatch, getState) => {
        let params;
        if(!_.isNull(inputRef.current)) {
            switch (inputRef.current.name) {
				case LOCATION_INPUT_BOX_NAME_SOURCE: {
					// check it is the source input or the other destinations
					if (getState().map.googlePlaceIndex === 0) {
						params = 0;
						dispatch(mapSelectAddressFromSuggestions(null, inputRef, params, selectedPlace)).then(() => {
							dispatch(setFirstDestinationLocation());
						});
					} else {
						params = 1;
						dispatch(mapSelectAddressFromSuggestions(null, inputRef, params, selectedPlace)).then(() => {
							dispatch(closeLocationSearchBottomSheet());
							dispatch(enableAddNextStop());
						});
					}
					break;
				}
				case LOCATION_INPUT_BOX_NAME_SECOND_SOURCE: {
					params = -1;
					dispatch(mapSelectAddressFromSuggestions(null, inputRef, params, selectedPlace)).then(() => {
						dispatch(setDestinationToTheMinPoints(inputRef));
					});
					break;
				}
				case LOCATION_INPUT_BOX_NAME_DESTINATION: {
					params = -1;
					dispatch(mapSelectAddressFromSuggestions(null, inputRef, params, selectedPlace)).then(() => dispatch(setDestionations(inputRef)));
					break;
				}
				default: {
					break;
				}
			}
        }
    }
}

export const setFirstDestinationLocation = () => {
    return (dispatch, getState) => {
        const { minPoints} = getState().booking.product;
        //when minPoint is 3 and user add 3 stops but want to change the first one
        if (minPoints >= 2 && minPoints === getState().map.googlePlaces.length + 1) {
			dispatch(fetchPriceWithNewLocations());
		} else {
			dispatch(closeLocationSearchBottomSheet());
			dispatch(enableAddNextStop());
		}

		localStorage.removeItem("isFirstRequest");
		dispatch(addFirstStop());
        dispatch(DISPATCH_STATE.setFirstDestinationLocationDone());
    }
}

export const setDestinationToTheMinPoints = (inputRef) => {
	return (dispatch, getState) => {
		const { minPoints } = getState().booking.product;
		if (minPoints >= 2) {
			if (minPoints <= getState().map.googlePlaces.length + 1) {
				dispatch(fetchPriceWithNewLocations());
			} else {
                dispatch(closeLocationSearchBottomSheet());
				dispatch(enableAddNextStop());
				//Prevent to add duplicated destination
				if (inputRef.current.value.length) inputRef.current.value = "";
			}
            dispatch(DISPATCH_STATE.setDestinationToTheMinPointsDone());
		}
	};
};

export const setDestionations = (inputRef) => {
	return (dispatch, getState) => {
		const { minPoints, maxPoints } = getState().booking.product;
		if (minPoints <= getState().map.googlePlaces.length + 1) {
			dispatch(fetchPriceWithNewLocations());
		}
		if (maxPoints > getState().map.googlePlaces.length + 1 && inputRef.current.name === LOCATION_INPUT_BOX_NAME_DESTINATION) {
			inputRef.current.value = "";
		}
        dispatch(DISPATCH_STATE.setDestionationsDone());
	};
};

export const fetchPriceWithNewLocations = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(true));
		dispatch(fetchPrice(getState().booking.product.id, createPlainLocationObject(getState().map.location))).then(() => {
			dispatch(closeLocationSearchBottomSheet());
			dispatch(DISPATCH_STATE.fetchSavedPlacesLoading(false));
		});
		dispatch(enableAddNextStop());
    }
}
/**
 * Saved place selected address
 * @param {*} place 
 */
export const savedPlaceSelectedAddress = (place) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.selectSavedPlacesAddress(place));

        // if (getState().savedPlaces.savedType === CONSTANT_ACTIONS.SAVED_PLACE_HOME_TYPE) {
        //     dispatch(addSavedPlace(CONSTANT_ACTIONS.SAVED_PLACE_HOME_TYPE));
        // } else if (getState().savedPlaces.savedType === CONSTANT_ACTIONS.SAVED_PLACE_WORK_TYPE) {
        //     dispatch(addSavedPlace(CONSTANT_ACTIONS.SAVED_PLACE_WORK_TYPE));
        // } else {
        //     dispatch(push('/saved-places/search/add'));
        // }
    }
}