import React, { Fragment } from "react";
import { connect } from "react-redux";

import CustomButton from "../../../ui-elements/CustomButton/CustomButton";

function JobChatHistoryNoteToPartner(props) {
	return (
		<Fragment>
			<div className="Job_Chat_History_Note_To_Partner">
				<div className="J_C_H_N_T_P_Title">
					<h5>Partner Note</h5>
				</div>
				<div className="job-note-container">
					<p>{props.job.note}</p>

					<CustomButton title="Got It" onClick={props.handleClickGotItButton}/>
				</div>
			</div>

		</Fragment>
	);
}

const mapStateToProps = state => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
});

export default connect(mapStateToProps)(JobChatHistoryNoteToPartner);