import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { toast } from "react-toastify";	
import { parse } from "query-string";

import LocationSearchBox from './LocationSearchBox/LocationSearchBox';
import LocationSearchSaving from './LocationSearchSaving/LocationSearchSaving';
import LocationSearchSuggestions from './LocationSearchSuggestions/LocationSearchSuggestions';
import GoogleMap from "./GoogleMap/GoogleMap";
import PinIcon from "../icons/pin-icon";
import LocationSearchConfirm from "./LocationSearchConfirm/LocationSearchConfirm";
import buttonTimeout from "../../utils/functions/button-timeout";
import TitleBar from "../../organisms/title-bar";
import SavedPlacesFavorite from "../SavedPlaces/SavedPlacesFavorites/SavedPlacesFavorites";
import UndoButton from "./LocationBookmarked/ToastLocationBookmarked";
import SingleListSkeleton from "../ui-elements/Skeleton/SingleListSkeleton";

import {
	fetchSearchPlacesGoogle,
	mapGetJustCurrentLocation,
	mapInputSelectAddress,
	fetchSearchPlacesGoogleInit,
	updateGoogleSearchPlaces,
} from "../../utils/redux/actions/mapActions";
import { searchNavigateToMapFromLocationSearch } from '../../utils/redux/actions/searchActions';
import LocationBookmarked from "./LocationBookmarked/LocationBookmarked";

import {
	fetchSavedPlaces,
	deleteRequestPlaceOfSavedPlaces,
	addBookmarkedPlace,
	undoBookmarkedPlace,
	navigateSavedPlaces,
	setSavedPlaceLocationPointer,
} from "../../utils/redux/actions/savedPlacesActions";
import {
	savedPlacesSetType,
	savedPlaceUnSetSearchLocationPointer,
	deletePlaceOfSavedPlaces,
	savedPlacesSetNullWantedToDeletePlace
} from "../../utils/redux/actions/savedPlacesActions/savedPlacesChangeState";
import { appGoHome } from '../../utils/redux/actions/commonActions';
import { locationIsChanged } from "../../utils/redux/actions/commonActions/commonChangeState";
import { createPlaceObjectFromSavedPlaceObject } from '../../utils/functions/create-place-object-saved-places';
import { mapSelectBookmarkedAddress } from "../../utils/redux/actions/mapActions/actions/map-select-address";
import { localStorageGetIsFirstRequest } from "../../utils/functions/local-storage";

import backButton from "../../v5-icons/back-button.svg";
import mapIcon from "../../v5-icons/map.svg";

class LocationSearch extends Component {
	locationSearchBoxInput = React.createRef();
	state = { clear: true, isSearching: false };

	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		this.props.dispatch(savedPlaceUnSetSearchLocationPointer());
		if(this.props.common.auth && this.props.common.authCheck) this.props.dispatch(fetchSavedPlaces());
		this.props.dispatch(fetchSearchPlacesGoogleInit());
		this.inputSelect();
	}

	/**
	 * Select text of input search box
	 */
	inputSelect = () => {
		if (!_.isNull(this.props.map.location.lat) && !_.isNull(this.props.map.location.lng)) {
			if (this.locationSearchBoxInput.current) {
				this.locationSearchBoxInput.current.select();
			}
		}
	};

	getCurrentLocation = (e) => {
		buttonTimeout(() => this.props.dispatch(mapGetJustCurrentLocation()));
	};

	chooseOnMap = (e) => {
		buttonTimeout(() => this.props.dispatch(searchNavigateToMapFromLocationSearch()));
	};

	onNavigateSavedPlaced = (e) => {};

	onBack = (e) => {
		this.props.history.goBack();
	};

	goToMap = () => {
		this.props.dispatch(searchNavigateToMapFromLocationSearch(this.props.match.params));
	};

	onSearchPlace = (e) => {
		this.props.dispatch(fetchSearchPlacesGoogle(e.target.value));
		this.changeStateOfClear(e.target.value);
		this.changeStateOfSearching(e.target.value);
	};

	/**
	 * Change state of searching by value is empty or not
	 */
	changeStateOfSearching = (value) => {
		if (value) {
			this.setState({ isSearching: true });
		} else {
			this.setState({ isSearching: false });
		}
	};

	changeStateOfClear = (value) => {
		if (value) {
			this.setState({ clear: true });
		} else {
			this.setState({ clear: false });
		}
	};

	onSearchClick = (e, index) => {
		const params = parse(window.location.search);
		if (_.has(params, "fromHome")) {
			this.changeLocation();
		}
		this.props.dispatch(mapInputSelectAddress(index, this.locationSearchBoxInput)).then((res) => {
			this.props.dispatch(appGoHome());
			
		});
	};

	onClickSavedPlace = (e, place, type) => {
		if (place) {
			const params = parse(window.location.search);
			if (_.has(params, "fromHome")) {
				this.changeLocation();
			}
			this.props.dispatch(mapInputSelectAddress(null, this.locationSearchBoxInput, createPlaceObjectFromSavedPlaceObject(place)));
			this.props.dispatch(appGoHome());
			
		} else {
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(savedPlacesSetType(type));
			this.props.dispatch(push("/saved-places/search"));
		}
	};

	onFocus = (e, ref, index) => {
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			e.target.parentNode.classList.add("focused");
		}
	};

	onConfirm = (e) => {
		const params = parse(window.location.search);
		if (_.has(params, "fromHome")) {
			this.changeLocation();
		}
		if (!_.isNull(this.props.map.location.lat) && !_.isNull(this.props.map.location.lng)) {
			this.props.dispatch(appGoHome());
		}
	};

	changeLocation = () => {
		this.props.dispatch(locationIsChanged(true));
		if (localStorageGetIsFirstRequest()) localStorage.removeItem("isFirstRequest");
	}

	onClear = () => {
		this.locationSearchBoxInput.current.value = "";
		this.setState({ clear: false });
		this.setState({ isSearching: false });
	};

	onDeleteOtherSavedPlaces = (e, place, title) => {
		this.props.dispatch(deletePlaceOfSavedPlaces(place));
		this.props.dispatch(deleteRequestPlaceOfSavedPlaces(place)).then(() => {
			toast(
				<UndoButton
					title={`${title} address removed`}
					buttonTitle={"Undo"}
					onHandleClick={() => this.props.dispatch(undoBookmarkedPlace(this.props.savedPlaces.savedPlaceWantToDelete))}
				/>,
				{
					position: "bottom-center",
					autoClose: 5000,
					limit: 1,
					className: "black-background",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
					onClose: (e) => this.props.dispatch(savedPlacesSetNullWantedToDeletePlace()),
				},
			);
		});
	};

	handleBookmarkClick = (e, index, title) => {
		const bookmarkedPlaces = this.props.savedPlaces.all.extra;
		if (bookmarkedPlaces.length <= 10) {
			this.props.dispatch(mapSelectBookmarkedAddress(index)).then((res) => {
				this.props.dispatch(addBookmarkedPlace(res))
				this.props.dispatch(updateGoogleSearchPlaces(index));
				toast(`You have added ${res.title} to your Saved Places`, {
					position: "bottom-center",
					utoClose: 5000,
					limit: 1,
					className: "black-background",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
			});
		} else {
			toast(
				<UndoButton
					title={`Oops! You have already reached the maximum number of Saved Places`}
					buttonTitle={"MANAGE"}
					onHandleClick={() => this.props.dispatch(navigateSavedPlaces())}
				/>,
				{
					position: "bottom-center",
					utoClose: 5000,
					limit: 1,
					className: "black-background",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				},
			);
		}
	};

	render() {
		return (
			<div className="change-location">
				<TitleBar
					title="Location"
					leftIcon={backButton}
					rightIcon={mapIcon}
					handleClickLeftIcon={this.onBack}
					handleClickRightIcon={this.goToMap}
				/>
				<LocationSearchBox
					value={this.props.map.location.title || this.props.map.location.address}
					index={0}
					onFocus={this.onFocus}
					// autoFocus={true}
					autoComplete="off"
					refrence={this.locationSearchBoxInput}
					icon={<PinIcon />}
					onSearchPlace={this.onSearchPlace}
					clear={this.state.clear}
					onClear={this.onClear}
					savedPlaces={false}
					classList="search-location-container"
					placeholder=" "
				/>
				{!this.state.isSearching && this.props.common.auth && this.props.common.authCheck ? (
					<Fragment>
						{this.props.savedPlaces.fetchSavedPlacesLoading ? (
							<div className="skeleton-saved-places">
								<SingleListSkeleton
									devicewidth={
										_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
									}
								/>
							</div>
						) : (
							<div className="saved-places location-search">
								<div className="saved-places-container">
									<SavedPlacesFavorite onClick={this.onClickSavedPlace} onDelete={this.onDeleteOtherSavedPlaces} height={64} />
								</div>
							</div>
						)}

						{this.props.savedPlaces.fetchSavedPlacesLoading ? (
							<div className="skeleton-saved-places">
								<SingleListSkeleton
									devicewidth={
										_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
									}
								/>
							</div>
						) : (
							<LocationSearchSaving />
						)}
					</Fragment>
				) : null}
				{this.props.savedPlaces.fetchSavedPlacesLoading ? (
					<div className="skeleton-saved-places">
						<SingleListSkeleton
							devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
						/>
					</div>
				) : !this.state.isSearching && this.props.savedPlaces.all && !_.isEmpty(this.props.savedPlaces.all.extra) ? (
					<LocationBookmarked
						classList="location-bookmarked-container"
						removeBookmarkPlace={this.onDeleteOtherSavedPlaces}
						onSearchClick={this.onClickSavedPlace}
						places={this.props.savedPlaces.all.extra}
					/>
				) : null}
				{!_.isEmpty(this.props.map.googleSearchPlaces) ? (
					<LocationSearchSuggestions
						showBookmarkIcon={this.props.common.authCheck && this.props.common.auth}
						isSearching={this.state.isSearching}
						onSearchClick={this.onSearchClick}
						places={this.props.map.googleSearchPlaces}
						handleBookmarkClick={this.handleBookmarkClick}
					/>
				) : null}
				<div className="location-search-map-confirm">
					<LocationSearchConfirm style={{ backgroundColor: "#0488FA" }} title="Confirm address" onClick={this.onConfirm} />
				</div>
				{React.createElement(GoogleMap, { display: "none", listener: false })}
			</div>
		);
	}
}

const mapStateToProps = state => ({
    ...state.search,
    common: state.common,
    map: state.map,
    savedPlaces: state.savedPlaces
});

export default connect(mapStateToProps)(LocationSearch);