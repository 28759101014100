import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DatePicker from "react-mobile-datepicker";

import * as LOG_EVENT from "../../../utils/analytics/index";
import {
	requestScheduledBooking, setDefaultScheduleDate,
} from "../../../utils/redux/actions/bookingActions";
import {
	toggleScheduleBooking,
	setScheduleBookingDate,
	setPriceWithAplliedPromoCode, setPromoCode, setNewPrice, setAmountOff, setPercentOff,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { getReviewBookingLocalStorage, getDeliveringToPlaceLocalStorage } from "../../../utils/functions/local-storage";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";
import { getMymallScheduleTimeFirebase, remoteConfig } from "../../../utils/functions/firebase-initialize";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import AdvanceButton from "../../ui-elements/AdvanceButton/AdvanceButton";
import * as DISPATCH_STATE from "../../../utils/redux/actions/bookingActions/bookingChangeState";

const weekMap = {
	1: "Monday",
	2: "Tuesday",
	3: "Wednesday",
	4: "Thursday",
	5: "Friday",
	6: "Saturday",
	0: "Sunday",
};

const monthMap = {
	1: "Jan",
	2: "Feb",
	3: "Mar",
	4: "Apr",
	5: "May",
	6: "Jun",
	7: "Jul",
	8: "Aug",
	9: "Sep",
	10: "Oct",
	11: "Nov",
	12: "Dec",
};


class ScheduledMymallBookingBottomSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deliveringToPlace: null,
			scheduleTimeFrom: 9,
			scheduleTimeTo: 18,
			scheduleTimeLimit: 60,
			scheduleTimeStepper: 30,
			isDatePickerOpen: false,
			isTimePickerOpen: false,
			minScheduleDate: null,
			maxScheduleDate: null,
			scheduleDate: null,
			minScheduleTime: null,
			maxScheduleTime: null,
			scheduleTime: null,
			dateSubtitle: "Today",
			timeSubtitle: "--:--",
			timeTitle: "Set",
			isConfirmAndBookDisabled: true,
			isTimeSet: false,
			clicked: false,
			isDatePreparedToChoose: false
		};
	}
	componentDidMount() {
		this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() });
		getMymallScheduleTimeFirebase(remoteConfig).then((res) => {
			this.setState({ ...res,  scheduleTimeFrom: this.props.booking.product?.scheduleTimeFrom || res?.scheduleTimeFrom,
				scheduleTimeTo: this.props.booking.product?.scheduleTimeTo || res?.scheduleTimeTo,
				scheduleTimeLimit: this.props.booking.product?.scheduleTimeLimit || res?.scheduleTimeLimit}, () => {
				this.setLimitationMymallScheduleDate();
			});
		});
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.booking.scheduleBookingBottomSheet !== this.props.booking.scheduleBookingBottomSheet) {
			this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() });
		}
		return true;
	}

	handleCancel = () => {
		this.props.dispatch(toggleScheduleBooking());
		this.props.dispatch(setPriceWithAplliedPromoCode(null));
	};

	handleDateSelect = () => {
		const today = new Date();
		const currentDate = this.state.scheduleDate;
		if (currentDate.getMonth() === today.getMonth()) {
			if (currentDate.getDate() === today.getDate()) {
				this.setState({
					dateSubtitle: "Today",
					isDatePickerOpen: false,
				});
			} else if (currentDate.getDate() === today.getDate() + 1) {
				this.setState({
					dateSubtitle: "Tomorrow",
					isDatePickerOpen: false,
				});
			} else {
				this.setState({
					dateSubtitle: `${weekMap[this.state.scheduleDate.getDay()]}, ${this.state.scheduleDate.toLocaleString("default", {
						month: "long",
					})} ${this.state.scheduleDate.getDate()}`,
					isDatePickerOpen: false,
				});
			}
		} else {
			this.setState({
				dateSubtitle: `${weekMap[this.state.scheduleDate.getDay()]}, ${this.state.scheduleDate.toLocaleString("default", {
					month: "long",
				})} ${this.state.scheduleDate.getDate()}`,
				isDatePickerOpen: false,
			});
		}
		this.setState({
			timeSubtitle: "--:--",
			timeTitle: "Set",
			isConfirmAndBookDisabled: true,
			isTimeSet: false,
		});
	};

	handleTimeSelect = () => {
		this.setState({
			timeTitle: "Change",
			timeSubtitle: `${this.padTo2Digits(this.formatHoue(this.state.scheduleDate))}:${this.padTo2Digits(
				this.state.scheduleDate.getMinutes(),
			)} ${this.formatAMPM(this.state.scheduleDate)}`,
			isTimePickerOpen: false,
			isConfirmAndBookDisabled: false,
			isTimeSet: true,
		});
	};

	padTo2Digits = (num) => String(num).padStart(2, "0");

	handleSelect = () => {
		if(!this.state.clicked) {
			this.setState(() => ({ clicked: true }));
			this.props.dispatch(DISPATCH_STATE.dispatchSetDefaultScheduleDate(this.state.scheduleDate));
			this.props.dispatch(setScheduleBookingDate(this.state.scheduleDate.toISOString()));
			if (this.props.booking.product.mode.includes("MY_MALL")) {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(
						requestScheduledBooking(
							this.state.scheduleDate.toISOString(),
							this.state.deliveringToPlace ? { jobLocations: [this.state.deliveringToPlace.location] } : this.props.booking.jobLocations,
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				} else {
					this.props.dispatch(
						requestScheduledBooking(
							this.state.scheduleDate.toISOString(),
							this.state.deliveringToPlace
								? { jobLocations: [this.state.deliveringToPlace.location] }
								: this.props.location
									? this.props.location
									: this.props.booking.jobLocations,
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				}
			} else {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(requestScheduledBooking(this.state.scheduleDate.toISOString(), this.props.booking.jobLocations));
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				} else {
					this.props.dispatch(
						requestScheduledBooking(
							this.state.scheduleDate.toISOString(),
							this.props.location ? this.props.location : this.props.booking.jobLocations,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				}
			}
			this.props.dispatch(setPromoCode(null));
			this.props.dispatch(setNewPrice(null));
			this.props.dispatch(setAmountOff(null));
			this.props.dispatch(setPercentOff(null));
			LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "scheduled" });
		}
	};

	setDefaultScheduleDate = (today) => {
		let changedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes(), 0);
		this.setState({ scheduleDate: changedDate });
	};

	setMinDate = (minDate) => {
		this.setState({ minScheduleDate: minDate });
	};

	setMaxDate = (maxDate) => {
		this.setState({ maxScheduleDate: maxDate }, () => {
			this.setState({ isDatePreparedToChoose: true });
		});
	};

	setLimitationMymallScheduleDate = () => {
		const today = new Date();
		let defaultDate = new Date(today);
		if (
			today.getHours() + this.state.scheduleTimeLimit / 60 > this.state.scheduleTimeTo ||
			(today.getHours() + this.state.scheduleTimeLimit / 60 === this.state.scheduleTimeTo && today.getMinutes())
		) {
			let extraDay = 0
			if ((this.state.scheduleTimeLimit / 60) + today.getHours() + (today.getMinutes() / 60) > this.state.scheduleTimeTo) {
				extraDay = Math.ceil(((this.state.scheduleTimeLimit / 60) + today.getHours() + (today.getMinutes() / 60) - this.state.scheduleTimeTo) / 24)
			}
			defaultDate.setDate(today.getDate() + extraDay);
			this.setState({ dateSubtitle: extraDay === 1 ? "Tomorrow" : `${weekMap[defaultDate.getDay()]}, ${defaultDate.toLocaleString("default", {
					month: "long",
				})} ${defaultDate.getDate()}` });
		}
		this.setDefaultScheduleDate(new Date(defaultDate));
		this.setMinDate(new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate(), 0, 0, 0));
		this.setMaxDate(new Date(defaultDate.getTime() + 31 * 24 * 60 * 60 * 1000));
	};

	setMinTime = (minDate) => {
		this.setState({ minScheduleTime: minDate });
	};

	setMaxTime = (maxDate) => {
		this.setState({ maxScheduleTime: maxDate });
	};

	setLimitationMymallScheduleTime = () => {
		const today = new Date();
		const tempScheduleDate = new Date(this.state.scheduleDate);
		let scheduleTimeFrom = null;
		let scheduleMinFrom = null;
		if (!this.state.isTimeSet) {
			if (tempScheduleDate.getDate() === today.getDate() && tempScheduleDate.getMonth() === today.getMonth()) {
				if (today.getHours() + (today.getMinutes() / 60) + (this.state.scheduleTimeLimit / 60) <= this.state.scheduleTimeFrom) {
					scheduleTimeFrom = this.state.scheduleTimeFrom;
				} else {
					scheduleTimeFrom = today.getHours() + Math.floor(this.state.scheduleTimeLimit / 60);
					scheduleMinFrom = Math.ceil((today.getMinutes() + (this.state.scheduleTimeLimit % 60)) / this.state.scheduleTimeStepper) * this.state.scheduleTimeStepper;
				}
			} else {
				const extraDays = tempScheduleDate.getDate() - today.getDate();
				if (today.getHours() + (today.getMinutes() / 60) + ((this.state.scheduleTimeLimit / 60) - extraDays * 24) <= this.state.scheduleTimeFrom) {
					scheduleTimeFrom = this.state.scheduleTimeFrom;
				} else {
					scheduleTimeFrom = today.getHours() + (Math.floor(this.state.scheduleTimeLimit / 60) - extraDays * 24);
					scheduleMinFrom = Math.ceil((today.getMinutes() + (this.state.scheduleTimeLimit % 60)) / this.state.scheduleTimeStepper) * this.state.scheduleTimeStepper;
				}
			}
			this.setState({ scheduleDate: new Date(tempScheduleDate.setHours(scheduleTimeFrom, scheduleMinFrom)) });
			this.setMinTime(
				new Date(
					tempScheduleDate.getFullYear(),
					tempScheduleDate.getMonth(),
					tempScheduleDate.getDate(),
					scheduleTimeFrom,
					scheduleMinFrom,
					0,
				),
			);
		} else {
			this.setState({
				scheduleDate: new Date(tempScheduleDate.setHours(this.state.scheduleDate.getHours(), this.state.scheduleDate.getMinutes())),
			});
			this.setMinTime(
				new Date(
					tempScheduleDate.getFullYear(),
					tempScheduleDate.getMonth(),
					tempScheduleDate.getDate(),
					this.state.scheduleDate.getHours(),
					this.state.scheduleDate.getMinutes(),
					0,
				),
			);
		}
		this.setMaxTime(
			new Date(tempScheduleDate.getFullYear(), tempScheduleDate.getMonth(), tempScheduleDate.getDate(), this.state.scheduleTimeTo, 0, 0),
		);
	};

	handleDateChange = (time) => {
		this.setDefaultScheduleDate(time);
		// this.props.dispatch(DISPATCH_STATE.dispatchSetDefaultScheduleDate(time));
	};

	handleTimeChange = (time) => {
		this.setDefaultScheduleDate(time);
		// this.props.dispatch(DISPATCH_STATE.dispatchSetDefaultScheduleDate(time));
	};

	onDatePickerClick = () => {
		this.setState({ isDatePickerOpen: true });
	};

	onDatePickerCancel = () => {
		this.setState({ isDatePickerOpen: false });
	};

	onTimePickerClick = () => {
		this.setState({ isTimePickerOpen: true });
		this.setLimitationMymallScheduleTime();
	};

	onTimePickerCancel = () => {
		this.setState({ isTimePickerOpen: false });
	};
	formatAMPM = (date) => {
		let hours = date.getHours();
		let ampm = hours >= 12 ? "PM" : "AM";
		return ampm;
	};
	formatHoue = (date) => {
		let hours = date.getHours();
		hours = hours % 12;
		hours = hours ? (hours < 10 ? `0${hours}` : hours) : 12; // the hour '0' should be '12'
		return hours;
	};
	render() {
		const datePickerConfig = {
			date: {
				format: (value) => `${weekMap[value.getDay()].slice(0, 3)} ${value.getDate()} ${monthMap[value.getMonth() + 1]}`,
				caption: "Date",
				step: 1,
			},
		};

		const timePickerConfig = {
			hour: {
				// format: "hh",
				format: (value) => this.formatHoue(value),
				caption: "Hour",
				step: 1,
			},
			minute: {
				format: "mm",
				caption: "Minute",
				step: this.state.scheduleTimeStepper,
			},
			second: {
				format: (value) => this.formatAMPM(value),
				caption: "s",
				step: 21600,
			},
		};
		return (
			<>
				<div className="schedule-container">
					<div className="schedule-header">
						<div className="schedule-title">When do you need this?</div>
						{/* <div className="schedule-subtitle">Schedule it for later</div> */}
					</div>

					<div className="schedule-date-btn">
						<AdvanceButton title="Date" subtitle={this.state.dateSubtitle} buttonTitle="Change" onClick={this.state.isDatePreparedToChoose ? this.onDatePickerClick : undefined} />
						<AdvanceButton
							title="Time"
							subtitle={this.state.timeSubtitle}
							buttonTitle={this.state.timeTitle}
							onClick={this.state.isDatePreparedToChoose ? this.onTimePickerClick : undefined}
						/>
					</div>

					<div className="schedule-action-btn">
						<CustomButton
							disabled={this.state.isConfirmAndBookDisabled}
							variant="contained"
							type="button"
							title="Request this schedule"
							classList="confirm-and-book"
							onClick={() => this.handleSelect()}
						/>
						<CustomButton
							outline={true}
							variant="outlined"
							classList="cancel-booking"
							type="button"
							title="Cancel"
							onClick={() => this.props.dispatch(toggleScheduleBooking())}
						/>
					</div>
				</div>
				{this.state.isDatePickerOpen && (
					<div className="queued-jobs-container">
						<DatePicker
							theme="ios"
							showCaption={true}
							isPopup={true}
							value={this.state.scheduleDate}
							min={this.state.minScheduleDate}
							max={this.state.maxScheduleDate}
							isOpen={this.state.isDatePickerOpen}
							onSelect={this.handleDateSelect}
							onCancel={this.onDatePickerCancel}
							onChange={this.handleDateChange}
							dateConfig={datePickerConfig}
							confirmText={
								this.props.booking.scheduleLoading ? (
									<div className="next-button-loading">
										<WhiteLoading />
									</div>
								) : (
									"Confirm"
								)
							}
							cancelText="Cancel"
							headerFormat="MM/DD hh:mm"
							customHeader={
								<div className="custom-date-time-picker-header">
									<h2 className="queued-jobs-container-title">On what day?</h2>
								</div>
							}
						/>
					</div>
				)}
				{this.state.isTimePickerOpen && (
					<div className="queued-jobs-container">
						<DatePicker
							theme="ios"
							showCaption={true}
							isPopup={true}
							value={this.state.scheduleDate}
							min={this.state.minScheduleTime}
							max={this.state.maxScheduleTime}
							isOpen={this.state.isTimePickerOpen}
							onSelect={this.handleTimeSelect}
							onCancel={this.onTimePickerCancel}
							onChange={this.handleTimeChange}
							dateConfig={timePickerConfig}
							confirmText={
								this.props.booking.scheduleLoading ? (
									<div className="next-button-loading">
										<WhiteLoading />
									</div>
								) : (
									"Confirm"
								)
							}
							cancelText="Cancel"
							headerFormat="MM/DD hh:mm"
							customHeader={
								<div className="custom-date-time-picker-header">
									<h2 className="queued-jobs-container-title">What time?</h2>
								</div>
							}
						/>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(ScheduledMymallBookingBottomSheet);