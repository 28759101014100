import React, { Fragment, Component } from 'react';
import {connect} from 'react-redux';
import _ from "lodash";

import { toggleWebWidgetWithoutClient, closeSupportWidget, setCloseListener } from "../../utils/redux/actions/supportActions";
import { fetchClient } from "../../utils/redux/actions/commonActions";
import { supportWillUnMount } from "../../utils/redux/actions/supportActions/supportChangeState";

class Support extends Component {
	componentDidMount() {
		if (!_.isEmpty(this.props.common.client) || (!this.props.common.auth && !this.props.common.authCheck)) {
			this.props.dispatch(toggleWebWidgetWithoutClient());
			this.props.dispatch(setCloseListener());
		} else {
			if (this.props.common.auth && this.props.common.authCheck) {
				this.props.dispatch(fetchClient()).then((res) => {
					if (res) {
						this.props.dispatch(toggleWebWidgetWithoutClient());
						this.props.dispatch(setCloseListener());
					}
				});
			}
		}
	}

	componentWillUnmount() {
		this.props.dispatch(closeSupportWidget());
		this.props.dispatch(supportWillUnMount());
	}

	render() {
		return <Fragment></Fragment>;
	}
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(Support);