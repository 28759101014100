import React, { Component } from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import GoogleMap from '../../../LocationSearch/GoogleMap/GoogleMap';

import { navigateToPartnerLivaLocation } from "../../../../utils/redux/actions/jobsActions";
const classNames = require("classnames");

class JobChatHistoryLiveLocation extends Component  {

    render() {
        return (
			<div className={classNames({
				"job-chat-history-live-location": true,
				"live-location-top": this.props.jobState === "Queued" && this.props.job.product.mode.includes("MY_MALL")
			})} style={{ visibility: _.isNull(this.props.markers) ? "hidden" : "visible" }}>
				<GoogleMap classList="job-chat-history-live-location-map" listener={true} />
				{this.props.viewFullScreen && (
					<button className="view-full-screen" onClick={(e) => this.props.dispatch(navigateToPartnerLivaLocation())}>
						View full screen
					</button>
				)}
			</div>
		);
    }
}

const mapStateToProps = state => ({
	...state.jobs,
    map: state.map,
    common: state.common,
});

export default connect(mapStateToProps)(JobChatHistoryLiveLocation);