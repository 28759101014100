import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import BillingInformationCardItem from '../BillingInformationCardItem/BillingInformationCardItem';

function BillingInformationCard(props) {
    return (
        <Fragment>
            <div className="billing-information-card">
                {props.methods.map((method, index) => {
                    return <BillingInformationCardItem key={index}
                        onDelete={props.onDelete}
                        method={method} onChangePrimary={props.onChangePrimary}/>
                })}
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    ...state.billing,
});

export default connect(mapStateToProps)(BillingInformationCard);