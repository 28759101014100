import React, {Fragment} from 'react';
import {connect} from 'react-redux';
const classNames = require("classnames");

function LocationSearchDestinationBox(props) {

    return (
		<Fragment>
			<div className={classNames(props.classList, "location-search-destination-box")}>
				{!props.savedPlaces ? (
					<div className="pin-icon-wrapper">
						{props.icon}
						<span className="number-destination">{props.number && props.number}</span>
					</div>
				) : null}
				<div className="location-search-box-inner">
					<input
						type="text"
						id={props.id}
						autoComplete={props.autoComplete}
						name={props.name}
						onFocus={(e) => props.onFocus(e, props.refrence, props.index)}
						ref={props.refrence}
						defaultValue={props.value}
						onKeyUp={props.onSearchPlace}
						autoFocus={props.autoFocus}
						className="location-search-box-input"
						placeholder={props.placeholder ? props.placeholder : "Add a stop (Optional)"}
					/>
				</div>
			</div>
		</Fragment>
	);
}


const mapStateToProps = state => ({
    map: state.map,
    booking: state.booking
});

export default connect(mapStateToProps)(LocationSearchDestinationBox);