import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import BillingInformationGcashItem from "./BillingInformationGcashItem";
import { ReactComponent as ForwardImg } from "../../../../../v5-icons/forward.svg";
class BillingInformationGcash extends Component {
	
	render() {
		return (
			<Fragment>
				{!_.isNull(this.props.gcashItems) && this.props.gcashItems.length ? (
				<div className="billing-information-card">
						{this.props.gcashItems.map((item, index) => {
							if (item.isPending) {
								return (
									<div className="billing-information-card-item GCash" key={index}>
										<div className="billing-information-card-item-first-row">
											<div className="billing-information-card-item-img">
												<img src={this.props.gcashIcon} alt="Gcash icon" className="billing-card-image" />
											</div>
											<div className="billing-information-card-item-value" onClick={this.props.toggleVerifyingGcashBottomSheet}>
												<p className="connect-to-gcash-wallet">
													Verifying
													<ForwardImg />
												</p>
											</div>
										</div>
									</div>
								);
							} else {
								return (
									<BillingInformationGcashItem
										key={index}
										onDelete={this.props.onDelete}
										onChangePrimary={this.props.onChangePrimary}
										method={item}
										icon={this.props.gcashIcon}
										isBottomSheet={this.props.isBottomSheet}
									/>
								);
							}
						})}
					</div>
				) : (
					<div className="billing-information-card">
						<div className="billing-information-card-item GCash">
							<div className="billing-information-card-item-first-row">
								<div className="billing-information-card-item-img">
									<img src={this.props.gcashIcon} alt="Gcash icon" className="billing-card-image" />
								</div>
								<div className="billing-information-card-item-value" onClick={this.props.toggleGcashBottomSheet}>
									<p className="connect-to-gcash-wallet">
										Connect
										<ForwardImg />
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.billing,
});

export default connect(mapStateToProps)(BillingInformationGcash);
