import {
	FETCH_NEWS_BEGIN,
	FETCH_NEWS_SUCCESS,
	WILL_UNMOUNT_NEWS,
	SELECT_ARTICLE_FROM_NEWS,
	FETCH_NEWS_ARTICLE_BEGIN,
	FETCH_NEWS_ARTICLE_SUCCESS,
	WILL_UNMOUNT_ARTICLE,
	NEWS_SET_SEARCH_LOCATION_POINTER,
	NEWS_LOADING,
	DETAIL_NEWS_LOADING,
} from "../actions/newsActions";
  
    const initialState = {
        news: {},
        article: {},
        searchLocationPointer: null,
        newsLoading: false,
        detailNewsLoading: false,
    };
    
    export default function newsReducer(state = initialState, action) {
      switch(action.type) {
        case FETCH_NEWS_BEGIN:
          return {
            ...state,
          };
    
        case FETCH_NEWS_SUCCESS:
          return {
            ...state,
            news: action.payload,
          };
        case WILL_UNMOUNT_NEWS:
          return {
            ...state,
            news: {},
            };
        case WILL_UNMOUNT_ARTICLE:
          return {
            ...state,
            article: {},
            };
        case SELECT_ARTICLE_FROM_NEWS:
          return {
            ...state,
            article: action.payload,
            };
        case FETCH_NEWS_ARTICLE_BEGIN:
          return {
            ...state,
            };
        case FETCH_NEWS_ARTICLE_SUCCESS:
          return {
            ...state,
            article: action.payload,
            };
        case NEWS_SET_SEARCH_LOCATION_POINTER:
          return {
            ...state,
            searchLocationPointer: action.payload
          }
        case NEWS_LOADING:
          return {
            ...state,
            newsLoading: action.payload
          }
        case DETAIL_NEWS_LOADING:
          return {
            ...state,
            detailNewsLoading: action.payload
          }
        default:
          // ALWAYS have a default case in a reducer
          return state;
      }
    }