import React, {Fragment} from 'react';
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

import './job-chat-history-camera.css';

export default function JobChatHistoryCamera(props) {

    return (
        <Fragment>
            <div onClick={props.onCamera} className="Job-chat-history-actions  no-padding text-center pull-right">
                <button className="Job-chat-history-emoji-button" type="button">
					<DynamicIcon icon={props.icon} classes={props.iconClasses}/>
                </button>
                <input type="file" 
                    onChange={props.inputFileCameraChange}
                    ref={props.inputCameraRef}
                    accept="image/*" 
                    capture="camera" 
                    className="hidden" />
            </div>
        </Fragment>
    );
}