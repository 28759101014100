import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as moment from "moment-timezone";

import JobLocations from "../../JobMatching/JobLocations/JobLocations";
import TitleBar from "../../../../organisms/title-bar";

import backButton from "../../../../v5-icons/close-thin.svg";
import { ReactComponent as SuiteCase } from "../../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../../v5-icons/clock-small.svg";
import { ReactComponent as Note } from "../../../../v5-icons/note-small.svg";
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import RequestPlacedImage from "../../../../v5-icons/request-placed.png";
import { ReactComponent as High } from "../../../../v5-icons/high.svg";
import { ReactComponent as Kuyas } from "../../../../v5-icons/request-placed-kuya.svg";
import { ReactComponent as Calendar } from "../../../../v5-icons/calendar.svg";

import {
	toggleRequestPlacedModal,
	setPriceWithAplliedPromoCode,
	willUnMountReviewBooking,
} from "../../../../utils/redux/actions/bookingActions/bookingChangeState";
import { googlePlacesMatching } from "../../../../utils/functions/google-places-matching";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import Moment from "../../../moment/moment";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require("classnames");

const weekMap = {
	1: "Monday",
	2: "Tuesday",
	3: "Wednesday",
	4: "Thursday",
	5: "Friday",
	6: "Saturday",
	0: "Sunday",
};

class RequestPlaced extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			weekDay: null,
		};
	}
	componentDidMount() {
		if (!_.isNull(this.props.booking.scheduleDate)) {
			const opendJobDate = moment(this.props.booking.scheduleDate, "YYYY-MM HH:mm:ss");
			const opendJobWeekDay = opendJobDate.format("dddd");
			this.setState(() => ({
				weekDay: opendJobWeekDay,
			}));
		}
	}
	onCancel = (e) => {
		this.props.dispatch(toggleRequestPlacedModal());
	};

	componentWillUnmount() {
		this.props.dispatch(setPriceWithAplliedPromoCode(null));
		this.props.dispatch(willUnMountReviewBooking());
	}

	render() {
		const { props } = this;
		const jobCost = {
			currency: props.booking.prices.items[props.booking.currentPrice]["price"]["currency"],
			value: props.booking.prices.items[props.booking.currentPrice]["price"]["value"],
		};

		return (
			<div className="request-placed">
				<TitleBar
					leftIcon={backButton}
					lozengeObject={{ title: "OPEN", color: "yellow", heading: this.props.booking.product.commercialName }}
					handleClickLeftIcon={this.onCancel}
				/>
				<div className="request-placed-image">
					<img src={RequestPlacedImage} alt="request-placed" />
				</div>
				<div className="matching-process">
					<div className="matching-process-title">
						<div className="booking-button-icon">
							<Kuyas />
						</div>
						<div className="title">
							<p>Request Placed</p>
							<span className="text-secondary">Get matched once a partner is ready</span>
						</div>
					</div>

					<div className="matching-process-container">
						<div className="matching-process-row">
							<SuiteCase className="icon" />
							<p className="commercial-name">{props.booking.product.commercialName}</p>
						</div>
						<div className="matching-process-row">
							<JobLocations locations={props.booking.jobLocations.jobLocations} />
						</div>
						<div className="matching-process-row">
							<Clock className="icon" />
							<p>
								{jobCost && props.common.client.currency.signText}{" "}
								{!_.isNull(props.priceWithAppliedCode) ? props.priceWithAppliedCode : jobCost.value} for {props.booking.formattedTime}
								, extendable
							</p>
						</div>

						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance <= 0 ? (
							<div className="matching-process-row payment">
								<img
									src={props.primaryPayment[0].icon}
									alt={props.primaryPayment[0].type}
									className={classNames("billing-card-image", props.primaryPayment[0].type)}
								/>
								<p>
									Pay via {!_.isNull(props.primaryPayment[0].name) ? props.primaryPayment[0].name : props.primaryPayment[0].type}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}
						{_.isEmpty(props.primaryPayment) ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
							</div>
						) : null}

						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance > 0 ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>
									Pay via mykoins then {props.primaryPayment[0].type}{" "}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}

						{props.job.state === "QUEUE_CREATED" ? (
							<div className="matching-process-row job-state">
								<High className="icon" />
								<p>Queued</p>
							</div>
						) : null}

						{props.job.state === "SCHEDULE_CREATED" && !_.isNull(this.state.weekDay) ? (
							<div className="matching-process-row job-state">
								<High className="icon" />
								<p>
									Scheduled for{" "}
									{/*{this.state.weekDay.indexOf("Invalid") === -1 ? <span>{this.state.weekDay.slice(0, 3)} </span> : ""}*/}
									{weekMap[new Date(props.booking.scheduleDate).getDay()].slice(0, 3)}{" "}
									<Moment format="DD MMM , h:mmA">{props.booking.scheduleDate}</Moment>
								</p>
							</div>
						) : null}

						<div className="matching-process-row note">
							<Note className="icon" />
							{!_.isEmpty(props.booking.note) ? <p>{props.booking.note}</p> : <p>No note to partner</p>}
						</div>
						<div className="request-placed-confirm-button">
							<CustomButton onClick={() => this.onCancel()} type="button" title="Confirm" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	chat: state.chat,
	common: state.common,
	locations: googlePlacesMatching(state.map),
	isInstantMatch: state.booking.isInstantMatch,
	primaryPayment: state.common.primaryPayment,
	billing: state.billing,
	priceWithAppliedCode: state.booking.priceWithAppliedCode,
});

export default connect(mapStateToProps)(RequestPlaced);
