import * as CONSTANT_ACTIONS from './supportConstant';

/**
 * Initialize close listener state
 */
export const initCloseLinstener = () => ({
    type: CONSTANT_ACTIONS.SUPPORT_WEB_WIDGET_CLOSE_LISTENER
});

/**
 * support will un mount
 */
export const supportWillUnMount = () => ({
    type: CONSTANT_ACTIONS.SUPPORT_WEB_WIDGET_WILL_UNMOUNT
})

export const supportWebWidgetToggle = () => ({
    type: CONSTANT_ACTIONS.SUPPORT_WEB_WIDGET_TOGGLE
})

export const supportWebWidgetCloseByBack = () => ({
    type: CONSTANT_ACTIONS.SUPPORT_WEB_WIDGET_CLOSE_BY_BACK
});

export const navigateToSupport = () => ({
    type: CONSTANT_ACTIONS.NAVIGATE_TO_SUPPORT
})