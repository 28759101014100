import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import { push } from 'connected-react-router';

import './location-search-booking-add-search.css';
import LocationSearchHeader from '../../LocationSearch/location-search-header/location-search-header';
import FixedBackground from '../../fixed-background/fixed-background';
import LocationSearchBox from '../../LocationSearch/LocationSearchBox/LocationSearchBox';
import LocationSearchSavedPlaceSlider from '../../LocationSearch/location-search-saved-place-slider/location-search-saved-place-slider';
import LocationSearchSaving from "../../LocationSearch/LocationSearchSaving/LocationSearchSaving";
import LocationSearchSuggestions from '../../LocationSearch/LocationSearchSuggestions/LocationSearchSuggestions';
import { fetchSearchPlacesGoogle, mapSelectAddressFromSuggestions } from '../../../utils/redux/actions/mapActions';
import GoogleMap from '../../LocationSearch/GoogleMap/GoogleMap';
import PinIcon from '../../icons/pin-icon';
import { appGoBack } from '../../../utils/redux/actions/commonActions';
import { searchBookingSearchInit, searchBookingLocationStopInit, searchNavigateToMapFromLocationSearch, searchCheckBookingProductExist } from '../../../utils/redux/actions/searchActions';
import LocationSearchConfirm from '../../LocationSearch/LocationSearchConfirm/LocationSearchConfirm';
import { fetchSavedPlaces, savedPlacesSetType, savedPlaceUnSetSearchLocationPointer } from '../../../utils/redux/actions/savedPlacesActions';
import {createPlaceObjectFromSavedPlaceObject } from '../../../utils/functions/create-place-object-saved-places';

class LocationSearchBookingAddSearch extends Component {

    locationSearchBoxInput = React.createRef();
    state = {clear: false, isSearching: false};

    componentDidMount() {
        this.props.dispatch(savedPlaceUnSetSearchLocationPointer());
        this.props.dispatch(fetchSavedPlaces());
        this.props.dispatch(searchCheckBookingProductExist());
        this.props.dispatch(searchBookingLocationStopInit());
        this.props.dispatch(searchBookingSearchInit(this.locationSearchBoxInput, parseInt(this.props.match.params.index)));
    }

    onNavigateSavedPlaced = (e) => {}

    onBack = (e) => {
        this.props.dispatch(appGoBack());
    }

    goToMap = () => {
        this.props.dispatch(searchNavigateToMapFromLocationSearch(this.props.match.params));
    }

    onSearchPlace = (e) => {
        this.props.dispatch(fetchSearchPlacesGoogle(e.target.value));
        this.changeStateOfClear(e.target.value);
        this.changeStateOfSearching(e.target.value);
    }

    /**
     * Change state of searching by value is empty or not
     */
    changeStateOfSearching = (value) => {
        if (value) {
            this.setState({isSearching: true});
        } else {
            this.setState({isSearching: false});
        }
    }

    changeStateOfClear = (value) => {
        if (value) {
            this.setState({clear: true});
        } else {
            this.setState({clear: false});
        }
    }

    onSearchClick = (e, index) => {
        this.props.dispatch(mapSelectAddressFromSuggestions(index, this.locationSearchBoxInput, parseInt(this.props.match.params.index)));
    }

    onClickSavedPlace = (e, place, type) => {
        if (place) {
            this.props.dispatch(mapSelectAddressFromSuggestions(null, this.locationSearchBoxInput, parseInt(this.props.match.params.index), createPlaceObjectFromSavedPlaceObject(place)));
        } else {
            this.props.dispatch(savedPlacesSetType(type));
            this.props.dispatch(push('/saved-places/search'));
        }
    }

    onFocus = (e, ref, index) => {}

    onConfirm = () => {
        this.props.dispatch(appGoBack());
    }

    onClear = () => {
        this.locationSearchBoxInput.current.value = '';
        this.setState({clear: false});
        this.setState({isSearching: false});
    }

    render() {
        return (
            <Fragment>
                <FixedBackground />
                <LocationSearchHeader goToMap={this.goToMap} onBack={this.onBack}  />
                <LocationSearchBox
                    index={this.props.match.params.index}
                    onFocus={this.onFocus}
                    autoFocus={true}
                    refrence={this.locationSearchBoxInput}
                    icon={<PinIcon />}
                    onSearchPlace={this.onSearchPlace}
                    clear={this.state.clear}
                    onClear={this.onClear}
                    savedPlaces={false} />
                    {!this.state.isSearching ?
                        <Fragment>
                            <LocationSearchSavedPlaceSlider onClick={this.onClickSavedPlace}/>
                            <LocationSearchSaving />
                        </Fragment>
                    : ''}
                {(!_.isEmpty(this.props.map.googleSearchPlaces)) ? 
                    <LocationSearchSuggestions showBookmarkIcon={this.props.common.authCheck && this.props.common.auth} onSearchClick={this.onSearchClick} places={this.props.map.googleSearchPlaces} />
                : ''}
                <div className="Location-search-booking-confirm">
                    <LocationSearchConfirm style={{'backgroundColor': '#0488FA'}} title="Confirm" onClick={this.onConfirm} />
                </div>
                {React.createElement(GoogleMap, {display: 'none', listener: false})}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    ...state.search,
    common: state.common,
    map: state.map
});

export default connect(mapStateToProps)(LocationSearchBookingAddSearch);