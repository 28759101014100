import * as CONSTANT_ACTIONS from './registerConstant';

export const registerFetchCountryCode = countries => ({
    type: CONSTANT_ACTIONS.REGISTER_FETCH_COUNTRY_CODE,
    payload: countries
});

export const setDefaultCountryCode = defaultCountry => ({
    type: CONSTANT_ACTIONS.SET_DEFAULT_COUNTRY_CODE,
    payload: defaultCountry
});

export const registerFetchCities = cities => ({
    type: CONSTANT_ACTIONS.REGISTER_FETCH_CITIES,
    payload: cities
});

export const registerPhoneNumberValidUnSet = () => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_VALUE_UNSET
});

export const registerPhoneNumberRequestFinish = () => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_REQUEST_FINISH
});

export const checkCodeHandlerAction = code => ({
    type: CONSTANT_ACTIONS.REGISTER_CODE_VALUE_SET,
    payload: code
});

export const registerCheckCodeReduceCount = countNumber => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CODE_REDUCE_COUNT,
    payload: countNumber
});

export const registerCheckCodeInterval = interval => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CODE_INTERVAL,
    payload: interval
});

export const registerBackToWelcomeDispatch = () => ({
    type: CONSTANT_ACTIONS.REGISTER_BACK_TO_WELCOME
});

export const registerChangeCountrySelectedDispatch = (selected) => ({
    type: CONSTANT_ACTIONS.REGISTER_CHANGE_COUNTRY_SELECTED,
    payload: selected
});

export const phoneNumberHandlerAction = number => ({
    type: CONSTANT_ACTIONS.REGISTER_PHONE_NUMBER_VALUE_SET,
    payload: number
});

export const intervalCheckCodeBegin = () => ({
    type: CONSTANT_ACTIONS.INTERVAL_CHECK_CODE_BEGIN,
    payload: {}
});

export const registerCheckVerificationCode = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_VERIFICATION_CODE,
    payload: {}
});

export const registerCheckClientExistDispatch = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_CLIENT_EXIST
});

export const registerCheckRedirectToRegisterPage = () => ({
    type: CONSTANT_ACTIONS.REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE
});

export const clearIntervalNumberCheckcodeInterval = () => ({
    type: CONSTANT_ACTIONS.CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL
});

export const postPhoneNumberHasError = () => ({
    type: CONSTANT_ACTIONS.POST_PHONE_NUMBER_HAS_ERROR
});

export const setLivingCity = (city) => ({
    type: CONSTANT_ACTIONS.SET_LIVING_CITY,
    payload: city
});

export const toggleSelectCityModal = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_SELECT_CITY_MODAL
});

export const verificationLoading = (condition) => ({
    type: CONSTANT_ACTIONS.VERIFICATION_LOADING,
    payload: condition
});

export const enterVerificationCodeLoading = (condition) => ({
    type: CONSTANT_ACTIONS.ENTER_VERIFICATION_CODE_LOADING,
    payload: condition
});

export const registerClientLoading = (condition) => ({
    type: CONSTANT_ACTIONS.REGISTER_CLIENT_LOADING,
    payload: condition
});

export const fetchCitiesLoading = (condition) => ({
    type: CONSTANT_ACTIONS.FETCH_CITIES_LOADING,
    payload: condition
});
