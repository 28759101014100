import React, {Fragment, Component} from 'react';
import TextareaAutosize from "react-textarea-autosize";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from 'lodash';
import $ from 'jquery';
import {connect} from 'react-redux';
import { push } from 'connected-react-router';

import {
	checkSavedPlacesExist,
	updateSavedPlace,
	editLocationSavedPlace,
	setSavedPlaceLocationPointer,
	savedPlacesSetType,
	addSavedPlace,
	setEventForOpenMapOnSavedPlaces, setAddressDetailsForm,
} from "../../../utils/redux/actions/savedPlacesActions";
import * as constant from '../../../utils/redux/actions/savedPlacesActions/savedPlacesConstant';

import TitleBar from "../../../organisms/title-bar";
import backButton from "../../../v5-icons/back-button.svg";
import CustomButton from '../../ui-elements/CustomButton/CustomButton';
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";
import { ReactComponent as Home } from"../../../v5-icons/home.svg";
import { ReactComponent as Work } from "../../../v5-icons/suite-case-colorful.svg";
import GoogleMap from "../../LocationSearch/GoogleMap/GoogleMap";
import { ReactComponent as PinImg } from "../../../assests/icons/new-locationPin.svg";
import EllipseIcon from "../../icons/ellipse-icon";
import { mapinitializeFinishIcon, setGooglePlaceObject } from "../../../utils/redux/actions/mapActions/mapChangeState";
import { setNavigationBlackBox } from "../../../utils/redux/actions/commonActions/commonChangeState";
const classNames = require("classnames");

const UpdateSchema = Yup.object().shape({
	address: Yup.string().required(),
	name: Yup.string().required(),
	details: Yup.string().required(),
});
class SavedPlacesUpdateAddress extends Component {
	finish = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			address: this.props.savedPlaces.savedPlace ? this.props.savedPlaces.savedPlace.address ? this.props.savedPlaces.savedPlace.address : this.props.savedPlaces.savedPlace.location.address : "",
			name:
				this.props.savedPlaces.addressDetailsForm?.name || (this.props.savedPlaces.savedType !== constant.SAVED_PLACE_CUSTOM_TYPE
					? this.props.savedPlaces.savedType
					: this.props.savedPlaces.savedPlace.title),
			details: this.props.savedPlaces.addressDetailsForm?.details || (this.props.savedPlaces.savedPlace
				? !_.isNull(this.props.savedPlaces.savedPlace.location.data)
					? this.props.savedPlaces.savedPlace.location.data
					: ""
				: ""),
			note: this.props.savedPlaces.addressDetailsForm?.note || (this.props.savedPlaces.savedPlace
				? !_.isNull(this.props.savedPlaces.savedPlace.note)
					? this.props.savedPlaces.savedPlace.note
					: ""
				: ""),
			phoneCall: this.props.savedPlaces.addressDetailsForm?.phoneCall || (this.props.savedPlaces.savedPlace ? (this.props.savedPlaces.savedPlace.phoneCall === "DO_NOT_CALL_ME" ? true : false) : false),
			savedPlaceType: this.props.savedPlaces.addressDetailsForm?.savedPlaceType || this.props.savedPlaces.savedType,
			currentType: this.props.savedPlaces.savedType,
			editingType: this.props.savedPlaces.savedType,
			isFocused: false,
		};

		this.props.dispatch(setAddressDetailsForm(
			{
				name:
					this.props.savedPlaces.addressDetailsForm?.name || (this.props.savedPlaces.savedType !== constant.SAVED_PLACE_CUSTOM_TYPE
						? this.props.savedPlaces.savedType
						: this.props.savedPlaces.savedPlace.title),
				details: this.props.savedPlaces.addressDetailsForm?.details || (this.props.savedPlaces.savedPlace
					? !_.isNull(this.props.savedPlaces.savedPlace.location.data)
						? this.props.savedPlaces.savedPlace.location.data
						: ""
					: ""),
				note: this.props.savedPlaces.addressDetailsForm?.note || (this.props.savedPlaces.savedPlace
					? !_.isNull(this.props.savedPlaces.savedPlace.note)
						? this.props.savedPlaces.savedPlace.note
						: ""
					: ""),
				phoneCall: this.props.savedPlaces.addressDetailsForm?.phoneCall || (this.props.savedPlaces.savedPlace ? (this.props.savedPlaces.savedPlace.phoneCall === "DO_NOT_CALL_ME" ? true : false) : false),
				savedPlaceType: this.props.savedPlaces.addressDetailsForm?.savedPlaceType || this.props.savedPlaces.savedType,
			}
		))

	}

	componentDidMount() {
		this.props.dispatch(checkSavedPlacesExist());
		this.props.dispatch(mapinitializeFinishIcon(this.finish));
		this.props.dispatch(setNavigationBlackBox({from: "", goBack: ""}));
		if (typeof window !== "undefined") {
			document.addEventListener("keydown", this.onKeyDown, true);
		}

		this.setState({currentType: this.props.savedPlaces.addressDetailsForm?.savedPlaceType || this.props.savedPlaces.savedType });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyDown, true);
	}

	onKeyDown = (e) => {
		if ((e.charCode || e.keyCode) === 13 || e.key === "Enter") {
			if (!e.target.parentNode.classList[0].includes("textarea")) {
				e.preventDefault();
				$("#mapView").fadeIn(300, () => this.setState({isFocused: false}));
				e.target.parentNode.classList.remove("focused");
			}
		}
	}


	onConfirm = (values) => {
		this.props.dispatch(savedPlacesSetType(this.state.currentType));
		if (this.props.common.navigationBlackBox.action === "updateAddress") {
			this.props.dispatch(addSavedPlace(values));
		} else {
			this.props.dispatch(updateSavedPlace(values, this.props.savedPlaces.savedPlace.id));
		}
	};

	toggleFocusedClass(e) {
		$("#mapView").fadeOut(300, () => this.setState({isFocused: true}));
		e.target.parentNode.classList.add("focused");
	}

	blur(e) {
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		}
	}

	goToMap = () => {
		this.props.dispatch(setNavigationBlackBox({from: "editAddress", goBack: "/saved-places/search/edit"}))
		this.props.dispatch(setGooglePlaceObject(this.props.savedPlaces.savedPlace.location));
		this.props.dispatch(setEventForOpenMapOnSavedPlaces({ isEdited: true, productId: !_.isNull(this.props.product) || !_.isEmpty(this.props.product) ? this.props.product.id : null }));
		this.props.dispatch(push("/saved-places/search"));
	}

	render() {
		const hasHome = !_.isNull(this.props.savedPlaces.savedHome);
		const hasWork = !_.isNull(this.props.savedPlaces.savedWork);
		
		return (
			<div className="saved-places-address-detail">
				<TitleBar
					title="Address Details"
					leftIcon={backButton}
					rightText={this.state.isFocused ? "Done" : ""}
					handleClickLeftIcon={(e) => {
						this.props.dispatch(editLocationSavedPlace(this.props.savedPlaces.savedPlace));
						this.props.dispatch(setAddressDetailsForm(null));
						this.props.dispatch(push(this.props.savedPlaces.searchPointer));
					}}
					handleClickRightIcon={(e) => $("#mapView").fadeIn(300, () => this.setState({ isFocused: false }))}
				/>
				<div id="mapView" className="google-map-wrapper">
					<GoogleMap listener={true} url={this.props.match.path} />

					<div className="edit-location-btn" onClick={this.goToMap}>
						<p>Tap to search or move pin</p>
						{/* <p>{this.props.common.navigationBlackBox?.action === "updateAddress" ? "Tap to search or move pin" : "Tap to edit location"}</p> */}
					</div>

					{!_.isEmpty(this.props.map.finish) && !_.isNull(this.props.map.finishLeft) && _.isEmpty(this.props.map.googleMarker) ? (
						<div
							className={classNames({
								"location-search-map-icon": true,
								dragged: this.props.map.dragged,
							})}
						>
							<Fragment>
								<PinImg className="Pin-icon" refs={this.props.map.finish} />
								<EllipseIcon style={{ left: this.props.map.finishLeft + 16, top: this.props.map.finishTop + 35 }} />
							</Fragment>
						</div>
					) : null}
				</div>
				<Formik
					initialValues={{
						address: this.state.address,
						name: this.state.name,
						details: this.state.details,
						phoneCall: this.state.phoneCall,
						note: this.state.note,
					}}
					validationSchema={UpdateSchema}
					onSubmit={(values, { setSubmitting }) => {
						this.onConfirm(values, setSubmitting);
					}}
				>
					{({ values, errors, touched, handleChange, handleSubmit, isValid, dirty, setFieldValue }) => (
						<form onSubmit={handleSubmit} className={classNames("saved-places-form", { "full-height": this.state.isFocused })}>
							<div className="saved-places-address">
								<div className={classNames("saved-places-address-container", { "input-error": errors.address && touched.address })}>
									<div className="form-group-container address">
										<label>Address</label>
										<input
											onChange={handleChange}
											onBlur={(e) => this.blur(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											placeholder="City, Street, alley"
											name="address"
											value={values.address}
											className="address"
											disabled={true}
										/>
									</div>
								</div>
								<div className={classNames("saved-places-address-container", { "input-error": errors.name && touched.name })}>
									<label className="saved-place-type-label">
										Name
										{/* {this.props.common.navigationBlackBox?.action === "updateAddress" ? "Name" : "Title"} */}
									</label>
									<div className="saved-places-types">
										{(!hasHome || this.state.editingType === "Home") && (
											<div
												onClick={() => {
													this.setState({currentType: "Home"})
													this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"savedPlaceType": "Home" }));
													//this.props.dispatch(savedPlacesSetType("Home"));
													setFieldValue("name", "Home");
												}}
												className={classNames({
													"selected-type": this.state.currentType === "Home",
												})}
											>
												Home
											</div>
										)}
										{(!hasWork || this.state.editingType === "Work") && (
											<div
												onClick={() => {
													this.setState({currentType: "Work"})
													this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"savedPlaceType": "Work" }));
													//this.props.dispatch(savedPlacesSetType("Work"));
													setFieldValue("name", "Work");
												}}
												className={classNames({
													"selected-type": this.state.currentType === "Work",
												})}
											>
												Work
											</div>
										)}
										<div
											onClick={() => {
												this.setState({currentType: "Custom"})
												this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"savedPlaceType": "Custom", "name": this.props.savedPlaces.savedPlace.title || "" }));
												//this.props.dispatch(savedPlacesSetType("Custom"));
												setFieldValue("name", this.props.savedPlaces.savedPlace.title || "");
											}}
											className={classNames({
												"selected-type": this.state.currentType === "Custom",
											})}
										>
											Custom
										</div>
									</div>
									{this.state.currentType === "Custom" && (
										<div className="form-group-container">
											<input
												onChange={(e) => {
													this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"name": e.target.value }));
													setFieldValue("name", e.target.value);
												}}
												onBlur={(e) => this.blur(e, setFieldValue)}
												onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
												// placeholder={
												// 	this.props.common.navigationBlackBox?.action === "updateAddress"
												// 		? "e.g. Family House"
												// 		: "e.g.School/Office"
												// }
												placeholder="e.g. Family House"
												name="name"
												value={this.props.savedPlaces.addressDetailsForm.name}
											/>
										</div>
									)}
								</div>
								<div className={classNames("saved-places-address-container", { "input-error": errors.details && touched.details })}>
									<div className="form-group-container">
										<label>Address Details</label>
										<input
											onChange={(e) => {
												this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"details": e.target.value }));
												setFieldValue("details", e.target.value);
											}}
											onBlur={(e) => this.blur(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											// placeholder={
											// 	this.props.common.navigationBlackBox?.action === "updateAddress"
											// 		? "Floor, unit number"
											// 		: "Floor, Unit"
											// }
											placeholder="Floor, unit number"
											name="details"
											value={this.props.savedPlaces.addressDetailsForm.details}
										/>
									</div>
								</div>
								<div className="saved-places-address-container">
									<div className="form-group-container-textarea form-group-container">
										<label>
											Delivery Instructions (optional but useful)
											{/* {this.props.common.navigationBlackBox?.action === "updateAddress"
												? "Delivery Instructions (optional but useful)"
												: "Note to Personnel (Optional)"} */}
										</label>
										<TextareaAutosize
											onChange={(e) => {
												this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"note": e.target.value }));
												setFieldValue("note", e.target.value);
											}}
											onBlur={(e) => this.blur(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											rows={4}
											name="note"
											value={this.props.savedPlaces.addressDetailsForm.note}
											placeholder="i.e. What should Kuya do when he arrives? Anything else?"
											// placeholder={
											// 	this.props.common.navigationBlackBox?.action === "updateAddress"
											// 		? "i.e. What should Kuya do when he arrives? Anything else? "
											// 		: "eg: Deliver my order to the gaurd"
											// }
										/>
									</div>
								</div>
								<div className="saved-places-address-container">
									<div className="form-group-container-checkbox">
										<label className="container-checkbox">
											Don't call me, just text please.
											{/* {this.props.common.navigationBlackBox?.action === "updateAddress"
												? "Don't call me, just text please."
												: "Don’t call me, Just text."} */}
											<input
												onChange={(e) => {
													this.props.dispatch(setAddressDetailsForm({...this.props.savedPlaces.addressDetailsForm ,"phoneCall": e.target.checked }));
													setFieldValue("phoneCall", e.target.checked);
												}}
												type="checkbox"
												name="phoneCall"
												value={this.props.savedPlaces.addressDetailsForm.phoneCall}
												checked={this.props.savedPlaces.addressDetailsForm.phoneCall}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
							</div>
							<div className="saved-places-address-confirm">
								<CustomButton
									disabled={this.props.savedPlaces.addSavedPlaceLoading}
									title={
										this.props.savedPlaces.addSavedPlaceLoading ? (
											<div className="next-button-loading">
												<WhiteLoading />
											</div>
										) : (
											"Save Address"
										)
									}
									type="submit"
									variant="contained"
								/>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = state => ({
    ...state.search,
	product: state.booking.product,
    common: state.common,
    map: state.map,
    savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(SavedPlacesUpdateAddress);