import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import * as Constant from "../../utils/redux/actions/myJobsActions/myJobsConstant";
import { fetchJobsListByRequestType, fetchJobsByOffset, checkJobState } from "../../utils/redux/actions/myJobsActions";
import { navigateOnClickJobInJobsList } from "../../utils/redux/actions/jobsActions";
import { setSelectedTabIndex } from "../../utils/redux/actions/myJobsActions/myJobsChangeState";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import { setLocationPointer } from "../../utils/redux/actions/commonActions";

import JobsBox from "../job-history/Jobs/JobsBox/JobsBox";
import OpenJobs from "../job-history/Jobs/OpenJobs";
import JobsEmpty from "../job-history/Jobs/jobs-empty/jobs-empty";
import ListSkeleton from "../ui-elements/Skeleton/ListSkeleton";
import FetchingLoading from "../chat-elements/loading/loading"
class MyJobs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
			innerHeight: null
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		if(this.props.selectedTab !== Constant.All) {
			this.props.dispatch(fetchJobsListByRequestType(this.props.selectedTab));
		} else {
			this.props.dispatch(fetchJobsListByRequestType(Constant.All));
		}
	}

	onSelect = (index, _, event) => {
		this.props.dispatch(setSelectedTabIndex(index, event.target.innerText));
		if (this.props[`${event.target.innerText.toLowerCase()}Jobs`] === null) {
			this.props.dispatch(fetchJobsListByRequestType(event.target.innerText));
		}
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	navigateToSearchLocation = (e) => {
		this.props.dispatch(setLocationPointer());
	};

	onJobClick(e, index, itemTitle) {
		this.props.dispatch(navigateOnClickJobInJobsList(this.props[itemTitle].items[index]))
	}

	onClickOpenJobs = (job) => {
		this.props.dispatch(checkJobState(job.id));
	};

	onScroll = (e) => {
		if (this.props.selectedTab !== Constant.Open && e.target.scrollTop) {
			if (
				e.target.clientHeight + 5 > e.target.scrollHeight - e.target.scrollTop &&
				e.target.clientHeight - 5 < e.target.scrollHeight - e.target.scrollTop
				) {
				this.props.dispatch(fetchJobsByOffset(this.props.selectedTab));
			}
		}
	}

	render() {
		const tabList = [Constant.All, Constant.Active, Constant.Open, Constant.Upcoming, Constant.Completed];
		return (
			<div className="">
				<Tabs selectedIndex={this.props.selectedIndex} onSelect={this.onSelect} selectedTabClassName={"selected"}>
					<TabList className="jobs-tab-list">
						{tabList.map((item, idx) => (
							<Tab className="tab-item" key={idx}>
								{item}
							</Tab>
						))}
					</TabList>
					<div className="tabs-container" onScroll={this.onScroll}>
						<TabPanel className="tab-panel-container">
							{this.props.fetchJobsLoading ? (
								!_.isNull(this.state.innerHeight) &&
								!_.isNull(this.state.innerWidth) && (
									<ListSkeleton
										devicewidth={
											_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
										}
										deviceheight={this.state.innerHeight - 132}
									/>
								)
							) : !_.isNull(this.props.allJobs) ? (
								this.props.allJobs.totalItems > 0 ? (
									this.props.allJobs.items.map((job, idx) => (
										<JobsBox onClick={(e, id, index) => this.onJobClick(e, index, "allJobs")} key={idx} index={idx} data={job} />
									))
								) : (
									<JobsEmpty
										activeTabTitle="No Jobs Yet"
										onSupport={this.onSupport}
										icon="emptyAll"
										tabType="all"
										buttonText="Search Service"
										helpDescription="Expecting to see jobs?"
										description="Get your first job done for you by searching for available services"
										onNavigateToSearch={this.navigateToSearchLocation}
									/>
								)
							) : null}
						</TabPanel>
						<TabPanel className="tab-panel-container">
							{this.props.fetchJobsLoading ? (
								!_.isNull(this.state.innerHeight) &&
								!_.isNull(this.state.innerWidth) && (
									<ListSkeleton
										devicewidth={
											_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
										}
										deviceheight={this.state.innerHeight - 132}
									/>
								)
							) : !_.isNull(this.props.activeJobs) ? (
								this.props.activeJobs.totalItems > 0 ? (
									this.props.activeJobs.items.map((job, idx) => (
										<JobsBox
											onClick={(e, id, index) => this.onJobClick(e, index, "activeJobs")}
											key={idx}
											index={idx}
											data={job}
										/>
									))
								) : (
									<JobsEmpty
										activeTabTitle="No Active Jobs Now"
										tabType="active"
										buttonText="Search Service"
										helpDescription="Expecting to see an active job?"
										description="This is where you see jobs that have been matched with a partner already"
										onSupport={this.onSupport}
										icon="emptyActive"
									/>
								)
							) : null}
						</TabPanel>
						<TabPanel className="tab-panel-container">
							{this.props.fetchJobsLoading ? (
								!_.isNull(this.state.innerHeight) &&
								!_.isNull(this.state.innerWidth) && (
									<ListSkeleton
										devicewidth={
											_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
										}
										deviceheight={this.state.innerHeight - 132}
									/>
								)
							) : !_.isNull(this.props.openJobs) ? (
								<OpenJobs data={this.props.openJobs} onClickOpenJobs={this.onClickOpenJobs} />
							) : (
								<JobsEmpty
									activeTabTitle="No Open Jobs Yet"
									onSupport={this.onSupport}
									tabType="open"
									buttonText="Search Service"
									helpDescription="Expecting to see a open job?"
									description="Get your first job done for you by searching for available services"
									icon="emptyComplement"
									onNavigateToSearch={this.navigateToSearchLocation}
								/>
							)}
						</TabPanel>
						<TabPanel className="tab-panel-container">
							{this.props.fetchJobsLoading ? (
								!_.isNull(this.state.innerHeight) &&
								!_.isNull(this.state.innerWidth) && (
									<ListSkeleton
										devicewidth={
											_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
										}
										deviceheight={this.state.innerHeight - 132}
									/>
								)
							) : !_.isNull(this.props.upcomingJobs) ? (
								this.props.upcomingJobs.totalItems > 0 ? (
									this.props.upcomingJobs.items.map((job, idx) => (
										<JobsBox
											onClick={(e, id, index) => this.onJobClick(e, index, "upcomingJobs")}
											key={idx}
											index={idx}
											data={job}
										/>
									))
								) : (
									<JobsEmpty
										activeTabTitle="No Upcoming Jobs Yet"
										onSupport={this.onSupport}
										tabType="upcoming"
										buttonText="Search Service"
										helpDescription="Expecting to see a upcoming job?"
										description="Get your first job done for you by searching for available services"
										icon="emptyComplement"
										onNavigateToSearch={this.navigateToSearchLocation}
									/>
								)
							) : null}
						</TabPanel>
						<TabPanel className="tab-panel-container">
							{this.props.fetchJobsLoading ? (
								!_.isNull(this.state.innerHeight) &&
								!_.isNull(this.state.innerWidth) && (
									<ListSkeleton
										devicewidth={
											_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40
										}
										deviceheight={this.state.innerHeight - 132}
									/>
								)
							) : !_.isNull(this.props.completedJobs) ? (
								this.props.completedJobs.totalItems > 0 ? (
									this.props.completedJobs.items.map((job, idx) => (
										<JobsBox
											onClick={(e, id, index) => this.onJobClick(e, index, "completedJobs")}
											key={idx}
											index={idx}
											data={job}
										/>
									))
								) : (
									<JobsEmpty
										activeTabTitle="No Completed Jobs Yet"
										onSupport={this.onSupport}
										tabType="completed"
										buttonText="Search Service"
										helpDescription="Expecting to see a completed job?"
										description="Get your first job done for you by searching for available services"
										icon="emptyComplement"
										onNavigateToSearch={this.navigateToSearchLocation}
									/>
								)
							) : null}
						</TabPanel>
						{this.props.fetchJobsbyScroll && <div className="jobs-loading"> <FetchingLoading /> </div>}
					</div>
				</Tabs>
			</div>
		);
	}
}


const mapStateToProps = state => ({
    ...state.myJobs,
});

export default connect(mapStateToProps)(MyJobs);