import React, { Fragment } from "react";
import { connect } from "react-redux";
import {push} from "connected-react-router";
import _ from "lodash";
import * as moment from "moment-timezone";

import { changeTimeFormat, getPrimaryPayment } from "../../../../utils/redux/actions/commonActions/commonChangeState";
import { fetchMethodsOfPayment } from "../../../../utils/redux/actions/billingActions";
import { convertTime } from "../../../../utils/functions/job-time-convert";
import { fetchJob, cancelOpenJob } from "../../../../utils/redux/actions/jobsActions";
import { googlePlacesMatching } from "../../../../utils/functions/google-places-matching";

import JobLocations from "../../JobMatching/JobLocations/JobLocations";
import TitleBar from "../../../../organisms/title-bar";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import ArticleSkeleton from "../../../ui-elements/Skeleton/ArticleSkeleton";

import { ReactComponent as SuiteCase } from "../../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../../v5-icons/clock-small.svg";
import { ReactComponent as Note } from "../../../../v5-icons/note-small.svg";
import { ReactComponent as High } from "../../../../v5-icons/high.svg";
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import backButton from "../../../../v5-icons/close-thin.svg";
import { ReactComponent as Kuyas } from "../../../../v5-icons/request-placed-kuya.svg";
import Moment from "../../../moment/moment";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require("classnames");

const weekMap = {
	1: "Monday",
	2: "Tuesday",
	3: "Wednesday",
	4: "Thursday",
	5: "Friday",
	6: "Saturday",
	0: "Sunday",
};

class OpenJobInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			weekDay: null,
			innerWidth: null,
			innerHeight: null,
		};
	}
	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		if(_.isEmpty(this.props.job)) {
			this.props.dispatch(fetchMethodsOfPayment()).then((res) => {
				if (res.data.data.paymentMethods.totalItems > 0) {
					const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
					this.props.dispatch(getPrimaryPayment(primaryPayment));
				}
			});
			this.props.dispatch(fetchJob(Number(this.props.match.params.id)))
				.then(() => {
					this.props.dispatch(changeTimeFormat(convertTime(this.props.job.jobTime.total.value)));
					if (!_.isNull(this.props.job.dueDate)) {
						const opendJobDate = moment(this.props.job.dueDate, "YYYY-MM HH:mm:ss");
						const opendJobWeekDay = opendJobDate.format("dddd");
						this.setState(() => ({
							weekDay: opendJobWeekDay,
						}));
					}
				})
		} else {
			if (!_.isNull(this.props.job.dueDate)) {
				const opendJobDate = moment(this.props.job.dueDate, "YYYY-MM HH:mm:ss");
				const opendJobWeekDay = opendJobDate.format("dddd");
				this.setState(() => ({
					weekDay: opendJobWeekDay,
				}));
			}
		}
	}
	onCancel = (e) => {
		this.props.dispatch(push("/jobs"));
	};
	render() {
		const { props } = this;
		return !_.isEmpty(this.props.job) && this.props.job.hasOwnProperty("product") ? (
			<div className="request-placed open-job-info">
				<TitleBar
					leftIcon={backButton}
					lozengeObject={{ title: "OPEN", color: "yellow", heading: this.props.job.product.commercialName }}
					handleClickLeftIcon={this.onCancel}
				/>
				<div className="matching-process">
					<div className="matching-process-information">
						<div className="matching-process-title">
							<div className="booking-button-icon">
								<Kuyas />
							</div>
							<div className="title">
								<p>Request Placed</p>
								<span className="text-secondary">Get matched once a partner is ready</span>
							</div>
						</div>

						<div className="matching-process-container">
							<div className="matching-process-row">
								<SuiteCase className="icon" />
								<p className="commercial-name">{props.job.product.commercialName}</p>
							</div>
							<div className="matching-process-row">
								<JobLocations locations={props.job.jobLocations} />
							</div>
							<div className="matching-process-row">
								<Clock className="icon" />
								<p>
									{props.job.jobCost.currency} {!_.isNull(props.newPrice) ? props.newPrice : props.job.jobCost.value} for{" "}
									{props.common.formattedTime}, extendable
								</p>
							</div>
							{!_.isNull(props.biling) ? (
								<Fragment>
									{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance <= 0 ? (
										<div className="matching-process-row payment">
											<img
												src={props.primaryPayment[0].icon}
												alt={props.primaryPayment[0].type}
												className={classNames("billing-card-image", props.primaryPayment[0].type)}
											/>
											<p>
												Pay via{" "}
												{!_.isNull(props.primaryPayment[0].name)
													? props.primaryPayment[0].name
													: props.primaryPayment[0].type}
												{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
													? `**** ${props.primaryPayment[0].cardNumber}`
													: null}
											</p>
										</div>
									) : null}
									{_.isEmpty(props.primaryPayment) ? (
										<div className="matching-process-row">
											<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
											<p>Pay via mykoins</p>
										</div>
									) : null}
									{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance > 0 ? (
										<div className="matching-process-row">
											<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
											<p>
												Pay via mykoins then {props.primaryPayment[0].type}{" "}
												{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
													? `**** ${props.primaryPayment[0].cardNumber}`
													: null}
											</p>
										</div>
									) : null}
								</Fragment>
							) : null}

							{props.job.state === "Queued" ? (
								<div className="matching-process-row job-state">
									<High className="icon" />
									<p>{props.job.state}</p>
								</div>
							) : null}

							{props.job.state === "Scheduled" && !_.isNull(this.state.weekDay) ? (
								<div className="matching-process-row job-state">
									<High className="icon" />
									<p>
										{props.job.state} for{" "}
										{/*{this.state.weekDay.indexOf("Invalid") === -1 ? <span>{this.state.weekDay.slice(0, 3)} </span> : ""}*/}
										{weekMap[new Date(props.job.dueDate).getDay()].slice(0, 3)}{" "}
										<Moment format="DD MMM , h:mmA">{props.job.dueDate}</Moment>
									</p>
								</div>
							) : null}

							<div className="matching-process-row note">
								<Note className="icon" />
								{!_.isEmpty(props.job.note) ? <p>{props.job.note}</p> : <p>No note to partner</p>}
							</div>
						</div>
					</div>

					<div className="open-job-info-button">
						<CustomButton onClick={() => this.onCancel()} type="button" title="Got It" />
						<CustomButton
							onClick={() => this.props.dispatch(cancelOpenJob(this.props.job.id))}
							type="button"
							title="Cancel Request"
							classList="cancel-booking"
							variant="outlined"
						/>
					</div>
				</div>
			</div>
		) : (
			<div className="detail-news-loading">
				<ArticleSkeleton
					devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	chat: state.chat,
	common: state.common,
	locations: googlePlacesMatching(state.map),
	isInstantMatch: state.booking.isInstantMatch,
	primaryPayment: state.common.primaryPayment,
	newPrice: state.booking.newPrice,
	billing: state.billing
});

export default connect(mapStateToProps)(OpenJobInfo);
