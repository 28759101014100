import React, {Fragment} from 'react';


export default function JobReceiptRow(props) {

    return (
        <Fragment>
            <div className={`Job-receipt-row ${props.classname ? props.classname : ""}`}>
                <div className="Job-receipt-title">{props.title}</div>
                {props.price ?
                    <div className="Job-receipt-value">{props.value.currency} {props.value.value}</div> :
                    <div className="Job-receipt-value">{props.value}</div>}
                
            </div>
        </Fragment>
    );
}