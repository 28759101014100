import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import HomeIcon from '../../../v5-icons/home.svg';
import WorkIcon from '../../../v5-icons/suite-case-colorful.svg';
import SavedPlacesFavoritesItem from './SavedPlacesFavoritesItem/SavedPlacesFavoritesItem';
import {SAVED_PLACE_HOME_TYPE, SAVED_PLACE_WORK_TYPE} from '../../../utils/redux/actions/savedPlacesActions';
import { ReactComponent as ActionIcon } from "../../../v5-icons/kebab-menu.svg";

function SavedPlacesFavorite(props) {

    return (
		<Fragment>
			<div className="saved-places-favorite">
				{!_.isNull(props.savedHome) && (<SavedPlacesFavoritesItem
					place={props.savedHome}
					title="Home"
					subTitle={_.isNull(props.savedHome) ? "Set once for easy access later" : props.savedHome.location.address}
					add={_.isNull(props.savedHome)}
					onClick={(e) => props.onClick(e, props.savedHome, SAVED_PLACE_HOME_TYPE)}
					id={"Home-icon"}
					actionIcon={<ActionIcon />}
					icon={HomeIcon}
				/>)}
				{!_.isNull(props.savedHome) && !_.isNull(props.savedWork) &&
					(<div className="saved-places-divider"></div>)}
				{!_.isNull(props.savedWork) && (<SavedPlacesFavoritesItem
						place={props.savedWork}
						title="Work"
						subTitle={_.isNull(props.savedWork) ? "Set once for easy access later" : props.savedWork.location.address}
						add={_.isNull(props.savedWork)}
						onClick={(e) => props.onClick(e, props.savedWork, SAVED_PLACE_WORK_TYPE)}
						id={"Work-icon"}
						actionIcon={<ActionIcon />}
						icon={WorkIcon}
						/>)}
			</div>
		</Fragment>
	);
}

const mapStateToProps = state => ({
    ...state.savedPlaces,
});

export default connect(mapStateToProps)(SavedPlacesFavorite);