import _ from 'lodash';
import { updatePickupLocation } from "../redux/actions/mapActions";
export function createMapObject(dispatch, getState, zoom, location) {
    return new Promise((resolve, reject) => {
        let data = null;
        let centerLocation = {};
        dispatch(updatePickupLocation(getState().map.location)).then(() => {
            centerLocation = {
				// lat : _.isNull(getState().map.location.lat) ? getState().map.location.defaultLat : getState().map.googleMapPlace.lat,
				// lng : _.isNull(getState().map.location.lng) ? getState().map.location.defaultLng : getState().map.googleMapPlace.lng,

				lat: !_.isNull(location.lat) ? location.lat : location.defaultLat,
				lng: !_.isNull(location.lng) ? location.lng : location.defaultLng,
			};
            if (getState().map.googleMapRef.current) {
                data = new window.google.maps.Map(getState().map.googleMapRef.current, {
                    zoom: zoom,
                    center: centerLocation,
                    disableDefaultUI: true,
                });
            }

            const mapObject = {
				data: data,
				centerLocation: centerLocation,
			};
            resolve(mapObject);
        });
    })    
}