import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from "react-toastify";	
import AdyenCheckout from "@adyen/adyen-web";

// import * as LOG_EVENT from '../../../analytics';
import * as DISPATCH_STATE from './billingChangeState';
import { GraphRequest } from '../../../../axios';
import { appFetchFailure, fetchClient } from '../commonActions';
import {
	appLoadingBegin,
	appLoadingEnd,
	storeBookingUrl,
	setclientHasNoPaymentMethodFalse,
	getPrimaryPayment,
	returnErrorFromAdyen,
	clientHasNoPaymentMethod,
	storePathName, xenditMinLimitBottomSheet,
} from "../commonActions/commonChangeState";
import { checkRejectedPayment, checkRetryWithCard } from "../bookingActions";
import {
	togglePaymentMethodsBottomSheet,
	redirectClientToAdyenForTopUp,
	setDefaultValueRejectedPayment,
	toggleRetryFailed,
} from "../bookingActions/bookingChangeState";
import { fetchPrimaryPayment, fetchXenditPrimaryPayment } from "../bookingActions/actions/booking-service";
import {
	adyenPaymentMethods,
	addPaymentMethod,
	add3dSecurePaymentMethod,
	paymentAndBalance,
	addGcashPaymentMethod,
	xenditAndBalance,
} from "./billing-gql";
import {
	getClientUnitCurrency,
	setStoredUrlLocalStorage,
	setReviewBookingLocalStorage,
	setAddingGcashLocalStorage,
} from "../../../functions/local-storage";
import { saveGcashInfo } from "./billingChangeState";
/**
 * when user click on add payment method in choose payment page
 */
export const billingOnAddPaymentMethodButton = () => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.billingOnAddPaymentMethodsInChoosePage());
        dispatch(push('/account/billing/add'));
    }
}
/**
 * Navigate user to account/billing
 */
export const navigateToAccountBilling = () => {
    return (dispatch) => {
        dispatch(push('/account/billing'));
    }
}
/**
 * Fetch list of payments methods from server
 */
export function fetchMethodsOfPayment() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const payments = {
				query: paymentAndBalance,
				variables: {
					unit: _.isEmpty(getState().common.client) ? getClientUnitCurrency() : getState().common.client.currency.unit,
					channel: "Web",
					productId: getState().booking?.product?.id
				},
			};

			GraphRequest.all(payments)
				.then(res => {
					if (res.data && res.data.data) {
						const { paymentMethods } = res.data.data;
						const cardItems = paymentMethods.items.filter((item) => item.type === 'mc' || item.type === 'visa');
						const gcashItem = paymentMethods.items.filter((item) => item.type === "GCash");
						dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(cardItems));
						dispatch(DISPATCH_STATE.fetchGcashSuccess(gcashItem.length ? gcashItem : null));
						dispatch(DISPATCH_STATE.fetchAccountingBalanceSuccess(res.data.data.balances));
						resolve(res);
						if (res.data.data.paymentMethods.totalItems === 0) {
							if (res.data.data.balances[0].balance < 0) {
								dispatch(checkRejectedPayment());
							} else if (res.data.data.balances[0].balance == 0) {
								dispatch(clientHasNoPaymentMethod());
							}
						}
					} else if (res.data.errors) {
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(res.data.errors[0].message));
					}
				})
				.catch(error => dispatch(appFetchFailure(error)));
		})
	};
}

/**
 * Fetch list of payments methods and balance
 */
export function fetchXenditAndBalance() {
	return (dispatch, getState) => {
		console.clear();
		console.log(getState().booking?.product?.productId, "getState().booking?.product?.productId");
		return new Promise((resolve, reject) => {
			const payments = {
				query: xenditAndBalance,
				variables: {
					unit: _.isEmpty(getState().common.client) ? getClientUnitCurrency() : getState().common.client.currency.unit,
					channel: "Web",
					productId: getState().booking?.product?.id
				},
			};

			GraphRequest.all(payments)
				.then(res => {
					if (res.data && res.data.data) {
						const { getXenditPaymentMethods: paymentMethods } = res.data.data;
						const cardItems = paymentMethods.items;
						dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(cardItems));
						dispatch(DISPATCH_STATE.fetchAccountingBalanceSuccess(res.data.data.balances));
						resolve(res);
						if (res.data.data.getXenditPaymentMethods.totalItems === 0) {
							if (res.data.data.balances[0].balance < 0) {
								dispatch(checkRejectedPayment());
							} else if (res.data.data.balances[0].balance == 0) {
								dispatch(clientHasNoPaymentMethod());
							}
						}
					} else if (res.data.errors) {
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(res.data.errors[0].message));
					}
				})
				.catch(error => dispatch(appFetchFailure(error)));
		})
	};
}
/**
 * Request for change primary
 */
export function billingRequestForChagnePrimary(id) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(true));
		dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(true));
        const setPrimaryRequestBody = {
            query: `mutation PrimaryPayment($id: String!) {
                primaryPaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                }
            }`,
            variables: { "id": id },
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(setPrimaryRequestBody)
                .then(json => {
                    dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(false));
					dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(false));
                    if (!_.isNull(json.data.data) && json.data.data.primaryPaymentMethod.succeed) {
						dispatch(checkRetryWithCard());
						dispatch(fetchPrimaryPayment());
						if (!_.isNull(getState().booking.retryWithCard) && getState().booking.retryWithCard) {
							dispatch(billingRequestPaymentCheckout()).then(() => {
								dispatch(togglePaymentMethodsBottomSheet());
								dispatch(fetchClient());
								dispatch(setDefaultValueRejectedPayment());
								toast("Checkout is done", {
									position: "bottom-center",
									autoClose: 5000,
									limit: 1,
									className: "toast-rejected-payment",
									bodyClassName: "toastify-inner",
									hideProgressBar: true,
									closeOnClick: false,
								});
							});
						} else {
							dispatch(appLoadingEnd());
						}
					} else if (json.data.errors) {
						toast(`${json.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(json.data.errors[0].message));
					}
                    resolve();
                });
        })
    };
}

/**
 * Request for Xendit change primary
 */
export function billingRequestForXenditChangePrimary(id) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(true));
		dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(true));
        const setPrimaryRequestBody = {
            query: `mutation PrimaryXenditPaymentMethod($id: String!) {
                primaryXenditPaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                    details
                }
            }`,
            variables: { "id": id },
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(setPrimaryRequestBody)
                .then(json => {
                    dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(false));
					dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(false));
                    if (!_.isNull(json.data.data) && json.data.data.primaryXenditPaymentMethod.succeed) {
						dispatch(checkRetryWithCard());
						dispatch(fetchXenditPrimaryPayment());
						if (!_.isNull(getState().booking.retryWithCard) && getState().booking.retryWithCard) {
							dispatch(billingRequestPaymentCheckout()).then(() => {
								dispatch(togglePaymentMethodsBottomSheet());
								dispatch(fetchClient());
								dispatch(setDefaultValueRejectedPayment());
								toast("Checkout is done", {
									position: "bottom-center",
									autoClose: 5000,
									limit: 1,
									className: "toast-rejected-payment",
									bodyClassName: "toastify-inner",
									hideProgressBar: true,
									closeOnClick: false,
								});
							});
						} else {
							dispatch(appLoadingEnd());
						}
					} else if (json.data.errors) {
						toast(`${json.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(json.data.errors[0].message));
					}
                    resolve();
                });
        })
    };
}
/**
 * Request for Delete card
 */
export function billingDeleteRequestPaymentMethod(id) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(true));
        dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(true));
        dispatch(DISPATCH_STATE.billingPaymentMethodEmpty());
        const deletePaymentRequestBody = {
            query: `mutation DeletePayment($id: String!) {
                deletePaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                }
            }`,
            variables: { "id": id }
        }
        GraphRequest.all(deletePaymentRequestBody)
            .then(json => {
                dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(false));
				dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(false));
                if (!_.isNull(json.data.data) && json.data.data.deletePaymentMethod.succeed) {
                    const { availableMethods } = getState().billing;
                    if (availableMethods.data && !_.isNull(availableMethods.data.data.adyenPaymentMethods.paymentMethods)) {
						availableMethods.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
							if (x.type === "gcash") dispatch(DISPATCH_STATE.isSupportGcash(true));
						});
					}
                    dispatch(fetchMethodsOfPayment())
                    dispatch(getPrimaryPayment(null));
                } else if (!json.data.data.deletePaymentMethod.succeed) {                    
                    dispatch(fetchMethodsOfPayment())
                        .then((res) => {
                            res.data.data.paymentMethods.items.filter((x) => {
								if (x.type === "GCash") dispatch(DISPATCH_STATE.isSupportGcash(true));
							});
                        })
                    dispatch(DISPATCH_STATE.deletePaymentErrorText(json.data.data.deletePaymentMethod.result))
                } else {
                    dispatch(appFetchFailure(json.data.data));
                }
            })
            .catch(error => dispatch(appFetchFailure(error)));
    };
}

/**
 * Request for Delete card
 */
export function billingDeleteXenditPaymentMethod(id) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(true));
        dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(true));
        dispatch(DISPATCH_STATE.billingPaymentMethodEmpty());
        const deletePaymentRequestBody = {
            query: `mutation DeleteXenditPaymentMethod($id: String!) {
                deleteXenditPaymentMethod(paymentMethodId: $id) {
                    succeed
                    result
                }
            }`,
            variables: { "id": id }
        }
        GraphRequest.all(deletePaymentRequestBody)
            .then(json => {
                dispatch(DISPATCH_STATE.fetchAvailableMethodsLoading(false));
				dispatch(DISPATCH_STATE.fetchPaymentMethodsLoading(false));
                if (!_.isNull(json.data.data) && json.data.data.deleteXenditPaymentMethod.succeed) {
                    dispatch(fetchXenditAndBalance())
                    dispatch(getPrimaryPayment(null));
                } else if (!json.data.data.deleteXenditPaymentMethod.succeed) {
                    dispatch(fetchXenditAndBalance())
                    dispatch(DISPATCH_STATE.deletePaymentErrorText(json.data.data.deleteXenditPaymentMethod.result))
                } else {
                    dispatch(appFetchFailure(json.data.data));
                }
            })
            .catch(error => dispatch(appFetchFailure(error)));
    };
}
/**
 * Request for payment checkout 
 */
export function billingRequestPaymentCheckout() {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        return new Promise((resolve, reject) => {
            const checkoutRequestBody = {
                query: `mutation ClientCheckout {
                    checkoutPayment {
                        id
                        state
                    }
                }`
            }
            GraphRequest.all(checkoutRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.checkoutPayment) {
                    dispatch(fetchMethodsOfPayment());
                    dispatch(setDefaultValueRejectedPayment());
                    toast("Checkout is done", {
                        position: "bottom-center",
                        autoClose: 5000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    dispatch(appLoadingEnd());
                } else if (res.data.errors) {
					if(getState().billing.balance[0].balance < 0 && getState().common.primaryPayment) {
						dispatch(toggleRetryFailed());
					} else {
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
                    dispatch(appFetchFailure(res.data.errors[0].message));
                }
            });
        })
    };
}
/**
 * Initilize adyen script to end of page whether does not exist 
 */
export function initAdyen() {
    return (dispatch, getState) => {
        var form = document.getElementById('adyen-encrypted-form');
        let options = {};
        var encryptedBlobFieldName = "adyen-encrypted-data";

        // options.name = encryptedBlobFieldName;
        options.onsubmit = (e) => {
            var encryptedData = form.elements[encryptedBlobFieldName].value;
            e.preventDefault();
            dispatch(appLoadingBegin());
            GraphRequest.all(encryptedData)
                .then((res) => {
                    dispatch(appLoadingEnd());
                    // LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                }).catch((e) => {
                    dispatch(DISPATCH_STATE.billingErrorPayment());
                    dispatch(appFetchFailure());
                });
        };
        let ady = window.adyen.createEncryptedForm(form, options);
        dispatch(DISPATCH_STATE.billingSetAdyen(ady))
    }
}

var card = null;
var gcash = null;
export const availableMethods = () => {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const requestBody = {
                query: adyenPaymentMethods,
                variables: {
                    unit: _.isEmpty(getState().common.client) ? getClientUnitCurrency() : getState().common.client.currency.unit,
                    channel: "Web",
                },
            };
            GraphRequest.all(requestBody)
                .then((res) => {
                    if (res.data && !res.data.errors && res.data.data.adyenPaymentMethods) {
                        dispatch(DISPATCH_STATE.storeAvailablePaymentMethods(res));
						resolve(res);
					} else if (res.data.errors) {
						toast(`${res.data.errors[0].message}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
						dispatch(appFetchFailure(res.data.errors[0].message));
					}
                })
        }) 
    }
}
/**
 * Initilize adyen script to end of page whether does not exist 
 */
export function initAdyenV2(methods = {}) {
    return (dispatch, getState) => {
        dispatch(appLoadingEnd());
        const translations = {
			"en-US": {
				"creditCard.holderName.placeholder": "Janina San Miguel ",
			},
		};
        const configuration = {
			locale: "en_US", // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/checkout/components-web/localization-components.
			environment: process.env.REACT_APP_ADYEN_ENV, // When you're ready to accept live payments, change the value to one of our live environments https://docs.adyen.com/checkout/components-web#testing-your-integration.
			// originKey: process.env.REACT_APP_ADYEN_ORIGIN_KEY,
			clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
			paymentMethodsResponse: methods,
			onSubmit: handleOnSubmit,
			translations: translations,
			// onChange: handleOnChange, // Your function for handling onChange event
		};
        const checkout = new AdyenCheckout(configuration);
        card = checkout.create('card', {
            // billingAddressRequired: true,
            holderNameRequired: true,
            hasHolderName: true,
            enableStoreDetails: false,
            showPayButton: false,
            onAdditionalDetails: handleOnAdditionalDetails,
        }).mount('#component-container');

        function handleOnAdditionalDetails(state, component) {
            dispatch(appLoadingBegin());
            dispatch(checkAddCard3DSecure(state));
        }

        function handleOnSubmit(state, component) {
            dispatch(appLoadingBegin());
            dispatch(addCardV2(state));
        }
        return card;
    }
}

export function initGCash(methods= {}) {
    return (dispatch, getState) => {
        const configuration = {
			locale: "en_US", // The shopper's locale. For a list of supported locales, see https://docs.adyen.com/checkout/components-web/localization-components.
			environment: process.env.REACT_APP_ADYEN_ENV, // When you're ready to accept live payments, change the value to one of our live environments https://docs.adyen.com/checkout/components-web#testing-your-integration.
			clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
			paymentMethodsResponse: methods.data.data.adyenPaymentMethods,
			onSubmit: handleOnSubmit,
		};
        const checkout = new AdyenCheckout(configuration);
        gcash = checkout.create("gcash", { showPayButton: false }).mount("#gcash-container");
        function handleOnSubmit(state, component) {
			dispatch(addGcashPayment(state));
		}
		dispatch(saveGcashInfo(gcash))
        return gcash;
    }
}

export const addGcashPayment = (state) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.gcashLoading(true));
        if(getState().router.location.pathname !== "/account/billing") {
            dispatch(storeUserBookingInfo(getState().router.location.pathname, getState().booking))
                .then((url) => {
                    const requestBody = {
                        query: addGcashPaymentMethod,
                        variables: {
                            unit: getState().common.client.currency.unit,
                            channel: "Web",
                            shopperRedirectUrl: `${window.location.origin}${url}`
                        },
                    };
                    dispatch(addGcashPaymentMethodApiCall(requestBody));
                });
        } else {
            const requestBody = {
                    query: addGcashPaymentMethod,
                    variables: {
                        unit: getState().common.client.currency.unit,
                        channel: "Web",
                        shopperRedirectUrl: !_.isNull(getState().common.pathName)
                            ? `${window.location.origin}${getState().common.pathName}`
                            : `${window.location.origin}/account/billing?addingGcash`,
                    },
                };
            dispatch(addGcashPaymentMethodApiCall(requestBody));
            
        }
    }
}

export const addGcashPaymentMethodApiCall = (requestBody) => {
    return (dispatch, getState) => {
        GraphRequest.all(requestBody).then((res) => {
            dispatch(DISPATCH_STATE.gcashLoading(false));
			if (res.data && res.data.data.addAdyenGCashPaymentMethod.succeed) {
				if (_.has(res.data.data.addAdyenGCashPaymentMethod, "result")) {
					const result = JSON.parse(res.data.data.addAdyenGCashPaymentMethod.result);
					if (_.has(result, "action")) {
						dispatch(handleGcashAction(result));
                        setAddingGcashLocalStorage();
					}
				}
			} else if(res.data && !res.data.data.addAdyenGCashPaymentMethod.succeed) {
                dispatch(DISPATCH_STATE.toggleGcashBottomSheet());
                toast(`${res.data.data.addAdyenGCashPaymentMethod.result}`, {
					position: "bottom-center",
					autoClose: 5000,
					limit: 1,
					className: "toast-rejected-payment",
					bodyClassName: "toastify-inner",
					hideProgressBar: true,
					closeOnClick: false,
				});
				dispatch(appFetchFailure(res.data.data.addAdyenGCashPaymentMethod.result));
            }
		});
    }
}

export const storeUserBookingInfo = (url, booking) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const storedUrl = `${url}?retryWithCard`;
            dispatch(storeBookingUrl(storedUrl));
            setStoredUrlLocalStorage(storedUrl);
            const reviewBookingObject = {
                prices: booking.prices,
                product: booking.product,
                formattedTime: booking.formattedTime,
                jobLocations: booking.jobLocations,
                primaryPayment: booking.primaryPayment,
                note: booking.note,
                promoCode: booking.promoCode,
                newPrice: booking.newPrice,
                amountOff: booking.amountOff,
                percentOff: booking.percentOff,
            };
            setReviewBookingLocalStorage(JSON.stringify(reviewBookingObject));
            resolve(storedUrl);
        })
    }
}

const handleGcashAction = (data) => {
	return (dispatch, getState) => {
		const configuration = {
			environment: process.env.REACT_APP_ADYEN_ENV,
			clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY || "test_QY2O45V2QVDSTGALEVNZNFEJPQ32NBIQ",
		};

		const checkout = new AdyenCheckout(configuration);
		checkout.createFromAction(data.action).mount("#gcash-container");
	};
};

const addCardV2 = (state) => {
    return (dispatch, getState) => {
        delete state.data.clientStateDataIndicator;
        if (state.data.paymentMethod) {
            delete state.data.paymentMethod.brand;
        }
        const requestBody = {
			query: addPaymentMethod,
			variables: {
				paymentCard: state.data,
				unit: getState().common.client.currency.unit,
				shopperRedirectUrl: !_.isNull(getState().common.pathName)
					? `${window.location.origin}${getState().common.pathName}`
					: `${window.location.origin}/account/billing`, 
			},
		};
        GraphRequest.all(requestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.addAdyenPaymentMethod.succeed)  {
                    dispatch(appLoadingEnd());
                    // LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    // res.data.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(setclientHasNoPaymentMethodFalse());
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                } else {
                    if (_.has(res.data.data.addAdyenPaymentMethod, 'details')) {
                        const details = JSON.parse(res.data.data.addAdyenPaymentMethod.details)
                        if( _.has(details, 'action')) {
                            // LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                            // res.data.data.addAdyenPaymentMethod.details.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                            dispatch(handleAction(details));
                        } else if (_.has(details, 'refusalReasonCode')) {
                            dispatch(DISPATCH_STATE.billingErrorPayment(res.data.data.addAdyenPaymentMethod.details.description));
                            dispatch(appLoadingEnd());
                        }
                    } 
                }
            });
    }
}

const checkAddCard3DSecure = (state) => { 
    return (dispatch, getState) => {
        const requestBody = {
            query: add3dSecurePaymentMethod,
            variables: {
                "paymentCard": state.data
            }
        }
        GraphRequest.all(requestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.addAdyenSecurePaymentMethod.succeed) {
                    dispatch(appLoadingEnd());
                    // LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                    if (!_.isNull(getState().common.pathName)) {
                        // dispatch(providePaymentMethod(false));
                        dispatch(push(getState().common.pathName));
                        dispatch(storeBookingUrl(null));
                    } else {
                        dispatch(push('/account/billing'))
                    }
                } else {
                    if (_.has(res.data.data.addAdyenSecurePaymentMethod, 'details')) {
                        const details = JSON.parse(res.data.data.addAdyenSecurePaymentMethod.details)
                        if (_.has(details, 'action')) {
                            // LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_CWA);
                            // res.data.data.addAdyenSecurePaymentMethod.details.isFirstCard && LOG_EVENT.logEvent(LOG_EVENT.PAYMENT_CARD_ADDED_FIRST_TIME_CWA, { currency: "PHP", value: 5.00 });
                            dispatch(handleAction(res.data.data.addAdyenSecurePaymentMethod));
                        } else if (_.has(details, 'refusalReasonCode')) {
                            dispatch(DISPATCH_STATE.billingErrorPayment(res.data.data.addAdyenSecurePaymentMethod.details.description));
                            dispatch(appLoadingEnd());
                        }
                    }
                }
            });
    }
}

export const handleAction = (data) => {
    return (dispatch, getState) => {
        function handleOnAdditionalDetails(state, component) {
            dispatch(appLoadingBegin());
            dispatch(checkAddCard3DSecure(state));
        }

        const configuration = {
            // locale: "en_US",
            environment: process.env.REACT_APP_ADYEN_ENV,
            // originKey: process.env.REACT_APP_ADYEN_ORIGIN_KEY,
            clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY || "test_QY2O45V2QVDSTGALEVNZNFEJPQ32NBIQ",
            onAdditionalDetails: handleOnAdditionalDetails,
            // onChange: handleOnChange,
            // onError: onError,
        };

        const checkout = new AdyenCheckout(configuration);
        // checkout.createFromAction(data.details.action).mount('#component-container');
        checkout.createFromAction(data.action).mount('#component-container');
    }
};

export const billingTopUpMyKoin = (amount, url, unit) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.billingTopUpMyKoin(true));
        let returnUrl = window.location.host === "localhost:3000" ? 
            `http://${window.location.host}/account/billing?topUp=${amount}` : 
            `https://${window.location.host}/account/billing?topUp=${amount}`;

        const topUprequestBody = {
            query: `mutation Topup($topUp: ClientTopUpMykoinInput!) {
                topUpMykoinMethod(topUp: $topUp) {
                    succeed
                    result {
                        url
                    }
                    message
                }
            }`,
            variables: { 
                "topUp" : {
                    amount: amount,
                    returnUrl: returnUrl,
                    unit: unit ? unit : getState().common.client.currency.unit
                }
            }
        }
        GraphRequest.all(topUprequestBody)
            .then((res) => {
                dispatch(DISPATCH_STATE.billingTopUpMyKoin(false));
                if (!_.isNull(res.data.data) && res.data.data.topUpMykoinMethod.succeed) {
                    dispatch(redirectClientToAdyenForTopUp());
                    window.location.replace(res.data.data.topUpMykoinMethod.result.url);
                } else if (res.data.errors) {
                    dispatch(returnErrorFromAdyen(res.data.errors[0].message));
                }
            });
    }
};

export const billingXenditTopUpMyKoin = (amount, returnPath, unit, returnQuery) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.billingTopUpMyKoin(true));
        let returnUrl = window.location.host === "localhost:3000" ?
            `http://${window.location.host}/${returnPath}?topUp=${amount}` :
            `https://${window.location.host}/${returnPath}?topUp=${amount}`;

		if(returnQuery) {
			returnUrl = `${returnUrl}&${returnQuery}`
		}

        const topUprequestBody = {
            query: `mutation TopUpXenditMykoinMethod($topUp: ClientXenditTopUpMykoinInput!) {
                topUpXenditMykoinMethod(topUp: $topUp) {
                    succeed
                    result {
                        url
                    }
                    message
                }
            }`,
            variables: {
                "topUp" : {
                    amount: amount,
                    returnUrl: returnUrl,
                    unit: unit ? unit : getState().common.client.currency.unit
                }
            }
        }
        GraphRequest.all(topUprequestBody)
            .then((res) => {
                dispatch(DISPATCH_STATE.billingTopUpMyKoin(false));
                if (!_.isNull(res.data.data) && res.data.data.topUpXenditMykoinMethod.succeed) {
                    dispatch(redirectClientToAdyenForTopUp());
                    window.location.replace(res.data.data.topUpXenditMykoinMethod.result.url);
					dispatch(xenditMinLimitBottomSheet(false));
				} else if (res.data.errors) {
                    dispatch(returnErrorFromAdyen(res.data.errors[0].message));
                }
            });
    }
};

export const fetchPaymentMethods = () => {
    return (dispatch, getState) => {
        const getPaymentsRequestBody = {
            query: `query PaymentMethods {
                paymentMethods {
                    totalItems
                    items {
                        id
                        type
                        icon
                        name
                        value
                        isPrimary
                        cardNumber
                        expirationMonth
                        expirationDate
                    }
            }}`
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(getPaymentsRequestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.paymentMethods) {

                        const { paymentMethods } = res.data.data;
						const cardItems = paymentMethods.items.filter((item) => item.type === "mc" || item.type === "visa");
						const gcashItem = paymentMethods.items.filter((item) => item.type === "GCash");
						dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(cardItems));
						dispatch(DISPATCH_STATE.fetchGcashSuccess(gcashItem));


                        // dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(res.data.data.paymentMethods.items));
                        resolve(res.data.data.paymentMethods)
                    }
                })
        })

    }
};

export const fetchXenditPaymentMethods = () => {
    return (dispatch, getState) => {
        const getPaymentsRequestBody = {
            query: `query GetXenditPaymentMethods {
					  getXenditPaymentMethods {
						totalItems
						items {
							id
							type
							icon
							name
							value
							isPrimary
							cardNumber
							expirationMonth
							expirationDate
							status
						}
					  }}`
        }
        return new Promise((resolve, reject) => {
            GraphRequest.all(getPaymentsRequestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.getXenditPaymentMethods) {
                        const { getXenditPaymentMethods: paymentMethods } = res.data.data;
						const cardItems = paymentMethods.items
						dispatch(DISPATCH_STATE.fetchPaymentMethodSuccess(cardItems));
                        resolve(res.data.data.getXenditPaymentMethods)
                    }
                })
        })

    }
};

export const fetchBalances = (unit) => {
    return (dispatch, getState) => {
        const getBalanceRequestBody = {
			query: `query GetBalance($unit: String) {
                balances(unit: $unit) {
                    unit
                    balance
                    rate
                    displayValue
                    currencySign
                }
            }`,
			variables: {
				unit: unit ? unit : getState().common.client.currency.unit,
			},
        };
        // dispatch(appLoadingBegin());
        GraphRequest.all(getBalanceRequestBody)
            .then((res) => {
                if(res.data.data && res.data.data.balances) {
                    dispatch(DISPATCH_STATE.setClientBalances(res.data.data.balances));
                    // dispatch(appLoadingEnd());
                }
            })
    }
}

export const addXenditCard = (paymentCard) => {
    return (dispatch, getState) => {
        const addXenditCardBody = {
			query: `mutation addXenditCard($paymentCard: ClientAddXenditCardInput!) {
                addXenditCard(paymentCard: $paymentCard) {
                    succeed
					result
					details
                }
            }`,
			variables: {
				paymentCard
			},
        };
        GraphRequest.all(addXenditCardBody)
            .then((res) => {
                if(res.data.data && res.data.data?.addXenditCard?.succeed) {
					getState().common.pathName ? dispatch(push(getState().common.pathName)) :
					dispatch(push("/account/billing"));

					dispatch(storePathName(null));
                }
            })
    }
}