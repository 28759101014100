import React, { Fragment } from 'react';

import info from '../../../v5-icons/information.svg';
import ProgressBarBooking from "../../../v5-icons/gradient-booking.png";
import ProgressBarBookingNote from "../../../v5-icons/gradient-booking-note.png";
const classNames = require("classnames");
export default function BookingService(props) { 
 
    return (
		<Fragment>
			<div className="booking-service">
				<div className="booking-service-wrapper">
					<div className="booking-service-product-image">
						<img src={props.icon} alt="" className="" />
					</div>
					<div className={classNames("booking-service-product", { "has-padding": props.hasFadePropProduct })}>
						<div className="long-heading text-container">
							<span className="heading booking-service-name">{props.commercialName}</span>
							{props.hasFadePropProduct && (
								<>
									<div className="fader fader-left"></div>
									<div className="fader fader-right"></div>
								</>
							)}
						</div>
						<p className="booking-service-description">{props.descriptionTitle}</p>
					</div>
				</div>
				{/* {props.hasIconInfo ? ( */}
				<div
					onClick={props.showInfoProductModal && props.showInfoProductModal}
					className="info-icon"
					id="tour-product-info"
					data-tut="tour-product-info"
				>
					<img src={info} className="" alt="info icon" />
				</div>
				{/* ) : null} */}
			</div>
			{props.progressBar && props.progressBar === "booking" && (
				<div className="prgress-bar">
					<img src={ProgressBarBooking} alt="ProgressBarBooking" />
				</div>
			)}
			{props.progressBar && props.progressBar === "booking-note" && (
				<div className="prgress-bar">
					<img src={ProgressBarBookingNote} alt="ProgressBarBookingNote" />
				</div>
			)}
		</Fragment>
	);
}