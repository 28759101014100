import React, { Component } from 'react';
import { connect } from "react-redux";
import Lottie from 'react-lottie';
import { IoMdDoneAll } from 'react-icons/io';

import * as voiceCall from './voice-call.json';
import * as videoCall from "./video-call.json";

export class VoipInteractiveMessages extends Component {
    render() {
        const voiceCallOptions = {
			background: "transparent",
			speed: "1",
			loop: true,
			control: true,
			autoplay: true,
			animationData: voiceCall.default,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
        const videoCallOptions = {
			background: "transparent",
			speed: "1",
			loop: true,
			control: true,
			autoplay: true,
			animationData: videoCall.default,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
        
        return (
            this.props.authorType === "partner" ? 
                <div className="voip-box-container">
                    {this.props.title && <div className="title">{this.props.title}</div>}

                    <div className="subtitle">
                        <div className="voice-call-lottie">
                            {this.props.state && this.props.state === "PENDING" ?  this.props.chatType === "video_chat_token" ? <Lottie options={videoCallOptions} /> : <Lottie options={voiceCallOptions} /> : null}
                        </div>
                        {this.props.subTitle && <p>{this.props.state === "PENDING" ? `${this.props.partnerName} is calling you...` : `${this.props.partnerName} called you.`}</p>}
                    </div>

                    {(this.props.state && this.props.job.state !== "Completed") ? 
                        this.props.state !== "PENDING" ? 
                            <p className={`${this.props.state.toLowerCase()} expired-call`}>{this.props.state.charAt(0).toUpperCase() + this.props.state.slice(1).toLowerCase()}</p> 
                            : 
                            <div className="call-to-actions">
                                {this.props.secondaryButton}
                                {this.props.primaryButton}
                            </div>
                    : null }
                </div>
            
            : 
                <div className="voip-box-container client">
                    <div className="rce-mbox-text">
                        <p>{this.props.subTitle}</p>
                    </div>
                    <div className="rce-mbox-time">
                        <span className="rce-mbox-status">
                            <IoMdDoneAll />
                        </span>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    job: state.jobs.job,
});
export default connect(mapStateToProps)(VoipInteractiveMessages);
