import _ from "lodash";
import * as CONSTANT_ACTIONS from "./mapConstant";
import calculateGoogleMapOffset, { calculateGoogleMapOffsetFirstTime } from "../../../functions/calculate-google-map-offset";

/**
 * when script google added in end of page is started
 * @param {*} script
 */
export const initializeMapScript = (script) => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_SCRIPT,
	payload: script,
});

/**
 * when map drag start or end
 */
export const mapOnDragEvent = () => ({
	type: CONSTANT_ACTIONS.MAP_ON_DRAG_EVENT,
});

/**
 * Create ref to #map google in google-map component
 * @param {*} ref
 */
export const initializeMapRef = (ref) => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_REF,
	payload: ref,
});

/**
 * when add script google to end of page is finished
 */
export const initializeMapScriptEnd = () => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_SCRIPT_END,
});

/**
 * when should show map
 */
export const initializeMapShow = () => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_SHOW,
});

/**
 * when should hide map
 */
export const initializeMapHide = () => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_HIDE,
});

/**
 * dispatch when script loaded in end of page
 */
export const mapAfterINitializeScriptLoaded = () => ({
	type: CONSTANT_ACTIONS.MAP_AFTER_INITIALIZE_SCRIPT_LOADED,
});

/**
 * create ref to fake icon finish marker
 * @param {*} finish
 */
export const mapinitializeFinishIcon = (finish) => ({
	type: CONSTANT_ACTIONS.MAP_INITIALIZE_FINISH_ICON,
	payload: finish,
});

/**
 * When we remove maker from map
 */
export const mapRemoveGoogleMarker = () => ({
	type: CONSTANT_ACTIONS.MAP_REMOVE_GOOGLE_MARKER,
});

/**
 * clear map google arrays of destination
 */
export const mapClearGoogleMapArrayOfDestination = () => ({
	type: CONSTANT_ACTIONS.MAP_CLEAR_GOOGLE_MAP_ARRAYS_OF_DESTINATION,
});
/**
 * clear map google arrays of destination
 */
export const mapInputSelectAddressFirstDestination = (places) => ({
	type: CONSTANT_ACTIONS.MAP_INPUT_SELECT_ADDRESS_FIRST_DESTINATION,
	payload: places,
});

export const setGooglePlaceObject = (data) => ({
	type: CONSTANT_ACTIONS.MAP_SET_PLACE_GOOGLE,
	payload: data,
});

export const setGooglePlaceId = (data) => ({
	type: CONSTANT_ACTIONS.MAP_SET_PLACE_GOOGLE_ID,
	payload: data,
});

export const mapInputSelectAddressDispatch = (data) => ({
	type: CONSTANT_ACTIONS.MAP_INPUT_SELECT_ADDRESS,
	payload: data,
});

export const mapGetCurrentLocationLocalstorageDispatch = (data) => ({
	type: CONSTANT_ACTIONS.MAP_GET_CURRENT_LOCATION_LOCALSTORAGE,
	payload: data,
});

export const mapFinishSetLeftTop = () => ({
	type: CONSTANT_ACTIONS.MAP_FINISH_SET_LEFT_TOP,
	payload: calculateGoogleMapOffset(),
});

export const mapFinishSetLeftTopFirstTime = () => ({
	type: CONSTANT_ACTIONS.MAP_FINISH_SET_LEFT_TOP_FIRST_TIME,
	payload: calculateGoogleMapOffsetFirstTime(),
});

export const mapSetCurrentLocationLocalstorageDispatch = () => ({
	type: CONSTANT_ACTIONS.MAP_SET_CURRENT_LOCATION_LOCALSTORAGE,
});

export const mapCreateGoogleMap = (data) => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_GOOGLE_MAP,
	payload: data,
});

export const mapCreateMarker = () => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_MARKER,
	payload: {},
});

export const mapRemoveMarkerForSelectingLocation = () => ({
	type: CONSTANT_ACTIONS.MAP_REMOVE_MARKER_FOR_SELECTING_LOCATION,
});

export const mapCreateMarkerPartner = (data) => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_MARKER_PARTNER,
	payload: data,
});

export const mapCreateMarkerSetPosition = () => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_MARKER_SET_POSITION,
});

export const mapCreateMarkerUnSetPosition = () => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_MARKER_UN_SET_POSITION,
});

export const mapCreateMarkerSetLocationPlaces = () => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_MARKER_SET_LOCATION_PLACES,
});

export const mapCreateCurrentMarker = (data) => ({
	type: CONSTANT_ACTIONS.MAP_CREATE_CURRENT_MARKER,
	payload: data,
});

export const mapGetCurrentLocationDispatch = () => ({
	type: CONSTANT_ACTIONS.MAP_GET_CURRENT_LOCATION,
});

export const mapSearchPlacesFetch = (data) => ({
	type: CONSTANT_ACTIONS.MAP_SEARCH_PLACES_FETCH,
	payload: data,
});

export const mapSearchPlacesFetchForCenterOfMap = (place) => ({
	type: CONSTANT_ACTIONS.MAP_SEARCH_PLACES_FETCH_FOR_CENTER_OF_MAP,
	payload: place,
});

export const mapGetCurrentLocationSet = (data) => ({
	type: CONSTANT_ACTIONS.MAP_GET_CURRENT_LOCATION_SET,
	payload: data,
});

export const mapSearchPlacesFetchByMarkerLocation = () => ({
	type: CONSTANT_ACTIONS.MAP_SEARCH_PLACES_FETCH_BY_MARKER_LOCATION,
});

export const smoothScrollToTopDispatch = () => ({
	type: CONSTANT_ACTIONS.SMOOTH_SCROLL_TO_TOP,
});

export const mapIntervalMarkerMatching = (interval) => ({
	type: CONSTANT_ACTIONS.MAP_INTERVAL_MARKER_MATCHING,
	payload: interval,
});

export const mapClearIntervalMarkerMatching = () => {
	return (dispatch, getState) => {
		if (!_.isNull(getState().map.mapIntervalMarkerMatching)) {
			clearInterval(getState().map.mapIntervalMarkerMatching);
		}
		dispatch({
			type: CONSTANT_ACTIONS.MAP_CLEAR_INTERVAL_MARKER_MATCHING,
			payload: null,
		});
	};
};

export const initUserLocationDetail = (location) => ({
	type: CONSTANT_ACTIONS.INIT_USER_LOCATION_DETAIL,
	payload: location,
});

export const addFirstStop = () => ({
	type: CONSTANT_ACTIONS.ADD_FIRST_STOP,
});
/**
 * Dragg item is started
 */
export const dragGooglePlaceItemBegin = (item) => ({
	type: CONSTANT_ACTIONS.DRAG_GOOGLE_PLACE_ITEM_BEGIN,
	payload: item,
});
/**
 * dragging end so update google places array
 */
export const dragGooglePlaceItemEnd = (item) => ({
	type: CONSTANT_ACTIONS.DRAG_GOOGLE_PLACE_ITEM_END,
	payload: item,
});
/**
 * Change current location when user start dragging and drop it in pickup location
 */
export const changeCurrentLocation = (place) => ({
	type: CONSTANT_ACTIONS.CHANGE_CURRENT_LOCATION,
	payload: place,
});
/**
 *
 *  pickupLocation
 */
export const setPickupLocationToMap = (pickupLocation) => ({
	type: CONSTANT_ACTIONS.SET_PICKUP_LOCATION_TO_MAP,
	payload: pickupLocation,
});

export const setDefaultPickupLocation = (location) => ({
	type: CONSTANT_ACTIONS.SET_DEFAULT_PICKUP_LOCATION,
	payload: location,
});

export const gettingLocationLoading = (condition) => ({
	type: CONSTANT_ACTIONS.GETTINNG_LOCATION_LOADING,
	payload: condition,
});

export const gettingLocationWithoutMapLoading = (condition) => ({
	type: CONSTANT_ACTIONS.GETTING_LOCATION_WITHOUT_MAP_LOADING,
	payload: condition,
});

export const userDeniedGeolocationAccess = () => ({
	type: CONSTANT_ACTIONS.USER_DENIED_GEOLOCATION_ACCESS,
});

export const setGooglePlaceIndex = (index) => ({
	type: CONSTANT_ACTIONS.SET_GOOGLE_PLACE_INDEX,
	payload: index,
})