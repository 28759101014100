import _ from 'lodash';

import * as DISPATCH_STATE from '../commonChangeState';
import { GraphRequest} from '../../../../../axios';
import {appFetchFailure} from './common-failure';
import { push } from "connected-react-router";
/**
 * Fetch profile from different api
 */
export function fetchProfile() {
    return (dispatch) => {
        const fetchProfileRequestBody = {
			query: `query profile {
                countryCodes {
                    code
                    icon
                }
                client {
                    id
                    givenName
                    lastName
                    emailAddress
                    mobileNumber {
                    number
                    fullNumber
                    countryCode {
                        icon
                        code
                    }
                }
                employerCompany
                refereeCode
                referralCode
                currency {
                    unit
                    countryCode
                    locale
                    signText
                    signSvg
                }
                livingCity {
                    id
                    displayValue
                }
                workingCity {
                    id
                    displayValue
                }
                state
                }
            }`,
		};
        GraphRequest.all(fetchProfileRequestBody)
            .then(json => {
                if (!_.isNull(json.data.data)) {
                    dispatch(DISPATCH_STATE.appProifleFetchClientBoundary(json.data.data.client, json.data.data.countryCodes));
                }
            })
        .catch(error => {
            dispatch(appFetchFailure(error))
        });
    };
}
/**
 * update values of profile
 * @param {*} values 
 */
export function updateProfile(values) {
    return (dispatch) => {
        // dispatch(DISPATCH_STATE.appLoadingBegin());
        const updateProfileRequestBody = {
            query: `mutation UpdateClient($input: ClientUpdateInput!) {
                updateClient(data: $input) {
                    id
                    givenName
                    lastName
                    emailAddress
                    mobileNumber {
                        number
                        fullNumber
                        countryCode {
                            icon
                            code
                        }
                    }
                    livingCity {
                        id
                        displayValue
                    }
                    workingCity {
                        id
                        displayValue
                    }
                    employerCompany
                    refereeCode
                    referralCode
                    state
                }
            }`,
            variables: { "input" : values }
        }
        GraphRequest.all(updateProfileRequestBody)
            .then(json => {
                if (!_.isNull(json.data.data) && json.data.data.updateClient)  {
                    dispatch(fetchProfile());
                    dispatch(push("/account"));
                }
            })
            .catch(error => {
                dispatch(appFetchFailure(error))
            });
    };
}