import React, { Fragment } from "react";
import { connect } from "react-redux";

import PaymentMethodItem from "./PaymentMethodItem";

function PaymentMethod(props) {
	return (
		<Fragment>
			<div className="billing-information-card">
				{props.methods.map((method, index) => {
					return <PaymentMethodItem key={index} method={method} onChangePrimary={props.onChangePrimary} />;
				})}
			</div>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	...state.billing,
    common: state.common
});

export default connect(mapStateToProps)(PaymentMethod);
