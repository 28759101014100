import * as CONSTANT_ACTIONS from './commonConstant';
import { detectUserDesktop } from '../../../functions/detect-user-agent';

export const appLoadingBegin = () => ({
    type: CONSTANT_ACTIONS.APP_LOADING_BEGIN
});

export const appLoadingEnd = () => ({
    type: CONSTANT_ACTIONS.APP_LOADING_END
});

export const appLoadingKuyaBegin = () => ({
    type: CONSTANT_ACTIONS.APP_LOADING_KUYA_BEGIN
});

export const appLoadingKuyaEnd = () => ({
    type: CONSTANT_ACTIONS.APP_LOADING_KUYA_END
});

export const appAuthCheckBegin = () => ({
    type: CONSTANT_ACTIONS.APP_AUTH_CHECK_BEGIN
});
// check user go home from other page
export const appGoHomeCheck = () => ({
    type: CONSTANT_ACTIONS.APP_GO_HOME_CHECK
});
// when internet is disconnected
export const appInternetIsDisconnected = () => ({
    type: CONSTANT_ACTIONS.APP_INTERNET_IS_DISCONNECTED
})

// when user enter wrong verification code
export const wrongVerificationCode = (data) => ({
    type: CONSTANT_ACTIONS.WRONG_VERIFICATION_CODE,
    payload: data
})

export const appAuthCheckPassed = (token) => ({
    type: CONSTANT_ACTIONS.APP_AUTH_CHECK_PASSED,
    payload: { token }
});

export const appAuthCheckFailed = (token, auth) => ({
    type: CONSTANT_ACTIONS.APP_AUTH_CHECK_FAILED,
    payload: { token, auth }
});

export const appAuthCheckEnd = () => ({
    type: CONSTANT_ACTIONS.APP_AUTH_CHECK_END
});

export const appAuthCheckInit = () => ({
    type: CONSTANT_ACTIONS.APP_AUTH_CHECK
});

export const appAuthRedirect = () => ({
    type: CONSTANT_ACTIONS.APP_AUTH_REDIRECT
});

export const appDetectDesktopUser = () => ({
    type: CONSTANT_ACTIONS.APP_DETECT_DESKTOP_USER,
    payload: detectUserDesktop()
});

export const appAuthGetClient = (client) => ({
    type: CONSTANT_ACTIONS.APP_AUTH_GET_CLIENT,
    payload: client
});

export const appGetClient = (client) => ({
    type: CONSTANT_ACTIONS.APP_GET_CLIENT,
    payload: client
});

export const appProifleFetchClientBoundary = (client, country) => ({
    type: CONSTANT_ACTIONS.APP_PROFILE_FETCH_CLIENT_BOUNDARY,
    payload: {client, country}
});

export const appProifleLogout = () => ({
    type: CONSTANT_ACTIONS.APP_PROFILE_LOGOUT,
});
/**
 * When user click on one link in bottom bar
 */
export const appUserClickOnBottomBarLink = () => {
    return (dispatch) => {
        dispatch({ type: CONSTANT_ACTIONS.APP_USER_CLICK_ON_BOTTOM_BAR_LINK });
    }
}

export const appInternetTryAgainDispatch = () => ({
    type: CONSTANT_ACTIONS.APP_INTERNET_TRY_AGAIN,
});

export const appDetectRotationDevice = () => ({
    type: CONSTANT_ACTIONS.APP_DETECT_ROTATION_DEVICE,
    payload: (window.orientation === 90 || window.orientation === -90) ? true : false
});

export const appInitTwilioDispatch = (client) => ({
    type: CONSTANT_ACTIONS.APP_INIT_TWILIO,
    payload: client
});

export const appFetchErrorFailure = (error) => ({
    type: CONSTANT_ACTIONS.APP_FETCH_FAILURE,
    payload: { error }
});

export const appSetLocalstorage = (data) => ({
    type: CONSTANT_ACTIONS.APP_SET_LOCALSTORAGE,
    payload: data
});

export const appAuthGetTwilioToken = (data) => ({
    type: CONSTANT_ACTIONS.APP_AUTH_GET_TWILIO_TOKEN,
    payload: data
});
//when user does not have payment method
export const clientHasNoPaymentMethod = () => ({
	type: CONSTANT_ACTIONS.CLIENT_HAS_NO_PAYMENT_METHOD,
});

export const setclientHasNoPaymentMethodFalse = () => ({
    type: CONSTANT_ACTIONS.SET_CLIENT_HAS_NO_PAYMENT_METHOD_FALSE,
})

export const storeBookingUrl = (pathName) => ({
    type: CONSTANT_ACTIONS.STORE_BOOKING_URL,
    payload: pathName
});

export const storePathName = (pathName) => ({
    type: CONSTANT_ACTIONS.STORE_PATH_NAME,
    payload: pathName
});

export const getPrimaryPayment = (data) => ({
    type: CONSTANT_ACTIONS.GET_PRIMARY_PAYMENT,
    payload: data
});

export const selectTab = (index) => ({
    type: CONSTANT_ACTIONS.SELECT_TAB,
    payload: index
});

export const resetWrongVerificationCodeStatus = () => ({
    type: CONSTANT_ACTIONS.RESET_WRONG_VERIFICATION_CODE_STATUS
});

export const changeTimeFormat = (time) => ({
    type: CONSTANT_ACTIONS.CHANGE_TIME_FORMAT,
    payload: time
});

export const returnErrorFromAdyen = (message) => ({
    type: CONSTANT_ACTIONS.RETURN_ERROR_FROM_ADYEN,
    payload: message
});

export const locationIsChanged = (state) => ({ 
    type: CONSTANT_ACTIONS.LOCATION_IS_CHANGED,
    payload: state
});

export const setLocationPointer = (location) => ({
    type: CONSTANT_ACTIONS.SET_LOCATION_POINTER,
    payload: location
});

export const setCurrentBalance = () => ({
	type: CONSTANT_ACTIONS.SET_CURRENT_BALANCE,
});

export const initIntervalCheckAccountBalance = (interval) => ({
    type: CONSTANT_ACTIONS.INIT_INTERVAL_CHECK_ACCOUNT_BALANCE,
    payload: interval,
});

export const clearIntervalCheckAccountBalance = () => ({
    type: CONSTANT_ACTIONS.CLEAR_INTERVAL_CHECK_ACCOUNT_BALANCE
});

export const setAccountingRequestCount = (index) => ({
    type: CONSTANT_ACTIONS.SET_ACCOUNTING_REQUEST_COUNT,
    payload: index
});

export const toggleChatWithSupportBottomSheet = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_CHAT_WITH_SUPPORT_BOTTOM_SHEET
});

export const navigateToSetLocation = () => ({
    type: CONSTANT_ACTIONS.NAVIGATE_TO_SET_LOCATION
});

export const setLocationSuccessfully = () => ({
    type: CONSTANT_ACTIONS.SET_LOCATION_SUCCESSFULLLY
});

export const toggleLogInBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_LOG_IN_BOTTOM_SHEET,
});

export const toggleLogOutBottomSheet = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_LOG_OUT_BOTTOM_SHEET,
});

export const saveLastPathName = (pathname) => ({
    type: CONSTANT_ACTIONS.SAVE_LAST_PATH_NAME,
    payload: pathname
});

export const setTopupLimitation = (amount) => ({
    type: CONSTANT_ACTIONS.SET_TOPUP_LIMITATION,
    payload: amount
});

export const setPredefinedTopupValues = (values) => ({
	type: CONSTANT_ACTIONS.SET_PREDEFINED_TOPUP_VALUES,
	payload: values,
});

export const toggleTour = (state) => ({
    type: CONSTANT_ACTIONS.TOGGLE_TOUR,
    payload: state
});

export const setTourSteps = (steps) => ({
	type: CONSTANT_ACTIONS.SET_TOUR_STEPS,
	payload: steps,
});

export const setCurrentStep = (step) => ({
    type: CONSTANT_ACTIONS.SET_CURRENT_STEP,
    payload: step,
});

export const unMountTourConfig = () => ({
    type: CONSTANT_ACTIONS.UNMOUNT_TOUR_CONFIG
});

export const tokenExpired = () => ({
    type: CONSTANT_ACTIONS.TOKEN_EXPIRED
});

export const gettingFcmTokenBegin = () => ({
	type: CONSTANT_ACTIONS.GETTING_FCM_TOKEN_BEGIN,
});

export const gettingFcmTokenSuccessed = () => ({
	type: CONSTANT_ACTIONS.GETTING_FCM_TOKEN_SUCCESSED,
});

export const noFcmTokenAvailable = () => ({
	type: CONSTANT_ACTIONS.NO_FCM_TOKEN_AVAILABLE,
});

export const enteringVerificationCode = (data) => ({
    type: CONSTANT_ACTIONS.ENTERING_VERIFICATION_CODE,
    payload: data
});

export const toggleRegisterBottomSheet = (condition) => ({
	type: CONSTANT_ACTIONS.TOGGLE_REGISTER_BOTTOM_SHEET,
	payload: condition,
});

export const setNavigationBlackBox = (data) => ({
    type: CONSTANT_ACTIONS.NAVIGATION_BLACK_BOX,
    payload: data
})

export const openGcashFailedBottomSheet = () => ({
    type: CONSTANT_ACTIONS.OPEN_GCASH_FAILED_BOTTOM_SHEET,
});

export const closeGcashFailedBottomSheet = () => ({
    type: CONSTANT_ACTIONS.CLOSE_GCASH_FAILED_BOTTOM_SHEET,
});

export const xenditMinLimitBottomSheet = (data) => ({
    type: CONSTANT_ACTIONS.XENDIT_MIN_LIMIT_BOTTOM_SHEET,
    payload: data
});

export const toggleQrCodeBottomSheet = (data) => ({
    type: CONSTANT_ACTIONS.TOGGLE_QRCODE_BOTTOM_SHEET,
    payload: data
});
