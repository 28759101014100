import React, { Fragment } from "react";

import BillingInformationPrimaryLozenge from "../../../Account/Billing/BillingInformation/BillingInformationPrimaryLozenge/BillingInformationPrimaryLozenge";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require("classnames");

export default function PaymentMethodItem(props) {
	return (
		<Fragment>
			<div
				className={classNames("billing-information-card-item", {
					mc: props.method.type === "mc",
					visa: props.method.type === "visa",
				})}
				onClick={(e) => props.onChangePrimary(e, props.method.id)}
			>
				<div className="billing-information-card-item-first-row">
					<div className="billing-information-card-item-inner">
						<div className="billing-information-card-item-name">{props.method.name}</div>
						{props.method.isPrimary ? (
							<div className="billing-information-card-item-radio">
								<BillingInformationPrimaryLozenge title="PRIMARY" color="gray" />
							</div>
						) : null}
					</div>
					<div className="billing-information-card-item-img">
						<img
							src={props.method.icon}
							alt={props.method.type}
							className={classNames("billing-card-image", {
								mc: props.method.type === "mc",
								visa: props.method.type === "visa",
							})}
							// className={classNames("billing-card-image", cardType[props.method.value])}
						/>
					</div>
				</div>
				<div className="billing-information-card-item-second-row">
					<div className="billing-information-card-item-field">
						*** <p className="billing-information-card-number">{props.method.cardNumber}</p>
					</div>
					<div className="billing-information-card-expiration">
						<span>VALID THRU</span>
						<p className="billing-information-card-expiration-date">
							{props.method.expirationMonth}/{props.method.value.substring(2, 4)}
						</p>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
