import _ from 'lodash';

import * as DISPATCH_STATE from "../registerChangeState";
import { localStorageGetUsername, setClientLocale, localStorageGetCountryFlag } from "../../../../functions/local-storage";
import { initUserLocationDetail } from "../../mapActions/mapChangeState";
import { localStorageSetIsFirstRequest, locationStorageSetData } from "../../../../functions/local-storage";
import { appAuthCheck } from "../../commonActions";
import { toggleRegisterBottomSheet } from "../../commonActions/commonChangeState";
import { GraphRequest } from "../../../../../axios";
import * as LOG_EVENT from "../../../../analytics";

export const submitClientRegister = (values) => {
    return async (dispatch, getState) => {
        const signupRequestBody = {
			query: `mutation SignupClient($input: ClientCreateInput!) {
				signUp(data: $input) {
					id
					givenName
					lastName
					emailAddress
					currency {
						unit
						countryCode
						locale
						signText
						signSvg
					}
					livingCity {
						id
						displayValue
					}
					referralCode
					mobileNumber {
						number
						fullNumber
						countryCode {
							icon
							code
						}
					}
				}
			}`,
			variables: {
				"input": normalizeValuesForSubmit(values, getState)
			}
		}
        try {
            const { data } = await GraphRequest.all(signupRequestBody);
            return data;
        } catch (e) {}
    }
}

export const registerClient = (values) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.registerClientLoading(true));
        dispatch(submitClientRegister(values))
            .then((res) => {
				dispatch(DISPATCH_STATE.registerClientLoading(false));
                LOG_EVENT.logEvent(LOG_EVENT.USER_INFO_SENT_CWA, {
					success: !_.isNull(res.data) && res.data.signUp ? true : false,
					hasReferralCode: values.referralCode ? true : false,
				});
				dispatch(toggleRegisterBottomSheet(false));
				localStorage.removeItem("userWantsToRegister");
                if (!_.isNull(res.data) && res.data.signUp) {
                    setClientLocale(res.data.signUp.currency.locale);
                    dispatch(appAuthCheck(res.data.signUp));
					LOG_EVENT.logEvent(LOG_EVENT.USER_LOGGED_IN_CWA, { newUser: true });
                }
            })
    }
}

export const setHomeCity = (city) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.setLivingCity(city));
		const location = {
			lat: Number(city.lat),
			lng: Number(city.lng),
			address: city.name,
			title: city.name,
			defaultLat: 14.5995,
			defaultLng: 120.9842,
		};
		dispatch(initUserLocationDetail(!_.isNull(getState().map.location.lat) && !_.isNull(getState().map.location.lng) ? getState().map.location : location));
		localStorageSetIsFirstRequest();
		locationStorageSetData(!_.isNull(getState().map.location.lat) && !_.isNull(getState().map.location.lng) ? getState().map.location : location);
	}
}

const normalizeValuesForSubmit = (values, getState) => {
    const fullNumber = localStorageGetUsername() ? localStorageGetUsername() : getState().common.username;
    const mobileNumber = {
        number: parseInt(fullNumber.slice(2)),
        countryCode: {
            "icon": localStorageGetCountryFlag(),
            "code": `+${fullNumber.slice(0, 2)}`
        }
    }
    values["mobileNumber"] = mobileNumber;
	values["livingCity"] = {
		id: getState().register.livingCity.id,
		displayValue: getState().register.livingCity.name
	};
    // if(!_.isNull(getState().register.livingCity)) {
    //     values["livingCity"] = { id: parseInt(getState().register.livingCity.id) };
    // }
    return values;
}