import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DatePicker from "react-mobile-datepicker";

import * as LOG_EVENT from "../../../utils/analytics/index";
import {
	setDefaultScheduleDate,
	requestScheduledBooking,
	setLimitationScheduleDate,
} from "../../../utils/redux/actions/bookingActions";
import {
	toggleScheduleBooking,
	setScheduleBookingDate,
	setPriceWithAplliedPromoCode, setPromoCode, setNewPrice, setAmountOff, setPercentOff,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { getReviewBookingLocalStorage, getDeliveringToPlaceLocalStorage } from "../../../utils/functions/local-storage";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";
class ScheduledBookingBottomSheet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deliveringToPlace: null,
			clicked: false
		};
	}
	componentDidMount() {
		this.props.dispatch(setLimitationScheduleDate(new Date()));
		this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() });
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.booking.scheduleBookingBottomSheet !== this.props.booking.scheduleBookingBottomSheet) {
			this.setState({ deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage() });
		}
		return true;
	}

	handleCancel = () => {
		this.props.dispatch(toggleScheduleBooking());
		this.props.dispatch(setPriceWithAplliedPromoCode(null));
	};

	handleSelect = () => {
		if(!this.state.clicked) {
			this.setState(() => ({ clicked: true }));
			this.props.dispatch(setScheduleBookingDate(this.props.booking.scheduleDate.toISOString()));
			if (this.props.booking.product.mode.includes("MY_MALL")) {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(
						requestScheduledBooking(
							this.props.booking.scheduleDate.toISOString(),
							this.state.deliveringToPlace ? { jobLocations: [this.state.deliveringToPlace.location] } : this.props.booking.jobLocations,
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				} else {
					this.props.dispatch(
						requestScheduledBooking(
							this.props.booking.scheduleDate.toISOString(),
							this.state.deliveringToPlace
								? { jobLocations: [this.state.deliveringToPlace.location] }
								: this.props.location
								? this.props.location
								: this.props.booking.jobLocations,
							this.state.deliveringToPlace,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				}
			} else {
				if (getReviewBookingLocalStorage()) {
					this.props.dispatch(requestScheduledBooking(this.props.booking.scheduleDate.toISOString(), this.props.booking.jobLocations));
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				} else {
					this.props.dispatch(
						requestScheduledBooking(
							this.props.booking.scheduleDate.toISOString(),
							this.props.location ? this.props.location : this.props.booking.jobLocations,
						),
					);
					if (!_.isNull(this.props.booking.newPrice)) this.props.dispatch(setPriceWithAplliedPromoCode(this.props.booking.newPrice));
				}
			}
			this.props.dispatch(setPromoCode(null));
			this.props.dispatch(setNewPrice(null));
			this.props.dispatch(setAmountOff(null));
			this.props.dispatch(setPercentOff(null));
			LOG_EVENT.logEvent(LOG_EVENT.TRIED_TO_BOOK_CWA, { bookingMode: "scheduled" });
		}
	};

	handleChange = (time) => {
		this.props.dispatch(setDefaultScheduleDate(time));
	}

	render() {	
		const monthMap = {
			1: "Jan",
			2: "Feb",
			3: "Mar",
			4: "Apr",
			5: "May",
			6: "Jun",
			7: "Jul",
			8: "Aug",
			9: "Sep",
			10: "Oct",
			11: "Nov",
			12: "Dec",
		};
		const config = {
			month: {
				format: (value) => monthMap[value.getMonth() + 1],
				caption: "Mon",
				step: 1,
			},
			date: {
				format: "DD",
				caption: "Day",
				step: 1,
			},
			hour: {
				format: "hh",
				caption: "Hour",
				step: 1,
			},
			minute: {
				format: "mm",
				caption: "Min",
				step: 30,
			},
		};
		return (
			<div className="queued-jobs-container">
				<DatePicker
					theme="ios"
					showCaption={true}
					isPopup={true}
					value={this.props.booking.scheduleDate}
					min={this.props.booking.minScheduleDate}
					max={this.props.booking.maxScheduleDate}
					isOpen={this.props.isOpen}
					onSelect={this.handleSelect}
					onCancel={this.handleCancel}
					onChange={this.handleChange}
					dateConfig={config}
					confirmText={
						this.props.booking.scheduleLoading ? (
							<div className="next-button-loading">
								<WhiteLoading />
							</div>
						) : (
							"Confirm"
						)
					}
					cancelText="Cancel"
					headerFormat="MM/DD hh:mm"
					customHeader={
						<div className="custom-date-time-picker-header">
							<h2 className="queued-jobs-container-title">When You Need This Job?</h2>
						</div>
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(ScheduledBookingBottomSheet);