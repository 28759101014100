import _ from 'lodash';
import { toast } from 'react-toastify';

// import * as LOG_EVENT from '../../../../analytics';
import * as DISPATCH_STATE from '../bookingChangeState';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from '../../commonActions';
import { fetchUpdatedJob } from '../../jobsActions';
import { fetchJobInformationSuccessDispatch } from "../../jobsActions/jobsChangeState";
import { onExtendJobChangeState, onNeverMindExtend } from "../../chatActions";
import { getPriceExtendJobRequestBody, extendJobRequestBody } from "../booking-gql";
import { getShopIdStorage } from "../../../../functions/local-storage";
import { togglePaymentMethodsBottomSheet } from "../bookingChangeState";
/**
 * Get price for extend job
 * @param {*} id 
 * @param {*} locations 
 */
export function requestPriceExtendBooking(id, locations) {
    return (dispatch, getState) => {
        // dispatch(appLoadingBegin());
        let variables = {};
        if (Number(getShopIdStorage())) {
            variables = {
                "productId": id ? id : getState().jobs.job.product.id,
                "priceModel": {
                    "jobLocations": locations,
                    "requestId": getState().jobs.job.id,
                    "exclusiveServiceProviderId": !_.isNull(getState().home.shopInfo) ? Number(getState().home.shopInfo.id) : Number(getShopIdStorage())
                },
            } 
        } else {
            variables = {
                "productId": id ? id : getState().jobs.job.product.id,
                "priceModel": {
                    "jobLocations": locations,
                    "requestId": getState().jobs.job.id
                }
            }       
        }
        const getPriceRequestBody = {
            query: getPriceExtendJobRequestBody,
            variables: variables
        }
        GraphRequest.all(getPriceRequestBody)
            .then((res) => {
                if (!_.isNull(res.data.data) && res.data.data.productPriceList) {
                    dispatch(DISPATCH_STATE.bookingSetCurrentPrice(getState().jobs.job.jobTime.cubeNumbers));
                    dispatch(DISPATCH_STATE.fetchJobExtendPriceSuccess(res.data.data.productPriceList));
                    dispatch(fetchJobInformationSuccessDispatch(getState().jobs.job));
                    // dispatch(appLoadingEnd());
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                    // dispatch(appLoadingEnd());
                }
            }).catch(error => {
                dispatch(appFetchFailure(error));
            });
    };
}
/**
 * Extend job request
 */
export function requestExtendJob() {
    return (dispatch, getState) => {
        if (document.getElementsByTagName("body")[0].classList.contains("fixed-body")) {
			document.getElementsByTagName("body")[0].classList.remove("fixed-body");
		}
        dispatch(DISPATCH_STATE.extentionRequestLoading(true));
        const extendJob = {
            query: extendJobRequestBody,
            variables: {
                "jobId": getState().jobs.job.id,
                "extendJobData": {
                    "cubeNumbers": getState().jobs.cubeNumber
                }
            }
        }
        GraphRequest.all(extendJob)
            .then(json => {
                dispatch(DISPATCH_STATE.extentionRequestLoading(false));
                if (!_.isNull(json.data.data) && json.data.data.extendJob) {
                    dispatch(fetchUpdatedJob());
                    dispatch(onNeverMindExtend());
                    toast(`Time extension request submitted`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                    });
                }
                if (json.data.errors) {
                    if (json.data.errors[0].extensions?.exception.status === 402) {
                        dispatch(onExtendJobChangeState());
                        dispatch(togglePaymentMethodsBottomSheet());
                    } else {
                        dispatch(onNeverMindExtend());
                        const errorMessage = json.data.errors[0].message.split(" , ").join(", ");
                        toast(`${errorMessage}`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                        });
                        dispatch(appFetchFailure(json.data.errors[0].message));
                    }
                }
            });
    }
}