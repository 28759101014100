import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';

import './location-search-booking-add-more-stop.css';
import FixedBackground from '../../fixed-background/fixed-background';
import HeaderBack from '../../header-back/header-back';
import LocationSearchBox from '../../LocationSearch/LocationSearchBox/LocationSearchBox';
import {appGoBack} from '../../../utils/redux/actions/commonActions';
import { searchBookingLocationMoreStopOnFocus, searchBookingLocationStopInit, 
    searchBookingStopConfirm, searchCheckBookingProductExist } from '../../../utils/redux/actions/searchActions';
import { removeStepFromPlaces } from '../../../utils/redux/actions/mapActions';
import StartIcon from '../../icons/start-icon';
import LocationSearchSpacerBooking from "../LocationSearchSpacerBooking/LocationSearchSpacerBooking";
import FinishLightIcon from '../../icons/Finish-light-icon';
import LocationPinHoleIcon from '../../icons/location-pin-hole-icon';
import LocationSearchDestinationBox from "../LocationSearchDestinationBox/LocationSearchDestinationBox";
import LocationSearchConfirm from '../../LocationSearch/LocationSearchConfirm/LocationSearchConfirm';


class LocationSearchBookingAddMoreStop extends Component {

    locationSearchBoxInput = React.createRef();
    state = {showPlaces: true};

    constructor(props) {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(searchCheckBookingProductExist());
        this.props.dispatch(searchBookingLocationStopInit());
    }

    onFocus(e, ref, index) {
        this.props.dispatch(searchBookingLocationMoreStopOnFocus(index));
    }

    goBack(e) {
        this.props.dispatch(appGoBack());
    }

    onConfirm(e) {
        this.props.dispatch(searchBookingStopConfirm());
    }

    onClearStop = (e, i) => {
        this.props.dispatch(removeStepFromPlaces(i))
            .then(() => {
                this.setState({showPlaces: false}, () => {
                    this.setState({showPlaces: true});
                })
            });
    }

    render() {
        
        return (
            <Fragment>
                <div className="col-xs-12 no-padding Location-search-booking-add-more-stop">
                    <FixedBackground />
                    <HeaderBack onBack={this.goBack} />
                    <LocationSearchBox
                        index={0} 
                        value={this.props.map.location.title || this.props.map.location.address}
                        onFocus={this.onFocus}
                        autoFocus={false}
                        icon={<StartIcon />}
                        refrence={this.locationSearchBoxInput}
                        onSearchPlace={this.onSearchPlace} 
                        savedPlaces={false}
                    />
                    {this.state.showPlaces && this.props.map.googlePlaces.map((place, i) => (
                        <Fragment key={i}>
                            <LocationSearchSpacerBooking />
                            <LocationSearchBox
                                index={i + 1}
                                value={place.title || place.address}
                                onFocus={this.onFocus}
                                autoFocus={false}
                                icon={<LocationPinHoleIcon />}
                                placeholder="Add a stop"
                                clear={true}
                                onClear={(e) => this.onClearStop(e, i)}
                                onSearchPlace={this.onSearchPlace}
                                savedPlaces={false}
                            />
                        </Fragment>
                    ))}
                    {(this.props.booking.product.maxPoints > this.props.map.googlePlaces.length + 1) ?
                        <Fragment>
                            <LocationSearchSpacerBooking />
                            <LocationSearchDestinationBox
                                index={-1}
                                addStop={this.addStop}
                                value=""
                                autoFocus={false}
                                onFocus={this.onFocus}
                                icon={<FinishLightIcon />}
                                placeholder="Add a stop"
                                onSearchPlace={this.onSearchPlace}/>    
                        </Fragment>
                    : ''}
                    <div className="Location-search-booking-confirm">
                        <LocationSearchConfirm style={{'backgroundColor': '#0488FA'}} title="Confirm" onClick={this.onConfirm} />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    search: state.search,
    common: state.common,
    map: state.map,
    booking: state.booking,
});

export default connect(mapStateToProps)(LocationSearchBookingAddMoreStop);