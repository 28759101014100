import React from "react";
import ContentLoader from "react-content-loader";

const SingleLineSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={50}
		height={20}
		viewBox="0 0 50 20"
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<rect x="3" y="10" rx="3" ry="3" width="35" height="7" />
	</ContentLoader>
);

export default SingleLineSkeleton;
