import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import JobLocations from '../JobLocations/JobLocations';
import { googlePlacesMatching } from '../../../../utils/functions/google-places-matching';
import 'react-circular-progressbar/dist/styles.css';
import { ReactComponent as Kuya } from '../../../../v5-icons/kuya.svg';
import { ReactComponent as Nearby } from '../../../../v5-icons/nearby.svg';
import { ReactComponent as Thunderbolt } from '../../../../v5-icons/thunderbolt.svg';
import { ReactComponent as SuiteCase } from '../../../../v5-icons/suitecase-small.svg';
import { ReactComponent as Clock } from '../../../../v5-icons/clock-small.svg';
import { ReactComponent as Note } from '../../../../v5-icons/note-small.svg';
import { convertTime } from "../../../../utils/functions/job-time-convert";
import { changeBookingTimeFormat } from "../../../../utils/redux/actions/bookingActions";
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import { getDeliveringToPlaceLocalStorage } from "../../../../utils/functions/local-storage";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require('classnames');

class JobMatchingModal extends React.Component {

	constructor() {
		super();
		this.state = {
			deliveringToPlace: null
		}
	}

	componentDidMount() {
		if(this.props.booking.product?.mode.includes("MY_MALL")) {
			this.setState({
				deliveringToPlace: this.props.savedPlaces?.deliveringToPlace || getDeliveringToPlaceLocalStorage()
			});
		}
	}

	render() {
		const { props } = this;
		const jobCost = {
			currency: props.booking.prices.items[props.booking.currentPrice]["price"]["currency"],
			value: props.booking.prices.items[props.booking.currentPrice]['price']["value"]
		};

		const showPayVia = !_.isNull(props.newPrice) ? props.newPrice !== 0 : jobCost.value !== 0;

        return (
			<Fragment>
				<div className="matching-process">
					{!_.isEmpty(props.job) ? (
						<>
							<div className="matching-process-title">
								{this.props.isInstantMatch ? (
									<Fragment>
										<div className="booking-button-icon">
											<Kuya className="kuya-headshot" />
											<Thunderbolt className="icon" />
										</div>
										<div className="title">
											<p>Looking Around</p>
											<span className="text-secondary">Get matched instantly</span>
										</div>
									</Fragment>
								) : (
									<Fragment>
										<div className="booking-button-icon">
											<Kuya className="kuya-headshot" />
											<Nearby className="icon" />
										</div>
										<div className="title">
											<p>Looking Around</p>
											<span className="text-secondary">Get matched in 2 to 3 minutes</span>
										</div>
									</Fragment>
								)}
							</div>
						</>
					) : null}

					{!_.isEmpty(props.job) ? (
						<div className="matching-process-container">
							<div className="matching-process-row">
								<SuiteCase className="icon" />
								<p className="commercial-name">{props.booking.product.commercialName}</p>
							</div>
							<div className="matching-process-row">
								<JobLocations locations={props.booking.jobLocations.jobLocations} />
							</div>
							<div className="matching-process-row">
								<Clock className="icon" />
								<p>
									{jobCost && props.common.client.currency.signText} {!_.isNull(props.newPrice) ? props.newPrice : jobCost.value}{" "}
									for {props.booking.formattedTime}, extendable
								</p>
							</div>
							{showPayVia && (<>{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance <= 0 ? (
								<div className="matching-process-row payment">
								<img
								src={props.primaryPayment[0].icon}
								alt={props.primaryPayment[0].type}
								className={classNames("billing-card-image", props.primaryPayment[0].type)}
								/>
								<p>
								Pay via{" "}
								{!_.isNull(props.primaryPayment[0].name) ? props.primaryPayment[0].name : props.primaryPayment[0].type}
								{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
									? `**** ${props.primaryPayment[0].cardNumber}`
									: null}
								{/*{!_.isNull(props.primaryPayment[0].name) ? props.primaryPayment[0].name : cardType[props.primaryPayment[0].value]}*/}
								{/*{cardType[props.primaryPayment[0].value] === "mc" || cardType[props.primaryPayment[0].value] === "visa"*/}
								{/*	? `**** ${props.primaryPayment[0].cardNumber}`*/}
								{/*	: null}*/}
								</p>
								</div>
								) : null}
							{_.isEmpty(props.primaryPayment) ? (
								<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
								</div>
								) : null}

							{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance > 0 ? (
								<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>
									{/*Pay via mykoins then {cardType[props.primaryPayment[0].value]}{" "}*/}
									{/*{props.primaryPayment[0].value === "MASTERCARD" || props.primaryPayment[0].value === "VISA"*/}
									{/*	? `**** ${props.primaryPayment[0].cardNumber}`*/}
									{/*	: null}*/}
									Pay via mykoins then {props.primaryPayment[0].type}{" "}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
								</div>
								) : null}</>)}

							<div className="matching-process-row note">
								<Note className="icon" />
								{!_.isNull(this.state.deliveringToPlace) ? (
									this.state.deliveringToPlace.note?.length ? (
										<p>{this.state.deliveringToPlace?.note}</p>
									) : (
										<p>No note to partner</p>
									)
								) : !_.isEmpty(props.booking.note) ? (
									<p>{props.booking.note}</p>
								) : (
									<p>No note to partner</p>
								)}
							</div>
						</div>
					) : null}
				</div>
			</Fragment>
		);
    }
}

const mapStateToProps = (state) => ({
	...state.jobs,
	booking: state.booking,
	savedPlaces: state.savedPlaces,
	chat: state.chat,
	common: state.common,
	locations: googlePlacesMatching(state.map),
	isInstantMatch: state.booking.isInstantMatch,
	primaryPayment: state.common.primaryPayment,
	newPrice: state.booking.priceWithAppliedCode,
	billing: state.billing,
});

export default connect(mapStateToProps)(JobMatchingModal);