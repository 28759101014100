import React from 'react';

// import pinImg from '../../assests/icons/pin.png';
import { ReactComponent as PinImg } from '../../assests/icons/pin.svg';

export default function PinIcon(props) {

    return (
        <PinImg  className="img-responsive Pin-icon"/>
    );
}