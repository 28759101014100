import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import _ from "lodash";

import { onNeverMindExtend } from "../../../../utils/redux/actions/chatActions";
import { requestExtendJob } from "../../../../utils/redux/actions/bookingActions";
import { increaseCubeNumber, decreaseCubeNumber } from "../../../../utils/redux/actions/jobsActions";
import JobChatHistoryExtend from "./JobChatHistoryExtend";
import WhiteLoading from "../../../ui-elements/Loading/WhiteLoading";
import { checkXenditMinLimit } from "../../../../utils/redux/actions/commonActions";
import * as DISPATCH_STATE from "../../../../utils/redux/actions/chatActions/chatChangeState";
import { xenditMinLimitBottomSheet } from "../../../../utils/redux/actions/commonActions/commonChangeState";
import { acceptXenditSendMk } from "../../../../utils/redux/actions/chatActions/actions/chat-send-request-mk";
import BottomSheet from "../../../ui-elements/BottomSheet/BottomSheet";
import { XenditMinLimitBottomSheet } from "../../../xenditMinLimitBottomSheet/XenditMinLimitBottomSheet";
import { billingXenditTopUpMyKoin } from "../../../../utils/redux/actions/billingActions";
const classNames = require("classnames");

class JobChatHistoryExtendSheetContent extends Component {

	componentDidMount() {
		// this.props.dispatch(requestPriceExtendBooking(this.props.jobs.job.product.id, this.props.jobs.job.jobLocations));
	}

	onBookingPlus = (e) => {
		this.props.dispatch(increaseCubeNumber());
	};

	onBookingMinus = (e) => {
		this.props.dispatch(decreaseCubeNumber());
	};

	onExtendJob = () => {
		if (this.props.common?.primaryPayment?.[0].type) {
			this.props.dispatch(checkXenditMinLimit("PHP",
				this.priceValue(this.props.prices, this.props.jobs.job.jobTime.cubeNumbers,
					this.props.jobs.cubeNumber))).then((isLowerThanMinLimit) => {
				if (isLowerThanMinLimit) {
					this.props.dispatch(xenditMinLimitBottomSheet(true));
				} else {
					this.props.dispatch(requestExtendJob())
				}
			})
		} else {
			this.props.dispatch(requestExtendJob())
		}
	}

	priceValue = (prices, cubeNumber, currentPrice) => {
		let priceCurrent = prices.items[currentPrice];
		let initialPrice = prices.items[cubeNumber];
		return (priceCurrent.price.value - initialPrice.price.value);
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return (
			<Fragment>
				<div className="Job_Chat_History_Extend_Bottom_Sheet">
					<div className="no-padding">
						<p className="text-center Title">Time Extension</p>
					</div>
					<div className="no-padding">
						{!_.isEmpty(this.props.prices) ? (
							<Fragment>
								<JobChatHistoryExtend
									onBookingPlus={this.onBookingPlus}
									onBookingMinus={this.onBookingMinus}
									prices={this.props.prices}
									currentPrice={this.props.jobs.cubeNumber}
									cubeNumber={this.props.jobs.job.jobTime.cubeNumbers}
									currency={this.props.common.client.currency.signText}
								/>
							</Fragment>
						) : null}
					</div>
					<div className="Job_Chat_History_Extend_Buttons">
						<div className="J_C_H_E_B_Left Job-chat-modal-button-container-left">
							<Button
								disabled={
									this.props.jobs.cubeNumber === this.props.jobs.job.jobTime.cubeNumbers || this.props.extensionRequestLoading
								}
								onClick={(e) => this.onExtendJob()}
								type="button"
								className={classNames({
									"Retry-Button": true,
									disabled: this.props.jobs.cubeNumber === this.props.jobs.job.jobTime.cubeNumbers,
								})}
							>
								{this.props.extensionRequestLoading ? (
									<div className="next-button-loading">
										<WhiteLoading />
									</div>
								) : (
									"Request Time Extension"
								)}
							</Button>
							<Button onClick={(e) => this.props.dispatch(onNeverMindExtend())} type="button" className="Cancel_Button">
								Nevermind
							</Button>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	...state.booking,
	jobs: state.jobs,
	chat: state.chat,
	common: state.common,
	location: {
		jobLocations: [
			{
				lat: state.map.location.lat,
				lng: state.map.location.lng,
				address: state.map.location.address,
				title: state.map.location.title,
			},
		],
	},
});

export default connect(mapStateToProps)(JobChatHistoryExtendSheetContent);