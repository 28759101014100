import React, {Fragment} from 'react';

const pageTitle = (props) => {
	return(
		<Fragment>
			<div className="page-title-box">
				<h3 className="page-title-up h6">{props.title}</h3>
			</div>
		</Fragment>
	)
};
export default pageTitle;