import React, { Component } from 'react';

import {ReactComponent as SystemMessageIcon} from "../../../v5-icons/system-message.svg";
export class SystemMessage extends Component {
    render() {
        return (
            <>
                <span className="timezone">{this.props.date}</span>
                <div className="rce-mbox rce-mbox-mc">
                    <div className="rce-mbox-body">
                        <div className="icon">
                            <SystemMessageIcon />
                        </div>

                        <div className="rce-mbox-text">{this.props.extraInfo?.nextJob || this.props.extraInfo?.previousJob ?
                            <a href=  {this.props.extraInfo?.previousJob ?
                                `/jobs/${this.props.extraInfo?.previousJob}/chat/` : `/jobs/${this.props.extraInfo?.nextJob}/chat/`} >{this.props.text}</a> : <p>{this.props.text}</p>}</div>
                    </div>
                </div>
            </>
        );
    }
}

export default SystemMessage;
