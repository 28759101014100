
import React, { Fragment } from "react";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

const HomeSearchEmpty = (props) => {
	return <Fragment>
		<div className="Home_Empty_Box">
			<div className={`${props.isFocus ? "Hide_Search_Background" : "Show_Search_Background"}`}>
				<DynamicIcon icon="ServiceNotYet" classes="Search_Empty_Product"/>
			</div>
			<h5 className="Not_Found_Title">
				No Recent Services Yet
			</h5>
			<p className="Empty_Search_Text_Home">
				Type the name of the service that you are looking for or <strong className="Get_Help_Strong" onClick={props.onSupport}>Get help
				now</strong>
			</p>
		</div>
	</Fragment>;
};
export default HomeSearchEmpty;
