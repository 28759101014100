import React from 'react';
import Lozenge from '../components/ui-elements/lozenge/lozenge';
export default function TitleBar({ title, leftIcon, rightIcon, rightText, handleClickLeftIcon, handleClickRightIcon, lozengeObject }) {
    return (
		<div className="title-bar-container">
			{leftIcon && (
				<div className="title-bar-icon" onClick={handleClickLeftIcon}>
					<img className="back-button" src={leftIcon} alt="" />
					{lozengeObject && 
						<>
							{lozengeObject.heading && <p className="heading">{lozengeObject.heading}</p>}
							<Lozenge title={lozengeObject.title} color={lozengeObject.color} />
						</>
					}
				</div>
			)}
			{title && <p>{title}</p>}

			<div style={{width: "24px", height: "24px"}}>
				{rightIcon && (<img className="right-icon" onClick={handleClickRightIcon} src={rightIcon} alt="" />)}
				{rightText && (<p className="right-text" onClick={handleClickRightIcon}>{rightText}</p>)}
			</div>
		</div>
	);
}