import {
	INIT_MESSAGES_FROM_JOB_CHAT_HISTORY,
	CHAT_ON_LIVE_LOCATION_PARTNER,
	CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE,
	CHAT_ON_EXTEND_JOB_CHANGE_STATE,
	CHAT_ON_NEVERMIND_CANCEL,
	CHAT_NOTE_TO_PARTNER,
	CHAT_ON_JOB_DETAIL_NAVIGATION,
	CHAT_ON_PHOTO_MESSAGE,
	CHAT_ON_CLOSE_PHOTO_MESSAGE,
	CHAT_WILL_UNMOUNT,
	INIT_MESSAGES_DISPATCH,
	INIT_INTERVAL_GET_MESSAGE,
	GET_MESSAGE_WILL_UNMOUNT_DISPATCH,
	SET_LAST_INDEX,
	SET_OFFSET,
	SET_LIMIT,
	FETCH_MESSAGES_SUCCESS,
	UPDATE_MESSAGE_LIST,
	PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY,
	FETCH_MESSAGES_BEGIN,
	FETCH_MESSAGES_END,
	EMPTY_NEW_MESSAGES,
	SET_LAST_CONSUME_INDEX,
	INIT_PEERS_TOKEN,
	SET_ROOM_INFO,
	PARTICIPANT_JOINED_TO_ROOM,
	GENERATE_PEERS_TOKEN_BEGIN,
	TOGGLE_VOICE_ON_VIDEO_CHAT,
	TOGGLE_VIDEO_ON_VIDEO_CHAT,
	SET_TOKEN_TYPE_FOR_VOIP,
	ADD_PARTNER_INFO,
	PARTNER_JOINED,
	PARTNER_NOT_JOINED,
	SET_AMOUNT_OF_REQUEST_MK_FORM,
	SET_AMOUNT_OF_SEND_MK_FORM,
	CANNOT_REQUEST_MK,
	CONFIRM_SEND_MK,
	REFUSE_SEND_MK,
	RECIEVED_MK_TRANSACTION_HISTORY,
	SENT_MK_TRANSACTION_HISTORY,
	TOGGLE_TRANSFER_MK_NOTE,
	UN_MOUNT_SENT_RECIEVE_MK_TRANSACTIONS,
	TOGGLE_TRANSFER_MK_CONFIRMATION,
	TOGGLE_COPY_CALL_BOTTOM_SHEET,
	MK_TRANSACTIONS_FETCHING,
	SEND_MONEY_LOADING,
	SET_JOB_CANCEL_REASONS,
	CONFIRM_TRANSFER_IN_APP, TOGGLE_TRANSFER_3DS_BOTTOM_SHEET,
} from "../actions/chatActions";

const initialState = {
	newMessages: [],
	updatedMessages: [],
	livePartner: false,
	liveInternal: null,
	chatCancelModal: false,
	chatExtendModal: false,
	chatNoteToPartner: false,
	chatJobDetailModal: false,
	chatShowPhoto: null,
	getMessageSetInterval: null,
	chatLastIndex: null,
	lastConsumeIndex: null,
	offset: null,
	isFetching: false,
	videoCallData: null,
	activeRoom: null,
	hasClientJoinedRoom: false,
	hasParticipantsJoinedRoom: false,
	goToVideoChat: false,
	toggleVoice: true,
	toggleVideo: true,
	tokenType: null,
	partnerInfo: {},
	partnerJoined: false,
	mkRequestFormData: null,
	mkSendFormData: null,
	canRequestMk: true,
	toggleConfirmationSendMkBottomSheet: false,
	transferMk: null,
	transferMkId: null,
	toggleRefuseSendMkBottomSheet: false,
	recievedMkTransactionHistory: null,
	sentMkTransactionHistory: null,
	transferMkNote: null,
	transferMkAmount: null,
	toggleTransferMkNote: false,
	toggleTransferMKConfirmation: false,
	copyCallbottomSheet: false,
	numberClicked: null,
	mkTransactionsFetching: false,
	sendMoneyLoading: false,
	cancelReasons: [],
	transferRequested: null,
	transferFee: null,
	partnerName: null,
	currencySign: null,
	transfer3DsBottomSheet: false
};

export default function chatReducer(state = initialState, action) {
	switch (action.type) {
		case INIT_MESSAGES_FROM_JOB_CHAT_HISTORY:
			return {
				...state,
				newMessages: action.payload,
			};
		case CHAT_ON_LIVE_LOCATION_PARTNER:
			return {
				...state,
				livePartner: !state.livePartner,
				liveInternal: action.payload,
			};
		case CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE:
			return {
				...state,
				livePartner: false,
				liveInternal: null,
			};
		case CHAT_ON_NEVERMIND_CANCEL:
			return {
				...state,
				chatCancelModal: !state.chatCancelModal,
			};
		case CHAT_NOTE_TO_PARTNER:
			return {
				...state,
				chatNoteToPartner: !state.chatNoteToPartner,
			};
		case CHAT_ON_JOB_DETAIL_NAVIGATION:
			return {
				...state,
				chatJobDetailModal: !state.chatJobDetailModal,
			};
		case CHAT_ON_EXTEND_JOB_CHANGE_STATE:
			return {
				...state,
				chatExtendModal: !state.chatExtendModal,
			};
		case CHAT_ON_PHOTO_MESSAGE:
			return {
				...state,
				chatShowPhoto: action.payload,
			};
		case CHAT_ON_CLOSE_PHOTO_MESSAGE:
			return {
				...state,
				chatShowPhoto: null,
			};
		case CHAT_WILL_UNMOUNT:
			return {
				...state,
				chatShowPhoto: null,
				chatCancelModal: false,
				chatExtendModal: false,
				chatJobDetailModal: false,
				chatNoteToPartner: false,
				newMessages: [],
			};
		case INIT_MESSAGES_DISPATCH:
		case FETCH_MESSAGES_SUCCESS:
		case PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY:
			return { 
				...state,
				newMessages: action.payload,
			};

		case UPDATE_MESSAGE_LIST:
			return {
				...state,
				updatedMessages: action.payload,
			};
		case INIT_INTERVAL_GET_MESSAGE:
			return {
				...state,
				getMessageSetInterval: action.payload,
			};
		case GET_MESSAGE_WILL_UNMOUNT_DISPATCH:
			return {
				...state,
				getMessageSetInterval: null,
			};
		case SET_LAST_INDEX:
			return {
				...state,
				chatLastIndex: action.payload,
			};
		case SET_OFFSET:
			return {
				...state,
				offset: action.payload,
			};
		case SET_LIMIT:
			return {
				...state,
				limit: action.payload,
			};
		case FETCH_MESSAGES_BEGIN:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_MESSAGES_END:
			return {
				...state,
				isFetching: false,
			};
		case EMPTY_NEW_MESSAGES:
			return {
				...state,
				newMessages: [],
			};
		case SET_LAST_CONSUME_INDEX:
			return {
				...state,
				lastConsumeIndex: action.payload,
			};
		case INIT_PEERS_TOKEN:
			return {
				...state,
				videoCallData: action.payload
			}
		case SET_ROOM_INFO:
			return {
				...state,
				activeRoom: action.payload,
				hasClientJoinedRoom: true,
			};
		case PARTICIPANT_JOINED_TO_ROOM:
			return {
				...state,
				hasParticipantsJoinedRoom: true
			}
		case GENERATE_PEERS_TOKEN_BEGIN:
			return {
				...state,
				goToVideoChat: true
			}
		case TOGGLE_VOICE_ON_VIDEO_CHAT:
			return {
				...state,
				toggleVoice: !state.toggleVoice
			}
		case TOGGLE_VIDEO_ON_VIDEO_CHAT:
			return {
				...state,
				toggleVideo: !state.toggleVideo
			}
		case SET_TOKEN_TYPE_FOR_VOIP:
			return {
				...state,
				tokenType: action.payload
			}
		case ADD_PARTNER_INFO:
			return  {
				...state,
				partnerInfo: action.payload
			}
		case PARTNER_JOINED:
			return {
				...state,
				partnerJoined: true,
			}
		case PARTNER_NOT_JOINED:
			return {
				...state,
				partnerJoined: false
			}
		case SET_AMOUNT_OF_REQUEST_MK_FORM:
			return {
				...state,
				mkRequestFormData: action.payload
			}
		case SET_AMOUNT_OF_SEND_MK_FORM:
			return {
				...state,
				mkSendFormData: action.payload
			}
		case CANNOT_REQUEST_MK:
			return {
				...state,
				canRequestMk: !state.canRequestMk
			}
		case CONFIRM_SEND_MK:
			return {
				...state,
				toggleConfirmationSendMkBottomSheet: !state.toggleConfirmationSendMkBottomSheet,
				transferMk: action.payload.amount,
				transferMkId: action.payload.referenceId
			}
		case REFUSE_SEND_MK:
			return {
				...state,
				toggleRefuseSendMkBottomSheet: !state.toggleRefuseSendMkBottomSheet,
				transferMk: action.payload.amount,
				transferMkId: action.payload.referenceId
			}
		case RECIEVED_MK_TRANSACTION_HISTORY:
			return {
				...state,
				recievedMkTransactionHistory: action.payload
			}
		case SENT_MK_TRANSACTION_HISTORY:
			return {
				...state,
				sentMkTransactionHistory: action.payload
			}
		case TOGGLE_TRANSFER_MK_NOTE:
			return {
				...state,
				transferMkNote: action.payload.note,
				transferMkAmount: action.payload.amount,
				toggleTransferMkNote: !state.toggleTransferMkNote
			}
		case UN_MOUNT_SENT_RECIEVE_MK_TRANSACTIONS:
			return {
				...state,
				recievedMkTransactionHistory: null,
				sentMkTransactionHistory: null,
			}
		case TOGGLE_TRANSFER_MK_CONFIRMATION:
			return {
				...state,
				toggleTransferMKConfirmation: !state.toggleTransferMKConfirmation
			}
		case TOGGLE_COPY_CALL_BOTTOM_SHEET:
			return {
				...state,
				copyCallottbomSheet: !state.copyCallottbomSheet,
				numberClicked: action.payload
			};
		case MK_TRANSACTIONS_FETCHING:
			return {
				...state,
				mkTransactionsFetching: action.payload
			}
		case SEND_MONEY_LOADING:
			return {
				...state,
				sendMoneyLoading: action.payload
			}
		case SET_JOB_CANCEL_REASONS:
			return {
				...state,
				cancelReasons: action.payload
			}
		case CONFIRM_TRANSFER_IN_APP:
			return {
				...state,
				toggleConfirmationSendMkBottomSheet: !state.toggleConfirmationSendMkBottomSheet,
				transferRequested: action.payload.amount,
				transferFee: action.payload.transferFee,
				transferMkId: action.payload.referenceId,
				partnerName: action.payload.partnerName,
				currencySign: action.payload.currencySign
			}
		case TOGGLE_TRANSFER_3DS_BOTTOM_SHEET:
			return {
				...state,
				transfer3DsBottomSheet: action.payload,
			};
		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}