import { toast } from 'react-toastify';
import _ from 'lodash';
import { push } from 'connected-react-router';

import * as DISPATCH_STATE from '../jobsChangeState';
import * as CONSTANT_ACTIONS from '../jobsConstant';
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure } from '../../commonActions';
import {emptyNewMessages} from '../../chatActions/chatChangeState';
import { homeWillUnMount } from '../../homeActions/homeChangeState';
import { jobStateCheckRequestBody, unratedJobAndJobStateCheckerRequestBody } from "../jobs-gql";
import { fetchActiveJob, fetchJobInformationSuccess } from "./jobs-detail";
import { getAllMessages, getMessagesWillUnmount } from "../../chatActions";
import { voipIsSupported } from "../../bookingActions/bookingChangeState";
/**
 *  setup interval request for job completed
 */
export const intervalJobStateChecker = () => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(DISPATCH_STATE.initIntervalJobStateCheck(setInterval(() => {
                dispatch(jobStateChecker()) 
                    .then((res) => {
                        const { unratedJobs, request } = res?.data || {};
                        if (_.has(res?.data, "unratedJobs") && 
                            unratedJobs.totalItems > 0 && 
                            getState().jobs.job.id === unratedJobs.id) {
                            dispatch(DISPATCH_STATE.fetchJobsUnratedSuccess(unratedJobs.items));
                        }
                        if (_.has(res?.data, "unratedJobs") && request) {
                            dispatch(homeWillUnMount());
                            dispatch(JobChangeStateIntervalChat(request));
                        }
                    });
            }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_CHECK_COMPLITION)));
        }
    }
}

export const jobStateChecker = (jobId) => {
    return async (dispatch, getState) => {
        const requestBody = {
            query: unratedJobAndJobStateCheckerRequestBody,
            variables: {
                "requestId": jobId ? Number(jobId) : getState().jobs.job.id,
                "offset": 0,
                "limit": 1
            }
        }

        try {
            const { data } = await GraphRequest.all(requestBody);
            return data;
        
        } catch(err) {
            // return dispatch(appFetchFailure(err));
        }
    }
}

export const checkJobState = (jobId) => {
    return (dispatch, getState) => {
        dispatch(jobStateChecker(jobId))
            .then((res) => {
                if(res.data && res.data.request) {
                    dispatch(DISPATCH_STATE.setJobState(res.data.request.state))
                } else {
                    dispatch(appFetchFailure(res.errors[0].message));
					toast(`${res.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
                }
            })
    }
}
/**
 * Check state job state and redirect user to home if his|her state is COMPLETED|CANCELED
 * @param {*} job 
 */
export const JobChangeStateIntervalChat = (job) => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== job.state) {
            const requestBody = {
				query: jobStateCheckRequestBody,
				variables: {
					requestId: job.id,
				},
			};
            GraphRequest.all(requestBody)
                .then((res) => {
                    if (!_.isNull(res.data.data) && res.data.data.request) { 
                        dispatch(DISPATCH_STATE.jobChangeStateIntervalChat(res.data.data.request));
                    }
                }).catch(error => dispatch(appFetchFailure(error)));
        }
        if(getState().jobs.job.state === "Queued" && job.state === "Matched") {
            dispatch(getMessagesWillUnmount());
            dispatch(fetchActiveJob(job.id)).then((res) => {
                if (res) {
                    dispatch(getAllMessages(res.id));
                    //check voip is supported or not
                    if (!_.isEmpty(res.product.mode) && (res.product.mode.includes("VIDEO_CHAT") || res.product.mode.includes("VOICE_CHAT"))) {
                        const mode = [];
                        if (res.product.mode.includes("VIDEO_CHAT") && res.product.mode.includes("VOICE_CHAT")) {
                            mode.push("VIDEO_CHAT", "VOICE_CHAT");
                        } else if (res.product.mode.includes("VIDEO_CHAT")) {
                            mode.push("VIDEO_CHAT");
                        } else {
                            mode.push("VOICE_CHAT");
                        }
                        dispatch(voipIsSupported(mode));
                    }
                }
            }).catch(error => {
                dispatch(push("/jobs"))
            });
        }
        if (job.state === CONSTANT_ACTIONS.JOB_STATE_CANCELLED) {
            toast(`Job is ${CONSTANT_ACTIONS.JOB_STATE_CANCELLED}!`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                limit: 1,
                className: "toast-rejected-payment",
                bodyClassName: "toastify-inner",
            });
            dispatch(push('/home'));
            dispatch(checkForActiveJobCount());
        } else if (job.state === CONSTANT_ACTIONS.JOB_STATE_COMPLETED) {
            dispatch(emptyNewMessages());
			dispatch(checkForActiveJobCount());
            dispatch(push("/home"));
        }
    }
}
/**
 * When job is completed|canceled check active jobs count
 */
const checkForActiveJobCount = () => {
    return (dispatch, getState) => {
        if (getState().jobs.activeJobsCount !== 0) {
            dispatch(DISPATCH_STATE.jobsActiveCountDecrementOne(getState().jobs.activeJobsCount));
        }
    }
}
