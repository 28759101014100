import React, { Fragment, Component } from 'react';
import {connect} from 'react-redux';
import {IoIosClose} from 'react-icons/io';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {closePhotoShow} from '../../../utils/redux/actions/chatActions';

class PhotoShowMessage extends Component {

    photoShowMessage = React.createRef()
    showImg = React.createRef()
    state = {
        topPhoto: '200px'
    }

    componentDidMount() {
        this.setState({
            topPhoto: (this.photoShowMessage.current.offsetHeight - this.showImg.current.offsetHeight) / 2 + 'px'
        })
    }

    render() {
        return (
			<Fragment>
				<div ref={this.photoShowMessage} className="photo-show-message">
					<div className="close-photo" onClick={(e) => this.props.dispatch(closePhotoShow())}>
						<IoIosClose className="close-io" />
					</div>
					<div className="photo-show-message-image-box">
						<TransformWrapper
							defaultScale={1}
							defaultPositionX={1920}
							defaultPositionY={0}
							zoomIn={{
								step: 2,
							}}
							zoomOut={{
								step: 2,
							}}
							Wheel={{
								step: .5,
								limitsOnWheel: false,
							}}
							scalePadding={{
								size: 1,
							}}
							options={{
								minScale: 0.1,
								maxScale: 1,
								centerContent: false,
								limitToBounds: false,
							}}
						>
							{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
								<React.Fragment>
									<TransformComponent>
										<img ref={this.showImg} className="img-responsive" src={this.props.photo} alt="" />
									</TransformComponent>
								</React.Fragment>
							)}
						</TransformWrapper>
					</div>
				</div>
			</Fragment>
		);
    }
}

const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(PhotoShowMessage);