import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Button from '@material-ui/core/Button';

import JobLocations from '../JobLocations/JobLocations';
import MymallLogo from "../../../../assests/icons/mymall-small.png";
import { cancelSetIntervalAndGoHomeInUnfulfilled } from '../../../../utils/redux/actions/jobsActions';
import { checkKeepLooking } from '../../../../utils/redux/actions/bookingActions';
import buttonTimeout from '../../../../utils/functions/button-timeout';
import { ReactComponent as SuiteCase } from "../../../../v5-icons/suitecase-small.svg";
import { ReactComponent as Clock } from "../../../../v5-icons/clock-small.svg";
import { ReactComponent as Note } from "../../../../v5-icons/note-small.svg";
import { googlePlacesMatching } from '../../../../utils/functions/google-places-matching';
import MyKoinSvg from "../../../../assests/images/billing/my-koin.svg";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";
import { ReactComponent as ScheduledKuya } from "../../../../v5-icons/scheduled-kuya.svg";
import * as LOG_EVENT from "../../../../utils/analytics/index";
import { cardType } from "../../../../utils/constants/constant";
const classNames = require("classnames");

function JobOutOfWorkingHourModal(props) {
    if (_.isFunction(props.prompt)) {
		props.prompt();
	}
	const jobCost = {
		currency: props.booking.prices.items[props.booking.currentPrice]["price"]["currency"],
		value: props.booking.prices.items[props.booking.currentPrice]["price"]["value"],
	};
    return (
		<Fragment>
			<div className="matching-process job-out-of-working">
				<div className="matching-process-title">
					<div className="booking-button-icon">
						<img src={MymallLogo} alt="MymallLogo" />
					</div>
					<div className="title">
						<p>Shoppers serve from 10am-7pm</p>
						<span className="text-secondary">
							As of now Personal shoppers Ates serve from 10am-7pm. Look forward to serving you soon again
						</span>
					</div>
				</div>
				{!_.isEmpty(props.job) ? (
					<div className="matching-process-container">
						<div className="matching-process-row">
							<SuiteCase className="icon" />
							<p className="commercial-name">{props.booking.product.commercialName}</p>
						</div>
						<div className="matching-process-row">
							<JobLocations locations={props.booking.jobLocations.jobLocations} />
						</div>
						<div className="matching-process-row">
							<Clock className="icon" />
							<p>
								{jobCost && props.common.client.currency.signText} {!_.isNull(props.newPrice) ? props.newPrice : jobCost.value} for{" "}
								{props.booking.formattedTime}, extendable
							</p>
						</div>
						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance <= 0 ? (
							<div className="matching-process-row payment">
								<img
									src={props.primaryPayment[0].icon}
									alt={props.primaryPayment[0].type}
									className={classNames("billing-card-image", props.primaryPayment[0].type)}
								/>
								<p>
									Pay via {!_.isNull(props.primaryPayment[0].name) ? props.primaryPayment[0].name : props.primaryPayment[0].type}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}
						{_.isEmpty(props.primaryPayment) ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>Pay via mykoins</p>
							</div>
						) : null}

						{!_.isEmpty(props.primaryPayment) && props.billing.balance[0].balance > 0 ? (
							<div className="matching-process-row">
								<img src={MyKoinSvg} alt="mykoins" className="mykoin-image" />
								<p>
									Pay via mykoins then {props.primaryPayment[0].type}{" "}
									{props.primaryPayment[0].type === "mc" || props.primaryPayment[0].type === "visa"
										? `**** ${props.primaryPayment[0].cardNumber}`
										: null}
								</p>
							</div>
						) : null}

						<div className="matching-process-row">
							<Note className="icon" />
							{!_.isEmpty(props.booking.note) ? <p>{props.booking.note}</p> : <p>No note to partner</p>}
						</div>
					</div>
				) : null}
				<p className="tip">💡 Drop your worries and let the next partner ready, take this job for you. You can cancel anytime too!</p>
				{!_.isUndefined(props.mode) && props.mode.includes("SCHEDULED") ? (
					<CustomButton
						outline
						variant="outlined"
						classList="unfulfilled-schedule-booking-button"
						title={
							<>
								<ScheduledKuya /> <p>Schedule this</p>
							</>
						}
						type="button"
						onClick={props.onScheduleBooking}
					/>
				) : null}
				<div className="job-closed-buttons">
					<Button
						onClick={(e) => {
							LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DID_CANCEL_CWA, { from: "unfulfilled" });
							buttonTimeout(() => props.dispatch(cancelSetIntervalAndGoHomeInUnfulfilled()));
						}}
						type="button"
						className="cancel-button"
					>
						Cancel request
					</Button>
				</div>
			</div>
		</Fragment>
	);
}

const mapStateToProps = (state) => ({
	...state.jobs,
	common: state.common,
	booking: state.booking,
	locations: googlePlacesMatching(state.map),
	primaryPayment: state.common.primaryPayment,
	billing: state.billing,
	newPrice: state.booking.priceWithAppliedCode,
});

export default connect(mapStateToProps)(JobOutOfWorkingHourModal);