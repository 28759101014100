import React, { Fragment } from "react";

import { ReactComponent as MyKoinSvg } from "../../../../assests/images/billing/my-koin.svg";
import { ReactComponent as ForwardImg } from "../../../../v5-icons/forward.svg";
import Lozenge from "../../../ui-elements/lozenge/lozenge";
export default function MyKoinBalance(props) {
	return (
		<Fragment>
			<div className="mykoin-container">
				<div className="billing-information-card-item">
					<div className="mykoin-inner">
						<div className="billing-information-card-item-name">
							mykoins
							<Lozenge title="DEFAULT" color="gunmetal" />
						</div>
						<div className="billing-information-card-item-img">
							<MyKoinSvg className="my-koin-svg" />
						</div>
					</div>
					<div className="billing-information-card-item-value">
						<span>{props.balance[0].availableDisplayValue}</span>
						<div className="top-up-mykoin-container">
							{(props.balance[0].availableBalance <= 0 || props.balance[0].availableBalance < props?.price) ? <span>{props.isMymall ? "" : "Insufficient balance."} </span> : null}
							<p onClick={props.showTopUpBottomSheet} data-tut="tour-topup">
								Top up
								<ForwardImg />
							</p>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
