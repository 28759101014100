import React, {Fragment} from 'react';

import './avatar.css';

export default function Avatar(props) {
    return (
        <Fragment>
            <div className="Avatar">
                <img src={props.img} alt="" className="img-responsive"/>
            </div>
        </Fragment>
    );
}