import React, { Component} from 'react';
import {connect} from 'react-redux';
import { push } from "connected-react-router";
import _ from 'lodash';
import { parse } from "query-string";
import { jsPDF } from "jspdf";
import { appGoBack, checkIsTopUpSuccess, removeQueryParam } from "../../../../utils/redux/actions/commonActions";
import {
	closeGcashFailedBottomSheet,
	openGcashFailedBottomSheet,
	setCurrentBalance,
	toggleChatWithSupportBottomSheet, toggleQrCodeBottomSheet,
} from "../../../../utils/redux/actions/commonActions/commonChangeState";
import {
	fetchMethodsOfPayment,
	billingOnAddPaymentMethodButton,
	billingRequestForChagnePrimary,
	billingDeleteRequestPaymentMethod,
	billingTopUpMyKoin,
	availableMethods,
	fetchXenditAndBalance,
	billingDeleteXenditPaymentMethod,
	billingRequestForXenditChangePrimary,
	billingXenditTopUpMyKoin,
} from "../../../../utils/redux/actions/billingActions";
import {
	toggleBillingTopUpBottomSheet,
	setDefaultValueOfDeletePaymentError,
	supportGcash,
	toggleGcashBottomSheet,
	toggleVerifyingGcashBottomSheet,
	fetchAvailableMethodsLoading,
	fetchPaymentMethodsLoading,
} from "../../../../utils/redux/actions/billingActions/billingChangeState";
import { navigateToSupport } from "../../../../utils/redux/actions/supportActions/supportChangeState";
import {
	localStorageSetCurrentBalance,
	localStorageGetCurrentBalance,
	getAddingGcashLocalStorage,
	removeAddingGcashLocalStorage,
} from "../../../../utils/functions/local-storage";

import BillingInformationCard from './BillingInformationCard/BillingInformationCard';
import BillingInformationAdd from './BillingInformationAdd/BillingInformationAdd';
import BillingInformationSafely from './BillingInformationSafely/BillingInformationSafely';
import BillingInformationBalance from './BillingInformationBalance/BillingInformationBalance';
import buttonTimeout from '../../../../utils/functions/button-timeout';
import TitleBar from "../../../../organisms/title-bar";
import BottomSheet from "../../../ui-elements/BottomSheet/BottomSheet";
import TopUpBottomSheet from "../../../TopUp/TopUpBottomSheet";
import ChatWithSupportBottomSheet from "../../../ChatWithSupportBottomSheet/ChatWithSupportBottomSheet";
import DeletePaymentErrorBottomSheet from "./DeletePaymentErrorBottomSheet/DeletePaymentErrorBottomSheet";
import BillingInformationGcash from "./BillingGCash/BillingInformationGcash";
import BillingGCash from "./BillingGCash/BillingGCash";
import BillingVerifyingGCash from "./BillingGCash/BillingVerifyingGCash";
import SingleListSkeleton from "../../../ui-elements/Skeleton/SingleListSkeleton";

import backButton from "../../../../v5-icons/back-button.svg";
import chatSupport from "../../../../v5-icons/chat-support.svg";
import { getQuerySearchValue } from "../../../../utils/functions/get-query-search-value";
import GcashFailedBottomSheet from "../../../GcashFailedBottomSheet/GcashFailedBottomSheet";
import { QRCode } from "../../../QRCode/QRCode";

class BillingInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
		}
	}
	
	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		// Check gcash is supported or not
		this.props.dispatch(fetchAvailableMethodsLoading(true));
		this.props.dispatch(fetchPaymentMethodsLoading(true));
		this.props.dispatch(availableMethods()).then((res) => {
			if (res.data && !_.isNull(res.data.data.adyenPaymentMethods.paymentMethods)) {
				this.props.dispatch(fetchAvailableMethodsLoading(false));
				res.data.data.adyenPaymentMethods.paymentMethods.filter((x) => {
					if (x.type === "gcash") this.props.dispatch(supportGcash(x));
				});
			}
			this.props.dispatch(fetchMethodsOfPayment()).then(() => {
				this.props.dispatch(fetchPaymentMethodsLoading(false));
				const params = parse(window.location.search);
				if (_.has(params, "topUp")) {
					this.props.dispatch(checkIsTopUpSuccess(params["topUp"]));
					this.props.dispatch(removeQueryParam("topUp", this.props.history));

				} else if (!_.isNull(this.props.gcashItems) && this.props.gcashItems.length) {
					if (_.has(params, "addingGcash") && getAddingGcashLocalStorage()) {
						if (this.props.gcashItems[0].isPending) {
							this.props.dispatch(toggleVerifyingGcashBottomSheet());
						}
						this.props.dispatch(removeQueryParam("addingGcash", this.props.history));
						removeAddingGcashLocalStorage();
					}
				} else {
					if (localStorageGetCurrentBalance()) localStorage.removeItem("currentBalance");
				}

				if (_.has(params, "gCashSucceed")) {
					if (getQuerySearchValue("gCashSucceed") === "false") {
						 this.props.dispatch(openGcashFailedBottomSheet());
					}
				}
			});
		});

		// this.props.dispatch(fetchXenditAndBalance()).then(() => {
		// 	this.props.dispatch(fetchPaymentMethodsLoading(false));
		// 	const params = parse(window.location.search);
		// 	if (_.has(params, "topUp")) {
		// 		this.props.dispatch(checkIsTopUpSuccess(params["topUp"]));
		// 		this.props.dispatch(removeQueryParam("topUp", this.props.history));
		//
		// 	} else if (!_.isNull(this.props.gcashItems) && this.props.gcashItems.length) {
		// 		if (_.has(params, "addingGcash") && getAddingGcashLocalStorage()) {
		// 			if (this.props.gcashItems[0].isPending) {
		// 				this.props.dispatch(toggleVerifyingGcashBottomSheet());
		// 			}
		// 			this.props.dispatch(removeQueryParam("addingGcash", this.props.history));
		// 			removeAddingGcashLocalStorage();
		// 		}
		// 	} else {
		// 		if (localStorageGetCurrentBalance()) localStorage.removeItem("currentBalance");
		// 	}
		//
		// 	if (_.has(params, "gCashSucceed")) {
		// 		if (getQuerySearchValue("gCashSucceed") === "false") {
		// 			this.props.dispatch(openGcashFailedBottomSheet());
		// 		}
		// 	}
		// });
	}

	componentWillUnmount() {
		this.props.dispatch(toggleQrCodeBottomSheet(false));
	}

	onAddMethod = (e) => {
		buttonTimeout(() => {
			this.props.dispatch(billingOnAddPaymentMethodButton());
		});
	};

	onChangePrimary = (e, id) => {
		this.props.dispatch(billingRequestForChagnePrimary(id));
	};

	onDelete = (e, id) => {
		this.props.dispatch(billingDeleteRequestPaymentMethod(id));
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	setTopUpValue = () => {
		this.props.dispatch(billingTopUpMyKoin(parseInt(this.props.topUpMyKoinAmount), process.env.REACT_APP_PUBLIC_URL, this.props.common.client.currency.unit));
	};

	handleClickGotIt = () => {
		this.props.dispatch(setDefaultValueOfDeletePaymentError());
	};

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		return (
			<div className="billing-information-container">
				<div className="billing-information">
					<TitleBar
						title="Payment Methods"
						leftIcon={backButton}
						rightIcon={chatSupport}
						handleClickLeftIcon={(e) => this.props.dispatch(appGoBack("/account"))}
						handleClickRightIcon={this.onSupport}
					/>
					<BillingInformationBalance
						showTopUpBottomSheet={() => {
							this.props.dispatch(toggleBillingTopUpBottomSheet());
							this.props.dispatch(setCurrentBalance());
							localStorageSetCurrentBalance(this.props.balance[0].balance);
						}}
						balance={this.props.balance}
					/>

					{this.props.availableMethodsLoading ? (
						<div className="billing-skeleton">
							<SingleListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
							/>
						</div>
					) : this.props.isGcashSupported ? (
						<BillingInformationGcash
							onChangePrimary={this.onChangePrimary}
							onDelete={this.onDelete}
							toggleGcashBottomSheet={() => this.props.dispatch(toggleGcashBottomSheet())}
							toggleVerifyingGcashBottomSheet={() => this.props.dispatch(toggleVerifyingGcashBottomSheet())}
						/>
					) : null}
					<h2 className="title-cards">Bank Cards</h2>
					{this.props.paymentMethodsLoading ? (
						<div className="billing-skeleton">
							<SingleListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
							/>
						</div>
					) : !_.isNull(this.props.methods) && this.props.methods.length ? (
						<BillingInformationCard onChangePrimary={this.onChangePrimary} onDelete={this.onDelete} />
					) : null}

					<BillingInformationAdd onAddMethod={this.onAddMethod} />
				</div>
				<BillingInformationSafely />
				<BottomSheet
					classes="topup-mykoin-bottom-sheet"
					isOpen={this.props.isShowTopUpBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleBillingTopUpBottomSheet());
					}}
				>
					<TopUpBottomSheet setTopUpValue={this.setTopUpValue} />
				</BottomSheet>

				<BottomSheet
					isOpen={this.props.common.toggleChatWithSupport}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					classes="chat-with-support-bottom-sheet"
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleChatWithSupportBottomSheet());
					}}
				>
					<ChatWithSupportBottomSheet navigateToSupport={this.onSupport} />
				</BottomSheet>

				<BottomSheet
					isOpen={!_.isNull(this.props.errorDeletePaymentText) ? true : false}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					classes="chat-with-support-bottom-sheet"
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(setDefaultValueOfDeletePaymentError());
					}}
				>
					<DeletePaymentErrorBottomSheet handleClickGotItButton={this.handleClickGotIt} errorText={this.props.errorDeletePaymentText} />
				</BottomSheet>

				{this.props.isGcashSupported ? (
					<>
						<BottomSheet
							isOpen={this.props.gcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleGcashBottomSheet());
							}}
						>
							<BillingGCash />
						</BottomSheet>
						<BottomSheet
							isOpen={this.props.verifyingGcashBottomSheet}
							styles={bottomSheetStyle}
							config={{ topShadow: false }}
							indicator={true}
							classes="gcash-bottom-sheet"
							onChangeOverlay={(isOpen) => {
								if (!isOpen) this.props.dispatch(toggleVerifyingGcashBottomSheet());
							}}
						>
							<BillingVerifyingGCash />
						</BottomSheet>
					</>
				) : null}

				{this.props.common.gcashFailedBottomSheet ? (
					<BottomSheet
						isOpen={this.props.common.gcashFailedBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(closeGcashFailedBottomSheet());
						}}
						classes="rejected-payment-bottom-sheet"
					>
						<GcashFailedBottomSheet />
					</BottomSheet>
				) : null}

				<BottomSheet
					classes="topup-mykoin-bottom-sheet"
					isOpen={this.props.common.qrCodeBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleQrCodeBottomSheet(false));
					}}
				>
					<QRCode client={this.props.common?.client} />
				</BottomSheet>
			</div>
		);
	}
}

const mapStateToProps = state => ({
    ...state.billing,
	common: state.common,
	topUpMyKoinAmount: state.booking.topUpMyKoinAmount,
	router: state.router
});

export default connect(mapStateToProps)(BillingInformation);