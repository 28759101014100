import React, {Fragment} from 'react';
import _ from "lodash";

import CustomButton from '../../ui-elements/CustomButton/CustomButton';
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import {ReactComponent as Kuya } from '../../../v5-icons/kuya.svg';
import {ReactComponent as Nearby } from '../../../v5-icons/nearby.svg';
import {ReactComponent as Thunderbolt } from '../../../v5-icons/thunderbolt.svg';
import {ReactComponent as ScheduledKuya} from "../../../v5-icons/scheduled-kuya.svg"
import ServiceFee from "../ServiceFee/ServiceFee";

const classNames = require("classnames");

export default function BookingOverlaidButton(props) {
	return (
		<div className={classNames({
			"booking-overlaid-button-container": true,
			"booking-button-fadein": props.isFadeIn
		})}>
			<div className="booking-button">
				<div className="tour-booking-button" data-tut="tour-booking-button">
					<ServiceFee
						{...props}
					/>
					{!_.isUndefined(props.mode) && props.mode.includes("INSTANT") ? (
						<div data-tut="tour-instant-booking">
							<CustomButton
								disabled={props.loading}
								classList={classNames({
									"is-instant-match": props.isInstantMatch,
								})}
								type="button"
								title={
									props.loading ? (
										<div className="next-button-loading">
											<WhiteLoading />
										</div>
									) : props.isInstantMatch ? (
										<Fragment>
											Request for Ate Shopper
										</Fragment>
									) : (
										<Fragment>
											Request for Ate Shopper
										</Fragment>
									)
								}
								onClick={props.onClick}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}