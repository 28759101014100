import { push } from 'connected-react-router';
import _ from 'lodash';

import * as DISPATCH_STATE from './savedPlacesChangeState';
import { appGoHome } from '../commonActions';
import { searchSetCurrentReferenceName } from '../searchActions/searchChangeState';

/**
 * Check savedPlaces is not null in mapReduer 
 */
export const checkSavedPlacesExist = () => {
    return (dispatch, getState) => {
        if (!getState().savedPlaces.savedPlace) {
            dispatch(push('/saved-places/search'));
        }
    }
}
/**
 * Check savedPlaces is not null in mapReduer 
 */
export const checkSavedPlacesSearchDemand = () => {
    return (dispatch, getState) => {
        if (_.isNull(getState().savedPlaces.savedType)) {
            dispatch(push('/saved-places'));
        }
    }
}
/**
 * navigate to saved places
 */
export const navigateSavedPlaces = () => {
    return (dispatch, getState) => {
        // dispatch(DISPATCH_STATE.savedPlaceSetSearchLocationPointer(getState().router.location.pathname));
        dispatch(setSavedPlaceLocationPointer());
        dispatch(push('/saved-places'));
        if (getState().search.currentRef.current) { // if currentRef exist in location booking page
            let name = getState().search.currentRef.current.name;
            dispatch(searchSetCurrentReferenceName(name));
        }

    }
}
/**
 * Set saved places location pointer
 */
export const setSavedPlaceLocationPointer = () => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.savedPlaceSetSearchLocationPointer(getState().router.location.pathname));
    }
}
/**
 * click on place
 * @param {*} place 
 */
export const clickOnPlace = (place) => {
    return (dispatch, getState) => {
        // dispatch(savedPlaceSelectPlace(createPlaceObjectFromSavedPlaceObject(place)));
        dispatch(push(getState().savedPlaces.searchPointer));
    }
}
/**
 * go back for saved palces page
 */
export const goBackSavedPlaces = () => {
    return (dispatch, getState) => {
        if (_.isNull(getState().savedPlaces.savedType)) {
            dispatch(push(getState().savedPlaces.searchPointer));
        } else {
            if (getState().savedPlaces.searchPointer) {
                dispatch(push(getState().savedPlaces.searchPointer));
            }
        }
    }
}
/**
 * go back for saved palces page
 */
export const checkLocationPointerExist = () => {
    return (dispatch, getState) => {
        if (!getState().savedPlaces.searchPointer) {
            dispatch(appGoHome());
        }
    }
}

/**
 * go back for saved palces page
 */
export const setAddressDetailsForm = (addressDetails) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.setAddressDetailsForm(addressDetails));
    }
}