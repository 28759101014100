import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import SaveIcon from '../../../v5-icons/more-place.svg';
// import ForwardIcon from '../../../assests/icons/forward-icon.svg';
import {navigateSavedPlaces} from '../../../utils/redux/actions/savedPlacesActions';
import forwardImg from "../../../v5-icons/forward.svg";

function LocationSearchSaving(props) {

    return (
		<Fragment>
			<div onClick={(e) => props.dispatch(navigateSavedPlaces())} className="location-search-saving-container">
				<div className="location-search-saving-item">
					<div className="location-search-saving saved-places">
						<div className="saved-places-bookmark">
							<img src={SaveIcon} className="bookmark-icon" alt="Bookmark icon" />
						</div>
						<div className="title-container">
							<p className="add">More Saved Places</p>
							<div className="sub-title">
								Choose from your favorite places
								<div className="forward-icon">
									<img src={forwardImg} className="img-responsive" alt="ForwardIcon" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

const mapStateToProps = state => ({
    map: state.map
});

export default connect(mapStateToProps)(LocationSearchSaving);