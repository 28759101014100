import React from "react";
import ContentLoader from "react-content-loader";

const ListSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={props.devicewidth}
		height={props.deviceheight}
		viewBox={`0 0 ${props.devicewidth} ${props.deviceheight}`}
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<circle cx="28" cy="39" r="18" />
		<rect x="58" y="20" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="39" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="58" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="112" r="18" />
		<rect x="58" y="94" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="112" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="133" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="189" r="18" />
		<rect x="58" y="169" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="189" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="209" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="265" r="18" />
		<rect x="58" y="245" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="265" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="285" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="341" r="18" />
		<rect x="58" y="321" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="341" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="361" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="417" r="18" />
		<rect x="58" y="397" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="417" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="437" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="493" r="18" />
		<rect x="58" y="473" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="493" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="513" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="569" r="18" />
		<rect x="58" y="549" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="569" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="589" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="645" r="18" />
		<rect x="58" y="625" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="645" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="665" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="721" r="18" />
		<rect x="58" y="701" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="721" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="741" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="797" r="18" />
		<rect x="58" y="777" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="797" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="817" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="873" r="18" />
		<rect x="58" y="853" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="873" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="893" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />

		<circle cx="28" cy="949" r="18" />
		<rect x="58" y="929" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
		<rect x="58" y="949" rx="3" ry="3" width={`${props.devicewidth - 110}`} height="6" />
		<rect x="58" y="969" rx="3" ry="3" width={`${props.devicewidth - 80}`} height="6" />
	</ContentLoader>
);

export default ListSkeleton;
