export const REGISTER_FETCH_COUNTRY_CODE = 'REGISTER_FETCH_COUNTRY_CODE';
export const SET_DEFAULT_COUNTRY_CODE = 'SET_DEFAULT_COUNTRY_CODE';
export const REGISTER_FETCH_CITIES = 'REGISTER_FETCH_CITIES';
export const REGISTER_CHANGE_COUNTRY_SELECTED = 'REGISTER_CHANGE_COUNTRY_SELECTED';
export const REGISTER_CODE_VALUE_SET = 'REGISTER_CODE_VALUE_SET';
export const REGISTER_PHONE_NUMBER_VALUE_SET = 'REGISTER_PHONE_NUMBER_VALUE_SET'; // set number when length is valid
export const REGISTER_PHONE_NUMBER_VALUE_UNSET = 'REGISTER_PHONE_NUMBER_VALUE_UNSET'; // remove number when length is not valid
export const INTERVAL_CHECK_CODE_BEGIN = 'INTERVAL_CHECK_CODE_BEGIN';
export const REGISTER_PHONE_NUMBER_REQUEST_FINISH = 'REGISTER_PHONE_NUMBER_REQUEST_FINISH';
export const REGISTER_CHECK_VERIFICATION_CODE = 'REGISTER_CHECK_VERIFICATION_CODE';
export const REGISTER_BACK_TO_WELCOME = 'REGISTER_BACK_TO_WELCOME';
export const REGISTER_CHECK_CLIENT_EXIST = 'REGISTER_CHECK_CLIENT_EXIST';
export const REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE = 'REGISTER_CHECK_REDIRECT_TO_REGISTER_PAGE';
export const REGISTER_CHECK_CODE_REDUCE_COUNT = 'REGISTER_CHECK_CODE_REDUCE_COUNT'; // when start to reduce number of register count number count
export const REGISTER_CHECK_CODE_INTERVAL = 'REGISTER_CHECK_CODE_INTERVAL'; // set interval
export const CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL = 'CLEAR_INTERVAL_NUMBER_CHECK_CODE_INTERVAL'; // when interval clear out
export const INTERVAL_NUMBER_CHECK_CODE = 119;
export const DEFAULT_COUNTRY_CODE = {
	code: "+63",
	icon: "🇵🇭",
};
export const POST_PHONE_NUMBER_HAS_ERROR = 'POST_PHONE_NUMBER_HAS_ERROR';
export const SET_LIVING_CITY = "SET_LIVING_CITY";
export const TOGGLE_SELECT_CITY_MODAL = "TOGGLE_SELECT_CITY_MODAL";
export const VERIFICATION_LOADING = "VERIFICATION_LOADING";
export const ENTER_VERIFICATION_CODE_LOADING = "ENTER_VERIFICATION_CODE_LOADING";
export const REGISTER_CLIENT_LOADING = "REGISTER_CLIENT_LOADING";
export const FETCH_CITIES_LOADING = "FETCH_CITIES_LOADING";