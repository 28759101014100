import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import NewsBox from "./NewsBox/NewsBox";
import NewsEmpty from "./news-empty/news-empty";
import PageTitle from "../ui-elements/PageTitle/PageTitle";
import ListSkeleton from "../ui-elements/Skeleton/ListSkeleton";

import {
	fetchNews,
	selectArticleFromNews,
	willUnmountNews,
} from "../../utils/redux/actions/newsActions";
import TitleBar from "../../organisms/title-bar";
import closeButton from "../../assests/icons/blue-back-button.svg";
import chatSupport from "../../v5-icons/chat-support.svg";
import { appGoHome } from "../../utils/redux/actions/commonActions";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";

class News extends Component {

	constructor(props) {
		super(props);
		this.state = {
			innerWidth: null,
			innerHeight: null,
		};

		this.onArticleClick = this.onArticleClick.bind(this);
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		if (_.isEmpty(this.props.news)) this.props.dispatch(fetchNews()); 
	}

	onArticleClick(e, id, index) {
		if (this.props.news[index].hasUrl) {
			window.location.replace(this.props.news[index].body);
		} else {
			this.props.dispatch(selectArticleFromNews(this.props.news[index]));
			this.props.history.push("/news/" + id);
		}
	}

	onScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			// this.props.dispatch(fetchNewsByOffset());
		}
	};

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	}

	render() {
		let news = "";
		if (!_.isEmpty(this.props.news)) {
			news = this.props.news.map((news, index) => (
				<NewsBox onClick={this.onArticleClick} data={news} index={index} key={news.id} hasUrl={news.hasUrl} />
			));
		}
		return (
			<Fragment>
				<TitleBar 
					leftIcon={closeButton}
					handleClickLeftIcon={() => {
						this.props.dispatch(willUnmountNews());
						this.props.dispatch(appGoHome());
					}}
					handleClickRightIcon={this.onSupport}
					rightIcon={chatSupport}/>
				<PageTitle title="What's New?" />
				{this.props.newsLoading ? (
					!_.isNull(this.state.innerHeight) &&
					!_.isNull(this.state.innerWidth) && (
						<div className="skeleton-news">
							<ListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
								deviceheight={this.state.innerHeight - 132}
							/>
						</div>
					)
				) : (
					<div className="News" onScroll={this.onScroll}>
						{news.length > 0 ? news : "items" in this.props.news ? <NewsEmpty /> : ""}
					</div>
				)}
			</Fragment>
		);
	}
}


const mapStateToProps = state => ({
	...state.news,
});

export default connect(mapStateToProps)(News);