import _ from 'lodash';
import React, {Fragment} from 'react';
import CustomButton from '../../ui-elements/CustomButton/CustomButton';
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

export default function WelcomeContinueButton(props) {

    return (
		<Fragment>
			<CustomButton
				title={
					props.loading ? (
						<div className="next-button-loading">
							<WhiteLoading />
						</div>
					) : props.tryAgain && !_.isNull(props.tryAgain) ? (
						"Try Again"
					) : (
						"Continue"
					)
				}
				onClick={props.onClick}
				disabled={props.disabled && props.disabled}
			/>
		</Fragment>
	);
}