import React, {Fragment} from 'react';
import { connect } from 'react-redux';

function CheckCodeRequestNew(props) {

    return (
		<Fragment>
			<div className="receive">
				<p className="didnt-receive">Didn't receive it?</p>
				{props.requestNumberCheckCode !== 0 ? (
					<p className="receive-new-text">Request new code in {props.requestNumberCheckCode} {props.requestNumberCheckCode > 1 ? "seconds" : "second"} </p>
				) : (
					<>
						<button onClick={props.onRequestNewCode} className="receive-new">
							Request new code now
						</button>
						<button onClick={props.onRequestNewCodeWithCall} className="receive-new">
							Receive a call instead
						</button>
					</>
				)}
			</div>
		</Fragment>
	);
}


const mapStateToProps = state => ({
    ...state.register,
});
  
export default connect(mapStateToProps)(CheckCodeRequestNew);