import {
	SEARCH_SET_CURRENT_REFERENCE,
	SEARCH_SET_CURRENT_REFERENCE_NAME,
	SEARCH_NAVIGATE_TO_MAP_FROM_LOCATION_SEARCH_BOOKING,
} from "../actions/searchActions";
  
    const initialState = {
        currentRef: {},
        currentRefName: '',
        searchLocationWithMap: null
    };
    
    export default function searchReducer(state = initialState, action) {
      switch(action.type) {
        case SEARCH_SET_CURRENT_REFERENCE:
          return {
            ...state,
            currentRef: action.payload
          }
        case SEARCH_SET_CURRENT_REFERENCE_NAME:
          return {
            ...state,
            currentRefName: action.payload
          }
        case SEARCH_NAVIGATE_TO_MAP_FROM_LOCATION_SEARCH_BOOKING:
          return {
            ...state,
            searchLocationWithMap: action.payload
          }
        default:
          // ALWAYS have a default case in a reducer
          return state;
      }
    }