import React, {Fragment} from 'react';

import './location-search-saved-place-box.css';

export default function LocationSearchSavedPlaceBox(props) {

    return (
        <Fragment>
            <div onClick={props.onClick} className="Location-search-saved-place-box">
                {props.icon}
                <p>{props.title}</p>
            </div>
        </Fragment>
    );
}