import { localStorageSetFcmToken, localStorageGetFcmToken, localStorageSetNotifIsNotSupported } from "../../../../functions/local-storage";
import { fcmDeviceParser } from "../../../../functions/notification";
import { GraphRequest } from "../../../../../axios";
import { appLoadingEnd, getPrimaryPayment } from "../../commonActions/commonChangeState";
import * as DISPATCH_STATE from "../commonChangeState";
import { appFetchFailure } from "./common-failure";
import { fetchMethodsOfPayment } from "../../billingActions";
import _ from "lodash";
import { toast } from "react-toastify";	

import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { isSaripay } from "../../../../functions/check-saripay";
/**
 * Initialize Fcm and get token first time
 * @param {*} 
 */
export const appInitFcm = () => {
    return (dispatch, getState) => {
        const messaging = getMessaging();
        
        //handleShowingNotification
        if (!("Notification" in window)) {
			localStorageSetNotifIsNotSupported();
			alert("This browser does not support desktop notification");
		} else if (Notification.permission === "granted") {
			//gettingFcmToken
			dispatch(gettingFcmToken(messaging));
			onMessage(messaging, (payload) => {
				if (payload.data.action === "CHAT_NEW_MESSAGE") {
					if (!getState().router.location.pathname.includes("chat")) {
						showNotificationNewMessage(payload.data);
					}
					// menu page is also included the chat word but still should show the notifs
					if (getState().router.location.pathname.includes("menu")) {
						showNotificationNewMessage(payload.data);
					}
				} else if (payload.data.action === "AddGCashAccount" || payload.data.action === "TopUpAccount") {
					showNotification(payload.data);
					if (getState().router.location.pathname === "/account/billing" || getState().router.location.pathname.includes("review")) {
						dispatch(fetchMethodsOfPayment()).then((res) => {
							if (res.data.data.paymentMethods.totalItems > 0) {
								const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
								dispatch(getPrimaryPayment(primaryPayment.length ? primaryPayment : null));
							}
						});
						toast(`${payload.data.body}`, {
							position: "bottom-center",
							autoClose: 5000,
							limit: 1,
							className: "toast-rejected-payment",
							bodyClassName: "toastify-inner",
							hideProgressBar: true,
							closeOnClick: false,
						});
					}
				} else {
					showNotification(payload.data);
				}
			});
		} else if (Notification.permission !== "denied") {
            //accessNotificationPermission
            dispatch(accessNotificationPermission());
		}
    }
};

export const accessNotificationPermission = () => {
    return (dispatch, getState) => {
        Notification.requestPermission().then((permission) => {
			if (permission === "granted") {
				dispatch(appInitFcm());
			}
		});
    }
}

export const gettingFcmToken = (messaging) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.gettingFcmTokenBegin());
        getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
			let lastFcmToken = localStorageGetFcmToken();
			if (currentToken) {
				if (_.isNull(lastFcmToken)) {
					localStorageSetFcmToken(currentToken);
					dispatch(registerFcm(currentToken));
                    dispatch(DISPATCH_STATE.gettingFcmTokenSuccessed());
				}
			}
			else {
                dispatch(DISPATCH_STATE.noFcmTokenAvailable());
                dispatch(gettingFcmToken(messaging));
			}
		}).catch((error) => {
			// alert(error);
			dispatch(gettingFcmToken(messaging));
			localStorageSetNotifIsNotSupported();
		});
    }
}

export function registerFcm(fcmToken) {
    return (dispatch, getState) => {
        fcmDeviceParser(getState, fcmToken, (obj) => {
            const registerDeviceRequestBody = {
                query : `
                    mutation GetDevice($input: ClientDeviceInput!) {
                        registerDevice(device: $input) {
                            succeed,
                            result,
                            details
                        }
                    }`,
                variables: { "input" : obj}
            }
            GraphRequest.all(registerDeviceRequestBody)
                .then(json => {
                    dispatch(appLoadingEnd());
                })
                .catch(error => {
                    dispatch(appFetchFailure(error));
                });
        });
    }
}

/**
 * Notification function
 */
function showNotificationNewMessage(data) {
	if ("serviceWorker" in navigator) {
        let body = typeof data.message !== "undefined" ? JSON.parse(data.message) : null;
		let authorType;
		
		if (body.authorType && !_.isNull(body.authorType) && body.authorType === "PARTNER") {
			authorType = "Partner";
		} else {
			authorType = "Support";
        }
		navigator.serviceWorker.getRegistration("/firebase-cloud-messaging-push-scope").then((registration) => {
			registration.showNotification(`${data.title}`, {
				body: `Message from ${authorType}\n` + body.text || body.media,
				icon: "./kuya_notification_icon.png",
				data: { ...data }
			});
		});
	}
}

function showNotificationAnnouncement(data) {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.getRegistration("/firebase-cloud-messaging-push-scope").then((registration) => {
			registration.showNotification(`${data.notification.title}`, {
				body: data.notification.body,
				icon: "./kuya_notification_icon.png",
			});
		});
	}
}

function showNotification(data) {
    if ("serviceWorker" in navigator) {
		navigator.serviceWorker.getRegistration("/firebase-cloud-messaging-push-scope").then((registration) => {
			registration.showNotification(`${data.title}`, {
				body: data.body,
				icon: "./kuya_notification_icon.png",
				data: { ...data }
			});
		}).catch((error) => {console.log("error", error)});
    }
}