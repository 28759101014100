export const getBrowserInfo = () => ({
	acceptHeader: "*/*",
	userAgent: navigator.userAgent,
	colorDepth: window.screen.colorDepth,
	screenHeight: window.screen.height,
	screenWidth: window.screen.width,
	javaEnabled: navigator.javaEnabled(),
	language: navigator.language,
	timeZoneOffset: new Date().getTimezoneOffset(),
})
