import _ from "lodash";
import { push } from "connected-react-router";
import $ from "jquery";
import { toast } from "react-toastify";

import * as CONSTANT_ACTIONS from "./bookingConstant";
import * as DISPATCH_STATE from "./bookingChangeState";
import { GraphRequest } from "../../../../axios";
import { appFetchFailure } from "../commonActions";
import { appLoadingBegin, appLoadingEnd, returnErrorFromAdyen } from "../commonActions/commonChangeState";
import { fetchPrice, fetchReviewMymallService } from "./actions/booking-service";
import { billingRequestPaymentCheckout } from "../billingActions";
import { billingTopUpMyKoin } from "../billingActions/billingChangeState";
import { getReviewBookingLocalStorage } from "../../../functions/local-storage.js";
import * as LOG_EVENT from "../../../analytics";
import { getLastUsedRequestBody } from "../savedPlacesActions/actions/saved-places-gql";
import { changeBookingTimeFormat, redirectClientToAdyenForTopUp } from "./bookingChangeState";
import { jobTime } from "../../../functions/job-time-convert";
// import { mapClearGoogleMapArrayOfDestination } from '../mapActions';

/**
 * Toggle description in service detail
 */
export const toggleShowDescription = () => {
	return (dispatch, getState) => {
		if (getState().booking.isDescription) {
			$(".Booking-service-description-component").slideUp();
		} else {
			$(".Booking-service-description-component").slideDown();
		}
		dispatch(DISPATCH_STATE.bookingToggleShowDescription());
	};
};
/**
 * When user leave booking component
 */
export const bookingWillUnMount = () => {
	return (dispatch, getState) => {
		if (getState().router.location.pathname.indexOf("search") <= -1 && getState().router.location.pathname.indexOf("matching") <= -1) {
			dispatch(DISPATCH_STATE.bookingWillUnMountDispatch());
			// dispatch(mapClearGoogleMapArrayOfDestination());
		}
	};
};
/**
 * Toggle Options detail part
 */
export const toggleShowOption = () => {
	return (dispatch, getState) => {
		if (getState().booking.isOption) {
			$(".Booking-options-checkbox").slideUp();
		} else {
			$(".Booking-options-checkbox").slideDown();
		}
		dispatch(DISPATCH_STATE.bookingToggleShowOption());
	};
};
/**
 * Check Toggle Options at first time and check selectedOptions
 */
export const checkToggleShowOption = () => {
	return (dispatch, getState) => {
		if (getState().booking.isOption || !_.isNull(getState().booking.selectedOptions)) {
			$(".Booking-options-checkbox").slideDown();
		}
		if (!_.isNull(getState().booking.selectedOptions)) {
			$(CONSTANT_ACTIONS.BOOKING_OPTIONS_FORM_ID + " input").each(function() {
				if (getState().booking.selectedOptions.includes($(this).data("id"))) {
					$(this).prop("checked", true);
				}
			});
		}
	};
};
/**
 * on focus text of note for request
 */
export const onFocusSpecialTextarea = () => {
	return (dispatch) => {
		dispatch(DISPATCH_STATE.bookingOnFocusSpecialText());
		// $("html,body").animate({
		// 	scrollTop: $(document).height(),
		// }, "slow");
	};
};
/**
 * increase height of textarea box in service detail by entering
 * @param {*} e
 */
export const onKeyDownSpecialTextarea = (e) => {
	return (dispatch) => {
		if (e.keyCode === 13 || e.keyCode === 8) {
			e.target.style.height = e.target.scrollHeight + "px";
		}
	};
};

/**
 * Navigate to location search for watching
 */
export function navigateToLocationFromBooking() {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.bookingNavigateToLocationFromBooking());
		dispatch(push("/search/" + getState().booking.product.id + "/booking"));
		// if (getState().map.googlePlaces.length > 1) {
		//   dispatch(push('/search/' + getState().booking.product.id + '/booking/add'));
		// } else {
		//   dispatch(push('/search/' + getState().booking.product.id + '/booking'));
		// }
	};
}

/**
 * Check options for selected or not
 */
export const bookingCheckOptions = () => {
	return (dispatch, getState) => {
		var selected = [];
		$(CONSTANT_ACTIONS.BOOKING_OPTIONS_FORM_ID + " input:checked").each(function() {
			selected.push($(this).data("id"));
		});
		if (selected.length > 0) {
			dispatch(DISPATCH_STATE.bookingGetSelectedOptions(selected));
		}
	};
};

export const requestCheckBookingProductExist = () => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.requestCheckBookingProductExist());
		if (_.isEmpty(getState().booking.product)) {
			dispatch(push("/home"));
		}
	};
};

export const navigateToRequestPage = (id, location, path) => {
	return (dispatch, getState) => {
		if (!_.isEmpty(getState().map.location)) {
			dispatch(setEventBookingLocationsDidSet(location));
			dispatch(DISPATCH_STATE.fetchPriceLoading(true));
			dispatch(fetchPrice(id, location))
				.then((res) => {
					dispatch(DISPATCH_STATE.fetchPriceLoading(false));
					if(res) {
						dispatch(DISPATCH_STATE.navigateToRequestPage());
						dispatch(push(path));
					}
				}).catch((err) => {
					dispatch(DISPATCH_STATE.fetchPriceLoading(false));
					dispatch(appFetchFailure(err.response.data.errors[0].message));
					toast(`${err.response.data.errors[0].message}`, {
						position: "bottom-center",
						autoClose: 2000,
						limit: 1,
						className: "toast-rejected-payment",
						bodyClassName: "toastify-inner",
						hideProgressBar: true,
						closeOnClick: false,
					});
				})
		}
	};
};

export const setEventBookingLocationsDidSet = (locations) => {
	return (dispatch, getState) => {
		if (!_.isEmpty(getState().map.googlePlaces)) {
			locations = [...locations.jobLocations, ...getState().map.googlePlaces];
		} else {
			locations = [...locations.jobLocations]
		}
		dispatch(DISPATCH_STATE.setLocationsLength(locations.length));
		LOG_EVENT.logEvent(LOG_EVENT.BOOKING_LOCATIONS_DID_SET_CWA, {
			minPoint: getState().booking.product.minPoints,
			maxPoint: getState().booking.product.maxPoints,
			locations: locations.length,
		});
	}
}

export const backToBookingPage = () => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.backToBookingPage());
		dispatch(push(`/booking/${getState().booking.product.id}`));
	};
};

export const navigateToReviewBooking = () => {
	return (dispatch, getState) => {
		if (!_.isEmpty(getState().map.location) && !_.isEmpty(getState().booking.prices)) {
			dispatch(DISPATCH_STATE.navigateToReviewBooking());
			dispatch(push(`/booking/${getState().booking.product.id}/review`));

			LOG_EVENT.logEvent(LOG_EVENT.APP_CHECKOUT_STARTED_CWA, {
				products: [
					{
						product_id: getState().booking.product.id,
						name: getState().booking.product.commercialName,
						price: getState().booking.prices.items[getState().booking.prices.minCubeNumbers].price.value,
					}
				],
				value: getState().booking.prices.items[getState().booking.prices.minCubeNumbers].price.value,
				currency: getState().booking.product.currency
			});
		}
	}
};

export const reviewBookingPriceAndLocationExist = () => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.reviewBookingPriceAndLocationExist());
		if (_.isEmpty(getState().booking.product) || _.isEmpty(getState().booking.prices)) {
			const reviewBooking = JSON.parse(getReviewBookingLocalStorage("reviewBooking"));
			if (!_.isEmpty(reviewBooking)) {
				dispatch(DISPATCH_STATE.changeBookingTimeFormat(reviewBooking["formattedTime"]));
				dispatch(DISPATCH_STATE.onChnageSpecialTextarea(reviewBooking["note"]));
				dispatch(DISPATCH_STATE.setProductOnReviewBooking(reviewBooking["product"]));
				dispatch(DISPATCH_STATE.setPriceOnReviewBooking(reviewBooking["prices"]));
				dispatch(DISPATCH_STATE.fetchPriceBegin(reviewBooking["jobLocations"]));
				dispatch(DISPATCH_STATE.setPromoCode(reviewBooking['promoCode']));
				dispatch(DISPATCH_STATE.setNewPrice(reviewBooking['newPrice']));
				dispatch(DISPATCH_STATE.setAmountOff(reviewBooking['amountOff']));
				dispatch(DISPATCH_STATE.setPercentOff(reviewBooking['percentOff']));
			} else {
				dispatch(push("/home"));
			}
		}
	};
};

export const mymallReviewBookingPriceAndLocationExist = (params) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.reviewBookingPriceAndLocationExist());
		if (!_.isEmpty(params)) {
			const reviewBooking = JSON.parse(getReviewBookingLocalStorage("reviewBooking"));
			if (!_.isEmpty(reviewBooking)) {
				dispatch(DISPATCH_STATE.changeBookingTimeFormat(reviewBooking["formattedTime"]));
				dispatch(DISPATCH_STATE.onChnageSpecialTextarea(reviewBooking["note"]));
				dispatch(DISPATCH_STATE.setProductOnReviewBooking(reviewBooking["product"]));
				dispatch(DISPATCH_STATE.setPriceOnReviewBooking(reviewBooking["prices"]));
				dispatch(DISPATCH_STATE.fetchPriceBegin(reviewBooking["jobLocations"]));
				dispatch(DISPATCH_STATE.setPromoCode(reviewBooking['promoCode']));
				dispatch(DISPATCH_STATE.setNewPrice(reviewBooking['newPrice']));
				dispatch(DISPATCH_STATE.setAmountOff(reviewBooking['amountOff']));
				dispatch(DISPATCH_STATE.setPercentOff(reviewBooking['percentOff']));
			} else {
				dispatch(push("/home"));
			}
		}
	};
};
/**
 * Show balance alert when user's balance is negative
 */
export function checkRejectedPayment() {
	return (dispatch, getState) => {
		if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance < 0) {
			dispatch(DISPATCH_STATE.clientRejectedPayment());
		} else {
			dispatch(DISPATCH_STATE.clientHasPositiveBalance());
		}
	};
}

export const topUpMyKoin = (amount, url, productId, unit) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.topUpMyKoinBegin());
		dispatch(billingTopUpMyKoin(true));

		const isMymall = getState().booking.product.mode.includes("MY_MALL");

		let returnUrl = window.location.host === "localhost:3000" ?
			`http://${window.location.host}/booking/${productId}/${isMymall ? 'review-mymall' : 'review'}?topUp=${amount}` :
			`https://${window.location.host}/booking/${productId}/${isMymall ? 'review-mymall' : 'review'}?topUp=${amount}`;
		if (getState().booking.retryWithTopUp) {
			returnUrl = `${returnUrl}&retryWithTopUp`
		}

		const topUprequestBody = {
			query: `mutation Topup($topUp: ClientTopUpMykoinInput!) {
                topUpMykoinMethod(topUp: $topUp) {
                    succeed
                    result {
                        url
                    }
                }
            }`,
			variables: {
				topUp: {
					amount: amount,
					returnUrl: returnUrl,
					unit: unit ? unit : getState().common.client.currency.unit,
				},
			},
		};
		window.history.pushState("", "", `${window.location.pathname}#adyen`);
		GraphRequest.all(topUprequestBody)
			.then((res) => {
				dispatch(billingTopUpMyKoin(false));
				if (!_.isNull(res.data.data) && res.data.data.topUpMykoinMethod.succeed) {
					dispatch(DISPATCH_STATE.redirectClientToAdyenForTopUp());
					window.location.replace(res.data.data.topUpMykoinMethod.result.url);
				} else {
					dispatch(returnErrorFromAdyen(res.data.errors[0].message));
				}
			});
	}
};

export const bookingXenditTopUpMyKoin = (amount, returnPath, unit, returnQuery) => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.topUpMyKoinBegin());
		dispatch(billingTopUpMyKoin(true));

		const isMymall = getState().booking.product.mode.includes("MY_MALL");

		let returnUrl = window.location.host === "localhost:3000" ?
			`http://${window.location.host}/${returnPath}/${isMymall ? 'review-mymall' : 'review'}?topUp=${amount}` :
			`https://${window.location.host}/${returnPath}/${isMymall ? 'review-mymall' : 'review'}?topUp=${amount}`;
		if (getState().booking.retryWithTopUp) {
			returnUrl = `${returnUrl}&${returnQuery}`
		}

		const topUprequestBody = {
			query: `mutation TopUpXenditMykoinMethod($topUp: ClientXenditTopUpMykoinInput!) {
                topUpXenditMykoinMethod(topUp: $topUp) {
                    succeed
                    result {
                        url
                    }
                    message
                }
            }`,
			variables: {
				"topUp" : {
					amount: amount,
					returnUrl: returnUrl,
					unit: unit ? unit : getState().common.client.currency.unit
				}
			}
		}

		window.history.pushState("", "", `${window.location.pathname}#adyen`);
		GraphRequest.all(topUprequestBody)
			.then((res) => {
				dispatch(billingTopUpMyKoin(false));
				if (!_.isNull(res.data.data) && res.data.data.topUpXenditMykoinMethod.succeed) {
					dispatch(DISPATCH_STATE.redirectClientToAdyenForTopUp());
					window.location.replace(res.data.data.topUpXenditMykoinMethod.result.url);
				} else {
					dispatch(returnErrorFromAdyen(res.data.errors[0].message));
				}
			});
	}
};

export const checkRetryWithCard = () => {
	return (dispatch, getState) => {
		if (_.has(getState().billing, "balance") && getState().billing.balance[0].balance < 0) {
			dispatch(billingRequestPaymentCheckout());
		} else {
			dispatch(appLoadingEnd());
		}
		// localStorage.removeItem("storedUrl");
		// localStorage.removeItem("reviewBooking");
	}
};

export const fetchReviewMymallAction = (productId, locations) => {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			dispatch(fetchReviewMymallService(productId, locations)).then((res) => {
				if (res) {
					dispatch(changeBookingTimeFormat(jobTime(getState().booking?.prices, getState().booking?.prices?.minCubeNumbers)));
					resolve(true)
				}
			})
		})
	}
}
