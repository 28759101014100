import React, { Component } from 'react';
import { connect } from "react-redux";
import { IoMdDoneAll } from 'react-icons/io';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { formatNumbersWithCommas } from "../../../utils/functions/formatNumbersWithCommas";
import { formatPhoneNumber } from "../../../utils/functions/formatPhoneNumber";
import genderPatner from "../../../utils/functions/gender-partner";
export class NewMykoinInteractiveMessages extends Component {

    onShowToast = () => {
        toast(`Copied to clipboard`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className: "toast-rejected-payment",
            bodyClassName: "toastify-inner",
        })
    }
    render() {
        return this.props.authorType === "partner" ? (
			<div className="voip-box-container">
				<div className="interactive-message-header">
					<div className="title">{`${this.props.mykoinSign}${formatNumbersWithCommas(this.props.mykoinAmount)} requested by 
                    ${this.props.partner.title ? this.props.partner.title : genderPatner(this.props.partner)}
                    `}</div>
					<div className="subtitle">Pay directly & minimize fees!</div>
				</div>
				<div className="interactive-message-body">
					<CopyToClipboard text={this.props.gCashNumber} onCopy={this.onShowToast}>
						<div className="gcash-input">
							<div className="gcash-info">
								<div className="title">GCash number</div>
								<div className="value">
									{formatPhoneNumber(
										this.props.gCashNumber.toString().length < 11
											? "0" + this.props.gCashNumber.toString()
											: this.props.gCashNumber.toString(),
									)}
								</div>
							</div>

							<button className="copy-btn">Copy</button>
						</div>
					</CopyToClipboard>
					<CopyToClipboard text={this.props.mykoinAmount} onCopy={this.onShowToast}>
						<div className="gcash-input">
							<div className="gcash-info">
								<div className="title">Exact Amount</div>
								<div className="value">
									{this.props.mykoinSign}
									{formatNumbersWithCommas(this.props.mykoinAmount)}
								</div>
							</div>

							<button className="copy-btn">Copy</button>
						</div>
					</CopyToClipboard>
				</div>
				<div className="interactive-message-action">
					{this.props.primaryButton}
					<div className="action-splitter">
						<div className="dashed-splitter"></div>
						<div className="text">OR</div>
						<div className="dashed-splitter"></div>
					</div>
					{this.props.secondaryButton}
				</div>
				<div className="bank-fee-tip">Easier, but includes bank fees.</div>
			</div>
		) : (
			<div className="voip-box-container client">
				<div className="rce-mbox-text">
					<p>{this.props.subTitle}</p>
				</div>
				<div className="rce-mbox-time">
					<span className="rce-mbox-status">
						<IoMdDoneAll />
					</span>
				</div>
			</div>
		);
    }
}

const mapStateToProps = (state) => ({
    job: state.jobs.job,
});
export default connect(mapStateToProps)(NewMykoinInteractiveMessages);
