import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import * as LOG_EVENT from "../../../../analytics/index";
import { GraphRequest } from '../../../../../axios';
import { appFetchFailure, appAuthCheck,appSetLocalStorage } from "../../commonActions";
import { toggleLogInBottomSheet } from "../../commonActions/commonChangeState";
import { onClearIntervalCheckCodeRequestNumber } from "./register-check-code";
/**
 * check code
 */
export function checkVerificationCodeAction() {
    return async (dispatch, getState) => {
        dispatch(DISPATCH_STATE.enterVerificationCodeLoading(true));
        const checkVerificationCodeRequestBody = {
            query: `mutation CheckVerificationCode($input: ClientMobileVerificationInput!) {
                checkVerificationCode(data: $input) {
                    succeed
                    token
                    username
                    result
                }
            }`,
            variables: {
                "input": initObjCodeVerification(getState)
            }
        }
        try {
            const { data } = await GraphRequest.all(checkVerificationCodeRequestBody);
            return data;
        } catch (e) {}
    }
}
export const checkVerificationCode = (otp) => {
    return (dispatch, getState) => {
        if (/^([0-9]{6})$/.test(otp)) { 
            dispatch(DISPATCH_STATE.checkCodeHandlerAction(otp));
            dispatch(checkVerificationCodeAction())
                .then((res) => {
                    dispatch(DISPATCH_STATE.enterVerificationCodeLoading(false));
                    LOG_EVENT.logEvent(LOG_EVENT.VERIFICATION_CODE_SENT_CWA, { success: res.data.checkVerificationCode.succeed });
                    localStorage.removeItem("userWantsToRegister");
                    if (res.data.checkVerificationCode.succeed) {
                        dispatch(appSetLocalStorage(res.data.checkVerificationCode));
                        dispatch(toggleLogInBottomSheet());
                        dispatch(registerCheckClientExist());
                        LOG_EVENT.logEvent(LOG_EVENT.USER_LOGGED_IN_CWA, {newUser: false});
						dispatch(onClearIntervalCheckCodeRequestNumber());
                    } else {
                        dispatch(appFetchFailure(res.data.checkVerificationCode)) 
                    }
                })
        }
    }
}
/**
 * check client exist or not
 */
const registerCheckClientExist = () => {
    return (dispatch) => {
        const clientRequestBody = {
			query: `query Client {
                client {
                    id
                    givenName
                    lastName
                    emailAddress
                    mobileNumber {
                    number
                    fullNumber
                    countryCode {
                        icon
                        code
                    }
                }
                employerCompany
                refereeCode
                referralCode
                currency {
                    unit
                    countryCode
                    locale
                    signText
                    signSvg
                }
                livingCity {
                    id
                    displayValue
                }
                workingCity {
                    id
                    displayValue
                }
                state
                }
            }`,
		};
        GraphRequest.all(clientRequestBody)
        .then(json => {
            if (!_.isNull(json.data.data) && json.data.data.client) {
                dispatch(checkRedirectToRegister(json.data.data.client));
            } else {
                dispatch(appFetchFailure(json.data.errors[0].message));
            }
        });
        dispatch(DISPATCH_STATE.registerCheckClientExistDispatch());
    }
}
/**
 * Create obj for code verification for sending to server
 * @param {*} getState 
 */
const initObjCodeVerification = (getState) => {
    let obj = {
        "mobileNumber": {
            "number": parseInt(getState().register.number),
            "countryCode": {
                "code": getState().register.countrySelected.code,
                "icon": "🇵🇭"
            }
        },
        "verificationCode": getState().register.code
    }
    return obj;
}
/**
 * Check authority of client
 * @param {*} client 
 */
export const checkRedirectToRegister = (client) => {
    return (dispatch) => {
        dispatch(appAuthCheck(client));
        // dispatch(DISPATCH_STATE.registerCheckRedirectToRegisterPage());
    }
}