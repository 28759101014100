import React, {Fragment} from 'react';
import handShaking from "../../../v5-icons/hand-shake.png"
import { isSaripay } from "../../../utils/functions/check-saripay";
export default function JobRatingCompleted(props) {

    return (
		<Fragment>
			<div className="Job_Rating_Completed_Section_1">
				{props.isShowTopImage && (
					<div className="Job_Rating_S_1_Img">
						<img src={handShaking} alt="" />
					</div>
				)}
				<div className="Job_Rating_S_1_Content">
					<h3>Job Completed</h3>
					<h5>Thank you for using { isSaripay() ? "saripay" : "mykuya" } app!</h5>
				</div>
			</div>
		</Fragment>
	);
}