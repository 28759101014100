import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { push } from "connected-react-router";

import ProfileForm from './ProfileForm/ProfileForm';
import TitleBar from "../../../organisms/title-bar";
import backButton from "../../../v5-icons/back-button.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";

import { updateProfile } from "../../../utils/redux/actions/commonActions";
import {appProifleFetchClientBoundary} from "../../../utils/redux/actions/commonActions/commonChangeState";
class Profile extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(appProifleFetchClientBoundary(this.props.client, [{ code: "+63", icon: "🇵🇭" }])); 
    }

    normalizeValuesForSubmit(values) {
        values[ 'mobileNumber' ] = {
            number: parseInt(values[ 'mobileNumber' ]),
            countryCode: {
                code: values.countryCode,
                icon: values.icon
            }
        };
        delete values.countryCode;
        return values;
    }

    onSubmit(values, setSubmitting) {
        this.props.dispatch(updateProfile(this.normalizeValuesForSubmit(values)));
    }

    render() {
        return (
			<div className="edit-profile">
				<TitleBar
					title="Personal Profile"
					leftIcon={backButton}
					rightIcon={chatSupport}
					handleClickLeftIcon={() => this.props.dispatch(push("/account"))}
					handleClickRightIcon={this.onSupport}
				/>
				{!_.isEmpty(this.props.client) ? <ProfileForm onSubmit={this.onSubmit} {...this.props} /> : null}
			</div>
		);
    }
}

const mapStateToProps = state => ({
    client: state.common.client,
    loading: state.common.loading,
    countries: state.common.countries,
    cities: state.common.cities,
});

export default connect(mapStateToProps)(Profile);