import {
	SELECT_SAVED_PLACES_ADDRESS,
	SAVED_PLACES_FETCH_ALL,
	SAVED_PLACES_SET_TYPE,
	SAVED_PLACES_CLEAR_TYPE_AND_PLACE,
	SAVED_PLACES_FIND_HOME_WORK,
	SAVED_PLACES_SELECT_MAP,
	SAVED_PLACES_UNSELECT_MAP,
	SAVED_PLACES_WANTED_TO_DELETE_PLACE,
	SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE,
	SAVED_PLACES_SET_SEARCH_LOCATION,
	SAVED_PLACES_UNSET_SEARCH_LOCATION,
	SAVED_PLACES_CLICK_SELECTED_PLACE,
	SAVED_PLACES_CLEAR_SELECTED_PLACE,
	UNDO_REMOVE_BOOKMARK_SAVED_PLACE,
	EMPTY_TEMP_STORED_BOOKMARKED,
	FETCH_SAVED_PLACES_LOADING,
	ADD_SAVED_PLACES_LOADING,
	EDIT_LOCATION_SAVED_PLACE,
	ADD_OPENED_MENU_ID,
	SET_DELIVERING_TO_PLACE,
	TOGGLE_OUT_OF_GEO,
	SET_PATHNAME,
	SET_ADDRESS_DETAILS_FORM
} from "../actions/savedPlacesActions";
import * as constant from "../actions/savedPlacesActions/savedPlacesConstant";
    
      const initialState = {
			savedPlace: null, // put address for saving for in saved places
			all: null, // list all saved places
			savedHome: null, // place of home
			savedWork: null, // place of work
			savedType: null,
			savedMap: false,
			savedPlaceWantToDelete: null, // put delete place object for get permission from user
			searchPointer: null,
			selected: null, // when click on one of place in saved places page put place in selected
			tempStoredBookmarked: null,
			fetchSavedPlacesLoading: false,
			addSavedPlaceLoading: false,
			editLocationSavedPlace: null,
			prevOpenedMenuId: null,
		    deliveringToPlace: null,
		    isOutOfGeoBottomSheet: false,
            pathname: null,
		  addressDetailsForm: {
			  savedPlaceType: "",
			  name: "",
			  details: "",
			  phoneCall: false,
			  note: "",
		  }
		};
      
      export default function savedPlacesReducer(state = initialState, action) {
        switch(action.type) {
            case SELECT_SAVED_PLACES_ADDRESS:
                return {
                    ...state,
                    savedPlace: action.payload
                };
            case SAVED_PLACES_FETCH_ALL:
                return {
                    ...state,
                    all: action.payload
                };
            case SAVED_PLACES_SET_TYPE:
                return {
                    ...state,
                    savedType: action.payload
                };
            case SAVED_PLACES_CLEAR_TYPE_AND_PLACE:
                return {
                    ...state,
                    savedPlace: null,
                    savedType: null,
                };
            case SAVED_PLACES_FIND_HOME_WORK:
                return {
                    ...state,
                    savedHome: action.payload.home,
                    savedWork: action.payload.work,
                };
            case SAVED_PLACES_SELECT_MAP:
                return {
                    ...state,
                    savedMap: true,
                };
            case SAVED_PLACES_UNSELECT_MAP:
                return {
                    ...state,
                    savedMap: false,
                };
            case SAVED_PLACES_WANTED_TO_DELETE_PLACE:
                return {
                    ...state,
                    savedPlaceWantToDelete: action.payload,
                };
            case SAVED_PLACES_SET_NULL_WANTED_TO_DELETE_PLACE:
                return {
                    ...state,
                    savedPlaceWantToDelete: null,
                };
            case SAVED_PLACES_SET_SEARCH_LOCATION:
                return {
                    ...state,
                    searchPointer: action.payload,
                };
            case SAVED_PLACES_UNSET_SEARCH_LOCATION:
                return {
                    ...state,
                    searchPointer: null,
                };
            case SAVED_PLACES_CLICK_SELECTED_PLACE:
                return {
                    ...state,
                    selected: action.payload,
                };
            case SAVED_PLACES_CLEAR_SELECTED_PLACE:
                return {
                    ...state,
                    selected: null,
                };
            case UNDO_REMOVE_BOOKMARK_SAVED_PLACE:
                return {
                    ...state,
                    tempStoredBookmarked: action.payload
                };
            case EMPTY_TEMP_STORED_BOOKMARKED:
                return {
                    ...state,
                    tempStoredBookmarked: null
                }
            case FETCH_SAVED_PLACES_LOADING:
                return {
                    ...state,
                    fetchSavedPlacesLoading: action.payload
                }
            case ADD_SAVED_PLACES_LOADING:
                return {
                    ...state,
                    addSavedPlaceLoading: action.payload
                }
            case EDIT_LOCATION_SAVED_PLACE:
                return {
					...state,
					editLocationSavedPlace: action.payload
				};
            case ADD_OPENED_MENU_ID:
                return {
					...state,
					prevOpenedMenuId: action.payload
				};
			case SET_DELIVERING_TO_PLACE:
                return {
					...state,
					deliveringToPlace: action.payload
				};
			case TOGGLE_OUT_OF_GEO:
                return {
					...state,
					isOutOfGeoBottomSheet: !state.isOutOfGeoBottomSheet
				};
            case SET_PATHNAME:
                return {
                    ...state,
                    pathname: action.payload
                }
			case SET_ADDRESS_DETAILS_FORM:
                return {
                    ...state,
                    addressDetailsForm: { ...action.payload }
                }
          default:
            return state;
        }
      }