import React, {Fragment} from 'react';

// import BillingInformationPrimaryInput from '../billing-information-primary-input/billing-information-primary-input';
import SwipeToDelete from '../BillingSwipeToDelete/BillingSwipeToDelete';
import BillingInformationPrimaryLozenge from '../BillingInformationPrimaryLozenge/BillingInformationPrimaryLozenge';
const classNames = require('classnames');

export default function BillingInformationCardItem(props) {

    return (
		<Fragment>
			<SwipeToDelete onDelete={(e) => props.onDelete(e, props.method.id)} height={95} borderRadius={4}>
				<div
					className={classNames("billing-information-card-item", {
						mc: props.method.type === "mc",
						visa: props.method.type === "visa",
					})}
					onClick={(e) => props.onChangePrimary(e, props.method.id)}
				>
					<div className="billing-information-card-item-first-row">
						<div className="billing-information-card-item-inner">
							<div className="billing-information-card-item-name">{props.method.name}</div>
							{props.method.isPrimary ? (
								<div className="billing-information-card-item-radio">
									<BillingInformationPrimaryLozenge title="PRIMARY" color="gray" />
								</div>
							) : null}
						</div>
						<div className="billing-information-card-item-img">
							<img
								src={props.method.icon}
								alt={props.method.type}
								className={classNames("billing-card-image", {
									mc: props.method.type === "mc",
									visa: props.method.type === "visa",
								})}
							/>
						</div>
					</div>
					<div className="billing-information-card-item-second-row">
						<div className="billing-information-card-item-field">
							*** <p className="billing-information-card-number">{props.method.cardNumber}</p>
						</div>
						<div className="billing-information-card-expiration">
							<span>VALID THRU</span>
							<p className="billing-information-card-expiration-date">
								{props.method.expirationMonth}/{props.method.value.substring(2, 4)}
							</p>
						</div>
					</div>
				</div>
			</SwipeToDelete>
		</Fragment>
	);
}