import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";

import backButton from "../../../v5-icons/back-button.svg";

import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import TitleBar from "../../../organisms/title-bar";
import SendingRecievingMkItem from "./SendingRecievingMkItem/SendingRecievingMkItem";
import ListSkeleton from "../../ui-elements/Skeleton/ListSkeleton";

import { backToChatHistoryMenuPage, fetchHistoryTransactions, acceptSendMk, rejecttSendMk } from "../../../utils/redux/actions/chatActions";
import { confirmSendMk, toggleTransferMkNote, refuseSendMk, unMountSentRecieveMkTransactions } from "../../../utils/redux/actions/chatActions/chatChangeState";
class HistoryTransactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			innerWidth: null,
			innerHeight: null
		};
	}
	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		const jobId = !_.isEmpty(this.props.job) ? this.props.job.id : this.props.match.params.id;
		this.props.dispatch(fetchHistoryTransactions(jobId));
	}

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		const jobId = !_.isEmpty(this.props.job) ? this.props.job.id : this.props.match.params.id;
		return (
			<>
				<div className="history-mk-transactions-container">
					<TitleBar
						leftIcon={backButton}
						handleClickLeftIcon={(e) => {
							this.props.dispatch(backToChatHistoryMenuPage(jobId));
							this.props.dispatch(unMountSentRecieveMkTransactions());
						}}
						title="Transfer Money"
					/>
					{this.props.chat.mkTransactionsFetching ? (
						<div className="mk-transactions-skeleton">
							<ListSkeleton
								devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 372 : this.state.innerWidth - 40}
								deviceheight={this.state.innerHeight - 132}
							/>
						</div>
					) : (
						<div className="history-mk-transactions">
							{_.isNull(this.props.chat.sentMkTransactionHistory) && _.isNull(this.props.chat.recievedMkTransactionHistory) ? (
								<div className="empty-state">
									<DynamicIcon icon="EmptyMKTransition" classes="empty-state-image" />
									<p>Need to pay for things? Send or Request money!</p>
								</div>
							) : null}
							{!_.isNull(this.props.chat.sentMkTransactionHistory) ? (
								<>
									<h2 className="history-mk-transactions-title">Sent</h2>
									{this.props.chat.sentMkTransactionHistory.map((item) => (
										<SendingRecievingMkItem data={item} key={item.id} />
									))}
								</>
							) : null}
							{!_.isNull(this.props.chat.recievedMkTransactionHistory) ? (
								<>
									<h2 className="history-mk-transactions-title">Recieved</h2>
									{this.props.chat.recievedMkTransactionHistory.map((item) => (
										<SendingRecievingMkItem data={item} key={item.id} />
									))}
								</>
							) : null}
						</div>
					)}

					<div className="call-to-action">
						<CustomButton
							title="Send/Request"
							type="button"
							onClick={() => this.props.dispatch(push(`/jobs/${jobId}/chat/add-transaction`))}
						/>
					</div>
				</div>

				<BottomSheet
					classes="transfer-mk-note-bottom-sheet"
					isOpen={this.props.chat.toggleTransferMkNote}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleTransferMkNote(null, null));
					}}
				>
					<div className="bottom-sheet-info">
						<p>{this.props.chat.transferMkNote}</p>
						<CustomButton title="Got It!" onClick={() => this.props.dispatch(toggleTransferMkNote(null, null))} />
					</div>
				</BottomSheet>

				{!_.isEmpty(this.props.client) && (
					<BottomSheet
						classes="confirmation-bottom-sheet"
						isOpen={this.props.chat.toggleConfirmationSendMkBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(confirmSendMk(null, null));
						}}
					>
						<div className="bottom-sheet-info">
							<p className="title-send-mk">
								Are you sure to send {this.props.client.currency.signText}
								{this.props.chat.transferMk}?
							</p>
							<div className="call-to-action">
								<CustomButton title="No, Go Back!" outline variant="outlined" onClick={() => this.props.dispatch(confirmSendMk(null, null))} />
								<CustomButton title="Send Money" onClick={() => this.props.dispatch(acceptSendMk(jobId))} />
							</div>
						</div>
					</BottomSheet>
				)}
				{!_.isEmpty(this.props.client) && (
					<BottomSheet
						classes="confirmation-bottom-sheet"
						isOpen={this.props.chat.toggleRefuseSendMkBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(refuseSendMk(null, null));
						}}
					>
						<div className="bottom-sheet-info">
							<h2>Reject Money Request</h2>
							<p>
								Are you sure to reject the request for {this.props.client.currency.signText}
								{this.props.chat.transferMk}?
							</p>
							<div className="call-to-action">
								<CustomButton title="No, Go Back!" outline variant="outlined" onClick={() => this.props.dispatch(refuseSendMk(null, null))} />
								<CustomButton
									title="Yes, Reject It."
									classList="reject-button"
									onClick={() => this.props.dispatch(rejecttSendMk(jobId))}
								/>
							</div>
						</div>
					</BottomSheet>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
    chat: state.chat,
	client: state.common.client,
});

export default connect(mapStateToProps)(HistoryTransactions);
