import React, { Fragment } from "react";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";


const classNames = require("classnames");

export default function JobChatHistoryRecorder(props) {
	return (
		<Fragment>
			<div className="no-padding Job_Chat_History_Record_Voice">
				<div className="no-padding Job_Chat_History_User_Actions_Recorder">
					<div className="J_C_H_U_A_R_Icon">
						<DynamicIcon icon="GrayVoiceRecorder" classes="Gray_Recorder"/>
					</div>
					<div className="J_C_H_U_A_R_Text">
						<p>Recording</p>
					</div>
				</div>
				<div className="Recording_Timer_Box">
					<div className="Job_Chat_History_Record_Voice_Box">
						<div className="values no-padding Timer"></div>
						<div className="Timer_Circle"></div>
					</div>

				</div>
				<div className="Job_Chat_History_Send_Delete">
					<Fragment>
						<div className="no-padding text-center J_C_H_S_D_Box">
							<button
								className="Job_Chat_History_Trash"
								onClick={props.onCancel} type="button">
								<DynamicIcon icon="TrashBin" classes="Orange_Trash"/>

							</button>
						</div>
					</Fragment>
					<Fragment>
						<div className="no-padding text-center J_C_H_S_D_Box">
							<button disabled={props.isDisabled}
									className="Job_Chat_History_Send_Recorder"
									onClick={props.onStop} type="button">
								<DynamicIcon icon="Send" classes="White_Up_Arrow_Record"/>

							</button>
						</div>
					</Fragment>
				</div>

			</div>

		</Fragment>
	);
}
