import React, { Fragment } from 'react';

import SaveIcon from '../../../v5-icons/bookmark-fill.svg';
import { SAVED_PLACE_CUSTOM_TYPE } from '../../../utils/redux/actions/savedPlacesActions';
const classNames = require("classnames");

export default function LocationBookmarked(props) {
	const isBookmarkedPlace  = props.places.filter((place) => place.isBookmarked);
    return (
		<Fragment>
		{isBookmarkedPlace.length ?
			<div className={classNames(props.classList, "location-search-suggestions bookmarked-places")}>
				{/* <p className="location-search-suggestion-title">Bookmark Places</p> */}
				{props.places.map((place, index) => {
					return (
						place &&
						place.isBookmarked && (
							<div key={index} index={index} className="location-search-suggestions-item">
								<p
									className="location-search-suggestions-title"
									onClick={(e) => props.onSearchClick(e, place, SAVED_PLACE_CUSTOM_TYPE, place.title)}
								>
									{place.title}
								</p>

								<div className="bookmark-icon" onClick={(e) => props.removeBookmarkPlace(e, place, place.title)}>
									<img src={SaveIcon} className="" alt="Bookmark icon" />
								</div>
							</div>
						)
					);
				})}
			</div>
		 : null}
		</Fragment>
	);
}
