import React, { Fragment } from 'react';

import { ReactComponent as LocationPin } from "../../../v5-icons/pin-location-small.svg";
export default function JobLocations({ locations}) {
    return (
		<Fragment>
			<div className="Job-locations">
				{locations
					? locations.map((location, idx) => (
							<div key={idx} className="review-booking-item-inner">
								<LocationPin />
								<p>{location.title || location.address}</p>
							</div>
					  ))
					: null}
			</div>
		</Fragment>
	);
}