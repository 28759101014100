import React, { useEffect, useState } from "react";
import _ from "lodash";
import SingleLineSkeleton from "../../ui-elements/Skeleton/SingleLineSkeleton";
import { getMymallPriceFromFirebase, remoteConfig } from "../../../utils/functions/firebase-initialize";

const classNames = require("classnames");

export default function ServiceFee({
        time,
        price,
        handleClickEnterPromoCode,
        newPrice,
        amountOff,
        percentOff,
        promoCode,
        handleLinkRemovePromoCode,
        currency,
    }) {

	const [mymallPriceModifier, setMymallPriceModifier] = useState(0);

	useEffect(() => {
		getMymallPriceFromFirebase(remoteConfig).then((res) => {
			setMymallPriceModifier(res || 0);
		});

		return () => {
			setMymallPriceModifier(undefined);
		};
	}, [mymallPriceModifier !== undefined])

	return (
		<div className="service-fee-wrapper">
			<div className="title-price-row">
				<div className="service-fee-text">
					<h4>One Honest Fee</h4>
					<div className="service-fee-price">
						{(!price?.items?.[price?.minCubeNumbers]?.price.value || _.isNull(mymallPriceModifier)) ? (
							<SingleLineSkeleton />
						) : (<>
								<span
									className={classNames({
										"line-through": !_.isNull(newPrice) || mymallPriceModifier,
									})}
								>
									{`${currency || "₱"}${price?.items?.[price?.minCubeNumbers]?.price.value + mymallPriceModifier}`}
								</span>
								{(!_.isNull(newPrice) || !!mymallPriceModifier) && <p>
									{`${currency || "₱"}${(newPrice || newPrice === 0) ? newPrice : price?.items?.[price?.minCubeNumbers]?.price.value}`}
								</p>}
							</>)}
					</div>
				</div>
				<div className="discount-text">
					{!_.isNull(newPrice) ? (
						<>
							<span>{`${percentOff ? `%${percentOff}` : `₱${amountOff}`} more off Using`}</span> <h6>{promoCode}</h6> <span>code</span>
						</>
					) : (
						<div className="discount-description">
							<p>
								{_.isNull(time) ? <SingleLineSkeleton /> : time}
								of Shopping + Delivery
							</p>
							<p>Free Cancellation until the job starts</p>
						</div>
					)}
				</div>
			</div>
			<div
				className={classNames({
					"remove-discount-code": true,
					"remove-color": !_.isNull(newPrice),
					"enter-color": _.isNull(newPrice),
				})}
				onClick={!_.isNull(newPrice) ? handleLinkRemovePromoCode : handleClickEnterPromoCode}
			>
				{`${!_.isNull(newPrice) ? "Remove Promo Code" : "Use a Promo Code"}`}
			</div>
		</div>
	);
}
