import React from "react";
import ContentLoader from "react-content-loader";

const CallingSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={412}
		height={312}
		viewBox="0 0 412 312"
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<rect x="106" y="22" rx="5" ry="5" width="200" height="10" />
		<circle cx="200" cy="111" r="40" />
	</ContentLoader>
);

export default CallingSkeleton;
