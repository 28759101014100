import React from 'react';

import './form-input.css';
// import { IoIosMedical } from 'react-icons/io';

const classNames = require('classnames');

export default function FormInput(props) {
    return (
		<React.Fragment>
			<div className={classNames("form-group-container", { "input-error": props.error })}>
				<label>
					{props.label} {props.optional ? <span className="optional">(Optional)</span> : ""}
				</label>
				<input
					{...props}
					className={classNames({
						"form-control Form-input": true,
					})}
					ref={props.forminputref && props.forminputref}
				/>
			</div>
			{props.error && <div className="error">{props.error}</div>}
		</React.Fragment>
	);
}