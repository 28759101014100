import React, {Fragment} from 'react';

import { ReactComponent as GrayForwardImg} from '../../../v5-icons/gray-forward.svg';

export default function AccountLink(props) {
    return (
        <Fragment>
            <div 
                onClick={(e) => props.onNavigate(e, props.data)} 
                className="account-links-box">
                    <div className="icon-and-title">
                        <img src={props.data.icon} />
                        <div className="account-links-box-title">
                            {props.data.title}
                        </div>
                    </div>
                    <div className="account-links-box-icon">
                        <GrayForwardImg />
                    </div>
            </div>
        </Fragment>
    );
}