import React from "react";
import * as LOG_EVENT from "../../utils/analytics";
import { appLogout } from "../../utils/redux/actions/commonActions";
import { toggleLogOutBottomSheet } from "../../utils/redux/actions/commonActions/commonChangeState";

export default function LogOutBottomSheet(props) {

	const onLogout = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOGOUT_CWA);
		props.dispatch(appLogout());
	}

	const onDismiss = () => {
		props.dispatch(toggleLogOutBottomSheet());
	}

	return (
		<div className="logout-btmsheet-wrapper">
			<h5 className="logout-btmsheet-title">
				Log Out
			</h5>
			<span className="logout-btmsheet-description">
				Are you sure you want to logout?
			</span>
			<div className="confirm-buttons-wrapper">
				<button onClick={() => onDismiss()} className="dismiss-btn">Dismiss</button>
				<button onClick={() => onLogout()} className="confirm-logout-btn">Log Out</button>
			</div>
		</div>
	);
}
