import Resizer from 'react-image-file-resizer';
import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import { chatScrollToBottom, putNewMessage, changeStatusOfMessageNewService } from './chat-messages';
import { GraphRequest } from '../../../../../axios';
import { sendTextMessageRequestBody } from "./chat-gql";
import { appFetchFailure } from '../../commonActions'

/**
 * Send message to other side from me / new service
 * @param {*} message 
 * @param {*} file 
 */
export const sendMessageWithNewService = (message, file = null) => {
    return (dispatch, getState) => {
        var formdata = new FormData();
        let chatRequestBody;
        const requestQuery = {
            "query": `mutation CreateChatMessage($channelId: Int!, $file: Upload!) {
                chatSendFile(channelId: $channelId, file: $file) {
                    _id
                    index
                    media
                    type
                    consumedBy
                    authorId
                    authorType
                    createdAt
                }
            }`,
            "variables": {
                "channelId": getState().jobs.job.id,
                "file": null
            }
        }
        if (!_.isNull(file)) {
                formdata.append("operations", JSON.stringify(requestQuery));
                formdata.append("map", "{ \"0\": [\"variables.file\"] }");
                formdata.append("0", file.blob ? file.blob : file, file.name);
        } else {
            chatRequestBody = {
                query: sendTextMessageRequestBody,
                variables: {
                    "channelId": getState().jobs.job.id,
                    "text": message
                }
            }
        }
        dispatch(putNewMessage(message, file)).then(() => dispatch(chatScrollToBottom()))
            .then(() => {
                dispatch(DISPATCH_STATE.sendMessageBegin());
                GraphRequest.all(_.isNull(file) ? chatRequestBody : formdata)
                    .then((res) => {
                        if (!_.isNull(res.data.data) && res.data.data.chatSendText) {
                            dispatch(changeStatusOfMessageNewService(res.data.data.chatSendText.index));
                        } else if (!_.isNull(res.data.data) && res.data.data.chatSendFile) {
                            dispatch(changeStatusOfMessageNewService(res.data.data.chatSendFile.index));
                        } 
                        else {
                            dispatch(appFetchFailure(res.data.errors[0].message));
                        }
                    }).catch((error) => dispatch(appFetchFailure(error)));
            })
    }
}
/**
 * When user attach file / new service
 * @param {*} file 
 */
export const chatOnAttachFileWithNewService = (file) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.chatOnAttachFileNewServiceDispatch());
            //Reduce file size
            let convertedFile;
            Resizer.imageFileResizer(
                file,
                700,
                700,
                'JPEG',
                70,
                0,
                blob => {
                  convertedFile = new File([blob], new Date().toISOString(), {type: 'image/jpg'});
                  let reader = new FileReader();
                    reader.readAsDataURL(convertedFile);
                    reader.onload = (e) => {
                        convertedFile['reader'] = reader;                        
                        dispatch(sendMessageWithNewService('', convertedFile))
                    }
                },
                'blob'
              );   
    }
}
/**
 * when user stop recording his|her voice / new service
 * @param {*} file 
 */
export const chatOnRecordStopWithNewService = (file) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.chatOnAttachFileNewServiceDispatch());
        dispatch(sendMessageWithNewService('', file));
    }
}