import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { push } from "connected-react-router";

import { navigateToAccountBilling, initAdyenV2, availableMethods} from '../../../../utils/redux/actions/billingActions';
import { billingUnSetErrorPayment } from "../../../../utils/redux/actions/billingActions/billingChangeState";
import BillingErrorModal from '../BillingErrorModal/BillingErrorModal';
import TitleBar from "../../../../organisms/title-bar";
import backButton from "../../../../v5-icons/back-button.svg";
class BillingAddCard extends Component {
	state = {
		card: null,
	};

	componentDidMount() {
		this.props.dispatch(availableMethods()).then((res) => { 
			let card = this.props.dispatch(initAdyenV2(res));
			this.setState({ card });
		});
	}

	onAddCard = (e) => {
		this.state.card.submit();
	};

	render() {
		return (
			<Fragment>
				<div className="billing-add-card">
					<TitleBar
						title="New Bank Card"
						leftIcon={backButton}
						handleClickLeftIcon={(e) => {
							if (!_.isNull(this.props.common.pathName)) {
								this.props.dispatch(push(this.props.common.pathName));
							} else {
								this.props.dispatch(navigateToAccountBilling());
							}
						}}
					/>
					<div className="add-new-card">
						<p className="tip">💡 Only Visa or Mastercard prepaid, debit, and credit bank cards can be accepted at the moment</p>
						<h6 className="title">Information On Card</h6>
						<div className="add-new-card-container" id="component-container"></div>
						<button onClick={this.onAddCard} className="adyen-checkout__button adyen-checkout__button--pay" type="button">
							<span className="adyen-checkout__button__content">
								{/* <img
									className="adyen-checkout__button__icon"
									src="https://checkoutshopper-test.adyen.com/checkoutshopper/images/components/lock.svg"
									alt="Icon"
									aria-hidden="true"
									role="presentation"
								/> */}
								<span className="adyen-checkout__button__text">Save bank card</span>
							</span>
						</button>
					</div>
				</div>
				{this.props.errorPayment ? (
					<BillingErrorModal
						edit={(e) => {
							this.props.dispatch(billingUnSetErrorPayment());
						}}
						another={(e) => {
							this.props.dispatch(billingUnSetErrorPayment());
							this.props.dispatch(navigateToAccountBilling());
						}}
					/>
				) : null}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    ...state.billing,
	common: state.common
});

export default connect(mapStateToProps)(BillingAddCard);