import _ from "lodash";

export default function createRatePartnerObject(form, rate, complimentsArray) {
	let rateObj = {
		rate,
		compliments: createCompliments(form.compliments, complimentsArray, rate),
		comment: form.comment,
	};
	return rateObj;
}

export const createJobRateObject = (comment, rate, complimentsArray) => {
	let rateObj = {
		rate,
		compliments: createRawCompliments(complimentsArray),
		comment: comment,
	};
	return rateObj;
}

function createRawCompliments(complimentsArray) {
	let clonned = [...complimentsArray];
	let allCompliments = [...clonned[0].compliments, ...clonned[1].compliments];
	let selectedCompliments = allCompliments.filter((c) => {
		if(c.selected) {
			delete c.selected;
			return c
		}
	});
	
	return selectedCompliments;

}

function createCompliments(values, complimentsArray, rate) {
	// console.log(complimentsArray)
	// let oposite = complimentsArray.filter((v, i) => {
	// 	if (rate !== v.rate) return v;
	// });
	// let correctCompliments = complimentsArray.filter((v, i) => { if (rate === v.rate) return v });
	// oposite = _.map(oposite[0].compliments, "id");
	// let compliments = [];
	// values.map((value, index) => {
	// 	if (value) {
	// 		const selectedCompliments = correctCompliments[0].compliments.filter((v, i) => { if(index === v.id) return v });
	// 		if (!oposite.includes(index)) {
	// 			compliments.push({ 
	// 				id: selectedCompliments[0].id,
	// 				name: selectedCompliments[0].name
	// 			});
	// 		}
	// 	}
	// 	return value;
	// });
	// return compliments;
}