import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import Avatar from "../../../avatar/avatar";
import genderPatner from "../../../../utils/functions/gender-partner";
import JobInformationThumb from "../JobInformationPartner/JobInformationThumb/JobInformationThumb";
import JobInformationRate from "../JobInformationPartner/JobInformationRate/JobInformationRate";
import check from "../../../../v5-icons/check-green.svg";
import close from "../../../../v5-icons/close-red.svg";
class JobPartnerRating extends Component{
	render(){
		return !_.isEmpty(this.props.job) && _.has(this.props.job, "clientRate") ? (
			<div className="Job_Information_Partner">
				<div className="Section_1">
					<Avatar img={this.props.job.partner.photo} />
					<div className="Job_Information_Section1_Right_Box">
						<h5 className="Partner_Name">
							{this.props.job.partner.title ? this.props.job.partner.title : genderPatner(this.props.job.partner)}{" "}
							{this.props.job.partner.givenName} {this.props.job.partner.lastName}
						</h5>
						<p className="Co_Partner_Name"> {this.props.job.partner.coPartner.displayValue || this.props.job.partner.coPartner.name}</p>
					</div>
				</div>

				{!_.isNull(this.props.job.clientRate.rate) ? (
					<div className="Job_Information_Thumb">
						<JobInformationThumb rate={this.props.job.clientRate.rate} />
					</div>
				) : null}
				{!_.isNull(this.props.job.clientRate.compliments) && !_.isNull(this.props.job.clientRate.rate)
					? this.props.sortedClientRate.map((jobRate, index) => (
							<div className="Job_Information_Rate" key={jobRate.id}>
								<JobInformationRate icon={jobRate.feedbackType === "POSITIVE" ? check : close} title={jobRate.name} />
							</div>
					  ))
					: null}
				{_.has(this.props.job.clientRate, "comment") ? <p className="job-comment">{this.props.job.clientRate.comment}</p> : null}
			</div>
		) : null;
	}
}
const mapStateToProps = state => ({
	...state.jobs,
});

export default connect(mapStateToProps)(JobPartnerRating);