import React from "react";
import { useTabState } from "@bumaga/tabs";

const TabItem = (props) => {
	const { onClick } = useTabState();
	return <div onClick={onClick}>
		<div className={`${props.children.active? props.children.activeClass : ''}`} onClick={props.clicked}>
			<h5>
				{props.children.title}
			</h5>
		</div>

	</div>;

};
export default TabItem;