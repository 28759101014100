import * as CONSTANT_ACTIONS from './newsConstant';

export const fetchNewsBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_NEWS_BEGIN
});

export const fetchNewsSuccess = news => ({
    type: CONSTANT_ACTIONS.FETCH_NEWS_SUCCESS,
    payload: news
});

export const willUnmountNews = () => ({
    type: CONSTANT_ACTIONS.WILL_UNMOUNT_NEWS
})

export const willUnmountArticle = () => ({
    type: CONSTANT_ACTIONS.WILL_UNMOUNT_ARTICLE
})

export const selectArticleFromNews = (article) => ({
    type: CONSTANT_ACTIONS.SELECT_ARTICLE_FROM_NEWS,
    payload: article
})

export const fetchNewsArticleBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_NEWS_ARTICLE_BEGIN
});

export const fetchNewsArticleSuccess = article => ({
    type: CONSTANT_ACTIONS.FETCH_NEWS_ARTICLE_SUCCESS,
    payload: article
});

export const newsSetSearchLocationPointer = (pathname) => ({
    type: CONSTANT_ACTIONS.NEWS_SET_SEARCH_LOCATION_POINTER,
    payload: pathname,
});

export const newsLoading = (condition) => ({
    type: CONSTANT_ACTIONS.NEWS_LOADING,
    payload: condition
});

export const detailNewsLoading = (condition) => ({
    type: CONSTANT_ACTIONS.DETAIL_NEWS_LOADING,
    payload: condition
})