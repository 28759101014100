import React, {Fragment} from 'react';

import CustomButton from '../../../../ui-elements/CustomButton/CustomButton';
export default function BillingInformationAdd(props) {

    return (
		<Fragment>
			<div className="billing-information-add">
				<CustomButton type="button" title="Add a new bank card" onClick={props.onAddMethod} />
			</div>
		</Fragment>
	);
} 