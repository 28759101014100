import React from 'react';
import { connect } from 'react-redux';

import CancelBookingImage from "../../../v5-icons/cancel-booking.png";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import { ReactComponent as ChatSupport } from "../../../v5-icons/chat-support.svg";
class RejectedPaymentMethod extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isShow: false,
			timeOut: null,
		};
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="rejected-payment-container">
				<img src={CancelBookingImage} alt="CancelBookingImage" />
				<div className="rejected-payment-header">
					<h2>Previous payment failed</h2>
					<h6>Retry payment or change payment method to proceed</h6>
				</div>
				<div className="rejected-payment-bottom">
					<CustomButton classList="top-up-button" title="Retry Payment" onClick={this.props.showTopUpBottomSheet} />
					<CustomButton
						outline={true}
						variant="outlined"
						classList="change-payment-button"
						title="Change Payment Method"
						onClick={this.props.showPaymentMethodsBottomSheet}
					/>
					<div className="chat-with-support" onClick={this.props.navigateToSupport}>
						<ChatSupport />
						<span>Get help</span>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => ({
    common: state.common,
});

export default connect(mapStateToProps)(RejectedPaymentMethod);
