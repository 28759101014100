import React, { Fragment } from "react";

import Moment from "../../../../../moment/moment";
import { JOB_STATE_COMPLETED } from "../../../../../../utils/redux/actions/jobsActions";
import moment from 'moment';

export default function JobsBoxHeadingDatePrice(props) {

	return (
		<Fragment>
			<div className="Jobs-box-heading-date">
				{props.state === "Matching" ? <p>Looking around...</p> : null}
				{props.state === "Scheduled" || props.state === "Queued" || props.state === "Upcoming" ? (
					<>
						<span>{moment(props.dueDate).format("ddd")}</span>
						<span>
							<Moment format="DD MMM , HH:mm">{props.dueDate}</Moment>
						</span>
					</>
				) : null}
				{props.state === JOB_STATE_COMPLETED || props.state === "Matched" || props.state === "Started" ? (
					<>
						<span>{moment(props.date).format("ddd")}</span>
						<span>
							<Moment format="DD MMM , HH:mm">{props.date}</Moment>
						</span>
					</>
				) : null}
				{/* {props.state === JOB_STATE_COMPLETED ? (
					<span className="job-price">
						<div className="dot-divider"></div>
						{props.jobPrice.currency} {props.jobPrice.value}
					</span>
				) : null} */}
			</div>
		</Fragment>
	);
}