import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import * as moment from 'moment-timezone';

import CustomButton from "../../../ui-elements/CustomButton/CustomButton";

import { toggleTransferMkNote, confirmSendMk, refuseSendMk } from "../../../../utils/redux/actions/chatActions/chatChangeState";

class SendingRecievingMkItem extends Component {
    setStatus(state) {
        switch (state) {
            case 'TRANSFER_MYKOIN_ACCEPTED':
                return "ACCEPTED";
            case "TRANSFER_MYKOIN_TRANSFERRED":
                return "TRANSFERRED";
            case 'TRANSFER_MYKOIN_REJECTED':
                return "REJECTED";
            case "TRANSFER_MYKOIN_PENDING":
                return "PENDING";
            default: 
                break;
        }
    }

	render() {
		return (
			<>
                {!_.isEmpty(this.props.client) ? <div className="mk-item-container">
                    <div className="mk-item-row">
                        <p>Amount</p>
                        <p>{this.props.client.currency.signText}{this.props.data.amount}</p>
                    </div>
                    <div className="mk-item-row">
                        {this.props.data.from === "TRANSFER_ACTOR_CLIENT" && this.props.data.createdBy === "TRANSFER_ACTOR_CLIENT" 
                        || this.props.data.from === "TRANSFER_ACTOR_PARTNER" && this.props.data.createdBy === "TRANSFER_ACTOR_PARTNER" ? <p>Sent from</p> : <p>Requested by</p>}
                        
                        <div className="mk-item-row-inner">
                            <p>{this.props.data.createdBy === "TRANSFER_ACTOR_CLIENT" ? "Customer" : "Partner"}</p>
                            <CustomButton title="View Note" classList="view-note" onClick={() => this.props.dispatch(toggleTransferMkNote(this.props.data.note, this.props.data.amount))} />
                        </div>
                    </div>
                    <div className="mk-item-row">
                        <p>Date & Time</p>
                        <p>{moment(this.props.data.createdAt).format("DD MMM , h:mmA")}</p>
                    </div>
                    <div className="mk-item-row">
                        <p>Status</p>
                        <p className={`${this.setStatus(this.props.data.state).toLocaleLowerCase()} state`}>{this.setStatus(this.props.data.state)}</p>
                    </div>
                    {this.props.data.state === "TRANSFER_MYKOIN_PENDING" && this.props.data.from === "TRANSFER_ACTOR_CLIENT" ? 
                        (<div className="mk-item-row call-to-action">
                            <CustomButton 
                                onClick={() => {
                                    this.props.dispatch(refuseSendMk(this.props.data.amount, this.props.data.id));
                                }}
                                classList="reject-send-mk" 
                                type="button" 
                                title="Reject" outline variant="outlined" />
                            <CustomButton 
                                classList="accept-send-mk" 
                                onClick={() => {
                                    this.props.dispatch(confirmSendMk(this.props.data.amount, this.props.data.id));
                                }}
                                type="button" 
                                title="Accept" />
                            </div>) : null}
                </div> : null}
            </>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.chat,
    client: state.common.client,
    job: state.jobs.job
});

export default connect(mapStateToProps)(SendingRecievingMkItem);
