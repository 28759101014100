import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { parse } from "query-string";

import {
	backToChatHistory,
	toggleVoiceOnVideoChat,
	disconnectRoom,
	addPartnerInfo,
	partnerNotJoined,
	setTokenTypeForVoip,
	endCall
} from "../../utils/redux/actions/chatActions";
import { connectToRoom, acceptCall } from "../../utils/redux/actions/chatActions/actions/chat-menu";
import { removeQueryParam } from "../../utils/redux/actions/commonActions";

import Timer from "./Timer";
import CallingSkeleton from "../ui-elements/Skeleton/CallingSkeleton";

import LeftCallIcon from "../../v5-icons/call-end.png";
import MuteIcon from "../../v5-icons/call-mic-enable.png";
import UnMuteIcon from "../../v5-icons/call-mic-disable.png";

import * as LOG_EVENT from "../../utils/analytics/index";

const classNames = require("classnames");
class VideoChat extends Component {
	state = {};
	componentDidMount() {
		this.props.dispatch(partnerNotJoined());
		const params = parse(window.location.search);
		if(_.has(params, "token")) {
			const tokenPaylod = {
				token: params["token"],
				expiresAt: params["expiresAt"]
			}
			const tokenType = params["type"];
			const refId = params["refId"];
			const authorObject = {
				authorType: params["authorType"],
				authorName: params["authorName"],
				authorPhoto: params["authorPhoto"]
			}
			if (_.has(params, "fromNotif")) {
				LOG_EVENT.logEvent(LOG_EVENT.LAUNCH_FROM_NOTIFICATION_CWA);
				this.props.dispatch(removeQueryParam("fromNotif", this.props.history));
			}
			if (authorObject.authorType !== "CLIENT") this.props.dispatch(addPartnerInfo(authorObject));
			this.props.dispatch(connectToRoom(tokenPaylod, tokenType));
			this.props.dispatch(setTokenTypeForVoip(tokenType));
			this.props.dispatch(acceptCall(tokenType, refId, this.props.match.params.id))
		} else if (!this.props.chat.goToVideoChat) {
			this.props.dispatch(backToChatHistory(this.props.match.params.id));
			this.props.dispatch(endCall(this.props.chat.activeRoom.name));
		}
		window.addEventListener("beforeunload", () => {
			if (!_.isNull(this.props.chat.activeRoom)) this.props.chat.activeRoom.disconnect();
		});
	}
	
	componentWillUnmount() {
		if (!_.isNull(this.props.chat.activeRoom)) this.props.dispatch(disconnectRoom(this.props.chat.activeRoom));
		this.props.dispatch(partnerNotJoined());
	}
    
    backToChatHistory = () => {
        this.props.dispatch(backToChatHistory(this.props.match.params.id));
    }

	render() {
		return (
			<Fragment>
				{this.props.chat.hasClientJoinedRoom ? (
					<div className={classNames("video-chat-container", { "partner-not-joined": !this.props.chat.partnerJoined })}>
						{!this.props.chat.partnerJoined && this.props.chat.tokenType === "VOICE_CHAT_TOKEN" ? (
							<div className="partner-not-joined-overlay"></div>
						) : null}
						{!_.isNull(this.props.chat.tokenType) && this.props.chat.tokenType ? (
							<div className="partner-info">
								{!_.isEmpty(this.props.chat.partnerInfo) ? (
									<>
										{!this.props.chat.partnerJoined ? (
											<>
												<p>{this.props.chat.partnerInfo.authorName}</p>
												<span>Ringing...</span>
											</>
										) : this.props.chat.tokenType !== "VIDEO_CHAT_TOKEN" ? (
											<>
												<p>{this.props.chat.partnerInfo.authorName}</p>
												<Timer />
											</>
										) : null}
									</>
								) : (
									<>
										{!this.props.chat.partnerJoined ? (
											<>
												<p>
													{this.props.jobs.job.partner.givenName} {this.props.jobs.job.partner.lastName}
												</p>
												<span>Ringing...</span>
											</>
										) : this.props.chat.tokenType !== "VIDEO_CHAT_TOKEN" ? (
											<>
												<p>
													{this.props.jobs.job.partner.givenName} {this.props.jobs.job.partner.lastName}
												</p>
												<Timer />
											</>
										) : null}
									</>
								)}
							</div>
						) : null}
						<div className="card-body">
							<div
								className={classNames("client-video", { "partner-joined": this.props.chat.partnerJoined })}
								id="local-media-container-id"
							></div>
						</div>
						<div className="card-body">
							<div id="remote-media-div" className="partner-video"></div>
							<div className="text-center"></div>
						</div>

						{!_.isNull(this.props.chat.tokenType) && this.props.chat.tokenType === "VOICE_CHAT_TOKEN" ? (
							<div className="image-partner">
								{
									<img
										alt="kuya"
										src={
											!_.isEmpty(this.props.chat.partnerInfo)
												? this.props.chat.partnerInfo.authorPhoto
												: this.props.jobs.job.partner.photo
										}
									/>
								}
							</div>
						) : null}

						<div className="card-bottom">
							<div className="left-call icon-container">
								<img
									alt="left call"
									src={LeftCallIcon}
									className="left-call-icon"
									onClick={() => {
										this.props.dispatch(backToChatHistory(this.props.match.params.id));
										this.props.dispatch(endCall(this.props.chat.activeRoom.name));
									}}
								/>
							</div>
							<div className="mute-call icon-container">
								{this.props.chat.toggleVoice ? (
									<img
										alt="mute"
										src={MuteIcon}
										className="mute-call-icon"
										onClick={() => this.props.dispatch(toggleVoiceOnVideoChat())}
									/>
								) : (
									<img
										alt="unmute"
										src={UnMuteIcon}
										className="unmute-call-icon"
										onClick={() => this.props.dispatch(toggleVoiceOnVideoChat())}
									/>
								)}
							</div>
						</div>
					</div>
				) : (
					<div className="calling-skeleton">
						<CallingSkeleton />
					</div>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
    chat: state.chat,
    jobs: state.jobs
});

export default connect(mapStateToProps)(VideoChat);
