import _ from 'lodash';
import { toast } from "react-toastify";	

import { networkCall } from '../../../../../axios';

import * as DISPATCH_STATE from '../chatChangeState';
import * as CONSTANT_ACTIONS from '../chatConstant';

import { chatScrollToBottom, initMessages, paginateMessageList } from './chat-messages';
import { appFetchFailure } from '../../commonActions';
import { MESSAGE_STATUS_RECEIVED } from '../../../../functions/message-object-creator';
import { JOB_STATE_COMPLETED } from '../../jobsActions/jobsConstant';
import { getMessagesWithOffsetRequestBody, getMessageWithLastIndexRequestBody } from "./chat-gql";
/**
 * Get all messages for the first time
 */
export const getAllMessages = (channelId) => {
    return (dispatch, getState) => {
        const offset = 0;
        const limit = 15; 
        const chatRequestBody = {
            query: getMessagesWithOffsetRequestBody,
            variables: {
                "channelId": Number(channelId),
                "offset": offset,
                "limit": limit
            }
        }
        return new Promise((resolve, reject) => {
            if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
                networkCall(chatRequestBody)
                    .then((res) => {
                        if(res.data && res.data.chatMessages) {
                            dispatch(setParams(res.data.chatMessages.totalItems, res.data.chatMessages.lastConsumeIndex));
                            dispatch(fetchMessagesWithOffset(channelId));
                            if(res.statusCode && res.statusCode === 401) {  
                                toast("unauthorized", {
                                    position: "bottom-center",
                                    autoClose: 2000,
                                    limit: 1,
                                    className: "toast-rejected-payment",
                                    bodyClassName: "toastify-inner",
                                    hideProgressBar: true,
                                    closeOnClick: false,
                                });
                            }
                        }
                    })
            } else {
                const chatRequestBody = {
                    query: getMessagesWithOffsetRequestBody,
                    variables: {
                        "channelId": Number(channelId)
                    }
                }
                networkCall(chatRequestBody)
                    .then((res) => {
                        if (!_.isNull(res.data) && res.data.chatMessages) {
                            dispatch(initMessages(res.data.chatMessages));
                        } else {
                            dispatch(appFetchFailure(res.errors[0].message));
                            toast(`${res.errors[0].message}`, {
                                position: "bottom-center",
                                autoClose: 2000,
                                limit: 1,
                                className: "toast-rejected-payment",
                                bodyClassName: "toastify-inner",
                                hideProgressBar: true,
                                closeOnClick: false,
                            });
                        }
                    })
                    .then(() => dispatch(chatScrollToBottom()))
            }
        })
    }
}

/**
 * Fetch and store messages in the newMessages
 */
export const fetchMessagesWithOffset = (channelId) => {
    return (dispatch, getState) => {
        const offset = getState().chat.offset;
        const limit = getState().chat.limit;
        const chatRequestBody = {
            query: getMessagesWithOffsetRequestBody,
            variables: {
                "channelId": Number(channelId),
                "offset": offset,
                "limit": limit
            }
        }
        networkCall(chatRequestBody)
            .then((res) => {
                if (!_.isNull(res.data) && res.data.chatMessages) {
                    dispatch(initMessages(res.data.chatMessages));
                    dispatch(setIntervalToCheckLastMessageIndex(res.data.chatMessages.requestId));
                } else {
                    dispatch(appFetchFailure(res.data.errors[0].message));
                    toast(`${res.data.errors[0].message}`, {
                        position: "bottom-center",
                        autoClose: 2000,
                        limit: 1,
                        className: "toast-rejected-payment",
                        bodyClassName: "toastify-inner",
                        hideProgressBar: true,
                        closeOnClick: false,
                    });
                }
            }).then(() => dispatch(chatScrollToBottom()))
    }
}
/**
 * Store offset, limit, lastIndex 
 */
export const setParams = (totalItems, lastConsumeIndex) => {
    return (dispatch, getState) => {
        let offset;
        let limit;
        const lastIndex = totalItems - 1;
        if (totalItems < 15) {
            offset = 0;
            limit = totalItems
        } else {
            offset = totalItems - 15;
            limit = 15;
        }
        dispatch(DISPATCH_STATE.setOffset(offset));
        dispatch(DISPATCH_STATE.setLimit(limit));
        dispatch(DISPATCH_STATE.setLastIndex(lastIndex));
        dispatch(DISPATCH_STATE.setLastConsumeIndex(lastConsumeIndex));
    }
}
/**
 * Check lastIndex with storedLastIndex to find new message every 5sec
 */
export const setIntervalToCheckLastMessageIndex = (channelId) => {
    return (dispatch, getState) => {
        if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
            //add  first request
            let requestSent;
            const storedLastIndex = getState().chat.chatLastIndex;
            const chatRequestBody = {
                query: getMessageWithLastIndexRequestBody,
                variables: {
                    "channelId": Number(channelId),
                    "lastIndex": storedLastIndex
                }
            }
            requestSent = networkCall(chatRequestBody) 
            let requestStatus = MakeQuerablePromise(requestSent);

            //add interval for get new messages
            dispatch(DISPATCH_STATE.initIntervalGetMessage(setInterval(() => {
                const storedLastIndex = getState().chat.chatLastIndex;
                const storedLastConsumeIndex = getState().chat.lastConsumeIndex;
                const chatRequestBody = {
                    query: getMessageWithLastIndexRequestBody,
                    variables: {
                        "channelId": Number(channelId),
                        "lastIndex": storedLastIndex
                    }
                }
                if (!requestStatus.isPending()) {
                    requestSent = networkCall(chatRequestBody) 
                    .then(res => {
                        if (!_.isNull(res.data) && res.data.chatMessages) {
                            const { totalItems, lastConsumeIndex, requestId } = res.data.chatMessages;
                            const lastIndex = totalItems - 1;
                            getState().chat.newMessages[0].items.map((item) => {
                                if((item.type === "voice_chat_token" || item.type === "video_chat_token") && item.state === "PENDING") {
                                    dispatch(fetchAllMessages(requestId));
                                }
                            })

                            ///////////// check the transfer mykoin state /////////////////
                            const arrayIndex = res.data.chatMessages.items.length - 2;
                            if (res.data.chatMessages.items[arrayIndex].state === "ACCEPTED" &&
                                res.data.chatMessages.items[arrayIndex].type === "INTERACTIVE_REQUEST_MYKOIN") {
                                const updatedMessages = [...getState().chat.newMessages[0].items]
                                updatedMessages[getState().chat.newMessages[0].items.length - 2] = {
                                    ...updatedMessages[getState().chat.newMessages[0].items.length - 2],
                                    state: "ACCEPTED"
                                }
                                dispatch(initMessages({ ...res.data.chatMessages, items:  [...updatedMessages]}));
                            }
                            //////////////////////////////

                            if (lastIndex !== storedLastIndex) {
                                dispatch(DISPATCH_STATE.setLastIndex(lastIndex));
                                dispatch(getMessagesWithLastIndex(lastIndex, res.data.chatMessages));
                            } else if (storedLastConsumeIndex !== lastConsumeIndex) {
                                dispatch(DISPATCH_STATE.setLastConsumeIndex(lastConsumeIndex));
                                dispatch(changeStatusOfMessageToReceived(lastConsumeIndex));
                            }}
                        }).catch((err) => dispatch(appFetchFailure(err)));
                    requestStatus = MakeQuerablePromise(requestSent);
                } else {
                    console.log('has pending request', requestSent);
                }                
                
            }, CONSTANT_ACTIONS.TIME_FOR_INTERVAL_REQUEST_FOR_NEW_MESSAGE)));
        }
    }
}
/**
 * wrapper for findout request isResolved, isPending or isFulfilled
 */
export const MakeQuerablePromise = (promise) => {
    // Don't modify any promise that has been already modified.
    if (promise.isResolved) return promise;

    // Set initial state
    var isPending = true;
    var isRejected = false;
    var isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    var result = promise.then(
        function (v) {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        function (e) {
            isRejected = true;
            isPending = false;
            throw e;
        }
    );

    result.isFulfilled = function () { return isFulfilled; };
    result.isPending = function () { return isPending; };
    result.isRejected = function () { return isRejected; };
    return result;
}

/**
 * Change message status
 */
export const changeStatusOfMessageToReceived = (index) => {
    return (dispatch, getState) => {
        if (typeof (getState().chat.newMessages[0]) !== 'undefined') {
            let messages = getState().chat.newMessages[ 0 ].items;
            messages = messages.map((message, i) => {
                message.status = MESSAGE_STATUS_RECEIVED;
                return message;
            });
            const messageList = {
                "0": {
                    "items": messages,
                    "totalItems": messages.length
                }
            }
            dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
        }
    }
}
/**
 * Change message status
 */
export const changeStatusOfMessageToCancelled = (index) => {
    return (dispatch, getState) => {
        if (typeof (getState().chat.newMessages[0]) !== 'undefined') {
            let messages = getState().chat.newMessages[ 0 ].items;
            let thatMessageIndex = messages.findIndex((message) => message.referenceId === messages[messages.length - 1].referenceId);
            messages[thatMessageIndex].state = "CANCELLED"
            const messageList = {
                "0": {
                    "items": messages,
                    "totalItems": messages.length
                }
            }
            dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
        }
    }
}
/**
 * Get Messages from server by lastIndex | Update message list
 */
export const getMessagesWithLastIndex = (lastIndex,messageList) => {
    return (dispatch, getState) => {
        messageList.items.shift(); 
        let newMessageList = [];
        const currentMessages = [...getState().chat.newMessages[0].items];
        currentMessages.pop();
        messageList.items.map((message) => {
            if(message.authorType !== 'CLIENT') {
                const duplicateMessage = getState().chat.newMessages[0].items.filter((item) => (item.index === message.index) && (item.authorType === message.authorType));
                if(duplicateMessage.length === 0) {
                    newMessageList.push(message);
                }
            }
        });
        const messageList2 = {
            "items": newMessageList,
            "totalItems": newMessageList.length
        }
        if (newMessageList.length) {
            dispatch(paginateMessageList(messageList2));
            if (typeof (getState().chat.newMessages[0]) !== 'undefined' && typeof (getState().chat.updatedMessages[0]) !== 'undefined') {
                let data = [{
                    items: [...getState().chat.newMessages[0].items, ...getState().chat.updatedMessages[0].items],
                    totalItems: getState().chat.newMessages[0].totalItems
                }];
                dispatch(DISPATCH_STATE.fetchMessagesSuccess(data));
            }
        } 
        dispatch(chatScrollToBottom());
    }
}
/**
 * Get Messages from server by offset
 */
export const fetchMessagesByOffset = (lastScrollHeight) => {
    return (dispatch, getState) => {
        const channelId = getState().jobs.job.id;
        let offset;
        let limit;
        if (getState().chat.offset > 15) {
            offset = getState().chat.offset - 15;
            limit = 15;
        } else {
            offset = 0;
            limit = getState().chat.offset;
        }
        const chatRequestBody = {
			query: getMessagesWithOffsetRequestBody,
			variables: {
				channelId: Number(channelId),
				offset: offset,
				limit: limit,
			},
		};
        if (getState().chat.offset !== 0) {
            dispatch(DISPATCH_STATE.fetchMessageBegin());
            dispatch(DISPATCH_STATE.setOffset(offset));
            dispatch(DISPATCH_STATE.setLimit(offset));
            networkCall(chatRequestBody)
                .then((res) => {
                    if (!_.isNull(res.data) && res.data.chatMessages) {
						dispatch(paginateMessageList(res.data.chatMessages));
						let data = [
							{
								items: [...getState().chat.updatedMessages[0].items, ...getState().chat.newMessages[0].items],
								totalItems: getState().chat.newMessages[0].totalItems,
							},
						];
						dispatch(DISPATCH_STATE.fetchMessagesSuccess(data));

						const { updatedMessages } = getState().chat;
						const getPrevFirstIndex = updatedMessages[0].items[updatedMessages[0].items.length - 1].index;
						const e = document.getElementById(`message-box-${getPrevFirstIndex}`);
						let scroll = document.getElementById("v-scrollable-chat");
						if (scroll) {
							scroll.scrollTop = scroll.scrollHeight - lastScrollHeight - e.scrollHeight;
						}
						dispatch(DISPATCH_STATE.fetchMessageEnd());
					} else {
                        dispatch(appFetchFailure(res.data.errors[0].message));
                        toast(`${res.data.errors[0].message}`, {
                            position: "bottom-center",
                            autoClose: 2000,
                            limit: 1,
                            className: "toast-rejected-payment",
                            bodyClassName: "toastify-inner",
                            hideProgressBar: true,
                            closeOnClick: false,
                        });
                    }
                });
        }
    };
}
/**
 * When user come out from chat page
 */
export const getMessagesWillUnmount = () => {
    return (dispatch, getState) => {
        clearInterval(getState().chat.getMessageSetInterval);
        dispatch(DISPATCH_STATE.getMessageWillUnMountDispatch());
    }
}

export const fetchAllMessages = (channelId) => {
    return (dispatch, getState) => {
        const chatRequestBody = {
            query: getMessagesWithOffsetRequestBody,
            variables: {
                "channelId": Number(channelId)
            }
        }
        if (getState().jobs.job.state !== JOB_STATE_COMPLETED) {
            networkCall(chatRequestBody)
                .then((res) => {
                    if(res.data && res.data.chatMessages) {
                        dispatch(setParams(res.data.chatMessages.totalItems, res.data.chatMessages.lastConsumeIndex));
                        dispatch(initMessages(res.data.chatMessages));
                        if(res.statusCode && res.statusCode === 401) {  
                            toast("unauthorized", {
                                position: "bottom-center",
                                autoClose: 2000,
                                limit: 1,
                                className: "toast-rejected-payment",
                                bodyClassName: "toastify-inner",
                                hideProgressBar: true,
                                closeOnClick: false,
                            });
                        }
                    }
                }).then(() => dispatch(chatScrollToBottom()))
        }
    }
}