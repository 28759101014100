import React, { Fragment } from 'react';

import SavedPlacesOthersItem from './SavedPlacesOthersItem/SavedPlacesOthersItem';
import { ReactComponent as MorePlace } from "../../../v5-icons/more-place.svg";
import { ReactComponent as ActionIcon } from "../../../v5-icons/kebab-menu.svg";

export default function SavedPlacesOthers(props) {
    let items = props?.data?.map((place, index) => {
        return (
			<div key={index}>
				<SavedPlacesOthersItem
					key={index}
					onClick={props.onClick}
					place={place}
					index={index}
					name={place.title}
					address={place.location.address}
					actionIcon={<ActionIcon />}
					icon={<MorePlace />}
				/>
				<div className="saved-places-divider"></div>
			</div>
		);
    })
    return (
		<Fragment>
			{props.data && <div className="saved-places-others">
				{items}
			</div>}
		</Fragment>
	);
}