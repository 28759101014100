import _ from 'lodash';

import * as DISPATCH_STATE from '../chatChangeState';
import {
    MESSAGE_STATUS_SENT,
    MESSAGE_STATUS_WAITING,
    messageObjectCreator,
    messageObjectCreatorFromChatService,
    messageObjectForMeWithNewService
} from '../../../../functions/message-object-creator';
/**
 * Scroll page to top
 */
export const chatScrollToBottom = () => {
    return (dispatch) => {
        let scroll = document.getElementById('v-scrollable-chat');
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
        }

        dispatch(DISPATCH_STATE.chatScrollToBottomDispatch());
    }
};
/**
 * put messages from histroy
 * @param {*} job 
 */
export const initMessagesFromJobChatHistory = (job) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.initMessagesFromJobChatHistoryDispatch(messageObjectCreator(job[ 0 ].data, getState().common.client)));
    }
}

/**
 * messages when user has job active
 * @param {*} messages 
 */
export const initMessages = (messages) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.initMessagesDispatch(messageObjectCreatorFromChatService(messages, getState().common.client)));
    }
}

/**
 * Update message list when user scroll
 * @param {*} messages 
 */
export const paginateMessageList = (messages) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.updateMessageList(messageObjectCreatorFromChatService(messages, getState().common.client)));
    }
}

/**
 * Add message from me to messages
 * @param {*} message
 * @param {*} file
 */
export const putNewMessage = (message, file = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (typeof (getState().chat.newMessages[0]) !== 'undefined') {
                let messages = getState().chat.newMessages[ 0 ].items;
                let newMessage = messageObjectForMeWithNewService(message, file, messages);
                if (_.has(messages[ messages.length - 1 ], 'index')) {
                    newMessage[ 'index' ] = messages[ messages.length - 1 ][ 'index' ] + 1;
                    newMessage[ 'status' ] = MESSAGE_STATUS_WAITING;
                }
                messages.push(newMessage);
                const messageList = {
                    "0": {
                        "items": messages,
                        "totalItems": messages.length
                    }
                }
                dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
                resolve();
            }
        });
    }
}
/**
 * Change status index of message for show message sent successfully
 * @param {*} index 
 */
export const changeStatusOfMessageNewService = (index) => {
    return (dispatch, getState) => {
        let messages = getState().chat.newMessages[ 0 ].items;
        messages = messages.map((message, i) => {
            if (message.index === index) {
                message.status = MESSAGE_STATUS_SENT;
            }
            return message;
        });
        const messageList = {
            "0": {
                "items": messages,
                "totalItems": messages.length
            }
        }
        dispatch(DISPATCH_STATE.putNewMessageToMessagesArray(messageList));
    }
}

export const callToNumber = (number) => {
    return (dispatch, getState) => {
        window.open(`tel:${number}`);
		dispatch(DISPATCH_STATE.callToNumberDispatch());
    }
}