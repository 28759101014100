import React from "react";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";

const HomeSearchLocation = (props) => {
	return <div className="Home_Search_Location">
		<div className="H_S_L_Left_Arrow" onClick={props.onBackHomePage}>
			<DynamicIcon icon="BackButton" classes="Left_Arrow"/>
		</div>
		<div className="Center_Search_Service" onClick={props.navigateToSearchLocation}>
			<div className="H_S_L_Pin">
				<DynamicIcon icon="LocationPin" classes="Home_SearchLocation_Pin"/>
			</div>
			<div className="H_S_L_Text">
				<h5>{props.locationTitle}</h5>
			</div>
			<div className="H_S_L_Arrow_Location" >
				<DynamicIcon icon="BlueLeft" classes="Right_Arrow_Search_Location"/>
			</div>
		</div>

	</div>;
};
export default HomeSearchLocation;