import React, { Fragment } from "react";
import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";
import Button from "@material-ui/core/Button";
import { ReactComponent as LocationPin} from "../../../assests/icons/new-locationPin.svg";
import { Box } from "@material-ui/core";

const HomeEmpty = ({navigateToSearchLocation}) => {

	return (
		<Fragment>
			<div className="Home_Empty_Services">
				<DynamicIcon icon="emptyHomePic" classes="Home_Search_Empty" />
				<h5 className="Empty_Description">Our services do not cover this location yet</h5>
				{/* <Box display="flex" justifyContent="center" width="100%"> */}
				<Button type="button" className="Empty_Top_Four" onClick={navigateToSearchLocation} variant="contained">
					{/* <span>
					</span> */}
					<LocationPin />
					<span className="Btn_Text">Change Location</span>
				</Button>
				{/* </Box> */}
			</div>
		</Fragment>
	);
}
export default HomeEmpty;