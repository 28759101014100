import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
// import ButtonUnstyled, { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
const classNames = require('classnames');

export default function CustomButton({ title, outline, disabled, type, onClick, classList, variant, noBorder }) {
    // <CustomButton title="Got it" type="button" className="primary-button" />
    return (
		// <div className={classNames("button-container", classList, { "button-outline": outline })}>
		<Button
			className={classNames("button-container", classList, { "button-outline": outline }, { "no-border": noBorder })}
            variant={variant ? variant : "contained"}
			onClick={onClick}
			type={type}
			disabled={!_.isNull(disabled) && disabled}
		>
			{title}
		</Button>
		// </div>
	);
}