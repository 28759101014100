import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import LinearProgress from "@material-ui/core/LinearProgress"
import HowItWorksImage1 from "../../../v5-icons/howItWorks-1.svg";
import HowItWorksImage2 from "../../../v5-icons/howItWorks-2.svg";
import HowItWorksImage3 from "../../../v5-icons/howItWorks-3.svg";

export default function HowItWorksCarousel() {

    const [progress, setProgress] = React.useState(0);
    const customSlider = useRef();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    customSlider.current.slickNext();
                    return 0;
                }
                return oldProgress + 5;
            });
        }, 300);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const settings = {
        customPaging: function(i) {
            return (
                <a>
                    <LinearProgress variant="determinate" value={ progress } />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => setProgress(0),
    };
	return (
		<div className="how-it-works-carousel-wrapper">
			<div className="how-it-works-title">How it works</div>
			<div className="slider-wrapper">
				<Slider ref={(slider) => (customSlider.current = slider)} {...settings}>
					<div className="slider-item alice-blue">
						<p>Book to chat with the best shoppers in town! They're inside the mall.</p>
						<img src={HowItWorksImage1} alt="How it works 1" />
					</div>
					<div className="slider-item aero-blue">
						<p>Give your shopping list. Groceries, food & the rest. No limits.</p>
						<img src={HowItWorksImage2} alt="How it works 2" />
					</div>
					<div className="slider-item pale-pink">
						<p>Pay for things in chat. Save with Store Prices Guarantee. We deliver your goodies!</p>
						<img src={HowItWorksImage3} alt="How it works 3" />
					</div>
				</Slider>
			</div>
		</div>
	);
}
