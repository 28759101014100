import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import JobRatingComplimentsItem from "./JobRatingComplimentsItem";
import JobRatingButton from "../JobRatingButtons/JobRatingButtons";

import { storeSelectedCompliments } from "../../../utils/redux/actions/jobsActions/actions/jobs-rating";
import { addCommentOfAppRating } from "../../../utils/redux/actions/jobsActions/jobsChangeState";

class JobRatingSegmentCompliments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			compliments: null,
			tabIndex: this.props.selectedIndex,
		};
	}

	onSelect = (index) => {
		this.setState(() => ({ tabIndex: index }));
		if (index === 0) {
			this.setState(() => ({
				compliments: this.props.compliments[1]["compliments"],
			}));
		} else if (index === 1) {
			this.setState(() => ({
				compliments: this.props.compliments[0]["compliments"],
			}));
		}
	};

	onChangeComplimentItem = (compliment) => {
		this.props.dispatch(storeSelectedCompliments(compliment));
	};

	handleCommentChange = (e) => {
		const { value } = e.target;
		if (value.length) {
			this.props.dispatch(addCommentOfAppRating(value));
		}
	};

	handleFocus = (e) => {
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			e.target.parentNode.classList.add("focused");
		}
	};

	render() {
		const tabList = ["Strengths", "Weaknesses"];
		const { compliments } = this.state;
		return this.props.isOpen ? (
			<Fragment>
				<div className="Job-rating-compliments compliments-with-segment">
					<h5 className="feedback-text">Feedback</h5>
					<h6 className="feedback-text-subtitle">Tell us about your experience</h6>
					<div className="job-information-container">
						<Tabs onSelect={this.onSelect} selectedIndex={this.state.tabIndex} selectedTabClassName={"segment-active"}>
							<TabList className="tabs-menu-segment">
								{tabList.map((item, idx) => (
									<Tab className="item-segment" key={idx}>
										{item}
									</Tab>
								))}
							</TabList>
							<div className="tabs-panel-container">
								<TabPanel panelclass="job-history">
									<div className="no-padding Job_Rating_Items">
										{!_.isNull(compliments)
											? compliments.map((compliment) => (
													<JobRatingComplimentsItem
														key={compliment.id}
														onChange={() => this.onChangeComplimentItem(compliment)}
														id={compliment.id}
														selected={compliment?.selected ? compliment?.selected : false}
													>
														{compliment.name}
													</JobRatingComplimentsItem>
											  ))
											: this.props.firstCompliment.map((compliment) => (
													<JobRatingComplimentsItem
														key={compliment.id}
														onChange={() => this.onChangeComplimentItem(compliment)}
														id={compliment.id}
														selected={compliment?.selected ? compliment?.selected : false}
													>
														{compliment.name}
													</JobRatingComplimentsItem>
											  ))}
									</div>
								</TabPanel>
								<TabPanel panelclass="job-history">
									<div className="no-padding Job_Rating_Items">
										{!_.isNull(compliments)
											? compliments.map((compliment) => (
													<JobRatingComplimentsItem
														key={compliment.id}
														onChange={() => this.onChangeComplimentItem(compliment)}
														id={compliment.id}
														selected={compliment?.selected ? compliment?.selected : false}
													>
														{compliment.name}
													</JobRatingComplimentsItem>
											  ))
											: this.props.firstCompliment.map((compliment) => (
													<JobRatingComplimentsItem
														key={compliment.id}
														onChange={() => this.onChangeComplimentItem(compliment)}
														id={compliment.id}
														selected={compliment?.selected ? compliment?.selected : false}
													>
														{compliment.name}
													</JobRatingComplimentsItem>
											  ))}
									</div>
								</TabPanel>
							</div>
						</Tabs>
					</div>

					<div className="Job_Rating_Comment no-padding">
						<div className="form-group-container" id="Job_Rating_Text">
							<label>Share feedback or a personal message to us...</label>
							<textarea
								rows={4}
								onChange={this.handleCommentChange}
								onFocus={this.handleFocus}
								onBlur={this.handleFocus}
								name="comment"
								className="Job_Rating_Comment_Text_Area"
							></textarea>
						</div>
					</div>
					<JobRatingButton
						loading={this.props.sendFeedBackLoading}
						isDisabbled={this.props.jobs.jobRatingButton || this.props.sendFeedBackLoading}
						closeSheet={this.props.toggleSheet}
						handleClick={this.props.obSubmit}
					/>
				</div>
			</Fragment>
		) : null;
	}
}

const mapStateToProps = (state) => ({
	jobs: state.jobs
});

export default connect(mapStateToProps)(JobRatingSegmentCompliments);