export const deleteSavedPlaceRequestBody = `mutation DeleteSavedPlace($id: Int!) {
  deleteSavedPlace(savedPlaceId: $id) {
    succeed
    result
  }
}`;

export const createSavedPlaceRequestBody = `mutation CreateSavedPlace($data: ClientCreateSavedPlaceInput!) {
  createSavedPlace(data: $data) {
    id
    clientId
    title
    isActive
    type
    isBookmarked
    phoneCall
    note
    location {
      lat
      lng
      address
      data
    }
  }
}`;

export const createLastUsedRequestBody = `mutation CreateSavedPlace($data: ClientCreateSavedPlaceInput!) {
  createSavedPlace(data: $data) {
    id
    referenceSavedPlaceId
    referenceSavedPlaceType
    clientId
    title
    isActive
    type
    isBookmarked
    phoneCall
    note
    location {
      lat
      lng
      address
      data
    }
  }
}`;

export const updateSavedPlaceRequestBody = `mutation UpdateSavedPlace($data: ClientUpdateSavedPlaceInput!, $savedPlaceId: Int!) {
  updateSavedPlace(data: $data, savedPlaceId: $savedPlaceId) {
    id
    referenceSavedPlaceId
    clientId
    title
    isActive
    type
    isBookmarked
    phoneCall
    note
    location {
      lat
      lng
      address
      data
    }
  }
}`;

export const updateLastUsedRequestBody = `mutation UpdateSavedPlace($data: ClientUpdateSavedPlaceInput!, $savedPlaceId: Int!) {
  updateSavedPlace(data: $data, savedPlaceId: $savedPlaceId) {
    id
    clientId
    title
    isActive
    type
    isBookmarked
    phoneCall
    note
    location {
      lat
      lng
      address
      data
    }
  }
}`;


export const getLastUsedRequestBody = `query LastUsedSavedPlace {
  lastUsedSavedPlace {
    id
    referenceSavedPlaceId
    referenceSavedPlaceType
    clientId
    title
    isActive
    type
    isBookmarked
    phoneCall
    note
    location {
      lat
      lng
      address
      data
    }
  }
}`;




