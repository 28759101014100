import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import './billing-error-modal.css';
import ModalBody from '../../../Modal/ModalBody';
import SadMascot from '../../../../assests/images/jobs/sad-mascot.svg';

function BillingErrorModal(props) {

    return (
        <Fragment>
            <div className="Billing-error-modal col-xs-12  no-padding">
                <ModalBody>
                    <div className="col-xs-12 no-padding">
                        <div className="modal-kuya-container">
                            <img src={SadMascot} alt="" className="img-responsive" />
                        </div>
                    </div>
                    <div className="col-xs-12 no-padding text-center m-t-10 Job-unfulfilled-modal-title">
                        <p><strong>Sorry Po!</strong></p>
                    </div>
                    <div className="col-xs-12 no-padding text-center message">
                        <p>{props.billing.errorPaymentMessage}</p>
                    </div>
                    <div className="col-xs-12 m-t-15 Job-unfulfilled-modal-button-container">
                        <Button onClick={props.edit} type="button" className="retry-button">Edit Card</Button>
                    </div>
                    <div className="col-xs-12 m-t-10 Job-unfulfilled-modal-button-container">
                        <Button onClick={props.another} type="button" className="cancel-button">Use Another Card</Button>
                    </div>
                </ModalBody>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    common: state.common,
    billing: state.billing,
});

export default connect(mapStateToProps)(BillingErrorModal);