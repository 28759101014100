import React, { Fragment, Component } from "react";
import _ from "lodash";
import Slider from "react-slick";

import * as LOG_EVENT from "../../../utils/analytics/index";

import ServiceBox from "../ServiceBox/ServiceBox";
import { Tabs } from "@bumaga/tabs";
import TabItem from "../../ui-elements/Tabs/TabItem/TabItem";
class Featured extends Component {
	constructor(props) {
		super(props);
		this.animateTabPanelRight = this.animateTabPanelRight.bind(this);
		this.animateTabPanelLeft = this.animateTabPanelLeft.bind(this);
		this.createTabs = this.createTabs.bind(this);
		this.state = {
			slideIndex: 0,
			updateCount: 0,
			tabs: [],
			nav1: null,
			nav2: null,
			isSwipped: false,
			isEventSet: false,
			setting2: { centerMode: false }
		};
	}
	componentDidMount() {
		this.createTabs();
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
		});
	}

	onActive(tabItems, tabItem) {
		tabItems.forEach((item) => {
			item.active = false;
		});
		this.setState({ tabs: tabItems });
		tabItem.active = true;
		this.slider1.slickGoTo(tabItem.index);
	}

	animateTabPanelRight() {
		this.setState({ rightAnimate: true });
		this.setState({ leftAnimate: false });
	}

	animateTabPanelLeft() {
		this.setState({ leftAnimate: true });
		this.setState({ rightAnimate: false });
	}

	/* create tabs feature and product*/
	createTabs() {
		let tabsItemsTemprary = [];
		if (this.props.data.length !== 0) {
			this.props.data.forEach((item, index) => {
				let tabItem = {
					id: 0,
					index: 0,
					title: "",
					active: false,
					animateRight: false,
					animateLeft: false,
					classes: "tab-item-history",
					activeClass: "tab-active-home",
					panelClass: "Home_Tab_Panel",
					products: [],
				};

				tabItem.id = index;
				tabItem.index = index;
				tabItem.title = item.title;
				tabItem.products = item.subCategories.filter((t) => t.showInHome);
				index === 0 ? (tabItem.active = true) : (tabItem.active = false);
				tabsItemsTemprary.push(tabItem);
			});
			let filteredTab = [];
			tabsItemsTemprary.map((tabItem) => {
				if (tabItem.products.length) {
					filteredTab.push(tabItem);
					filteredTab[0].active = true;
					filteredTab.map((tabItem, index) => {
						tabItem.index = index;
					});
					this.setState(() => ({
						tabs: filteredTab,
					}));
				}
			});
		}
	}
	onActiveAgain(current) {
		let tabs = this.state.tabs;
		tabs.map((tab, index) => {
			tab.active = false;
			if (index === current) {
				tab.active = true;
			}
		});
		this.setState({ tabs: tabs });
	}

	handleSwipeSlider() {
		this.setState(() => ({
			isSwipped: true,
		}));
		if (!this.state.isSwipped && !this.state.isEventSet) {
			LOG_EVENT.logEvent(LOG_EVENT.SCROLLED_THROUGH_SERVICES_CWA, { browseType: "scroll" });
			this.setState(() => ({
				isEventSet: true,
			}));
		}
	}

	handleAfterChnageSlider() {		
		if(!this.state.isSwipped) {
			LOG_EVENT.logEvent(LOG_EVENT.SCROLLED_THROUGH_SERVICES_CWA, { browseType: "segment" });
		} else {
			this.setState(() => ({
				isSwipped: false,
			}));
		}
	}

	render() {
		const settings = {
			dots: false,
			infinite: false,
			speed: 600,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: (current) => {this.onActiveAgain(current)},
			beforeChange: (current, next) => {
				if(next !== 0) {
					this.setState({ setting2: { centerMode: true } });
				} else if (next === 0) {
					this.setState({ setting2: { centerMode: false } });
				}
				this.setState({ slideIndex: next });
			},
		};
		
		
		return (
			<Fragment>
				<div className="Home_Featured">
					<div className="Home_Featured_Box no-padding">
						<div className="Tabs_Parent">
							<Tabs>
								<div className="Tabs_Menu_Parent">
									{this.props.categories.length > 3 ? (
										<Slider
											asNavFor={this.state.nav1}
											ref={(slider) => (this.slider2 = slider)}
											infinite={false}
											speed={600}
											slidesToShow={1}
											swipeToSlide={true}
											focusOnSelect={true}
											variableWidth={true}
											{...this.state.setting2}
											useTransform={false}
											useCSS={false}
											onSwipe={() => this.handleSwipeSlider()}
											afterChange={() => this.handleAfterChnageSlider()}
											className="category-container"
										>
											{!_.isNull(this.props.categories)
												? this.props.categories.map((cat, idx) => (
														<div key={idx} style={{ width: "fit-content" }}>
															<h3>{cat.title}</h3>
														</div>
												  ))
												: null}
										</Slider>
									) : (
										<div id="tabs-menu-tab" className="tabs-menu-tab">
											{this.state.tabs.map((tabItem, index) => (
												<div className={tabItem.classes} key={index}>
													<TabItem clicked={() => this.onActive(this.state.tabs, tabItem)}>{tabItem}</TabItem>
												</div>
											))}
										</div>
									)}
								</div>
								<div>
									<Slider {...settings} asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)}>
										{this.state.tabs.map((item, index) => (
											<div key={index} tabIndex={index}>
												<div className="featured-container">
													{item["products"].map((service, index) => {
														return (
															<div key={index} className="Home_Featured_Box_Service no-padding">
																<ServiceBox
																	subCategoryId={item.id}
																	data={service}
																	onNavigateToService={this.props.onNavigateToService}
																	img={service.icon}
																	id={service.id}
																	title={service.title}
																/>
															</div>
														);
													})}
												</div>
											</div>
										))}
									</Slider>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Featured;