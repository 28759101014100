import React, {Fragment} from 'react';
import { push } from "connected-react-router";
import { connect } from "react-redux";

import * as LOG_EVENT from "../../../utils/analytics/index";
import forwardImg from '../../../v5-icons/forward.svg';
import { toggleLogInBottomSheet, toggleRegisterBottomSheet } from "../../../utils/redux/actions/commonActions/commonChangeState";
import LogInBottomSheet from "../../LogInBottomSheet/LogInBottomSheet";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import RegisterForm from "../../register/form/register-form";

const bottomSheetStyle = {
	zIndex: "100",
	borderRadius: "20px",
	bottom: "0",
	position: "fixed",
	marginLeft: "auto",
	marginRight: "auto",
	maxWidth: "414px",
};

function AccountProfile(props) {

	const navigateToAccount = () => {
		if (!props.auth || !props.authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_OPENED_CWA, { from: "bar" });
			props.dispatch(toggleLogInBottomSheet());
		} else {
			LOG_EVENT.logEvent(LOG_EVENT.ACCOUNT_PAGE_CWA, { from: "tab" });
			props.dispatch(push("/account/profile"));
		}
	};

	return (
		<Fragment>
			{props.auth && props.authCheck ? (
				<div
					className="account-profile"
					onClick={() => {
						LOG_EVENT.logEvent(LOG_EVENT.EDIT_PROFILE_PAGE_CWA);
						navigateToAccount();
					}}
				>
					<div className="profile-info">
						<p className="account-profile-name">
							{props.data.givenName} {props.data.lastName}
						</p>
						<div>
							<p className="account-profile-edit">Edit Profile</p>
							<img className="account-profile-forward" src={forwardImg} alt="" />
						</div>
					</div>
				</div>
			) : (
				<div
					className="account-profile"
					onClick={() => {
						LOG_EVENT.logEvent(LOG_EVENT.EDIT_PROFILE_PAGE_CWA);
						navigateToAccount();
					}}
				>
					<div className="profile-info">
						<p className="account-profile-name">Guest</p>
						<div>
							<p className="account-profile-edit">Login</p>
							<img className="account-profile-forward" src={forwardImg} alt="" />
						</div>
					</div>
				</div>
			)}
			{!props.auth ? (
				<BottomSheet
					classes="login-bottom-sheet"
					isOpen={props.logInBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={false}
					onChangeOverlay={(isOpen) => {
						//false => phone | true => showing verification
						if (props.enteringVerificationCode) {
							LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "code" });
						} else {
							LOG_EVENT.logEvent(LOG_EVENT.LOGIN_SHEET_CLOSED_CWA, { from: "phone" });
						}
						if (!isOpen) {
							props.dispatch(toggleLogInBottomSheet());
						}
					}}
				>
					<LogInBottomSheet />
				</BottomSheet>
			) : null}

			{props.registerBottomSheet ? (
				<BottomSheet
					classes="login-bottom-sheet"
					isOpen={props.registerBottomSheet}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={false}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) {
							props.dispatch(toggleRegisterBottomSheet(false));
							localStorage.removeItem("token");
							localStorage.removeItem("username");
							localStorage.removeItem("countryFlag");
						}
					}}
				>
					<RegisterForm />
				</BottomSheet>
			) : null}
		</Fragment>
	);
}
const mapStateToProps = (state) => ({
	...state.common,
	register: state.register,
});

export default connect(mapStateToProps)(AccountProfile);