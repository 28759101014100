import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import MykuyaLogo from "../../assests/icons/mykuya-logo.png";
import TitleBar from "../../organisms/title-bar";
import closeButton from "../../v5-icons/close-thin.svg";
import chatSupport from "../../v5-icons/chat-support.svg";
import blueForward from "../../assests/icons/blueForward.svg";
import {
	backToHme,
} from "../../utils/redux/actions/homeActions";
import { navigateToSupport } from "../../utils/redux/actions/supportActions/supportChangeState";
import { isSaripay } from "../../utils/functions/check-saripay";

class AboutMyKuya extends Component {

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	goBack = () => {
		this.props.dispatch(backToHme());
	};

	navigateAboutUs = (navigateRow) => {
		window.location.href = navigateRow.link;
	};

	render() {
		const sourcePlatform = isSaripay() ? "saripay" : "mykuya";
		let aboutMyKuyaRows = [
			{ id: 0, title: "Privacy Policy", link: "https://www.mykuya.com/privacy-policy" },
			{ id: 1, title: "Terms of use", link: "https://www.mykuya.com/terms-of-use" },
			{ id: 2, title: "Our Story", link: " https://www.mykuya.com/about" },
			{ id: 3, title: "Help Center", link: "https://help.mykuya.com/hc/en-us" },
			{ id: 4, title: "MK Enterprise solutions", link: " https://www.mykuya.com/enterprise-solutions" },
			{ id: 5, title: `Work at ${sourcePlatform}`, link: "https://www.mykuya.com/franchise-program" },
		];
		const titleBar = <TitleBar leftIcon={closeButton}
								   handleClickLeftIcon={this.goBack}
								   handleClickRightIcon={this.onSupport}
								   rightIcon={chatSupport}/>;
		return (
			<Fragment>
				<div className="About_My_Kuya">
					<div>{titleBar}</div>
					<div className="A_M_K_Img_Box">
						<img src={MykuyaLogo} alt="mykuya-logo" />
					</div>
					<div className="About_My_Kuya_Rows">
						{aboutMyKuyaRows.map((item, index) => {
							return (
								<div className="A_M_K_R" key={index} onClick={() => this.navigateAboutUs(item)}>
									<div className="A_M_K_R_Text">
										<h5>{item.title}</h5>
									</div>
									<div className="A_M_K_R_Link">
										<img src={blueForward} alt="" />
									</div>
								</div>
							);
						})}
					</div>
					<div className="About_My_Kuya_Description">
						<h5>{ isSaripay() ? "saripay" : "mykuya" } keeps getting better; but only with your honest feedback. Share with
							us your thoughts via{" "}
							<strong className="About_Kuya_Support" onClick={this.onSupport}>
								{ isSaripay() ? "saripay" : "mykuya" } support
							</strong>
							. We are here to listen!
						</h5>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	...state.home,
});
export default connect(mapStateToProps)(AboutMyKuya);