import React, { Fragment } from 'react';

import BookingHeader from '../BookingHeader/BookingHeader';
import BookingService from '../BookingService/BookingService';
import BookingServiceDescription from '../BookingServiceDescription/BookingServiceDescription';
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
const classNames = require("classnames");

export default function BookingInfoModal(props) {
    return (
		<Fragment>
			<div className="booking-info-container">
				<div className="booking-service">
					<div className="booking-service-wrapper">
						<div className="booking-service-product-image">
							<img src={props.productDetail.icon} alt="" className="" />
						</div>
						<div
							className={classNames("booking-service-product", {
								"has-padding": props.hasFadePropProduct,
								"has-subtitle-padding": props.hasFadePropSubtitle,
							})}
						>
							<div className="long-heading-info text-container">
								<span className="heading-info booking-service-name">{props.productDetail.commercialName}</span>
								{props.hasFadePropProductInfo && (
									<>
										<div className="fader fader-left"></div>
										<div className="fader fader-right"></div>
									</>
								)}
							</div>

							<div className="long-subtitle-info text-container">
								<span className="subtitle-info booking-service-description">{props.productDetail.descriptionTitle}</span>
								{props.hasFadePropSubtitleInfo && (
									<>
										<div className="fader fader-left"></div>
										<div className="fader fader-right"></div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="booking-service-body">
					<BookingHeader img={props.productDetail.image} commercialName={props.productDetail.commercialName} />
					<BookingServiceDescription description={props.productDetail.description} />
					<div className="got-it-button">
						<CustomButton title="Got It" onClick={props.closeModal} />
					</div>
				</div>
			</div>
		</Fragment>
	);
}