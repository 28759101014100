import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';

import  WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

export default function JobRatingButton(props) {

    return (
		<Fragment>
			<div className="Job_Rating_Buttons">
				<div className="Job_Rating_Buttons_Submit">
					<Button onClick={props.handleClick} type="submit" className="J_R_B_Submit" disabled={props.isDisabbled}>
						{props.loading ? (
							<div className="next-button-loading">
								<WhiteLoading />
							</div>
						) : (
							"Give Feedback"
						)}
					</Button>
				</div>
			</div>
		</Fragment>
	);
}