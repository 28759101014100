import React from "react";

import HowItWorksImage from "../../../v5-icons/how-it-work.png";
export default function HowItWorks({content}) {
	return (
        <div className="how-it-works-wrapper">
            <div className="how-it-works-container">
                <div className="wrapper-image">
                    <img src={HowItWorksImage} alt="How it works sample" />
                </div>
                <div className="content-container">
                    <h6>How This Works</h6>
                    <p>{content}</p>
                </div>
            </div>
        </div>
	);
}
