import React, { Fragment } from "react";

import PciImg from "../../../../../v5-icons/pci.png";
import paymentSecuredImg from "../../../../../v5-icons/payment-secured.svg";
import bangkoSentralImg from "../../../../../v5-icons/bangko-sentral-logo.png";
import { isSaripay } from "../../../../../utils/functions/check-saripay";

export default function BillingInformationSafely(props) {
	return (
		<Fragment>
			<div className="billing-information-safely">
				<div className="billing-information-safely-inner">
					<div className="billing-information-safely-image">
						<img src={bangkoSentralImg} alt="PciImg" />
					</div>
					<p>{ isSaripay() ? "saripay" : "mykuya" } is supervised by <br/> Bangko Sentral ng Pilipinas</p>
				</div>
				<div className="billing-information-safely-inner">
					<div className="billing-information-safely-image">
						<img src={PciImg} alt="PciImg" />
					</div>
					<p>{ isSaripay() ? "saripay" : "mykuya" } is PCI-DSS Compliant</p>
				</div>
				<div className="billing-information-safely-inner" style={{height: "21px"}}>
					<div className="billing-information-safely-image">
						<img src={paymentSecuredImg} alt="" className="payment" />
					</div>
					<p>Your details are safely encrypted</p>
				</div>
			</div>
		</Fragment>
	);
}