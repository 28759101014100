import React, {Fragment} from 'react';
import _ from "lodash";

import CustomButton from '../../ui-elements/CustomButton/CustomButton';
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import {ReactComponent as Kuya } from '../../../v5-icons/kuya.svg';
import {ReactComponent as Nearby } from '../../../v5-icons/nearby.svg';
import {ReactComponent as Thunderbolt } from '../../../v5-icons/thunderbolt.svg';
import {ReactComponent as ScheduledKuya} from "../../../v5-icons/scheduled-kuya.svg"

const classNames = require("classnames");

export default function BookingButton(props) {
	return (
		<div className="booking-button-container">
			<div className="booking-button">
				<div className="tour-booking-button" data-tut="tour-booking-button">
					{!_.isUndefined(props.mode) && props.mode.includes("INSTANT") ? (
						<div data-tut="tour-instant-booking">
							<CustomButton
								disabled={props.loading}
								classList={classNames({
									"is-instant-match": props.isInstantMatch,
								})}
								variant="contained"
								type="button"
								title={
									props.loading ? (
										<div className="next-button-loading">
											<WhiteLoading />
										</div>
									) : props.isInstantMatch ? (
										<Fragment>
											{!props.mymall && (<div className="booking-button-icon">
												<Kuya />
												<Thunderbolt className="icon" />
											</div>)}
											{props.mymall ? "Request for Ate Shopper" : "Book This Job"}
										</Fragment>
									) : (
										<Fragment>
											{!props.mymall && (<div className="booking-button-icon">
												<Kuya />
												<Nearby className="icon" />
											</div>)}
											{props.mymall ? "Request for Ate Shopper" : "Book This Job"}
										</Fragment>
									)
								}
								onClick={props.onClick}
							/>
						</div>
					) : null}

					{!_.isUndefined(props.mode) && props.mode.includes("SCHEDULED") ? (
						<div data-tut="tour-scheduled-booking">
							<CustomButton
								outline={true}
								variant="outlined"
								classList="schedule-booking-button"
								title={
									<>
										{!props.mymall && <ScheduledKuya />}
										<p>{props.mymall ? "Schedule for later" : "Schedule Booking"}</p>
									</>
								}
								type="button"
								onClick={props.onScheduleBooking}
							/>
						</div>
					) : null}
				</div>
				<CustomButton title={props.mymall ? "Nevermind" : "Cancel Booking"}
							  type="button"
							  classList="cancel-booking"
							  noBorder
							  onClick={props.onCancelBooking} />
			</div>
		</div>
	);
}