import _ from 'lodash';
/**
 * Get exact address by lat long
 * @param {*} location 
 * @param {*} callback 
 */
export function getExactAddress(location, getState, callback) {
    const geocoder = new window.google.maps.Geocoder();
    if (getState().map.googlePlaceId) {
        geocoder
            .geocode({ placeId: getState().map.googlePlaceId })
            .then(({ results }) => {
                if (results[0]) {
                    callback(results);
                }
            });
    } else {
        geocoder.geocode({ 'location': location }, callback);
   }
    return geocoder;
}
/**
 *
 * @param {*} getState
 * @param {*} radius
 * @param {*} keyword
 * @param {*} callback
 */
export function autocompleteSearch(getState, keyword, radius, callback) {
    let pyrmont = new window.google.maps.LatLng(getState().map.location.lat, getState().map.location.lng);
    let service = new window.google.maps.places.AutocompleteService();
    let sessionToken = getSessionToken();

    var request = {
        location: pyrmont,
        radius: radius,
        sessionToken: sessionToken,
        input: keyword
    };

    service.getPlacePredictions(request, callback);
    return service;
}

var globalSessionToken;

function getSessionToken() {
    if (!globalSessionToken) {
        changeSessionToken();
    }
    return globalSessionToken;
}

function changeSessionToken() {
    globalSessionToken = new window.google.maps.places.AutocompleteSessionToken();
}

/**
 *
 * @param {*} getState
 * @param {*} placeId
 * @param {*} callback
 */
export function getDetailPlaces(getState, placeId, callback) {
    let service = new window.google.maps.places.PlacesService(getState().map.googleMap);
    var request = {
        placeId: placeId,
        fields: [ 'formatted_address', 'geometry', 'name', 'vicinity', 'place_id' ],
        sessionToken: getSessionToken()
    };
    service.getDetails(request, callback);
    changeSessionToken();
    return service;
}

/**
 *
 * @param {*} getState
 * @param {*} placeId
 * @param {*} callback
 */
export function getGeoCodePlaces(placeId, callback) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder
        .geocode({ placeId: placeId })
        .then(({ results }) => {
            if (results[0]) {
                callback(results[0]);
            }
        });
}

/**
 * 
 * @param {*} results 
 */
export function sliceNearbyResult(results) {
    if (_.isArray(results)) {
        return results.slice(0, 5);
    } else {
        return [];
    }
}

export function getLocationByState(getState) {
    if (!_.isNull(getState().map.location.lat) || !_.isNull(getState().map.location.lng)) {
        return {
            lat: getState().map.location.lat,
            lng: getState().map.location.lng
        }
    } else {
        return {
            lat: getState().map.location.defaultLat,
            lng: getState().map.location.defaultLng
        }
    }
}