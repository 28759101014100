import React from 'react';
import _ from 'lodash';

import { ReactComponent as MyKoinSvg } from '../../../../../assests/images/billing/my-koin.svg';
import SingleLineSkeleton from "../../../../ui-elements/Skeleton/SingleLineSkeleton";
import { ReactComponent as ForwardImg } from "../../../../../v5-icons/forward.svg";

export default function BillingInformationBalance(props) {
    return (
		<div className="mykoin-container">
			<div className="billing-information-card-item">
				<div className="mykoin-inner">
					<div className="billing-information-card-item-name">mykoins</div>
					<div className="billing-information-card-item-img">
						<MyKoinSvg className="my-koin-svg" />
					</div>
				</div>
				<div className="billing-information-card-item-value">
					{_.isNull(props.balance) ? <SingleLineSkeleton /> : <span>{props.balance[0].displayValue}</span>}
					{_.isNull(props.balance) ? (
						<SingleLineSkeleton />
					) : (
						<div className="top-up-mykoin-container">
							<p onClick={props.showTopUpBottomSheet}>
								Top up
								<ForwardImg />
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}