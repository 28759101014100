import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import LocationSearchBoxDraggable from '../LocationSearch/LocationSearchBoxDraggable/LocationSearchBoxDraggable';
import LocationSearchDestinationBox from './LocationSearchDestinationBox/LocationSearchDestinationBox';

import { removeDestinationPlace, removeStepFromPlaces, updateGooglePlaces } from "../../utils/redux/actions/mapActions";
import { setGooglePlaceIndex } from "../../utils/redux/actions/mapActions/mapChangeState";
import { searchSetCurrentReference, searchSetCurrentReferenceName } from "../../utils/redux/actions/searchActions/searchChangeState";
import { searchNavigateToMapFromLocationSearch } from "../../utils/redux/actions/searchActions"
import { LOCATION_INPUT_BOX_NAME_DESTINATION, LOCATION_INPUT_BOX_NAME_SECOND_SOURCE } from '../../utils/constants/constant';
import { openLocationSearchBottomSheet, enableAddNextStop } from "../../utils/redux/actions/bookingActions/bookingChangeState";
import { setSavedPlaceLocationPointer } from "../../utils/redux/actions/savedPlacesActions";
import * as LOG_EVENT from "../../utils/analytics/index";

import {
	localStorageGetIsFirstRequest,
	localStorageGetLocation,
} from "../../utils/functions/local-storage";
import { ReactComponent as LocationPin } from "../../v5-icons/location.svg";
import { localStorageIsTwa } from "../../utils/functions/local-storage";
class LocationSearchBooking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clearSource: true,
			clearDestination: false,
			isSearching: false,
			showPlaces: true,
			isUpdated: true,
		};
		this.locationSearchBoxInput = React.createRef();
		this.locationSearchBoxDestinationInput = React.createRef();

		Array.from(Array(this.props.booking.product.minPoints)).map((_, index) => (this[`destinationRef_${index}`] = React.createRef()));
	}

	componentDidMount() {
		this.checkDestinationClearButton();
		if (
			this.props.booking.product.minPoints !== 2 &&
			!_.isNull(this.locationSearchBoxInput.current) &&
			this.locationSearchBoxInput.current.value.length
		) {
			if (JSON.stringify(this.props.map.location) !== JSON.stringify(this.props.map.defaultPickupLocation)) {
				this.props.dispatch(enableAddNextStop());
			}
		}
		//Add new address and redirect to this page and SavedPlacesBottomSheet is open so the input ref should be stored
		if (this.props.booking.locationSearchBottomSheet) {
			const domNode = document.getElementsByName(this.props.currentRefName);
			let ref = { current: domNode[0] };
			this.onFocus(_, ref);
		}
	}

	/**
	 * Check destination clear button for showing at first
	 */
	checkDestinationClearButton = () => {
		if (this.props.map.googlePlaces.length > 0) {
			this.setState({ clearDestination: true });
		}
	};

	/**
	 * Select text of input search box
	 */
	inputSelect = (ref) => {
		if (ref.current) {
			ref.current.select();
		}
	};

	onFocus = (e, ref, index) => {
		const reference =
			localStorageGetIsFirstRequest() ||
			_.isNull(localStorageGetLocation().lat) ||
			_.isNull(localStorageGetLocation().lng) ||
			this.props.map.location.title === "Choose your location"
				? this.locationSearchBoxInput
				: ref;

		this.props.dispatch(searchSetCurrentReference(reference));
		this.props.dispatch(searchSetCurrentReferenceName(reference.current.name));
		this.props.dispatch(setGooglePlaceIndex(index));
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_SHEET_OPENED_CWA, { from: "booking" });
		const { auth, authCheck } = this.props.common;
		if (auth && authCheck) {
			LOG_EVENT.logEvent(LOG_EVENT.SAVED_PLACES_OPENED, { from: "addStop", os: localStorageIsTwa() ? "twa" : "web" });
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.props.dispatch(openLocationSearchBottomSheet());
		} else {
			this.props.dispatch(setSavedPlaceLocationPointer());
			this.goToMap();
		}
		if (!_.isNull(reference.current) || !_.isUndefined(reference.current)) {
			reference.current.blur();
		}
	};

	goToMap = () => {
		LOG_EVENT.logEvent(LOG_EVENT.LOCATION_FROM_MAP_OPENED, {
			os: localStorageIsTwa() ? "twa" : "web",
			from: "addStop",
			isGuest: this.props.common.auth && this.props.common.authCheck ? false : true,
		});
		this.props.dispatch(searchNavigateToMapFromLocationSearch(this.props.match.params));
	};

	onClear = (e, ref) => {
		ref.current.value = "";
		if (ref.current.name === LOCATION_INPUT_BOX_NAME_DESTINATION) {
			this.setState({ clearDestination: false });
			this.props.dispatch(removeDestinationPlace());
		} else if (ref.current.name === LOCATION_INPUT_BOX_NAME_SECOND_SOURCE) {
			this.setState({ clearDestination: false });
			//remove first index of googlePlaces (second Source)
			this.props.dispatch(removeStepFromPlaces(0));
		} else {
			this.setState(() => ({
				clearSource: false,
			}));
			this.props.dispatch(searchSetCurrentReference(ref));
			this.inputSelect(ref);
		}
		this.setState({ isSearching: false });
	};

	onClearStop = (e, i) => {
		this.props.dispatch(removeStepFromPlaces(i)).then(() => {
			this.setState({ showPlaces: false }, () => {
				this.setState({ showPlaces: true });
			});
		});
	};

	onDragEnd = (result) => {
		const { destination, source, draggableId } = result;
		if (!destination) return;
		if (source.index === -2 || destination.index === -2) return;
		if (destination.droppableId === source.droppableId && destination.index === source.index) return;
		this.props.dispatch(updateGooglePlaces(source.index, destination.index, draggableId)).then(() => {
			this.setState(
				{
					showPlaces: false,
					isUpdated: false,
				},
				() => {
					this.setState({
						showPlaces: true,
						isUpdated: true,
					});
				},
			);
		});
	};

	render() {
		let hasConstantLocation = false;
		if (this.props.booking.product.mode && this.props.booking.product.mode.includes("CONSTANT_LOCATION")) {
			hasConstantLocation = true;
		}
		const { constantLocation } = this.props.booking.product;
		return (
			<div className="booking-location-box">
				<DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
					<h2>Where will the job be?</h2>
					<div className="select-location">
						<div data-tut="tour-select-location">
							<Droppable droppableId="droppable-source">
								{(provided) => (
									<div ref={provided.innerRef} {...provided.droppableProps}>
										{hasConstantLocation && (
											<LocationSearchBoxDraggable
												id={-2}
												index={-1}
												number={1}
												value={constantLocation.address}
												autoComplete="off"
												autoFocus={false}
												icon={<LocationPin />}
												placeholder="Add a stop"
												clear={false}
												disabled={true}
												savedPlaces={false}
											/>
										)}
										{this.state.isUpdated && (
											<LocationSearchBoxDraggable
												id={-1}
												index={0}
												number={
													this.props.map.googlePlaces.length
														? hasConstantLocation
															? 2
															: 1
														: this.props.booking.product.minPoints >= 2
														? hasConstantLocation
															? 2
															: 1
														: hasConstantLocation
														? 2
														: null
												}
												value={localStorageGetIsFirstRequest() ? "" : this.props.map.location.address}
												onFocus={this.onFocus}
												autoFocus={false}
												autoComplete="off"
												icon={<LocationPin />}
												refrence={this.locationSearchBoxInput}
												clear={this.props.booking.isEnableNextStop}
												onClear={(e) => this.onClear(e, this.locationSearchBoxInput)}
												savedPlaces={false}
											/>
										)}
										{this.state.showPlaces ? (
											this.props.booking.product.minPoints >= 2 ? (
												this.props.booking.product.minPoints > this.props.map.googlePlaces.length ? (
													<>
														{this.props.map.googlePlaces.map((place, i) => (
															<LocationSearchBoxDraggable
																key={i}
																id={i}
																index={i + 1}
																number={i + hasConstantLocation ? 3 : 2}
																value={place.address}
																autoComplete="off"
																autoFocus={false}
																icon={<LocationPin />}
																placeholder="Add a stop"
																clear={true}
																onClear={(e) => this.onClearStop(e, i)}
																savedPlaces={false}
																refrence={this.locationSearchBoxInput}
																onFocus={this.onFocus}
															/>
														))}
														{Array.from(
															Array(this.props.booking.product.minPoints - 1 - this.props.map.googlePlaces.length),
														).map((_, index) => (
															<LocationSearchDestinationBox
																key={index}
																id={index}
																index={index + 1}
																number={index + hasConstantLocation ? 3 : 2}
																name={LOCATION_INPUT_BOX_NAME_SECOND_SOURCE}
																value=""
																onFocus={this.onFocus}
																autoFocus={false}
																icon={<LocationPin />}
																refrence={this[`destinationRef_${index}`]}
																placeholder="Add next stop"
																classList="has-min-point"
															/>
														))}
													</>
												) : (
													this.props.map.googlePlaces.map((place, i) => (
														<LocationSearchBoxDraggable
															key={i}
															id={i}
															index={i + 1}
															// number={i + hasConstantLocation ? 3 : 2}
															number={hasConstantLocation ? i + 3 : i + 2}
															value={place.address}
															autoComplete="off"
															autoFocus={false}
															icon={<LocationPin />}
															placeholder="Add a stop"
															clear={true}
															onClear={(e) => this.onClearStop(e, i)}
															savedPlaces={false}
															refrence={this.locationSearchBoxInput}
															onFocus={this.onFocus}
														/>
													))
												)
											) : (
												this.props.map.googlePlaces &&
												this.props.map.googlePlaces.map((place, i) => (
													<LocationSearchBoxDraggable
														key={i}
														id={i}
														index={i + 1}
														// number={i + hasConstantLocation ? 3 : 2}
														number={hasConstantLocation ? i + 3 : i + 2}
														value={place.address}
														autoComplete="off"
														autoFocus={false}
														icon={<LocationPin />}
														placeholder="Add a stop"
														clear={true}
														onClear={(e) => this.onClearStop(e, i)}
														savedPlaces={false}
														refrence={this.locationSearchBoxInput}
														onFocus={this.onFocus}
													/>
												))
											)
										) : null}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</div>

						{this.props.booking.product.maxPoints > this.props.map.googlePlaces.length + 1 ? (
							<div className="add-stop-button">
								<LocationSearchDestinationBox
									index={-1}
									name={LOCATION_INPUT_BOX_NAME_DESTINATION}
									autoFocus={false}
									onFocus={this.onFocus}
									autoComplete="off"
									placeholder="Add a stop"
									refrence={this.locationSearchBoxDestinationInput}
								/>
							</div>
						) : null}
					</div>
				</DragDropContext>
			</div>
		);
	}
}

const mapStateToProps = state => ({
    ...state.search,
    common: state.common,
    map: state.map,
    booking: state.booking,
    savedPlaces: state.savedPlaces,
});

export default connect(mapStateToProps)(LocationSearchBooking);