import React from 'react';

const classNames = require('classnames');

export default function Lozenge({ title, color }) {
    return (
        <div className="lozenge-container">
            {color && <div className={classNames("lozenge-inner", `color-${color}`)}>
                {title && <div className="lozenge-title">
                    <span>{title}</span>
                </div>}
            </div>}
        </div>
    );
}