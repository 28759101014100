import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";

import { fetchUpdatedJob, jobChatHistoryWillUnMount, onletsGetStarted } from "../../../../utils/redux/actions/jobsActions";
import { setCubeNumber } from "../../../../utils/redux/actions/jobsActions/jobsChangeState";
import { PARTNER_KEY_JOB } from "../../../../utils/redux/actions/jobsActions/jobsConstant";
import {
	getMessagesWillUnmount,
	jobChatDetailModal,
	jobChatDetailCancel,
	chatOnAttachFileWithNewService,
	setIntervalPartnerLiveLocation,
	onExtendJob,
	onCloseLivePartnerLocation,
} from "../../../../utils/redux/actions/chatActions";
import { onNeverMind, chatWillUnmount, noteToPartner, cannotRequestMk } from "../../../../utils/redux/actions/chatActions/chatChangeState";
import { addAnimateToText } from "../../../../utils/redux/actions/commonActions";
import {
	changeTimeFormat,
	storePathName,
	xenditMinLimitBottomSheet,
} from "../../../../utils/redux/actions/commonActions/commonChangeState";
import { convertTime } from "../../../../utils/functions/job-time-convert";

import JobChatHistoryExtendSheetContent from "../JobChatHistoryExtendSheetContent/JobChatHistoryExtendSheetContent";
import JobChatHistoryDetailSheetContent from "../JobChatHistoryDetailSheetContent/JobChatHistoryDetailSheetContent";
import BottomSheet from "../../../ui-elements/BottomSheet/BottomSheet";
import DynamicIcon from "../../../ui-elements/DynamicIcon/DynamicIcon";
import JobChatHistoryCancelModal from "../JobChatHistoryCancelModal/JobChatHistoryCancelModal";
import JobChatHistoryNoteToPartner from "../JobChatHistoryNoteToPartner/JobChatHistoryNoteToPartner";
import TitleCard from "../../../ui-elements/TitleCard";
import JobChatHistoryLiveLocation from "../JobChatHistoryLiveLocation/JobChatHistoryLiveLocation";
import  FetchingLoading from "../../../chat-elements/loading/loading";
import CustomButton from "../../../ui-elements/CustomButton/CustomButton";

import backButton from "../../../../v5-icons/back-button.svg";
import {
	togglePaymentMethodsBottomSheet,
	toggleTopUpBottomSheet,
} from "../../../../utils/redux/actions/bookingActions/bookingChangeState";
import BookingPaymentBottomSheet from "../../../Booking/BookingPayment/BookingPaymentBottomSheet";
import TopUpBottomSheet from "../../../TopUp/TopUpBottomSheet";
import { billingXenditTopUpMyKoin } from "../../../../utils/redux/actions/billingActions";
import { parse } from "query-string";
import { XenditMinLimitBottomSheet } from "../../../xenditMinLimitBottomSheet/XenditMinLimitBottomSheet";
import { requestExtendJob } from "../../../../utils/redux/actions/bookingActions";
class JobChatHistoryMenuPage extends Component {
	inputFileRef = React.createRef(); // for reffering to input file for selecting file

	constructor(props) {
		super(props);

		this.state = {
			innerWidth: null,
			hasFadePropTitle: false
		};
	}

	componentDidMount() {
		this.setState(() => ({
			innerWidth: window.innerWidth,
		}));
		
		let channelId = "";
		if (this.props.match) {
			channelId = this.props.match.params.id;
		} else {
			channelId = this.props.jobId;
		}
		if(_.isEmpty(this.props.job)) {
			this.props.dispatch(fetchUpdatedJob(channelId))
				.then((res) => {
					if(res) {
						this.addAnimationOnTitle();
						this.props.dispatch(setIntervalPartnerLiveLocation(channelId));

						// const params = parse(window.location.search);
						// if (_.has(params, "retryWithTopUp") || _.has(params, "retryWithCard")) {
						// 	this.handleChangeJobExtensionBottomSheet(false)
						// }
					}
				})
		} else {
			this.props.dispatch(setCubeNumber(this.props.job.jobTime.cubeNumbers));
			this.props.dispatch(changeTimeFormat(convertTime(this.props.job.jobTime.total.value)));
			this.props.dispatch(setIntervalPartnerLiveLocation(channelId));
			this.addAnimationOnTitle();

			// const params = parse(window.location.search);
			// if (_.has(params, "retryWithTopUp") || _.has(params, "retryWithCard")) {
			// 	this.handleChangeJobExtensionBottomSheet(false)
			// }
		}
	}

	addAnimationOnTitle () {
		const containerText = document.querySelector(".long-heading");
		const innerText = document.querySelector(".long-heading .heading");
		this.props.dispatch(addAnimateToText(containerText, innerText))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropTitle: true,
					}));
				}
			}).catch((err) => {});
	}

	componentWillUnmount() {
		this.props.dispatch(jobChatHistoryWillUnMount());
		this.props.dispatch(chatWillUnmount());
		this.props.dispatch(getMessagesWillUnmount());
		this.props.dispatch(onCloseLivePartnerLocation());
	}

	inputFileChange = (e) => {
		this.props.dispatch(chatOnAttachFileWithNewService(this.inputFileRef.current.files[0]));
	};

	handleChangeNotePartnerBottomSheet = (isOpen) => {
		if (!isOpen) this.props.dispatch(noteToPartner());
	};

	handleChangeJobDetailBottomSheet = (isOpen) => {
		if (!isOpen) this.props.dispatch(jobChatDetailModal());
	};

	handleChangeJobExtensionBottomSheet = (isOpen) => {
		if(document.getElementsByTagName("body")[0].classList.contains("fixed-body")) {
			document.getElementsByTagName("body")[0].classList.remove("fixed-body");
		}
		if (!isOpen) this.props.dispatch(onExtendJob());
	};

	handleChangeCancelJobBottomSheet = (isOpen) => {
		if (!isOpen) this.props.dispatch(onNeverMind());
	};

	navigateToSupport = () => {
		this.props.dispatch(push("/support"));
	}

	checkJobState() {
		if (this.props.jobState !== "Started") {
			this.props.dispatch(cannotRequestMk());
		} else {
			this.props.dispatch(
				push(`/jobs/${this.props.match.params.id ? this.props.match.params.id : this.props.job.id}/chat/history-transactions`),
			);
		}
	}

	// completePaymentMethod = () => {
	// 	this.props.dispatch(togglePaymentMethodsBottomSheet());
	// 	this.props.dispatch(storePathName(`/jobs/${this.props.job.id}/chat/menu?retryWithCard`))
	// 	this.props.dispatch(push("/account/billing/add"));
	// };
	//
	// setTopUpValue = () => {
	// 	this.props.dispatch(billingXenditTopUpMyKoin(parseInt(this.props.booking.topUpMyKoinAmount), `jobs/${this.props.job.id}/chat/menu`, this.props.common.client.currency.unit, "retryWithTopUp"));
	// };
	//
	// xenditTopup200Peso = () => {
	// 	this.props.dispatch(requestExtendJob())
	// 	this.props.dispatch(xenditMinLimitBottomSheet(false));
	// }

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		const bottomSheetConfig = { topShadow: false, overlay: true, overflowHeight: 0 };
		return (
			<Fragment>
				{_.isNull(this.props.map.googlePartnerMarkers) ? (
					<div className="live-location-loading">
						<FetchingLoading />
					</div>
				) : null}
				{!_.isEmpty(this.props.job) ? (
					<Fragment>
						<div className="chat-menu-title-bar chat-menu-title-bar-container">
							<TitleCard
								leftIcon={backButton}
								handleClickLeftIcon={(e) => this.props.dispatch(onletsGetStarted())}
								lozengeObject={{
									title:
										this.props.jobState === "Started"
											? "In Progress"
											: this.props.jobState === "Queued" && this.props.job.product.mode.includes("MY_MALL")
											? "MATCHED"
											: this.props.jobState,
									color: this.props.jobState === "Started" ? "green" : "orange",
									longHeading: this.props.job.product.commercialName,
								}}
								classList="has-long-heading"
								hasFadeProp={this.state.hasFadePropTitle}
							/>
						</div>
						<div className="Job_Chat_History_Menu_Box">
							{this.props.job.note && (
								<div className="J_C_H_M_B_Row" onClick={(e) => this.props.dispatch(noteToPartner())}>
									<div className="J_C_H_M_B_Row_Icon">
										<DynamicIcon icon="Placeholder" classes="J_C_H_B_Row_Icon_Img" />
									</div>
									<div className="J_C_H_M_B_Row_Text">
										<h5>See note to partner</h5>
									</div>
								</div>
							)}
							{this.props.job.product.mode.includes("MY_MALL") && this.props.jobState !== "Queued" && (
								<div className="J_C_H_M_B_Row" onClick={(e) => this.props.dispatch(onExtendJob())}>
									<div className="J_C_H_M_B_Row_Icon">
										<DynamicIcon icon="Clock" classes="J_C_H_B_Row_Icon_Img" />
									</div>
									<div className="J_C_H_M_B_Row_Text">
										<h5>Extend time for this job</h5>
									</div>
								</div>
							)}
							{!this.props.job.product.mode.includes("MY_MALL") && (
								<div className="J_C_H_M_B_Row" onClick={(e) => this.props.dispatch(onExtendJob())}>
									<div className="J_C_H_M_B_Row_Icon">
										<DynamicIcon icon="Clock" classes="J_C_H_B_Row_Icon_Img" />
									</div>
									<div className="J_C_H_M_B_Row_Text">
										<h5>Extend time for this job</h5>
									</div>
								</div>
							)}
							<div className="J_C_H_M_B_Row" onClick={(e) => this.props.dispatch(jobChatDetailModal())}>
								<div className="J_C_H_M_B_Row_Icon">
									<DynamicIcon icon="SeeJob" classes="J_C_H_B_Row_Icon_Img" />
								</div>
								<div className="J_C_H_M_B_Row_Text">
									<h5>See job details</h5>
								</div>
							</div>
							{/*<div className="J_C_H_M_B_Row" onClick={(e) => this.checkJobState()}>*/}
							{/*	<div className="J_C_H_M_B_Row_Icon">*/}
							{/*		<DynamicIcon icon="MyKoin" classes="J_C_H_B_Row_Icon_Img mykoin-icon" />*/}
							{/*	</div>*/}
							{/*	<div className="J_C_H_M_B_Row_Text">*/}
							{/*		<h5>Transfer Money</h5>*/}
							{/*	</div>*/}
							{/*</div>*/}
							<div className="J_C_H_M_B_Row" onClick={(e) => this.props.dispatch(push("/support"))}>
								<div className="J_C_H_M_B_Row_Icon">
									<DynamicIcon icon="voiceIcon" classes="J_C_H_B_Row_Icon_Img" />
								</div>
								<div className="J_C_H_M_B_Row_Text">
									<h5>Get help now</h5>
								</div>
							</div>
							{this.props.jobState !== "Started" && this.props.jobState !== "Completed" && (
								<div className="Cancel_Btn_Box" onClick={(e) => this.props.dispatch(onNeverMind())}>
									<Button className="Cancel_Job_Button">Cancel Job</Button>
								</div>
							)}
							{this.props.chat.chatNoteToPartner && (
								<BottomSheet
									isOpen={this.props.chat.chatNoteToPartner}
									styles={bottomSheetStyle}
									classes="Chat_Note_To_Partner"
									config={bottomSheetConfig}
									indicator={true}
									onChangeOverlay={this.handleChangeNotePartnerBottomSheet}
								>
									<JobChatHistoryNoteToPartner
										inputFileChange={this.inputFileChange}
										onCloseNotePartner={(e) => this.props.dispatch(noteToPartner())}
										inputFileCameraChange={this.inputFileCameraChange}
										handleClickGotItButton={() => this.props.dispatch(noteToPartner())}
									/>
								</BottomSheet>
							)}

							{this.props.chat.chatCancelModal && (
								<BottomSheet
									isOpen={this.props.chat.chatCancelModal}
									styles={bottomSheetStyle}
									classes="cancel-job-bottom-sheet"
									config={bottomSheetConfig}
									indicator={true}
									onChangeOverlay={this.handleChangeCancelJobBottomSheet}
								>
									<JobChatHistoryCancelModal navigateToSupport={this.navigateToSupport} />
								</BottomSheet>
							)}

							{this.props.chat.chatExtendModal && (
								<BottomSheet
									isOpen={this.props.chat.chatExtendModal}
									styles={bottomSheetStyle}
									classes="Chat_Extend_Job"
									config={bottomSheetConfig}
									indicator={true}
									onChangeOverlay={this.handleChangeJobExtensionBottomSheet}
								>
									<JobChatHistoryExtendSheetContent />
								</BottomSheet>
							)}

							{this.props.chat.chatJobDetailModal && (
								<BottomSheet
									isOpen={this.props.chat.chatJobDetailModal}
									styles={bottomSheetStyle}
									classes="Chat_Extend_Job"
									indicator={true}
									config={bottomSheetConfig}
									onChangeOverlay={this.handleChangeJobDetailBottomSheet}
								>
									<JobChatHistoryDetailSheetContent onCancelDetail={(e) => this.props.dispatch(jobChatDetailCancel())} />
								</BottomSheet>
							)}

							{!this.props.chat.canRequestMk && (
								<BottomSheet
									classes="add-transaction-bottom-sheet"
									isOpen={!this.props.chat.canRequestMk}
									styles={bottomSheetStyle}
									config={{ topShadow: false }}
									indicator={true}
									onChangeOverlay={(isOpen) => {
										if (!isOpen) this.props.dispatch(cannotRequestMk());
									}}
								>
									<div className="bottom-sheet-info">
										<div className="heading">
											<DynamicIcon icon="ErrorKuya" classes="sad-icon" />
											<h2>Sorry po</h2>
										</div>
										<p>You can only send/request money after job gets started.</p>
										<CustomButton title="Got It" onClick={() => this.props.dispatch(cannotRequestMk())} />
									</div>
								</BottomSheet>
							)}

						{/*	{this.props.booking.isOpenPaymentMethodsBottomSheet && (*/}
						{/*		<BottomSheet*/}
						{/*			isOpen={this.props.booking.isOpenPaymentMethodsBottomSheet}*/}
						{/*			styles={bottomSheetStyle}*/}
						{/*			classes="payment-method-bottom-sheet"*/}
						{/*			config={{ topShadow: false }}*/}
						{/*			indicator={true}*/}
						{/*			onChangeOverlay={(isOpen) => {*/}
						{/*				if (!isOpen) this.props.dispatch(togglePaymentMethodsBottomSheet());*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			{!_.isNull(this.props.billing.balance) ? (*/}
						{/*				<BookingPaymentBottomSheet*/}
						{/*					onAddMethod={this.completePaymentMethod}*/}
						{/*					paymentMethods={this.props.billing.methods}*/}
						{/*					insufficientBalance={this.props.billing.balance[0].balance <= 0}*/}
						{/*				/>*/}
						{/*			) : null}*/}
						{/*		</BottomSheet>*/}
						{/*	)}*/}

						{/*	<BottomSheet*/}
						{/*		classes="topup-mykoin-bottom-sheet"*/}
						{/*		isOpen={this.props.booking.isShowTopUpBottomSheet}*/}
						{/*		styles={bottomSheetStyle}*/}
						{/*		config={{ topShadow: false }}*/}
						{/*		indicator={true}*/}
						{/*		onChangeOverlay={(isOpen) => {*/}
						{/*			if (!isOpen) this.props.dispatch(toggleTopUpBottomSheet());*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		<TopUpBottomSheet setTopUpValue={this.setTopUpValue} />*/}
						{/*	</BottomSheet>*/}
						{/*</div>*/}

						{/*{this.props.common.xenditMinLimitBottomSheet ? (*/}
						{/*	<BottomSheet*/}
						{/*		isOpen={this.props.common.xenditMinLimitBottomSheet}*/}
						{/*		styles={bottomSheetStyle}*/}
						{/*		config={{ topShadow: false }}*/}
						{/*		indicator={true}*/}
						{/*		onChangeOverlay={(isOpen) => {*/}
						{/*			if (!isOpen) this.props.dispatch(xenditMinLimitBottomSheet(false));*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		<XenditMinLimitBottomSheet xenditTopup200Peso={this.xenditTopup200Peso} />*/}
						{/*	</BottomSheet>*/}
						{/*) : null}*/}
						</div>
					</Fragment>
				) : null}
				<JobChatHistoryLiveLocation viewFullScreen markers={this.props.map.googlePartnerMarkers} />
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
	map: state.map,
	booking: state.booking,
	billing: state.billing
});

export default connect(mapStateToProps)(JobChatHistoryMenuPage);
