import React from "react";
import ContentLoader from "react-content-loader";

const ChatSkeleton = (props) => (
	<ContentLoader
		speed={3}
		width={props.devicewidth}
		height={props.deviceheight}
		viewBox={`0 0 ${props.devicewidth} ${props.deviceheight}`}
		backgroundColor="rgba(217,217,217,0.9)"
		foregroundColor="rgba(237,237,237,0.9)"
		{...props}
	>
		<circle cx="28" cy="39" r="18" />
		<rect x="58" y="20" rx="5" ry="5" width={`${props.devicewidth - 80}`} height="10" />
		<rect x="58" y="39" rx="5" ry="3" width={`${props.devicewidth - 110}`} height="10" />

		{/* <rect x="20" y="12" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="29" rx="5" ry="5" width="220" height="10" /> */}
		{/* <rect x={`${props.devicewidth - 240}`} y="59" rx="5" ry="5" width="220" height="10" /> */}
		{/* <rect x={`${props.devicewidth - 240}`} y="76" rx="5" ry="5" width="220" height="10" /> */}
		<rect x="20" y="104" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="122" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="161" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="178" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="211" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="231" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="270" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="287" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="326" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="343" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="382" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="399" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="438" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="455" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="494" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="511" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="550" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="567" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="606" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="623" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="662" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="679" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="718" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="735" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="774" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="791" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="830" rx="5" ry="5" width="220" height="10" />
		<rect x={`${props.devicewidth - 240}`} y="847" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="886" rx="5" ry="5" width="220" height="10" />
		<rect x="20" y="903" rx="5" ry="5" width="220" height="10" />
	</ContentLoader>
);

export default ChatSkeleton;
