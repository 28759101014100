import $ from 'jquery';
import _ from "lodash";

import * as DISPATCH_STATE from '../mapChangeState';
import * as CONSTANT_ACTIONS from '../mapConstant';
import { addUserLocationToPlace, createPlaceGoogleSuggestionsFromRawPlace } from '../../../../functions/create-place-object-from-google-suggestions';
import { getDetailPlaces } from '../../../../functions/nearby-search';
import { mapSetCurrentLocationLocalstorage } from './map-current-location';
import { savedPlaceSelectedAddress } from '../../savedPlacesActions';
import { LOCATION_INPUT_BOX_NAME_SOURCE, LOCATION_INPUT_BOX_NAME_DESTINATION } from '../../../../constants/constant';
import { selectSavedPlacesAddress, savedPlaceSelectMap } from "../../savedPlacesActions/savedPlacesChangeState";
import { appLoadingBegin } from '../../commonActions/commonChangeState';
import { createGoogleMap } from "./map-initialize";
import { setLocation, createMarker } from "./map-marker";

/**
 * When user search by google places api and then select on item in result we dispatch current location storage and others
 * @param {*} i 
 * @param {*} ref 
 */
export const mapInputSelectAddress = (i, ref, savedPlace = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let place;
            if (savedPlace) {
                ref.current.value = savedPlace.address;
                place = savedPlace;
                dispatch(changeInputOfSearchBox(ref, place));
                resolve(place);
            } else {
                getDetailPlaces(getState, getState().map.googleSearchPlaces[ i ][ 'place_id' ], (res) => {
                    if (!_.isNull(res)) {
                        ref.current.value = res?.["name"];
                        place = createPlaceGoogleSuggestionsFromRawPlace(res, getState().map.googleSearchPlaces[i]['description']);
                        dispatch(changeInputOfSearchBox(ref, place));
                        resolve(place);
                    } 
                })
            }
        })
    }
};
/**
 * change input value of search boxs
 * @param {*} ref 
 * @param {*} place 
 */
export const changeInputOfSearchBox = (ref, place) => {
    return (dispatch, getState) => {
        if (LOCATION_INPUT_BOX_NAME_SOURCE === ref.current.name) {
            dispatch(mapSetCurrentLocationLocalstorage(place));
            dispatch({
                type: CONSTANT_ACTIONS.MAP_INPUT_SELECT_ADDRESS,
                payload: addUserLocationToPlace(getState, place)
            });
            dispatch(DISPATCH_STATE.addFirstStop());
        } else if (LOCATION_INPUT_BOX_NAME_DESTINATION === ref.current.name) {
            dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination([ place ]));
        }
        dispatch(smoothScrollToTop());
    }
}

export const smoothScrollToTop = () => {
    return (dispatch) => {
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        dispatch(DISPATCH_STATE.smoothScrollToTopDispatch());
    }
}

/**
 * when user select of address from suggestion for choosing saved place
 */
export const mapSelectSavedPlacesAddress = (i) => {
    return (dispatch, getState) => {
        getDetailPlaces(getState, getState().map.googleSearchPlaces[i]["place_id"], (res) => {
			if (!_.isNull(res)) {
				let place = createPlaceGoogleSuggestionsFromRawPlace(res, getState().map.googleSearchPlaces[i]["description"]);
                dispatch(savedPlaceSelectedAddress(place));
                dispatch(DISPATCH_STATE.setGooglePlaceId(getState().map.googleSearchPlaces[i]["place_id"]));
				setTimeout(() => {
					dispatch(createGoogleMap(true, getState().savedPlaces.savedPlace));
					dispatch(DISPATCH_STATE.mapFinishSetLeftTop());
				}, 500);
			}
		});
    }
}

/**
 * When user search by google places api and then select on item in result we put it in specific location source or stop
 * @param {*} i 
 * @param {*} ref 
 */
export const mapSelectAddressFromSuggestions = (i, ref, index, savedPlace = null) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let place;
            if (savedPlace) {
                ref.current.value = savedPlace.address;
                place = savedPlace;
                dispatch(changeInputOfSearchBoxOfAddDestination(index, place));
                resolve(place);
            } else {
                getDetailPlaces(getState, getState().map.googleSearchPlaces[ i ][ 'place_id' ], (res) => {
                    if(!_.isNull(res)) {
                        // ref.current.value = res[ 'name' ];
                        ref.current.value = getState().map.googleSearchPlaces[i]["description"];
                        place = createPlaceGoogleSuggestionsFromRawPlace(res, getState().map.googleSearchPlaces[i]["description"]);
                        dispatch(changeInputOfSearchBoxOfAddDestination(index, place));
                        resolve(place); 
                    }
                })
            }
        });
    }
};
/**
 * When user search by google places api and then select on item in result we pan map to the selected location
 * @param {*} i //index
 */
export const mapGetAddressFromSuggestions = (i) => {
    return (dispatch, getState) => {
        getDetailPlaces(getState, getState().map.googleSearchPlaces[i]["place_id"], (res) => {
			if (!_.isNull(res)) {
				let place = createPlaceGoogleSuggestionsFromRawPlace(res, getState().map.googleSearchPlaces[i]["description"]);
                    //dispatch(DISPATCH_STATE.mapInputSelectAddressDispatch(place)); //update location  props
                    //dispatch(DISPATCH_STATE.setGooglePlaceObject(place));
                    dispatch(DISPATCH_STATE.setGooglePlaceId(getState().map.googleSearchPlaces[i]["place_id"]));
                setTimeout(() => {
					dispatch(createGoogleMap(true, place));
                    dispatch(DISPATCH_STATE.mapFinishSetLeftTop());
				}, 500);
			}
		});
    }
}

/**
 * 
 * @param {*} index 
 * @param {*} place 
 */
export const changeInputOfSearchBoxOfAddDestination = (index, place) => {
    return (dispatch, getState) => {
        if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_CURRENT) {
            // Consider selected place as a default location
            // dispatch(mapSetCurrentLocationLocalstorage(place));
            dispatch(DISPATCH_STATE.mapInputSelectAddressDispatch(addUserLocationToPlace(getState, place)))
        } else if (index === CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_NEW) {
            let places = getState().map.googlePlaces;
            places.push(place);
            dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
        } else if (index => CONSTANT_ACTIONS.MAP_GOOGLE_PLACE_DESTINATION_SELECT_INDEX_DESTINATION) {
            let places = getState().map.googlePlaces;
            const { googlePlaceIndex } = getState().map;
            console.clear();
            places[googlePlaceIndex-1] = place;
            console.log("yohooo", googlePlaceIndex, places, places[googlePlaceIndex - 1], place);
            // console.log("yohoooo2", places)
            // places[ index - 1 ] = place;
            dispatch(DISPATCH_STATE.mapInputSelectAddressFirstDestination(places));
        }
        // dispatch(appGoBack());
    }
}

/**
 * when user select of address from suggestion for choosing Bookmarked place
 */
export const mapSelectBookmarkedAddress = (i) => {
    return (dispatch, getState) => {
        dispatch(appLoadingBegin());
        return new Promise((resolve, reject) => {
            getDetailPlaces(getState, getState().map.googleSearchPlaces[i]['place_id'], (res) => {
                let place = createPlaceGoogleSuggestionsFromRawPlace(res, getState().map.googleSearchPlaces[i]["description"]);
                dispatch(selectSavedPlacesAddress(place));
                resolve(place);
            });
        })
    }
};

/**
 * Update google places when client start dragging
 */
export const updateGooglePlaces = (sourceIndex, destIndex, draggableId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let clonnedGooglePlaces = getState().map.googlePlaces;
            if (destIndex === -1) {
                const draggedItem = clonnedGooglePlaces[draggableId];
                const currentLocation = getState().map.location;
                
                clonnedGooglePlaces.splice(sourceIndex, 1);
                clonnedGooglePlaces.splice(0, 0, currentLocation);
                dispatch(DISPATCH_STATE.changeCurrentLocation(draggedItem));
                // dispatch(mapSetCurrentLocationLocalstorage(draggedItem));
            } else if(sourceIndex === -1) {
                const currentLocation = getState().map.location;
                const draggedItem = currentLocation;
                const newLocation = clonnedGooglePlaces[destIndex];

                clonnedGooglePlaces.splice(destIndex, 1)
                clonnedGooglePlaces.splice(destIndex, 0 ,draggedItem);
                dispatch(DISPATCH_STATE.changeCurrentLocation(newLocation));
                // dispatch(mapSetCurrentLocationLocalstorage(newLocation));
            } else {
                const draggedItem = clonnedGooglePlaces[draggableId];
                dispatch(DISPATCH_STATE.dragGooglePlaceItemBegin(draggedItem));

                clonnedGooglePlaces.splice(sourceIndex, 1);   
                clonnedGooglePlaces.splice(destIndex, 0, draggedItem);
            }
            
            dispatch(DISPATCH_STATE.dragGooglePlaceItemEnd(clonnedGooglePlaces));
            resolve();
        });
    };
};

export const confirmSelectedAddress = (param, match={}, isSearching, searchPointer) => {
    return (dispatch, getState) => {
        switch (param) {
			case "/saved-places/search":
                dispatch(savedPlaceSelectMap());
                dispatch(createMarker(param, isSearching));
				break;
			case "/map":
				dispatch(setLocation({}, isSearching, searchPointer));
				break;
            case `/booking/${match.id}/map`:
                dispatch(createMarker(match, isSearching, searchPointer));
                break;
            default:
                break;
		}
    }
}