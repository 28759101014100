import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import backButton from "../../../v5-icons/back-button.svg";
import { ReactComponent as BankCard } from "../../../v5-icons/bank-card.svg";

import TitleBar from "../../../organisms/title-bar";
import RequestMK from "../ReguestMK/RequestMK";
import SendMK from "../SendMK/SendMK";
import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";

import { backToHistoryTransactionPage } from "../../../utils/redux/actions/chatActions";
import { getPrimaryPayment } from "../../../utils/redux/actions/commonActions/commonChangeState";
import { fetchMethodsOfPayment } from "../../../utils/redux/actions/billingActions";
class AddTransactions extends Component {
	componentDidMount() {
        this.props.dispatch(fetchMethodsOfPayment())
            .then((res) => {
                if (res.data.data.paymentMethods.totalItems > 0) {
					const primaryPayment = res.data.data.paymentMethods.items.filter((payment) => payment.isPrimary === true);
					this.props.dispatch(getPrimaryPayment(primaryPayment));
				}
            })
	}

    render() {
        const tabList = ["Send", "Request"];
        const jobId = !_.isEmpty(this.props.job) ? this.props.job.id : this.props.match.params.id;
		return (
			<div className="add-transaction-container">
				<TitleBar leftIcon={backButton} handleClickLeftIcon={(e) => this.props.dispatch(backToHistoryTransactionPage(jobId))} />

				<div className="add-transaction">
					<Tabs selectedIndex={this.props.selectedIndex} selectedTabClassName={"segment-active"}>
						<TabList className="tabs-menu-segment">
							{tabList.map((item, idx) => (
								<Tab className="item-segment" key={idx}>
									{item}
								</Tab>
							))}
						</TabList>
						<div className="tabs-panel-container">
							{!_.isNull(this.props.balance) &&
								(!_.isNull(this.props.common.primaryPayment) ? (
									<>
										<div className="current-payment-methods-wrapper">
											<div className="current-payment">
												<div className="icon">
													<DynamicIcon icon="MyKoin" classes="icon-mk" />
												</div>
												<p>mykoins Balance</p>
												<span>{this.props.balance[0].availableDisplayValue}</span>
											</div>
											<div className="current-payment">
												<div className="icon">
													<img
														src={this.props.common.primaryPayment[0].icon}
														alt={this.props.common.primaryPayment[0].type}
													/>
												</div>
												{this.props.common.primaryPayment[0].type !== "GCash" ? (
													<>
														<p>Primary Card</p>
														<span>**** {this.props.common.primaryPayment[0].cardNumber}</span>
													</>
												) : (
													<>
														<p>Payment method</p>
														<span>{this.props.common.primaryPayment[0].name}</span>
													</>
												)}
											</div>
										</div>
										<div className="hint">
											<p>
												In case of insufficient mykoins balance, your{" "}
												{this.props.common.primaryPayment[0].type === "GCash" ? "GCash " : "card "}
												will be charged.
											</p>
										</div>
									</>
								) : (
									<>
										<div className="current-payment-methods-wrapper">
											<div className="current-payment">
												<div className="icon">
													<DynamicIcon icon="MyKoin" classes="icon-mk" />
												</div>
												<p>mykoins Balance</p>
												<span>{this.props.balance[0].availableDisplayValue}</span>
											</div>
											<div className="current-payment">
												<div className="icon">
													<BankCard />
												</div>
												<p>Primary Card</p>
												<span>None</span>
											</div>
										</div>
										<div className="hint">
											<p>In case of insufficient mykoins balance, your card will be charged.</p>
										</div>
									</>
								))}
							<TabPanel>
								<SendMK jobId={jobId} />
							</TabPanel>
							<TabPanel>
								<RequestMK jobId={jobId} />
							</TabPanel>
						</div>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.jobs,
    common: state.common,
    chat: state.chat,
	balance: state.billing.balance
});

export default connect(mapStateToProps)(AddTransactions);
