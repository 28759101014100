import React, {Fragment, Component} from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "connected-react-router";

import TitleBar from "../../../organisms/title-bar";
import chatSupport from "../../../v5-icons/chat-support.svg";
import MyKoinBalance from "./PaymentBalance/MyKoinBalance";
import PaymentMethod from "./PaymentBalance/PaymentMethod";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import BillingInformationGcash from "../../Account/Billing/BillingInformation/BillingGCash/BillingInformationGcash";

import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import {
	toggleTopUpBottomSheet,
	togglePaymentMethodsBottomSheet,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { billingRequestForChagnePrimary, billingDeleteRequestPaymentMethod } from "../../../utils/redux/actions/billingActions";
import { toggleGcashBottomSheet, toggleVerifyingGcashBottomSheet } from "../../../utils/redux/actions/billingActions/billingChangeState";
class BookingPaymentBottomSheet extends Component {
	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};
	onChangePrimary = (e, id) => {
		this.props.dispatch(billingRequestForChagnePrimary(id)).then((res) => {
			this.props.dispatch(togglePaymentMethodsBottomSheet());
		});
	};
	onDelete = (e, id) => {
		this.props.dispatch(billingDeleteRequestPaymentMethod(id));
	};

	getCurrentPrice = () => {
		if (window.location.pathname.includes("menu")) {
			return this.priceValue(this.props.booking.prices, this.props.jobs.job.jobTime.cubeNumbers, this.props.jobs.cubeNumber)
		} else if (window.location.pathname.includes("jobs")) {
			return this.props.chat.transferRequested
		} else if (window.location.pathname.includes("review")) {
			return this.props.booking.prices.items[this.props.booking.prices.minCubeNumbers]?.price?.value
		}
	}

	priceValue = (prices, cubeNumber, currentPrice) => {
		let priceCurrent = prices.items[currentPrice];
		let initialPrice = prices.items[cubeNumber];
		return priceCurrent.price.value - initialPrice.price.value;
	};

	render() {

		return (
			<Fragment>
				<TitleBar rightIcon={chatSupport} title="Payment Methods" handleClickRightIcon={this.onSupport} />
				<div className="payment-methods-container">
					<MyKoinBalance
						showTopUpBottomSheet={() => {
							this.props.dispatch(togglePaymentMethodsBottomSheet());
							this.props.dispatch(toggleTopUpBottomSheet());
						}}
						balance={this.props.balance}
						isMymall={this.props.booking?.product?.mode?.includes("MY_MALL")}
						price={this.getCurrentPrice()}
					/>

					{this.props.isGcashSupported ? (
						<BillingInformationGcash
							onChangePrimary={this.onChangePrimary}
							onDelete={this.onDelete}
							toggleGcashBottomSheet={() => {
								this.props.dispatch(togglePaymentMethodsBottomSheet());
								this.props.dispatch(toggleGcashBottomSheet());
							}}
							toggleVerifyingGcashBottomSheet={() => {
								if (this.props.booking.isOpenPaymentMethodsBottomSheet) {
									this.props.dispatch(togglePaymentMethodsBottomSheet());
								}
								this.props.dispatch(toggleVerifyingGcashBottomSheet());
							}}
							isBottomSheet
						/>
					) : null}

					{!_.isNull(this.props.methods) && this.props.methods.length ? <PaymentMethod onChangePrimary={this.onChangePrimary} /> : null}
				</div>
				<div className="billing-information-add">
					<div data-tut="tour-add-card">
						<CustomButton type="button" title="Add a new bank card" onClick={this.props.onAddMethod} />
					</div>
				</div>
				{/*<p className="tip">*/}
				{/*	<span role="img" aria-label="emoji">*/}
				{/*		💡*/}
				{/*	</span>*/}
				{/*	Free cancellation until the job starts*/}
				{/*</p>*/}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.billing,
	common: state.common,
	booking: state.booking,
	chat: state.chat,
	jobs: state.jobs
});

export default connect(mapStateToProps)(BookingPaymentBottomSheet);