import * as CONSTANT_ACTIONS from './chatConstant';
import { SET_JOB_CANCEL_REASONS } from "./chatConstant";

/**
 * Chat will unmount
 */
export const chatWillUnmount = () => ({
    type: CONSTANT_ACTIONS.CHAT_WILL_UNMOUNT
});
/**
 * When user click on nevermind on menu option in chat page
 */
export const onNeverMind = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_NEVERMIND_CANCEL
})
export const noteToPartner = () => ({
    type: CONSTANT_ACTIONS.CHAT_NOTE_TO_PARTNER
})
/**
 * When user click on extend job on menu option in chat page
 */
export const onExtendJobChangeState = () => ({
	type: CONSTANT_ACTIONS.CHAT_ON_EXTEND_JOB_CHANGE_STATE,
});
/**
 * When click on a photo in chat list
 * @param {*} photo 
 */
export const onShowPhoto = (photo) => ({
    type: CONSTANT_ACTIONS.CHAT_ON_PHOTO_MESSAGE,
    payload: photo
})
/**
 * When click somewhere on screen for close image show
 */
export const closePhotoShow = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_CLOSE_PHOTO_MESSAGE
})

export const chatScrollToBottomDispatch = () => ({
    type: CONSTANT_ACTIONS.CHAT_SCROLL_TO_BOTTOM
});

export const initMessagesFromJobChatHistoryDispatch = (messages) => ({
    type: CONSTANT_ACTIONS.INIT_MESSAGES_FROM_JOB_CHAT_HISTORY,
    payload: messages
});
/**
 * When user click job detail on option menu in chat page
 */
export const onJobDetail = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_JOB_DETAIL_NAVIGATION,
})

export const chatOnLiveLocationPartner = (interval) => ({
    type: CONSTANT_ACTIONS.CHAT_ON_LIVE_LOCATION_PARTNER,
    payload: interval
})

export const chatOnLiveLocationPartnerClose = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_LIVE_LOCATION_PARTNER_CLOSE
})

export const chatOnCallPartner = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_CALL_PARTNER
})

export const requestJobCancelBegin = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_BEGIN
})

export const requestJobCancelReasonsBegin = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_REASONS_BEGIN
})

export const requestJobCancelSuccess = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_SUCCESS
})

export const requestJobCancelReasonsSuccess = () => ({
    type: CONSTANT_ACTIONS.REQUEST_JOB_CANCEL_REASONS_SUCCESS
})

export const initMessagesDispatch = (messages) => ({
    type: CONSTANT_ACTIONS.INIT_MESSAGES_DISPATCH,
    payload: messages
})

export const updateMessageList = (messages) => ({
    type: CONSTANT_ACTIONS.UPDATE_MESSAGE_LIST,
    payload: messages
})

export const initIntervalGetMessage = (interval) => ({
    type: CONSTANT_ACTIONS.INIT_INTERVAL_GET_MESSAGE,
    payload: interval
})

export const getMessageWillUnMountDispatch = () => ({
    type: CONSTANT_ACTIONS.GET_MESSAGE_WILL_UNMOUNT_DISPATCH
})

export const setLastIndex = (lastIndex) => ({
    type: CONSTANT_ACTIONS.SET_LAST_INDEX,
    payload: lastIndex
})

export const setLastConsumeIndex = (lastConsumedIndex) => ({
    type: CONSTANT_ACTIONS.SET_LAST_CONSUME_INDEX,
    payload: lastConsumedIndex
})

export const setOffset = (offset) => ({
    type: CONSTANT_ACTIONS.SET_OFFSET,
    payload: offset
})

export const setLimit = (limit) => ({
    type: CONSTANT_ACTIONS.SET_LIMIT,
    payload: limit
})

export const fetchMessagesSuccess = (data) => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_SUCCESS,
    payload: data
})

export const sendMessageBegin = () => ({
    type: CONSTANT_ACTIONS.SEND_MESSAGE_BEGIN
})

export const putNewMessageToMessagesArray = (messages) => ({
    type: CONSTANT_ACTIONS.PUT_NEW_MESSAGE_TO_MESSAGES_ARRAY,
    payload: messages
})

export const chatOnAttachFileNewServiceDispatch = () => ({
    type: CONSTANT_ACTIONS.CHAT_ON_ATTACH_FILE_NEW_SERVICE_DISPATCH
})

export const fetchMessageBegin = () => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_BEGIN
})

export const fetchMessageEnd = () => ({
    type: CONSTANT_ACTIONS.FETCH_MESSAGES_END
})

export const emptyNewMessages = () => ({
    type: CONSTANT_ACTIONS.EMPTY_NEW_MESSAGES
});

export const checkVoipIsSupportedBegin = () => ({
    type: CONSTANT_ACTIONS.CHECK_VOIP_IS_SUPPORTED_BEGIN
});

export const checkVoipIsSupportedEnd = () => ({
	type: CONSTANT_ACTIONS.CHECK_VOIP_IS_SUPPORTED_END,
});

export const generatePeersTokenBegin = () => ({
    type: CONSTANT_ACTIONS.GENERATE_PEERS_TOKEN_BEGIN
});

export const generatePeersTokenSuccessful = () => ({
    type: CONSTANT_ACTIONS.GENERATE_PEERS_TOKEN_SUCCESSFUL
});

export const initPeersToken = (data) => ({
    type: CONSTANT_ACTIONS.INIT_PEERS_TOKEN,
    payload: data
});

export const setRoomInfo = (roomName) => ({
    type: CONSTANT_ACTIONS.SET_ROOM_INFO,
    payload: roomName
});

export const participantJoinedToRoom = () => ({
    type: CONSTANT_ACTIONS.PARTICIPANT_JOINED_TO_ROOM
});

export const backToChatHistory = () => ({
    type: CONSTANT_ACTIONS.BACK_TO_CHAT_HISTORY
});

export const toggleVoiceOnVideoChat = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_VOICE_ON_VIDEO_CHAT
});

export const toggleVideoOnVideoChat = () => ({
	type: CONSTANT_ACTIONS.TOGGLE_VIDEO_ON_VIDEO_CHAT,
});

export const setTokenTypeForVoip = (tokenType) => ({
    type: CONSTANT_ACTIONS.SET_TOKEN_TYPE_FOR_VOIP,
    payload: tokenType
});

export const addPartnerInfo = (data) => ({
    type: CONSTANT_ACTIONS.ADD_PARTNER_INFO,
    payload: data
});

export const partnerJoined = () => ({
    type: CONSTANT_ACTIONS.PARTNER_JOINED
});

export const partnerNotJoined = () => ({
    type: CONSTANT_ACTIONS.PARTNER_NOT_JOINED
});

export const endCallDispatch = () => ({
    type: CONSTANT_ACTIONS.END_CALL
});

export const backToChatHistoryMenuPageDispatch = () => ({
    type: CONSTANT_ACTIONS.BACK_TO_CHAT_HISTORY_MENU_PAGE
});

export const backToHistoryTransactionPageDispatch = () => ({
    type: CONSTANT_ACTIONS.BACK_TO_HISTORY_TRANSACTION_PAGE
});

export const setAmountOfRequestMkForm = (data) => ({
    type: CONSTANT_ACTIONS.SET_AMOUNT_OF_REQUEST_MK_FORM,
    payload: data
});

export const setAmountOfSendMkForm = (data) => ({
    type: CONSTANT_ACTIONS.SET_AMOUNT_OF_SEND_MK_FORM,
    payload: data
})

export const cannotRequestMk = () => ({
    type: CONSTANT_ACTIONS.CANNOT_REQUEST_MK
});

export const confirmSendMk = (amount, referenceId) => ({
    type: CONSTANT_ACTIONS.CONFIRM_SEND_MK,
    payload: {amount, referenceId}
});

export const confirmTransferInApp = ({ amount, transferFee, partnerName, currencySign, referenceId }) => ({
    type: CONSTANT_ACTIONS.CONFIRM_TRANSFER_IN_APP,
    payload: {amount, transferFee, partnerName, currencySign, referenceId}
});

export const refuseSendMk = (amount, referenceId) => ({
    type: CONSTANT_ACTIONS.REFUSE_SEND_MK,
    payload: { amount, referenceId }
});

export const sentMkTransactionHistory = (data) => ({
    type: CONSTANT_ACTIONS.SENT_MK_TRANSACTION_HISTORY,
    payload: data
});

export const recievedMkTransactionHistory = (data) => ({
    type: CONSTANT_ACTIONS.RECIEVED_MK_TRANSACTION_HISTORY,
    payload: data
});

export const toggleTransferMkNote = (note, amount) => ({
    type: CONSTANT_ACTIONS.TOGGLE_TRANSFER_MK_NOTE,
    payload: { note, amount}
});

export const unMountSentRecieveMkTransactions = () => ({
    type: CONSTANT_ACTIONS.UN_MOUNT_SENT_RECIEVE_MK_TRANSACTIONS
});

export const backToTransactionsHistoryDispatch = () => ({
    type: CONSTANT_ACTIONS.BACK_TO_TRANSACTIONS_HISTORY
});

export const toggleTransferMKConfirmation = () => ({
    type: CONSTANT_ACTIONS.TOGGLE_TRANSFER_MK_CONFIRMATION
});

export const toggleCopyCallBottomSheet = (numbers) => ({
	type: CONSTANT_ACTIONS.TOGGLE_COPY_CALL_BOTTOM_SHEET,
	payload: numbers,
});

export const callToNumberDispatch = () => ({
    type: CONSTANT_ACTIONS.CALL_TO_NUMBER_DISPATCH
});

export const mkTransactionsFetching = (condition) => ({
    type: CONSTANT_ACTIONS.MK_TRANSACTIONS_FETCHING,
    payload: condition
});

export const sendMoneyLoading = (condition) => ({
    type: CONSTANT_ACTIONS.SEND_MONEY_LOADING,
    payload: condition
})

export const setJobCancelReasons = (reasons) => ({
    type: CONSTANT_ACTIONS.SET_JOB_CANCEL_REASONS,
    payload: reasons
})

export const toggleTransfer3DsBottomSheet = (flag) => ({
    type: CONSTANT_ACTIONS.TOGGLE_TRANSFER_3DS_BOTTOM_SHEET,
    payload: flag
});