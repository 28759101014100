import React, { Fragment, Component } from "react";

import Moment from "../../moment/moment";
import dateBoxImg from "../../../assests/images/news/Date.svg";

export default class NewsBox extends Component {
	render() {
		/*get day and monthName for newsCalender Img*/
		let { data } = this.props;
		return (
			<Fragment>
				<div onClick={(e) => this.props.onClick(e, this.props.data.id, this.props.index)} className="News_Box m-t-5">
					<div className="no-padding News_Box_Envelope text-center">
						<div className="News_Box_Envelope_Container">
							<span className="News_Day">
								<Moment format="D">{data.dateTime}</Moment>
							</span>
							<span className="News_Month">
								<Moment format="MMM">{data.dateTime}</Moment>
							</span>
							<img src={dateBoxImg} className="" alt="" />
						</div>
					</div>
					<div className="no-padding-left News_Box_Right">
						<div className="News_Box_Title">{data.title}!</div>
						{!this.props.hasUrl && (
							<div className="News_Box_Summary">
								<p>{data.body}</p>
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}