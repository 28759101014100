import React, {Fragment} from 'react';

import './location-search-header.css';
import BackBlackIcon from '../../icons/back-black-icon';
import MapIcon from '../../icons/map-icon';

function LocationSearchHeader(props) { 

    return (
        <Fragment>
            <div className="col-xs-12 Location-search-header">
                <div onClick={props.onBack} className="col-xs-2 no-padding"><BackBlackIcon /></div>
                <div onClick={props.goToMap} className="col-xs-offset-4 col-xs-6 no-padding"><MapIcon /></div>
            </div>
        </Fragment>
    );
}

export default LocationSearchHeader;