import _ from 'lodash';

import * as DISPATCH_STATE from '../registerChangeState';
import { appLoadingEnd } from "../../commonActions/commonChangeState";
import { GraphRequest } from '../../../../../axios';
import * as LOG_EVENT from '../../../../analytics';
/**
 * send phone number to server for get code
 */
export function phoneNumberPostRequest(isCall) {
    return async (dispatch, getState) => {
        dispatch(DISPATCH_STATE.verificationLoading(true));
        const sendMobileNumberBodyRequest = {
            query: `mutation SendVerificationCode($input: ClientMobileNumberInput!) {
                sendVerificationCode(data: $input) {
                    succeed
                    result
                    details
                }
            }`,
            variables: {
                "input": initObjPhoneNumberRequest(getState, isCall)
            }
        }
        try {
            const { data } = await GraphRequest.all(sendMobileNumberBodyRequest);
            return data;
        
        } catch(err) {}
        
    }
}

export const requestVerificationCode = (isCall) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(phoneNumberPostRequest(isCall))
				.then((res) => {
					const phoneNumber = initObjPhoneNumberRequest(getState, isCall);
					dispatch(DISPATCH_STATE.verificationLoading(false));
					LOG_EVENT.logEvent(LOG_EVENT.PHONE_NUMBER_SENT_CWA, {
						success: res.data.sendVerificationCode.succeed,
						countryCode: phoneNumber.countryCode.code,
						number: phoneNumber.number.toString(),
					});
					if (!_.isNull(res.data) && res.data.sendVerificationCode.succeed) {
						// dispatch(DISPATCH_STATE.intervalCheckCodeBegin());
						dispatch(DISPATCH_STATE.registerPhoneNumberRequestFinish());
						dispatch(appLoadingEnd());
						resolve(res.data.sendVerificationCode);
					} else {
						dispatch(DISPATCH_STATE.postPhoneNumberHasError());
						dispatch(appLoadingEnd());
						resolve(res.data.sendVerificationCode);
					}
				})
				.catch((err) => {
					dispatch(appLoadingEnd());
					dispatch(DISPATCH_STATE.postPhoneNumberHasError());
					reject(err);
				});
        })
    }
}
/**
 * Create obj that is needed for sending to server
 * @param {*} getState 
 */
const initObjPhoneNumberRequest = (getState, wantToCall) => {
    let obj = {
		number: parseInt(getState().register.number),
		countryCode: {
			code: getState().register.countrySelected.code,
			icon: getState().register.countrySelected.icon,
		},
		fullNumber: getState().register.countrySelected.code + getState().register.number,
		isCall: wantToCall,
	};
    return obj;
}