import _ from 'lodash';
import { push } from "connected-react-router";

import * as DISPATCH_STATE from './chatChangeState';
import { fetchPrimaryPayment } from '../bookingActions';
import { fetchBalances } from "../billingActions";
import { bookingSetCurrentPrice, bookingRemovePrice } from "../bookingActions/bookingChangeState";
/**
 * When user click on nevermind in extend modal in option modal
 */
export const onNeverMindExtend = () => {
    return (dispatch) => {
        dispatch(bookingRemovePrice());
        dispatch(bookingSetCurrentPrice(0));
        dispatch(DISPATCH_STATE.onExtendJobChangeState());
    }
}
/**
 * when user click on job detail in menu of chat history
 */
export const jobChatDetailModal = () => {
    return (dispatch, getState) => {
        // dispatch(appLoadingBegin());
        if (_.isNull(getState().common.primaryPayment) || _.isNull(getState().billing.balance)) {
            dispatch(fetchPrimaryPayment());
            dispatch(fetchBalances());
        }
        dispatch(DISPATCH_STATE.onJobDetail());
        // dispatch(appLoadingEnd());
    }
}

export const jobChatDetailCancel = () => {
	return (dispatch, getState) => {
		dispatch(DISPATCH_STATE.onJobDetail());
	}
}

export const backToChatHistoryMenuPage = (jobId) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.backToChatHistoryMenuPageDispatch());
        dispatch(push("/jobs/" + Number(jobId) + "/chat/menu"));
    }
}

export const backToHistoryTransactionPage = (jobId) => {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.backToHistoryTransactionPageDispatch());
        dispatch(push("/jobs/" + Number(jobId) + "/chat/history-transactions"));
    }
}