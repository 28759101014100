import React, { Fragment, Component } from "react";
import _ from "lodash";

import JobsBoxHeading from "./JobsBoxHeading/JobsBoxHeading";
import JobImage from "./JobImage/JobImage";

export default class JobsBox extends Component {

	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		this.props.onClick(e, this.props.data.id, this.props.index);
	}

	render() {
		let job = this.props.data;
		return (
			<Fragment>
				<div onClick={this.onClick} className="Jobs-box">
					<div className="job-box-right">
						<JobImage imgSrc={job.product.icon} />
					</div>
					<div className="job-box-left">
						<JobsBoxHeading
							title={job.product.commercialName}
							date={job.dateTime}
							dueDate={!_.isNull(job.dueDate) ? job.dueDate : null}
							price={job.jobCost}
							address={job.jobLocations[0].address}
							state={job.state}
							mode={job.product.mode}
							{...this.props}
						/>
					</div>
				</div>
			</Fragment>
		);
	}
}