import React from "react";

import NoteIcon from "../../../assests/icons/note-to-partner.svg";
import DontCallIcon from "../../../assests/icons/dont-call.svg";
import NoAddressIcon from "../../../assests/icons/no-address-warning.svg";
import { separateCityName } from "../../../utils/functions/create-place-object-from-google-suggestions";

export default function DeliveringTo({
        title,
        note,
        isSavedPlace,
        location,
        dontCallMe,
        detail,
        onChangeAddress,
        onAddEditDetails,
        onUpdateAddress,
        hasSavedPlace
    }) {

    const isAddDetailNeeded = !detail;
    const isSetAddressNeeded = !isSavedPlace;

	return (
		<div className="delivering-to-wrapper">
			<div className="delivering-title">Delivering to</div>
			<div className="delivering-body">
				{isSavedPlace && <div className="saved-place-tag">{title}</div>}
				<div className="address-text">
					<span>
						{isSavedPlace ? location?.address : separateCityName(location?.title)} <br /> {isSavedPlace && location?.data}
					</span>
					{isAddDetailNeeded && !isSetAddressNeeded && (
						<div className="no-address-warning">
							<img src={NoAddressIcon} alt="no address" />
							<div className="no-address-text">No address details added</div>
						</div>
					)}
					{isSetAddressNeeded && (
						<div className="no-address-warning">
							<img src={NoAddressIcon} alt="no address" />
							<div className="no-address-text">Tell us the details. We deliver.</div>
						</div>
					)}
				</div>
				{!isAddDetailNeeded && isSavedPlace && (
					<>
						<div className="note-to-partner">
							<img src={NoteIcon} alt="note to partner" />
							<div className={`${note && note.length > 64 ? "note-to-partner-text note" : "note-to-partner-text"}`}>
								{note || "No note to personnel"}
							</div>
						</div>
						{dontCallMe && (
							<div className="note-to-partner">
								<img src={DontCallIcon} alt="don't call option" />
								<div className="note-to-partner-text">Dont call me, just text please</div>
							</div>
						)}
					</>
				)}
				{!isSetAddressNeeded ? (
					<div className="delivering-to-actions">
						<button onClick={onChangeAddress}>Pick Another</button>
						<button onClick={() => onAddEditDetails("Edit")}>{isAddDetailNeeded ? "Add Detail" : "Update This"}</button>
					</div>
				) : hasSavedPlace ? (
					<div className="delivering-to-actions">
						<button onClick={onChangeAddress}>Pick Another</button>
						<button onClick={() => onUpdateAddress()}>Update This</button>
					</div>
				) : (
					<div className="delivering-to-actions">
						<button onClick={() => onUpdateAddress()}>Update Your Address</button>
					</div>
				)}
			</div>
		</div>
	);
}
