export function googlePlacesMatching(map) {
    let places = [];
    places.push(map.location);

    map.googlePlaces.map((googlePlace, index) => places.push(map.googlePlaces[ index ]));
    // const slicedPlaces = places.slice(0, 3);

    // if (map.googlePlaces.length > 0) {
    //     places.push(map.googlePlaces[ map.googlePlaces.length - 1 ]);
    // }
    return places;
}
/**
 * in show locations in job matching details
 * @param {*} job 
 */
export function matchedPlaces(job) {
    let places = [];
    places.push(job.jobLocations[ 0 ]);
    if (job.jobLocations.length > 1) {
        places.push(job.jobLocations[ job.jobLocations.length - 1 ]);
    }

    return places;
}