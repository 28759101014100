export function jobTime(price, minCubeNumber) {
    return convertTime(price.items[minCubeNumber].jobTime.total.value);
};

export function convertTime(value) {
	const hours = Math.floor(value / 60);
	const minutes = value % 60;
	
	return `${hours > 0 ? padNumber(hours, 2) + " hr" : ""} ${minutes > 0 ? padNumber(minutes, 2) + " min " : " "}`;
};

export function padNumber(number, size) {
	number = String(number);
	while (number.length < (size || 2)) {
		number = "0" + number;
	}
	return number;
};