import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
const classNames = require('classnames');

export default function AdvanceButton({ title, subtitle, buttonTitle, onClick, icon }) {

    return (
		<div className="advance-button-container" onClick={onClick}>
			<div className="advance-button-specs">
				<img className="advance-button-icon" src={icon} />
				<div className="advance-button-text">
					<div className="advance-button-title">{title}</div>
					<div className="advance-button-subtitle">{subtitle}</div>
				</div>
			</div>
			<div className="advance-button-action">
				{buttonTitle}
			</div>
		</div>
	);
}