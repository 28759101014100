import React from 'react';

// import mapImg from '../../assests/icons/map.png';
import { ReactComponent as MapImg } from '../../assests/icons/map.svg';

export default function MapIcon(props) {

    return (
        <MapImg {...props} className="img-responsive Map-icon"/>
    );
}