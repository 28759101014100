import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "connected-react-router";

import FormInput from "./input/form-input";
import {
	saveLastPathName,
	appAuthCheckFailed,
	toggleLogInBottomSheet,
	toggleRegisterBottomSheet,
} from "../../../utils/redux/actions/commonActions/commonChangeState";
import { registerClient, setHomeCity } from "../../../utils/redux/actions/registerActions";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";

import TitleBar from "../../../organisms/title-bar";
import CustomButton from "../../../components/ui-elements/CustomButton/CustomButton";
import WhiteLoading from "../../ui-elements/Loading/WhiteLoading";

import chatSupport from "../../../v5-icons/chat-support.svg";
import backButton from "../../../v5-icons/back-button.svg";

const SignupSchema = Yup.object().shape({
	givenName: Yup.string().required("Fist Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	emailAddress: Yup.string().trim().email("Invalid email address").required("Email Address is required"),
});

class RegisterForm extends Component {
	emailAddressRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			emailAddressError: null
		};
	}

	componentDidMount() {
		// LOG_EVENT.logEvent(LOG_EVENT.APP_OPENED_FIRST_TIME_CWA, { currency: 'PHP', value: 1.00 });
		this.props.dispatch(saveLastPathName(null));
		this.props.dispatch(setHomeCity({"id":3,"name":"Manila","lat":14.5995,"lng":120.9842}));
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	onBack = () => {
		this.props.dispatch(toggleRegisterBottomSheet(false));
		this.props.dispatch(toggleLogInBottomSheet());
	}

	onSubmit(values, setSubmitting) {
		this.props.dispatch(registerClient(values));
	}

	onEmailChange(e, setFieldValue) {
		const val = (e.target.value || "").replace(/\s+/gi, "");
		setFieldValue("emailAddress", val, false);
		if (!_.isNull(this.emailAddressRef.current)) {
			if (_.isUndefined(this.validateEmail(this.emailAddressRef.current.value))) {
				this.setState(() => ({
					emailAddressError: null,
				}));
			} else {
				this.validateEmail(this.emailAddressRef.current.value);
			}
		}
	}

	toggleFocusedClass(e, setFieldValue) {
		if (e.target.parentNode.classList.contains("focused")) {
			e.target.parentNode.classList.remove("focused");
		} else {
			e.target.parentNode.classList.add("focused");
		}
		if (e.target.name === "emailAddress") {
			if (!_.isNull(this.emailAddressRef.current)) {
				if (_.isUndefined(this.validateEmail(this.emailAddressRef.current.value))) {
					this.setState(() => ({
						emailAddressError: null,
					}));
				} else {
					this.validateEmail(this.emailAddressRef.current.value);
				}
			}
		}
	}

	validateEmail(value) {
		let error;
		if (
			value &&
			!/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
				value,
			)
		) {
			error = "Invalid email address";
		} else if (!value.length) {
			error = "Email Address is Required";
		}
		this.setState(() => ({
			emailAddressError: error,
		}));
		return error;
	}

	render() {
		return (
			<React.Fragment>
				<div className="register-form-container">
					<TitleBar
						rightIcon={chatSupport}
						handleClickRightIcon={this.onSupport}
						title="Personal Profile"
						leftIcon={backButton}
						handleClickLeftIcon={this.onBack}
					/>
					<div className="register-form">
						<Formik
							initialValues={{
								givenName: "",
								lastName: "",
								emailAddress: "",
								livingCity: "",
							}}
							validationSchema={SignupSchema}
							onSubmit={(values, { setSubmitting }) => {
								this.onSubmit(values, setSubmitting);
							}}
						>
							{({ values, errors, touched, handleChange, handleSubmit, isValid, dirty, setFieldValue }) => (
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<FormInput
											label="First Name"
											placeholder="Janina"
											autoFocus
											name="givenName"
											onChange={handleChange}
											onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											value={values.givenName}
											error={errors.givenName && touched.givenName && errors.givenName}
										/>
									</div>
									<div className="form-group">
										<FormInput
											label="Last Name"
											placeholder="San Miguel"
											name="lastName"
											onChange={handleChange}
											onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											value={values.lastName}
											error={errors.lastName && touched.lastName && errors.lastName}
										/>
									</div>
									<div className="form-group">
										<FormInput
											label="Email Address"
											placeholder="bb.janina@gmail.com"
											forminputref={this.emailAddressRef}
											name="emailAddress"
											onChange={(e) => this.onEmailChange(e, setFieldValue)}
											onBlur={(e) => this.toggleFocusedClass(e, setFieldValue)}
											onFocus={(e) => this.toggleFocusedClass(e, setFieldValue)}
											value={values.emailAddress}
											error={this.state.emailAddressError}
											// error={errors.emailAddress && touched.emailAddress && errors.emailAddress}
										/>
									</div>
									{/* {!_.isEmpty(this.props.cities) && 
										<React.Fragment>
											<div className="form-group">
												<div className="form-group-container">
													<label>Home City</label>
													<div onClick={() => this.props.dispatch(toggleSelectCityModal())}>
														{this.props.livingCity ? <span className="home-city-selected">{this.props.livingCity.name}</span> : <span className="select-city-placeholder">select your city</span>}
													</div>

												</div>
												<SelectCity cities={this.props.cities} isOpen={this.props.toggleSelectCityModal} />
											</div>
										</React.Fragment>
									} */}
									<div className="no-padding sticky-button-container">
										<div className="sticky-button">
											<CustomButton
												disabled={
													values.givenName === "" ||
													values.lastName === "" ||
													!_.isNull(this.state.emailAddressError) ||
													values.emailAddress === "" ||
													this.props.registerClientLoading
												}
												title={
													this.props.registerClientLoading ? (
														<div className="next-button-loading">
															<WhiteLoading />
														</div>
													) : (
														"Let's Go"
													)
												}
												type="submit"
												className="primary-button"
											/>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.register,
	common: state.common,
});

export default connect(mapStateToProps)(RegisterForm);