import _ from 'lodash';

import * as DISPATCH_STATE from '../commonChangeState';
import { GraphRequest } from '../../../../../axios';
import {appFetchFailure} from './common-failure';
import { setClientLocale, getClientLocale, setClientUnitCurrency, getClientUnitCurrency } from "../../../../functions/local-storage";
// import * as LOG_EVENT from '../../../../analytics';
/**
 * fetch client
 * @param {*} loading 
 */
export function fetchClient(loading = true) {
    return (dispatch) => {
        const clientRequestBody = {
          query: `query Client {
                client {
                  id
                  givenName
                  lastName
                  emailAddress
                  mobileNumber {
                    number
                    fullNumber
                    countryCode {
                      icon
                      code
                    }
                  }
                employerCompany
                refereeCode
                referralCode
                livingCity {
                    id
                    displayValue
                }
                workingCity {
                    id
                    displayValue
                }
                state
                currency {
                  unit
                  countryCode
                  locale
                  signText
                  signSvg
                }
              }}`,
		};
      return new Promise((resolve) => {
        GraphRequest.all(clientRequestBody)
          .then(json => {
            if (!_.isNull(json.data.data) && json.data.data.client) {
              dispatch(DISPATCH_STATE.appGetClient(json.data.data.client));
              if (!getClientLocale() || !getClientUnitCurrency()) {
                setClientLocale(json.data.data.client.currency.locale);
                setClientUnitCurrency(json.data.data.client.currency.unit);
              }
              resolve(json.data.data.client);
            } else {
              dispatch(appFetchFailure(json.data.errors[0].message));
              dispatch(DISPATCH_STATE.appAuthCheckEnd());
            }
          })
      })
    };
  }