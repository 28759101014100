import _ from "lodash";

import {
    localStorageGetTourPageViwed,
	localStorageSetTourPageViwed,
	localStorageGetTourPageStepsViwed,
	localStorageSetTourPageStepsViwed,
	localStorageRemoveTourPageStepsViwed,
	localStorageRemoveTourPageViwed,
	localStorageSetSkipTour,
	localStorageGetSkipTour } from "../../../../functions/local-storage";
import * as DISPATCH_STATE from "../commonChangeState";

export const checkCurrentStep = (currentStep, pageName) => {
	return (dispatch, getState) => {
		const isSkipped = localStorageGetSkipTour();
		if (!isSkipped) {
			let storeTourStepsViewed = !_.isNull(JSON.parse(localStorageGetTourPageStepsViwed(pageName)))
				? JSON.parse(localStorageGetTourPageStepsViwed(pageName))
				: [];
			if (!_.isNull(JSON.parse(localStorageGetTourPageStepsViwed(pageName)))) {
				if (!localStorageGetTourPageStepsViwed(pageName).includes(currentStep)) {
					storeTourStepsViewed.push(currentStep);
					localStorageSetTourPageStepsViwed(pageName, JSON.stringify(storeTourStepsViewed));
				}
			} else {
				if (!_.isNull(localStorageGetTourPageViwed())) {
					if(!JSON.parse(localStorageGetTourPageViwed()).includes(pageName)) {
						//adding next steps
						storeTourStepsViewed.push(currentStep);
						localStorageSetTourPageStepsViwed(pageName, JSON.stringify(storeTourStepsViewed));
						dispatch(DISPATCH_STATE.setCurrentStep(JSON.parse(localStorageGetTourPageStepsViwed(pageName)).pop()));
					}
				} else {
					//first time show tour
					storeTourStepsViewed.push(currentStep);
					localStorageSetTourPageStepsViwed(pageName, JSON.stringify(storeTourStepsViewed));
					dispatch(DISPATCH_STATE.setCurrentStep(JSON.parse(localStorageGetTourPageStepsViwed(pageName)).pop()));
				}
			}
		}
	};
};

export const setConfigForTour = (pageName, steps) => {
	return (dispatch, getState) => {
		if (JSON.parse(localStorageGetTourPageStepsViwed(pageName)) && (getState().common.currentStep !== JSON.parse(localStorageGetTourPageStepsViwed(pageName)).pop())) {
			dispatch(DISPATCH_STATE.setCurrentStep(JSON.parse(localStorageGetTourPageStepsViwed(pageName)).pop()));
		}
		if (_.isNull(JSON.parse(localStorageGetTourPageViwed())) || !JSON.parse(localStorageGetTourPageViwed()).includes(pageName)) {
			dispatch(DISPATCH_STATE.setTourSteps(steps));
			dispatch(DISPATCH_STATE.toggleTour(true));
			if (!_.isNull(JSON.parse(localStorageGetTourPageStepsViwed(pageName))))
				dispatch(DISPATCH_STATE.setCurrentStep(JSON.parse(localStorageGetTourPageStepsViwed(pageName)).pop()));
		}
	};
};

export const tourPageisDone = (pageName) => {
	return (dispatch, getState) => {
		let storeTourPages = _.isNull(JSON.parse(localStorageGetTourPageViwed())) ? [] : JSON.parse(localStorageGetTourPageViwed());
		storeTourPages.push(pageName);
		localStorageSetTourPageViwed(JSON.stringify(storeTourPages));
		localStorageRemoveTourPageStepsViwed(pageName);
	};
};

export const skipShowingTour = (pageName) => {
	return (dispatch, getState) => {
		localStorageRemoveTourPageStepsViwed(pageName);
		localStorageRemoveTourPageViwed();
		localStorageSetSkipTour();
		dispatch(DISPATCH_STATE.toggleTour(false));
	};
};