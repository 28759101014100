import React from 'react';

// import img from '../../assests/icons/current.png';
import { ReactComponent as Svg } from '../../assests/icons/current.svg';

export default function CurrentIcon(props) {

    return (
        <Svg className="img-responsive Current-icon" />
    );
}