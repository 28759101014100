import React from "react";
import * as icons from "../../../assests/icons";

/* dynamic component use export file from icons and images */
const DynamicIcon = (props) => {
	const icon = icons[`${props.icon}`];
	return (
		<div onClick={props.clicked} className="Dynamic_Parent_Icon">
			<img src={icon} alt="icon" className={`img-responsive ${ props.classes }`}/>
			{props.name && <h1>{props.name}</h1>}
		</div>
	);
};
export default DynamicIcon;