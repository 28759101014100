import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "connected-react-router";

import * as LOG_EVENT from "../../../utils/analytics/index";
import {
	requestCheckBookingProductExist,
	onFocusSpecialTextarea,
	onKeyDownSpecialTextarea,
	backToBookingPage,
	navigateToReviewBooking,
} from "../../../utils/redux/actions/bookingActions";
import { onChnageSpecialTextarea } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
import { addAnimateToText } from "../../../utils/redux/actions/commonActions";

import ReviewBookingButton from "../ReviewBookingButton/ReviewBookingButton";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import BookingInfoModal from "../BookingInfoModal/BookingInfoModal";
import TitleBar from "../../../organisms/title-bar";
import BookingSpecial from "../BookingSpecial/BookingSpecial";
import BookingService from "../BookingService/BookingService";

import backButton from "../../../v5-icons/back-button.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";

class Request extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowProductInfoPopup: false,
			isOpenBottomSheet: false,
			isBookingNoteFocused: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(requestCheckBookingProductExist());
		LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DETAIL_PAGE_CWA);
		//Add animate to text when it's too long
		this.props.dispatch(addAnimateToText(document.querySelector(".long-heading"), document.querySelector(".long-heading .heading")))
			.then((res) => {
				if (res) {
					this.setState(() => ({
						hasFadePropProduct: true,
					}));
				}
			}).catch((err) => {});
	}

	onCloseProductInfo = () => {
		this.setState(() => ({
			isShowProductInfoPopup: false,
		}));
	};
	isToggleSheet = () => {
		this.setState(() => ({ isShowProductInfoPopup: false }));
	};
	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};
	goBack = () => {
		this.props.dispatch(backToBookingPage());
	};
	onOpenProductInfoModal = () => {
		this.setState(() => ({
			isShowProductInfoPopup: true,
		}));
	};
	onChangeNote = (e) => {
		this.props.dispatch(onChnageSpecialTextarea(e.target.value));
	};

	onFocusNote = (e) => {
		this.props.dispatch(onFocusSpecialTextarea(e.target.value));
		this.setState(() => ({
			isBookingNoteFocused: true,
		}));
	};

	onBlurBookingNote = () => {
		this.setState(() => ({ isBookingNoteFocused: false }));
	}

	onKeyDown = (e) => {
		this.props.dispatch(onKeyDownSpecialTextarea(e));
	};

	onReviewBooking = () => {
		LOG_EVENT.logEvent(LOG_EVENT.BOOKING_DETAIL_DID_ENTER_CWA, { hasNote: this.props.note.length > 0 ? true : false });
		this.props.dispatch(navigateToReviewBooking());
	};
	handleChangeBookingInfoBottomSheet = (isOpen) => {
		if (!isOpen) {
			this.setState(() => ({ isShowProductInfoPopup: false }));
		}
	};
	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};
		return (
			<div className="booking-note">
				{this.state.isShowProductInfoPopup ? (
					<BottomSheet
						isOpen={this.state.isShowProductInfoPopup}
						styles={bottomSheetStyle}
						closeSheet={this.isToggleSheet}
						onChangeOverlay={this.handleChangeBookingInfoBottomSheet}
						classes="product-info-bottom-sheet"
						indicator={true}
					>
						<BookingInfoModal closeModal={this.onCloseProductInfo} productDetail={this.props.product} />
					</BottomSheet>
				) : null}
				<div className="request-container">
					{!_.isEmpty(this.props.product) ? (
						<Fragment>
							<TitleBar
								leftIcon={backButton}
								rightIcon={chatSupport}
								handleClickLeftIcon={this.goBack}
								handleClickRightIcon={this.onSupport}
							/>
							<BookingService
								progressBar="booking-note"
								icon={this.props.product.icon}
								commercialName={this.props.product.commercialName}
								descriptionTitle={this.props.product.descriptionTitle}
								hasIconInfo={true}
								showInfoProductModal={this.onOpenProductInfoModal}
								hasFadePropProduct={this.state.hasFadePropProduct}
							/>
						</Fragment>
					) : null}
					<BookingSpecial
						value={this.props.note}
						onBlur={this.onBlurBookingNote}
						onChange={this.onChangeNote}
						onFocus={this.onFocusNote}
						onKeyDown={this.onKeyDown}
					/>
				</div>
				{!_.isEmpty(this.props.prices) && !this.props.common.loading ? (
					<ReviewBookingButton onReviewBooking={this.onReviewBooking} prices={this.props.prices} />
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
	common: state.common,
	map: state.map,
	location: {
		jobLocations: [
			{
				lat: state.map.location.lat,
				lng: state.map.location.lng,
				address: state.map.location.address,
				title: state.map.location.title,
			},
		],
	},
});

export default connect(mapStateToProps)(Request);